import api from '@/api'

const SET_GOALS = 'SET_GOALS'
const ADD_GOAL = 'ADD_GOAL'
const REMOVE_GOAL = 'REMOVE_GOAL'
const FAIL_GOAL = 'FAIL_GOAL'
const COMPLETE_GOAL = 'COMPLETE_GOAL'
const REJECT_GOAL = 'REJECT_GOAL'
const APPROVE_GOAL = 'APPROVE_GOAL'
const SHOW_READ_GOAL_MODAL = 'SHOW_READ_GOAL_MODAL'
const SET_GOAL = 'SET_GOAL'
export default {
  namespaced: true,

  state () {
    return {
      goals: {
        inProgress: [],
        completed: [],
        failed: []
      },
      showReadGoalModal: false
    }
  },

  getters: {
    goals: (state) => state.goals,
    getShowReadGoalModal: (state) => state.showReadGoalModal
  },

  mutations: {
    [SET_GOALS] (state, data) {
      state.goals = data
    },

    [ADD_GOAL] (state, goal) {
      state.goals.inProgress.push(goal)
    },

    [REMOVE_GOAL] (state, goalId) {
      for (const key in state.goals) {
        const findedGoalIndex = state.goals[key].findIndex(el => el.id === goalId)
        if (findedGoalIndex !== -1) {
          state.goals[key].splice(findedGoalIndex, 1)
          break
        }
      }
    },

    [FAIL_GOAL] (state, goal) {
      for (const key in state.goals) {
        const findedGoalIndex = state.goals[key].findIndex(el => el.id === goal.id)
        if (findedGoalIndex !== -1) {
          state.goals[key].splice(findedGoalIndex, 1)
          break
        }
      }
      goal.completionStatusId = 'FAILED'
      goal.helpers.canBeUpdatedByCurrentEmployee = false
      state.goals.failed.push(goal)
    },

    [APPROVE_GOAL] (state, goal) {
      for (const key in state.goals) {
        const findedGoal = state.goals[key].find(el => el.id === goal.id)
        if (findedGoal !== -1) {
          findedGoal.approvalStatusId = 'APPROVED'
          findedGoal.helpers.canBeUpdatedByCurrentEmployee = false
          findedGoal.helpers.canBeDeletedByCurrentEmployee = false
          break
        }
      }
    },

    [REJECT_GOAL] (state, goal) {
      for (const key in state.goals) {
        const findedGoal = state.goals[key].find(el => el.id === goal.id)
        if (findedGoal !== -1) {
          findedGoal.approvalStatusId = 'REJECTED'
          findedGoal.helpers.canBeUpdatedByCurrentEmployee = false
          findedGoal.helpers.canBeDeletedByCurrentEmployee = false
          break
        }
      }
    },

    [COMPLETE_GOAL] (state, goal) {
      for (const key in state.goals) {
        const findedGoalIndex = state.goals[key].findIndex(el => el.id === goal.id)
        if (findedGoalIndex !== -1) {
          state.goals[key].splice(findedGoalIndex, 1)
          break
        }
      }
      goal.completionStatusId = 'COMPLETED'
      goal.helpers.canBeUpdatedByCurrentEmployee = false
      state.goals.completed.push(goal)
    },

    [SHOW_READ_GOAL_MODAL] (state, bool) {
      state.showReadGoalModal = bool
    },

    [SET_GOAL] (state, data) {
      state.goals.inProgress = data
    }
  },

  actions: {
    async loadGoals (context, employeeId) {
              // context.commit(SET_GOALS, [])
      if (typeof employeeId === 'number') {
        const { data } = await api.goals.listByEmployeeId(employeeId)
        context.commit(SET_GOALS, data || {})
      } else {
        const { data } = await api.goals.list()
        context.commit(SET_GOALS, data || {})
      }
    },

    async updateGoal (context, { goalId, values }) {
      const { data, error } = await api.goals.update(goalId, values)
      return { data, error }
    },

    async createGoal (context, { goal, employeeId }) {
      let data = null
      if (employeeId) {
        const res = await api.goals.createForEmployee(employeeId, goal)
        data = res.data
      } else {
        const res = await api.goals.create(goal)
        data = res.data
      }

      if (data) {
        context.commit(ADD_GOAL, data)
      }
      return data
    },

    async removeGoal (context, { goalId }) {
      api.goals.remove(goalId)
      context.commit(REMOVE_GOAL, goalId)
    },

    completeGoal (context, { goal }) {
      api.goals.complete(goal.id)
      context.commit(COMPLETE_GOAL, goal)
    },

    failGoal (context, { goal }) {
      api.goals.fail(goal.id)
      context.commit(FAIL_GOAL, goal)
    },

    approve (context, { goal }) {
      api.goals.approve(goal.id)
      goal.approvalStatusId = 'APPROVED'
    },

    reject (context, { goal }) {
      api.goals.reject(goal.id)
      goal.approvalStatusId = 'REJECTED'
    },

    async comment (context, { comment, goal }) {
      goal.comments.push(comment.comment)
      const data = await api.goals.comment(goal.id, { comment: comment.comment.comment })
      comment.comment.id = data.id
    },

    setAprrovalStatusFromNotification (context, { goal }) {
      if (goal.approvalStatusId === 'APPROVED') {
        context.commit(APPROVE_GOAL, goal)
      } else {
        context.commit(REJECT_GOAL, goal)
      }
    },

    setCompletionStatusFromNotification (context, { goal }) {
      if (goal.completionStatusId === 'COMPLETED') {
        context.commit(COMPLETE_GOAL, goal)
      } else {
        context.commit(FAIL_GOAL, goal)
      }
    },
    setShowReadGoalModal ({ commit }, bool) {
      commit('SHOW_READ_GOAL_MODAL', bool)
    },

    async getSingleGoal ({ state, commit }, id) {
      const { data } = await api.goals.singleGoal(id)
      const arr = state.goals.inProgress.map(el => el.id === data.id ? data : el)
      commit('SET_GOAL', arr)
    }
  }
}
