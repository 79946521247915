<template lang="pug">
  .search-result__section(v-if="items.length")
    .search-result_title(v-if="showTitle") {{ 'ui.labels.badges'  | translate}}
    .search-result__item(
      v-for="item in items"
      :key="item.id"
      @click="onClick(item.id)")

      .search-result__item-icon
        node-item(:data="item")
      .search-result__item-inner
        .search-result__item-title
          | {{ item.displayName || item.name }}
</template>

<script>
import NodeItem from '@/components/common/NodeItem'
import { PREFERENCES_VIEWS_TOGGLER } from '@/util/constants'
import { mapActions } from 'vuex'
export default {
  name: 'SearchResultBadges',

  components: {
    NodeItem
  },

  props: {
    items: Array,
    showTitle: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    treeViewRoutes: [
      '/my',
      '/skill-finder',
      '/builder',
      '/employee'
    ]
  }),

  computed: {
    navigateTo () {
      if (this.$route.path.includes('/share')) {
        return Object.keys(this.$route.params).length ? `/employees/share/${this.$route.params.id}` : this.$route.path
      }
      if (this.treeViewRoutes.some(item => this.$route.path.includes(item))) {
        return this.$route.path.split('/')[1] === 'employee' ? '/' + this.$route.path.split('/')[1] + '/' + this.$route.path.split('/')[2]
          : '/' + this.$route.path.split('/')[1]
      } else return '/my'
    }
  },
  methods: {
    ...mapActions('preferences', ['changeToggler']),
    ...mapActions('trees', [
      'selectMySkilltree',
      'unselectMySkilltree'
    ]),
   async  onClick (ID) {
    await this.unselectMySkilltree()
      this.changeToggler(PREFERENCES_VIEWS_TOGGLER[1])
      this.$router.push(`${this.navigateTo}/badge/${ID}`)
    }
  }
}
</script>

<style lang="scss" scoped>
  .search-result {
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 0px !important;
      padding: 5px;
    }

    &__item-icon {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 40px;
      height: 100%;
      width: 30px;
      margin: 0 7px;
    }
  }
</style>
