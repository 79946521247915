<template lang="pug" functional>
  svg(
    :height="props.size"
    :width="props.size"
    :class="props.classNames")
    circle(
      fill="transparent"
      :cx="props.size / 2"
      :cy="props.size / 2"
      :r="props.size / 2 - props.width"
      :stroke="props.color"
      :stroke-width="props.width")

</template>

<script>
import { COLOR_PRIMARY } from '@/util/constants'

export default {
  name: 'CategoryCircle',

  props: {
    size: {
      type: Number,
      default: 24
    },
    color: {
      type: String,
      default: COLOR_PRIMARY
    },
    width: {
      type: Number,
      default: 4
    },
    classNames: {
      type: String
    }
  }
}
</script>
