import store from '@/store'
import interact from 'interactjs'
import { NODE_TYPE } from '@/util/constants.js'
import { isSpecialKeyPressed } from '@/util/utils.js'

export const initDragable = (svgpanzoom) => {
  const startPos = { x: 0, y: 0 }
  const currentPos = { x: 0, y: 0 }

  let zoom = 1
  let started = false
  let node = null

  interact('.node-draggable').unset()
  interact('.node-draggable').draggable({
    onstart: async (event) => {
      store.dispatch('nodeTmp/unsetCursorNode')
      const el = event.target
      const nodeId = el.getAttribute('data-id')
      node = await store.dispatch('trees/getNodeById', parseInt(nodeId))
      if ((store.getters['nodeTmp/hasSelectedNodes'] &&
        !isSpecialKeyPressed(event) &&
        !await store.dispatch('nodeTmp/isNodeSelected', node)) ||
        (store.getters['nodeTmp/hasSelectedNodes'] &&
          store.getters['nodeTmp/selectedNodes'][0].props.typeId === NODE_TYPE.CATEGORY)) {
        await store.dispatch('nodeTmp/unselectAllNodes')
      }
      if (!store.getters['nodeTmp/hasSelectedNodes'] ||
        (store.getters['nodeTmp/hasSelectedNodes'] &&
          isSpecialKeyPressed(event) &&
          node.props.typeId !== NODE_TYPE.CATEGORY)) {
        if (!await store.dispatch('nodeTmp/isNodeSelected', node)) {
          node.localProps.el = el
          store.dispatch('nodeTmp/selectNode', node)
        }
      }
      store.dispatch('nodeTmp/setDragedBefore', true)
      for (const item of store.getters['nodeTmp/selectedNodes']) {
        store.dispatch('svgTopLayer/moveElementToTopLayer', item.localProps.el)
      }
      startPos.x = event.client.x
      startPos.y = event.client.y
      zoom = svgpanzoom.getZoom()
      svgpanzoom.disablePan()
      started = true // IMPORTANT!!! it's must be in the end of this function
      // for execute 'onmove' after finishing of 'onstart'
      // and prevent some bugs
    },
    onmove: (event) => {
      if (started) {
        const shift = {
          x: (event.client.x - startPos.x) / zoom,
          y: (event.client.y - startPos.y) / zoom
        }
        startPos.x = event.client.x
        startPos.y = event.client.y
        currentPos.x = currentPos.x + shift.x
        currentPos.y = currentPos.y + shift.y
        if (store.getters['nodeTmp/selectedNodes'].length > 0 &&
          !(node.props.typeId === NODE_TYPE.CATEGORY &&
            store.getters['nodeTmp/selectedNodes'][0].props.typeId !== NODE_TYPE.CATEGORY)) {
          for (const node of store.getters['nodeTmp/selectedNodes']) {
            const x = +node.localProps.el.getAttribute('data-x')
            const y = +node.localProps.el.getAttribute('data-y')
            node.localProps.el.setAttribute('transform', `translate(${x + currentPos.x}, ${y + currentPos.y})`)
          }
        }
      }
    },
    onend: async (event) => {
      currentPos.x = 0
      currentPos.y = 0
      if (store.getters['nodeTmp/selectedNodes'].length > 0) {
        for (const node of store.getters['nodeTmp/selectedNodes']) {
          node.localProps.el.removeAttribute('transform')
          store.dispatch('svgTopLayer/restoreElementFromTopLayer', node.localProps.el)
        }
      }
      await store.dispatch('nodeTmp/unselectAllNodes')
      svgpanzoom.enablePan()
      started = false
      node = null
      // START: Fix for FireFox sticky pan bug
      const treeSvgEl = document.getElementById('treeSvg')
      const mouseupEvent = new Event('mouseup')
      treeSvgEl.dispatchEvent(mouseupEvent)
      // END
    }
  })
}

export const unsetDragable = () => {
  interact('.node-draggable').unset()
}

const dragMoveListener = (event) => {
  const target = event.target
  const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
  const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
  target.style.webkitTransform =
    target.style.transform =
    'translate(' + x + 'px, ' + y + 'px)'
  target.setAttribute('data-x', x)
  target.setAttribute('data-y', y)
}

const dragMoveListener2 = (event) => {
  const target = event.target
  const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
  const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
  target.style.position = 'absolute'
  target.style.left = x + 'px'
  target.style.top = (y - 165) + 'px'
  target.setAttribute('data-x', x)
  target.setAttribute('data-y', y)
}

const badgeMoveLogic = (nodeId, badgeId) => {
  if (nodeId !== badgeId) {
    if (store.getters['nodeTmp/selectedNodes'].length > 0) {
      for (const node of store.getters['nodeTmp/selectedNodes']) {
        store.dispatch('trees/moveBadge', { categoryId: nodeId, badgeId: node.props.id })
      }
    }
  }
}

const createHookedBadge = (params) => {
  return store.dispatch('trees/createHookedBadge', params)
}

export const initDrop = (addCategory, addBadge, addGradationalBadge, addBadgeByTemplate) => {
  interact('.dropzone').unset()

  interact('.dropzone')
    .dropzone({
    accept: ['.drag-drop', '.drag-move'],
    overlap: 0.001,
    ondropactivate: (event) => {
      const rankType = event.relatedTarget.getAttribute('data-rank')
      store.dispatch('dragNDrop/setDraggingElementType', rankType)
    },
    ondragenter: async (event) => {
      const rank = event.relatedTarget.getAttribute('data-rank')
      const nodeId = event.target.getAttribute('data-id')
      await store.dispatch('nodeTmp/setDragEnterNodeId', parseInt(nodeId))
      const cat = document.getElementById('cat' + nodeId)
      const allowDrop = cat.getAttribute('data-allow-drop')
      const zone = event.target.getAttribute('data-zone')
      if (allowDrop && zone !== 'hooked') {
        cat.classList.add('show-' + zone)
      }
      if (zone === 'hooked' && (rank === 'badge' || rank === 'move-badge')) {
        cat.classList.add('show-' + zone)
      }
    },

    ondragleave: async (event) => {
      const zone = event.target.getAttribute('data-zone')
      const nodeId = event.target.getAttribute('data-id')
      await store.dispatch('nodeTmp/setDragEnterNodeId', null)
      const cat = document.getElementById('cat' + nodeId)
      cat.classList.remove('show-' + zone)
      event.relatedTarget.classList.remove('can-drop')
    },
    ondrop: async (event) => {
      const nodeId = +event.target.getAttribute('data-id')
      const zone = event.target.getAttribute('data-zone')
      const cat = document.getElementById('cat' + nodeId)
      const allowDrop = cat.getAttribute('data-allow-drop')
      if (allowDrop || zone === 'hooked') {
        const rank = event.relatedTarget.getAttribute('data-rank')
        const badgeId = +event.relatedTarget.getAttribute('data-id')
        switch (zone) {
          case 'add':
            switch (rank) {
              case 'category':
                await store.dispatch('trees/setCursorNodeById', nodeId)
                addCategory()
                break
              case 'badge':
                store.dispatch('trees/setCursorNodeById', nodeId)
                addBadge()
                break
              case 'gradational-badge':
                store.dispatch('trees/setCursorNodeById', nodeId)
                addGradationalBadge()
                break
              case 'badge-template':
                await store.dispatch('trees/setCursorNodeById', nodeId)
                const templateId = +event.relatedTarget.getAttribute('data-template-id')
                const type = event.relatedTarget.getAttribute('data-type')
                if (type === 'bookmark') {
                  const elements = store.getters['dragNDrop/draggingElements']
                  if (elements.length) {
                    elements.forEach(item => {
                      addBadgeByTemplate(item.id, item.templateId)
                    })
                    store.dispatch('dragNDrop/setDraggingElements', false)
                    break
                  }
                  addBadgeByTemplate(badgeId, templateId)
                } else {
                  addBadgeByTemplate(templateId)
                }
                break
              case 'move-badge':
                badgeMoveLogic(nodeId, badgeId)
                break
              case 'move-gradational':
                badgeMoveLogic(nodeId, badgeId)
                break
              case 'move-category':
                badgeMoveLogic(nodeId, badgeId)
                break
            }
            break
          case 'hooked':
            const node = await store.dispatch('trees/getNodeById', nodeId)
            switch (rank) {
              case 'move-badge':
                store.dispatch('nodeTmp/setCursorNode', node)
                if (node.props.hookedBadge !== null) {
                  const options = {
                    title: 'pages.builder.this_category_has_hooked_badge',
                    onconfirm: async () => {
                      const badgeId = parseInt(event.relatedTarget.getAttribute('data-id'))
                      const badge = await store.dispatch('trees/getNodeById', badgeId)
                      store.dispatch('trees/setHookedBadge', {
                        node: store.getters['nodeTmp/cursorNode'],
                        badge: badge
                      })
                    }
                  }
                  store.dispatch('app/showConfirmDialog', options)
                } else {
                  const badgeId = parseInt(event.relatedTarget.getAttribute('data-id'))
                  const badge = await store.dispatch('trees/getNodeById', badgeId)
                  store.dispatch('trees/setHookedBadge', {
                    node: store.getters['nodeTmp/cursorNode'],
                    badge: badge
                  })
                }
                break
              case 'badge':
                // if (node.props.hookedBadge !== null) {
                //   store.dispatch('nodeTmp/setCursorNode', node)
                //   const options = {
                //     title: 'pages.builder.this_category_has_hooked_badge',
                //     params: nodeId,
                //     onconfirm: createHookedBadge
                //   }
                //   store.dispatch('app/showConfirmDialog', options)
                // } else {
                  const hookedBadge = await createHookedBadge(nodeId)
                  store.dispatch('nodeTmp/setCursorNode', hookedBadge)
                // }
                break
            }
            break
        }
      }
    },
    ondropdeactivate: (event) => {
      event.relatedTarget.style = ''
      if (!event.relatedTarget.getAttribute('data-rank').includes('move')) {
        event.relatedTarget.setAttribute('data-x', 0)
        event.relatedTarget.setAttribute('data-y', 0)
      }
      event.target.classList.remove('drop-active')
      store.dispatch('dragNDrop/setDraggingElementType', null)
    }
  })

  interact('.drag-drop').unset()

  interact('.drag-drop')
    .draggable({
      inertia: true,
      autoScroll: true,
      onstart: (event) => {
        store.dispatch('nodeTmp/unsetCursorNode')
        store.dispatch('nodeTmp/unselectAllNodes')
        store.dispatch('app/setToolTipHidden', true)
        store.dispatch('app/setIsDragDropMode', true)
        const tooltip = document.getElementsByClassName('tooltip')
        for (let i = 0; tooltip.length > i; i++) {
          if (tooltip[i]) {
            tooltip[i].classList.add('tooltip--hidden')
          }
        }
        store.dispatch('nodeTmp/discardNodeChanges')
      },
      onend: () => {
        store.dispatch('app/setToolTipHidden', false)
      },
      onmove: dragMoveListener
    })

  interact('.drag-drop-2').unset()

  const templateDragOps = {
    node: null,
    parent: null,
    fixLayout: null
  }

  interact('.drag-drop-2')
    .draggable({
      inertia: true,
      autoScroll: true,
      restrict: {
        elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
      },
      onstart: (event) => {
        templateDragOps.fixLayout = document.getElementById('badge-template-drag-n-drop-fix')
        templateDragOps.node = event.currentTarget
        const pos = templateDragOps.node.getBoundingClientRect()
        event.page.x = pos.x + 35
        event.page.y = pos.y + 5
        const firstChild = event.currentTarget.firstChild
        const secondChild = event.currentTarget.firstChild.firstChild
        // const viewBox = secondChild.classList.value.includes('sw-badge') ? '0 0 40 80' : '0 0 35 35'
        const viewBox = secondChild.classList.value.includes('sw-badge')
            ? '0 0 40 80' : secondChild.classList.value.includes('drag-category')
            ? '0 0 28 28' : null
        firstChild.style.cssText = 'width:30px; height:35px; margin:0px'
        secondChild.setAttribute('stroke-width', '1')
        secondChild.setAttribute('stroke', '#ffdbac')
        if (viewBox) {
          secondChild.setAttribute('viewBox', viewBox)
        }
        secondChild.setAttribute('height', '70px')
        secondChild.setAttribute('width', '70px')
        const elements = store.getters['dragNDrop/draggingElements']
        if (elements.length) {
          let div = document.createElement('div')
          div.style.cssText = 'width: 20px; height: 20px; border-radius: 100%; display: flex; justify-content: center; align-items: center; background: red; position: absolute; top: 0; right: 30%; color: white'

          div.innerHTML = elements.length
          firstChild.appendChild(div)
        }
        if (templateDragOps.node.children[1]) templateDragOps.node.children[1].style.display = 'none'
        if (templateDragOps.node.children[2]) templateDragOps.node.children[2].style.display = 'none'
        if (templateDragOps.node.children[3]) templateDragOps.node.children[3].style.opacity = '0'
        templateDragOps.node.style.backgroundColor = 'unset'
        templateDragOps.node.style.background = 'none'
        templateDragOps.parent = templateDragOps.node.parentElement
        templateDragOps.node.setAttribute('data-x', pos.x)
        templateDragOps.node.setAttribute('data-y', pos.y)
        templateDragOps.parent.removeChild(templateDragOps.node)
        templateDragOps.fixLayout.appendChild(templateDragOps.node)
        store.dispatch('nodeTmp/unsetCursorNode')
        store.dispatch('nodeTmp/unselectAllNodes')
        store.dispatch('app/setIsDragDropMode', true)
        store.dispatch('app/setToolTipHidden', true)
        const tooltip = document.getElementsByClassName('tooltip')
        for (let i = 0; tooltip.length > i; i++) {
          if (tooltip[i]) {
            tooltip[i].classList.add('tooltip--hidden')
          }
        }
        store.dispatch('nodeTmp/discardNodeChanges')
      },
      onend: (event) => {
        templateDragOps.fixLayout.removeChild(templateDragOps.node)
        store.dispatch('app/setToolTipHidden', false)
        if (event.currentTarget.children[1]) event.currentTarget.children[1].style.display = 'block'
        if (event.currentTarget.children[2]) event.currentTarget.children[2].style.display = 'block'
      },
      onmove: dragMoveListener2
    })
    .on('move', function (event) {
      let interaction = event.interaction
      if (interaction.pointerIsDown && !interaction.interacting() && event.currentTarget.getAttribute('clonable') !== 'false') {
        let original = event.currentTarget
        let clone = event.currentTarget.cloneNode(true)
        original.setAttribute('clonable', 'true')
        clone.setAttribute('clonable', 'true')
        clone.style.position = 'absolute'
        clone.style.left = original.offsetLeft + 'px'
        clone.style.top = original.offsetTop + 'px'
        original.parentElement.appendChild(clone)
        interaction.start({ name: 'drag' }, event.interactable, clone)
      }
    })
}
