<template lang="pug">
  v-line(:config="lineConfig")
</template>

<script>
export default {
  name: 'TreeBadge',

  props: {
    begin: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    end: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    color: {
      type: String,
      default: '#13B389'
    },
    strokeWidth: {
      type: Number,
      default: 1.5
    },
    direction: {
      type: Number,
      default: -1
    },
    isSelected: Boolean
  },

  computed: {
    lineConfig () {
      return {
        points: [this.begin.x, this.begin.y, this.angle, this.begin.y, this.end.x, this.end.y],
        strokeWidth: this.strokeWidth,
        stroke: this.color
      }
    },

    angle () {
      const a = this.end.x
      const b = Math.abs(this.end.y - this.begin.y)
      return this.direction >= 0 ? a - b : a + b
    }
  }
}
</script>

<style>
</style>
