<template lang="pug">
.chevron-arrow(:class="{ active: !!activeTab }" @click="$emit('click-arrow')")
    chevron(
        :color="!!activeTab ? '#29A687' : '#666D74'",
        :rotate="!!activeTab ? 180 : 0"
    )
</template>

<script>
export default {
    name: 'ChevronArrow',
    components: {
        Chevron: () => import('@/components/svg/Chevron')
    },
    props: {
        activeTab: {
            type: Object,
            default: () => null
        }
    }
}
</script>

<!-- <style lang="scss" scoped>
.chevron-arrow{
    &.active{}
}
</style> -->
