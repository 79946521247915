/* eslint-disable */
const docCookies = {
  resetMaxAge: function (sKey) {
    if (!sKey) { return null; }
    this.setItem(sKey, this.getItem(sKey), (new Date(Date.now() + 2592000 * 1000)).toUTCString())
  },

  getItem: function (sKey) {
    if (!sKey) { return null; }
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  },

  setItem: function (sKey, sValue, sExpires = 0, domain = '') {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
    if (domain) {
      document.cookie = `${encodeURIComponent(sKey)}=${encodeURIComponent(sValue)};expires=${sExpires}; path=/; domain=${domain};`
    } else {
      document.cookie = `${encodeURIComponent(sKey)}=${encodeURIComponent(sValue)};expires=${sExpires}; path=/;`
    }
    return true;
  },

  removeItem: function (sKey, domain = '') {
    if (!this.hasItem(sKey)) { return false; }
    if(domain) {
      document.cookie = `${encodeURIComponent(sKey)}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${domain};`
    } else {
      document.cookie = `${encodeURIComponent(sKey)}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
    }
    return true;
  },

  hasItem: function (sKey) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
    return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
  },

  keys: function () {
    var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
    for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
    return aKeys;
  }
}

export default docCookies
