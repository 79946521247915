<template lang="pug">
  .v-chip-group
    v-chip.ma-2(
      v-for="(item, index) in options"
      :key="index"
      :color="color || item.color"
      :colored="!!color || !!item.color"
      :outline="!isSelected(item)"
      @click="onChange(item)")
      | {{ item.title }}
</template>

<script>
import VChip from './VChip'

export default {
  name: 'VChipGroup',

  components: {
    VChip
  },

  props: {
    color: String,
    options: Array,
    value: Array
  },

  mounted () {
    this.selected = this.value
  },

  data: () => ({
    selected: []
  }),

  methods: {
    onChange (item) {
      let index = this.selected
        .indexOf(item)
      if (index !== -1) {
        this.selected.splice(index, 1)
        this.$emit('remove', item)
      } else {
        this.selected.push(item)
        this.$emit('add', item)
      }
      this.$emit('input', this.selected)
    },

    isSelected (item) {
      return this.selected.indexOf(item) !== -1
    }
  },

  watch: {
    value (value) {
      this.selected = value
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-chip-group {
    display: flex;
    flex-wrap: wrap;
  }
</style>
