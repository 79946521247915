import { NODE_TYPE } from './constants'
import structuredClone from '@ungap/structured-clone'

// GLOBAL FUNCTIONS
export const GET_ITEM_BY_PATH = (items = [], path = [], parentNode = null) => {
  const index = path[0]
  if (!path.length) {
    return { node: null, parent: items, parentNode: parentNode, index: null }
  }
  if (path.length === 1) {
    return { node: items[index], parent: items, parentNode: parentNode, index: index }
  }
  const item = items[index]
  path.splice(0, 1)
  return GET_ITEM_BY_PATH(item.children, path, item)
}

export const SORT_NODES_BY_TYPES = (nodes) => {
  const groups = nodes
    .filter(item => !item.isLeaf)
  const elements = nodes
    .filter(item => item.isLeaf)
  return [...groups, ...elements]
}

export const GET_ITEM_BY_ID = (items = [], id, isLeaf = null) => {
  let result = items.find(item => {
    return isLeaf === null
      ? item.data.id === id
      : item.isLeaf === isLeaf && item.data.id === id
  })
  if (!result) {
    for (const item of items) {
      result = GET_ITEM_BY_ID(item.children, id, isLeaf)
      if (result) {
        break
      }
    }
  }
  return result
}

export const GET_ITEM_BY_ID_X = (items = [], id, isLeaf = null) => {
  let result = items.find(item => {
    return isLeaf === null
      ? item.data.id === id
      : item.isLeaf === isLeaf && item.data.id === id
  })
  if (!result) {
    for (const item of items) {
      result = GET_ITEM_BY_ID_X(item.children, id, isLeaf)
      if (result) {
        break
      }
    }
  } else {
    result = {
      node: result,
      items
    }
  }
  return result
}

export const RECURSIVE_UPDATE_PARENT_NODES = (items, parentId, callback) => {
  if (parentId !== null) {
    const parent = GET_ITEM_BY_ID(items, parentId, false)
    if (parent && callback) {
      callback(parent)
    }
    RECURSIVE_UPDATE_PARENT_NODES(items, parent.data.parentId, callback)
  }
}

export const sortItems = (a, b) => a.data.order - b.data.order

// FOR DEPARTMENTS
export const PREPEAR_DEPARTMENTS_STRUCTURE = (data) => {
  const departments = data.subDepartments
  const departmentItems = []
  for (const item of (departments || [])) {
    const node = GET_DEPARTMENT_NODE(item, false, data.id)
    departmentItems.push(node)
  }
  departmentItems.sort(sortItems)
  const positionItems = []
  for (const item of (data.positions || [])) {
    const node = GET_DEPARTMENT_NODE(item, true, data.id)
    positionItems.push(node)
  }
  positionItems.sort(sortItems)
  return [...departmentItems, ...positionItems]
}

export const GET_DEPARTMENT_NODE = (item, isLeaf = false, parentId = null) => {
  return {
    data: {
      id: item.id || null,
      parentId: parentId,
      name: item.name || null,
      badgeCount: item.badgeCount,
      employeeCount: item.employeeCount,
      allChildEmployeeCount: item.allChildEmployeeCount,
      department: item.department || null,
      order: item.order || 0,
      employees: {
        items: [],
        pagination: null
      },
      badges: {
        items: [],
        pagination: null
      },
      supervisor: item.supervisor,
      errorText: null
    },
    checked: false,
    isExpanded: false,
    isSelected: false,
    isNotAssignedSelected: false,
    isLeaf,
    isEditing: false,
    children: isLeaf
      ? []
      : PREPEAR_DEPARTMENTS_STRUCTURE(item)
  }
}

// FOR LOCATIONS
export const PREPEAR_LOCATIONS_STRUCTURE = (data) => {
  const items = []
  for (const item of data.subLocations) {
    const node = GET_LOCATION_NODE(item, !item.subLocations.length, data.id)
    items.push(node)
  }
  return items.sort(sortItems)
}

export const GET_LOCATION_NODE = (item, isLeaf = false, parentId = null) => {
  return {
    data: {
      id: item.id || null,
      parentId: parentId,
      name: item.name || null,
      allChildEmployeeCount: item.allChildEmployeeCount,
      employeeCount: item.employeeCount,
      location: item.location || null,
      order: item.order || 0,
      employees: [],
      pagination: null,
      errorText: null,
      defaultLanguage: item.defaultLanguage || {}
    },
    checked: false,
    isExpanded: false,
    isSelected: false,
    isNotAssignedSelected: false,
    isEditing: false,
    isLeaf: isLeaf,
    children: isLeaf
      ? []
      : PREPEAR_LOCATIONS_STRUCTURE(item)
  }
}

// FOR SKILLS
const PREPARE_SKILLS_HOOKED = (item, parentId, color) => {
  return {
    data: {
      id: item.props.id || null,
      parentId: parentId,
      ...item.props,
      color: item.props.color || color
    }
  }
}

export const PREPEAR_SKILLS_STRUCTURE = (data) => {
  const items = []
  for (const item of data.children) {
    const node = GET_SKILL_NODE(
      item,
      item.props.typeId !== NODE_TYPE.CATEGORY,
      data.props.id,
      item.props.color
    )
    items.push(node)
  }
  return items
}

export const GET_SKILL_NODE = (item, isLeaf = false, parentId = null, color = null) => {
  let hookedBadge = null
  item = structuredClone(item)
  if (item.props.hookedBadge) {
    hookedBadge = PREPARE_SKILLS_HOOKED(item.props.hookedBadge, parentId, color)
    delete item.props.hookedBadge
  }

  return {
    data: {
      id: item.props.id || null,
      parentId: parentId,
      ...item.props,
      hookedBadge,
      color: item.props.color || color
    },
    checked: false,
    isExpanded: parentId === null,
    isSelected: false,
    isEditing: false,
    isLeaf: isLeaf,
    children: isLeaf
      ? []
      : PREPEAR_SKILLS_STRUCTURE(item)
  }
}
