import Vue from 'vue'
import api from '@/api'
import router from '@/router'
import cookie from '@/util/cookie'
import { TOKEN_COOKIE, TREE_OPTIONS, BUILDER_OPTIONS, EMAIL_REMINDERS_PREFERENCES_OPTIONS } from '@/util/constants'

const SET_APP_LOADED_STATE = 'SET_APP_LOADED_STATE'
const SET_DID_SHOW_ONBOARDING = 'SET_DID_SHOW_ONBOARDING'
const SET_ERRORS = 'SET_ERRORS'
const CLEAR_ERRORS = 'CLEAR_ERRORS'
const SET_USER_INFO = 'SET_USER_INFO'
const SET_CONFIRM_DIALOG = 'SET_CONFIRM_DIALOG'

const SET_POPUP_MESSAGE = 'SET_POPUP_MESSAGE'
const SET_FEEDBACK_MODAL = 'SET_FEEDBACK_MODAL'

const SET_LOGIN_EMAIL = 'SET_LOGIN_EMAIL'
const SET_PASSWORD_RESET_CODE = 'SET_PASSWORD_RESET_CODE'

const SET_LANGUAGES_LIST = 'SET_LANGUAGES_LIST'
const SET_CATEGORIES = 'SET_CATEGORIES'
const SET_CATEGORY = 'SET_CATEGORY'
const DELETE_CATEGORY = 'DELETE_CATEGORY'
const SET_BADGES = 'SET_BADGES'

const SET_IS_BADGE_FILE_UPLOAD_ENABLED = 'SET_IS_BADGE_FILE_UPLOAD_ENABLED'
const SET_IS_SETUP_COMPLETED = 'SET_IS_SETUP_COMPLETED'
const SET_DID_SHOW_ON_BOARDING = 'SET_DID_SHOW_ON_BOARDING'
const SET_IS_OWN_PROFILE_EDITING_PERMITTED = 'SET_IS_OWN_PROFILE_EDITING_PERMITTED'

const SET_TOOLTIP_HIDDEN = 'SET_TOOLTIP_HIDDEN'
const SET_OPEN_WITH_ALT_KEY_PRESSED = 'SET_OPEN_WITH_ALT_KEY_PRESSED'
const SET_IS_DRAG_DROP_MODE = 'SET_IS_DRAG_DROP_MODE'

const SET_INCORRECT_PROVIDER = 'SET_INCORRECT_PROVIDER'
const SET_INCORRECT_PROVIDER_CODE = 'SET_INCORRECT_PROVIDER_CODE'
const SET_IS_THIRD_PARTY_LOGIN_ENABLED = 'SET_IS_THIRD_PARTY_LOGIN_ENABLED'
const SET_IS_SUPER_ADMIN = 'SET_IS_SUPER_ADMIN'
const SET_IS_THIRD_PARTY_LOGOUT_ENABLED = 'SET_IS_THIRD_PARTY_LOGOUT_ENABLED'
const SET_IS_THIRD_PARTY_DEV_LOGIN_ENABLED = 'SET_IS_THIRD_PARTY_DEV_LOGIN_ENABLED'
const SET_THIRD_PARTY_LOGIN_PROVIDER = 'SET_THIRD_PARTY_LOGIN_PROVIDER'

const SHOW_DESIGN_CHOICE = 'SHOW_DESIGN_CHOICE'
const SET_SKILLTREE_VIEW_DEFAULT = 'SET_SKILLTREE_VIEW_DEFAULT'
const SET_BUILDER_VIEW_DEFAULT = 'SET_BUILDER_VIEW_DEFAULT'

const SET_COMPANY_INFO = 'SET_COMPANY_INFO'
const SET_LAST_OPENED_SIDEBAR = 'SET_LAST_OPENED_SIDEBAR'

const SET_EMAIL_REMINDERS_NEWS_PREFERENCE = 'SET_EMAIL_REMINDERS_NEWS_PREFERENCE'
const SET_EMAIL_REMINDERS_SUGGESTIONS_PREFERENCE = 'SET_EMAIL_REMINDERS_SUGGESTIONS_PREFERENCE'
const SET_EMAIL_REMINDERS_VALIDATIONS_PREFERENCE = 'SET_EMAIL_REMINDERS_VALIDATIONS_PREFERENCE'

const SET_EMAIL_REMINDERS_GLOBAL_SETTINGS = 'SET_EMAIL_REMINDERS_GLOBAL_SETTINGS'

const TRIAL_PERIOD = 'TRIAL_PERIOD'
const SHOW_TUTORIAL = 'SHOW_TUTORIAL'
const SHOW_TRIAL_PERIOD = 'SHOW_TRIAL_PERIOD'
const SHOW_TUTORIAL_AFTER_LOGIN = 'SHOW_TUTORIAL_AFTER_LOGIN'

export default {
  namespaced: true,

  state () {
    return {
      isAppLoaded: false,
      popupMessage: null,
      errors: [],
      userInfo: {},
      confirmDialog: {},
      loginEmail: '',
      passwordResetCode: '',
      languages: [],
      categories: [],
      badges: [],
      settings: {
        global: {
          isBadgeFileUploadEnabled: false,
          isSetupCompleted: false,
          isOwnProfileEditingPermitted: false,
          isThirdPartyLoginEnabled: false,
          isThirdPartyLogoutEnabled: false,
          isThirdPartyDevLoginEnabled: false,
          thirdPartyLoginProvider: null
        },
        employee: {
          isSetNewPasswordNeeded: false,
          didShowOnBoarding: true,
          isSuperAdmin: false
        },
        emailReminders: {
          isNewsEmailEnabled: false,
          isSuggestionsEmailEnabled: false,
          isValidationsEmailEnabled: false,
          emailNewsInterval: null,
          emailSuggestionsInterval: null,
          emailValidationsInterval: null
        }
      },
      hiddenToopTip: false,
      openWithAltKeyPressed: false,
      incorrectProvider: false,
      incorrectProviderCode: 0,
      showFeedbackModal: false,
      disableLookSelection: false,
      skilltreeDefaultView: null,
      builderDefaultView: null,
      companyInfo: null,
      lastOpenedSidebar: null,
      preferences: {
        email: {
          reminders: {
            news: null,
            suggestions: null,
            validations: null
          }
        }
      },
      trialPeriod: null,
      showTutorial: false,
      showTrialPeriod: true,
      showTutorialAfterLogin: true
    }
  },

  getters: {
    // TODO: left here
    version: () => process.env.PACKAGE_VERSION,
    buildHash: () => process.env.BUILD_HASH,
    isAppLoaded: (state) => state.isAppLoaded,
    didShowOnBoarding: (state) => state.settings.employee.didShowOnBoarding,
    isSuperAdmin: (state) => state.settings.employee.isSuperAdmin,
    isSetupCompleted: (state) => state.settings.global.isSetupCompleted,
    isDragDropMode: (state) => state.isDragDropMode,
    isBadgeFileUploadEnabled: (state) => state.settings.global.isBadgeFileUploadEnabled,
    isOwnProfileEditingPermitted: (state) => state.settings.global.isOwnProfileEditingPermitted,
    languages: (state) => state.languages,
    lastOpenedSidebar: (state) => state.lastOpenedSidebar,

    // TODO: move to MODALS store module
    confirmDialog: (state) => state.confirmDialog,
    popupMessage: (state) => state.popupMessage,
    showFeedbackModal: (state) => state.showFeedbackModal,

    // TODO: move to errors store module
    errors: (state) => state.errors,
    hasAnyError: (state) => !!state.errors.length,

    // TODO: move to profile store module
    userInfo: (state) => state.userInfo,

    // TODO: move to AUTH store module
    loginEmail: (state) => state.loginEmail,
    passwordResetCode: (state) => state.passwordResetCode,

    // TODO: move somewhere
    categories: (state) => state.categories,
    badges: (state) => state.badges,
    hiddenToopTip: (state) => state.hiddenToopTip,
    openWithAltKeyPressed: (state) => state.openWithAltKeyPressed,

    incorrectProvider: (state) => state.incorrectProvider,
    incorrectProviderCode: (state) => state.incorrectProviderCode,
    isThirdPartyLoginEnabled: (state) => state.settings.global.isThirdPartyLoginEnabled,
    isThirdPartyLogoutEnabled: (state) => state.settings.global.isThirdPartyLogoutEnabled,
    isThirdPartyDevLoginEnabled: (state) => state.settings.global.isThirdPartyDevLoginEnabled,
    thirdPartyLoginProvider: (state) => state.settings.global.thirdPartyLoginProvider,
    isDesignDisableLookSelection: (state) => state.disableLookSelection,
    isSkilltreeViewDefault: (state) => state.skilltreeDefaultView,
    isBuilderViewDefault: (state) => state.builderDefaultView,
    isCompanyInfo: (state) => state.companyInfo,

    emailRemindersNewsPreference: (state) => state.preferences.email.reminders.news,
    emailRemindersSuggestionsPreference: (state) => state.preferences.email.reminders.suggestions,
    emailRemindersValidationsPreference: (state) => state.preferences.email.reminders.validations,
    emailRemindersSettings: (state) => state.settings.emailReminders,
    trialPeriod: (state) => state.trialPeriod,
    showTutorial: (state) => state.showTutorial,
    showTrialPeriod: (state) => state.showTrialPeriod,
    showTutorialAfterLogin: (state) => state.showTutorialAfterLogin
  },

  mutations: {
    [SET_APP_LOADED_STATE] (state, loaded) {
      state.isAppLoaded = loaded
    },

    [SET_ERRORS] (state, errors) {
      state.errors = [...state.errors, ...errors]
    },

    [CLEAR_ERRORS] (state, errors) {
      state.errors = errors
    },

    [SET_USER_INFO] (state, info) {
      state.userInfo = info
    },

    [SET_CONFIRM_DIALOG] (state, dialogOpts) {
      state.confirmDialog = dialogOpts
    },

    [SET_LOGIN_EMAIL] (state, email) {
      state.loginEmail = email
    },

    [SET_PASSWORD_RESET_CODE] (state, code) {
      state.passwordResetCode = code
    },

    [SET_LANGUAGES_LIST] (state, list) {
      state.languages = list
    },

    [SET_CATEGORIES] (state, listOfCategories) {
      state.categories = listOfCategories
    },

    [SET_CATEGORY] (state, category) {
      state.categories.push(category)
    },

    [DELETE_CATEGORY] (state, category) {
      state.categories = state.categories.filter(c => c.id !== category.id)
    },

    [SET_BADGES] (state, listOfbadges) {
      state.badges = listOfbadges
    },

    [SET_IS_BADGE_FILE_UPLOAD_ENABLED] (state, value) {
      state.settings.global.isBadgeFileUploadEnabled = value
    },

    [SET_IS_SETUP_COMPLETED] (state, value) {
      state.settings.global.isSetupCompleted = value
    },

    [SET_DID_SHOW_ON_BOARDING] (state, value) {
      state.settings.employee.didShowOnBoarding = value
    },

    [SET_POPUP_MESSAGE] (state, value) {
      state.popupMessage = value
    },

    [SET_IS_OWN_PROFILE_EDITING_PERMITTED] (state, value) {
      state.settings.global.isOwnProfileEditingPermitted = value
    },

    [SET_TOOLTIP_HIDDEN] (state, value) {
      state.hiddenToopTip = value
    },

    [SET_IS_THIRD_PARTY_LOGIN_ENABLED] (state, value) {
      state.settings.global.isThirdPartyLoginEnabled = value
    },

    [SET_OPEN_WITH_ALT_KEY_PRESSED] (state, value) {
      state.openWithAltKeyPressed = value
    },

    [SET_IS_DRAG_DROP_MODE] (state, value) {
      state.isDragDropMode = value
    },

    [SET_IS_SUPER_ADMIN] (state, value) {
      state.settings.employee.isSuperAdmin = !!value
    },

    [SET_INCORRECT_PROVIDER] (state, value) {
      state.incorrectProvider = value
    },

    [SET_INCORRECT_PROVIDER_CODE] (state, value) {
      state.incorrectProviderCode = value
    },

    [SET_IS_THIRD_PARTY_LOGOUT_ENABLED] (state, value) {
      state.settings.global.isThirdPartyLogoutEnabled = value
    },

    [SET_THIRD_PARTY_LOGIN_PROVIDER] (state, value) {
      state.settings.global.thirdPartyLoginProvider = value
    },

    [SET_IS_THIRD_PARTY_DEV_LOGIN_ENABLED] (state, value) {
      state.settings.global.isThirdPartyDevLoginEnabled = value
    },

    [SET_FEEDBACK_MODAL] (state, value) {
      state.showFeedbackModal = value
    },

    [SHOW_DESIGN_CHOICE] (state, value) {
      state.disableLookSelection = value
    },

    [SET_SKILLTREE_VIEW_DEFAULT] (state, value) {
      state.skilltreeDefaultView = value
    },

    [SET_BUILDER_VIEW_DEFAULT] (state, value) {
      state.builderDefaultView = value
    },

    [SET_COMPANY_INFO] (state, value) {
      state.companyInfo = value
    },

    [SET_EMAIL_REMINDERS_NEWS_PREFERENCE] (state, value) {
      state.preferences.email.reminders.news = value
    },

    [SET_EMAIL_REMINDERS_SUGGESTIONS_PREFERENCE] (state, value) {
      state.preferences.email.reminders.suggestions = value
    },

    [SET_EMAIL_REMINDERS_VALIDATIONS_PREFERENCE] (state, value) {
      state.preferences.email.reminders.validations = value
    },

    [SET_EMAIL_REMINDERS_GLOBAL_SETTINGS] (state, obj) {
      state.settings.emailReminders = obj
    },

    [SET_LAST_OPENED_SIDEBAR] (state, value) {
      state.lastOpenedSidebar = value
    },

    [TRIAL_PERIOD] (state, value) {
      state.trialPeriod = value
    },

    [SHOW_TUTORIAL] (state, value) {
      state.showTutorial = value
    },

    [SHOW_TRIAL_PERIOD] (state, value) {
      state.showTrialPeriod = value
    },

    [SHOW_TUTORIAL_AFTER_LOGIN] (state, value) {
      state.showTutorialAfterLogin = value
    }
  },

  actions: {
    async initApp (context) {
      let lang = 'en'

      if (!cookie.hasItem(TOKEN_COOKIE)) {
        let { data, error } = await api.loadTranslate(lang)
        if (!error) {
          Vue.i18n.add(lang, data)
          Vue.i18n.set(lang)
        }
      }

      if (cookie.hasItem(TOKEN_COOKIE)) {
        const { data, error } = await api.loginSetup()
        if (!error) {
          data.currentEmployee.isBadgeFileUploadEnabled = data.isBadgeFileUploadEnabled

          // SET LANG
          const lang = data.currentEmployee.language.id
          let langRes = await api.loadTranslate(lang)
          if (!langRes.error) {
            Vue.i18n.add(lang, langRes.data)
            Vue.i18n.set(lang)
          }

          if (data.currentEmployee) {
            context.commit(SET_IS_SUPER_ADMIN, data.currentEmployee.isSuperAdmin)
          }
          if (data.settings) {
            const { settings } = data
            // SET GLOBAL
            context.commit(SET_IS_BADGE_FILE_UPLOAD_ENABLED, settings.global.isBadgeFileUploadEnabled)
            context.commit(SET_IS_SETUP_COMPLETED, settings.global.isSetupComplete)
            context.commit(SET_IS_OWN_PROFILE_EDITING_PERMITTED, settings.global.isOwnProfileEditingPermitted)
            context.commit(SET_IS_THIRD_PARTY_LOGIN_ENABLED, settings.global.isThirdPartyLoginEnabled)
            context.commit(SET_THIRD_PARTY_LOGIN_PROVIDER, settings.global.thirdPartyLoginProvider)

            // SET TRIAL PERIOD
            context.commit(TRIAL_PERIOD, settings.demo?.expiresAt)
            context.commit(SHOW_TUTORIAL_AFTER_LOGIN, !!settings.demo?.showTutorialAfterLogin)

            // SET CURRENT
            if (settings.employee) {
              context.commit(SET_DID_SHOW_ON_BOARDING, settings.employee.didShowOnBoarding)
            }

            // IF NEW PASSWORD NEEDED
            if (settings.employee.isSetNewPasswordNeeded) {
              await api.logout()
              router.push('/auth/forget-password')
            }
          }

          context.commit(SET_USER_INFO, data.currentEmployee)
          context.commit('permissions/SET_CURRENT_PERMISSIONS', data.permissions, { root: true })
        }
      }
      context.commit(SET_APP_LOADED_STATE, true)
      if (!window.location.pathname.includes('/share')) {
        context.dispatch('getDisableLookSelection')
      }
    },

    hideOnboarding (context) {
      context.commit(SET_DID_SHOW_ONBOARDING, true)
    },

    setErrors (context, errors) {
      context.commit(SET_ERRORS, errors)
    },

    hasError (context, filed) {
      return !!context.getters.errors.find(err => err.field === filed)
    },

    clearErrors (context) {
      context.commit(CLEAR_ERRORS, [])
    },

    toggleFeedbackModal (context, param = false) {
      context.commit(SET_FEEDBACK_MODAL, param)
    },

    setLoginEmail (context, email) {
      context.commit(SET_LOGIN_EMAIL, email)
    },

    setPasswordResetCode (context, code) {
      context.commit(SET_PASSWORD_RESET_CODE, code)
    },

    async updateUserInfo (context, payload) {
      const { data } = await api.employees.updateById(context.getters.userInfo.id, payload)
      if (!data) return false
      context.commit(SET_USER_INFO, data)
      return true
    },

    showConfirmDialog (context, dialogOpts) {
      dialogOpts.show = true
      context.commit(SET_TOOLTIP_HIDDEN, true)
      context.commit(SET_CONFIRM_DIALOG, dialogOpts)
    },

    hideConfirmDialog (context) {
      context.commit(SET_TOOLTIP_HIDDEN, false)
      context.commit(SET_CONFIRM_DIALOG, {})
    },

    async loadLanguages (context) {
      const { data } = await api.getLanguages()
      context.commit(SET_LANGUAGES_LIST, data)
      return data
    },

    async loadCategories (context) {
      const { data } = await api.loadCategories()
      context.commit(SET_CATEGORIES, data)
    },

    async getBadges (context) {
      const { data } = await api.loadBadges()
      context.commit(SET_BADGES, data)
      return true
    },

    setPopupMessage (context, value) {
      context.commit(SET_POPUP_MESSAGE, value)
    },

    setToolTipHidden (context, value) {
      context.commit(SET_TOOLTIP_HIDDEN, value)
    },

    setOpenWithAltKeyPressed (context, value) {
      context.commit(SET_OPEN_WITH_ALT_KEY_PRESSED, value)
    },

    setIsDragDropMode (context, value) {
      context.commit(SET_IS_DRAG_DROP_MODE, value)
    },

    setIncorrectProvider (context, value) {
      context.commit(SET_INCORRECT_PROVIDER, value)
    },

    setIncorrectProviderCode (context, value) {
      context.commit(SET_INCORRECT_PROVIDER_CODE, value)
    },

    logoutHandler (context) {
      context.commit(SET_POPUP_MESSAGE, null)
      context.dispatch('trees/setTreeIsLoaded', false, { root: true })
    },

    setIsThirdPartyLoginEnabled (context, value) {
      context.commit(SET_IS_THIRD_PARTY_LOGIN_ENABLED, value)
    },

    setisThirdPartyLogoutEnabled (context, value) {
      context.commit(SET_IS_THIRD_PARTY_LOGOUT_ENABLED, value)
    },

    setIsThirdPartyDevLoginEnabled (context, value) {
      context.commit(SET_IS_THIRD_PARTY_DEV_LOGIN_ENABLED, value)
    },

    async getDisableLookSelection ({ commit }) {
      const { data } = await api.settings.disableLookSelection.restore()
      commit(SHOW_DESIGN_CHOICE, typeof data === 'object' ? data.value : data)
    },

    async setDisableLookSelection ({ commit, state }, value) {
      if (value !== state.disableLookSelection) {
        await api.settings.disableLookSelection.store({ value: value })
        commit(SHOW_DESIGN_CHOICE, value)
      }
    },

    async getSkilltreeDefaultLook ({ commit }) {
      const { data } = await api.settings.defaultLook.getDefaultSkilltreeLook()
      const activeLook = TREE_OPTIONS.find(el => el.id === data)
      commit(SET_SKILLTREE_VIEW_DEFAULT, activeLook)
    },

    async setSkillTreeDefaultLook ({ commit, state }, data) {
      if (data && data.id !== state.skilltreeDefaultView?.id) {
        await api.settings.defaultLook.setDefaultSkilltreeLook({ value: data.id })
        commit(SET_SKILLTREE_VIEW_DEFAULT, data)
      }
    },

    async getBuilderDefaultLook ({ commit }) {
      const { data } = await api.settings.defaultLook.getDefaultBuilderLook()
      const activeLook = BUILDER_OPTIONS.find(el => el.id === data)
      commit(SET_BUILDER_VIEW_DEFAULT, activeLook)
    },

    async setBuilderDefaultLook ({ commit, state }, data) {
      if (data && data.id !== state.builderDefaultView?.id) {
        await api.settings.defaultLook.setDefaultBuilderLook({ value: data.id })
        commit(SET_BUILDER_VIEW_DEFAULT, data)
      }
    },

    async getCompanyInfo ({ commit }) {
      const { data } = await api.settings.companyInfo.restore()
      commit(SET_COMPANY_INFO, data)
    },

    async setCompanyInfo ({ commit }, payload) {
      await api.settings.companyInfo.store(payload)
    },

    async getEmailRemindersNewsPreference ({ commit }) {
      const { data } = await api.preferences.emailRemindersNews.restore()
      const preference = EMAIL_REMINDERS_PREFERENCES_OPTIONS.find(el => el.value === data.value)
      commit(SET_EMAIL_REMINDERS_NEWS_PREFERENCE, preference)
    },

    async getEmailRemindersSuggestionsPreference ({ commit }) {
      const { data } = await api.preferences.emailRemindersSuggestions.restore()
      const preference = EMAIL_REMINDERS_PREFERENCES_OPTIONS.find(el => el.value === data.value)
      commit(SET_EMAIL_REMINDERS_SUGGESTIONS_PREFERENCE, preference)
    },

    async getEmailRemindersValidationsPreference ({ commit }) {
      const { data } = await api.preferences.emailRemindersValidations.restore()
      const preference = EMAIL_REMINDERS_PREFERENCES_OPTIONS.find(el => el.value === data.value)
      commit(SET_EMAIL_REMINDERS_VALIDATIONS_PREFERENCE, preference)
    },

    async setEmailRemindersNewsPreference ({ commit, state }, data) {
      if (data && data.value !== state.preferences.email.reminders.news?.value) {
        await api.preferences.emailRemindersNews.store({ value: data.value })
        commit(SET_EMAIL_REMINDERS_NEWS_PREFERENCE, data)
      }
    },

    async setEmailRemindersSuggestionsPreference ({ commit, state }, data) {
      if (data && data.value !== state.preferences.email.reminders.suggestions?.value) {
        await api.preferences.emailRemindersSuggestions.store({ value: data.value })
        commit(SET_EMAIL_REMINDERS_SUGGESTIONS_PREFERENCE, data)
      }
    },

    async setEmailRemindersValidationsPreference ({ commit, state }, data) {
      if (data && data.value !== state.preferences.email.reminders.validations?.value) {
        await api.preferences.emailRemindersValidations.store({ value: data.value })
        commit(SET_EMAIL_REMINDERS_VALIDATIONS_PREFERENCE, data)
      }
    },

    async getEmailRemembersSettings ({ commit }) {
      const { data } = await api.settings.emailReminders.restore()
      commit(SET_EMAIL_REMINDERS_GLOBAL_SETTINGS, data)
    },

    async setEmailRemembersSettings ({ commit, getters }, { nameKey, value }) {
      if (value !== null && getters.emailRemindersSettings[nameKey] !== value.value) {
        const newData = { ...getters.emailRemindersSettings, [nameKey]: typeof value === 'object' ? value.value : value }
        await api.settings.emailReminders.store(newData)
        commit(SET_EMAIL_REMINDERS_GLOBAL_SETTINGS, newData)
      }
    },

    async updateShowTutorialAfterLogin ({ commit, getters }, value) {
      await api.preferences.demo.updateShowTutorialAfterLogin(value)
      commit(SHOW_TUTORIAL_AFTER_LOGIN, value)
    }
  }
}
