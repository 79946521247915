import { execute } from '@/api/utils.js'
import { GET, POST, DELETE } from '@/api/types.js'

const ENDPOINT = 'permissions'

export default {
  list () {
    return execute(GET, ENDPOINT)
  },

  tree () {
    return execute(GET, `${ENDPOINT}/tree`)
  },

  getCurrent () {
    return execute(GET, `${ENDPOINT}/current`)
  },

  linkRole (id, roleId) {
    return execute(POST, `${ENDPOINT}/${id}/link?toRoleId=${roleId}`)
  },

  unlinkRole (id, roleId) {
    return execute(DELETE, `${ENDPOINT}/${id}/unlink?fromRoleId=${roleId}`)
  }
}
