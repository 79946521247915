import { execute } from '../utils'
import helpers from '@/util/helpers'

const ENDPOINT = 'insights'
const GOALS_ENDPOINT = 'insights/goals'

export default {
  get (filters = {}) {
    return execute('GET', helpers.addQueryParams(ENDPOINT, filters))
  },

  getGoals (filters = {}) {
    return execute('GET', helpers.addQueryParams(GOALS_ENDPOINT, filters))
  },

  getStats (filters = {}) {
    return execute('GET', helpers.addQueryParams(`${ENDPOINT}/stats`, filters))
  },

  getGoalsStats (filters = {}) {
    return execute('GET', helpers.addQueryParams(`${GOALS_ENDPOINT}/stats`, filters))
  },

  getStatsByBadgeId (id, filters) {
    return execute('GET', helpers.addQueryParams(`${ENDPOINT}/stats/badge/${id}`, filters))
  },

  getGoalsStatsByBadgeId (id, filters) {
    return execute('GET', helpers.addQueryParams(`${GOALS_ENDPOINT}/stats/badge/${id}`, filters))
  },

  getEmployeesByBadgeId (id, filters) {
    return execute('GET', helpers.addQueryParams(`${ENDPOINT}/stats/badge/${id}/employees`, filters))
  },

  getEmployeesByBadgeIdAll (id, filters) {
    return execute('GET', helpers.addQueryParams(`${ENDPOINT}/stats/badge/${id}/employees/all`, filters))
  },

  getGoalsEmployeesByBadgeId (id, filters) {
    return execute('GET', helpers.addQueryParams(`${GOALS_ENDPOINT}/stats/badge/${id}/employees`), filters)
  },

  getGoalsEmployeesByBadgeIdAll (id, filters) {
    return execute('GET', helpers.addQueryParams(`${GOALS_ENDPOINT}/stats/badge/${id}/employees/all`), filters)
  }

}
