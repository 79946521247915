<template lang="pug">
  div
    .portlet__head.justify-content-center
      .portlet__head-label
        .portlet__head-title
          h3.text-center {{ 'pages.auth.sign_in'  | translate}}
          h5.text-center {{ 'pages.auth.please_enter_your_password'  | translate}}
    .portlet__body
      form.kt-form(@submit.prevent="loginHadler")
        .form-group(:class="{ 'is-invalid': isPasswordInvalid }")
          input.form-control(
            v-focus
            type="password"
            autocomplete="current-password"
            :placeholder="'pages.auth.password' | translate "
            v-model="password")
          span.form-text.invalid(name="error-password") {{ 'password' | errorText }}
          v-checkbox.keep-logged(
            :label="'pages.auth.keep_logged' | translate"
            :value="keepLogged"
            @input="$emit('change-loggin', $event)")
        .form-controls
          router-link(to="/forgot") {{ 'pages.auth.forgot_password'  | translate}}?
          .text-right
            v-btn(
              name="sign-in"
              :loading="loading"
              data-size="s"
              data-style="slide-up") {{'pages.auth.sign_in' | translate}}
    .under-modal-action-btns
      span.active-text(@click="goBack") {{'ui.buttons.go_back' | translate}}
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AuthLoginPassword',

  mounted () {
    this.email = this.loginEmail
  },

  destroyed () {
    this.clearErrors()
  },

  data: () => ({
    email: '',
    password: '',
    ready: false
  }),

  props: {
    step: Number,
    loading: Boolean,
    login: Function,
    keepLogged: Boolean
  },

  methods: {
    ...mapActions('app',
      ['clearErrors']),

    async loginHadler () {
      await this.login(this.email, this.password, this.keepLogged)
    },

    goBack () {
      this.$emit('go-back')
    }
  },

  computed: {
    ...mapGetters('app', ['loginEmail', 'errors']),

    isPasswordInvalid () {
      return this.errors.find(item => item.field === 'password')
    }
  }
}
</script>

<style lang="scss" scoped>
.keep-logged {
  margin-top: 24px;
}
</style>
<style lang="scss">
.keep-logged {
  label {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
  }
}
</style>
