<template lang="pug">
  .chip-group(v-if="selected.length")
    template(v-if="!isAllSelected")
      template(v-if="!showUnselected")
        .chip(v-for="item in selected" :key="item.id"
          @click="selectItem(item, true)")
          | {{ item[trackBy] }}
        span.label in {{ group[trackBy] }}
    template(v-else)
      .chip(@click="selectItem(group, true)") {{ group[trackBy] }}
    template(v-if="showUnselected")
      .chip(@click="selectItem(group, true)") {{ group[trackBy] }}
      span.label  {{ 'ui.labels._except' | translate }}
      .chip(v-for="item in unselected" :key="item.id"
        @click="selectItem(item, false)")
        | {{ item[trackBy] }}
    .splitter
</template>

<script>
export default {
  name: 'VSelectSelectedChipsGroup',

  props: {
    group: Object,
    trackBy: String,
    selected: Array,
    unselected: Array
  },

  methods: {
    selectItem (item, isSelected) {
      this.$emit('select', { item: item, isSelected: isSelected })
    }
  },

  computed: {
    allCount () {
      return this.selected.length + this.unselected.length
    },

    isAllSelected () {
      return this.selected.length === this.allCount
    },

    showUnselected () {
      return this.unselected.length && this.selected.length / this.allCount >= 0.8
    }
  }
}
</script>

<style lang="scss">
  .chip-group {
    display: inline-flex;

    .splitter {
      height: 24px;
      margin-right: 5px;
      margin-bottom: 5px;
      border-right: 1px solid #ddd;
    }

    &:last-child {
      .splitter {
        border: none;
      }
    }
  }
</style>
