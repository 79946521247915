<template functional>
  <svg :class="[data.class, data.staticClass]" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0ZM11.87 11.25C12.04 11.42 12.04 11.7 11.87 11.87C11.7 12.04 11.42 12.04 11.25 11.87L8 8.62L4.75 11.87C4.58 12.04 4.3 12.04 4.13 11.87C3.96 11.7 3.96 11.42 4.13 11.25L7.38 8L4.13 4.75C3.96 4.58 3.96 4.3 4.13 4.13C4.3 3.96 4.58 3.96 4.75 4.13L8 7.38L11.25 4.13C11.42 3.96 11.7 3.96 11.87 4.13C12.04 4.3 12.04 4.58 11.87 4.75L8.62 8L11.87 11.25Z" fill="#A0AAC3"/>
  </svg>
</template>

<script>
export default {
  name: 'VInputSearch_CloseIcon'
}
</script>

<style scoped></style>
