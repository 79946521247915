<template lang="pug">
  .v-radio-group
    slot
</template>

<script>
export default {
  name: 'VRadioGroup',

  props: {
    list: Boolean,
    value: [String, Number]
  },

  data: () => ({
    items: []
  }),

  mounted () {
    this.items = this.list
      ? this.$slots.default
          .map(item => item.componentInstance.$children[0])
      : this.$slots.default
          .map(item => item.children[0])
          .filter(item => item.tag !== 'div')
          .map(item => item.componentInstance)
    this.items.map(el => el.$on('select', this.onRadioSelect))
    const activeItem = this.items
      .find(item => item.id === this.value)
    if (activeItem) {
      activeItem.isSelected = true
    }
  },

  methods: {
    onRadioSelect (id) {
      this.items.forEach(radio => {
        radio.isSelected = radio.id === id
      })
      this.$emit('input', id)
    }
  }
}
</script>

<style>
</style>
