import TWEEN from '@tweenjs/tween.js'
import { TREE_SCALE } from '@/util/constants.js'

const SET_SVGPANZOOM = 'SET_SVGPANZOOM'
const SET_TREE_OFFSET = 'SET_TREE_OFFSET'

const ADD_TREE_NAVIGATE_ITEM = 'ADD_TREE_NAVIGATE_ITEM'
const REMOVE_TREE_NAVIGATE_ITEM = 'REMOVE_TREE_NAVIGATE_ITEM'

export default {
  namespaced: true,

  state () {
    return {
      svgpanzoom: null,
      treeOffset: { x: 0, y: 0 },
      treeNavigateStack: []
    }
  },

  getters: {
    lastNavigateItem: (state) => {
      if (state.treeNavigateStack.length) {
        return state.treeNavigateStack[state.treeNavigateStack.length - 1]
      }
      return null
    }
  },

  mutations: {
    [SET_SVGPANZOOM] (state, svgpanzoom) {
      state.svgpanzoom = svgpanzoom
    },

    [SET_TREE_OFFSET] (state, offset) {
      state.treeOffset.x = offset.x
      state.treeOffset.y = offset.y
    },

    [ADD_TREE_NAVIGATE_ITEM] (state, item) {
      state.treeNavigateStack.push(item)
    },

    [REMOVE_TREE_NAVIGATE_ITEM] (state, item) {
      const index = state
        .treeNavigateStack
        .indexOf(item)
      if (index !== -1) {
        state.treeNavigateStack.splice(index, 1)
      }
    }
  },

  actions: {
    initSvgtree (context, svgpanzoom) {
      context.commit(SET_SVGPANZOOM, svgpanzoom)
    },

    zoomToNode (context, { node, beforeZoom, afterZoom }) {
      const pos = node.props.pos
      const sizes = context.state.svgpanzoom.getSizes()
      const centerX = sizes.width / 2
      const centerY = sizes.height / 2

      const offset = {
        x: centerX - pos.x * sizes.realZoom * TREE_SCALE,
        y: centerY - (pos.y * sizes.realZoom * TREE_SCALE)
      }

      const coords = {
        x: context.state.treeOffset.x || centerX,
        y: context.state.treeOffset.y || centerY
      }

      const params = {
        from: coords,
        to: offset
      }

      context.commit(ADD_TREE_NAVIGATE_ITEM, params)

      context.dispatch('zoomByParams', {
        params: params,
        beforeZoom: beforeZoom,
        afterZoom: afterZoom ? () => {
          const payload = {
            node: node,
            pos: { x: centerX, y: centerY }
          }
          afterZoom(payload)
        } : null
      })
    },

    zoomByParams (context, { params, beforeZoom, afterZoom }) {
      if (beforeZoom) {
        beforeZoom()
      }

      let zoomTime = 800
      if (Math.floor(params.from.x) === Math.floor(params.to.x) &&
        Math.floor(params.from.y) === Math.floor(params.to.y)) {
        zoomTime = 0
      }

      const tween = new TWEEN.Tween(params.from)
        .to(params.to, zoomTime)
        .easing(TWEEN.Easing.Cubic.Out)
        .onUpdate(() => {
          context.state.svgpanzoom.pan(params.from)
        })
        .onComplete(() => {
          const transition = { zoom: context.state.svgpanzoom.getZoom() }
          const tween = new TWEEN.Tween(transition)
          tween.to({ zoom: 1.05 }, 600)
            .onUpdate(() => {
              context.state.svgpanzoom.zoom(transition.zoom)
            })
            .onComplete(() => {
              if (afterZoom) {
                afterZoom()
              }
            })
            .start()
        })
      tween.start()
    },

    undoZoomToNode (context, { beforeZoom, afterZoom }) {
      // TODO: finish undo zoom logic
      const lastItem = context.getters.lastNavigateItem
      if (lastItem) {
        const params = {
          from: lastItem.to,
          to: lastItem.from
        }
        context.dispatch('zoomByParams', {
          params: params,
          beforeZoom: beforeZoom,
          afterZoom: afterZoom
        })
        context.commit(REMOVE_TREE_NAVIGATE_ITEM, lastItem)
      }
    },

    setTreeOffset (context, offset) {
      context.commit(SET_TREE_OFFSET, offset)
    }
  }
}
