const SET_TOP_LAYER_EL = 'SET_TOP_LAYER_EL'

const MOVE_ELEMENT_TO_TOP_LAYER = 'MOVE_ELEMENT_TO_TOP_LAYER'
const RESTORE_ELEMENT_FROM_TOP_LAYER = 'RESTORE_ELEMENT_FROM_TOP_LAYER'
const RESTORE_ALL_ELEMENTS_FROM_TOP_LAYER = 'RESTORE_ALL_ELEMENTS_FROM_TOP_LAYER'

export default {
  namespaced: true,

  state () {
    return {
      topLayerEl: null,
      topLayerStack: []
    }
  },

  getters: {
  },

  mutations: {
    [SET_TOP_LAYER_EL] (state, el) {
      state.topLayerEl = el
    },

    [MOVE_ELEMENT_TO_TOP_LAYER] (state, item) {
      state.topLayerEl.appendChild(item.el)
      state.topLayerStack.push(item)
    },

    [RESTORE_ELEMENT_FROM_TOP_LAYER] (state, el) {
      const item = state.topLayerStack
        .find(item => item.el === el)
      if (item) {
        if (state.topLayerEl.contains(el)) {
          state.topLayerEl.removeChild(el)
        }
        item.parent.appendChild(el)
        const index = state.topLayerStack.indexOf(item)
        state.topLayerStack.splice(index, 1)
      }
    },

    [RESTORE_ALL_ELEMENTS_FROM_TOP_LAYER] (state) {
      for (const item of state.topLayerStack) {
        if (state.topLayerEl.contains(item.el)) {
          state.topLayerEl.removeChild(item.el)
        }
        item.parent.appendChild(item.el)
      }
    }
  },

  actions: {
    setTopLayerEl (context, el) {
      context.commit(SET_TOP_LAYER_EL, el)
    },

    moveElementToTopLayer (context, el) {
      const item = {
        parent: el.parentNode,
        el: el.parentNode.removeChild(el)
      }
      context.commit(MOVE_ELEMENT_TO_TOP_LAYER, item)
    },

    restoreElementFromTopLayer (context, el) {
      context.commit(RESTORE_ELEMENT_FROM_TOP_LAYER, el)
    },

    restoreAllElementsFromTopLayer (context) {
      context.commit(RESTORE_ALL_ELEMENTS_FROM_TOP_LAYER)
    }
  }
}
