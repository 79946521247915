<template lang="pug">
 v-dialog(
    width="500"
    :value="showDebugModal")
    v-card
      v-card-head
        v-card-head-label
          h2.modal-title {{ 'Debug menu' | translate }}
        v-card-head-toolbar
          v-btn(
            icon
            gray
            @click="closeDebug") x
      v-card-content
          v-switch(
            icon
            outline
            block
            label="Translation Key Mode"
            label-position="left"
            :value="options.translationKeyMode"
            @input="changeTranslationKeyMode")

          .form-group
            label Debug Token
            input.form-control(v-model="debugToken" type='text' @blur="onBlurDebugToken")

          .form-group(style="margin-bottom:0px")
            label Endpoint URL
            input.form-control(v-model="endpointUrl" :disabled="!!instanceName"  type='text' @blur="onBlurEndpointUrl")
            span(v-if="instanceName" style="font-size:14px") {{ 'infotext.please_disconnect_from_instance_msg' | translate}}
      v-card-actions
        v-btn(@click="closeDebug") {{ 'ui.buttons.close' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import UiModal from '@/components/ui/Modal'

export default {
  name: 'DebugModal',

  components: {
    UiModal
  },

  data: () => ({
    options: {},
    debugToken: '',
    endpointUrl: '',
    instanceName: ''
  }),

  mounted () {
    this.init()
  },

  methods: {
    ...mapActions('debug', [
      'setShowDebugModal',
      'setDebugOptions'
    ]),

    init () {
      this.options = this.debugOptions
      this.debugToken = localStorage.getItem('debugToken') || ''
      this.endpointUrl = localStorage.getItem('endpointUrl') || ''
      this.instanceName = localStorage.getItem('instanceName') || ''
    },

    closeDebug (param) {
      this.setShowDebugModal(false)
    },

    changeTranslationKeyMode () {
      this.options.translationKeyMode = !this.options.translationKeyMode
      this.setDebugOptions(this.options)
      if (this.options.translationKeyMode) {
        Vue.i18n.fallback('')
        Vue.i18n.set('keys')
      } else {
        Vue.i18n.fallback('translations')
        Vue.i18n.set((this.userInfo.language && this.userInfo.language.id) || 'en')
      }
    },

    onBlurDebugToken () {
      if (!this.debugToken) {
        localStorage.removeItem('debugToken')
      } else {
        localStorage.setItem('debugToken', this.debugToken)
      }
    },

    onBlurEndpointUrl () {
      if (!this.endpointUrl) {
        localStorage.removeItem('endpointUrl')
      } else {
        localStorage.setItem('endpointUrl', this.endpointUrl)
      }
    }
  },

  computed: {
    ...mapGetters('debug', [
      'showDebugModal',
      'debugOptions'
    ]),
    ...mapGetters('app', [
      'userInfo',
      'languages'
    ])

  },

  watch: {
    showDebugModal () {
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
