<template lang="pug">
  g(ref="nodeLabel")
    circle(v-if="isNewBadge" :cx="labelX - 6" :cy="labelY + 11" r="2" fill="#F68A37")
    text(
      v-if="!showEditField"
      :x="labelX"
      :y="labelY"
      :font-size="fontSize"
      :class="`depth-${depth}`"
      v-html="labelText")
    foreignObject(
      v-else
      :x="inputX"
      :y="inputY"
      width="270"
      height="150")
      input.name-filed(
        ref="textInput"
        type="text"
        :value="titleValue"
        @mousedown.stop
        @mouseup.stop
        @input="handleInput"
        @keypress.enter.prevent="saveChanges")
      span.fake-input(ref="fakeInput")
      AutocompleteSearch(@mouseenter="svgpanzoom.disableZoom()" @mouseleave="svgpanzoom.enableZoom()"  @set-text="setTextHandler" :search-text="titleValue" :style="{height: `${110}px`}" v-if="showEditField")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { NODE_TYPE } from '@/util/constants.js'

import AutocompleteSearch from '@/components/common/search/AutocompleteSearch'

import helpers from '@/util/helpers'

export default {
  name: 'TreeNodeLabel',

  props: {
    pos: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    parentPos: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    title: {
      type: String,
      default: ''
    },
    nodeSize: {
      type: Number,
      default: 100
    },
    depth: {
      type: Number,
      default: 1
    },
    nodeType: {
      type: String,
      default: ''
    },
    node: {
      type: Object,
      default: () => {
      }
    },
    isEditable: Boolean,
    isNewBadge: {
      type: Boolean,
      default: false
    }
  },

  components: {
    AutocompleteSearch
  },

  beforeMount () {
    this.titleValue = this.title || ''
  },

  mounted () {
    if (this.showEditField) {
      const cursorWatcherUnsubscribe = this.$watch(() => this.cursorNode, () => {
        this.restoreElementFromTopLayer(this.$refs.nodeLabel)
        cursorWatcherUnsubscribe()
      })
      this.handleInput()
      this.moveElementToTopLayer(this.$refs.nodeLabel)
      this.$refs.textInput.select()
    }
  },

  data: () => ({
    titleValue: '',
    description: '',
    inputWidth: 20,
    isSaved: false,
    hasChanges: false,
    usedAutocompleteSearch: false
  }),

  methods: {
    ...mapActions('trees', [
      'updateNode'
    ]),

    ...mapActions('svgTopLayer', [
      'moveElementToTopLayer',
      'restoreElementFromTopLayer'
    ]),

    setTextHandler (payload) {
      this.usedAutocompleteSearch = true
      const fakeInput = this.$refs.fakeInput
      const textInput = this.$refs.textInput
      this.titleValue = payload.name
      this.description = payload.description
      fakeInput.innerHTML = payload.name
      this.inputWidth = fakeInput.offsetWidth + 10
      textInput.style.width = this.inputWidth + 'px'
      this.hasChanges = true
    },

    handleInput () {
      const fakeInput = this.$refs.fakeInput
      const textInput = this.$refs.textInput
      this.titleValue = textInput.value
      fakeInput.innerHTML = textInput.value
      this.inputWidth = fakeInput.offsetWidth + 10
      textInput.style.width = this.inputWidth + 'px'
      this.hasChanges = true
    },

    saveChanges () {
      this.isSaved = true
      const payload = {
        node: this.node,
        props: {
          name: this.titleValue
        }
      }
      if (this.usedAutocompleteSearch) {
        payload.props.description = this.description || this.node?.props?.description || ''
      }
      if (this.node.props.template) {
        payload.updateSubbadges = true
      }
      this.updateNode(payload)
      this.restoreElementFromTopLayer(this.$refs.nodeLabel)
    }
  },

  computed: {
    ...mapGetters('nodeTmp', [
      'cursorNode'
    ]),

    showEditField () {
      return this.node &&
        this.node.localProps.showInput
    },

    isLabelForCategory () {
      return this.nodeType === NODE_TYPE.CATEGORY
    },

    isLabelForSimpleBadge () {
      return this.nodeType === NODE_TYPE.BADGE
    },

    labelX () {
      return this.isLabelForCategory ? this.pos.x : this.pos.x - 10
    },

    labelY () {
      return this.pos.y + this.nodeOffset
    },

    inputX () {
      return this.isLabelForCategory ? this.pos.x : this.pos.x - this.inputWidth / 2 - 5
    },

    inputY () {
      return this.pos.y + this.nodeOffset + (this.isNodeUnderParent ? 10 : -10)
    },

    nodeOffset () {
      const nodeOffset = this.nodeHalfSize + (this.isLabelForCategory ? 23
        : this.isLabelForSimpleBadge ? 20 : 13)
      return this.showLabelUnderNode ? nodeOffset : -(nodeOffset + this.textBlockHeight)
    },

    showLabelUnderNode () {
      return (this.isNodeUnderParent && !this.isLabelForCategory) || this.depth === 1
    },

    textBlockHeight () {
      return this.textLineHeight * this.textLines.length
    },

    textLineHeight () {
      return this.fontSize + 4
    },

    fontSize () {
      if (this.isLabelForCategory) {
        return this.depth < 5 ? 22 - (this.depth - 1) * 4 : 10
      }
      return 10
    },

    nodeHalfSize () {
      return this.nodeSize / 2
    },

    isNodeUnderParent () {
      return this.pos.y > this.parentPos.y
    },

    maxLineWidth () {
      return this.isLabelForCategory && this.depth === 3 ? 15 : 22
    },

    labelText () {
      let result = ''
      for (const line of this.textLines) {
        result += `<tspan x="${this.labelX}" dy="${this.textLineHeight}">${helpers.escapeHTML(line)}</tspan>`
      }
      return result
    },

    textLines () {
      let lines = []
      let str = this.title || ''
      while (str.length > this.maxLineWidth) {
        let found = false
        for (let i = this.maxLineWidth - 1; i >= 0; i--) {
          if (str.charAt(i) === ' ') {
            lines.push(str.slice(0, i))
            str = str.slice(i + 1)
            found = true
            break
          }
        }
        // Inserts new line at maxLineWidth position, the word is too long to wrap
        if (!found) {
          lines.push(str.slice(0, this.maxLineWidth) + '-')
          str = str.slice(this.maxLineWidth)
        }
      }
      lines.push(str)
      return lines
    }
  },

  watch: {
    title (value) {
      this.titleValue = value
    },

    cursorNode (value) {
      if (value && this.hasChanges) {
        this.saveChanges()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
text {
  font-weight: 400;
}

.depth-1 {
  font-weight: 900;
  text-anchor: middle;
}

.depth-2 {
  font-weight: 700;
}

.depth-3 {
  font-weight: 600;
}

.name-filed {
  color: $color-black;
  border-radius: 15px;
  height: 30px;
  font-size: 12px;
  padding: 2px 5px;
  min-width: 20px;
  max-width: 250px;
  border: 1px solid #eee;
  font-weight: normal;
  text-align: center;
}

.fake-input {
  position: absolute;
  left: 0;
  max-width: 250px;
  visibility: hidden;
  font-size: 12px;
  padding: 2px 5px;
  border: 1px solid transparent;
  font-weight: normal;
}
</style>
