<template lang="pug">
  component(
    :is="component")
</template>

<script>
const IconBack = () => import('@/components/svg/icons/IconBack')
const IconBadge = () => import('@/components/svg/icons/IconBadge')
const IconChevron = () => import('@/components/svg/icons/IconChevron')
const IconClose = () => import('@/components/svg/icons/IconClose')
const IconCheck = () => import('@/components/svg/icons/IconCheck')
const IconDelete = () => import('@/components/svg/icons/IconDelete')
const IconDuplicate = () => import('@/components/svg/icons/IconDuplicate')
const IconEdit = () => import('@/components/svg/icons/IconEdit')
const IconEmployees = () => import('@/components/svg/icons/IconEmployees')
const IconExport = () => import('@/components/svg/icons/IconExport')
const IconExpand = () => import('@/components/svg/icons/IconExpand')
const IconMore = () => import('@/components/svg/icons/IconMore')
const IconNodes = () => import('@/components/svg/icons/IconNodes')
const IconPen = () => import('@/components/svg/icons/IconPen')
const IconPin = () => import('@/components/svg/icons/IconPin')
const IconPrint = () => import('@/components/svg/icons/IconPrint')
const IconProfile = () => import('@/components/svg/icons/IconProfile')
const IconSearch = () => import('@/components/svg/icons/IconSearch')
const IconStar = () => import('@/components/svg/icons/IconStar')
const IconSwap = () => import('@/components/svg/icons/IconSwap')
const IconPlus = () => import('@/components/svg/icons/IconPlus')
const IconBookmark = () => import ('@/components/svg/icons/IconBookmark')
const IconReset = () => import ('@/components/svg/icons/IconReset')
const IconCheckInCircle = () => import ('@/components/svg/icons/IconCheckInCircle')

export default {
  name: 'VIcon',

  components: {
    IconBack,
    IconBadge,
    IconChevron,
    IconClose,
    IconCheck,
    IconDelete,
    IconDuplicate,
    IconEdit,
    IconEmployees,
    IconExport,
    IconExpand,
    IconMore,
    IconNodes,
    IconPen,
    IconPin,
    IconPrint,
    IconProfile,
    IconSearch,
    IconStar,
    IconSwap,
    IconPlus,
    IconBookmark,
    IconReset,
    IconCheckInCircle
  },

  props: {
    color: String
  },

  computed: {
    component () {
      return `icon-${this.$slots.default[0].text}`
    }
  }
}
</script>

<style>
</style>
