import api from '@/api'

const SET_EMPLOYEES = 'SET_EMPLOYEES'
const ADD_EMPLOYEE = 'ADD_EMPLOYEE'
const ADD_EMPLOYEES = 'ADD_EMPLOYEES'
const REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE'
const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'

const SET_CURRENT_EMPLOYEE = 'SET_CURRENT_EMPLOYEE'
const SET_PAGINATION_PARAMS = 'SET_PAGINATION_PARAMS'

export default {
  namespaced: true,

  state () {
    return {
      employees: [],
      currentEmployee: null,
      paginationParams: {}
    }
  },

  getters: {
    employees: (state) => state.employees,
    currentEmployee: (state) => state.currentEmployee,
    paginationParams: (state) => state.paginationParams
  },

  mutations: {
    [SET_EMPLOYEES] (state, data) {
      state.employees = data
    },

    [ADD_EMPLOYEES] (state, data) {
      state.employees = [...state.employees, ...data]
    },

    [ADD_EMPLOYEE] (state, data) {
      state.employees.push(data)
    },

    [SET_CURRENT_EMPLOYEE] (state, data) {
      state.currentEmployee = data
    },

    [REMOVE_EMPLOYEE] (state, data) {
      const index = state.employees.indexOf(data)
      state.employees.splice(index, 1)
    },

    [UPDATE_EMPLOYEE] (state, data) {
      const employees = [...state.employees]
      const index = employees.findIndex(el => el.id === data.id)
      if (index !== -1) {
        employees[index] = { ...state.employees[index], ...data }
        state.employees = employees
      }
    },

    [SET_PAGINATION_PARAMS] (state, params) {
      for (const key in params) {
        state.paginationParams[key] = params[key]
      }
    }
  },

  actions: {
    async loadEmployees (context, { params = {}, isPushed = false }) {
      const { data, headers } = await api.employees.list(params)

      const paginationParams = {
        page: params.page
      }

      paginationParams.perPage = (headers && parseInt(headers['x-pagination-per-page'])) || 0
      paginationParams.totalCount = (headers && parseInt(headers['x-pagination-total-count'])) || 0
      paginationParams.pages = (headers && parseInt(headers['x-pagination-page-count'])) || 1
      paginationParams.total = paginationParams.totalCount

      if (!paginationParams.page) {
        paginationParams.page = 1
      }

      context.commit(SET_PAGINATION_PARAMS, paginationParams)

      for (const key in data) {
        data[key].checked = false
      }

      if (paginationParams.page > 1 && isPushed) {
        context.commit(ADD_EMPLOYEES, data)
      } else {
        context.commit(SET_EMPLOYEES, data)
      }
      return { data, headers }
    },

    async searchEmployees (context, { params = {}, isPushed = false }) {
      if (!isPushed) {
        context.commit(SET_EMPLOYEES, [])
      }

      const { data, headers } = await api.employees.search(params)

      if (data) {
        const paginationParams = {
          page: params.page,
          q: params.q
        }

        paginationParams.perPage = (headers && parseInt(headers['x-pagination-per-page'])) || 0
        paginationParams.totalCount = (headers && parseInt(headers['x-pagination-total-count'])) || 0
        paginationParams.pages = (headers && parseInt(headers['x-pagination-page-count'])) || 1
        paginationParams.total = paginationParams.totalCount

        context.commit(SET_PAGINATION_PARAMS, paginationParams)

        for (const key in data) {
          data[key].checked = false
        }

        if (paginationParams.page > 1 && isPushed) {
          context.commit(ADD_EMPLOYEES, data)
        } else {
          context.commit(SET_EMPLOYEES, data)
        }
      }

      return { data, headers }
    },

    async addEmployee (context, { body, dataForStore }) {
      const { data, error } = await api.employees.create(body)
      if (!error) {
        context.commit(ADD_EMPLOYEE, { ...data, ...dataForStore })
      }
      return data
    },

    async openEmployee (context, id) {
      const { data } = await api.employees.getById(id)
      context.commit(SET_CURRENT_EMPLOYEE, data)
    },

    async updateEmployee (context, { payload }) {
      const { data } = await api.employees.updateById(payload.id, payload)
      if (data) {
        context.commit(UPDATE_EMPLOYEE, data)
      }
      return data
    },

    updateLocalEmployee (context, data) {
      context.commit(UPDATE_EMPLOYEE, data)
    },

    async removeEmployeesMultiple (context, { employees }) {
      const { error } = await api.employees.delete({ employeeIds: employees.map(el => el.id) })
      if (!error) {
        for (let idx in employees) {
          context.commit(REMOVE_EMPLOYEE, employees[idx])
        }
      }
      return { error }
    },

    async activateMultiple (context, { employees }) {
      const { error } = await api.employees.activateMultiple({ employeeIds: employees.map(el => el.id) })
      if (!error) {
        for (let idx in employees) {
          employees[idx].isActive = 1
        }
      }
      return { error }
    },

    async deactivateMultiple (context, { employees }) {
      const { error } = await api.employees.deactivateMultiple({ employeeIds: employees.map(el => el.id) })
      if (!error) {
        for (let idx in employees) {
          employees[idx].isActive = 0
        }
      }
      return { error }
    },

    async setToPositionMultiple (context, { position, employees }) {
      const { error } = await api.employees.setToPositionMultiple(position.id, { employeeIds: employees.map(el => el.id) })
      if (!error) {
        for (let idx in employees) {
          employees[idx].position = position
        }
      }
      return { error }
    },

    async setToLocationMultiple  (context, { location, employees }) {
      const { error } = await api.employees.setToLocationMultiple(location.id, { employeeIds: employees.map(el => el.id) })
      if (!error) {
        for (let idx in employees) {
          employees[idx].location = location
        }
      }
      return { error }
    },

    async removeEmployee (context, item) {
      const { error } = await api.employees.deleteById(item.id)
      if (!error) {
        context.commit(REMOVE_EMPLOYEE, item)
      }
    },

    async linkRole (context, { id, roleId }) {
      const { data, error } = await api.employees.linkRole(id, roleId)
      return { data, error }
    },

    async unlinkRole (context, { id, roleId }) {
      const { data, error } = await api.employees.unlinkRole(id, roleId)
      return { data, error }
    }
  }
}
