import axios from 'axios'
import { execute, addQueryParams, cancelableRequest } from './utils'
import { TOKEN_COOKIE, KEEP_SIGNED_FLAG, BASE_AUTH_URL, INSTANCE_URL } from '@/util/constants'

import cookie from '@/util/cookie'
import helpers from '@/util/helpers'
import { getDomainFromUrl, checkedIsDemo } from '@/util/utils.js'
import store from '@/store'

import badges from './badges'
import departments from './departments'
import employees from './employees'
import goals from './goals'
import locations from './locations'
import permissions from './permissions'
import positions from './positions'
import roles from './roles'
import matrix from './matrix'
import storage from './storage'
import insights from './insights'
import settings from './settings'
import preferences from './preferences'

const cancelable = cancelableRequest(execute)
const CancelToken = axios.CancelToken
let cancel = null

export default {
  badges,
  departments,
  employees,
  goals,
  locations,
  permissions,
  roles,
  matrix,
  positions,
  insights,
  settings,
  storage,
  preferences,

  // AUTH
  async loginCheck (body) {
    const { data } = await execute('post', 'login/check', body)
    return data
  },

  async login (body, keepLogged) {
    const { data, error } = await execute('post', 'login/auth', body)
    const isLocalProject = window.location.origin.includes('localhost')
    if (!error && data.sessionToken) {
      if (keepLogged) cookie.setItem(KEEP_SIGNED_FLAG, true, (new Date(Date.now() + 2592000 * 1000)).toUTCString())
      const domain = getDomainFromUrl(window.location.href, checkedIsDemo())
      cookie.setItem(TOKEN_COOKIE, data.sessionToken, 0, domain)
      const redirectUrl = localStorage.getItem('savedRedirect') ? data.redirectUrl + localStorage.getItem('savedRedirect') : data.redirectUrl
      if (data.hasOwnProperty('redirectUrl')) {
        const parsedUrl = new URL(redirectUrl)
        cookie.setItem(INSTANCE_URL, parsedUrl.origin, 0, domain)
      }
      setTimeout(() => {
        store.commit('app/SHOW_TRIAL_PERIOD', true)
      }, 2500)
      if (data.hasOwnProperty('redirectUrl') && !checkedIsDemo()) {
        if (!isLocalProject) {
          window.location.href = `${redirectUrl}?${TOKEN_COOKIE}=${data.sessionToken}`
        }
      }
      return true
    }
    return false
  },

  async logout () {
    await execute('post', 'login/logout')
    cookie.removeItem(INSTANCE_URL, getDomainFromUrl(window.location.href, checkedIsDemo()))
    cookie.removeItem(TOKEN_COOKIE, getDomainFromUrl(window.location.href, true))
    cookie.removeItem(KEEP_SIGNED_FLAG)
    store.dispatch('app/logoutHandler')
    store.commit('app/SHOW_TRIAL_PERIOD', false)
    store.commit('app/TRIAL_PERIOD', null)
    localStorage.removeItem('TRIAL_PERIOD')
    if (!checkedIsDemo()) {
      window.location.href = BASE_AUTH_URL ? BASE_AUTH_URL + '/login?logout=true' : '/login?logout=true'
    }
  },

  loginSetup () {
    return execute('get', 'login/setup')
  },

  passwordReset (data) {
    return execute('post', 'login/reset', data)
  },

  async passwordResetConfirm (body) {
    const { data, error } = await execute('post', 'login/confirm', body)
    if (!error && data.resetPasswordSessionToken) {
      localStorage.setItem('need_new_password', true)
      cookie.setItem(TOKEN_COOKIE, data.resetPasswordSessionToken)
      return true
    }
    return false
  },

  async setNewPassword (body) {
    const { error } = await execute('put', 'login/set', body)
    if (!error) {
      localStorage.removeItem('need_new_password')
      cookie.removeItem(TOKEN_COOKIE)
      return true
    }
    return false
  },

  // BADGES
  getBadgesTree (params = {}) {
    return execute('get', helpers.addQueryParams('badges/tree', params))
  },

  getBadgeTypes () {
    return execute('get', 'badge-types')
  },

  getBranches () {
    return execute('get', 'badges/branches')
  },

  // async onSuggestBadge (params = {}) {
  //   const { data } = await execute('post', 'badges/suggest', params)
  //   return data
  // },

  declineSuggestBadge (id, body = {}) {
    return execute('delete', `badges/suggested/${id}`, body)
  },

  acceptSuggestBadge (id, payload) {
    return execute('post', `badges/suggested/${id}`, payload)
  },

  getDeclineReasons () {
    return execute('get', `badges/suggested/decline-reasons`)
  },

  // REQUIRED BADGES
  getRequiredBadges (badgeId) {
    return execute('get', `badges/required-badges?badgeId=${badgeId}`)
  },

  loadBadges () {
    return execute('get', `badges?noCategories=1`)
  },

  addRequiredBadge (badgeId, requiredBadgeId) {
    const fields = {
      badgeId,
      requiredBadgeId
    }
    return execute('post', 'badges/add-required-badge', fields)
  },

  removeRequiredBadge (badgeId, requiredBadgeId) {
    const queryParams = {
      badgeId: {
        value: badgeId
      },
      requiredBadgeId: {
        value: requiredBadgeId
      }
    }
    return execute('delete', helpers.addQueryParams('badges/remove-required-badge', queryParams))
  },

  // GRADATIONAL BADGES
  createGradationalSubBadge (data) {
    return execute('post', 'badges/create-gradational-sub-badge', data)
  },

  removeGradationalSubBadge (id) {
    return execute('delete', `badges/delete-gradational-sub-badge?badgeId=${id}`)
  },

  moveGradationalBadge (fromBadgeId, toBadgeId) {
    return execute('put', `badges/move-gradational-sub-badge?fromBadgeId=${fromBadgeId}&toBadgeId=${toBadgeId}`)
  },

  // HOOKED BADGES
  createHookedBadge (data) {
    return execute('post', 'badges/hooked-badge', data)
  },

  addHookedBadge (badgeId, hookedBadgeId) {
    return execute('post', `badges/add-hooked-badge?badgeId=${badgeId}&hookedBadgeId=${hookedBadgeId}`)
  },

  // EMPLOYEES
  // TODO: Move to "employee" module
  updateEmployeePicture (id, file) {
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + cookie.getItem(TOKEN_COOKIE)
    }
    const formData = new FormData()
    formData.append('image', file)
    return execute('post', `employees/${id}/profile-picture`, formData, headers)
  },

  deleteEmployeePicture (id) {
    return execute('delete', `employees/${id}/profile-picture`)
  },

  getEmployeeContact (employeeId) {
    return execute('get', `employees/${employeeId}/contact`)
  },

  updateEmployeeSettings (data) {
    return execute('put', 'employees/settings', data)
  },

  employeeChangePassword (body) {
    return execute('put', `employees/change-password`, body)
  },

  // ANALYSIS
  sortPositions (order) {
    return execute('get', `positions?sort=${order}`)
  },

  sortDepartments (order) {
    return execute('get', `departments?sort=${order}`)
  },

  // PERMISSIONS
  getPermissions () {
    return execute('get', 'permissions')
  },

  getCurrentPermissions () {
    return execute('get', 'permissions/current')
  },

  // PROFILE
  updateProfilePicture (file) {
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + cookie.getItem(TOKEN_COOKIE)
    }
    const formData = new FormData()
    formData.append('image', file)
    return execute('post', 'employees/profile-picture', formData, headers)
  },

  // SEARCH
  globalSearch (query) {
    if (cancel) {
      cancel()
      cancel = null
    }
    const params = {
      q: { value: query }
    }
    return execute('get', helpers.addQueryParams('search', params), null, null, new CancelToken(function executor (c) {
      cancel = c
    }))
  },

  hybridSearch (params) {
    return cancelable.execute('GET', addQueryParams('search/hybrid', params))
  },

  // TRANSLATIONS
  loadTranslate (query) {
    return execute('get', `translations?languageId=${query}`)
  },

  getLanguages () {
    return execute('get', `translations/languages`)
  },

  // CATEGORIES
  loadCategories () {
    return execute('get', 'badges?onlyBadgeCategories=1&all=1')
  },

  // HELP CENTER
  loadArticles (languageId) {
    return execute('get', `help/articles?languageId=${languageId}`)
  },

  getArticleInfo (prettyUrl) {
    return execute('get', `help/articles/${prettyUrl}`)
  },

  loadFaq (languageId) {
    return execute('get', `faq/articles?languageId=${languageId}`)
  },

  getContactTypes () {
    return execute('get', `contact/types`)
  },

  sendMailToHelpCenter (values) {
    return execute('post', 'contact/send', values)
  },

  // NOTIFICATIONS
  notificationsSubscribe (pageNumber = 1) {
    return execute('get', `notifications?per-page=50&page=${pageNumber}`)
  },

  notificationsTypes () {
    return execute('get', `notifications/types`)
  },

  getNotificationById (id) {
    return execute('get', `/notifications/${id}`)
  },

  setSeenNotification (id) {
    return execute('put', `notifications/seen?id=${id}`)
  },

  setUnSeenNotification (id) {
    return execute('put', `notifications/unseen?id=${id}`)
  },

  setUnSeenAllNotification () {
    return execute('put', `notifications/seen-all`)
  },

  onNotificationsPerPageSubscribe () {
    return execute('get', `notifications?per-page=10`)
  },

  loadUnseenNotifications (pageNumber = 1) {
    return execute('get', `notifications?onlyUnseen=1&page=${pageNumber}`)
  },

  onReccomendBadgeToColleague (data) {
    return execute('post', 'badges/recommend', data)
  },

  onLoadEmployeeForReccomendedBadge (badgeId, pageNumber, searchQuery = '') {
    return execute('get', `employees/recommended-for-badge?badgeId=${badgeId}&q=${searchQuery}&page=${pageNumber}`)
  },

  // SETUPWIZARD
  storeStep (data) {
    return execute('put', `settings/company-info`, data)
  },

  storeDataSyncType (data) {
    return execute('put', `/settings/data-sync/type`, data)
  },

  storeDataSyncProvider (data) {
    return execute('put', `/settings/data-sync`, data)
  },

  restoreDataSyncProvider (data) {
    return execute('get', `/settings/data-sync`, data)
  },

  startDataSyncProvider () {
    return execute('put', `/sync/start`)
  },

  uploadCompanyLogo (file) {
    const formData = new FormData()
    formData.append('file', file)
    return execute('post', `/settings/company-info/upload-logo`, formData)
  },

  async getSettingCompanyInfo () {
    const { data } = await execute('get', 'settings/company-info')
    return data
  },

  onComleteSetup () {
    return execute('PUT', 'settings/setup-complete', { value: true })
  },

  importEmployees (table) {
    return execute('post', 'employees/import', table)
  },

  loginSetupWithoutToken () {
    const headers = {
      'content-type': 'application/json',
      'Accept': 'application/json'
    }
    return execute('get', `login/setup`, null, headers)
  },

  postSuperEmployee (body) {
    return execute('post', `employees/super`, body)
  },

  getSuperEmployee (body) {
    return execute('get', `employees/super`)
  },

  putSuperEmployee (id, body = {}) {
    return execute('put', `employees/${id}/super`, body)
  },

  postSuperEmployeeId (id) {
    return execute('post', `employees/${id}/super`)
  },

  countriesList () {
    return execute('get', 'countries?all')
  },

  // loadSettingsFrequencies () {
  //   return execute('get', `settings/data-sync/frequencies`)
  // },

  // loadSettingsWeekDays () {
  //   return execute('get', `settings/data-sync/week-days`)
  // },

  loadSettingsProvider () {
    return execute('get', `settings/data-sync/providers`)
  },

  loadAzureGroups () {
    return execute('get', `sync/groups`)
  },

  putAzureGroups (body) {
    return execute('put', `settings/data-sync/selected-groups`, body)
  },

  loadAzureSelectedGroups () {
    return execute('get', `settings/data-sync/selected-groups`)
  }
}
