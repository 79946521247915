<template lang="pug">
  .tree-elements
    h3.title-2  {{ 'pages.builder.basic_elements' | translate}}
      v-info-icon(:text="$t('infotext.create_a_new_empty_badge_or_category')")
    .tree-elements__list
      .tree-elements__item(
        data-rank="category"
        :class="['drag-drop-2']")
        //- switch class from .drag-drop to .drag-drop-2 (fix scroll overflow)
        .tree-elements__item-icon
            category(:size="28" class-names="drag-category")
        .tree-elements__item-label(
          @click="addCategory") {{ 'ui.labels.category' | translate}}
      .tree-elements__item(
        data-rank="badge"
        class="drag-drop-2")
        .tree-elements__item-icon
            badge(width="30")
        .tree-elements__item-label(
          @click="addBadge") {{ 'ui.labels.badge' | translate}}
      .tree-elements__item(
        data-rank="gradational-badge"
        class="drag-drop-2")
        .tree-elements__item-icon
            badge(
              width="30"
              level="3")
        .tree-elements__item-label(
          @click="addGradationalBadge") {{ 'ui.labels.gradational_badge' | translate}}

</template>

<script>
import Badge from '@/components/svg/Badge'
import Category from '@/components/svg/CategoryCircle'

export default {
  name: 'BasicElements',

  props: {
    allowAddCategory: Boolean,
    allowAddBadge: Boolean
  },

  components: {
    Badge,
    Category
  },

  methods: {
    addCategory () {
      if (this.allowAddCategory) {
        this.$emit('add-category')
      }
    },

    addBadge () {
      if (this.allowAddBadge) {
        this.$emit('add-badge')
      }
    },

    addGradationalBadge () {
      if (this.allowAddBadge) {
        this.$emit('add-gradational-badge')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tree-elements__item-icon{
  cursor: not-allowed;
}
.drag-drop-2{
  .tree-elements__item-label, .tree-elements__item-icon{
    cursor: move;
  }
}
.tree-elements__list{
  user-select: none;
}
</style>
