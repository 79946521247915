<template lang="pug">
  .drawer-panel(:class="stateClass" :style="stateStyles")
    .drawer-panel__tabs(v-if="hasTabSlot")
      slot(name="tabs")
    drawer-panel-toggler(
      v-if="togglable"
      :badgeValue="badgeValue"
      :title="title | translate"
      :side="side"
      :value="!minimize"
      @input="onChange")
    .drawer-panel__inner
      slot
</template>

<script>
import DrawerPanelToggler from '@/components/ui/DrawerPanelToggler'

export default {
  name: 'DrawerPanel',

  props: {
    title: String,
    side: {
      type: String,
      default: 'left'
    },
    minimize: {
      type: Boolean,
      default: true
    },
    togglable: {
      type: Boolean,
      default: true
    },
    addTopOffset: {
      type: Boolean,
      default: false
    },
    addSideOffset: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 340
    },
    badgeValue: {
      type: Number,
      default: 0
    }
  },

  data: () => ({
    hasTabSlot: false
  }),

  components: {
    DrawerPanelToggler
  },

  updated () {
    if (this.$slots && this.$slots['tabs']) this.hasTabSlot = true
    else this.hasTabSlot = false
  },

  methods: {
    onChange (value) {
      this.$emit('on-toggle', value)
    }
  },

  computed: {
    stateClass () {
      return {
        'side-offset': this.addSideOffset,
        'top-offset': this.addTopOffset,
        'minimize': this.minimize,
        'togglable': this.togglable,
        'tabs': this.tabs,
        'desktop-position-left': this.side === 'left',
        'desktop-position-right': this.side === 'right'
      }
    },

    stateStyles () {
      let style = ''
      if (window.innerWidth > 800) {
        if (this.minimize) {
          style += this.side === 'right'
            ? `right: -${this.width - 40}px;`
            : `left: -${this.width - 40}px;`
        }
        style += this.togglable ? `width: ${this.width}px;` : ''
      }
      return style
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';

  .drawer-panel {
    position: absolute;
    border-radius: 7px;
    background: $color-white;
    box-shadow: 2px 2px 20px 0 rgba(37, 47, 57, 0.15);
    display: flex;

    &__inner {
      width: 100%;
    }

    &__tabs {
      position: absolute;
      bottom: 100%;
    }
  }

  @include desktop {
    .drawer-panel {
      min-height: 160px;
    }

    .desktop-position-left,
    .desktop-position-right {
      top: 20px;
      transition: all 0.3s;
      will-change: left;

      &.top-offset {
        top: 85px;
      }
    }

    .desktop-position-left {
      left: 0;
      z-index: 10;
      flex-direction: row-reverse;

      .drawer-panel__tabs{
        right: 40px;
      }

      &:not(.side-offset) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .desktop-position-right {
      right: 0;
      z-index: 1;

      .drawer-panel__tabs{
        left: 40px;
      }

      &:not(.side-offset) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    // .desktop-position-right.minimize {
    //   right: -300px;
    // }

    // .desktop-position-left.minimize {
    //   left: -300px;
    // }

    .tabs {
      top:75px;
    }

    .side-offset {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  @include mobile {
    .drawer-panel {
      position: relative;
      margin: 20px;
      margin-top: 0;
      flex-direction: column;

      &:not(.minimize) .drawer-panel__inner {
        margin-top: -35px;
      }

      &.minimize {
        overflow: hidden;

        .drawer-panel__inner {
          height: 0px;
        }
      }
    }
  }
</style>
