<template lang="pug">
  v-dialog(
    width="400"
    :value="show"
    @input="onClose")
    v-card
      v-card-head
        v-card-head-label
          h2.modal-title {{ 'pages.my.close_goal' | translate }}
        v-card-head-toolbar
          v-btn(
            icon
            gray
            @click="onClose") x
      v-card-content
        .form-group
          span {{ 'pages.my.please_indicate_goal_outcome_msg' | translate }}
      v-card-actions
        v-btn(
          v-if="!failed"
          text
          danger
          small
          @click="onFailed") {{ 'ui.labels.failed'| translate }}
        v-btn(
          v-if="!completed"
          small
          @click="onAccept") {{ 'ui.labels.completed'| translate }}
</template>

<script>
export default {
  name: 'MarkGoalModal',

  props: {
    show: Boolean,
    goal: Object
  },

  data: () => ({
    value: false
  }),

  methods: {
    onClose () {
      this.$emit('close')
    },

    onFailed () {
      this.$emit('on-fail')
    },

    onAccept () {
      this.$emit('on-accept')
    }
  },

  computed: {
    completed () {
      return this.goal && this.goal.completionStatusId === 'COMPLETED'
    },

    failed () {
      return this.goal && this.goal.completionStatusId === 'FAILED'
    }
  }
}
</script>

<style lang="scss">
</style>
