<template lang="pug">
simple-modal(
    width="500"
    :title="$t('pages.builder.enable_full_syncronization')"
    :type="modal.type"
    :confirmBtnTitle="$t('ui.buttons.proceed')"
    :show="show"
    @on-confirm="onConfirm"
    @on-cancel="onCancel")
    template(slot="body")
      p(style="font-weight: bold") {{ 'ui.labels.warning' | translate }}!
      p {{ 'infotext.enable_sync_warning_message' | translate}}
      p(v-html="$t('infotext.enable_sync_warning_message_details')" style="padding-left:25px")

</template>

<script>
import { MODAL_TYPES } from '@/util/constants'
import SimpleModal from '@/components/modals/global/SimpleModal'

export default {
  name: 'ModalSyncWarning',

  components: {
    SimpleModal
  },

  props: {
    show: Boolean
  },

  data: () => ({
    modal: {
      type: MODAL_TYPES.WARNING
    }
  }),

  methods: {
    onConfirm () {
      this.$emit('on-confirm')
    },

    onCancel () {
      this.$emit('on-cancel')
    }
  }
}
</script>

<style lang="scss">
</style>
