<template lang="pug">
  .skill-detail-comment.skill-detail-section
    img(:src="userInformation.profilePictureUrl || '/img/no-profile-picture.svg'")
    div
      h4 {{fullName}}
      span.comment-wrapper
        span.top “
      span.comment-text {{displayComment}}
      span.comment-wrapper
        span.bottom ”
      div.show-full-comment
        span(v-if="!activeLongText && isCommentLong" @click="setLengthText") {{ 'ui.buttons.show_more' | translate }}
        span(v-if="activeLongText" @click="setLengthText") {{ 'ui.buttons.hide' | translate }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SkillDetailComment',

  props: {
    badge: {
      type: Object,
      default: null
    },
    userData: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    activeLongText: false
  }),

  computed: {
    ...mapGetters('app', [
      'userInfo'
    ]),

    userInformation () {
      return this.$route.path.includes('employee') && this.userData ? this.userData : this.userInfo
    },

    fullName () {
      return `${this.userInformation.firstName} ${this.userInformation.lastName}`
    },
    isCommentLong () {
      return this.comment.length > 90
    },
    displayComment () {
      let shortText = this.comment.slice(0, 90)
      return this.activeLongText ? this.comment : shortText
    },
    comment () {
      return this.badge.activationComment || this.badge.validation?.comment || ''
    }
  },

  methods: {
    setLengthText () {
      this.activeLongText = !this.activeLongText
    }
  }
}
</script>

<style lang="scss" scoped>
  .skill-detail-comment {
    display: grid;
    grid-template-columns: 1fr 11fr;
    font-family: 'Nunito';
    .comment-text{
      font-style: italic;
      color: #A0AAC3;
      font-weight: 400;
    }
    .comment-wrapper{
      color: $h2-title;
      font-weight: bold;
      font-size: 18px;
      .top{
        padding-right: 3px;
      }
      .bottom{
        padding-left: 5px;
      }
    }
    .show-full-comment{
      padding-right: 10px;
      padding-top: 6px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: block;
      text-align: right;
      text-decoration-line: underline;
      color: #666D74;
      span{
        cursor: pointer;
        user-select: none;
      }
    }
    h4{
      padding-bottom: 12px;
      display: block;
    }
    img{
      border-radius: 30px;
      width: 28px;
      height: 28px;
      margin-right: 15px;
    }
  }
</style>
