<template lang="pug">
  g(
    :transform="pos | svgtranslate({ x: -20, y: -20 })"
    :class="{ 'cut': node.localProps.isCut }")
    g(:transform="{ x: 3.5, y: 7.5 } | svgtranslate")
      path.cls-1.under-badge(fill="#ccc" d="M4.89,5.07,15.44,2.68,26,5.07V15.16a13.5,13.5,0,0,1-6.36,11.3l-4.2,2.38-4.19-2.38a13.5,13.5,0,0,1-6.36-11.3Z")
      path.cls-2.under-badge(fill="#ccc" d="M8.73,8.38l6.71-1.65,6.72,1.65v7a8.44,8.44,0,0,1-4.05,7.18l-2.67,1.64-2.66-1.64a8.44,8.44,0,0,1-4-7.18Z")
    g(@click.stop="onClick"
      ref="movedBadge"
      data-rank="move-gradational"
      class="drag-move node-draggable"
      :data-id="id"
      :data-depth="depth"
      :data-x="pos.x - 20"
      :data-y="pos.y - 20"
      :class="{ 'selected': isSelected,  'hilighted': isHilighted }"
      @contextmenu.prevent="onRightClick")
      path.halo(d="M3 7.44854V23.6468C2.9999 26.9307 3.96866 30.1607 5.81518 33.033C7.6617 35.9053 10.3253 38.3254 13.5554 40.0658L19 43L24.4446 40.0658C27.6747 38.3254 30.3383 35.9053 32.1848 33.033C34.0313 30.1607 35.0001 26.9307 35 23.6468V7.44854L19 4L3 7.44854Z")
      g.badge(:transform="{ x: 3.5, y: 0 } | svgtranslate")
        path.cls-1(:fill="isSuggested ? '#ccc' : color" d="M13.13,34.09s0,0,0,0l0,0c.06.07.11.16.14.2L15,37l1.69-2.64a.74.74,0,0,1,.09-.12l.15-.12c.57-.43,1-.72,3.48-2.21a7,7,0,0,0,2.68-3A8.92,8.92,0,0,0,24,25.07V12.93l-9-1.68L6,12.93V25.07a8.92,8.92,0,0,0,.93,3.84,7,7,0,0,0,2.68,3c.17.11,3,1.74,3.32,2l.2.18Z")
        text.badge-text(v-if="showTextLevel" transform="translate(14.5 23)" dominant-baseline="middle" text-anchor="middle" fill='#fff') {{level}}
        polygon.cls-2(
          v-for="coor in currentLvl"
          :points="coor"
          :fill="isSuggested ? '#ccc': color")
</template>

<script>
import { COLOR_PRIMARY } from '@/util/constants'

export default {
  name: 'TreeNodeBadge',

  props: {
    id: Number,
    color: {
      type: String,
      default: COLOR_PRIMARY
    },
    level: {
      type: [String, Number],
      default: 0
    },
    pos: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    depth: {
      type: Number,
      default: 1
    },
    node: Object,
    isSelected: {
      type: Boolean,
      default: false
    },
    isHilighted: {
      type: Boolean,
      default: false
    },
    isSuggested: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    this.changeCurrent(this.level)

    if (this.node.localProps.showInput) {
      this.selectNode()
    }
    this.$refs.movedBadge.onnodeclick = this.onClick
  },

  data: () => ({
    showTextLevel: false,
    currentLvl: [],
    coordinatesLvl1: [
      '14.68 9.91 16.08 10.65 15.82 9.09 16.95 7.98 15.38 7.76 14.68 6.34 13.98 7.76 12.42 7.98 13.55 9.09 13.28 10.65 14.68 9.91'
    ],
    coordinatesLvl2: [
      '12 9.91 13.4 10.65 13.13 9.09 14.27 7.98 12.7 7.76 12 6.34 11.3 7.76 9.73 7.98 10.87 9.09 10.6 10.65 12 9.91',
      '18 9.91 19.4 10.65 19.13 9.09 20.27 7.98 18.7 7.76 18 6.34 17.3 7.76 15.73 7.98 16.87 9.09 16.6 10.65 18 9.91'
    ],
    coordinatesLvl3: [
      '15 5.91 16.4 6.65 16.13 5.09 17.27 3.98 15.7 3.76 15 2.34 14.3 3.76 12.73 3.98 13.87 5.09 13.6 6.65 15 5.91',
      '19 10.91 20.4 11.65 20.13 10.09 21.27 8.99 19.7 8.76 19 7.34 18.3 8.76 16.73 8.99 17.87 10.09 17.6 11.65 19 10.91',
      '11 10.91 12.4 11.65 12.13 10.09 13.27 8.99 11.7 8.76 11 7.34 10.3 8.76 8.73 8.99 9.87 10.09 9.6 11.65 11 10.91'
    ],
    coordinatesLvl4: [
      '12.28 10.91 13.68 11.65 13.42 10.09 14.55 8.98 12.98 8.76 12.28 7.34 11.58 8.76 10.02 8.98 11.15 10.09 10.88 11.65 12.28 10.91',
      '18.28 10.91 19.68 11.65 19.42 10.09 20.55 8.98 18.98 8.76 18.28 7.34 17.58 8.76 16.02 8.98 17.15 10.09 16.88 11.65 18.28 10.91',
      '12.28 4.91 13.68 5.65 13.42 4.09 14.55 2.98 12.98 2.76 12.28 1.34 11.58 2.76 10.02 2.98 11.15 4.09 10.88 5.65 12.28 4.91',
      '18.28 4.91 19.68 5.65 19.42 4.09 20.55 2.98 18.98 2.76 18.28 1.34 17.58 2.76 16.02 2.98 17.15 4.09 16.88 5.65 18.28 4.91'
    ],
    coordinatesLvl5: [
      '14.78 5.91 16.18 6.65 15.92 5.09 17.05 3.98 15.48 3.76 14.78 2.34 14.08 3.76 12.52 3.98 13.65 5.09 13.38 6.65 14.78 5.91',
      '18.78 10.91 20.18 11.65 19.92 10.09 21.05 8.99 19.48 8.76 18.78 7.34 18.08 8.76 16.52 8.99 17.65 10.09 17.38 11.65 18.78 10.91',
      '10.78 10.91 12.18 11.65 11.92 10.09 13.05 8.99 11.48 8.76 10.78 7.34 10.08 8.76 8.52 8.99 9.65 10.09 9.38 11.65 10.78 10.91',
      '21.78 5.91 23.18 6.65 22.92 5.09 24.05 3.98 22.48 3.76 21.78 2.34 21.08 3.76 19.52 3.98 20.65 5.09 20.38 6.65 21.78 5.91',
      '7.78 5.91 9.18 6.65 8.92 5.09 10.05 3.98 8.48 3.76 7.78 2.34 7.08 3.76 5.51 3.98 6.65 5.09 6.38 6.65 7.78 5.91'
    ]
  }),

  methods: {
    onClick (e) {
      this.selectNode(e)
    },

    selectNode (e) {
      const rect = this.$el.getBoundingClientRect()
      const pos = { x: rect.left, y: rect.top }
      this.$emit('select', {
        event: e,
        pos: pos,
        node: this.node,
        el: this.$refs.movedBadge
      })
    },

    onRightClick (e) {
      const rect = this.$el.getBoundingClientRect()
      const pos = { x: rect.left, y: rect.top }
      this.$emit('right-click', {
        event: e,
        pos: pos,
        node: this.node,
        el: this.$el
      })
    },
    changeCurrent (lvl) {
      if (lvl <= 5) {
          this.currentLvl = this['coordinatesLvl' + lvl]
          this.showTextLevel = false
        } else {
          this.showTextLevel = true
          this.currentLvl = this.coordinatesLvl1
        }
    }
  },

  watch: {
    level (value) {
       this.changeCurrent(value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .selected {
    .halo {
      opacity: 1;
    }
  }

  .cls-1,
  .cls-2 {
    fill-rule: evenodd;
  }

  .cls-1 {
    stroke: $website-color;
    stroke-width: 4px;
  }

  .cut {
    .under-badge {
      fill: $website-color;
    }

    .badge {
      opacity: .5;
    }
  }
  .badge-text{
    font-size: 10px;
  }
</style>
