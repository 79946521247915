<template functional>
  <svg
    :class="[data.class, data.staticClass]"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.01" width="16" height="16" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.77814 6.96646C2.52054 6.67819 2.5454 6.23567 2.83367 5.97807L7.53367 1.77807C7.80145 1.53877 8.20689 1.54094 8.47209 1.78308L13.0721 5.98308C13.3576 6.24376 13.3777 6.68651 13.117 6.97201C12.8564 7.25751 12.4136 7.27764 12.1281 7.01696L8.7001 3.88704L8.7001 13.7C8.7001 14.0866 8.3867 14.4 8.0001 14.4C7.61351 14.4 7.3001 14.0866 7.3001 13.7L7.3001 3.86433L3.76654 7.02198C3.47827 7.27959 3.03575 7.25473 2.77814 6.96646Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowTop'
}
</script>

<style scoped></style>
