import lazyLoading from '../lazyLoading'

const ROUTE = 'administration'

export default {
  path: `/${ROUTE}`,
  redirect: `/${ROUTE}/permissions`,
  component: lazyLoading(`${ROUTE}/Index`),
  children: [
    { path: 'permissions', component: lazyLoading(`${ROUTE}/permissions/Index`) },
    { path: 'permissions/:role_id', component: lazyLoading(`${ROUTE}/permissions/Index`) },
    { path: 'positions', component: lazyLoading(`${ROUTE}/positions/Index`) },
    { path: 'locations', component: lazyLoading(`${ROUTE}/locations/Index`) },
    { path: 'organization', component: lazyLoading(`${ROUTE}/organization/Index`) },
    { path: 'employees', component: lazyLoading(`${ROUTE}/employees/Index`) },
    { path: 'employees/import', component: lazyLoading(`${ROUTE}/employees/ImportCsv`) },
    { path: 'employees/:employee_id', component: lazyLoading(`${ROUTE}/employees/Detail`) },
    { path: 'employees/add', component: lazyLoading(`${ROUTE}/employees/Detail`) }
  ]
}
