import api from '@/api'

const SET_MATRIX_LIST = 'SET_MATRIX_LIST'
const ADD_MATRIX = 'ADD_MATRIX'
const UPDATE_MATRIX = 'UPDATE_MATRIX'
const REMOVE_MATRIX = 'REMOVE_MATRIX'
const UPDATE_MATRIX_ITEM = 'UPDATE_MATRIX_ITEM'

const SET_MATRIX = 'SET_MATRIX'

export default {
  namespaced: true,

  state () {
    return {
      matrixDetail: null,
      matrixList: []
    }
  },

  getters: {
    matrixDetail: (state) => state.matrixDetail,
    matrixList: (state) => state.matrixList
  },

  mutations: {
    [SET_MATRIX_LIST] (state, data) {
      state.matrixList = data
    },

    [ADD_MATRIX] (state, data) {
      state.matrixList.push(data)
    },

    [UPDATE_MATRIX] (state, data) {
      const index = state.matrixList
        .findIndex(item => item.id === data.id)
      if (index !== -1) {
        for (const key in data) {
          state.matrixList[index][key] = data[key]
        }
      }
    },

    [REMOVE_MATRIX] (state, data) {
      const index = state.matrixList
        .map(item => item.id)
        .indexOf(data.id)
      if (index !== -1) {
        state.matrixList.splice(index, 1)
      }
    },

    [SET_MATRIX] (state, data) {
      state.matrixDetail = data
    },

    [UPDATE_MATRIX_ITEM] (state, { matrixID, status, key, count = 0 }) {
      const item = state.matrixList.filter(el => el.id === matrixID)
      if (!status) {
        item[0][key] = count
      } else {
        item[0][key] = status === 'add' ? item[0][key] + 1 : item[0][key] - 1
      }
    }
  },

  actions: {
    async loadMatrixList ({ commit }) {
      const { data } = await api.matrix.list()
      commit(SET_MATRIX_LIST, data)
    },

    async loadMatrixAnalysis ({ commit }, item) {
      const { data } = await api.matrix.analysis(item.id)
      commit(SET_MATRIX, { info: item, analysis: data })
    },

    async getMatrixById ({ commit }, ID) {
      const { data } = await api.matrix.getById(ID)
      return data
    },

    async createMatrix ({ commit }, payload) {
      const { data, error } = await api.matrix.create(payload)
      const matrix = {
        ...data,
        employeesCount: payload.employeeIds.length,
        badgesCount: payload.badgeIds.length
      }
      if (!error) {
        commit(ADD_MATRIX, matrix)
      }
      return { data, error }
    },

    async copyMatrix ({ commit }, item) {
      const { data } = await api.matrix.copy(item.id)
      commit(ADD_MATRIX, data)
    },

    async updateMatrix ({ commit }, payload) {
      const { data } = await api.matrix.updateById(payload.id, payload)
      if (data) {
        commit(UPDATE_MATRIX, data)
      }
    },

    deleteMatrix ({ commit }, item) {
      commit(REMOVE_MATRIX, item)
      api.matrix.removeById(item.id)
    }
  }
}
