<template lang="pug">
  .v-expansion(
    :class="{ 'v-expansion--toggled': toggled }")
    .v-expansion__inner
      slot
      .v-expansion__inner-chevron(
        @click="toggle")
        chevron
    .v-expansion__content(
      v-if="toggled")
      slot(name="content")
</template>

<script>
import Chevron from '@/components/svg/Chevron'

export default {
  name: 'VExpansion',

  components: {
    Chevron
  },

  props: {
    value: [Boolean, Number]
  },

  mounted () {
    this.toggled = !!this.value
  },

  data: () => ({
    toggled: false
  }),

  methods: {
    toggle () {
      if (this.value) {
        this.toggled = !this.toggled
      }
    },

    setValue (value) {
      this.toggled = value
    }
  }
}
</script>

<style lang="scss">
  .v-expansion {

    &__inner {
      display: flex;
      align-items: center;

      &-chevron {
        padding-left: 10px;
        cursor: pointer;

        svg {
          transform: rotate(90deg);
        }
      }
    }

    &--toggled {
      .v-expansion__inner-chevron {
        svg {
          transform: rotate(-90deg) translateX(1px);
        }
      }
    }

    &__content {
      padding-top: 5px;
      padding-left: 30px;
    }
  }
</style>
