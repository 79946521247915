import defaultView from './default-view'
import defaultLook from './default-look'
import lastSelectedView from './last-selected-view'
import demo from './demo'

import emailRemindersNews from './email/reminders/news'
import emailRemindersSuggestions from './email/reminders/suggestions'
import emailRemindersValidations from './email/reminders/validations'

export default {
  defaultView,
  defaultLook,
  lastSelectedView,
  emailRemindersNews,
  emailRemindersSuggestions,
  emailRemindersValidations,
  demo
}
