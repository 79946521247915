<template lang="pug">
  v-dialog(
      :value="show"
      @input="onClose")
      v-card(style="width: 650px")
        v-card-head
          v-card-head-label
            h2.title {{ 'ui.labels.description' | translate }}
          v-card-head-toolbar
            v-btn(
              icon
              @click="onClose") ×
        v-scrollable
          v-card-content
            template(v-if="hasTemplate")
              .content__warning
                i.icon.icon--warning-orange
                span {{ 'infotext.template_linked_description' | translate }}
              h3.title-2.mb-2 {{ 'pages.builder.prepended_description' | translate }}
              vue-simplemde(
                v-model="form.prependDescription"
                :configs="configs")
            vue-simplemde(
                v-else
                v-model="form.description"
                :configs="configs")
            template(v-if="hasTemplate")
              h3.title-2.mb-2(
                v-if="templateDescription") {{ 'pages.builder.template_description' | translate }}
              p {{ templateDescription }}
            template(v-if="hasTemplate")
              h3.title-2.mb-2 {{ 'pages.builder.appended_description' | translate }}
              vue-simplemde(
                v-model="form.appendDescription"
                :configs="configs")
</template>

<script>
import VueSimplemde from 'vue-simplemde'
import ScrollablePanel from '@/components/ui/ScrollablePanel'

export default {
  name: 'MarkdownEditorModal',

  components: {
    VueSimplemde,
    ScrollablePanel
  },

  props: {
    badge: Object,
    templateDescription: String,
    isSaving: Boolean,
    show: {
      type: Boolean,
      default: true
    }
  },

  mounted () {
    this.form.description = this.badge.description
    if (this.hasTemplate) {
      this.form.prependDescription = this.badge.template.prependDescription
      this.form.appendDescription = this.badge.template.appendDescription
    }
  },

  data: () => ({
    form: {
      description: null,
      prependDescription: null,
      appendDescription: null
    },
    configs: {
      spellChecker: false,
      status: false,
      hideIcons: [
        'guide',
        'fullscreen',
        'side-by-side'
      ]
    }
  }),

  methods: {
    onClose () {
      if (this.form.description !== this.badge.description) {
        this.onSave()
      } else if (this.hasTemplate && (this.form.prependDescription !== this.badge.template.prependDescription ||
        this.form.appendDescription !== this.badge.template.appendDescription)) {
        this.onSave()
      } else {
        this.$emit('on-close')
      }
    },

    onSave () {
      this.$emit('on-save', this.form)
    }
  },

  computed: {
    hasTemplate () {
      return this.badge.template !== null
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~simplemde/dist/simplemde.min.css";

  .content {
    &__warning {
      color: #FEC031;
      display: flex;
      align-items: center;
      font-size: 18px;
      margin-bottom: 20px;

      i {
        width: 60px;
        height: 60px;
        margin-right: 20px;
      }

      span {
        width: calc(100% - 60px);
      }
    }
  }
</style>
