import Vue from 'vue'
import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)

Vue.directive('focus', {
  inserted (el, binding) {
    if (binding.value || !binding.hasOwnProperty('value')) {
      el.focus()
    }
  }
})

Vue.directive('select', {
  inserted (el) {
    el.select()
  }
})

Vue.directive('autoresize', {
  inserted (el) {
    el.style.cssText = 'height: auto; padding: 0'
    el.style.cssText = 'height: ' + (el.scrollHeight + 10) + 'px'
  },
  update (el) {
    el.style.cssText = 'height: auto; padding: 0'
    el.style.cssText = 'height: ' + (el.scrollHeight + 10) + 'px'
  }
})

/* eslint-disable no-console */
Vue.directive('hover', {
  bind (el, binding, vNode) {
    const compName = vNode.context.name
    if (typeof binding.value !== 'object') {
      let warn = `[v-hover]: provided expression '${binding.expression}' is not an object, but it needs to be.`
      if (compName) { warn += `\nFound in component '${compName}'` }
      console.warn(warn)
    }
    if (!binding.value.over && !binding.value.leave) {
      let warn = `[v-hover]: object provided does not have 'over' or 'leave' properties. Needs at least one to be of use`
      if (compName) { warn += `\nFound in component '${compName}'` }
      console.warn(warn)
    }
    el.__vHoverOver__ = binding.value.over || (() => {})
    el.__vHoverLeave__ = binding.value.leave || (() => {})

    el.addEventListener('mouseover', el.__vHoverOver__)
    el.addEventListener('mouseleave', el.__vHoverLeave__)
  },

  unbind (el, binding) {
    el.removeEventListener('mouseover', el.__vHoverOver__)
    el.removeEventListener('mouseleave', el.__vHoverLeave__)
    delete el.__vHoverOver__
    delete el.__vHoverLeave__
  }
})
