import defaultView from './default-view'
import dataSync from './data-sync'
import companyInfo from './company-info'
import defaultLook from './default-look'
import disableLookSelection from './disable-look-selection'
import emailReminders from './email-reminders'
import tokens from './tokens'
import general from './general'

export default {
  defaultView,
  dataSync,
  companyInfo,
  defaultLook,
  disableLookSelection,
  tokens,
  emailReminders,
  general
}
