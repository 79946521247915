<template lang="pug">
  v-dropdown(
    id="header-topbar"
    right
    medium
    auto-close
    @toggle="toggleMenu")
    .header__topbar-item
      .header__topbar-profile
        .header__topbar-profile-wrapper
          span.header__topbar-username {{ fullName }}
          span.header__topbar-position {{ position }}
        img.header__topbar-icon(:src="avatarUrl")
    template(slot="content")
      v-card
        .header__topbar-menu
          .header__topbar-menu-item.link(@click="openProfile")
            .header__topbar-menu-item-details
              .header__topbar-menu-item-title {{ 'app.header.my_account'  | translate}}
              .header__topbar-menu-item-desc {{ 'app.header.account_settings_and_more'  | translate}}
          .header__topbar-menu-item.link(@click="pushToHelpCenter")
            .header__topbar-menu-item-details
              .header__topbar-menu-item-title {{ 'app.header.help_center'  | translate}}
              .header__topbar-menu-item-desc {{ 'app.header.skilltree_help_center'  | translate}}
          .header__topbar-menu-item.link(name="feedback-link" @click="openFeedbackMenu")
            .header__topbar-menu-item-details
              .header__topbar-menu-item-title {{ 'app.header.feedback'  | translate}}
              .header__topbar-menu-item-desc {{ 'app.header.feedback_msg'  | translate}}
          .header__topbar-menu-item.link(v-if="isSuperAdmin" @click="pushToSettings" )
            .header__topbar-menu-item-details
              .header__topbar-menu-item-title {{ 'app.header.settings'  | translate}}
              .header__topbar-menu-item-desc {{ 'app.header.skilltree_settings'  | translate}}
          .header__topbar-menu-item.link(v-if="isDevelopment" @click="pushToDebug")
            .header__topbar-menu-item-details
              .header__topbar-menu-item-title DEBUG
              .header__topbar-menu-item-desc Settings only for development enviroment
          .header__topbar-menu-item.link(v-if="isDevelopment" @click="openComponentsView")
            .header__topbar-menu-item-details
              .header__topbar-menu-item-title COMPONENTS
              .header__topbar-menu-item-desc Page for components demo
          div(@click.stop)
            .header__topbar-menu-item.logout-menu-item
              button.btn-logout.btn.btn-link.btn-bold(@click="logout") {{ 'app.header.logout'  | translate}}
            .header__topbar-menu-item.version-menu-item
              .header__topbar-menu-item-details
                .header__topbar-menu-item-desc {{ 'ui.other.v'  | translate }}{{ versionNameByEnv }}

</template>

<script>
import api from '@/api'
import { ENV } from '@/util/constants'
import { mapGetters, mapActions } from 'vuex'
import helpers from '@/util/helpers'

export default {
  name: 'Profile',
  methods: {
    ...mapActions('debug', [
      'setShowDebugModal'
    ]),
    ...mapActions('app', ['setisThirdPartyLogoutEnabled', 'setIsThirdPartyDevLoginEnabled', 'toggleFeedbackModal']),

  ...mapActions('treeView', [
      'setIsRecommendedSkillsDrawerOpened'
    ]),
    openProfile () {
      this.navigate({ path: '/profile' })
    },

    openFeedbackMenu () {
      this.toggleFeedbackModal(true)
    },

    openComponentsView () {
      this.navigate({ path: '/components' })
    },

    navigate (item) {
      if (this.$route.path !== item.path) {
        this.$router.push(item.path)
      }
    },

    async logout () {
      await api.logout()
      if (this.isThirdPartyLoginEnabled || this.isThirdPartyDevLoginEnabled) {
        await this.setisThirdPartyLogoutEnabled(true)
      }
      this.$router.push('/login')

      if (this.isThirdPartyDevLoginEnabled) {
        this.setIsThirdPartyDevLoginEnabled(false)
      }
    },

    pushToDebug () {
      this.setShowDebugModal(true)
    },

    pushToHelpCenter () {
      const langId = this.userInfo.language.id === 'en' ? 'en-us' : this.userInfo.language.id
      window.open(`https://skilltree.tawk.help/${langId}`, '_blank').focus()
    },

    pushToSettings () {
      this.navigate({ path: '/settings' })
    },
    toggleMenu (status) {
      this.closeMyProfile(status)
    },
    closeMyProfile (bool) {
      if (bool) {
        this.setIsRecommendedSkillsDrawerOpened(false)
      }
    }
  },

  computed: {
    ...mapGetters('app', [
      'userInfo',
      'version',
      'openWithAltKeyPressed',
      'buildHash',
      'isSuperAdmin',
      'isThirdPartyLoginEnabled',
      'isThirdPartyDevLoginEnabled'
    ]),

    fullName () {
      return `${this.userInfo.firstName} ${this.userInfo.lastName}`
    },

    position () {
      return this.userInfo.position ? this.userInfo.position.name : ''
    },

    isDevelopment () {
      return ENV.SHOW_DEBUG_TOOLS
    },

    avatarUrl () {
      return this.userInfo.profilePictureUrl || '/img/no-profile-picture.svg'
    },

    versionNameByEnv () {
      let fullVersion = this.version + ` (${this.buildHash})`
      if (process.env.NODE_ENV === 'development') {
        return fullVersion
      }
      return this.openWithAltKeyPressed ? fullVersion : helpers.parseVersion(this.version)
    }
  }
}
</script>

<style lang="scss" scoped>
  .debug-menu-item{
    text-align: center;

    .btn {
      font-size: 20px;
      padding: 0px;
      color: red;
    }
  }

  .logout-menu-item {
    text-align: center;
    padding-top: 0;

    .btn {
      color: red;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .version-menu-item {
    padding-top: 0;

    .header__topbar-menu-item-details {
      .header__topbar-menu-item-desc {
        font-size: 10px;
        margin: auto;
      }
    }
  }
</style>
