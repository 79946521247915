<template lang="pug">
  .template-actions(
    v-if="badge.template")
    v-dropdown(
      auto-close
      width-auto
      @toggle="onToggle")
      .template-actions__link
        i.fas.fa-link
        span {{ badge.template.name }}
        v-info-icon(:text="$t('infotext.this_badge_is_linked_to_a_template')")
        .template-actions__chevron
          chevron(
            color="#646c9a"
            :rotate="toggled ? -90 : 90")

      template(slot="content")
        v-card(
          overflow-hidden)
          v-list
            v-list-item(
              @click.native="openTemplateEditModal({ id: badge.template.id, index })")
              | {{ 'pages.builder.open_template' | translate}}
            v-list-item(
              @click.native="showUnlinkModal=true")
              | {{ 'pages.builder.unlink_from_template' | translate}}
    modal-warning(:show="showUnlinkModal"
      @close="closeUnlinkModal"
      @on-confirm="onAcceptUnlinkModal")
</template>

<script>
import { mapActions } from 'vuex'

import Chevron from '@/components/svg/Chevron'
import ModalWarning from '../templates/modals/ModalWarning'
export default {
  name: 'BadgeDetailTemplateActions',

  components: {
    Chevron,
    ModalWarning
  },

  props: {
    index: Number,
    badge: Object
  },

  data: () => ({
    toggled: false,
    showUnlinkModal: false
  }),

  methods: {
    ...mapActions('builder', [
      'openTemplateEditModal'
    ]),

    ...mapActions('badges', [
      'unlinkTemplate'
    ]),

    closeUnlinkModal () {
      this.showUnlinkModal = false
    },

    onAcceptUnlinkModal () {
      this.unlinkTemplate({ id: this.badge.id })
      this.closeUnlinkModal()
    },

    onToggle (value) {
      this.toggled = value
    }
  }
}
</script>

<style lang="scss" scoped>
  .template-actions {
    &__link {
      display: flex;
      align-items: center;
      width: fit-content;

      i {
        margin-right: 8px;
      }
    }

    &__chevron {
      margin-left: 0.5rem;
    }
  }
</style>
