<template lang="pug">
.favorites
  .favorites-header
    h2.title {{ 'pages.my.my_favorites' | translate }}
    button.link-text.clear-all-list(@click="favoritesAllDelete()")
      v-icon delete
      span {{ 'ui.buttons.clear_all_list' | translate }}
  .favorites-tabs
    tabs(:tabs="tabs", :activetab="activeTab", @tab-change="onTabChange")
  .favorites-main
    v-list-empty(v-if="!favorites.length")
      i.icon.icon--sm.icon--no-result
      span {{ 'ui.labels.no_results' | translate }}
    v-scrollable(
      v-else,
      :bottom-offset="40",
      updatable,
      :min-height="0",
      @scroll-complete="onNextPage"
    )
      favorite-item(
        v-for="(item, idx) in favorites",
        :key="item.id",
        :item="item",
        :class="{ 'favorite-item--last': idx === favorites.length - 1 }",
        @click="onSelectBadge(item)",
        @onDelete="deleteFavorites"
      )
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import FavoriteItem from './FavoriteItem.vue'
import Tabs from '@/views/my/modals/goals/Tabs'
import scrollPaginationSkeletonsMixin from '@/mixins/scroll-pagination-skeletons.mixin.js'

export default {
  components: { FavoriteItem, Tabs },

  data: () => ({
    activeTab: 1
  }),
  mixins: [scrollPaginationSkeletonsMixin],

  async mounted () {
     await this.setFavorites({ page: 1 })
    this.initScrollPagination(
      { pagination: this.favoritesPaginationParams },
      this.loadNextPage
    )
  },

  methods: {
    ...mapActions('badges', [
      'setFavorites',
      'deleteFavorites',
      'favoritesAllDelete'
    ]),

    onTabChange (tab) {
      this.activeTab = tab
    },

    onSelectBadge (badge) {
      this.$router.push(`${this.navigateTo}/badge/${badge.id}`)
    },

    async loadNextPage () {
      const page = this.favoritesPaginationParams.page
        ? this.favoritesPaginationParams.page + 1
        : 1
      const params = {
        page
      }
      if (page > this.favoritesPaginationParams.pages) return
      await this.setFavorites(params)
    }
  },

  computed: {
    ...mapGetters('badges', ['getFavorites', 'favoritesPaginationParams']),

    favorites () {
      return this.getFavorites.filter((el) =>
        this.activeTab === 1
          ? el
          : this.activeTab === 2
          ? el.isActivated
          : !el.isActivated
      )
    },

    tabs () {
      return [
        {
          tabId: 1,
          title: this.$t('ui.labels.all')
        },
        {
          tabId: 2,
          title: this.$t('ui.labels.active')
        },
        {
          tabId: 3,
          title: this.$t('ui.labels.not_active')
        }
      ]
    },
    navigateTo () {
      if (
        ['/my', '/employee'].some((item) => this.$route.path.includes(item))
      ) {
        return this.$route.path.split('/')[1] === 'employee'
          ? '/' +
              this.$route.path.split('/')[1] +
              '/' +
              this.$route.path.split('/')[2]
          : '/' + this.$route.path.split('/')[1]
      } else return '/my'
    }
  }
}
</script>

<style lang="scss">
.favorites {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
  }

  &-tabs {
    padding: 0 24px;
  }

  &-main {
    padding: 24px 24px 20px;
  }
  .clear-all-list {
    display: flex;
    align-items: flex-end;
    height: fit-content;
  }
}
</style>
