<template lang="pug">
.v-input
  input.v-input__field(
    :type="type",
    :value="value",
    :placeholder="placeholder",
    @focus="isFocus(true)"
    @blur="isFocus(false)"
    @input="$emit('input', $event.target.value)"
  )
  .v-input__append
    slot(name="append")
</template>

<script>
export default {
  name: 'VInput',

  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholder: String,
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    isFocus (bool) {
      this.$emit('focus', bool)
    }
  }
}
</script>

<style lang="scss">
.v-input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;

  &__field {
    flex: 1;
    width: 100%;
    border: none;
    background-color: transparent;

    &::placeholder {
      color: $color-inactive-text;
    }
  }
}
</style>
