import { execute, addQueryParams } from '../../utils'

const ENDPOINT = 'badges'

export default {
  request (id, params = {}, payload = null) {
    return execute('POST', addQueryParams(`${ENDPOINT}/${id}/request`, params), payload)
  },

  cancel (id) {
    return execute('POST', `${ENDPOINT}/${id}/cancel`)
  },

  approve (id, params = {}) {
    return execute('POST', addQueryParams(`${ENDPOINT}/${id}/approve`, params))
  },

  decline (id, params = {}, payload) {
    return execute('POST', addQueryParams(`${ENDPOINT}/${id}/decline`, params), payload)
  }
}
