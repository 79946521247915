<template lang="pug">
  div
    transition(
      name="slide-right"
      mode="out-in")
      forgot-password(
        v-if="step === 0"
        @next="nextStep")
      forgot-password-confirm(
        v-else-if="step === 1"
        @next="nextStep")
      forgot-password-set-new(
        v-else)
</template>

<script>
import { mapActions } from 'vuex'

import ForgotPassword from './forgot/ForgotPassword'
import ForgotPasswordConfirm from './forgot/ForgotPasswordConfirm'
import ForgotPasswordSetNew from './forgot/ForgotPasswordSetNew'

export default {
  name: 'AuthForgot',

  created () {
    if (this.$route.params.confirmationCode && this.$route.query.email) {
      this.step = 1
      this.setLoginEmail(this.$route.query.email)
      this.setPasswordResetCode(this.$route.params.confirmationCode)
    }
  },

  mounted () {
    this.clearErrors()
  },

  components: {
    ForgotPassword,
    ForgotPasswordConfirm,
    ForgotPasswordSetNew
  },

  data: () => ({
    step: 0
  }),

  methods: {
    ...mapActions('app', [
      'clearErrors',
      'setPasswordResetCode',
      'setLoginEmail'
    ]),

    nextStep () {
      this.step++
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
