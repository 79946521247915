import axios from 'axios'
import store from '@/store'
// import router from '@/router'
import cookie from '@/util/cookie'
import api from '@/api'

import {
  BASE_URL,
  TOKEN_COOKIE,
  ENV
  // INSTANCE_URL
} from '@/util/constants'

const client = axios.create({
  baseURL: BASE_URL,
  json: true
})

export const execute = async (method, resource, data, headers, cancelToken) => {
  const token = { data: null }
  if (window.location.pathname.includes('/share/')) {
    token.data = window.location.pathname.split('/share/')[1]
  } else {
    token.data = cookie.getItem(TOKEN_COOKIE)
  }

  const defaultHeaders = {
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + token.data
  }

  if (ENV.SHOW_DEBUG_TOOLS || ENV.IS_SUPPORT_MODE_ENABLED) {
    const debugToken = localStorage.getItem('debugToken')
    const endpointUrl = localStorage.getItem('endpointUrl')
    const supportToken = localStorage.getItem('supportToken')

    if (debugToken) { (headers || defaultHeaders)['X-Dev-Token'] = debugToken }
    if (supportToken) { (headers || defaultHeaders)['X-Support-Session-Token'] = supportToken }
    if (endpointUrl) {
      client.defaults.baseURL = endpointUrl
    } else if (client.defaults.baseURL !== BASE_URL) {
      client.defaults.baseURL = BASE_URL
    }
  }

  const listWithNeedInstanceCode = ['login/auth', 'login/reset', 'login/confirm', 'login/set']
  if (listWithNeedInstanceCode.includes(resource) && data.hasOwnProperty('instanceCode')) {
    defaultHeaders['X-Instance-Code'] = data.instanceCode
    delete data.instanceCode
  }

  return client({
    method,
    url: resource,
    data: data,
    headers: headers || defaultHeaders,
    cancelToken: cancelToken
  }).then(resp => {
    return {
      data: resp.data,
      headers: resp.headers
    }
  }).catch(error => {
    if (error.response) {
      const modal = {
        title: error.response.data.name,
        message: error.response.data.message
      }
      switch (error.response.status) {
        case 422:
          store.dispatch('app/setErrors', error.response.data)
          break
        case 401:
          if (cookie.hasItem(TOKEN_COOKIE)) {
            api.logout()
          }
          // if (cookie.hasItem(INSTANCE_URL) && cookie.getItem(INSTANCE_URL) !== window.location.origin) {
          //   window.location.href = cookie.getItem(INSTANCE_URL)
          // }
          // router.push('/login?logout=true')
          return {
            error: error.response
          }
        case 403:
          store.dispatch('modals/openForbiddenModal', modal, { root: true })
          return {
            error: error.response
          }
        case 404:
          if (error.response.data.code === 100 || error.response.data.code === 101) {
            store.dispatch('app/setIncorrectProvider', true)
            store.dispatch('app/setIncorrectProviderCode', error.response.data.code)
          }
          return {
            error: error.response
          }
      }
      return {
        error: true,
        errorData: error.response
      }
    }
    return { error }
  })
}

export const addQueryParams = (url, params = {}) => {
  let paramsLine = ''
  for (const key in params) {
    if (params[key] !== null) {
      if (Array.isArray(params[key])) {
        for (const item of params[key]) {
          paramsLine += paramsLine ? '&' : '?'
          paramsLine += `${key}[]=${item}`
        }
      } else {
        paramsLine += paramsLine ? '&' : '?'
        if (key === 'all') {
          paramsLine += key
        } else {
          paramsLine += key + '=' + encodeURIComponent(params[key])
        }
      }
    }
  }
  return url + paramsLine
}

export const cancelableRequest = (baseExecute) => {
  const baseExecuteFunction = baseExecute
  const CancelToken = axios.CancelToken
  let cancelCallback = null
  return {
    execute: (method, resource, data, headers) => {
      if (cancelCallback) {
        cancelCallback()
        cancelCallback = null
      }
      return baseExecuteFunction(method, resource, data, headers, new CancelToken(function executor (c) {
        cancelCallback = c
      }))
    }
  }
}
