<template lang="pug">
  .context-menu(
    v-if="show && contextMenuNode"
    v-click-outside="closeContextMenu"
    :style="position")
    v-menu
      v-menu-item(
        :disabled="!allowAddBadge"
        @click="addBadge")
        | {{ 'ui.buttons.add_badge' | translate }}
      v-menu-item(
        :disabled="!allowAddCategory"
        @click="addCategory")
        | {{ 'ui.buttons.add_category' | translate }}
      v-menu-item(
        :disabled="!allowAddBadge"
        @click="addGradationalBadge")
        | {{ 'ui.buttons.add_gradational_badge' | translate }}
      v-divider
      v-menu-item(
        :disabled="!allowCopy"
        @click="copyBadge")
        | {{ 'ui.buttons.copy' | translate }}
      v-menu-item(
        :disabled="!allowCut"
        @click="cutNode")
        | {{ 'ui.buttons.cut' | translate }}
      v-menu-item(
        :disabled="!clipboard.length || !allowPasteToCategory"
        @click="pasteBadge")
        | {{ 'ui.buttons.paste' | translate }}
      v-menu-item(
        :disabled="!allowDelete"
        @click="deleteNode")
        | {{ 'ui.buttons.delete' | translate }}
      v-divider
      v-menu-item(
        :disabled="!allowToggleVisibility")
        v-checkbox(
          :disabled="isUpdating || !allowToggleVisibility"
          :label="'pages.builder.visible' | translate"
          :value="!!contextMenuNode.props.isVisible"
          @input="changeInvisible")
</template>

<script>
import contextMenu from '@/mixins/context-menu.js'

export default {
  name: 'ContextMenu',
  mixins: [contextMenu]
}
</script>

<style lang="scss">
  .context-menu {
    position: absolute;
    width: 250px;
    background-color: white;
    border-radius: 4px;
    user-select: none;
    box-shadow: 2px 2px 20px 0 rgba(37, 47, 57, 0.15);
    z-index: 1000;
  }
</style>
