<template lang="pug">
  .v-step(
    :style="style"
    :x-placemen="placement")
    .v-step__header
      .v-step__header-title {{ title | translate }}

      .v-step__header-subtitle(v-if="!hasSubtitleSlot")
        | {{ subtitle | translate }}
      .v-step__header-subtitle(v-else)
        slot(name="subtitle")

      .v-step__header-actions
        button.v-step__header-actions-close(@click="skip") x

    .v-step__content
      slot(name="content")

    .v-step__actions
      button.v-step__btn.v-step__btn-skip(@click="skipWalkthrough")
        | {{ labels.skip | translate }}
      button.v-step__btn.v-step__btn-prev(
        v-if="!isFirst"
        @click="prevStep")
        | {{ labels.prev | translate }}
      button.v-step__btn.v-step__btn-next(
        v-if="!isLast"
        @click="nextStep")
        | {{ labels.next | translate }}
      button.v-step__btn.v-step__btn-stop(
        v-if="isLast"
        @click="finish")
        | {{ labels.stop | translate }}

    .v-step__arrow
</template>

<script>
export default {
  name: 'OnboardingStep',

  props: {
    title: String,
    subtitle: String,
    isFirst: Boolean,
    isLast: Boolean,
    labels: {
      type: Object,
      default: () => ({
        skip: 'app.onboarding.default.labels.skip',
        prev: 'app.onboarding.default.labels.prev',
        next: 'app.onboarding.default.labels.next',
        stop: 'app.onboarding.default.labels.stop'
      })
    },
    width: {
      type: String,
      default: '80%'
    },
    placement: {
      type: String,
      default: 'center'
    },
    position: Object
  },

  methods: {
    skip () {
      this.$emit('skip')
    },

    skipWalkthrough () {
      this.$emit('skip-walkthrough')
    },

    prevStep () {
      this.$emit('prev')
    },

    nextStep () {
      this.$emit('next')
    },

    finish () {
      this.$emit('finish')
    }
  },

  computed: {
    style () {
      let position = ''
      if (this.placement !== 'center') {
        position = `
          left: ${this.position.x}px;
          top: ${this.position.y}px;`

        if (this.position.vertical) {
          position += 'transform: translateX(-50%);'
        } else if (this.position.horizontal) {
          position += 'transform: translateY(-50%);'
        }
      }
      return `${position} max-width: ${this.width}`
    },

    hasSubtitleSlot () {
      return !!this.$slots['subtitle']
    }
  }
}
</script>

<style lang="scss">
  .v-step {
    width: 100%;
    position: absolute;
    background: $color-white;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #eee;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

    .v-step__arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 0.5rem;
      border-color: $color-white;
    }

    &[x-placemen="center"] {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &[x-placemen="top"] .v-step__arrow {
      border-width: 0.5rem 0.5rem 0 0.5rem;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      bottom: -0.5rem;
      left: calc(50% - 1rem);
      margin-top: 0;
      margin-bottom: 0;
    }

    &[x-placemen="bottom"] .v-step__arrow {
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
      top: -0.5rem;
      left: calc(50% - 1rem);
      margin-top: 0;
      margin-bottom: 0;
    }

    &[x-placemen="right"] .v-step__arrow {
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-left-color: transparent;
      border-top-color: transparent;
      border-bottom-color: transparent;
      left: -0.5rem;
      top: calc(50% - 1rem);
      margin-left: 0;
      margin-right: 0;
    }

    &[x-placemen="left"] .v-step__arrow {
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      right: -0.5rem;
      top: calc(50% - 1rem);
      margin-left: 0;
      margin-right: 0;
    }

    .v-step__header {
      position: relative;
      margin-bottom: 5px;

      .v-step__header-title {
        color: #333;
        font-size: 22px;
        font-weight: 600;
      }

      // .v-step__header-subtitle {
      // }

      .v-step__header-actions {
        position: absolute;
        top: 0;
        right: 0;
        height: 32px;
        display: flex;
        align-items: center;

        .v-step__header-actions-close {
          border: none;
          background-color: transparent;
        }
      }
    }

    .v-step__content {
      min-height: 1px;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      overflow: hidden;

      img {
        object-fit: contain;
        width: 90%;
        height: 330px;
        margin: 25px 0;

        &.full-width {
          width: calc(100% + 10px);
          margin-left: -5px;
          margin-right: -5px;
        }
      }
    }

    .v-step__actions {
      position: relative;
      display: flex;
      justify-content: flex-end;

      .v-step__btn {
        padding: 5px 15px;
        border: none;
        font-size: 12px;
        background-color: transparent;
        border-radius: 4px;

        &.v-step__btn-skip {
          padding: 5px 0;
          position: absolute;
          left: 0;

          &:hover {
            text-decoration: underline;
          }
        }

        &.v-step__btn-prev {
          margin-right: 10px;
        }

        &.v-step__btn-next,
        &.v-step__btn-stop {
          color: $color-white;
          background-color: $color-primary;
        }
      }
    }
  }
</style>
