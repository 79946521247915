<template lang="pug">
  div(v-if="isBadgeFileUploadEnabled")
    .form-item
      v-checkbox(
        :label="'pages.builder.file_upload_enabled' | translate"
        v-model="fileUploadEnabled")

    .form-item
      label(for="maxFilesize") {{ 'pages.builder.file_max_size' | translate }} ({{ 'pages.builder.mb' | translate }})
      input.input-field(
        name="maxFilesize"
        type="number"
        v-model="maxFilesize")
      span.error-msg {{ 'maxfilesize' | errorText }}

    .form-item
      label(for="file-extensions") {{ 'pages.builder.allowed_file_extensions' | translate }}
      v-select(
        name="file-extensions"
        chips
        v-model="selected",
        :options="options",
        @on-change="addFileExtensions")

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BadgeDetailFileUpload',

  props: {
    badge: Object
  },

  data: () => ({
    options: [
      { id: 1, name: 'pdf' },
      { id: 2, name: 'doc' },
      { id: 3, name: 'docx' },
      { id: 4, name: 'png' },
      { id: 5, name: 'jpeg' }
    ],
    selected: [],
    maxFilesize: 5,
    fileUploadEnabled: false
  }),

  methods: {
    addFileExtensions () {
    }
  },

  computed: {
    ...mapGetters('app', [
      'isBadgeFileUploadEnabled'
    ])
  }
}
</script>

<style lang="scss">
</style>
