<template lang="pug">
    div
      v-list-item(v-for="item in list" :key="item.id" @click="onSelect(item)")
        v-list-item-content
          div(style="width: 70px")
            v-donut(
              :value="getStatusValue(item)"
              :count="+item.badge.starsCount || 1"
              :color="parseColor(item)"
            )
          .skill-suggestion__skils__list--content
            v-list-item-title
              span.skill-suggestion__skils__list--title {{ item.badge.name }}
            v-list-item-subtitle
              span(:class="{orange: checkRequestedValidationBadge(item)}")  {{ parseActiveLevelName(item) }}
          span.skill-suggestion__skils__list--required  {{ item.badge.levelName || '' }}
        v-list-item-actions
          v-btn(
            icon
            v-tooltip="$t('ui.labels.open_details')"
            @click.native.stop="onSelect(item)")
              i.fas.fa-arrow-right
</template>

<script>
import Badge from '@/components/svg/Badge'

export default {
  name: 'SkillsProfileList',

  components: {
    Badge
  },

  props: {
    list: Array
  },

  methods: {
    onSelect (item) {
      this.$emit('on-select', item.badge)
    },

    parseColor (item) {
      if (item.lastActivatedChildBadge && item.lastActivatedChildBadge.starsCount < item.badge.starsCount) {
        return '#F68A37'
      }
    },

    getStatusValue (item) {
      if (item.levelGap === 0) return item.badge.starsCount || 1
      if (!item.lastActivatedChildBadge) return 0
      return item.levelGap < 0 ? +item.badge.starsCount - Math.abs(item.levelGap) : +item.badge.starsCount
    },

    parseActiveLevelName (item) {
      if (!item.lastActivatedChildBadge && !item.badge.starsCount && item.levelGap === 0) return ''
      return this.checkRequestedValidationBadge(item)
        ? this.requestedValidationBadgeTitle(item)
        : item.lastActivatedChildBadge
          ? item.lastActivatedChildBadge.levelName
          : this.$t('ui.labels._not_active')
    },

    checkRequestedValidationBadge (item) {
      return item.requestedValidationBadge
    },

    requestedValidationBadgeTitle (item) {
      return item.badge.typeId === 'BADGE'
        ? this.$t('ui.labels.confirmation_is_pending')
        : this.$t('ui.labels.confirmation_for_levelname_is_pending', { levelName: item.requestedValidationBadge.levelName })
    }
  }
}
</script>

<style lang="scss" scoped>

  .skill-suggestion__skils {

    &__list {

      &--required {
        display: flex;
        align-items: center;
        padding: 0 9px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100px;
        font-size: 12px;
        // text-align: center;
      }

      &--content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0.75rem;
        width: 159px;

        .orange{
          color: $color-orange;
        }
      }

      &--title {
        font-size: 14px;
        font-weight: bold;
      }

      .v-list__item {
        padding: 5px 20px 5px 10px !important;
      }

      .v-list__item-content {
        flex-direction: row;
        // padding: 0 15px;
      }

    }
  }
</style>
