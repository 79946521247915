<template lang="pug">
  v-card
    v-card-head
      h2.title {{ 'pages.builder.skilltree_elements' | translate }}
    v-scrollable(
        :min-height="0"
        :bottom-offset="20")
      v-card-content
        basic-elements(
        :allow-add-category="allowAddCategory"
        :allow-add-badge="allowAddBadge"
        @add-category="addCategory"
        @add-badge="addBadge"
        @add-gradational-badge="addGradationalBadge")
        element-templates(
        :allow-add-badge="allowAddBadge"
        @add-badge-by-template="addBadgeByTemplate")

</template>

<script>
import { NODE_TYPE } from '@/util/constants.js'
import { initDrop } from '@/util/drag-n-drop.js'
import { mapActions, mapGetters } from 'vuex'

import BasicElements from './basic-elements/BasicElelements'
import ElementTemplates from './element-templates/ElementTemplates'

export default {
  name: 'TreeElements',

  components: {
    BasicElements,
    ElementTemplates
  },

  props: {
    init: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: Object,
      default: () => null
    }
  },

  mounted () {
    if (this.init) {
      initDrop(this.addCategory, this.addBadge, this.addGradationalBadge, this.addBadgeByTemplate)
    }
  },

  methods: {
    ...mapActions('trees', [
      'createNode'
    ]),

    addCategory () {
      if (this.allowAddCategory) {
        const parentName = this.cursorNode.props.name || this.cursorNode.props.displayName
        const badgesCount = this.cursorNode.children.length + 1
        this.createNewNode(`${parentName} - ${this.$t('ui.other._category')} (${badgesCount})`, NODE_TYPE.CATEGORY)
      }
    },

    addBadge () {
      if (this.allowAddBadge) {
        const parentName = this.cursorNode.props.name || this.cursorNode.props.displayName
        const badgesCount = this.cursorNode.children.length + 1
        this.createNewNode(`${parentName} - ${this.$t('ui.other._badge')} (${badgesCount})`, NODE_TYPE.BADGE)
      }
    },

    addBadgeByTemplate (templateId) {
      const parentName = this.cursorNode.props.name || this.cursorNode.props.displayName
      const badgesCount = this.cursorNode.children.length + 1
      this.createNewNode(`${parentName} - ${this.$t('ui.other._badge')} (${badgesCount})`, NODE_TYPE.BADGE, templateId)
    },

    addGradationalBadge () {
      if (this.allowAddBadge) {
        const parentName = this.cursorNode.props.name || this.cursorNode.props.displayName
        const badgesCount = this.cursorNode.children.length + 1
        this.createNewNode(`${parentName} - ${this.$t('ui.other._gradational_badge')} (${badgesCount})`, NODE_TYPE.GRADATIONAL_BADGE)
      }
    },

    async createNewNode (name, type, templateId) {
      const data = {
        name: name,
        typeId: type,
        toNode: this.cursorNode,
        templateId
      }
      this.createNode(data)
    }
  },

  computed: {
    ...mapGetters('app', [
      'isDragDropMode',
      'hiddenToopTip'
    ]),

    ...mapGetters('nodeTmp', [
      'cursorNode',
      'lastAddedNode',
      'selectedBadges'
    ]),

    allowAddCategory () {
      return this.cursorNode &&
        this.cursorNode.props.typeId === NODE_TYPE.CATEGORY &&
        (!this.cursorNode.children.length ||
        this.cursorNode.children[0].props.typeId === NODE_TYPE.CATEGORY)
    },

    allowAddBadge () {
      return this.cursorNode &&
        this.cursorNode.props.typeId === NODE_TYPE.CATEGORY && this.cursorNode.props.id !== 1 &&
        (!this.cursorNode.children.length ||
        (this.cursorNode.children[0].props.typeId !== NODE_TYPE.CATEGORY))
    }
  },

  watch: {
    init (value) {
      if (value) {
        initDrop(this.addCategory, this.addBadge, this.addGradationalBadge, this.addBadgeByTemplate)
      }
    },
    activeTab (value) {
      initDrop(this.addCategory, this.addBadge, this.addGradationalBadge, this.addBadgeByTemplate)
    }
  }
}
</script>

<style lang="scss">
  .tree-elements {

    &:not(:last-child) {
      margin-bottom: 20px;
      border-bottom: 1px solid #ccc;
    }

    &__list {
      padding-top: 10px;
      padding-bottom: 20px;
    }

    &__item {
      display: flex;
      align-items: center;
      padding-right: 14px;
      border-radius: 4px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:hover {
        background-color: #f7f8fa;

        .tree-elements__item-actions--show-on-item-hover {
          visibility: visible;
        }
      }

      &--disabled {
        .tree-elements__item-label {
          cursor: not-allowed;
          opacity: .55;
        }
      }

      &-icon {
        cursor: move;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 30px;
        margin-right: 15px;
      }

      &-label {
        cursor: pointer;
        flex: 1;
        font-size: 16px;
        font-weight: 600;
      }

      &-actions {
        &--show-on-item-hover {
          visibility: hidden;
        }
      }
    }

    &__empty {
        display: flex;
        align-items: center;
        justify-content: center;
      }
  }
</style>
