import api from '@/api'

const SET_COLLABORATORS = 'SET_COLLABORATORS'
const RESET_COLLABORATORS = 'RESET_COLLABORATORS'
const SET_PAGINATION_PARAMS = 'SET_PAGINATION_PARAMS'

export default {
  namespaced: true,

  state () {
    return {
      collaborators: [],
      paginationParams: {}
    }
  },

  getters: {
    searchListCollaborators: state => state.collaborators,
    paginationParams: state => state.paginationParams
  },

  mutations: {
    [SET_COLLABORATORS] (state, data) {
      state.collaborators = data
    },
    [RESET_COLLABORATORS] (state, data) {
      state.collaborators = data
    },
    [SET_PAGINATION_PARAMS] (state, params) {
      for (const key in params) {
        state.paginationParams[key] = params[key]
      }
    }
  },

  actions: {
    async getEmployees (context, matrixID) {
      const responce = await api.matrix.listEmployees(matrixID)
      return responce
    },
    async getDepartment (context, matrixID) {
      const responce = await api.matrix.listDepartments(matrixID)
      return responce
    },
    async getLocation (context, matrixID) {
      const responce = await api.matrix.listLocation(matrixID)
      return responce
    },
    async addEmployee (context, { matrixID, ids }) {
      await api.matrix.addEmployees(matrixID, { employeeIds: ids })
      context.commit('insightsMatrix/UPDATE_MATRIX_ITEM', { matrixID, status: 'add', key: 'employeesCount' }, { root: true })
    },
    async addDepartment (context, { matrixID, ids }) {
      await api.matrix.addDepartment(matrixID, { departmentIds: ids })
    },
    async addLocation (context, { matrixID, ids }) {
      await api.matrix.addLocation(matrixID, { locationIds: ids })
    },
    async removeEmployee (context, { matrixID, ids }) {
      await api.matrix.removeEmployees(matrixID, { employeeIds: ids })
      context.commit('insightsMatrix/UPDATE_MATRIX_ITEM', { matrixID, status: 'remove', key: 'employeesCount' }, { root: true })
    },
    async removeDepartment (context, { matrixID, ids }) {
      await api.matrix.removeDepartment(matrixID, { departmentIds: ids })
    },
    async removeLocation (context, { matrixID, ids }) {
      await api.matrix.removeLocation(matrixID, { locationIds: ids })
    },

    async searchCollaborators (context, { matrixID, params: payload }) {
      const { data, headers } = await api.matrix.searchCollaborators(
        matrixID,
        payload.params
      )

      const paginationParams = { page: payload.params.page }
      paginationParams.perPage =
        (headers && parseInt(headers['x-pagination-per-page'])) || 0
      paginationParams.totalCount =
        (headers && parseInt(headers['x-pagination-total-count'])) || 0
      paginationParams.pages =
        (headers && parseInt(headers['x-pagination-page-count'])) || 1
      paginationParams.total = paginationParams.totalCount

      if (!paginationParams.page) {
        paginationParams.page = 1
      }

      context.commit(SET_PAGINATION_PARAMS, paginationParams)
      context.commit(SET_COLLABORATORS, paginationParams.page === 1 ? data : [...context.getters.searchListCollaborators, ...data])
      return { data, headers }
    }
  }
}
