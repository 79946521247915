<template lang="pug">
  .app(:class="globalClasses")
    transition(
      v-if="isAppLoaded && !incorrectProvider"
      name="fadex" mode="out-in")
      router-view

    incorrect-provider(
      v-else-if="incorrectProvider"
      :code="incorrectProviderCode")

    .app__preloader(v-else)
      v-preloader

    //- Action confirmation modal
    confirmation

    //- New modals
    demo-alert(v-if="showTrialPeriod && isDemo" @close="closeDemoAlert" :offset="offsetDemoAlert" :data-time="trialPeriod")
    tutorial-modal(v-if='showTutorial', :show="showTutorial")
    confirm-modal
    server-error-modal
    feedback(v-if="showFeedbackModal")

    debug-modal(v-if="isDevelopment")

    //- First signin onboarding
    onboarding(
      v-if="showOnboarding && !isDemo")

    //- For ui-multiselect on mobile
    .drawer-wrapper(ref="drawerWrapper")
    ui-modal(
      :show="showReadMoreNotifications.show"
      title="Comment"
      @on-close="setShowReadMoreNotifications({show: false, comment: ''})")
      .direction-column(slot='body')
        v-scrollable(
          :min-height="100"
          :max-height="350"
          :bottom-offset="20")
          span(style="white-space: pre-line") {{showReadMoreNotifications.comment}}
    decline-suggest-modal(
          v-if="showDeclineModal.show"
          :show="showDeclineModal.show"
          :show-reason="false"
          :node="showDeclineModal.node"
          @decline="onDeclineValidation($event)"
          @on-close="setDeclineMode({show: false, node: null})")
</template>

<script>
import api from '@/api'

import { mapActions, mapGetters, mapMutations } from 'vuex'

import { checkOS, getDomainFromUrl } from '@/util/utils.js'
import { ENV, OS_TYPES, BASE_AUTH_URL, TOKEN_COOKIE, INSTANCE_URL, APP_DEMO_URL } from '@/util/constants.js'
import cookie from '@/util/cookie'

import ConfirmModal from '@/components/modals/global/ConfirmModal'
import ServerErrorModal from '@/components/modals/global/ServerErrorModal'
import Feedback from '@/components/modals/global/Feedback'

import Confirmation from '@/components/modals/Confirmation'
import DebugModal from '@/debug/DebugModal'
import Onboarding from '@/components/modals/onboarding/Onboarding'
import IncorrectProvider from '@/views/info/IncorrectProvider'

import DemoAlert from '@/components/modals/global/DemoAlert.vue'
import TutorialModal from '@/components/modals/onboarding/modal-tutorial.vue'
import UiModal from '@/components/ui/Modal'
import DeclineSuggestModal from '@/components/modals/builder/DeclineSuggestModal.vue'

const TRIAL_PERIOD = 'TRIAL_PERIOD'

export default {
  name: 'App',

  components: {
    ConfirmModal,
    ServerErrorModal,
    Confirmation,
    Feedback,
    Onboarding,
    DebugModal,
    IncorrectProvider,
    DemoAlert,
    TutorialModal,
    UiModal,
    DeclineSuggestModal
  },

  data: () => ({
    offsetDemoAlert: 80
  }),

  async created () {
    this.handleAuthenticationFlow()
    await this.beforeInitApp()
    await this.newBadgeNotifications()
    await this.getValidationsWithComment()
    this.setShowAlertTrialPeriod(JSON.parse(localStorage.getItem(TRIAL_PERIOD) === null ? true : localStorage.getItem(TRIAL_PERIOD)))
    window.addEventListener('resize', this.setOffsetDemoAlert)
  },

  updated () {
    if (document.querySelector('.app-header')) {
      this.setOffsetDemoAlert()
    }
  },

  destroyed () {
    window.removeEventListener('resize', this.setOffsetDemoAlert)
  },

  methods: {
    ...mapActions('app', [
      'initApp'
    ]),
    ...mapActions('settings', ['newBadgeNotifications', 'getValidationsWithComment']),

    ...mapActions('badges', [
      'declineBadgeValidation'
    ]),
    ...mapActions('trees', [
      'getNodeById'
    ]),

    ...mapMutations('app', {
      setShowAlertTrialPeriod: 'SHOW_TRIAL_PERIOD',
      showTutorialModal: 'SHOW_TUTORIAL'
    }),
     ...mapMutations('notifications', {
      setShowReadMoreNotifications: 'SHOW_READ_MORE_NOTIFICATIONS'
    }),
    ...mapMutations('badges', {
      setDeclineMode: 'SHOW_DECLINE_MODE'
    }),

    async onDeclineValidation (payload) {
      const params = {
        forEmployeeId: this.showDeclineModal.node.data.employeeId
      }
      if (payload.declineComment) {
        params.declineComment = payload.declineComment
      }
      let result = await this.declineBadgeValidation({
        badgeId: payload.badgeId,
        params,
        payload
      })
      if (!result.error) {
        this.setDeclineMode({ show: false, node: null })
      }
    },

    async beforeInitApp () {
      const { data } = await api.loginSetupWithoutToken()
      if (data &&
        data.settings &&
        data.settings.global) {
        this.$store.commit('app/SET_THIRD_PARTY_LOGIN_PROVIDER', data.settings.global.thirdPartyLoginProvider)
        !data.settings.global.isSetupComplete ? this.$store.commit('app/SET_APP_LOADED_STATE', true) : this.initApp()
      } else {
        this.initApp()
      }
    },
     closeDemoAlert () {
        this.setShowAlertTrialPeriod(false)
        localStorage.setItem(TRIAL_PERIOD, false)
     },

     setOffsetDemoAlert () {
      const appHeader = document.querySelector('.app-header')
      if (appHeader && this.offsetDemoAlert !== appHeader.clientHeight) {
        this.offsetDemoAlert = appHeader.clientHeight
      }
     },

     handleAuthenticationFlow () {
        const { search, href, origin } = window.location
        const urlParams = new URLSearchParams(search)
        const tokenFromUrl = urlParams.get(TOKEN_COOKIE)
        const instanceUrl = cookie.getItem(INSTANCE_URL)
        const domainWithoutSubdomain = getDomainFromUrl(href, false)
        if (origin.includes('localhost')) return
        if (tokenFromUrl && !this.isDemoUrl) {
          cookie.removeItem(TOKEN_COOKIE, domainWithoutSubdomain)
          cookie.setItem(TOKEN_COOKIE, tokenFromUrl, 0, getDomainFromUrl(href, true))
          this.$router.replace({ path: this.$route.path })
        }
        if (origin === BASE_AUTH_URL && this.$route.query.logout) {
            cookie.removeItem(TOKEN_COOKIE, domainWithoutSubdomain)
            cookie.removeItem(INSTANCE_URL, domainWithoutSubdomain)
          }
        if (!this.isDemoUrl) {
          if (cookie.hasItem(INSTANCE_URL) && instanceUrl) { // fix redirect loop issue for already logged in users
            const parsedInstanceUrl = new URL(instanceUrl)
            if (parsedInstanceUrl.origin === origin && instanceUrl !== origin) {
              cookie.setItem(INSTANCE_URL, origin, 0, getDomainFromUrl(href, false))
            }
          }
          if (cookie.hasItem(INSTANCE_URL) && instanceUrl !== origin) {
            window.location.href = instanceUrl
          } else if (origin !== BASE_AUTH_URL && !cookie.hasItem(TOKEN_COOKIE)) {
            window.location.href = BASE_AUTH_URL + '/login'
          } else if (cookie.hasItem(TOKEN_COOKIE) && !cookie.hasItem(INSTANCE_URL)) { // fix blank screen issue
            cookie.removeItem(TOKEN_COOKIE)
            window.location.href = BASE_AUTH_URL + '/login'
          }
        }
     }
  },

  watch: {
    trialPeriod (value) {
      if (value && this.showTutorialAfterLogin) {
        this.showTutorialModal(true)
      }
    }
  },

  computed: {
    ...mapGetters('app', [
      'isAppLoaded',
      'didShowOnBoarding',
      'incorrectProvider',
      'showFeedbackModal',
      'trialPeriod',
      'showTutorial',
      'showTutorialAfterLogin',
      'showTrialPeriod',
      'userInfo',
      'incorrectProviderCode',
      'showFeedbackModal'
    ]),

     ...mapGetters('notifications', [
      'showReadMoreNotifications'
    ]),
    ...mapGetters('badges', ['showDeclineModal']),

    globalClasses () {
      return {
        'mac-os-fixes': checkOS(OS_TYPES.MAC)
      }
    },

    isDevelopment () {
      return ENV.SHOW_DEBUG_TOOLS
    },

    showOnboarding () {
      return this.isAppLoaded &&
        !this.didShowOnBoarding
    },

    isDemo () {
      return !!this.trialPeriod
    },

    isDemoUrl () {
      return window.location.origin.includes(APP_DEMO_URL)
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/main.scss';
</style>

<style lang="scss" scoped>
  .app {
    height: 100vh;
    width: 100%;
    overflow: hidden;

    &__preloader {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.6;
    }
  }
</style>
