<template lang="pug">
  .v-flipable(
    :class="classes")
    slot
</template>

<script>
export default {
  name: 'VFlipable',

  props: {
    flip: Boolean
  },

  computed: {
    classes () {
      return {
        'v-flipable--flip': this.flip
      }
    }
  }
}
</script>

<style lang="scss">
  .v-flipable {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    &:not(&--flip) {
      svg,
      img {
        margin-left: 10px;
      }
    }

    &--flip {
      flex-direction: column-reverse;

      p,
      span {
        writing-mode: vertical-lr;
        text-orientation: mixed;
        transform: rotate(180deg);

        &:not(:last-child) {
          margin-top: 10px;
        }
      }

      svg,
      img {
        transform: rotate(-90deg);

        &:not(:last-child) {
          margin-top: 10px;
        }
      }
    }
  }
</style>
