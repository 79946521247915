<template lang="pug">
  half-circle-spinner(
    :animation-duration="1000"
    color="#13b389"
    :size="size")
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners'

export default {
  name: 'VPreloader',

  props: {
    size: {
      type: Number,
      default: 60
    }
  },

  components: {
    HalfCircleSpinner
  }
}
</script>

<style>
</style>
