import { execute } from '../../utils'

const ENDPOINT = 'badges/new'

export default {
  list () {
    return execute('GET', `${ENDPOINT}`)
  },

  setSeen (badgeId) {
    return execute('PUT', `/badges/${badgeId}/new/seen`)
  },

  setAllSeen () {
    return execute('PUT', `${ENDPOINT}/seen`)
  }
}
