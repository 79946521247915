<template lang="pug">
  div(style="position:relative")
    .login-warning-alert(v-if="isDevelopment && alertData")
      span
        strong {{ alertData.title }}
      |  {{ alertData.message }}
    .portlet__head.justify-content-center
      .portlet__head-label
        .portlet__head-title
          h3.text-center {{ 'pages.auth.sign_in'  | translate}}
          h5.text-center {{ 'pages.auth.please_enter_your_email_address'  | translate}}
    .portlet__body
      form.kt-form(@submit.prevent)
        div(v-if="isDevelopment || isSupportModeEnabled")
          v-select(
            :placeholder="$t('ui.dropdown.select_employee')"
            :options="listOfEmployees"
            :disabled="disableAllAction"
            fixed
            single
            v-model="selectedEmployee"
            @on-change="onPresetClick")
        .form-group(
          v-if="step === 1"
          :class="{ 'is-invalid': isEmailInvalid }")
          input.form-control(
            type='email'
            v-focus
            :disabled="disableAllAction"
            autocomplete="username"
            :placeholder="'pages.auth.e_mail' | translate"
            v-model="email")
          span.form-text.invalid(name="error-email") {{ 'email' | errorText }}
          instance(v-if="isSupportModeEnabled" @on-load="getDevUsers")
        v-checkbox.keep-logged(
              :label="'pages.auth.keep_logged' | translate"
              :value="keepLogged"
              @input="$emit('change-loggin', $event)")
        .form-controls
          router-link(v-if="!disableAllAction" to="/forgot") {{ 'pages.auth.forgot_password'  | translate}}?
          span.inactive-link(v-else) {{ 'pages.auth.forgot_password'  | translate}}?
          .text-right
            v-btn(
              name='next'
              @click="loginHadler"
              :disabled="disableAllAction"
              :loading="loading"
              data-size="s"
              data-style="slide-up") {{'ui.buttons.next' | translate }}
        azure-redirect(v-if="!isDemoUrl")
    .under-modal-action-btns(v-if="isDevelopment")
      span.active-text(@click="openDebug" ) DEBUG

</template>

<script>
import api from '@/api'
import { ENV } from '@/util/constants'
import { mapActions, mapGetters } from 'vuex'
import { checkedIsDemo } from '@/util/utils.js'
import Instance from './common/Instance'
import AzureRedirect from './auth-services/AzureRedirect'

export default {
  name: 'AuthLogin',

  components: {
    Instance,
    AzureRedirect
  },

  props: {
    step: Number,
    loading: Boolean,
    login: Function,
    keepLogged: Boolean
  },

  mounted () {
    this.clearErrors()
    this.email = this.loginEmail

    if (this.isDevelopment || this.isSupportModeEnabled) {
      this.getDevUsers()
    }
  },

  data: () => ({
    email: '',
    ready: false,
    selectedEmployee: {},
    listOfEmployees: [],
    alertData: null,
    disableAllAction: false
  }),

  methods: {
    ...mapActions('app', [
      'setLoginEmail',
      'clearErrors'
    ]),

    ...mapActions('debug', [
      'setShowDebugModal'
    ]),

    async getDevUsers () {
      this.disableAllAction = false
      this.alertData = null

      const { data, error } = await api.employees.devList()

      if ((error && error.status === 404) || (error && !error.status)) {
        // this.disableAllAction = true
        if (localStorage.getItem('endpointUrl')) {
          let alertInfo = {
            title: this.$t('infotext.could_not_reach_the_instance'),
            message: this.$t('infotext.connect_or_reconnect_instance_msg')
          }
          this.alertData = alertInfo
        }
      } else if (error && error.status === 401) {
        let alertInfo = {
          title: this.$t('infotext.support_session_is_expired'),
          message: this.$t('infotext.please_reconnect_to_instance')
        }
        this.alertData = alertInfo
      } else {
        this.listOfEmployees = (data || []).map(el => {
          return {
            ...el,
            name: el.firstName + ' ' + el.lastName,
            id: el.email
          }
        })
      }
    },

    onPresetClick () {
      if (this.selectedEmployee.email) {
        this.$emit('on-loading', true)
        this.email = this.selectedEmployee.email
        this.setLoginEmail(this.selectedEmployee.email)
        this.$emit('login-preset', this.selectedEmployee.email, 'mind the skill-gap')
      }
    },

    loginHadler () {
      this.login(this.email, this.password)
    },

    openDebug () {
      this.setShowDebugModal(true)
    }
  },

  computed: {
    ...mapGetters('app', [
      'loginEmail',
      'errors'
    ]),

    isEmailInvalid () {
      return this.errors.find(item => item.field === 'email')
    },

    isDevelopment () {
      return ENV.SHOW_DEBUG_TOOLS
    },

    isSupportModeEnabled () {
      return ENV.IS_SUPPORT_MODE_ENABLED
    },

    isDemoUrl () {
      return checkedIsDemo()
    }
  },

  watch: {
    email (value) {
      this.setLoginEmail(value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .login-warning-alert {
    padding: 1rem;
    width: 100%;
    background-color: $color-orange;
    text-align: center;

      span {
        font-size: 1.25rem;
        font-weight: 500;

      }
  }

  .inactive-link {
    color: $color-inactive-text;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    cursor: not-allowed;
  }

  .keep-logged {
    margin-top: 24px;
  }
</style>
<style lang="scss">
.keep-logged {
  label {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
  }
}
</style>
