import api from '@/api'
const BOOKMARK_LIST = 'BOOKMARK_LIST'
const ADD_BOOKMARK = 'ADD_BOOKMARK'
const DELETE_BOOKMARK = 'DELETE_BOOKMARK'
const UPDATE_BOOKMARK = 'UPDATE_BOOKMARK'

export default {
  namespaced: true,

  state: () => ({
    bookmarkSkills: []
  }),

  getters: {
    bookmarks: state => state.bookmarkSkills
  },

  mutations: {
    [BOOKMARK_LIST]: (state, data) => {
      state.bookmarkSkills = data
    },

    [ADD_BOOKMARK]: (state, item) => {
      state.bookmarkSkills.push(item)
    },

    [DELETE_BOOKMARK]: (state, ID) => {
      state.bookmarkSkills = state.bookmarkSkills.filter(bookmark => bookmark.id !== ID)
    },

    [UPDATE_BOOKMARK]: (state, item) => {
      const idx = state.bookmarkSkills.findIndex(bookmark => bookmark.id === item.id)
      if (idx >= 0) {
        state.bookmarkSkills[idx].templateId = item.templateId
        state.bookmarkSkills[idx].templateName = item.templateName
      }
    }
  },

  actions: {
    async getBookmarkSkills ({ commit }) {
      const { data } = await api.badges.skills.external.bookmarks.bookmarkSkills()
      commit('BOOKMARK_LIST', data)
    },

    async addBookmarkSkill ({ commit }, { payload, addToUiBookmark }) {
      const { data } = await api.badges.skills.external.bookmarks.addBookmarkSkill(payload)
      if (addToUiBookmark) {
        commit('ADD_BOOKMARK', data)
      }
      return data
    },

    async deleteBookmarkSkill ({ commit }, ID) {
       await api.badges.skills.external.bookmarks.deleteBookmarkSkill(ID)
       commit('DELETE_BOOKMARK', ID)
    },

    async deleteAllBookmarkSkills ({ commit }) {
       await api.badges.skills.external.bookmarks.deleteBookmarkAllSkills()
       commit('BOOKMARK_LIST', [])
    },

    async updateBookmarkSkill ({ commit }, payload) {
      const { data } = await api.badges.skills.external.bookmarks.updateBookmarkSkill(payload)
      commit('UPDATE_BOOKMARK', data)
    }
  }
}
