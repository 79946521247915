import api from '@/api'

const SET_TEMPLATES = 'SET_TEMPLATES'
const UPDATE_TEMPLATE_BY_ID = 'UPDATE_TEMPLATE_BY_ID'
const ADD_TEMPLATE = 'ADD_TEMPLATE'
const DELETE_TEMPLATE = 'DELETE_TEMPLATE'
const UPDATE_LEVEL = 'UPDATE_LEVEL'
const DELETE_LEVEL = 'DELETE_LEVEL'
const CREATE_LEVEL = 'CREATE_LEVEL'
const SELECT_TEMPLATE = 'SELECT_TEMPLATE'

const updateNodes = async (context, templateId) => {
  const setNodes = async (badge) => {
    const node = await context.dispatch('trees/getNodeById', badge.id, { root: true })
    if (node) {
      updateNodeKeys(node.props, badge)
    } else {
      const nodeByGradtionalId = await context.dispatch('trees/getNodeBySubbadgeId', badge.id, { root: true })
      updateNodeKeys(nodeByGradtionalId.props, badge)
    }
  }

  const { data } = await api.badges.templates.getBagesWithTemplates(templateId)
  if (data && data.badges.length) {
    for (let item of data.badges) {
      setNodes(item)
    }
  }

  const updateNodeKeys = (node, badge) => {
    let isLevelDeletedOrCreated = false
    for (let el in node) {
      if (el === 'template' && node[el] && badge[el]) {
        node[el].prependDescription = badge[el].prependDescription
        node[el].appendDescription = badge[el].appendDescription
      } else if (el === 'gradationalSubBadges' && node[el] && badge[el]) {
        if (badge[el].length === node[el].length) {
          onUpdateGradationalNodes(node[el], badge[el])
        } else if (badge[el].length > node[el].length) {
          isLevelDeletedOrCreated = true
          let pushedBadge = badge[el][badge[el].length - 1]
          pushedBadge.template.name = ''
          node[el].push(pushedBadge)
          onUpdateGradationalNodes(node[el], badge[el])
        } else {
          isLevelDeletedOrCreated = true
          for (let itemIdx in node[el]) {
            let isExist = badge[el].find(item => item.id === node[el][itemIdx].id)
            if (!isExist) {
              node[el].splice(itemIdx, 1)
              break
            }
          }
          onUpdateGradationalNodes(node[el], badge[el])
        }
      } else {
        if (badge[el] !== undefined) node[el] = badge[el]
      }
    }

    const cursorNode = context.rootState.nodeTmp.cursorNode
    if (isLevelDeletedOrCreated && node.id === (cursorNode && cursorNode.props.id)) {
      context.dispatch('nodeTmp/setCursorNode', { ...cursorNode }, { root: true })
    }
  }

  const onUpdateGradationalNodes = (arrA, arrB) => {
    for (let gradNode of arrA) {
      let gradBadge = arrB.find(el => el.id === gradNode.id)
      updateNodeKeys(gradNode, gradBadge)
    }
  }
}

export default {
  namespaced: true,

  state: () => ({
    templates: [],
    selectedTemplate: null,
    templateIsLoaded: false
  }),

  getters: {
    templates: (state) => state.templates,
    selectedTemplate: (state) => state.selectedTemplate,
    templateIsLoaded: (state) => state.templateIsLoaded
  },

  mutations: {
    [SET_TEMPLATES]: (state, data) => {
      state.templates = data
      state.templateIsLoaded = true
    },

    [SELECT_TEMPLATE]: (state, data) => {
      state.selectedTemplate = data
    },

    [UPDATE_TEMPLATE_BY_ID]: (state, data) => {
      let index = state.templates.findIndex(el => el.id === data.id)
      state.templates[index] = data
      state.templates = [...state.templates]
    },

    [ADD_TEMPLATE]: (state, data) => {
      state.templates.push(data)
    },

    [DELETE_TEMPLATE]: (state, id) => {
      state.templates.splice(state.templates.findIndex(el => el.id === id), 1)
    },

    [UPDATE_LEVEL]: (state, { templateId, data }) => {
      let templateIndex = state.templates.findIndex(el => el.id === templateId)
      let levelIndex = state.templates[templateIndex].levels.findIndex(el => el.id === data.id)
      state.templates[templateIndex].levels[levelIndex] = data
    },

    [DELETE_LEVEL]: (state, { templateId, id }) => {
      let templateIndex = state.templates.findIndex(el => el.id === templateId)
      let levelIndex = state.templates[templateIndex].levels.findIndex(el => el.id === id)
      state.templates[templateIndex].levels.splice(levelIndex, 1)
    },

    [CREATE_LEVEL]: (state, { templateId, data }) => {
      let index = state.templates.findIndex(el => el.id === templateId)
      state.templates[index].levels.push(data)
    }
  },

  actions: {
    async loadTemplates (context) {
      const { data } = await api.badges.templates.list()
      context.commit(SET_TEMPLATES, data)
    },

    selectTemplate (context, data) {
      context.commit(SELECT_TEMPLATE, data)
    },

    async getById (context, id) {
      return JSON.parse(JSON.stringify(context.state.templates.find(el => el.id === id) || {}))
    },

    async updateTemplate (context, template = {}) {
      const { error, errorData, data } = await api.badges.templates.updateById(template.id, template)
      if (!error) {
        context.commit(UPDATE_TEMPLATE_BY_ID, data)
        const nodes = await context.dispatch('trees/findNodes', { template: { id: template.id } }, { root: true })
        if (nodes.length) {
          const params = {
            nodes,
            props: {
              template: { name: data.name }
            },
            updateSubbadges: true
          }
          await context.dispatch('trees/updateNodes', params, { root: true })
          if (template.hasOwnProperty('isSyncEnabled') && template.isSyncEnabled) {
            const paramsNode = {
              props: { isVisible: template.isVisible },
              updateSubbadges: false
            }
            for (let node of nodes) {
              paramsNode.node = node
              await context.dispatch('trees/updateNode', paramsNode, { root: true })
            }
          }
        }
      }
      return { error, errorData }
    },

    async createTemplate (context, template) {
      const { error, data } = await api.badges.templates.create(template)
      if (!error && data) {
        data.isNew = true
        context.commit(ADD_TEMPLATE, data)
      }
      return { error, data }
    },

    async deleteTemplate (context, id) {
      const { error, data } = await api.badges.templates.deleteById(id)
      if (!error) {
        context.commit(DELETE_TEMPLATE, id)
        const nodes = await context.dispatch('trees/findNodes', { template: { id: id } }, { root: true })
        if (nodes.length) {
          const params = {
            nodes,
            props: {
              template: null
            },
            updateSubbadges: true
          }
          await context.dispatch('trees/updateNodes', params, { root: true })
        }
      }
      return { error, data }
    },

    async createLevel (context, { templateId }) {
      const { error, data } = await api.badges.templates.createLevel({ templateId })
      if (!error) {
        updateNodes(context, templateId)
        context.commit(CREATE_LEVEL, { templateId: templateId, data: data })
      }
      return { error, data }
    },

    async updateLevel (context, { templateId, level }) {
      const { error, data, errorData } = await api.badges.templates.updateLevel(level.id, level)
      if (!error) {
        updateNodes(context, templateId)
        context.commit(UPDATE_LEVEL, { templateId: templateId, data: data })
      }
      return { error, data, errorData }
    },

    async deleteLevel (context, { templateId, id }) {
      const { error, data } = await api.badges.templates.deleteLevel(id)
      if (!error) {
        updateNodes(context, templateId)
        context.commit(DELETE_LEVEL, { templateId: templateId, id: id })
      }
      return { error, data }
    },

    async setBadgeAutocomplete (context, params) {
      const { error, data, headers } = await api.badges.skills.external.search(params)
      return { error, data, headers }
    },

    async createSkillWizardBookmark (context, payload) {
      const response = await api.badges.createSkillWizardBookmark(payload)
      return response
    }
  }
}
