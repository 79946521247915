<template lang="pug">
v-list-item.favorite-item(@click="$emit('click')")
    v-list-item-content.flex-row.align-center
      div.favorite-item--badge
        badge(
          width="20"
          height="32"
          :color="item.color"
          :level="item.starsCount")
      v-list-item-title
        strong.favorite-item--name( v-tooltip="{ content: item.name, placement: 'top-start' }") {{ item.name }}
        span.favorite-item--lvl {{ item.levelName }}
    v-list-item-actions
      div(@click.stop="$emit('onDelete', item.id)")
        v-btn.favorite-item--delete(icon)
          v-icon delete
</template>

<script>
import Badge from '@/components/svg/Badge'
export default {
  components: {
    Badge
  },
  props: {
    item: {
      type: Object,
      default: () => null
    }
  }
}
</script>

<style lang="scss" scoped>
.favorite-item {
  border-bottom: 1px dashed $color-line-dotted;
  padding: 1rem 0;
  &:hover {
    background: none;
  }
  &--last {
    border: 0;
  }
  &--badge {
    margin-right: 18px;
  }
  .v-list__item-title {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &--name {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    color: $h2-title;
    padding-right: 8px;
    white-space: nowrap;
  }
  &--lvl {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $body-text-2;
  }
  &--delete {
    ::v-deep .v-btn__content {
      color: $body-text-2;
    }
  }
}
</style>
