<template lang="pug">
  v-dropdown(
    id="search-modal"
    right
    width-auto
    auto-close
    center-on-mobile)
    .header__topbar-item
      .header__topbar-icon
        img(src="../../../assets/ui/search-icon.svg" alt="Search" v-tooltip="$t('infotext.search')")
    template(slot="content")
      v-card.search__content(elevation)
        .search-form(
          @click.stop
          :class="{ 'search-form__has-result': startSearch }")
          .input-group
            .input-group-prepend
              i.flaticon2-search-1
            input.search-form__input(
              ref="searchInput"
              v-focus
              v-model="search"
              type="text"
              :placeholder="`${$t('ui.labels.search')}...`")
            .icon-input-clear(@click.stop="clearSearch" v-if="search !== ''")
              close-icon
        v-scrollable(
          v-if="notEmpty"
          :bottom-offset="80")
          .result-wrapper
            .search-result
              search-result-employees(:items="result.employees")
              search-result-badges(:items="result.badges")
        .search__message(
          v-else-if="startSearch")
          | {{ 'ui.labels.no_record_found' | translate}}
</template>

<script>
import api from '@/api'

import SearchResultBadges from './SearchResultBadges'
import SearchResultEmployees from './SearchResultEmployees'
import closeIcon from '../../svg/VInputSearch_CloseIcon'

export default {
  name: 'Search',

  components: {
    SearchResultBadges,
    SearchResultEmployees,
    closeIcon
  },

  data: () => ({
    search: '',
    result: {
      employees: [],
      badges: []
    },
    startSearch: false
  }),
  methods: {
    clearSearch () {
      this.search = ''
      this.$refs.searchInput.focus()
    }
  },

  computed: {
    notEmpty () {
      return this.result.employees.length > 0 || this.result.badges.length > 0
    }
  },

  watch: {
    async search (value) {
      const { data } = await api.globalSearch(value)
      if (data) {
        this.result = data
      }
      this.startSearch = true
    }
  }
}
</script>

<style lang="scss">
  .search__content {
    width: 320px;
  }

  .result-wrapper {
    margin-right: -20px;
    padding-bottom: 15px;
  }

  .search__content {
    .input-group {
      align-items: center;
    }

    .icon-input-clear {
      position: absolute;
      right: 0;
      z-index: 3;
      cursor: pointer;
      transition: all .2s;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
</style>
