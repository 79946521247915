<template functional>
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5841 0.323464C11.8251 0.574397 11.8171 0.97321 11.5662 1.21424L5.34962 7.18539C4.75749 7.75415 3.822 7.75415 3.22987 7.18539L0.433327 4.49924C0.182394 4.25821 0.174365 3.8594 0.415392 3.60846C0.65642 3.35753 1.05523 3.3495 1.30617 3.59053L4.10271 6.27668C4.2072 6.37705 4.37229 6.37705 4.47678 6.27668L10.6933 0.305529C10.9443 0.0645013 11.3431 0.0725311 11.5841 0.323464Z" fill="#29A687"/>
  </svg>
</template>

<script>
export default {
  name: 'Checkbox_tickIcon'
}
</script>

<style scoped>

</style>
