<template lang="pug">
  v-card(
    :elevation="elevation")
    v-scrollable(
      :bottom-offset="10")
      v-card-wrapper(v-if="loaded")
        v-card-head
          v-editable(
            block
            :error-text="'name' | errorText"
            @change="onNameChange")
            h2.title {{ badgeInfo.name }}
        v-card-content(@click.stop)
          .badge-detail-inner
            .form-item(
              v-if="form.template")
              badge-detail-template-actions(
                :index="index"
                :badge="form")
            .form-item(
              v-if="!isSimpleBadge && !hookedBadge"
              style="width: 50%")
              label(for="levelName") {{ 'ui.labels.level_name' | translate }}
              v-info-icon(:text="$t('infotext.this_field_is_controlled_by_the_linked_template')")
              input.input-field(
                name="levelName"
                type="text"
                :disabled="form.template"
                v-model="badgeInfo.levelName"
                @focus="focusLevelName = true"
                @blur.stop="onLevelNameChange")
              span.error-msg {{ 'levelName' | errorText }}
            badge-detail-required-badges(
              :form="form"
              :node="node"
              :badge-info="badgeInfo"
              :selected="badgeInfo.requiredBadges"
              :pre-required-badges="preRequiredBadges")
            template
              badge-detail-description(
                :badge="form"
                :template-description="templateDescription"
                @change="onDescriptionChange")
              badge-detail-training-materials(
                :badge="form")
            template
              badge-detail-validation(
                :form="form"
                :badge="badgeInfo"
                :disabled="isSyncEnabled"
                @on-change-mode="onChangeToggles")
              label Settings
              template
                .checkbox-item
                  v-checkbox(
                    :label="'pages.builder.visible' | translate"
                    v-tooltip="$t('infotext.hide_this_element_for_normal_users')"
                    v-model="badgeInfo.isVisible"
                    :disabled="isSyncEnabled"
                    @input="onChangeVisible")
                  v-info-icon(:text="$t('infotext.show_this_badge_for_all_users')")
              .checkbox-item(v-if="showDropdownButtons")
                v-checkbox(
                  :label="'pages.builder.comment_for_activate_required' | translate"
                  v-model="badgeInfo.isCommentForActivatingRequired"
                  :disabled="isSyncEnabled"
                  @input="onChangeToggles")
              .checkbox-item
                v-checkbox(
                  :label="'infotext.file_upload_required' | translate"
                  v-model="badgeInfo.isFileRequired"
                  :disabled="isSyncEnabled"
                  @input="onChangeToggles")
                v-info-icon(:text="'infotext.file_upload_required__description' | translate")

              .checkbox-item
                badge-detail-expiring(
                  :badge-info="badgeInfo"
                  :disabled="isSyncEnabled"
                  @update="updateChanges")
            badge-detail-file-upload
            badge-detail-suggested(
              :badgeInfo="badgeInfo"
              :node="node")
            v-btn(
              v-if="!node.props.isSuggested"
              text
              danger
              paddingless
              v-hover="{ over: () => setHilightSubBranche(true), leave: () => setHilightSubBranche(false) }"
              @click="removeBadge")
              i.fa.fa-trash-alt
              span {{ 'ui.buttons.remove' | translate }}
</template>

<script>
import api from '@/api'

import { mapActions, mapGetters } from 'vuex'
import { NODE_TYPE, ENV } from '@/util/constants'

import BadgeDetailDescription from './BadgeDetailDescription'
import BadgeDetailFileUpload from './BadgeDetailFileUpload'
import BadgeDetailSuggested from './BadgeDetailSuggested'
import BadgeDetailRequiredBadges from './BadgeDetailRequiredBadges'
import BadgeDetailTemplateActions from './BadgeDetailTemplateActions'
import BadgeDetailValidation from './BadgeDetailValidation'
import BadgeDetailExpiring from './BadgeDetailExpiring'
import BadgeDetailTrainingMaterials from './BadgeDetailTrainingMaterials'

export default {
  name: 'BadgeDetail',

  components: {
    BadgeDetailDescription,
    BadgeDetailFileUpload,
    BadgeDetailSuggested,
    BadgeDetailRequiredBadges,
    BadgeDetailTemplateActions,
    BadgeDetailValidation,
    BadgeDetailExpiring,
    BadgeDetailTrainingMaterials
  },

  props: {
    index: Number,
    form: Object,
    node: Object,
    hookedBadge: Boolean,
    preRequiredBadges: {
      type: Array,
      default: () => []
    },
    elevation: Boolean
  },

  data: () => ({
    badgeInfo: {
      name: null,
      levelName: null,
      requiredBadges: [],
      isVisible: true,
      isFileRequired: false,
      isExpiryRequired: false,
      isCommentForActivatingRequired: false
    },
    template: null,
    loaded: false,
    prevInputValue: null,
    focusLevelName: false,
    isSyncEnabled: false
  }),

  mounted () {
    if (this.form) {
      this.init()
    }
  },

  destroyed () {
    this.onBlurInput()
    this.clearErrors()
    if (this.focusLevelName) {
      this.onLevelNameChange()
    }
  },

  methods: {
    ...mapActions('app', [
      'showConfirmDialog',
      'clearErrors'
    ]),

    ...mapActions('trees', [
      'getNodeById',
      'updateNode',
      'removeNode',
      'updateGradationalSubBadge'
    ]),

    ...mapActions('treeView', [
      'setHilightSubBranche'
    ]),

    ...mapActions('badges/templates', [
      'getById'
    ]),

    async init () {
      this.loaded = false
      if (this.form.template) {
        this.template = await this.getById(this.form.template.id)
        this.form.template.name = this.template.name
      }

      this.onChechisSyncEnabled()

      this.prevInputValue = this.form.name
      this.badgeInfo.starsCount = this.form.starsCount
      this.badgeInfo = { ...this.badgeInfo, ...this.form }
      this.badgeInfo.description = this.form.description
      this.badgeInfo.validationTypeId = this.form.validationTypeId
      this.badgeInfo.isVisible = this.node.props.isVisible
      this.badgeInfo.isFileRequired = this.form.isFileRequired
      this.badgeInfo.isExpiryRequired = this.form.isExpiryRequired
      this.badgeInfo.isCommentForActivatingRequired = this.form.isCommentForActivatingRequired || false
      this.badgeInfo.requiredBadges = this.form.requiredBadges
      this.badgeInfo.levelName = this.form.levelName
      if (this.badgeInfo.isVisible === undefined) {
        this.badgeInfo.isVisible = true
      }
      this.loaded = true
    },

    async onNameChange (name) {
      this.clearErrors()
      this.badgeInfo.name = name
      const props = {
        name: name
      }
      await this.updateNode({ node: this.node, props, updateSubbadges: true })
    },

    async onLevelNameChange () {
      this.focusLevelName = false
      this.clearErrors()
      const props = {
        levelName: this.badgeInfo.levelName,
        name: this.badgeInfo.name
      }
      if (this.form.typeId === NODE_TYPE.GRADATIONAL_SUB_BADGE) {
        props.id = this.form.id
        await this.updateGradationalSubBadge({ node: this.node, props })
      } else {
        await this.updateNode({ node: this.node, props })
      }
    },

    onBlurInput () {
      if (this.prevInputValue !== this.badgeInfo.name) {
        this.prevInputValue = this.badgeInfo.name
        this.updateChanges()
      }
    },

    onChangeToggles () {
      this.updateChanges()
    },

    async onChangeVisible () {
      const props = {
        isVisible: this.badgeInfo.isVisible
      }
      await this.updateNode({ node: this.node, props })
    },

    async onDescriptionChange (form) {
      const props = {}
      if (this.form.template) {
        props.description = `${form.prependDescription}\n\n${this.templateDescription || ''}\n\n${form.appendDescription || ''}`
        const payload = {
          prependDescription: form.prependDescription || '',
          appendDescription: form.appendDescription || ''
        }
        props.template = payload
        await api.badges.updateTemplate(this.form.id, payload)
      } else {
        props.description = form.description
      }
      this.updateNode({ node: { props: this.form }, props })
      this.clearErrors()
    },

    async updateChanges (fields) {
      const props = {
        ...fields,
        isFileRequired: this.badgeInfo.isFileRequired,
        isExpiryRequired: this.badgeInfo.isExpiryRequired,
        validationTypeId: this.badgeInfo.validationTypeId,
        isCommentForActivatingRequired: this.badgeInfo.isCommentForActivatingRequired
      }
      this.clearErrors()
      if (this.form.typeId === NODE_TYPE.GRADATIONAL_SUB_BADGE) {
        props.id = this.form.id
        await this.updateGradationalSubBadge({ node: this.node, props })
      } else {
        await this.updateNode({ node: this.node, props })
      }
    },

   removeNodeHandler (params) {
      this.removeNode(params)
      this.$emit('delete-hooked')
    },

    async removeBadge () {
      const options = {
        title: 'ui.modals.do_you_really_want_to_delete_badge',
        params: { node: this.node },
        onconfirm: this.removeNodeHandler
      }
      this.showConfirmDialog(options)
    },

    onChechisSyncEnabled () {
      this.isSyncEnabled = this.currentTemplate ? !!this.currentTemplate?.isSyncEnabled : false
    }
  },

  computed: {
    ...mapGetters('badges/templates', ['templates']),
    showDropdownButtons () {
      return ENV.SHOW_ACTIVATE_OR_DECLINE_WITH_COMMENT
    },

    templateDescription () {
      if (this.template && this.template.levels?.length > this.index) {
        return this.template.levels[this.index].description
      }
      return null
    },

    isSimpleBadge () {
      return this.form.typeId === NODE_TYPE.BADGE
    },

    badgeId () {
      return this.form
        ? this.form.id
        : null
    },

    currentTemplate () {
      return this.form.template ? this.templates.find(temp => temp.id === this.form.template.id) : null
    }
  },

  watch: {
    badgeId () {
      this.init()
      this.clearErrors()
    },

    form: {
      handler () {
        this.init()
      },
      deep: true
    },

    currentTemplate () {
      this.onChechisSyncEnabled()
    }
  }
}

</script>

<style lang="scss" scoped>
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input::placeholder {
    color: $color-inactive-text;
    font-size: 1rem;
  }

  .checkbox-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
</style>
