import { render, staticRenderFns } from "./VColorSelect.vue?vue&type=template&id=5e68dc02&scoped=true&lang=pug&"
import script from "./VColorSelect.vue?vue&type=script&lang=js&"
export * from "./VColorSelect.vue?vue&type=script&lang=js&"
import style0 from "./VColorSelect.vue?vue&type=style&index=0&id=5e68dc02&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e68dc02",
  null
  
)

export default component.exports