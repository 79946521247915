<template lang="pug">
  .v-drawer-toggler(
    :class="`${side}-side`"
    @click="toggle")
    span(:style="textStyles")
      | {{ title | translate }}
    .v-drawer-toggler__chevron
      chevron(
        color="#64cbb1"
        :rotate="value ? 180 : 0")

</template>

<script>
import Chevron from '@/components/svg/Chevron'

export default {
  name: 'DrawerPanelToggler',

  components: {
    Chevron
  },

  props: {
    title: String,
    side: {
      type: String,
      default: 'left'
    },
    value: Boolean
  },

  methods: {
    toggle () {
      this.$emit('input', !this.value)
    }
  },

  computed: {
    textStyles () {
      return {
        visibility: this.value
          ? 'hidden'
          : 'visible'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';

  $v-drawer-border-color: #ccc;

  .v-drawer-toggler {
    position: relative;
    cursor: pointer;
    padding: 20px 8px;
    padding-bottom: 40px;
    display: flex;
    align-items: center;

    span {
      white-space: nowrap;
      color: #666D74;
      font-size: 18px;
      font-weight: 700;
      font-family: "Nunito";
      margin-bottom: 0;
    }
  }

  @include desktop {
    .v-drawer-toggler {
      width: 41px;

      span {
        writing-mode: vertical-lr;
        text-orientation: mixed;
      }

      &.left-side {
        border-left: 1px solid $v-drawer-border-color;

        .v-drawer-toggler__chevron {
          position: absolute;
          right: 12px;
          bottom: 10px;
        }
      }

      &.right-side {
        border-right: 1px solid $v-drawer-border-color;

        span {
          transform: rotate(-180deg);
        }

        .v-drawer-toggler__chevron {
          position: absolute;
          left: 12px;
          bottom: 10px;
          transform: rotate(180deg);
        }
      }
    }
  }

  @include mobile {
    .v-drawer-toggler {
      .v-drawer-toggler__chevron {
        position: absolute;
        top: 15px;
        right: 20px;
        transform: rotate(-90deg);
      }
    }
  }
</style>
