<template lang="pug">
.drawer-panel__tabs(:class="stateClass", :style="stateStyles")
  drawer-panel-toggler.drawer-panel__tabs_sidebar(
    :tabs="tabs",
    :active-tab="activeTab",
    :type-chevron="typeChevron",
    :side="side",
    :class="[side, {'h-100': activeTab}]"
    @active-chevron="getChevron",
    @input="onChange"
  )
  .drawer-panel__tabs_content
    div(:class="showClass")
      slot
</template>

<script>
import DrawerPanelToggler from './components/DrawerPanelToggler'

export default {
  name: 'DrawerPanelTabs',

  props: {
    tabs: {
      type: Array,
      required: true,
      default: () => []
    },
    activeTab: {
      type: Object,
      default: () => null
    },
    typeChevron: {
      type: String,
      default: 'arrow'
    },
    side: {
      type: String,
      default: 'left'
    },
    addTopOffset: {
      type: Boolean,
      default: false
    },
    addSideOffset: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 384
    }
  },

  data: () => ({
    propsWidthChevron: 0,
    showClass: 'hide'
  }),

  components: {
    DrawerPanelToggler
  },

  methods: {
    onChange (tab) {
      this.$emit('on-toggle', tab)
      if (!tab.typeChevron) {
        if (this.activeTab) {
            this.propsWidthChevron = this.width
        }
      }
    },
    getChevron (item) {
      this.$emit('setWidth', item)
      if (item.id === 0) this.onChange(this.activeTab)
      else if (!this.activeTab) this.onChange(this.tabs[0])

      switch (this.typeChevron) {
        case 'burger':
        case 'slider':
          this.propsWidthChevron = item.width
          break
        default:
          this.propsWidthChevron = this.width
      }
    }
  },

  computed: {
    stateClass () {
      return {
        open: this.activeTab,
        'side-offset': this.addSideOffset,
        'top-offset': this.addTopOffset,
        'desktop-position-left': this.side === 'left',
        'desktop-position-right': this.side === 'right'
      }
    },

    stateStyles () {
      const width = this.activeTab ? this.propsWidthChevron ? this.propsWidthChevron - 40 : this.width - 40 : 0
      const style = `width: ${width}px;`
      return style
    }
  },
  watch: {
    activeTab (val, oldval) {
      const time = 350
      if (oldval === null && val) {
        setTimeout(() => {
          this.showClass = 'show'
        }, time)
      } else if (val === null && oldval) {
        this.showClass = 'hide'
      }
      if (!val) {
        this.propsWidthChevron = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/abstracts/_mixins.scss";

.drawer-panel__tabs {
  position: absolute;
  display: flex;
  min-height: 460px;
  &_sidebar {
    position: absolute;
    top: 0;

    &.left{
      left: 100%;
    }
    &.right{
      right: 100%;
    }
  }
  &_content {
    width: 100%;
    flex: 1;
    background-color: $color-white;
    border-radius: 4px 0 4px 4px;
    box-shadow: 2px 2px 20px 0 rgba(37, 47, 57, 0.15);
  }
}

@include desktop {
  .desktop-position {
    &-left,
    &-right {
      top: 20px;
      transition: all 0.4s;
      will-change: all;
      z-index: 2;
      flex-direction: row-reverse;
      }
      &-left {
        left: 0;
        &:not(.side-offset) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      &-right {
        right: 0;
        &:not(.side-offset) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      &.top-offset {
        top: 85px;
      }

  }

  .side-offset {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.show{
  transition: all 0.4s;
  opacity: 1;
}
.hide{
  opacity: 0;
}
</style>
