<template lang="pug">
div.instance-connect
  span.active-text(v-if="!instanceName" @click="onConnectToInstance")
    i.fa.fa-globe
    span Connect to instance
  v-dropdown(
      v-else
      widthAuto
      left
      autoClose)
    span.active-text
      i.fa.fa-globe
      span {{ instanceName }}
    template(slot='content')
        v-card(elevation)
          v-list-item(@click="onDisconnectInstance")
            v-list-item-content
              v-list-item-title
                span {{'ui.buttons.disconnect' | translate}}
  instance-connect(ref="instanceConnect" @on-load="onLoadData")
</template>

<script>
import InstanceConnect from '../auth-services/InstanceConnect'
export default {
  name: 'Instance',

  components: {
    InstanceConnect
  },

  mounted () {
    this.instanceName = localStorage.getItem('instanceName') || ''
  },

  data: () => ({
    instanceName: ''
  }),

  methods: {
    onConnectToInstance () {
      this.$refs.instanceConnect.connect()
    },

    onLoadData (data) {
      this.instanceName = data.instanceName
      this.$emit('on-load')
    },

    onDisconnectInstance () {
      localStorage.removeItem('instanceName')
      localStorage.removeItem('supportToken')
      localStorage.removeItem('endpointUrl')
      this.instanceName = ''
      this.$emit('on-load')
    }
  }
}
</script>

<style lang="scss" scoped>
  .instance-connect {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.5rem;
    i {
      padding-right: 3px;
    }
    span {
        font-size: 13px;
        font-weight: bold;
        text-decoration: none;
    }
  }
</style>
