import api from '@/api'

const SET_FOREIGN_EMPLOYEE_DETAIL = 'SET_FOREIGN_EMPLOYEE_DETAIL'
const SET_SHARED_PROFILE_CONFIG = 'SET_SHARED_PROFILE_CONFIG'
const SET_CREATED_PROFILE = 'SET_CREATED_PROFILE'

export default {
  namespaced: true,

  state () {
    return {
      foreignEmloyeeDetail: {},
      createdProfile: {},
      sharedProfileConfig: {}
    }
  },

  getters: {
    foreignEmloyeeDetail: (state) => state.foreignEmloyeeDetail,
    sharedProfileConfig: (state) => state.sharedProfileConfig,
    createdProfile: (state) => state.createdProfile
  },

  mutations: {
    [SET_FOREIGN_EMPLOYEE_DETAIL] (state, data) {
      state.foreignEmloyeeDetail = data
    },
    [SET_SHARED_PROFILE_CONFIG] (state, data) {
      state.sharedProfileConfig = data
    },
    [SET_CREATED_PROFILE] (state, data) {
      state.createdProfile = data
    }
  },

  actions: {
    async loadForeighEmployee (context, employeeId) {
      const { error, data } = await api.employees.getById(employeeId)
      context.commit(SET_FOREIGN_EMPLOYEE_DETAIL, data)
      return { error, data }
    },

    async getSharedProfile (context, id) {
      const data = await api.employees.getSharedProfile(id)
      context.commit(SET_SHARED_PROFILE_CONFIG, data)
    },

    async createSharedProfile (context, payload) {
      const { error, data } = await api.employees.createSharedProfile(payload)
      context.commit(SET_CREATED_PROFILE, data)
      return { error, data }
    },

    async updateSharedProfile (context, payload) {
      const { error, data } = await api.employees.updateSharedProfile(payload)
      return { error, data }
    }
  }
}
