<template lang="pug">
  .header-menu
    ul.menu__nav
      li.menu__item(
        v-for="item in itemsToDisplay"
        :key="item.id"
        :class="{ 'menu__item--active': isActive(item), 'menu__item--finder': item.id === 1 }")
        template(v-if="!item.children")
          a.menu__link( @click="navigate(item)")
            span.menu__link-text {{ item.title | translate }}
        template(v-else)
          navigation-dropdown(
            :items="item.children"
            :currentPermissions="currentPermissions"
            :permKey="item.permKey")
            a.menu__link
              span.menu__link-text {{ item.title | translate }}
</template>

<script>
import { mapGetters } from 'vuex'
import NavigationDropdown from './NavigationDropdown'

export default {
  name: 'Navigation',

  components: {
    NavigationDropdown
  },

  mounted () {
    this.hideMenuItems()
  },

  data: () => ({
    itemsToDisplay: []
  }),

  methods: {
    hideMenuItems () {
      this.itemsToDisplay = this.menuItems.filter(item => {
        return item.permKey === 'ALLOW' || this.currentPermissions[item.permKey] || (item.permKey === 'PAGE_ANALYSIS' && this.matrixList.length > 0)
      })
    },

    isActive (item) {
      return this.$route.path.includes(item.path[0]) || item.path.find(el => this.$route.path.includes(el))
    },

    navigate (item) {
      if (this.$route.path !== item.path[0]) {
        this.$router.push(item.path[0])
        this.$emit('on-navigate')
      }
    }
  },

  computed: {
    ...mapGetters('permissions', [
      'currentPermissions'
    ]),

    ...mapGetters('insightsMatrix', ['matrixList']),

    ...mapGetters('builder', ['templateModal', 'toggledBuilderLook']),
    menuItems () {
      return [
      {
        id: 0,
        title: 'app.header.my_skilltree',
        path: ['/my'],
        permKey: 'ALLOW'
        // children: [
        //   { title: 'app.header.new_tree', path: '/new-tree' }
        // ]
      },
      { id: 1, title: 'app.header.skill_finder', path: ['/skill-finder'], permKey: 'PAGE_SKILL_FINDER' },
      { id: 2, title: 'app.header.skilltree_builder', path: this.toggledBuilderLook?.id === 'LIST' ? ['/builder-new'] : ['/builder'], permKey: 'PAGE_SKILLTREE_BUILDER' },
      {
        id: 5,
        title: 'app.header.insights',
        path: ['/insights', '/skill-gap'],
        permKey: 'PAGE_ANALYSIS',
        children: [
          { title: 'ui.labels.all_skills', path: '/insights/all' },
          { title: 'ui.labels.goals', path: '/insights/goals' },
          { title: 'ui.labels.skill_matrix', path: '/skill-gap' }
        ]
      },
      {
        id: 4,
        title: 'app.header.administration',
        path: ['/administration'],
        permKey: 'PAGE_ADMINISTRATION',
        children: [
          { title: 'ui.labels.permissions', path: '/administration/permissions' },
          { title: 'ui.labels.locations', path: '/administration/locations' },
          { title: 'ui.labels.organization', path: '/administration/organization' },
          { title: 'ui.labels.employees', path: '/administration/employees' }
        ]
      }
    ]
    }
  },

  watch: {
    currentPermissions () {
      this.hideMenuItems()
    },

    matrixList () {
      this.hideMenuItems()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
