const ITEMS_PER_PAGE = 20

export default {
  data: () => ({
    skeletons: 0,
    data: null,
    fetchCallback: null
  }),

  methods: {
    initScrollPagination (data, fetchCallback) {
      this.data = data
      this.fetchCallback = fetchCallback
    },

    async onNextPage () {
      const currentPage = this.pagination.page
      const loadedCount = currentPage * ITEMS_PER_PAGE
      const totalCount = this.pagination.total
      if (this.skeletons || loadedCount >= totalCount) {
        return
      }
      const restCount = loadedCount < totalCount
        ? 5
        : 0
      const skeletonsCount = currentPage > 0
        ? restCount > ITEMS_PER_PAGE
          ? 5
          : restCount
        : 0
      this.skeletons = skeletonsCount
      if (this.fetchCallback) {
        await this.fetchCallback()
      }
      this.skeletons = 0
    }
  },

  computed: {
    pagination () {
      return this.data && this.data.pagination
    }
  }
}
