import { GET, PUT } from '@/api/types.js'
import { execute } from '../../utils'

const ENDPOINT_SKILLTREE = 'preferences/skilltree/default-look'
const ENDPOINT_BUILDER = 'preferences/skilltree/builder/default-look'

export default {
  restoreSkilltree () {
    return execute(GET, `${ENDPOINT_SKILLTREE}`)
  },

  storeSkilltree (payload) {
    return execute(PUT, `${ENDPOINT_SKILLTREE}`, payload)
  },

  options () {
    return execute(GET, `${ENDPOINT_SKILLTREE}/options`)
  },

  restoreBuilder () {
    return execute(GET, `${ENDPOINT_BUILDER}`)
  },

  storeBuilder (payload) {
    return execute(PUT, `${ENDPOINT_BUILDER}`, payload)
  }
}
