<template lang="pug">
 .sf--search-panel__input-group
    input.sf--search-panel__input-group--input(
      :value="search"
      type="text"
      :placeholder="$t('ui.inputs.type_to_search_for_skills')"
      @input="handleInput"
      @focus="onFocus")

    v-select(
      v-if="levels.length"
      fixed
      single
      simple
      hideBottomMargin
      :placeholder="$t('ui.dropdown.select_level')"
      :options="levels"
      :trackBy="'levelName'"
      :value="selectedSearchBadge"
      @input="onSelectLevel"
    )

    .sf--search-panel__input-group--icon(v-show="!showSearchClear")
      i.flaticon2-search-1
    .sf--search-panel__input-group--icon(v-show="showSearchClear" :style="{cursor:'pointer'}" @click="clearinput")
      close-icon

    transition(name="dropdown")
      v-card.sf--search-panel__input-group--dropdown(
       v-if="search && showDropdown"
       v-click-outside="onCloseDropdown")
        v-scrollable(
         v-if="badges.length"
         :min-height="0"
         :bottom-offset="80"
         @scroll-complete="onScrollEnd")
          v-list-item(v-for="item in badges"
             :key="item.id"
             @click="onSelect(item)")
            span.mr-3
             badge(
               width="25"
               :color="item.color"
               :level="item.starsCount")
            v-list-item-content
              v-list-item-title {{ item.name }} {{item.levelName}}
          v-skeleton(v-if="loading" width="100%" height="55px")
        v-skeleton(v-else-if="loading" width="100%" height="55px")
        .search__message(v-else)
         | {{ 'ui.labels.no_search_resuts'  | translate}}
</template>

<script>
import Badge from '@/components/svg/Badge'
import closeIcon from '@/components/svg/VInputSearch_CloseIcon'
import { mapGetters } from 'vuex'
import api from '@/api'

export default {
  name: 'SearchPanel',

  components: {
    Badge,
    closeIcon
  },

  props: {
    selectedSearchBadge: Object
  },

  data: () => ({
    showDropdown: false,
    search: '',
    page: 1,
    selectedLevel: {},
    badges: [],
    levels: [],
    loading: false,
    blockScroll: false
  }),

  methods: {
    clearinput () {
      this.search = ''
    },

    onCloseDropdown () {
      this.showDropdown = false
    },

    onFocus () {
      this.showDropdown = true
    },

    handleInput (event) {
      if (this.selectedSearchBadge) {
        this.onSelectLevel(null)
      }
      this.search = event.target.value
      this.page = 1
      this.loadBadges()
      this.showDropdown = true
    },

    onSelect (item) {
      this.search = item.name
      if (item.gradationalSubBadges && item.gradationalSubBadges.length) {
        this.levels = [item, ...item.gradationalSubBadges]
        this.$emit('on-select-search', this.levels[0])
      } else {
        this.$emit('on-select-search', item)
      }
      this.onCloseDropdown()
    },

    onSelectLevel (item) {
      this.$emit('on-select-search', item)
    },

    resetSearch () {
      this.search = ''
      this.levels = []
    },

    onScrollEnd () {
      if (!this.blockScroll) {
        this.blockScroll = true
        this.page++
        this.loadBadges()
      }
    },

    async loadBadges  () {
      this.loading = true
      const params = {
        q: this.search,
        noCategories: 1,
        page: this.page
      }
      const { data, headers } = await api.badges.search(params)
      if (data) {
        this.badges = this.page > 1 ? [...this.badges, ...data] : data

        if (+headers['x-pagination-page-count'] > this.page) {
          this.blockScroll = false
        } else {
          this.blockScroll = true
        }
      }
       this.loading = false
    }
  },

  computed: {
    ...mapGetters('skillFinder', [
      'options',
      'selected'
    ]),

    showSearchClear () {
      return this.search !== ''
    }
  }
}
</script>

<style lang="scss" >
.sf--search-panel__input-group{
  .v-select--simple {
      width: 350px;
    .v-select__field {
      border-width: 0px;
      background-color: transparent
    }
  }
}

</style>

<style lang="scss" scoped>
.sf--search-panel {
  &__input-group {
    position: relative;
    display: flex;
    border: 1px solid rgba(82, 63, 105, 0.05);
    border-radius: 5px;
    width: 100%;
    padding: 0px 1rem 0px 1.5rem;
    height: 40px;
    z-index: 1;

    &--icon {
      display: flex;
      align-items: center;
      i {
        margin-top: 2px;
        color: $primary-lighten;
      }
    }

    &--input {
      width: 100%;
      color: #707070;
      font-size: 14px;
      font-weight: 400;
      border: 0;
      padding: 0;
      background: none;
      background-color: $color-white;
      &::placeholder {
          color:$color-lighten-grey;
          font-weight: 400;
      }
    }

    &--dropdown {
        position: absolute;
        width: 100%;
        background-color: white;
        top: calc(100% + 1px);
        left: 0px;
        .v-list__item {
          padding: 2px 1rem !important;
          border: none;
        }
    }
  }
}

</style>
