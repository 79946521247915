<template lang="pug">
  .search-result__section(v-if="items.length")
    .search-result_title {{ 'ui.labels.employees'  | translate}}
    router-link.search-result__item(v-for="item in items" :key="item.id" :to="`/employee/${item.id}`" name="search-employees-list")
      .search-result__item-img
        img(:src="item.profilePictureUrl || '/img/no-profile-picture.svg'")
      .search-result__item-inner
        div.search-result__item-title
          | {{ item.firstName + ' ' + item.lastName }}
        .search-result__item-desc
          | {{ item.position ? item.position.name : '' }}
</template>

<script>
export default {
  name: 'SearchResultEmployees',

  props: {
    items: Array
  }
}
</script>

<style lang="scss" scoped>
   .search-result__item {
    margin-bottom: 0px !important;
    padding:10px;
  }

  .search-result__item-img {
    margin: 0 5px;
  }
</style>
