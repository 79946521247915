<template lang="pug">
v-dropdown.change-view(autoClose, right, medium)
  v-btn.change-view--btn(text, paddingless)
    icon-open-eye
  template(slot="content")
    v-card(elevation)
      v-list-item(
        v-for="item in list",
        :key="item.id",
        :class="{ active: item.id === (toggledBuilderLook && toggledBuilderLook.id) }"
        @click="onSelect(item)"
      )
        v-list-item-content
          v-list-item-title
            span {{ $t(item.title) }}
</template>

<script>
import { BUILDER_OPTIONS } from '@/util/constants'
import { mapGetters, mapActions } from 'vuex'
import IconOpenEye from '@/components/svg/icons/IconOpenEye.vue'
export default {
  name: 'ChangeBuilderLook',
  components: { IconOpenEye },
  data () {
    return {
      selectedSize: null
    }
  },
  computed: {
    ...mapGetters('builder', ['toggledBuilderLook']),
    list () {
      return BUILDER_OPTIONS
    }
  },
  methods: {
    ...mapActions('badges/templates', ['loadTemplates']),

    async onSelect (item) {
        await this.$store.commit('builder/TOGGLE_BUILDER_LOOK', item, { root: true })
        if (item.id === 'TREE') {
          this.$router.push({ name: 'BuilderOld' })
          this.loadTemplates()
        } else {
          this.$router.push({ path: '/builder-new' })
        }
    }
  }
}
</script>

<style scoped lang="scss">
  .change-view {
    position: absolute;
    bottom: 20px;
    right: 90px;
    &--btn{
      background-color: $color-white!important;
      color: $primary-lighten;
      width: 40px;
      height: 40px;
      border: 1px solid $inactive-btn!important;
      display: flex!important;
      align-items: center;
      justify-content: center!important;
      margin: 0;
      svg{
        width: 20px;
        height: 20px;
        color:$color-inactive-text;
      }
    }
    .active {
      span{
          color: $primary-lighten;
      }
    }
  }
  </style>
