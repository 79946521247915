import api from '@/api'

const SET_POSITIONS = 'SET_POSITIONS'
const ADD_POSITION = 'ADD_POSITION'
const REMOVE_POSITION = 'REMOVE_POSITION'
const UPDATE_POSITION = 'UPDATE_POSITION'
const SET_CURRENT_POSITION = 'SET_CURRENT_POSITION'

export default {
  namespaced: true,

  state () {
    return {
      positions: [],
      currentPosition: null
    }
  },

  getters: {
    positions: (state) => state.positions,
    currentPosition: (state) => state.currentPosition
  },

  mutations: {
    [SET_POSITIONS] (state, data) {
      state.positions = data
    },

    [ADD_POSITION] (state, data) {
      state.positions.push(data)
    },

    [REMOVE_POSITION] (state, data) {
      const index = state.positions
        .map(item => item.id)
        .indexOf(data.id)
      if (index !== -1) {
        state.positions.splice(index, 1)
      }
    },

    [UPDATE_POSITION] (state, data) {
      const index = state.positions
        .map(item => item.id)
        .indexOf(data.id)
      if (index !== -1) {
        for (const key in data) {
          state.positions[index][key] = data[key]
        }
      }
    },

    [SET_CURRENT_POSITION] (state, data) {
      state.currentPosition = data
    }
  },

  actions: {
    async loadPositions (context, params = {}) {
      const { data, headers } = await api.positions.list({ all: true, sort: 'name', ...params })
      context.commit(SET_POSITIONS, data)
      return { headers, data }
    },

    async createPosition (context, payload) {
      const { error, data } = await api.positions.create(payload)
      if (data) {
        context.commit(ADD_POSITION, data)
      }
      return { error }
    },

    updatePosition (context, item) {
      api.positions.updateById(item.id, item)
      context.commit(UPDATE_POSITION, item)
    },

    async deletePosition (context, item) {
      const { error } = await api.positions.deleteById(item.id)
      if (!error) {
        context.commit(REMOVE_POSITION, item)
      } else {
        // TODO: Add error code check here
        await context.dispatch('loadPositions')
        const modal = {
          title: error.data.name,
          message: error.data.message,
          unlinkTitle: 'pages.administration.move_employees_to_different_position',
          unlinkSubtitle: 'pages.administration.select_new_position_for_employees',
          unlinkOptions: context.state
            .positions
            .filter(el => el.id !== item.id),
          deleteCallback: async (toId) => {
            await api.positions.moveEmployees(item.id, toId)
            await context.dispatch('deletePosition', item)
          }
        }
        context.dispatch('modals/openForbiddenModal', modal, { root: true })
      }
    },

    openPosition (context, item) {
      context.commit(SET_CURRENT_POSITION, item)
    },

    unsetCurrentPosition (context) {
      context.commit(SET_CURRENT_POSITION, null)
    }
  }
}
