<template lang="pug">
  .modal-node-detail(@click="onModalFocus")
    .tabs-panel(v-if="isCategorySelected || hasHookedBadge")
      .tabs-panel-item(
        :class="{ active: isCategorySelected }"
        @click="selectCategory")
        hooked-badge-icon(
          :is-category-selected="true")
      .tabs-panel-item(
        v-if="hasHookedBadge"
        :class="{ active: isBadgeSelected }"
        @click="selectHookedBadge")
        hooked-badge-icon(
          :is-badge-selected="true")
      .tabs-panel-item(
        v-else
        :class="{ active: isBadgeSelected }"
        @click="createHookedBadgeHandler")
        hooked-badge-add-icon(
          :is-badge-selected="true")
    badge-detail(
      v-if="isBadgeSelected"
      :elevation="elevation"
      :node="node"
      :form="node.props"
      :hooked-badge="isHookedBadgeSelected"
      @delete-hooked="selectCategory")
    category(
      v-if="isCategorySelected"
      :elevation="elevation"
      :node="node"
      @remove="deleteCategory")
    gradational-badge(
      v-if="isGradationalBadgeSelected"
      :elevation="elevation"
      :node="node"
      :form="node.props")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { NODE_TYPE } from '@/util/constants'

import BadgeDetail from './badge-detail/BadgeDetail'
import Category from './Category'
import GradationalBadge from './GradationalBadge'

import HookedBadgeIcon from '@/components/svg/HookedBadge'
import HookedBadgeAddIcon from '@/components/svg/HookedBadgeAddIcon'

export default {
  name: 'ModalNodeDetail',

  components: {
    BadgeDetail,
    Category,
    GradationalBadge,
    HookedBadgeIcon,
    HookedBadgeAddIcon
  },

  props: {
    node: Object,
    elevation: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    isSaving: false
  }),

  methods: {
    ...mapActions('trees', [
      'removeNode',
      'createHookedBadge'
    ]),

    ...mapActions('app', [
      'showConfirmDialog',
      'clearErrors'
    ]),

    ...mapActions('nodeTmp', [
      'setCursorNode',
      'discardNodeChanges'
    ]),

    onModalFocus () {
      if (this.lastAddedNode) {
        this.discardNodeChanges()
      }
    },

    deleteCategory () {
      const options = {
        title: 'ui.modals.do_you_really_want_to_delete_category',
        params: { node: this.node },
        onconfirm: this.removeNode
      }
      this.showConfirmDialog(options)
    },

    selectCategory () {
      if (!this.isCategorySelected && this.node) {
        this.setCursorNode(this.node.parents[0])
        this.$emit('select-hooked', this.node.parents[0])
      }
    },

    selectHookedBadge () {
      if (!this.isBadgeSelected && this.node &&
        this.node.props.hookedBadge) {
        this.setCursorNode(this.node.props.hookedBadge)
        this.$emit('select-hooked', this.node.props.hookedBadge)
      }
    },

    async createHookedBadgeHandler () {
      const hookedBadge = await this.createHookedBadge(this.node.props.id)
      this.setCursorNode(hookedBadge)
    }
  },

  computed: {
    ...mapGetters('nodeTmp', [
      'lastAddedNode'
    ]),

    isCategorySelected () {
      return this.node &&
        this.node.props.typeId === NODE_TYPE.CATEGORY
    },

    isBadgeSelected () {
      return this.node &&
        (this.node.props.typeId === NODE_TYPE.BADGE ||
        this.node.props.typeId === NODE_TYPE.HOOKED_BADGE)
    },

    isGradationalBadgeSelected () {
      return this.node &&
        this.node.props.typeId === NODE_TYPE.GRADATIONAL_BADGE
    },

    isHookedBadgeSelected () {
      return this.node && this.node.props.typeId === NODE_TYPE.HOOKED_BADGE
    },

    hasHookedBadge () {
      return this.node &&
        (!!this.node.props.hookedBadge ||
        this.node.props.typeId === NODE_TYPE.HOOKED_BADGE)
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-node-detail {
    width: 380px;
  }
</style>
