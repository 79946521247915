import api from '@/api'

const CHANGE_NOTIFICATION_ON = 'CHANGE_NOTIFICATION_ON'
const SET_DECLINE_BADGE_VALIDATIONS_ONLY_WITH_COMMENT = 'SET_DECLINE_BADGE_VALIDATIONS_ONLY_WITH_COMMENT'

export default {
  namespaced: true,

  state () {
    return {
      enabledNotification: false,
      declineBadgeValidationsOnlyWithComment: null
    }
  },

  getters: {
    isEnabledNotification: (s) => s.enabledNotification,
    declineBadgeValidationsOnlyWithComment: (s) => !!s.declineBadgeValidationsOnlyWithComment
  },

  mutations: {
    [CHANGE_NOTIFICATION_ON] (state, bool) {
      state.enabledNotification = bool
    },
    [SET_DECLINE_BADGE_VALIDATIONS_ONLY_WITH_COMMENT] (state, bool) {
      state.declineBadgeValidationsOnlyWithComment = bool
    }
  },

  actions: {
    async newBadgeNotifications (context) {
      const { data } = await api.settings.general.restore()
      context.commit(CHANGE_NOTIFICATION_ON, data.isEnabled)
    },

    async changeBadgeNotifications (context, bool) {
      await api.settings.general.store({ isEnabled: bool })
      context.commit(CHANGE_NOTIFICATION_ON, bool)
    },

    async getValidationsWithComment (context) {
      const { data } = await api.settings.general.getDeclineBadgeValidationsOnlyWithComment()
      if (data) {
        context.commit(SET_DECLINE_BADGE_VALIDATIONS_ONLY_WITH_COMMENT, data.isEnabled)
      }
    },

    async updateValidationsWithComment (context, bool) {
      await api.settings.general.updateDeclineBadgeValidationsOnlyWithComment({ isEnabled: bool })
      context.commit(SET_DECLINE_BADGE_VALIDATIONS_ONLY_WITH_COMMENT, bool)
    }
  }
}
