import { OS_TYPES, APP_DEMO_URL } from '@/util/constants.js'

export const checkOS = (type) => {
  return navigator.appVersion.indexOf(type) !== -1
}

export const isSpecialKeyPressed = (event) => {
  if (checkOS(OS_TYPES.MAC)) {
    return event.metaKey
  }
  return event.ctrlKey
}

export const debounce = (fn, ms = 0) => {
  let timeoutId
  return ((...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  })()
}

export const getDomainFromUrl = (url, subDomain = false) => {
  let domain
  let protocolIndex = url.indexOf('://')
  let domainIndex = url.indexOf('/', protocolIndex + 3)
  if (domainIndex !== -1) {
    domain = url.substring(protocolIndex + 3, domainIndex)
  } else {
    domain = url.substring(protocolIndex + 3)
  }
  domain = domain.split(':')[0]
  if (!subDomain) {
    domain = domain.split('.').slice(-2).join('.')
  }
  return domain
}

export const checkedIsDemo = () => {
  const url = window.location.origin
  return url.includes(APP_DEMO_URL)
}
