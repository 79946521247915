import api from '@/api'

const SET_DEPARTMENTS = 'SET_DEPARTMENTS'
const ADD_DEPARTMENT = 'ADD_DEPARTMENT'
const REMOVE_DEPARTMENT = 'REMOVE_DEPARTMENT'
const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT'

const SET_CURRENT_DEPARTMENT = 'SET_CURRENT_DEPARTMENT'

export default {
  namespaced: true,

  state () {
    return {
      departments: [],
      currentDepartment: null
    }
  },

  getters: {
    departments: (state) => state.departments,
    currentDepartment: (state) => state.currentDepartment
  },

  mutations: {
    [SET_DEPARTMENTS] (state, data) {
      state.departments = data
    },

    [ADD_DEPARTMENT] (state, data) {
      state.departments.push(data)
    },

    [REMOVE_DEPARTMENT] (state, data) {
      const index = state.departments.indexOf(data)
      state.departments.splice(index, 1)
    },

    [UPDATE_DEPARTMENT] (state, data) {
      const index = state.departments.indexOf(data)
      state.departments[index] = data
    },

    [SET_CURRENT_DEPARTMENT] (state, data) {
      state.currentDepartment = data
    }
  },

  actions: {
    async loadDepartments (context, params = {}) {
      const { data, headers } = await api.departments.list({ all: true, sort: 'name', ...params })
      context.commit(SET_DEPARTMENTS, data)
      return { headers, data }
    },

    async createDepartment (context, item) {
      const { error, data } = await api.departments.create(item)
      if (data) {
        context.commit(ADD_DEPARTMENT, data)
      }
      return { error }
    },

    updateDepartment (context, item) {
      context.commit(UPDATE_DEPARTMENT, item)
      api.departments.updateById(item.id, item)
    },

    async deleteDepartment (context, item) {
      const { error } = await api.departments.deleteById(item.id)
      if (!error) {
        context.commit(REMOVE_DEPARTMENT, item)
      } else {
        // TODO: Add error code check here
        await context.dispatch('loadDepartments')
        const modal = {
          title: error.data.name,
          message: error.data.message,
          unlinkTitle: 'pages.administration.move_employees_to_different_department',
          unlinkSubtitle: 'pages.administration.select_new_department_for_employees',
          unlinkOptions: context.state
            .departments
            .filter(el => el.id !== item.id),
          deleteCallback: async (toId) => {
            await api.departments.moveEmployees(item.id, toId)
            await context.dispatch('deleteDepartment', item)
          }
        }
        context.dispatch('modals/openForbiddenModal', modal, { root: true })
      }
    },

    openDepartment (context, item) {
      context.commit(SET_CURRENT_DEPARTMENT, item)
    },

    unsetCurrentDepartment (context) {
      context.commit(SET_CURRENT_DEPARTMENT, null)
    },

    linkRole (context, { id, roleId }) {
      return api.departments.linkRole(id, roleId)
    },

    unlinkRole (context, { id, roleId }) {
      return api.departments.unlinkRole(id, roleId)
    }
  }
}
