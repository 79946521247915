<template lang="pug">
  .skills-select__item
    .skills-select__item-title
      .skills-select__item-icon
        badge-icon(
          :color="color"
          :level="starsCount")
      span {{ item.name }}
    .skills-select__item-description(v-html="description")
    button.btn-main.skills-select__btn-select(
      v-if="!needsValidation"
      :class="btnSelectClasses"
      @click="activateToggle")
      span {{ isActivated ? 'ui.buttons.deactivate' : 'ui.buttons.activate' | translate }}
    button.btn-main.skills-select__btn-goal(
      v-else
      :class="btnGoalClasses"
      @click="goalToggle"
      v-tooltip="{ content: 'This badge can not be activated now, since it requires additional data, but you can mark it as a goal and activate it later.', placement: 'bottom-start' }")
      i.fas.fa-star.mr-2
      span {{ isFavorite ? 'Remove from favorites' : 'Add to favorites'  }}

</template>

<script>
import marked from 'marked'
import { COLOR_PRIMARY } from '@/util/constants'

import BadgeIcon from '@/components/svg/Badge'

import helpers from '@/util/helpers'

export default {
  name: 'SelectSkillsItem',

  components: {
    BadgeIcon
  },

  props: {
    item: Object,
    isFavorite: Boolean,
    isActivated: Boolean,
    needsValidation: Boolean
  },

  methods: {
    activateToggle () {
      this.$emit('activate', this.item)
    },

    goalToggle () {
      this.$emit('goal-toggle', this.item)
    }
  },

  computed: {
    color () {
      return this.item.color || COLOR_PRIMARY
    },

    starsCount () {
      return this.item.starsCount || 0
    },

    description () {
      const description = helpers.escapeHTML(this.item.description || '')
      return marked(description)
    },

    btnSelectClasses () {
      return {
        'skills-select__btn-select--selected': this.isActivated
      }
    },

    btnGoalClasses () {
      return {
        'skills-select__btn-goal--added': this.isFavorite
      }
    }
  }
}
</script>

<style lang="scss">
  .skills-select {

    &__item {
      padding: 10px;
      border-radius: 4px;
      border: 1px solid rgb(204, 204, 204);

      &-title {
        position: relative;
        color: $h2-title;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Nunito';
        margin-bottom: 12px;
        padding-left: 40px;
        min-height: 30px;
        display: flex;
        align-items: center;
      }

      &-icon {
        position: absolute;
        left: 0;
        height: 43px;
        width: 40px;
      }

      &-description {
        color: #333;
        font-size: 12px;
        margin-bottom: 10px;

        ul {
          padding-left: 15px;
        }
      }
    }

    &__btn-select {
      margin-bottom: 0;

      &--selected {
        background-color: #d4182f !important;
      }
    }

    &__btn-goal {
      background-color: #FEB60E !important;

      &--added {
        background-color: #d4182f !important;
      }
    }
  }
</style>
