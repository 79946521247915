<template lang="pug">
  v-dialog(
    width="400"
    :value="show"
    @input="onClose")
    v-card
      v-card-head
        v-card-head-label
          h2.modal-title {{ 'pages.my.set_goal_deadline' | translate }}
        v-card-head-toolbar
          v-btn(
            icon
            gray
            @click="onClose") x
      v-card-content
        .form-group
          div(style="margin-bottom:15px") {{ 'pages.my.please_update_deadline_for_your_goal' | translate }}
          v-date-picker(
            mode="single"
            color="green"
            v-model="date"
            :attributes="attrs"
            :popover="{ placement: 'bottom', visibility: 'click' }"
            :max-date="maxDate")
            template(
              v-slot="{ inputValue, inputEvents }")
              input.form-control(
                v-on="inputEvents"
                :value="inputValue"
                :placeholder="'pages.my.set_deadline' | translate")
            template(
              v-if="date"
              v-slot:footer)
              .flex.justify-center.mb-3
                v-btn(
                  icon
                  danger
                  @click="date = null")
                  i.fas.fa-trash
          span.form-text.invalid {{ 'deadline' | errorText }}
      v-card-actions
        v-btn(
          block
          :loading="loading"
          @click="onAccept") {{ 'pages.my.update_deadline'| translate }}
</template>
<script>

import { mapActions } from 'vuex'

export default {
  name: 'DeadlineModal',

  async mounted () {
    if (this.goal && this.goal.deadlineAt) {
      this.date = new Date(this.goal.deadlineAt)
    }
  },

  destroyed () {
    this.clearErrors()
  },

  props: {
    show: Boolean,
    goal: Object
  },

  data: () => ({
    attrs: [
      {
        key: 'today',
        highlight: {
          color: 'green'
        },
        dates: new Date()
      }
    ],
    date: null,
    maxDate: null,
    loading: false
  }),

  methods: {
    ...mapActions('app', ['clearErrors']),

    onClose () {
      this.$emit('close')
    },

    onAccept () {
      this.goal.deadlineAt = this.date.toISOString().substring(0, 10)
      this.$emit('accept', this.date.toISOString().substring(0, 10))
    }
  },

  watch: {
    goal () {
      if (this.goal && this.goal.deadlineAt) {
        this.date = new Date(this.goal.deadlineAt)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
