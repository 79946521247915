<template lang="pug">
  svg(
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 31"
    :width="width"
    :fill="color")
    path.cls-1(:fill="color" d="M4.89,5.07,15.44,2.68,26,5.07V15.16a13.5,13.5,0,0,1-6.36,11.3l-4.2,2.38-4.19-2.38a13.5,13.5,0,0,1-6.36-11.3Z")
    path.cls-2(:fill="color" d="M8.73,8.38l6.71-1.65,6.72,1.65v7a8.44,8.44,0,0,1-4.05,7.18l-2.67,1.64-2.66-1.64a8.44,8.44,0,0,1-4-7.18Z")
</template>

<script>
import { COLOR_PRIMARY } from '@/util/constants'
export default {
  props: {
    width: {
      type: [String, Number],
      default: 40
    },
    color: {
      type: String,
      default: COLOR_PRIMARY
    }
  }
}
</script>

<style lang="scss" scoped>
  .cls-1,
  .cls-2{
    fill-rule: evenodd;
  }

  .cls-1{
    stroke: $color-white;
    stroke-width: 4px;
  }

  .cls-2{
    stroke: $website-color;
    stroke-width: 1.2px;
  }
</style>
