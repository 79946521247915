<template lang="pug">
  li.node-list-item
    node-item(:data="data")
    span.node-list-item__title(
      :title="name") {{ name | shortString }}
    button.close-btn(
      v-if="showRemove"
      @click="onRemove")
        icon-close(color="#000")

</template>

<script>
import IconClose from '@/components/svg/IconClose'
import NodeItem from './NodeItem'

export default {
  name: 'NodeListItem',

  props: {
    data: Object,
    showRemove: Boolean
  },

  components: {
    IconClose,
    NodeItem
  },

  filters: {
    shortString (str) {
      return str.length > 22
        ? `${str.substring(0, 22)}...`
        : str
    }
  },

  computed: {
    name () {
      return this.data.displayName
        ? this.data.displayName
        : this.data.name
    }
  },

  methods: {
    onRemove () {
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss" scoped>
  .node-list-item {
    position: relative;
    min-height: 50px;
    padding: 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: none;
    }

    &__title {
      margin-left: 35px;
      margin-right: 25px;
      padding-top: 5px;
      padding-right: 10px;
    }

    .close-btn {
      position: absolute;
      right: 5px
    }
  }
</style>
