import { GET, PUT } from '@/api/types.js'
import { execute } from '../../utils'

const ENDPOINT = 'settings/general'

export default {
  restore () {
    return execute(GET, `${ENDPOINT}/new-badge-notifications`)
  },

  store (payload) {
    return execute(PUT, `${ENDPOINT}/new-badge-notifications`, payload)
  },

  getDeclineBadgeValidationsOnlyWithComment () {
    return execute(GET, `${ENDPOINT}/decline-badge-validations-only-with-comment`)
  },

  updateDeclineBadgeValidationsOnlyWithComment (payload) {
    return execute(PUT, `${ENDPOINT}/decline-badge-validations-only-with-comment`, payload)
  }
}
