<template lang="pug">
  div
    .form-item
      .form-item_header
        label(for="validation_method") {{ 'pages.builder.validation_method' | translate }}
          v-info-icon(:text="$t('infotext.send_a_confirmation_request_to_validation_msg')")
        v-btn(
          icon
          v-if="onShowEmployeeModal"
          @click="editing=true")
          i.fa(:class="editing ? 'fa-eye' : 'fa-edit'")
      v-select(
        fixed
        single
        name="validation_method"
        :disabled="disabled"
        :options="validationMethod.options"
        v-model="validationMethod.selected"
        @on-change="onChangeMethod")

      div(v-if="selectedUsersList.length && onShowEmployeeModal")
        v-show-hide
          v-list-item(
            v-for="employee in selectedUsersList"
            :key="employee.id"
            @click="editing = true")
            v-avatar.mr-3(
              :size="30"
              :src="employee.profilePictureUrl")
            v-list-item-content
              v-list-item-title {{ employee.name }}
      div(v-else-if="loading && onShowEmployeeModal")
        v-skeleton(width="100%")
      .badge-validation__empty_users(v-else-if="onShowEmployeeModal")
        span {{ 'pages.builder.no_specific_users_selected' | translate }} {{ ' ' }}
          span.active-text(@click="editing = true") {{ 'ui.buttons.select' | translate }}

    user-selection-modal(
      v-if="editing"
      :show="editing"
      :title="'pages.builder.select_users_for_validation'"
      :value="selectedUsersList"
      @close="editing=false"
      @on-select="onSelectUser")

</template>

<script>
import { VALIDATION_MODE } from '@/util/constants'
import NodeListItem from '@/components/common/NodeListItem'
import UserSelectionModal from './UserSelectionModal'

export default {
  name: 'Validation',

  components: {
    NodeListItem,
    UserSelectionModal
  },

  props: {
    badge: Object,
    selectedUsers: Array,
    loading: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    this.init()
  },

  data: (vm) => ({
    validationMethod: {
      options: [{ id: 0, type: null, name: vm.$t('pages.builder.no_validation') },
        { id: 1, type: VALIDATION_MODE.SUPERVISOR, name: vm.$t('pages.builder.supervisor') },
        { id: 2, type: VALIDATION_MODE.SPECIFIC_USER, name: vm.$t('pages.builder.specific_users') },
        { id: 3, type: VALIDATION_MODE.SUPERVISOR_AND_SPECIFIC_USER, name: vm.$t('pages.builder.supervisor_and_specific_users') }],
      selected: {}
    },
    selectedUsersList: [],
    editing: false
  }),

  methods: {
    init () {
      this.validationMethod.selected = this.validationMethod.options.find(el => el.type === this.badge.validationTypeId) || {}
      this.selectedUsersList = [...this.selectedUsers]
    },

    onChangeMethod (data) {
      if (this.validationMethod.selected) {
        this.$emit('on-change-mode', this.validationMethod.selected.type)
      }
    },

    onSelectUser (data) {
      this.selectedUsersList = [...data]
      this.$emit('on-change-users', data)
    }
  },

  computed: {
    onShowEmployeeModal () {
      return this.validationMethod.selected.type === VALIDATION_MODE.SPECIFIC_USER ||
        this.validationMethod.selected.type === VALIDATION_MODE.SUPERVISOR_AND_SPECIFIC_USER
    }
  },

  watch: {
    badge () {
      this.init()
    },

    selectedUsers () {
      this.init()
    }
  }
}
</script>

<style scoped lang="scss">
  .badge-validation {
    &__empty_users {
      font-size: 1rem;
      text-align: center;
    }
  }
</style>
