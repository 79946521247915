<template lang="pug">
.skill-suggestion__skils__header
  v-table
    v-table-head
      th(style="width:calc(65px - 0.75rem)" v-if="!isSkillProfile")
      th.table-sort-arrows-hover(v-else style="width:80px"
           @click="onSort('levelGap', sortedLabels.levelGap)")
        | {{ 'ui.labels.status' | translate }}
        v-table-sorter(
          :show="currentSortLabel === 'levelGap'"
          :toggled="sortedLabels.levelGap")

      th.table-sort-arrows-hover(
           @click="onSort('name', sortedLabels.name)")
        | {{ !isSuggestions ? 'pages.my.my_badges' : 'pages.my.suggestions' | translate }}
        v-table-sorter(
          :show="currentSortLabel === 'name'"
          :toggled="sortedLabels.name")

      th.table-sort-arrows-hover(v-if="isSkillProfile"
        @click="onSort('starsCount', sortedLabels.starsCount)")
        | {{ 'ui.labels.required' | translate }}
        v-table-sorter(
          :show="currentSortLabel === 'starsCount'"
          :toggled="sortedLabels.starsCount")
      th(style="width:30px" v-if="isSkillProfile")
</template>

<script>

export default {
  components: {
  },

  props: {
    isSuggestions: Boolean,
    isSkillProfile: Boolean,
    sortedLabels: Object,
    currentSortLabel: String
  },

  data: () => ({

  }),

  methods: {
    onSort (fieldName, order) {
      this.$emit('on-sort', fieldName, order)
    }
  }

}
</script>

<style lang="scss" scoped>
.skill-suggestion__skils__header {
  .table-sort-arrows-hover{
    color: $h2-title;
    font-weight: bold;
    font-size: 13px;
  }
}

</style>
