<template lang="pug">
  .sd-step-comments
    v-card-head
      h2.title {{ 'ui.labels.comment'  | translate }}
    v-card-content
      textarea.form-control(
        rows="4"
        :placeholder="'ui.inputs.enter_comment' | translate"
        v-focus
        v-model="text")
      span.invalid {{ 'comment' | errorText }}
    v-card-actions
      v-btn(
        text
        @click="onCancel")
        | {{ (isFirst ? 'ui.buttons.cancel' : 'ui.buttons.back') | translate }}
      v-btn.ml-1(
        :disabled="!text"
        :loading="loading"
        @click="onAccept")
        | {{ (isLast ? 'ui.buttons.send' : 'ui.buttons.next')  | translate }}

</template>

<script>
export default {
  name: 'SkillDetailStepComment',

  props: {
    value: String,
    isFirst: Boolean,
    isLast: Boolean
  },

  mounted () {
    this.text = this.value
  },

  data: () => ({
    text: null,
    loading: false
  }),

  methods: {
    onCancel () {
      if (this.isFirst) {
        this.$emit('close')
      } else {
        this.$emit('prev')
      }
    },

    onAccept () {
      this.$emit('next')
    }
  },

  watch: {
    text (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
</style>
