<template lang="pug">
  div
    file-pond(
          v-if="isEditable"
          name="file"
          :files="files"
          :server="serverOptions"
          :allowImagePreview="false"
          :labelInvalidField="'file-pond.labelInvalidField' | translate"
          :labelFileWaitingForSize="'file-pond.labelFileWaitingForSize' | translate"
          :labelFileSizeNotAvailable="'file-pond.labelFileSizeNotAvailable' | translate"
          :labelFileLoading="'file-pond.labelFileLoading' | translate"
          :labelIdle="'file-pond.labelIdle' | translate"
          :labelFileProcessing="'file-pond.labelFileProcessing' | translate"
          :labelFileProcessingComplete="'file-pond.labelFileProcessingComplete' | translate"
          :labelFileProcessingAborted="'file-pond.labelFileProcessingAborted' | translate"
          :labelFileProcessingError="'file-pond.labelFileProcessingError' | translate"
          :labelFileProcessingRevertError="'file-pond.labelFileProcessingRevertError' | translate"
          :labelFileRemoveError="'file-pond.labelFileRemoveError' | translate"
          :labelTapToCancel="'file-pond.labelTapToCancel' | translate"
          :labelTapToRetry="'file-pond.labelTapToRetry' | translate"
          :labelTapToUndo="'file-pond.labelTapToUndo' | translate"
          @processfile="onProcessFile")
    .skill-detail-list(v-if="badge.length")
        .skill-detail-item(v-for="(item, index) in badge"  :key="index")
          a(:href="item.url" target="_blank")
            icon-file.skill-detail-item_icon
          span.skill-detail-item_name(:title="item.name")
            a(:href="item.url" target="_blank") {{ item.name }}
          .skill-detail-item_action
            a(
              download
              :href="item.url"
              target="_blank")
              i.fa.flaticon-download
            v-btn(
              v-if="isEditable"
              reset
              text
              @click="onRemove(item)")
              icon-close-circle

</template>

<script>
// import api from '@/api'
import cookie from '@/util/cookie'
import { BASE_URL, TOKEN_COOKIE, ENV } from '@/util/constants'

import vueFilePond from 'vue-filepond'
import IconFile from '@/components/svg/icons/IconFile.vue'
import IconCloseCircle from '@/components/svg/icons/IconCloseCircle.vue'

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import { mapActions } from 'vuex'
const endpointUrl = ENV.SHOW_DEBUG_TOOLS ? localStorage.getItem('endpointUrl') : null

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)
export default {
  name: 'SkillDetailFileUpload',

  components: {
    FilePond,
    IconFile,
    IconCloseCircle
  },

  props: {
    badgeId: Number,
    badge: {
      type: Array,
      default: () => []
    },
    isEditable: Boolean,
    employeeId: Number
  },

  data: () => ({
    newFile: null,
    files: []
  }),

  computed: {
    serverOptions () {
      let url = `/uploads/badges/${this.badgeId}/employees`

      if (this.employeeId) {
        url += `?forEmployeeId=${this.employeeId}`
      }

      return {
        url: endpointUrl || BASE_URL,
        process: {
          url: url,
          headers: {
          'Authorization': 'Bearer ' + cookie.getItem(TOKEN_COOKIE)
        },
        onload: this.onLoad
        },
        load: null,
        revert: null
      }
    }
  },

  methods: {
    ...mapActions('nodeTmp', ['addFile', 'removeFile']),

    onLoad (data) {
      if (data) {
        this.newFile = data
      }
    },
    onProcessFile (error, file) {
      if (file && !error) {
         this.addFile({ files: JSON.parse(this.newFile), badgeId: this.badgeId })
         this.newFile = null
         this.files = []
      }
    },
    onRemove (item) {
      this.removeFile({ uuid: item.uuid, name: item.name, badgeId: this.badgeId, url: item.url })
    }
  }
}
</script>

<style lang="scss" scoped>
  .skill-detail-list {
    margin-top: 12px;
  }
  .skill-detail-item {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $body-text-2;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &_icon {
        min-width: 16px;
        min-height: 16px;
        margin-right: 8px;
        color: #A0AAC3;
      }

      &_name {
        a:link, a:visited {
          color: #A0AAC3;
        }
      }

      &_action{
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 8px;

        .v-btn, a {
          color: $body-text-2;
          font-size: 16px;
        }
      }

      &_name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
  }
</style>
