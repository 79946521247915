<template lang="pug">
  .expansion(
    :class="expansionClasses"
    @click="toggle")
    div
      slot(name="header")
    transition(
      name="expansion"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave")
      .expansion-body(v-show="show")
        slot(name="body")

</template>

<script>
import Chevron from '@/components/svg/Chevron'

export default {
  name: 'VExpansionPanel',

  components: {
    Chevron
  },

  data: () => ({
    show: false
  }),

  methods: {
    toggle () {
      this.show = !this.show
      if (this.show) {
        this.$emit('on-open', this.show)
      } else {
        this.$emit('on-close', this.show)
      }
    },

    beforeEnter (el) {
      el.style.height = '0'
    },

    enter (el) {
      el.style.height = el.scrollHeight + 'px'
    },

    beforeLeave (el) {
      el.style.height = el.scrollHeight + 'px'
    },

    leave (el) {
      el.style.height = '0'
    }
  },

  computed: {
    expansionClasses () {
      return {
        'currentSelected': this.show
      }
    }
  }
}

</script>

<style lang="scss" scoped>
  .expansion {
    cursor: pointer;
    width: 100%;
    padding: 10px 0;

    &:hover{
      background-color: lightgrey;
    }

    .currentSelected {
      background-color: rgba(19, 179, 137, 0.15) ;
    }

    .expansion-body {
      overflow: hidden;
      transition: 150ms ease-out;
    }
  }
</style>
