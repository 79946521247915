<template lang="pug">
v-dropdown.change-view(autoClose, right, medium)
  v-btn.change-view--btn(text, paddingless)
    icon-open-eye
  template(slot="content")
    v-card(elevation)
      v-list-item(
        v-for="item in list",
        :key="item.id",
        :class="{ active: item.use === useNewTree }"
        @click="onSelect(item)"
      )
        v-list-item-content
          v-list-item-title
            span {{ $t(item.title) }}
</template>

<script>
import { TREE_OPTIONS } from '@/util/constants'
import { mapActions, mapGetters } from 'vuex'
import IconOpenEye from '@/components/svg/icons/IconOpenEye.vue'
export default {
  name: 'ChangeTreeLook',
  components: { IconOpenEye },
  computed: {
    ...mapGetters('trees', ['useNewTree']),
    list () {
      return TREE_OPTIONS
    }
  },
  methods: {
    ...mapActions('trees', ['setUseNewTree']),
    onSelect (item) {
      this.setUseNewTree(item.use)
    }
  }
}
</script>

<style scoped lang="scss">
.change-view {
  position: absolute;
  bottom: 20px;
  right: 40px;
  &--btn{
    background-color: $color-white!important;
    color: $primary-lighten;
    width: 40px;
    height: 40px;
    border: 1px solid $inactive-btn!important;
    display: flex!important;
    align-items: center;
    justify-content: center!important;
    margin: 0;
    svg{
      width: 20px;
      height: 20px;
      color:$color-inactive-text;
    }
  }
  .active {
    span{
        color: $primary-lighten;
    }
  }
}
</style>
