<template lang="pug">
  .v-card(
    :class="classes"
    :style="styles")
    slot
</template>

<script>
export default {
  name: 'VCard',

  props: {
    flat: Boolean,
    overflowHidden: Boolean,
    elevation: Boolean,
    transparent: Boolean,
    styles: String
  },

  computed: {
    classes () {
      return {
        'v-card--flat': this.flat,
        'v-card--transparent': this.transparent,
        'v-card--elevation': this.elevation,
        'v-card--overflow-hidden': this.overflowHidden
      }
    }
  }
}
</script>

<style lang="scss">
  @import './_variables.scss';

  .v-card {
    border-radius: $v-card-border-radius;

    &--flat {
      border-radius: 0;

      .v-card__title {
        padding: 20px 20px 0px !important;
      }

      .v-card__content {
        padding: 20px 20px 20px !important;
      }

      .v-card__actions {
        padding: 0 20px 20px !important;
      }
    }

    &:not(&--transparent) {
      background-color: $color-white;
      box-shadow: $v-card-shadow-1;
    }

    &--elevation {
      box-shadow: $v-card-shadow-3 !important;
    }

    &--overflow-hidden {
      overflow: hidden;
    }

    .v-card__title {
      padding: 24px 24px 0px;
    }

    .v-card__head {
      padding: 22px 24px 0px;
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      &-label {
        display: flex;
        align-content: flex-start;
        flex-direction: column;
      }

      &-toolbar {
        display: flex;
        align-content: flex-end;
      }
    }

    .v-card__content {
      padding: 18px 24px 20px;
    }

    .v-card__actions {
      padding: 0 24px 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &--left {
        justify-content: flex-start;
      }

      &--center {
        justify-content: center;
      }

      &--fixed {
        padding-top: 20px;
      }

      &--stack {
        flex-direction: column;
        align-items: stretch;
      }

      &--block {
        display: block;
      }

      .v-btn:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
</style>
