<template lang="pug">
  simple-modal(
      width="500"
      isWarning
      :title="$t('ui.labels.warning') + '!'"
      :type="modal.type"
      :confirmBtnTitle="$t('ui.buttons.unlink')"
      :show="show"
      @on-confirm="onConfirm"
      @on-cancel="onCancel")
      template(slot="body")
        p {{ 'infotext.are_you_sure_you_want_to_unlink_this_badge_from_its_template' | translate }}
        p {{ 'infotext.after_this_action_you_can_edit_all_field_of_the_badge_freely_message' | translate }}
        p {{ 'infotext.this_action_can_not_be_reversed' | translate }}

</template>

<script>
import { MODAL_TYPES } from '@/util/constants'
import SimpleModal from '@/components/modals/global/SimpleModal'

export default {
  name: 'ModalWarning',

  components: {
    SimpleModal
  },
  props: {
    show: Boolean
  },

  data: () => ({
    modal: {
      type: MODAL_TYPES.WARNING_ORANGE
    }
  }),

  methods: {
    onConfirm () {
      this.$emit('on-confirm')
    },

    onCancel () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
</style>
