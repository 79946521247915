<template lang="pug">
.sf--results
    v-card
      v-card-head
        v-card-head-label
            span.subtitle {{ 'ui.labels.results' | translate }} {{ `(${employeesCount})` }}
              v-preloader(v-if="isEmployeesLoading && filteredEmployees.length" :size="18" style="margin-left: 10px")
        v-card-head-toolbar(v-if="filteredEmployees.length")
          v-dropdown(
              width-auto
              right
              autoClose)
            img.sf--results--sort-icon(src="@/assets/img/sort.svg" alt="icon-sort"
              v-tooltip="$t('infotext.change_order_of_the_results')")
            v-card(elevation style="width:150px" slot='content')
                v-list-item(@click="onSortByName")
                  v-list-item-content
                    v-list-item-title
                      div.sf--results--sorted-labels(:class="{'sf--results--sorted-labels--active': isCurrentSorted('lastName')}")
                        span {{ 'ui.labels.last_name' | translate }}
                v-list-item(@click="onSortByMatch")
                  v-list-item-content
                    v-list-item-title
                      div.sf--results--sorted-labels(:class="{'sf--results--sorted-labels--active': isCurrentSorted('fullMatch')}")
                        span {{ 'ui.labels.match_score' | translate }}
      v-scrollable(
        :bottom-offset="85"
        :min-height="100"
        @scroll-complete="onScrollEnd")
        v-card-content(v-if="filteredEmployees.length")
          .sf--results--card(v-for="user in filteredEmployees" :key="user.id")
            .sf--results--card__head
              .sf--results--card__head--user
                v-avatar.mr-2(
                  :size="60"
                  :src="user.employee.profilePictureUrl")
                .sf--results--card__head--user--info
                  span.sf--results--card__head--user--info__match(:class="{'color-primary' : user.isFullMatch}")
                    | {{ (user.isFullMatch ? 'ui.labels.full_match' : 'ui.labels.partial_match')  | translate}}
                  span.sf--results--card__head--user--info__name {{ user.employee.firstName  + ' ' +  user.employee.lastName}}
                  span.sf--results--card__head--user--info__position {{ user.employee.position ? user.employee.position.name : '' }}
              div.sf--results--card__head--actions
                v-btn(
                  small
                  outlinedText
                  style="margin-bottom: 8px"
                  v-tooltip="{placement: 'top-start', content: $t('infotext.open_profile_and_show_details')}"
                  @click="goToEmployeePage(user.employee)") {{ 'ui.buttons.details' | translate }}
                v-btn(
                  small
                  @click="sendMail(user.employee)"
                  v-tooltip="$t('infotext.write_an_email')")  {{ 'ui.buttons.contact' | translate }}
            .sf--results--card__skills
              v-show-hide(:max-height="87" disable-offset)
                .sf--results--card__skills--item(v-for="match in user.matches"
                :class="{'sf--results--card__skills--item--not-match': !match.isMatch}") {{ match.name }}
        v-list-empty(v-if="!filteredEmployees.length && !isEmployeesLoading")
            i.icon.icon--sm.icon--no-result
            span {{ 'ui.labels.no_results' | translate }}
        v-list-empty(v-else-if="isEmployeesLoading")
          v-preloader(:size="50" style="margin-bottom: 10px")
          span {{ 'ui.labels.loading' | translate }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'SearchResult',

  methods: {
    ...mapActions('skillFinder', [
      'searchEmployees',
      'onLoadMoreEmployees',
      'setSort']),

    onSortByMatch () {
      this.setSort('-fullMatch')
    },

    onScrollEnd () {
      if (!this.isEmployeesLoading) {
        this.onLoadMoreEmployees()
      }
    },

    onSortByName () {
      this.setSort('lastName')
    },

    goToEmployeePage (user) {
      this.$router.push(`/employee/${user.id}`)
    },

    sendMail (item) {
      window.open(`mailto:${item.email}`)
    },

    isCurrentSorted (label) {
      return (this.searchEmployeesSort || '').includes(label)
    }
  },

  computed: {
    ...mapGetters('skillFinder', [
      'filteredEmployees',
      'isEmployeesLoading',
      'filteredHeaders',
      'selected',
      'searchEmployeesSort'
    ]),

    employeesCount () {
      return parseInt(this.filteredHeaders['x-pagination-total-count'] || 0)
    }
  }

}
</script>

<style lang="scss" scoped>
.sf--results {
  position: absolute;
  right: 20px;
  margin-top:15px;
  width: 400px;

  .subtitle {
    margin-top: 3px;
  }

  &--sort-icon {
    width: 25px;
    height: 15px;
  }

  &--sorted-labels {
    padding-left: 12px;
    position: relative;

    &--active::before  {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        left: -5px;
        top: 50%;
        transform: translateY(-50%);
        background: url('../../../assets/img/check-primary.svg') no-repeat center !important;
    }
  }

  &--card {
    border: 2px solid rgba(82, 63, 105, 0.05);
    border-radius: 5px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid rgba(82, 63, 105, 0.05);

      &--actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 110px;
      }

      &--user {
        display: flex;

        &--info {
          display: flex;
          flex-direction: column;

          &__match {
            color: #E4AC29;
            font-size: 13px;
          }

          &__name {
            color: #707070;
            font-size: 14px;
            font-weight: bold;
          }

          &__position {
            font-size: 13px;
          }
         }
      }
    }

    &__skills {
      padding: 10px;

      &--item {
        padding: 5px;
        padding-left: 25px;
        font-size: 13px;
        position: relative;

        &::before {
          background: url('../../../assets/img/check-primary.svg') no-repeat center;
          content: "";
          position: absolute;
          top: 49%;
          transform: translateY(-50%);
          left: 5px;
          height: 10px;
          width: 10px;
          border: none;
          background-color: transparent !important;
        }

        &--not-match::before {
          background: url('../../../assets/img/x-mark.svg') no-repeat center;
        }

      }
    }
  }

  .v-card__head {
    padding: 10px 16px 5px;

    .subtitle {
      display: flex;
      align-items: center;
    }
  }
  .v-card__content {
     padding: 10px 16px 10px
  }
}

.color-primary {
  color: $color-primary
}

.v-list__empty {
  span {
    line-height: 24px;
  }
}
</style>
