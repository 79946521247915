<template lang="pug">
  svg(
    width="20"
    height="19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg")
    path(
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.052 15.185l-5.7 2.998 1.088-6.349L.828 7.34l6.373-.926 2.85-5.775 2.851 5.775 6.373.926-4.611 4.495 1.088 6.349-5.7-2.998z"
      :fill="color")
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#DFE2EA'
    }
  }
}
</script>
