<template lang="pug">
  .scroll-wrapper(
    :class="`${scrollBarPos}-bar`"
    :style="heightStyle")
    vue-scroll(
      ref="vs"
      :ops="ops"
      @handle-scroll="handleScrollComplete")
      .scroll-inner(ref="inner")
        slot
</template>

<script>
import ResizeObserver from '@juggle/resize-observer'
import helpers from '@/util/helpers'

export default {
  name: 'ScrollablePanel',

  props: {
    minH: {
      type: Number,
      default: 100
    },
    maxH: Number,
    bottomOffset: {
      type: Number,
      default: 300
    },
    scrollBarPos: {
      type: String,
      default: 'right'
    },
    isForDropdown: Boolean
  },

  mounted () {
    this.contentHeight = this.$refs.inner.clientHeight
    helpers.windowResizeObserver.subscribe(this.onWindowResize)
    this.initResizeObserver()
    this.onWindowResize()
  },

  beforeDestroy () {
    helpers.windowResizeObserver.unsubscribe(this.onWindowResize)
    this.resizeObserver.unobserve(this.$refs.inner)
  },

  data: () => ({
    resizeObserver: null,
    ops: {
      scrollPanel: {
        scrollingX: false
      },
      rail: {
        disable: false,
        background: '#000',
        opacity: 0.1
      },
      bar: {
        disable: false,
        keepShow: true,
        opacity: 1
      },
      speed: 2000
    },
    maxHeight: 500,
    contentHeight: 0
  }),

  methods: {
    initResizeObserver () {
      this.resizeObserver = new ResizeObserver(entries => {
        // eslint-disable-next-line
        for (let entry of entries) {
          this.onResize()
        }
      })
      this.resizeObserver.observe(this.$refs.inner)
    },

    onResize () {
      this.contentHeight = this.$refs.inner.clientHeight
    },

    onWindowResize () {
      if (this.isForDropdown) {
        let topOffset = this.$refs.inner.getBoundingClientRect().top
        let maxHeight = window.innerHeight - topOffset - 40
        this.maxHeight = this.maxH || maxHeight < this.minH ? this.minH : maxHeight
      } else {
        this.maxHeight = this.maxH ? this.maxH : window.innerHeight - this.bottomOffset
      }
    },

    handleScrollComplete (vertical, horizontal, nativeEvent) {
      let scroll = vertical.scrollTop >= nativeEvent.target.scrollHeight - nativeEvent.target.clientHeight
      if (scroll) {
        this.$emit('handle-scroll-complete', this.$refs['vs'])
      }
    }
  },

  computed: {
    heightStyle () {
      let height = Math.max(this.contentHeight, this.minH)
      height = Math.min(height, this.maxHeight)
      return `height: ${height}px;`
    }
  }
}
</script>

<style lang="scss">
  .scroll-wrapper {
    position: relative;
  }
  .__vuescroll,
  .__panel,
  .__view {
    position: static !important;
  }
</style>
