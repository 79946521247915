<template lang="pug">
  .v-slider
    input(
      type="range"
      :value="value"
      step="1"
      min="0"
      max="100"
      @input="onChange")
    i.v-slider__icons-minus(@click="onSubstruct")
    i.v-slider__icons-plus(@click="onAdd")
</template>

<script>
export default {
  name: 'ZoomSlider',

  props: {
    value: Number
  },

  methods: {
    onChange (e) {
      this.$emit('input', +e.target.value)
    },

    onAdd () {
      if (this.value < 100) {
        this.$emit('input', this.value + 1)
      }
    },

    onSubstruct () {
      if (this.value > 0) {
        this.$emit('input', this.value - 1)
      }
    }
  }
}
</script>

<style lang="scss">
  .v-slider {
    position: relative;
    margin: 20px 0 5px 0;
    width: 100%;
    height: 18px;
    display: flex;
    align-items: center;

    .v-slider__icons-minus,
    .v-slider__icons-plus {
      position: absolute;
      top: 0;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background-color: rgba(0, 0, 0, 0.08);

      &:hover {
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.14);
      }
    }

    .v-slider__icons-minus {
      left: 0;

      &::before {
        position: absolute;
        content: '';
        display: block;
        left: 3px;
        top: 8px;
        width: 12px;
        height: 2px;
        background-color: $color-white;
      }
    }

    .v-slider__icons-plus {
      right: 0;

      &::before {
        position: absolute;
        content: '';
        display: block;
        left: 3px;
        top: 8px;
        width: 12px;
        height: 2px;
        background-color: $color-white;
      }

      &::after {
        position: absolute;
        content: '';
        display: block;
        top: 3px;
        left: 8px;
        width: 2px;
        height: 12px;
        background-color: $color-white;
      }
    }

    input[type=range] {
      display: block;
      margin: 0 auto;
      width: calc(100% - 60px);
      height: 8px;
      background: transparent;
      appearance: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &::-webkit-slider-thumb {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
        appearance: none;
        margin-top: -3px;
        width: 12px;
        height: 12px;
        background-color: #61c091;
        border-radius: 100%;
        border: none;
        transition: 0.2s;
      }

      &::-moz-range-thumb {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
        appearance: none;
        width: 12px;
        height: 12px;
        background-color: #61c091;
        border-radius: 100%;
        border: none;
        transition: 0.2s;
      }

      &::-ms-thumb {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
        appearance: none;
        width: 12px;
        height: 12px;
        background-color: #61c091;
        border: none;
        border-radius: 100%;
        transition: 0.2s;
      }

      &:active::-moz-range-thumb {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
        width: 14px;
        height: 14px;
      }

      &:active::-ms-thumb {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
        width: 14px;
        height: 14px;
      }

      &:active::-webkit-slider-thumb {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
        margin-top: -4px;
        width: 14px;
        height: 14px;
      }

      &::-webkit-slider-runnable-track {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        width: 100%;
        height: 6px;
        cursor: pointer;
        border-radius: 2px;
        border: none;
        background-color: rgba(68, 170, 119, 0.3);
      }

      &::-moz-range-track {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        width: 100%;
        height: 6px;
        cursor: pointer;
        border-radius: 2px;
        border: none;
        background-color: rgba(68, 170, 119, 0.3);
      }

      &::-ms-track {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        width: 100%;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
        height: 6px;
        border-radius: 2px;
        border: none;
      }

      &::-ms-fill-lower,
      &::-ms-fill-upper,
      &:focus::-webkit-slider-runnable-track,
      &:focus::-moz-range-track {
        background-color: rgba(68, 170, 119, 0.3);
      }

      &:focus::-ms-fill-lower {
        background-color: rgba(68, 170, 119, 0.45);
      }

      &:focus::-ms-fill-upper {
        background-color: rgba(68, 170, 119, 0.25);
      }
    }
  }
</style>
