import { render, staticRenderFns } from "./VTableTree.vue?vue&type=template&id=39aadf74&"
import script from "./v-table-tree.js?vue&type=script&lang=js&"
export * from "./v-table-tree.js?vue&type=script&lang=js&"
import style0 from "./VTableTree.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports