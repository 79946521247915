const SET_DEBUG_OPTIONS = 'SET_DEBUG_OPTIONS'
const SET_SHOW_DEBUG_MODAL = 'SET_SHOW_DEBUG_MODAL'

export default {
  namespaced: true,

  state () {
    return {
      debugOptions: {
        translationKeyMode: false
      },
      showDebugModal: false
    }
  },

  getters: {
    debugOptions: (state) => state.debugOptions,
    showDebugModal: (state) => state.showDebugModal
  },

  mutations: {
    [SET_DEBUG_OPTIONS] (state, options) {
      state.debugOptions = options
    },

    [SET_SHOW_DEBUG_MODAL] (state, value) {
      state.showDebugModal = value
    }
  },

  actions: {
    setDebugOptions (context, options) {
      context.commit(SET_DEBUG_OPTIONS, options)
    },

    setShowDebugModal (context, value) {
      context.commit(SET_SHOW_DEBUG_MODAL, value)
    }
  }
}
