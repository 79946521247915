<template lang="pug">
  .not-found
    .back-text  {{ 'ui.labels.error' | translate }}
    .inner
      i.ufo
      div.wrapper(v-if="code === 100")
        span.title-text {{ 'pages.not_found.no_account_found' | translate }}
        span.text {{ 'pages.not_found.please_check_if_the_link_you_used_is_correct' | translate }}
        a.btn(href="https://skilltree.at/en/show-me/") {{ 'ui.buttons.create_account' | translate }}
        span.text.small-text
          a(href="mailto:office@skilltree.at") {{ 'pages.not_found.or_get_in_touch_with_us' | translate }}
      div.wrapper(v-if="code === 101")
        span.title-text {{ 'pages.not_found.account_expired' | translate }}
        span.text {{ 'pages.not_found.your_trial_has_expired' | translate }}
        a.btn(href="https://meetings-eu1.hubspot.com/arman-mafi/skilltree-demo-standard") {{ 'ui.buttons.schedule_appointment' | translate }}
</template>

<script>
export default {
  name: 'IncorrectProvider',

  props: {
    code: Number
  }
}
</script>

<style lang="scss" scoped>
  .not-found {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 100;

      h2 {
        margin-bottom: 25px;
      }

      .ufo {
        display: inline-block;
        height: 120px;
        width: 120px;
        background: url('../../assets/icons/info/ufo.svg') no-repeat center;
        margin-bottom: 35px;
      }

      .wrapper {
        text-align: center;

        .title-text {
          font-size: 24px;
        }

        .title-text, .text {
          font-weight: 600;
          color: #000;
          display: block;
        }

        .text {
          margin-top: 20px;
          max-width: 500px;
        }

        .small-text {
          font-size: 14px;
        }

        .btn {
          margin-top: 30px;
          margin-bottom: 10px;
          background: #13b389;
          color: #fff;
          padding: 6px 40px;
          border-radius: 100px;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    .back-text {
      position: absolute;
      font-size: 600px;
      opacity: 0.05;
      z-index: 0;
    }
  }
</style>
