import { execute, cancelableRequest, addQueryParams } from '../utils'

const ENDPOINT = 'employees'

const cancelable = cancelableRequest(execute)

export default {
  list (params) {
    return cancelable.execute('GET', addQueryParams(ENDPOINT, params))
  },

  devList () {
    return execute('GET', `${ENDPOINT}?dev`)
  },

  search (params = {}) {
    return cancelable.execute('GET', addQueryParams(`${ENDPOINT}/search`, params))
  },

  filter (params = {}) {
    return execute('GET', addQueryParams(`${ENDPOINT}/filter`, params))
  },

  create (payload) {
    return execute('POST', ENDPOINT, payload)
  },

  getById (id) {
    return execute('GET', `${ENDPOINT}/${id}`)
  },

  updateById (id, payload) {
    return execute('PUT', `${ENDPOINT}/${id}`, payload)
  },

  deleteById (id) {
    return execute('DELETE', `${ENDPOINT}/${id}`)
  },

  delete (payload) {
    return execute('DELETE', `${ENDPOINT}`, payload)
  },

  deactivateMultiple (payload) {
    return execute('PUT', `${ENDPOINT}/deactivate`, payload)
  },

  activateMultiple (payload) {
    return execute('PUT', `${ENDPOINT}/activate`, payload)
  },

  setToPositionMultiple (positionId, payload) {
    return execute('POST', `${ENDPOINT}/link?toPositionId=${positionId}`, payload)
  },

  setToLocationMultiple (positionId, payload) {
    return execute('POST', `${ENDPOINT}/link?toLocationId=${positionId}`, payload)
  },

  linkRole (id, roleId) {
    return execute('POST', `${ENDPOINT}/${id}/link?toRoleId=${roleId}`)
  },

  unlinkRole (id, roleId) {
    return execute('DELETE', `${ENDPOINT}/${id}/unlink?fromRoleId=${roleId}`)
  },

  linkDepartment (departmentId, payload) {
    return execute('POST', `${ENDPOINT}/link?toDepartmentId=${departmentId}`, payload)
  },

  linkPosition (positionId, payload) {
    return execute('POST', `${ENDPOINT}/link?toPositionId=${positionId}`, payload)
  },

  linkLocation (locationId, payload) {
    return execute('POST', `${ENDPOINT}/link?toLocationId=${locationId}`, payload)
  },

  unlinkDepartment (departmentId, payload) {
    return execute('DELETE', `${ENDPOINT}/unlink?fromDepartment`, payload)
  },

  unlinkPosition (positionId, payload) {
    return execute('DELETE', `${ENDPOINT}/unlink?fromPosition`, payload)
  },

  unlinkLocation (locationId, payload) {
    return execute('DELETE', `${ENDPOINT}/unlink?fromLocation`, payload)
  },

  getFile (uuid) {
    return execute('GET', `${ENDPOINT}/badge-file?uuid=${uuid}`)
  },

  uploadFile (badgeId) {
    // TODO: add form data
    return execute('POST', `${ENDPOINT}/badge-file?badgeId=${badgeId}`)
  },

  deleteFile (uuid) {
    return execute('DELETE', `${ENDPOINT}/badge-file?uuid=${uuid}`)
  },

  onlyNotActivated (id) {
    return execute('GET', `${ENDPOINT}/${id}/badges?onlyNotActivated=1`)
  },

  getSharedProfile (id) {
    return execute('GET', `${ENDPOINT}/share/${id}`)
  },

  createSharedProfile (payload) {
    return execute('POST', `${ENDPOINT}/${payload.id}/share`, payload.data)
  },

  updateSharedProfile (payload) {
    return execute('PUT', `${ENDPOINT}/share/${payload.id}`, payload.data)
  }
}
