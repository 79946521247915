<template lang="pug">
  .app-header
    .app-header__inner
      .app-header__mobile-menu
        img.app-header__mobile-menu-toggler(
          @click="toggleMobileMenu" src="../assets/icons/util/menu-button.svg")
        .app-header__mobile-menu-title {{ 'app.header.my_skilltree' | translate }}
        transition(name="fadex")
          .menu-overlay(
            v-if="isMobileMenuOpened"
            @click="hideMobileMenu")
      .app-header__brand(@click="redirectMySkills")
        img.app-header__brand-logo.cursor-pointer(alt="Skilltree" src="../assets/ui/logo.svg")
        span.app-header__brand-instance(v-if="instanceName") for {{instanceName}}
      navigation(
        @on-navigate="hideMobileMenu"
        :class="{ 'show-on-mobile': isMobileMenuOpened }")
      .header__topbar
        notifications
        search
        profile
</template>

<script>
import { ENV } from '@/util/constants'
import Search from '@/components/common/search/Search'
import Profile from '@/components/common/header/Profile'
import Navigation from '@/components/common/header/Navigation'
import Notifications from '@/components/common/notifications/Notifications'

export default {
  name: 'AppHeader',

  components: {
    Search,
    Profile,
    Navigation,
    Notifications
  },

  data: () => ({
    isMobileMenuOpened: false
  }),

  methods: {
    toggleMobileMenu () {
      this.isMobileMenuOpened = !this.isMobileMenuOpened
    },

    hideMobileMenu () {
      this.isMobileMenuOpened = false
    },

    redirectMySkills () {
      this.$router.push({ path: '/my' })
    }
  },

  computed: {
    instanceName () {
      return ENV.SHOW_DEBUG_TOOLS && (localStorage.getItem('instanceName') || '')
    }

  }
}
</script>

<style lang="scss">
</style>
