<template lang="pug">
  .v-stepper-content(
    v-show="isVisible")
    slot
</template>

<script>
export default {
  name: 'VStepperContent',

  props: {
    step: [String, Number]
  },

  data: () => ({
    isVisible: false
  })
}
</script>

<style>
</style>
