import api from '@/api'
import {
  GET_ITEM_BY_PATH,
  GET_ITEM_BY_ID,
  RECURSIVE_UPDATE_PARENT_NODES,
  GET_DEPARTMENT_NODE,
  SORT_NODES_BY_TYPES
} from '@/util/v-table-tree.js'

const SET_DEPARTMENTS = 'SET_DEPARTMENTS'
const SELECT_DEPARTMENT = 'SELECT_DEPARTMENT'
const UNSELECT_DEPARTMENT = 'UNSELECT_DEPARTMENT'

const CREATE_ROOT_ITEM = 'CREATE_ROOT_ITEM'
const CREATE_CHILD_ITEM = 'CREATE_CHILD_ITEM' // add item inside department
const REMOVE_ITEM = 'REMOVE_ITEM'

const UPDATE_ITEM = 'UPDATE_ITEM' // update node by node path
const UPDATE_NODE = 'UPDATE_NODE' // update node by node object link

const SET_LINKED_EMPLOYEES = 'SET_LINKED_EMPLOYEES'
const MOVE_EMPLOYEES = 'MOVE_EMPLOYEES'
const ADD_EMPLOYEE = 'ADD_EMPLOYEE'
const REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE'
const SELECT_UNASSIGNED = 'SELECT_UNASSIGNED'

const SET_LINKED_SKILLS = 'SET_LINKED_SKILLS'
const MOVE_BADGES = 'MOVE_BADGES'
const ADD_BADGE = 'ADD_BADGE'
const REMOVE_BADGE = 'REMOVE_BADGE'

const SET_EXTERNAL_DRAG_MODE = 'SET_EXTERNAL_DRAG_MODE'
const SET_CLIPBOARD = 'SET_CLIPBOARD'

export default {
  namespaced: true,

  state () {
    return {
      departments: [],
      selected: null,
      selectedDepartment: null,
      externalDragMode: false,
      clipboard: []
    }
  },

  getters: {
    departments: (state) => state.departments,
    selected: (state) => state.selected,
    selectedDepartment: (state) => state.selectedDepartment,
    externalDragMode: (state) => state.externalDragMode
  },

  mutations: {
    [SET_DEPARTMENTS] (state, data) {
      state.departments = data
      if (state.departments.length) {
        state.departments[0].children = SORT_NODES_BY_TYPES(data[0].children)
      }
    },

    [SELECT_DEPARTMENT] (state, node) {
      const itemData = GET_ITEM_BY_PATH(state.departments, node.path)
      state.selected = itemData
      itemData.node.data.employees.pagination = {
        page: 0,
        pages: 1,
        total: 1
      }
      itemData.node.data.badges.pagination = {
        page: 0,
        pages: 1,
        total: 1
      }
      state.selectedDepartment = itemData.node
      if (!itemData.node.isExpanded) {
        itemData.node.isExpanded = true
      }
      itemData.node.isNotAssignedSelected = false
    },

    [SELECT_UNASSIGNED] (state, node) {
      if (state.selected) {
        state.selected.node.isSelected = false
        state.selected.node.isNotAssignedSelected = false
      }
      const itemData = GET_ITEM_BY_PATH(state.departments, node.path)
      itemData.node.data.employees.pagination = {
        page: 0,
        pages: 1,
        total: 1
      }
      itemData.node.data.badges.pagination = {
        page: 0,
        pages: 1,
        total: 1
      }
      itemData.node.isSelected = true
      itemData.node.isNotAssignedSelected = true
      state.selected = itemData
      state.selectedDepartment = itemData.node
    },

    [UNSELECT_DEPARTMENT] (state) {
      state.selected = null
      state.selectedDepartment = null
    },

    [SET_LINKED_EMPLOYEES] (state, { data, pagination }) {
      if (state.selectedDepartment) {
        state.selectedDepartment.data.employees.items = pagination.page > 1
          ? [...state.selectedDepartment.data.employees.items, ...data]
          : data
        state.selectedDepartment.data.employees.pagination = pagination
      }
    },

    [ADD_EMPLOYEE] (state, data) {
      if (state.selectedDepartment) {
        state.selectedDepartment.data.employees.items.push(data)
        state.selectedDepartment.data.employees.pagination.total += 1
      }
    },

    [REMOVE_EMPLOYEE] (state, data) {
      if (state.selectedDepartment) {
        const index = state.selectedDepartment.data.employees.items
          .map(item => item.id)
          .indexOf(data.id)
        if (index !== -1) {
          state.selectedDepartment.data.employees.items.splice(index, 1)
          state.selectedDepartment.data.employees.pagination.total -= 1
        }
      }
    },

    [MOVE_EMPLOYEES] (state, node) {
      const itemData = GET_ITEM_BY_PATH(state.departments, node.path)
      if (state.selectedDepartment.data.id !== itemData.node.data.id) {
        if (itemData.node) {
          for (const index in state.clipboard) {
            const item = state.clipboard[index]
            itemData.node.data.employees.items.push(item)
            const index2 = state.selectedDepartment.data.employees.items.indexOf(item)
            if (index2 !== -1) {
              state.selectedDepartment.data.employees.items.splice(index2, 1)
              state.selectedDepartment.data.employees.pagination.total -= 1
            }
          }
        }
      }
    },

    [SET_LINKED_SKILLS] (state, { data, pagination }) {
      if (state.selectedDepartment) {
        state.selectedDepartment.data.badges.items = pagination.page > 1
          ? [...state.selectedDepartment.data.badges.items, ...data]
          : data
        state.selectedDepartment.data.badges.pagination = pagination
      }
    },

    [ADD_BADGE] (state, data) {
      if (state.selectedDepartment) {
        state.selectedDepartment.data.badges.items.push(data)
        state.selectedDepartment.data.badges.pagination.total += 1
      }
    },

    [REMOVE_BADGE] (state, data) {
      if (state.selectedDepartment) {
        const index = state.selectedDepartment.data.badges.items
          .map(item => item.id)
          .indexOf(data.id)
        if (index !== -1) {
          state.selectedDepartment.data.badges.items.splice(index, 1)
          state.selectedDepartment.data.badges.pagination.total -= 1
        }
      }
    },

    [MOVE_BADGES] (state, node) {
      const itemData = GET_ITEM_BY_PATH(state.departments, node.path)
      if (itemData.node) {
        for (const index in state.clipboard) {
          const item = state.clipboard[index]
          itemData.node.data.badges.items.push(item)
          const index2 = state.selectedDepartment.data.badges.items.indexOf(item)
          if (index2 !== -1) {
            state.selectedDepartment.data.badges.items.splice(index2, 1)
            state.selectedDepartment.data.badges.pagination.total -= 1
          }
        }
      }
    },

    [CREATE_ROOT_ITEM] (state, newNode) {
      if (state.departments.length) {
        state.departments[0].children.push(newNode)
        state.departments[0].children = SORT_NODES_BY_TYPES(state.departments[0].children)
      }
    },

    [CREATE_CHILD_ITEM] (state, { toNode, newNode }) {
      const itemData = GET_ITEM_BY_PATH(state.departments, toNode.path)
      if (itemData.node) {
        itemData.node.isExpanded = true
        itemData.node.children.push(newNode)
        itemData.node.children = SORT_NODES_BY_TYPES(itemData.node.children)
      }
    },

    [UPDATE_ITEM] (state, { node, fields }) {
      const itemData = GET_ITEM_BY_PATH(state.departments, [...node.path])
      if (itemData.node) {
        for (const key in fields) {
          itemData.node.data[key] = fields[key]
        }
        itemData.node.isEditing = false
      }
    },

    [REMOVE_ITEM] (state, node) {
      const itemData = GET_ITEM_BY_PATH(state.departments, node.path)
      if (itemData.node) {
        itemData.parent.splice(itemData.index, 1)
      }
    },

    [UPDATE_NODE] (state, { node, fields }) {
      for (const key in fields) {
        node.data[key] = fields[key]
      }
    },

    [SET_EXTERNAL_DRAG_MODE] (state, data) {
      state.externalDragMode = data
    },

    [SET_CLIPBOARD] (state, data) {
      state.clipboard = data
    }
  },

  actions: {
    async loadDepartments (context, params = {}) {
      const { data } = await api.departments.tree({ all: true, ...params })
      context.commit(SET_DEPARTMENTS, [GET_DEPARTMENT_NODE(data)])
      return { data }
    },

    async loadLinkedEmployees (context, item) {
      const node = item || context.state.selectedDepartment
      const page = context.state.selectedDepartment
        ? context.state.selectedDepartment.data.employees.pagination.page + 1
        : 1
      const pages = context.state.selectedDepartment
        ? context.state.selectedDepartment.data.employees.pagination.pages
        : 1
      if (page <= pages) {
        const params = {
          page: page
        }
        const { data, headers } = node.data.id
          ? node.isLeaf // is it position
            ? await api.positions.listEmployees(node.data.id, params)
            : node.isNotAssignedSelected
              ? await api.departments.listEmployees(node.data.id, params)
              : await api.departments.listAllEmployees(node.data.id, params)
          : await api.departments.listEmployees(null, params)
        const pagination = {
          page,
          pages: (headers && parseInt(headers['x-pagination-page-count'])) || 1,
          total: (headers && parseInt(headers['x-pagination-total-count'])) || 0
        }
        context.commit(SET_LINKED_EMPLOYEES, { data: data || [], pagination })
      }
    },

    async loadLinkedSkills (context, loadAll = false) {
      const node = context.state.selectedDepartment
      if (node.isLeaf) {
        const page = context.state.selectedDepartment
          ? context.state.selectedDepartment.data.badges.pagination.page + 1
          : 1
        const pages = context.state.selectedDepartment
          ? context.state.selectedDepartment.data.badges.pagination.pages
          : 1
        if (page <= pages) {
          const params = {
            fields: 'id,color,displayName,starsCount',
            page: page
          }
          if (loadAll) {
              params.all = true
          }
          const { data, headers } = await api.positions.listBadges(node.data.id, params)
          const pagination = {
            page: loadAll ? 1 : page,
            pages: (headers && parseInt(headers['x-pagination-page-count'])) || 1,
            total: (headers && parseInt(headers['x-pagination-total-count'])) || (data || []).length
          }
          context.commit(SET_LINKED_SKILLS, { data: data || [], pagination })
        }
      }
    },

    selectDepartment (context, node) {
      context.commit(SELECT_DEPARTMENT, node)
    },

    selectUnassigned (context, node) {
      context.commit(SELECT_UNASSIGNED, node)
    },

    async setSupervisor (context, id) {
      const selected = context.state.selected
      const node = selected.node.isLeaf
        ? selected.parentNode
        : selected.node
      if (node) {
        const payload = {
          supervisorId: id
        }
        const { data } = await api.departments.updateById(node.data.id, payload)
        context.commit(UPDATE_NODE, { node, fields: data })
      }
    },

    async addDepartment (context, { toNode }) {
      const name = toNode
        ? `${toNode.data.name} subdepartment ${toNode.children.length + 1}`
        : `Department ${context.state.departments[0].children.length + 1}`
      const newNode = {
        data: {
          id: -1,
          parentId: toNode
            ? toNode.data.id
            : null,
          name: name,
          badges: {
            items: [],
            pagination: null
          },
          employees: {
            items: [],
            pagination: null
          },
          employeeCount: 0,
          allChildEmployeeCount: 0,
          isNew: true,
          errorText: null
        },
        isEditing: true,
        isVisible: true,
        isExpanded: false,
        children: [],
        isLeaf: false
      }
      if (toNode) {
        context.commit(CREATE_CHILD_ITEM, { toNode, newNode })
      } else {
        context.commit(CREATE_ROOT_ITEM, newNode)
      }
    },

    async addPosition (context, { toNode }) {
      const name = `${toNode.data.name} position ${toNode.children.length + 1}`
      const newNode = {
        data: {
          id: -1,
          parentId: toNode.data.id,
          name: name,
          badges: {
            items: [],
            pagination: null
          },
          employees: {
            items: [],
            pagination: null
          },
          badgeCount: 0,
          employeeCount: 0,
          isNew: true,
          errorText: null
        },
        isEditing: true,
        isLeaf: true
      }
      if (toNode) {
        context.commit(CREATE_CHILD_ITEM, { toNode, newNode })
      } else {
        context.commit(CREATE_ROOT_ITEM, newNode)
      }
    },

    removeItem (context, { node }) {
      const employeeCount = node.isLeaf
        ? node.data.employeeCount
        : node.data.allChildEmployeeCount
      if (employeeCount) {
        const parent = GET_ITEM_BY_ID(context.state.departments, node.data.parentId)
        parent.data.employeeCount += employeeCount
      }
      context.commit(UNSELECT_DEPARTMENT)
      context.commit(REMOVE_ITEM, node)
      if (!node.data.isNew) {
        if (node.isLeaf) {
          api.positions.deleteById(node.data.id)
        } else {
          api.departments.deleteById(node.data.id)
        }
      }
    },

    async updateItem (context, { node, fields }) {
      if (node.data.isDeleted) return
      context.commit(UPDATE_ITEM, { node, fields })
      if (node.isLeaf) {
        const payload = {
          ...fields,
          departmentId: node.data.parentId
        }
        const { data, errorData } = node.data.isNew
          ? await api.positions.create(payload)
          : await api.positions.updateById(node.data.id, fields)
        if (data) {
          fields.id = data.id
          fields.isNew = false
          fields.errorText = null
        } else if (errorData) {
          const error = errorData.data.find(item => item.field === 'name')
          fields.errorText = error && error.message
        }
      } else {
        const payload = {
          ...fields,
          parentId: node.data.parentId
        }
        const { data, errorData } = node.data.isNew
          ? await api.departments.create(payload)
          : await api.departments.updateById(node.data.id, fields)
        if (data) {
          fields.id = data.id
          fields.isNew = false
          fields.errorText = null
        } else if (errorData) {
          const error = errorData.data.find(item => item.field === 'name')
          fields.errorText = error && error.message
        }
      }
      context.commit(UPDATE_ITEM, { node, fields })
    },

    // this function executes on node (department or position) move or sorting
    async onMoveItems (context, { nodes, params }) {
      // START: SORTING LOGIC
      if (params.placement !== 'inside') {
        const { parentNode } = GET_ITEM_BY_PATH(context.state.departments, [...params.node.path])

        // if items not moving to another department
        if (parentNode && parentNode.data.id === nodes[0].data.parentId) {
          // get current department id
          const nodeId = parentNode
            ? parentNode.data.id
            : null

          // get department children
          const items = parentNode
            ? parentNode.children
            : context.state.departments

          // sort items by type (move departments to the top)
          if (parentNode) {
            parentNode.children = SORT_NODES_BY_TYPES(parentNode.children)
          }

          // get departments ids
          const departmentIds = items
            .filter(item => !item.isLeaf)
            .map(item => item.data.id)

          // get positions ids
          const positionIds = items
            .filter(item => item.isLeaf)
            .map(item => item.data.id)

          // update departments order on server
          if (departmentIds.length) {
            await api.departments.sort(nodeId, {
              departmentIds: departmentIds
            })
          }

          // update positions order on server
          if (positionIds.length) {
            await api.positions.sort(nodeId, {
              positionIds: positionIds
            })
          }
        }
      }
      // END: SORTING LOGIC

      // START: ITEMS MOVE LOGIC
      // if move inside node
      const parentId = params.placement === 'inside'
        // get id of this node
        ? params.node.data.id
        // otherwise if we insert item before or after node, get parent of this node
        : (() => {
          const { parentNode } = GET_ITEM_BY_PATH(context.state.departments, [...params.node.path])
          return parentNode
            ? parentNode.data.id
            : null
        })()

      // calculate total employee count in moved items
      const count = (() => {
        let result = 0
        for (const node of nodes) {
          if (node.isLeaf) {
            result += node.data.employeeCount
          } else {
            result += node.data.allChildEmployeeCount
          }
        }
        return result
      })()

      const items = context.state.departments

      // decrease employee count in previous node
      if (nodes.length) {
        RECURSIVE_UPDATE_PARENT_NODES(items, nodes[0].data.parentId, (node) => {
          node.data.allChildEmployeeCount -= count
        })
      }

      // increase employee count in new node
      RECURSIVE_UPDATE_PARENT_NODES(items, parentId, (node) => {
        node.data.allChildEmployeeCount += count
      })

      // update parent ids
      for (const node of nodes) {
        const item = GET_ITEM_BY_ID(items, node.data.id, node.isLeaf)
        item.data.parentId = parentId
      }

      // move employees on server
      const toDepartmentId = parentId !== null
        ? parentId
        : ''

      if (toDepartmentId !== null) {
        const departments = nodes.filter(node => !node.isLeaf)
        if (departments.length) {
          const payload = {
            departmentIds: departments
              .map(node => node.data.id)
          }
          await api.departments.moveMany(toDepartmentId, payload)
        }

        const positions = nodes.filter(node => node.isLeaf)
        if (positions.length) {
          const payload = {
            positionIds: positions
              .map(node => node.data.id)
          }
          await api.positions.moveMany(toDepartmentId, payload)
        }
      }
      // END: ITEMS MOVE LOGIC
    },

    addBadge (context, data) {
      const payload = {
        badgeIds: [data.id]
      }
      context.state.selectedDepartment.data.badgeCount += 1
      context.commit(ADD_BADGE, data)
      api.badges.linkPosition(context.state.selectedDepartment.data.id, payload)
    },

    removeBadge (context, data) {
      const payload = {
        badgeIds: [data.id]
      }
      context.state.selectedDepartment.data.badgeCount -= 1
      context.commit(REMOVE_BADGE, data)
      api.badges.unlinkPosition(context.state.selectedDepartment.data.id, payload)
    },

    activateExternalDragMode (context) {
      context.commit(SET_EXTERNAL_DRAG_MODE, true)
    },

    deactivateExternalDragMode (context) {
      context.commit(SET_EXTERNAL_DRAG_MODE, false)
    },

    setClipboardItems (context, data) {
      context.commit(SET_CLIPBOARD, data)
    },

    updateEmployeeCount (context, { id, count, isPosition = false }) {
      const items = context.state.departments
      const item = GET_ITEM_BY_ID(items, id, isPosition)
      if (item) {
        item.data.employeeCount += count

        if (!isPosition) {
          item.data.allChildEmployeeCount += count
        }

        RECURSIVE_UPDATE_PARENT_NODES(items, item.data.parentId, (node) => {
          node.data.allChildEmployeeCount += count
        })
      }
    },

    addEmployee (context, data) {
      const items = context.state.departments

      // STEP 1: get previous department or position
      if (data.department) {
        const department = GET_ITEM_BY_ID(items, data.department.id, false)

        department.data.employeeCount -= 1
        department.data.allChildEmployeeCount -= 1

        // STEP 2: recursive update user count fields for all parent elements
        RECURSIVE_UPDATE_PARENT_NODES(items, department.data.parentId, (node) => {
          node.data.allChildEmployeeCount -= 1
        })
        data.department = null
      }

      if (data.position) {
        const position = GET_ITEM_BY_ID(items, data.position.id, true)

        position.data.employeeCount -= 1

        // STEP 2: recursive update user count fields for all parent elements
        RECURSIVE_UPDATE_PARENT_NODES(items, position.data.parentId, (node) => {
          node.data.allChildEmployeeCount -= 1
        })
        data.position = null
      }

      // STEP 3: link employee to new department / position
      const node = context.state.selectedDepartment
      const payload = {
        employeeIds: [data.id]
      }
      const id = node.data.id || ''
      node && node.isLeaf
        ? api.employees.linkPosition(id, payload)
        : api.employees.linkDepartment(id, payload)
      context.commit(ADD_EMPLOYEE, data)

      // STEP 4: recursive update user count fields for all parent elements
      node.data.employeeCount += 1
      if (!node.isLeaf) {
        node.data.allChildEmployeeCount += 1
      }
      RECURSIVE_UPDATE_PARENT_NODES(items, node.data.parentId, (node) => {
        node.data.allChildEmployeeCount += 1
      })

      // STEP 5: update employee data
      const employee = {
        id: data.id
      }

      const parentNode = node.data.parentId !== null
        ? GET_ITEM_BY_ID(items, node.data.parentId)
        : null
      const department = parentNode
        ? {
          id: parentNode.data.id,
          name: parentNode.data.name
        }
        : null

      if (!node.isLeaf) {
        const department = {
          id: node.data.id,
          name: node.data.name
        }
        employee.department = department
        employee.position = null
      } else {
        const position = {
          id: node.data.id,
          name: node.data.name,
          department
        }
        employee.department = null
        employee.position = position
      }

      for (const key in employee) {
        data[key] = employee[key]
      }

      context.dispatch('employees/updateLocalEmployee', employee, { root: true })
    },

    removeEmployee (context, data) {
      const node = context.state.selectedDepartment
      const payload = {
        employeeIds: [data.id]
      }
      const id = node.data.id || ''
      node && node.isLeaf
        ? api.employees.unlinkPosition(id, payload)
        : api.employees.unlinkDepartment(id, payload)

      if (node.isNotAssignedSelected) {
        node.data.allChildEmployeeCount -= 1
      }

      if (node.data.employeeCount > 0) {
        node.data.employeeCount -= 1
        node.data.allChildEmployeeCount -= 1
      }

      if (context.state.selected.parentNode) {
        context.state.selected.parentNode.data.employeeCount += 1
      }
      context.commit(REMOVE_EMPLOYEE, data)

      // update employee data
      const employee = {
        id: data.id
      }
      if (context.state.selected && context.state.selected.parentNode) {
        employee.department = {
          id: context.state.selected.parentNode.data.id,
          name: context.state.selected.parentNode.data.name
        }
        employee.position = null
      } else {
        employee.position = null
        employee.department = null
      }
      context.dispatch('employees/updateLocalEmployee', employee, { root: true })
    },

    moveClipboardItemsTo (context, node) {
      const items = context.state.clipboard
      if (items.length) {
        const isBadge = !!items[0].displayName || !!items[0].name
        if (isBadge) {
          context.dispatch('moveBadgesTo', node)
        } else {
          context.dispatch('moveEmployeesTo', node)
        }
      }
    },

    async moveBadgesTo (context, node) {
      const count = context.state.clipboard.length

      context.commit(MOVE_BADGES, node)

      // update prev node badge count
      const prevNode = context.state.selectedDepartment

      // update on server
      const id = node.data.id
      const payload = {
        badgeIds: context.state.clipboard
          .map(item => item.id)
      }
      const unlink = await api.badges.unlinkPosition(prevNode.data.id, payload)
        if (unlink.hasOwnProperty('data')) {
          // update prev node badge count
          prevNode.data.badgeCount -= count
        }
      const link = await api.badges.linkPosition(id, payload)
      if (link.hasOwnProperty('data')) {
         // update new node badge count
        node.data.badgeCount += count
      }
      context.commit(SET_CLIPBOARD, [])
    },

    async moveEmployeesTo (context, node) {
      const count = context.state.clipboard.length
      const items = context.state.departments

      context.commit(MOVE_EMPLOYEES, node)

      // update prev node employees count
      const prevNode = context.state.selectedDepartment
      prevNode.data.employeeCount -= count
      if (!prevNode.isLeaf) {
        prevNode.data.allChildEmployeeCount -= count
      }
      RECURSIVE_UPDATE_PARENT_NODES(items, prevNode.data.parentId, (node) => {
        node.data.allChildEmployeeCount -= count
      })

      // update new node employees count
      node.data.employeeCount += count
      if (!node.isLeaf) {
        node.data.allChildEmployeeCount += count
      }
      RECURSIVE_UPDATE_PARENT_NODES(items, node.data.parentId, (node) => {
        node.data.allChildEmployeeCount += count
      })

      const parentNode = node.data.parentId !== null
        ? GET_ITEM_BY_ID(items, node.data.parentId)
        : null

      for (const item of context.state.clipboard) {
        const department = parentNode
          ? {
            id: parentNode.data.id,
            name: parentNode.data.name
          }
          : null
        if (node.isLeaf) {
          item.position = {
            id: node.data.id,
            name: node.data.name,
            department
          }
        } else {
          item.department = {
            id: node.data.id,
            name: node.data.name,
            department
          }
        }
        context.dispatch('employees/updateLocalEmployee', item, { root: true })
      }

      // update on server
      const id = node.data.id
      const payload = {
        employeeIds: context.state.clipboard
          .map(item => item.id)
      }
      if (node.isLeaf) {
        await api.employees.linkPosition(id, payload)
      } else {
        await api.employees.linkDepartment(id, payload)
      }
      context.commit(SET_CLIPBOARD, [])
    },

    async copyPosition (context, { node }) {
      const parent = GET_ITEM_BY_ID(context.state.departments, node.data.parentId)
      const newNode = {
        data: {
          isLoading: true,
          id: -1,
          parentId: parent.data.id,
          name: 'copy ' + node.data.name,
          badges: { ...node.data.badges },
          employees: {
            items: [],
            pagination: null
          },
          badgeCount: node.data.badgeCount,
          employeeCount: 0,
          isNew: false,
          errorText: null
        },
        isEditing: false,
        isLeaf: true
      }
      parent.children.splice(node.ind + 1, 0, newNode)
      const { data } = await api.positions.copy(node.data.id)
      if (data) {
        newNode.data.name = data.name
        newNode.data.id = data.id
        newNode.data.isLoading = false
      }
    }
  }
}
