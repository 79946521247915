<script>
import { COLOR_PRIMARY } from '@/util/constants'

export default {
  functional: true,
  render (h, ctx) {
    const params = {
      transforms: [
        'translate(4 5)',
        'translate(4 5)',
        'translate(4 5)',
        'translate(4 1)',
        'translate(4 0)',
        'translate(1.5 1)'
      ],
      badgeTransforms: [
        'translate(-8 -8.34)',
        'translate(-8 -6.34)',
        'translate(-8 -6.34)',
        'translate(-8 -2.34)',
        'translate(-8 -1.34)',
        'translate(-5.51 -2.34)'
      ],
      coordinates: [
        ['6.68 3.57 8.08 4.31 7.82 2.75 8.95 1.64 7.38 1.42 6.68 0 5.98 1.42 4.42 1.64 5.55 2.75 5.28 4.31 6.68 3.57'],
        ['4 3.57 5.4 4.31 5.13 2.75 6.27 1.64 4.7 1.42 4 0 3.3 1.42 1.73 1.64 2.87 2.75 2.6 4.31 4 3.57',
          '10 3.57 11.4 4.31 11.13 2.75 12.27 1.64 10.7 1.42 10 0 9.3 1.42 7.73 1.64 8.87 2.75 8.6 4.31 10 3.57'],
        ['7 3.57 8.4 4.31 8.13 2.75 9.27 1.64 7.7 1.42 7 0 6.3 1.42 4.73 1.64 5.87 2.75 5.6 4.31 7 3.57',
          '11 8.57 12.4 9.31 12.13 7.75 13.27 6.65 11.7 6.42 11 5 10.3 6.42 8.73 6.65 9.87 7.75 9.6 9.31 11 8.57',
          '3 8.57 4.4 9.31 4.13 7.75 5.27 6.65 3.7 6.42 3 5 2.3 6.42 0.73 6.65 1.87 7.75 1.6 9.31 3 8.57'],
        ['4.28 9.57 5.68 10.31 5.42 8.75 6.55 7.64 4.98 7.42 4.28 6 3.58 7.42 2.02 7.64 3.15 8.75 2.88 10.31 4.28 9.57',
          '10.28 9.57 11.68 10.31 11.42 8.75 12.55 7.64 10.98 7.42 10.28 6 9.58 7.42 8.02 7.64 9.15 8.75 8.88 10.31 10.28 9.57',
          '4.28 3.57 5.68 4.31 5.42 2.75 6.55 1.64 4.98 1.42 4.28 0 3.58 1.42 2.02 1.64 3.15 2.75 2.88 4.31 4.28 3.57',
          '10.28 3.57 11.68 4.31 11.42 2.75 12.55 1.64 10.98 1.42 10.28 0 9.58 1.42 8.02 1.64 9.15 2.75 8.88 4.31 10.28 3.57'],
        ['9.27 3.57 10.67 4.31 10.41 2.75 11.54 1.64 9.97 1.42 9.27 0 8.57 1.42 7.01 1.64 8.14 2.75 7.87 4.31 9.27 3.57',
          '13.27 8.57 14.67 9.31 14.41 7.75 15.54 6.65 13.97 6.42 13.27 5 12.57 6.42 11.01 6.65 12.14 7.75 11.87 9.31 13.27 8.57',
          '5.27 8.57 6.67 9.31 6.41 7.75 7.54 6.65 5.97 6.42 5.27 5 4.57 6.42 3.01 6.65 4.14 7.75 3.87 9.31 5.27 8.57',
          '16.27 3.57 17.67 4.31 17.41 2.75 18.54 1.64 16.97 1.42 16.27 0 15.57 1.42 14.01 1.64 15.14 2.75 14.87 4.31 16.27 3.57',
          '2.27 3.57 3.67 4.31 3.41 2.75 4.54 1.64 2.97 1.42 2.27 0 1.57 1.42 0 1.64 1.14 2.75 0.87 4.31 2.27 3.57']
      ]
    }
    var transforms = []
    var badgeTransforms = []
    var coordinates = []
    var numberXcoordinate = []
    var text = ''
    // compute transforms
    if (ctx.props.level > 5) {
      transforms = params.transforms[0]
      badgeTransforms = params.badgeTransforms[1]
      coordinates = params.coordinates[0]
      text = ctx.props.level
    } else if (ctx.props.level === 0) {
      transforms = params.transforms[ctx.props.level]
      badgeTransforms = params.badgeTransforms[ctx.props.level]
    } else {
      transforms = params.transforms[ctx.props.level]
      badgeTransforms = params.badgeTransforms[ctx.props.level]
      coordinates = params.coordinates[ctx.props.level - 1]
    }

    if (String(ctx.props.level).length > 1) {
      numberXcoordinate = '35%'
    } else {
      numberXcoordinate = '50%'
    }

    return h('svg', {
      attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 22.25 37.14',
        width: ctx.props.width,
        height: ctx.props.height,
        fill: ctx.props.color
      }
    }, [h('g', { attrs: { transform: transforms } },
      [
        h('path', {
          class: 'cls-1',
          attrs: {
            transform: badgeTransforms,
            d: 'M8,14.59V25.07A6.82,6.82,0,0,0,8.72,28a5,5,0,0,0,1.94,2.18l.61.37a31.26,31.26,0,0,1,2.92,1.79l.46.43a3.56,3.56,0,0,1,.26.36l.09.14h0a2,2,0,0,1,.3-.39l.37-.33a38.3,38.3,0,0,1,3.7-2.36,5.05,5.05,0,0,0,1.9-2.14,6.94,6.94,0,0,0,.72-3V14.59l-7-1.31Z'
          }
        }),
        Array.from(coordinates).map((item) => {
          return [h('polygon', {
            class: 'cls-2',
            attrs: {
              points: item
            }
          })]
        }),
        h('text', {
          class: 'font-small',
          attrs: {
            x: numberXcoordinate,
            y: '70%',
            transform: badgeTransforms,
            fill: 'white',
            dominantBaseline: 'middle',
            textAnchor: 'middle'
          }
        }, [text])
      ]
    )])
  },
  props: {
    level: {
      type: [String, Number],
      default: 0
    },
    width: {
      type: [String, Number],
      default: 40
    },
    height: {
      type: [String, Number],
      default: 50
    },
    color: {
      type: String,
      default: COLOR_PRIMARY
    }
  }
}
</script>

<style lang="scss" scoped>
.font-small{
  font-size: 12px;
}
</style>
