import {
    filterMyTree,
    filterMultipleRootNode
  } from '@/util/badge-tree-helpers'

const ALL_SKILLS = (tree) => tree
const MY_SKILLTREE = (tree) => filterMyTree(tree)
const MULTIPLE_ROOT_NODE = (tree, categoryID) => filterMultipleRootNode(tree, categoryID)

export const filters = {
    ALL_SKILLS,
    MY_SKILLTREE,
    MULTIPLE_ROOT_NODE
}
