<template lang="pug">
  .v-skeleton(:style="styles")
</template>

<script>
export default {
  name: 'VSkeleton',

  props: {
    width: {
      type: String,
      default: '200px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },

  computed: {
    styles () {
      return `width:${this.width}; height:${this.height}`
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_mixins.scss';
  .v-skeleton {
    border-radius: 5px;
    @include skeleton;
  }
</style>
