<template lang="pug">
v-dialog(
  id="feedback-modal"
  width="520"
  top-offset="60"
  :value="true"
  @input="onClose")
  v-card
    v-card-head
      v-card-head-label
        h2.modal-title {{ editedGoal ? 'pages.my.edit_goal' : 'pages.my.add_new_goal' | translate }}
      v-card-head-toolbar
        v-btn(
          icon
          gray
          @click="onClose") x
    v-scrollable(
      id="new-goal-scroll-panel"
      :bottom-offset="100")
      v-card-content.create-goal--cart
        .form-group
          label {{ 'ui.labels.title' | translate }}
          input.form-control(
                :placeholder="'pages.my.please_enter_title_of_goal' | translate"
                v-model="values.title")
          span.form-text.invalid {{ 'title' | errorText }}
        .form-group
              label {{ 'ui.labels.description' | translate }}
              vue-simplemde(
                v-model="values.description"
                :configs="configs")

              span.form-text.invalid {{ 'description' | errorText }}
        .form-group
              label {{ 'pages.my.reason_for_this_goal' | translate }}
                v-info-icon(:text="$t('pages.my.goal_reason_info')")
              textarea.form-control(
                      :placeholder="'pages.my.please_enter_reason_for_a_goal' | translate "
                      rows="4"
                      v-model="values.reason")
              span.form-text.invalid {{ 'message' | errorText }}
        .form-group
          label {{ 'pages.my.deadline' | translate }}
          v-date-picker(
                      mode="single"
                      color="green"
                      v-model="date"
                      :attributes="attrs"
                      :popover="{ placement: 'bottom', visibility: 'click' }"
                      :max-date="null")
                      template(
                        v-slot="{ inputValue, inputEvents }")
                        input.form-control(
                          v-on="inputEvents"
                          :value="inputValue"
                          :placeholder="'pages.my.set_deadline' | translate")
                      template(
                        v-if="date"
                        v-slot:footer)
                        .flex.justify-center.mb-3
                          v-btn(
                            icon
                            danger
                            @click="date = null")
                            i.fas.fa-trash
          span.form-text.invalid {{ 'deadlineAt' | errorText }}
        .dotted-line
        .create-goal--creating-badges
          h3.title-2 {{ 'ui.labels.adding_badges' | translate }}
          .flex.align-center.create-goal--empty(v-if="!values.badges.length")
            img.create-goal--empty__img(src="@/assets/img/add_new_goal.svg" alt="Add new goal")
            .create-goal--empty__block
              p.create-goal--empty__text {{ 'pages.my.no_badges_added_yet'| translate }}
              v-btn(
                :outlinedText="true"
                @click.native.stop="onShowGoals") {{  'ui.labels.add_badges' | translate  }}
                v-card(elevation)
          .create-goal--header(v-else)
            span.create-goal--header__subtitle {{ 'pages.my.add_badges_without_goal_impossible' | translate }}
            v-btn.create-goal--header__icon(icon @click.native.stop="onShowGoals")
                v-icon plus
          div
              goals-item(v-for="item in values.badges"
                :key="item.id+'a'"
                :item="item"
                icon-close
                newStyleEnabled
                ghost
                noHover
                classesBadge="ml-19"
                @onRemove="deleteBadge")
          div(style="width: 100%")
            vDropdownDetachedList(
                ref="dropdown"
                disabledScrollId="new-goal-scroll-panel"
                trackHeightById="goal-badges-content-height-tracker"
                @scroll-complete="onHandleScroolEnd")
              template(slot="component")
                .flex.create-goal--search
                    input.input-field(
                      v-focus
                      v-model="search"
                      @input="searchSkill"
                      type="text"
                      :placeholder="'ui.inputs.type_to_research' | translate")
                    v-btn.create-goal--search__icon(text)
                      v-preloader(v-if="loadingBadges" :size='16')
                      v-icon(v-else) search
              template(slot="content")
                goals-item(v-for="item in badges"
                :key="item.id"
                :item="item"
                newStyleDrop
                :active="!values.badges.find(el => el.id === item.id)"
                @onAdd="onBadgeAdd"
                @onRemove="deleteBadge")
                v-skeleton.w-100.mb-2(v-if="loadingBadges")
                v-skeleton.w-100.mb-2(v-if="loadingBadges")
                v-skeleton.w-100(v-if="loadingBadges")
                v-list-empty(v-if="!loadingBadges && badges && !badges.length")
                    i.icon.icon--sm.icon--no-result
                    span {{ 'ui.labels.no_results' | translate }}
        span.form-text.invalid(style="font-size: 0.9rem") {{ 'badgeIds' | errorText }}
        .form-group.form-group--supervisor
          v-checkbox(
            v-tooltip="$t('infotext.your_supervisor_will_receive_a_notification_to_check_and_confirm_this_goal')"
            :label="'pages.my.notify_my_supervisor_about_this_goal' | translate"
            v-model="values.isSupervisorNotificationEnabled")
    v-card-actions( style="justify-content:center")
      .create-goal--action__wrapper(v-if="!editedGoal")
        v-btn(
          block
          :loading="onCreating"
          @click="onFormSend") {{  'pages.my.add_goal' | translate  }}
      template(v-else)
        v-btn(
          block
          text
          danger
          outlinedText
          :loading="onCreating"
          @click="onClose") {{  'ui.buttons.cancel' | translate  }}
        v-btn(
          block
          :loading="onCreating"
          @click="onFormSend") {{  'pages.my.edit_goal' | translate  }}
</template>
<script>
import { mapActions } from 'vuex'
import api from '@/api'
import Chips from './Chips'
import GoalsItem from '@/views/my/modals/goals/GoalsItem.vue'
import Badge from '@/components/svg/Badge'
import vDropdownDetachedList from '@/components/ui/v-dropdown-detached-list/vDropdownDetachedList.vue'
import VueSimplemde from 'vue-simplemde'

export default {
  name: 'NewGoalModal',

  components: {
    Chips, GoalsItem, Badge, vDropdownDetachedList, VueSimplemde
  },

  mounted () {
    this.init()
  },

  destroyed () {
    this.clearErrors()
  },

  props: {
    editedGoal: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    loadingBadges: false,
    onCreating: false,
    badges: [],
    levels: [],
    selectedBadge: {},
    selectedSkill: null,
    badgePage: 1,
    search: '',
    blockScroll: true,
    values: {
      title: '',
      description: '',
      reason: '',
      badges: [],
      isSupervisorNotificationEnabled: true,
      deadlineAt: null
    },
    attrs: [
      {
        key: 'today',
        highlight: {
          color: 'green'
        },
        dates: new Date()
      }
    ],
    date: null,
    selectedAllBadge: [],
    configs: {
      spellChecker: false,
      status: false,
      hideIcons: [
        'guide',
        'fullscreen',
        'side-by-side'
      ]
    }
  }),

  methods: {
    ...mapActions('app', [
      'clearErrors'
    ]),

    ...mapActions('goals', [
      'createGoal',
      'updateGoal'
    ]),

    async init () {
      this.loadBadges()
      if (this.editedGoal) {
        for (const key in this.values) {
          if (key === 'deadlineAt' && this.editedGoal[key]) {
            this.date = new Date(this.editedGoal[key])
          } else if (key === 'badges' && this.editedGoal[key]) {
            this.values[key] = [...this.editedGoal[key]]
            continue
          }
          this.values[key] = this.editedGoal[key]
        }
      }
    },

    onBadgeAdd (item) {
      if (item.id && !this.values.badges.find(el => this.selectedBadge.id === el.id)) {
        this.values.badges.push(item)
      }
    },

    deleteBadge (item) {
      let idx = this.values.badges.findIndex(el => item.id === el.id)
      this.values.badges.splice(idx, 1)
    },

    async searchSkill () {
      this.badgePage = 1
      this.loadBadges()
    },

    async loadBadges () {
      this.loadingBadges = true
      const params = {
        noCategories: 1,
        sort: 'name',
        page: this.badgePage,
        q: this.search
      }
      const { data, headers } = await api.badges.search(params)
      if (headers && (+headers['x-pagination-page-count'] > this.badgePage)) {
        this.blockScroll = false
      }
      this.loadingBadges = false
      if (this.badgePage === 1) {
        this.badges = data
      } else {
        this.badges = [...this.badges, ...data]
      }
    },

    onHandleScroolEnd () {
      if (!this.loadingBadges) {
        this.badgePage += 1
        if (!this.blockScroll) {
          this.loadBadges()
        }
        this.blockScroll = true
      }
    },

    async onFormSend () {
      this.onCreating = true
      this.clearErrors()
      let form = { ...this.values }
      form.deadlineAt = this.date ? this.date.toISOString().substring(0, 10) : null
      form.badgeIds = this.values.badges.map(el => el.id)
      delete form.badges

      if (this.editedGoal) {
       const { error } = this.updateGoal({ goalId: this.editedGoal.id, values: form })
        if (!error) {
          delete form.badgesIds
          for (let key in form) {
            this.editedGoal[key] = form[key]
          }
          this.editedGoal.badges = [...this.values.badges]
          this.onClose()
        }
      } else {
      const data = await this.createGoal(
          {
            goal: form,
            employeeId: this.isEmployeeView ? +this.$route.params.employee_id : null }
        )
        if (data) this.onClose()
      }
      this.onCreating = false
    },

    onClose () {
      this.$emit('close')
    },

    isEmployeeView () {
      return this.$route.path.includes('employee')
    },
    onShowGoals () {
      this.$refs.dropdown.open()
    }
  }
}
</script>

<style lang="scss">
  .v-checkbox label {
    font-size: 1.1rem !important;
    font-weight: 600 !important;
  }
</style>

<style lang="scss" scoped>

.dotted-line{
  border-bottom: 1px dashed $color-line-dotted;
  margin: 32px 0 18px;
}
.form-group {
   &--supervisor {
    margin-top: 24px;
    margin-bottom: 0;
  }

  label {
    font-weight: normal;
    color: $h2-title;
  }

}

.create-goal--action__wrapper {
  width: 100%;
}

  .form-group--badge-row {
    display: flex;
    gap: 10px;

    .form-group {
      width: calc(50% - 5px)
    }
  }
  .create-goal--add-badge__action {
    display: flex;
    justify-content: center;
    margin: 15px 0px;

    span {
      font-size: 1.2rem;
      font-weight: 600;
      cursor: pointer;
      color: $color-primary;

      &:hover {
          color: darken($color-primary, 15%);
      }
    }
}
.create-goal{
  &--creating-badges{
    position: relative;
  }
  &--empty {
    margin-top: 25px;
    &__img{
      width: 162px;
      height: 168px;
      margin-right: 42.8px;
      margin-left: 31px;
    }
    &__text{
      font-weight: bold;
      font-size: 1.7rem;
      line-height: 24px;
      color: $body-text-2;
      padding-bottom: 18px;
      margin-bottom: 0;
    }
  }
  &--header {
    position: relative;
    margin-bottom: 24px;
      &__subtitle {
        font-size: 14px;
        color: $body-text-2;
      }
      &__icon {
       &.v-btn {
        position: absolute;
        top: -17px;
        right: 7px;
        padding: 0;
        color: $body-text-2;
        i.fa{
          font-size: 21px!important;
        }
      }
      }
  }

  &--search{
    align-items: center;
    position: relative;
    border-radius: 4px;
    background-color: $light-blue;
    &__icon{
      &.v-btn{
        position: absolute;
        right: 18px;
        padding: 0;
        min-width: 14px!important;
        background-color: transparent;
        &:hover{
          background-color: transparent;
        }
      }
      svg{
       ::v-deep path {
        fill: $color-dark-green;
      }
      }
    }
  }
}
</style>
