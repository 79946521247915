<template lang="pug">
  .skills-wrapper
    input.search-field(
      type="text"
      :placeholder="'ui.inputs.search_by_name' | translate"
      v-model="search")
    vue-scroll
      .skills-select
        .skills-select__col(
          v-for="item in items"
          :key="item.id")
          select-skills-item(
            :item="item"
            :is-favorite="isFavorite(item)"
            :is-activated="isActivated(item)"
            :needs-validation="needsValidation(item)"
            @activate="toggleItemActivate"
            @goal-toggle="toggleItemGoal")

</template>

<script>
import api from '@/api'
import { mapActions, mapGetters } from 'vuex'

import SelectSkillsItem from './SelectSkillsItem'

export default {
  name: 'SkillsSelect',

  components: {
    SelectSkillsItem
  },

  mounted () {
    this.loadBadges()
    this.setFavorites({ all: true })
  },

  data: () => ({
    search: null,
    items: [],
    activatedList: []
  }),

  computed: {
    ...mapGetters('goals', [
      'goals'
    ]),

    ...mapGetters('badges', [
      'getFavorites'
    ])
  },

  methods: {
    ...mapActions('trees', [
      'getNodeById'
    ]),

    ...mapActions('tree', [
      'toggleBadge'
    ]),

    ...mapActions('badges', ['setFavorites', 'addFavorites', 'deleteFavorites']),

    async loadBadges () {
      const params = {
        onBoarding: 1
      }
      const { data } = await api.badges.list(params)
      this.items = data
    },

    async searchBadges () {
      const params = {
        q: this.search,
        onBoarding: 1
      }
      const { data } = await api.badges.search(params)
      this.items = data
    },

    isActivated (item) {
      return this.activatedList
        .some(el => el.id === item.id)
    },

    isFavorite (item) {
      return this.getFavorites
        .some(el => el.id === item.id)
    },

    needsValidation (item) {
      return !!(item.isCommentForActivatingRequired ||
        item.isFileRequired ||
        item.isExpiryRequired)
    },

    async toggleItemActivate (item) {
      const node = await this.getNodeById(item.id)
      const index = this.activatedList
        .map(item => item.id)
        .indexOf(item.id)
      if (index !== -1) {
        node.props.isActivated = false
        this.activatedList.splice(index, 1)
      } else {
        node.props.isActivated = true
        this.activatedList.push(item)
      }
      this.toggleBadge({ badge: item, toggleFromStore: false, node })
    },

    toggleItemGoal (item) {
      const index = this.getFavorites
        .map(item => item.id)
        .indexOf(item.id)
      if (index !== -1) {
        this.deleteFavorites(item.id)
      } else {
        this.addFavorites(item.id)
      }
    }
  },

  watch: {
    search (value) {
      if (value) {
        this.searchBadges()
      } else {
        this.loadBadges()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .skills-wrapper {
    width: 100%;
    height: 70vh;
    overflow: hidden;
    padding: 15px 0;

    .search-field {
      width: 100%;
      max-width: 300px;
      border: none;
      border-radius: 4px;
      background-color: #eee;
      margin-top: 5px;
      margin-bottom: 10px;
      padding: 4px 12px;
      font-size: 14px;
    }
  }

  .skills-select {
    column-count: 3;
    column-gap: 0;
    margin: 0 -10px !important;
    margin-bottom: 20px !important;

    .skills-select__col {
      display: inline-block;
      width: 100%;
      padding: 0 10px;
      margin-bottom: 20px;
    }
  }
</style>
