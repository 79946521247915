<template lang="pug">
div.template-detail
  v-dialog(
    :width="620"
    :value="templateModal !== null"
    @input="close")

    gradation-tabs(
      disableDragAndDrop
      :gradationalBadges="template.levels"
      :activeTabIndex="activeTabIndex"
      @setActiveTabIndex="setActiveTabIndex"
      @didAddGradationalSubBadge="onAddLevel"
      @didRemoveGradationalSubBadge="onShowDeleteLevelModal"
      :max-tabs-number="null")

    .tabs-content
      v-card(styles="border-top-left-radius:0px")
        v-card-head
          v-card-head-label
            v-editable(
              v-if="loaded"
              :disabled="false"
              :iconEditTooltip="$t('pages.builder.edit_the_template_name')"
              @change="updateTemplateName"
              @close="onSaveTemplateName")
              h3.title {{ template.name }}
            v-skeleton(v-else height="30px")
            span.form-text.invalid {{ templateNameError  }}
          v-card-head-toolbar
            v-btn(
              icon
              gray
              @click="close") x
        v-scrollable(
          :bottom-offset="100")
          v-card-content
            .form-item
              .required
                label(for="name") {{ 'ui.labels.level_name' | translate }}
                v-info-icon(:text="$t('infotext.set_a_name_for_this_level_examples')")
              input.input-field(
                v-if="!showSkeleton"
                name="name"
                style="width:200px; display:block"
                type="text"
                :placeholder="$t('pages.builder.template_level_example')"
                v-model="activeTab.name"
                @blur="onUpdateLevel")
              v-skeleton(v-else)
              span.form-text.invalid {{ levelNameErrors[activeTabIndex] }}
            .form-item
              label(for="description") {{ 'ui.labels.description' | translate }}
              v-info-icon(:text="$t('infotext.template_desc_message_this_description_will_be_added')")
              textarea.form-control.input-field(
                v-if="!showSkeleton"
                :placeholder="'ui.inputs.enter_description' | translate "
                rows="4"
                name="description"
                v-model="activeTab.description"
                @blur="onUpdateLevel")
              v-skeleton(v-else width="100%" height="100px")
              span.form-text.invalid {{ 'description' | errorText }}
            level-validation(v-if="loaded" :loading="showSkeleton"  ref="validation" :badge="activeTab" @on-change-mode="onUpdateLevel")

            label {{ 'ui.labels.settings' | translate }}
            v-info-icon(:text="$t('infotext.define_settings_for_new_badges')")
            .u-stack-panel.mb-3
              .u-stack-panel__item
                v-checkbox(
                  v-if="loaded"
                  :label="'pages.builder.visible' | translate"
                  v-tooltip="$t('infotext.hide_this_element_for_normal_users')"
                  :value="template.isVisible"
                  @input="onChangeVisible")
                v-skeleton(v-else width="150px" height="30px")
                v-info-icon(text="Show this badge for all users.")
              .u-stack-panel__item
                v-checkbox(
                    :value="isCommentRequired"
                    :cutLongText="false"
                    :label="'pages.builder.comment_for_activate_required' | translate"
                    @input="onChangeRequiredComment")
              .u-stack-panel__item
                v-checkbox(
                  :value="isFileRequired"
                  label="File upload required"
                  @input="onChangeRequiredFile")
                v-info-icon(text="Require the upload of a file or certificate to activate the badge. Specify in the description what files you want your users to upload.")
            .form-item
              badge-detail-expiring(
                :badge-info="activeTab"
                @update="onPropsUpdate")
        div.callout
          v-card-actions(left)
            span.active-dark-text(
              @click="onShowDeleteModal")
              | {{ 'pages.builder.delete_template' | translate }}
          v-switch(
            icon
            outline
            :label="'pages.builder.enable_full_syncronization' | translate"
            label-position="left"
            :infoIconText="'infotext.enable_sync_warning_infotext' | translate"
            v-model="template.isSyncEnabled"
            @input="onChangeSyncEnabled")

  modal-delete(
    :show="modals.showDeleteModal"
    @on-cancel="onHideDeleteModal"
    @on-confirm="onConfirmDelete")

  modal-level-delete(
    :show="modals.showDeleteLevelModal"
    @on-cancel="onHideDeleteLevelModal"
    @on-confirm="onConfirmDeleteLevel")

  modal-sync-warning(
    :show="modals.showSyncWarningModal"
    @on-cancel="onHideSyncWarningModal"
    @on-confirm="onConfirmSyncWarning")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import GradationTabs from '@/components/ui/GradationTabs'

import ModalDelete from './modals/ModalDelete'
import ModalLevelDelete from './modals/ModalLevelDelete'
import ModalSyncWarning from './modals/ModalSyncWarning'
import LevelValidation from './common/LevelValidation'

import BadgeDetailExpiring from '../badge-detail/BadgeDetailExpiring'

export default {
  name: 'TemplateDetail',

  components: {
    GradationTabs,
    ModalDelete,
    ModalLevelDelete,
    ModalSyncWarning,
    LevelValidation,
    BadgeDetailExpiring
  },

  props: {
    index: Number,
    show: Boolean
  },

  async mounted () {
    this.clearErrors()
    this.activeTabIndex = this.index || 0
    this.template = await this.getById(this.templateModal.id)
    this.loaded = true
  },

  destroyed () {
    this.clearErrors()
  },

  data: (vm) => ({
    modals: {
      showDeleteModal: false,
      showDeleteLevelModal: false,
      showSyncWarningModal: false
    },
    template: {
      name: vm.$t('pages.builder.template_name'),
      isVisible: true,
      isSyncEnabled: false,
      isCommentForActivatingRequired: false,
      levels: []
    },
    level: {
      name: '',
      description: '',
      validationTypeId: null,
      maxExpiryDays: null,
      isExpiryRequired: false,
      isFileRequired: false
    },
    loaded: false,
    disableActions: false,
    loadingIndex: null,
    levelNameErrors: {},
    templateNameError: '',
    activeTabIndex: 0,
    keyUpdateTemplate: 1
  }),

  methods: {
    ...mapActions('app', [
      'clearErrors'
    ]),

    ...mapActions('builder', [
      'closeTemplateModal'
    ]),

    ...mapActions('badges/templates', [
      'getById',
      'updateTemplate',
      'deleteTemplate',
      'createLevel',
      'deleteLevel',
      'updateLevel'
    ]),

    updateTemplateName (name) {
      this.template.name = name
    },

    close () {
      this.clearErrors()
      this.closeTemplateModal()
    },

    setActiveTabIndex (index) {
      this.activeTabIndex = index
    },

    onShowDeleteLevelModal () {
      this.modals.showDeleteLevelModal = true
    },

    onHideDeleteLevelModal () {
      this.modals.showDeleteLevelModal = false
    },

    onConfirmDeleteLevel () {
      this.onDeleteLevel(this.activeTab.id)
      this.template.levels.splice(this.activeTabIndex, 1)
      this.setActiveTabIndex(this.activeTabIndex === 0 ? 0 : this.activeTabIndex - 1)
      this.onHideDeleteLevelModal()
    },

    onConfirmSyncWarning () {
      this.updateTemplate({ ...this.template, isSyncEnabled: !!this.template.isSyncEnabled })
      this.modals.showSyncWarningModal = false
      this.onUpdateLevel()
    },

    onShowDeleteModal () {
      this.modals.showDeleteModal = true
    },

    onShowSyncWarningModal () {
      this.modals.showSyncWarningModal = true
    },

    onHideDeleteModal () {
      this.modals.showDeleteModal = false
    },

    onHideSyncWarningModal () {
      this.modals.showSyncWarningModal = false
      this.template.isSyncEnabled = false
    },

    onConfirmDelete () {
      this.deleteTemplate(this.template.id)
      this.close()
      this.onHideDeleteModal()
    },

    onSaveTemplateName () {
      this.onSave()
    },

    onChangeVisible (value) {
      this.template.isVisible = value
      this.onSave()
    },

    onChangeRequiredComment (value) {
      this.template.levels[this.activeTabIndex].isCommentForActivatingRequired = value
      this.onUpdateLevel()
      this.onIncrementKeyUpdateTemplate()
    },

    onChangeRequiredFile (value) {
      this.template.levels[this.activeTabIndex].isFileRequired = value
      this.onUpdateLevel()
    },

    onPropsUpdate (props) {
      for (const index in props) {
        this.template.levels[this.activeTabIndex][index] = props[index]
      }
      this.onUpdateLevel()
    },

    onChangeSyncEnabled (props) {
      if (props) {
        this.onShowSyncWarningModal()
      } else {
        this.updateTemplate(this.template)
      }
    },

    async onUpdateLevel () {
      const errorIndex = this.activeTabIndex
      const { errorData } = await this.updateLevel({ templateId: this.template.id, level: this.activeTab })
      if (errorData && errorData.status === 422) {
        let errorInfoMessage = (errorData.data || []).find(el => el.field === 'name') || {}
        this.levelNameErrors[errorIndex] = errorInfoMessage.message || ''
        this.levelNameErrors = { ...this.levelNameErrors }
      } else {
        this.levelNameErrors[errorIndex] = ''
        this.levelNameErrors = { ...this.levelNameErrors }
      }
    },

    async onAddLevel () {
      if (this.loaded && !this.disableActions) {
        this.template.levels.push({ ...this.level })
        const nextActiveIndex = this.template.levels.length - 1
        this.setActiveTabIndex(nextActiveIndex)
        this.loadingIndex = nextActiveIndex
        this.disableActions = true
        const { data } = await this.createLevel({ templateId: this.template.id })
        if (data) {
          for (const key in data) {
            this.template.levels[nextActiveIndex][key] = data[key]
          }
          this.$refs.validation.init()
        }
        this.loadingIndex = null
        this.disableActions = false
      }
    },

    async onDeleteLevel (id) {
      if (id) {
        this.deleteLevel({ templateId: this.template.id, id: id })
      }
    },

    async onSave () {
      this.clearErrors()
      if (this.template.id) {
        const { errorData } = await this.updateTemplate({ ...this.template, isSyncEnabled: !!this.template.isSyncEnabled })
        if (errorData && errorData.status === 422) {
          const errorInfoMessage = (errorData.data || []).find(el => el.field === 'name') || {}
          this.templateNameError = errorInfoMessage.message || ''
        } else {
          this.templateNameError = ''
        }
      }
    },

    onIncrementKeyUpdateTemplate () {
      this.keyUpdateTemplate += 1
    }

  },

  computed: {
    ...mapGetters('builder', [
      'templateModal'
    ]),

    activeTab () {
      return this.template.levels[this.activeTabIndex] || {}
    },

    showSkeleton () {
      return !this.loaded || this.loadingIndex === this.activeTabIndex
    },

    isCommentRequired () {
      return this.keyUpdateTemplate ? this.template?.levels[this.activeTabIndex]?.isCommentForActivatingRequired : false
    },

    isFileRequired () {
      return this.template?.levels[this.activeTabIndex]?.isFileRequired
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';

  .invalid {
    font-size: 1rem;
  }

  .v-card__actions {
    padding-top: 24px !important;
  }

  .v-card__head-label {
    justify-content: center;
  }
</style>
<style lang="scss">
.template-detail {
  z-index: 103;
}
.callout {
  display: flex;
  justify-content: space-between;

  .v-switch {
    margin: 0 20px 0 0 !important;

    .v-switch__label {
      font-size: 1rem;
    }

    .v-info-icon {
      margin-left: 2px;
      margin-right: 2px;
    }

    .v-switch__toggler {
      margin-left: 0.25em;
    }
  }
}
</style>
