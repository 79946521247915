<template lang="pug">
  g(:class="{ 'selected': isSelected, 'hilighted': isHilighted }")
    template(v-if="isCategory")
      line(:x1="begin.x" :y1="begin.y" :x2="end.x" :y2="end.y" :stroke="lighterColor" stroke-width="6" stroke-linecap="square")
      line.halo(:x1="begin.x" :y1="begin.y" :x2="end.x" :y2="end.y" stroke="#ffdbac" stroke-width="6" stroke-linecap="round")

    template(v-else)
      line(:x1="begin.x" :y1="begin.y" :x2="angle" :y2="begin.y" :stroke="isSuggested ? '#ccd0dc' : lighterColor" stroke-width="1.3" :stroke-dasharray="isSuggested ? 4 : 1"  stroke-linecap="square")
      line(:x1="angle" :y1="begin.y" :x2="end.x" :y2="end.y" :stroke="isSuggested ? '#ccd0dc' : lighterColor" stroke-width="1.3" :stroke-dasharray="isSuggested ? 4 : 1"  stroke-linecap="square")
      line.halo(:x1="begin.x" :y1="begin.y" :x2="angle" :y2="begin.y" stroke="#ffdbac" stroke-width="6" stroke-linecap="round")
      line.halo(:x1="angle" :y1="begin.y" :x2="end.x" :y2="end.y" stroke="#ffdbac" stroke-width="6" stroke-linecap="round")
</template>

<script>
import helpers from '@/util/helpers.js'

export default {
  name: 'TreeNodeLine',

  props: {
    begin: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    end: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    color: {
      type: String,
      default: '#ccd0dc'
    },
    isCategory: {
      type: Boolean,
      default: true
    },
    direction: {
      type: String,
      default: 'left'
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isHilighted: {
      type: Boolean,
      default: false
    },
    isSuggested: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    angle () {
      let a = this.end.x
      let b = Math.abs(this.end.y - this.begin.y)
      return this.direction === 'right' ? a - b : a + b
    },

    lighterColor () {
      return helpers.pSBC(0.55, this.color, '#F8F9FC')
    }
  }
}
</script>
