<template lang="pug">
  .form-item
    label(for="required-badges") {{ 'pages.builder.required_badge' | translate }}
    v-select(
      enableClear
      fixed
      name="required-badges"
      trackBy="displayName"
      :options="badgesSelect.options"
      :loading="badgesSelect.loading"
      v-model="badgesSelect.selected"
      @on-change="addRequiredBadge")

    .required-badges(
      v-if="allRequiredBadges.length")
      v-show-hide
        node-list-item(
          v-for="badge in allRequiredBadges"
          :key="badge.id"
          :data="badge"
          :show-remove="!badge.isPreRequired"
          @remove="onRequiredBadgeRemove(badge)")

</template>

<script>
import api from '@/api'

import { mapActions } from 'vuex'
import { NODE_TYPE } from '@/util/constants'

import NodeListItem from '@/components/common/NodeListItem'

export default {
  name: 'BadgeDetailRequiredBadges',

  components: {
    NodeListItem
  },

  props: {
    form: Object,
    node: Object,
    selected: Array,
    badgeInfo: Object,
    preRequiredBadges: Array
  },

  mounted () {
    this.loadRequiredBadges()
    this.badgesSelect.selected = [...this.selected]
  },

  data: () => ({
    badgesSelect: {
      options: [],
      selected: [],
      loading: false
    }
  }),

  methods: {
    ...mapActions('trees', [
      'addRequired',
      'removeRequired',
      'addRequiredBadgeInGradationalSubBadge',
      'removeRequiredBadgeInGradationalSubBadge'
    ]),

    ...mapActions('app', [
      'showConfirmDialog'
    ]),

    async loadRequiredBadges () {
      if (!this.badgesSelect.loading) {
        this.badgesSelect.loading = true
        if (this.node.props.isSuggested) {
          const { data } = await api.loadBadges()
          this.badgesSelect.options = data
        } else {
          if (this.form) {
            const { data } = await api.getRequiredBadges(this.form.id)
            this.badgesSelect.options = data
              .filter(item => item.id !== this.form.id)
          }
        }
      }
      this.badgesSelect.loading = false
    },

    addRequiredBadge () {
      this.badgeInfo.requiredBadges.forEach(props => {
        if (!this.badgesSelect.selected.some(item => item.id === props.id)) {
          this.removeRequiredBadge(props)
        }
      })
      this.badgesSelect.selected.forEach(props => {
        if (!this.badgeInfo.requiredBadges.some(item => item.id === props.id)) {
          if (this.form.typeId === NODE_TYPE.GRADATIONAL_SUB_BADGE) {
            const payload = {
              node: this.node,
              props: props,
              badgeId: this.form.id
            }
            this.addRequiredBadgeInGradationalSubBadge(payload)
          } else {
            const payload = {
              node: this.node,
              props: props
            }
            this.addRequired(payload)
          }
        }
      })
    },

    onRequiredBadgeRemove (props) {
      if (this.node.props.isSuggested) {
        this.badgesSelect.selected = this.badgesSelect.selected.filter(el => el.id !== props.id)
        this.badgeInfo.requiredBadges = this.badgesSelect.selected
        return null
      }
      const options = {
        title: 'ui.modals.do_you_really_want_to_remove_required_badge',
        params: props,
        onconfirm: this.removeRequiredBadge
      }

      this.showConfirmDialog(options)
    },

    removeRequiredBadge (props) {
      if (this.form.typeId === NODE_TYPE.GRADATIONAL_SUB_BADGE) {
        const payload = {
          node: this.node,
          props: props,
          badgeId: this.form.id
        }
        this.removeRequiredBadgeInGradationalSubBadge(payload)
      } else {
        const payload = {
          node: this.node,
          props: props
        }
        this.removeRequired(payload)
      }
    }
  },

  computed: {
    allRequiredBadges () {
      return [...this.preRequiredBadges, ...this.selected]
    }
  },

  watch: {
    selected () {
      this.loadRequiredBadges()
      this.badgesSelect.selected = [...this.selected]
    }
  }
}
</script>

<style>
</style>
