<template lang="pug">
  .v-avatar-group
    slot
</template>

<script>
export default {
  name: 'VAvatarGroup'
}
</script>

<style lang="scss">
  .v-avatar-group {
    display: flex;
    flex-direction: reverse;

    > *:not(:last-child) {
      margin-right: -6px;
    }

    > * {
      border: 1px solid $color-white !important;
      border-radius: 50%;
    }
  }
</style>
