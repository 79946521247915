<template lang="pug">
  g
    tree-node-line(
      v-if="parentNode"
      :begin="parentPos"
      :end="nodePos"
      :color="chainColor"
      :direction="direction"
      :is-category="isCategory"
      :is-selected="isSelected"
      :is-suggested="isSuggested"
      :is-hilighted="isLineHilighted")

    tree-node(
      v-for="(child, i) in node.children"
      v-if="!child.props.isSuggested || (isBuilderPage && showSuggestBadges && child.props.isSuggested)"
      :key="`child-${i}`"
      :node="child"
      :zoom="zoom"
      :parent-node="node"
      :depth="depth + 1"
      :isEditable="isEditable"
      :filteredBadges="filteredBadges"
      @activate-parent="onParentActivate"
      @node-select="onNodeSelect"
      @node-right-click="onNodeRightClick")

    tree-node-category(
      v-if="isCategory"
      :id="node.props.id"
      :pos="nodePos"
      :color="chainColor"
      :depth="depth"
      :children="node.children"
      :node="node"
      :is-selected="(isSelected || isFiltered || isSelectedForMove) && !hasSelectedHookedBadge"
      :is-hilighted="isHilighted"
      @select="onNodeSelect"
      @right-click="onRightClick")
        tree-node-hooked-badge(
          v-if="node.props.hookedBadge"
          :color="hookedColor"
          :depth="depth"
          :node="node.props.hookedBadge"
          :pos="{ x: 20, y: 20 }"
          :filtered-badges="filteredBadges"
          :filteredBadges="filteredBadges"
          @select="onNodeSelect"
          @right-click="onRightClick")

    tree-node-badge(
      v-if="isSimpleBadge"
      :is-suggested="isSuggested"
      :id="node.props.id"
      :pos="nodePos"
      :color="chainColor"
      :depth="depth"
      :node="node"
      :is-selected="isSelected || isFiltered || isSelectedForMove"
      :is-hilighted="isHilighted"
      @select="onNodeSelect"
      @right-click="onRightClick")

    tree-node-badge-gradational(
      v-if="isGradationalBadge"
      :is-suggested="isSuggested"
      :id="node.props.id"
      :pos="nodePos"
      :color="chainColor"
      :depth="depth"
      :level="level"
      :node="node"
      :is-selected="isSelected || isFiltered || isSelectedForMove"
      :is-hilighted="isHilighted"
      @select="onNodeSelect"
      @right-click="onRightClick")

    tree-node-label(
      v-if="showLabel"
      :node="node"
      :depth="depth"
      :pos="nodePos"
      :parent-pos="parentPos"
      :title="labelText"
      :node-size="100 / depth"
      :node-type="node.props.typeId"
      :is-new-badge="isNewlyBadgesIds.includes(node.props.id) && isMyPage && isEnabledNotification"
      :is-editable="isEditable")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { NODE_TYPE, TREE_SCALE, COLOR_PRIMARY } from '@/util/constants.js'

import TreeNodeBadge from './TreeNodeBadge'
import TreeNodeBadgeGradational from './TreeNodeBadgeGradational'
import TreeNodeHookedBadge from './TreeNodeHookedBadge'
import TreeNodeCategory from './TreeNodeCategory'
import TreeNodeLabel from './TreeNodeLabel'
import TreeNodeLine from './TreeNodeLine'

export default {
  name: 'TreeNode',

  components: {
    TreeNodeBadge,
    TreeNodeBadgeGradational,
    TreeNodeHookedBadge,
    TreeNodeCategory,
    TreeNodeLabel,
    TreeNodeLine
  },

  props: {
    node: Object,
    parentNode: Object,
    depth: Number,
    zoom: Number,
    isEditable: Boolean,
    filteredBadges: Array
  },

  mounted () {
    if (this.node.props && (this.node.props.isActivated || this.node.props.hookedBadge?.props.isActivated)) {
      this.$emit('activate-parent', true)
    }
  },

  data: () => ({
    activeChildCount: 0
  }),

  methods: {
    ...mapActions('badges/newlyAdded', ['updateNewlyItemSeen']),

    onNodeSelect (payload) {
      this.$emit('node-select', payload)

      if (this.isEnabledNotification && this.isNewlyBadgesIds.includes(payload.node.props.id) && this.isMyPage) {
        this.updateNewlyItemSeen(payload.node.props.id)
      }
    },

    onRightClick (payload) {
      this.$emit('node-right-click', payload)
    },

    onParentActivate (value) {
      this.activeChildCount += value
        ? 1
        // next condition for fix bug with new badge activation
        : this.activeChildCount > 0
          ? -1
          : 0
    },

    onNodeRightClick (value) {
      this.$emit('node-right-click', value)
    }
  },

  computed: {
    ...mapGetters('trees', [
      'rootCategoryTitle',
      'highlightedTemplateId'
    ]),

    ...mapGetters('treeView', [
      'showAllAsActivated',
      'hilightSubBranche',
      'showSuggestBadges'
    ]),

    ...mapGetters('nodeTmp', [
      'cursorNode',
      'contextMenuNode',
      'selectedNodes'
    ]),

    ...mapGetters('badges/templates', [
      'selectedTemplate'
    ]),

    ...mapGetters('preferences', [
      'isDefaultView'
    ]),

    ...mapGetters('badges/newlyAdded', ['isNewlyBadgesIds']),

    ...mapGetters('settings', ['isEnabledNotification']),

    ...mapGetters('trees', ['isLoadLazyTree']),

    nodePos () {
      return { x: this.node.props.pos.x * TREE_SCALE, y: this.node.props.pos.y * TREE_SCALE }
    },

    parentPos () {
      if (!this.parentNode) return { x: 0, y: 0 }
      return { x: this.parentNode.props.pos.x * TREE_SCALE, y: this.parentNode.props.pos.y * TREE_SCALE }
    },

    isMainNode () {
      return !this.node.parents.length
    },

    isSuggested () {
      return this.node.props.isSuggested
    },

    isCategory () {
      return this.node.props.typeId === NODE_TYPE.CATEGORY
    },

    isSimpleBadge () {
      return this.node.props.typeId === NODE_TYPE.BADGE
    },

    isGradationalBadge () {
      return this.node.props.typeId === NODE_TYPE.GRADATIONAL_BADGE
    },

    isSelected () {
      if (this.cursorNode && this.cursorNode.props.isSuggested && !this.isBuilderPage) {
        return false
      } else if (this.cursorNode) {
        const editChainNodes = [this.cursorNode, ...this.cursorNode.parents]
        return editChainNodes.some(chainNode => chainNode.props.id === this.node.props.id)
      }
      return false
    },

    isSelectedForMove () {
      return !this.isMyPage && this.selectedNodes.some(node => node.props.id === this.node.props.id)
    },

    hasSelectedHookedBadge () {
      if (!this.cursorNode || this.cursorNode.props.typeId !== NODE_TYPE.HOOKED_BADGE) {
        return false
      }
      return this.cursorNode.parents[0].props.id === this.node.props.id
    },

    isFiltered () {
      if (this.selectedTemplate && this.node.props.template) {
        return this.selectedTemplate.id === this.node.props.template.id
      }

      if (this.filteredBadges && (this.isFinderPage || this.isAdministrationPage)) {
        return this.filteredBadges.some(item => {
          let result = false
          result = item.id === this.node.props.id
          if (!result) {
            result = !!(this.node.props.gradationalSubBadges || []).find(el => el.id === item.id)
          }
          return result
        })
      }

      if (this.contextMenuNode) {
        return this.contextMenuNode.props.id === this.node.props.id
      }

      return false
    },

    isHilighted () {
      if (this.hilightSubBranche && this.cursorNode) {
        const chainNodes = [this.node, ...this.node.parents]
        return chainNodes.some(node => node.props.id === this.cursorNode.props.id)
      }
      return false
    },

    isLineHilighted () {
      if (this.hilightSubBranche && this.cursorNode) {
        return this.node.parents.some(node => node.props.id === this.cursorNode.props.id)
      }
      return false
    },

    hasActiveChild () {
      return this.activeChildCount > 0
    },

    isActivated () {
      return this.showAllAsActivated ||
        this.node.props.isActivated ||
        this.node.props?.hookedBadge?.props.isActivated ||
        this.hasActiveChild
    },
    isActivatedHooked () {
      return this.showAllAsActivated ||
        this.node.props?.hookedBadge?.props.isActivated
    },

    hookedColor () {
      const chainNodes = [this.node, ...this.node.parents, this.node.hookedBadge]
      const colorNode = chainNodes.find(chainNode => {
        if (chainNode?.props?.color) {
          return chainNode.props.color
        }
      })
      const color = colorNode ? colorNode.props.color : COLOR_PRIMARY
      return this.isActivatedHooked ? color : '#C0C0C0'
    },

    chainColor () {
      const chainNodes = [this.node, ...this.node.parents, this.node.hookedBadge]
      const colorNode = chainNodes.find(chainNode => {
        if (chainNode?.props?.color) {
          return chainNode.props.color
        }
      })
      const color = colorNode ? colorNode.props.color : COLOR_PRIMARY
      return this.isActivated ? color : '#C0C0C0'
    },

    level () {
      if (this.showAllAsActivated) {
        return this.node.props.gradationalSubBadges.length + 1
      }
      let lev = 1

      let { isActivated, gradationalSubBadges } = this.node.props

      if (isActivated) {
        for (let i of gradationalSubBadges) {
          if (i.isActivated) {
            lev = i.starsCount
          }
        }
      } else if (this.isDefaultView && (this.isDefaultView.key === 'MY_PROFILE' || this.isDefaultView.key === 'MY_SKILLTREE')) {
          for (let i of gradationalSubBadges) {
              if (i.hasOwnProperty('helpers') && (i.helpers.isGoal || i.helpers.isProfileBadge || i.helpers.isRecommended)) {
              lev = i.starsCount
            }
          }
      }

      return lev
    },

    showLabel () {
      return this.zoom > this.depth * 0.12 ||
        this.node.localProps.showInput
    },

    nodeTitle () {
      const badges = [this.node.props, ...this.node.props.gradationalSubBadges]
      const badge = badges[this.level - 1]
      return this.isMyPage
          ? badge.displayName
          : badge.name || badge.displayName
    },

    labelText () {
      return this.isMainNode
        ? this.rootCategoryTitle
        : this.nodeTitle
    },

    direction () {
      return this.node.props.pos.x < this.parentNode.props.pos.x
        ? 'left'
        : 'right'
    },

    isBuilderPage () {
      return this.$route.path.includes('builder')
    },

    isMyPage () {
      return this.$route.path.includes('my')
    },

    isFinderPage () {
      return this.$route.path.includes('skill-finder')
    },

    isAdministrationPage () {
      return this.$route.path.includes('administration')
    }
  },

  watch: {
    isActivated (value) {
      this.$emit('activate-parent', value)
    }
  }
}
</script>

<style lang="scss">
  .selected,
  .drop-active {
    .halo {
      opacity: 1;
    }
  }

  .hilighted {
    .halo {
      stroke: #ff9696;
      opacity: 1;
    }
  }
</style>
