<template lang="pug">
  ul.v-list(
    :class="classes")
    slot
</template>

<script>
export default {
  name: 'VList',

  props: {
    flat: Boolean
  },

  computed: {
    classes () {
      return {
        'v-list--flat': this.flat
      }
    }
  }
}
</script>

<style lang="scss">
  .v-list {
    display: flex;
    flex-direction: column;

    &--flat {
      padding: 5px 0;

      .v-list__item {
        padding: 10px 20px !important;
        border-bottom: none !important;
      }
    }
  }
</style>
