<template lang="pug">
  .v-scrollable(
    :style="styles"
    ref="scrollable")
    vue-scroll(
      ref="scroll"
      :id="id"
      :ops="options"
      @handle-scroll="onScroll")
      .v-scrollable__inner(ref="content")
        slot
</template>

<script>
import ResizeObserver from '@juggle/resize-observer'
import { windowResizeObserver } from '@/util/helpers'

export default {
  name: 'VScrollable',

  props: {
    id: {
      type: String,
      default: null
    },
    maxHeight: Number,
    minHeight: {
      type: Number,
      default: 150
    },
    bottomOffset: {
      type: Number,
      default: 20
    },
    keepShow: {
      type: Boolean,
      default: true
    },
    updatable: {
      type: Boolean,
      default: false
    },
    scrollingX: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    this.initParams() // It must be here
    windowResizeObserver.subscribe(this.onWindowResize)
    this.initContentResizeObserver()
    if (this.$listeners['get-scroll-ref']) {
      this.$emit('get-scroll-ref', this.$refs.scroll)
    }
    setTimeout(() => { this.initParams() }, 1) // Hotfix for height initialization bug
  },

  updated () {
    if (this.updatable) {
      this.initParams()
      if (!this.blockCollbale) { // for insigths drawer panel
        this.blockCollbale = true
        setTimeout(() => {
          this.initParams()
          this.blockCollbale = false
        }, 300)
      }
    }
  },

  beforeDestroy () {
    windowResizeObserver.unsubscribe(this.onWindowResize)
    this.resizeObserver.unobserve(this.$refs.content)
  },

  data: () => ({
    resizeObserver: null,
    blockCollbale: false,
    contentHeight: 0,
    availableSpace: 0,
    topOffset: 0
  }),

  methods: {
    initContentResizeObserver () {
      this.resizeObserver = new ResizeObserver(entries => {
        this.onContentResize()
      })
      this.resizeObserver.observe(this.$refs.content)
    },

    onContentResize () {
      this.contentHeight = this.getContentHeight()
    },

    onWindowResize () {
      this.initParams()
    },

    initParams () {
      this.contentHeight = this.getContentHeight()
      this.availableSpace = this.getAvailableSpace()
    },

    getContentHeight () {
      return this.$refs.content &&
        this.$refs.content.clientHeight
    },

    getAvailableSpace () {
      if (this.$refs.content) {
        const offset = this.$refs.scrollable.getBoundingClientRect().top
        if (offset > 0) {
          this.topOffset = offset
        }
        return window.innerHeight - this.topOffset - this.bottomOffset
      }
      return 0
    },

    scrollToTop () {
      this.$refs.scroll.scrollTo({ y: 0, x: 0 })
    },

    onScroll (vertical, horizontal, nativeEvent) {
      this.$emit('on-scroll')
      const scrollHeight = nativeEvent.target.scrollHeight - nativeEvent.target.clientHeight
      let scrollTop = vertical.scrollTop
      if (!Number.isInteger(scrollTop) && window.devicePixelRatio > 1) { // fix ZOOM 100+
        scrollTop = Math.ceil(scrollTop)
      }
      if (scrollTop >= scrollHeight) {
        this.$emit('scroll-complete', this.$refs.scroll)
      }
    }
  },

  computed: {
    styles () {
      return {
        height: `${this.scrollWrapperHeight}px`
      }
    },

    scrollWrapperHeight () {
      const maxAvailableHeight = this.maxHeight
        ? Math.min(this.maxHeight, this.availableSpace)
        : this.availableSpace
      const heightLimitedByMaxValue = Math.min(this.contentHeight, maxAvailableHeight)
      return Math.max(this.minHeight, heightLimitedByMaxValue)
    },

    options () {
      return {
        scrollPanel: {
          scrollingX: this.scrollingX
        },
        bar: {
          keepShow: this.keepShow
        }
      }
    }
  },

  watch: {
    bottomOffset (value) {
      this.availableSpace = this.getAvailableSpace()
    }
  }
}
</script>

<style lang="scss">
  .v-scrollable {
    position: relative;

    &__inner {
      &:last-child {
        margin-bottom: 0!important;
      }
    }
  }
</style>
