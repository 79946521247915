<template lang="pug">
simple-modal(
    width="500"
    isWarning
    :title="$t('pages.builder.delete_level')"
    :type="modal.type"
    :confirmBtnTitle="$t('ui.buttons.delete')"
    :show="show"
    @on-confirm="onConfirm"
    @on-cancel="onCancel")
    template(slot="body")
      p {{ 'ui.labels.warning' | translate }}!
      p {{ 'infotext.template_level_delete_message' | translate }}

</template>

<script>
import { MODAL_TYPES } from '@/util/constants'
import SimpleModal from '@/components/modals/global/SimpleModal'

export default {
  name: 'ModalLevelDelete',

  components: {
    SimpleModal
  },

  props: {
    show: Boolean
  },

  data: () => ({
    modal: {
      type: MODAL_TYPES.WARNING_RED
    }
  }),

  methods: {
    onConfirm () {
      this.$emit('on-confirm')
    },

    onCancel () {
      this.$emit('on-cancel')
    }
  }
}
</script>

<style lang="scss">
</style>
