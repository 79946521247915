<template lang="pug">
  .v-avatar(
    :style="{ height: size + 'px', width: size + 'px' }")
    img(:src="src ||'/img/no-profile-picture.svg'")
</template>

<script>
export default {
  name: 'VAvatar',

  props: {
    src: {
      type: String,
      default: '/img/no-profile-picture.svg'
    },
    size: {
      type: Number,
      default: 40
    }
  }
}
</script>

<style lang="scss">
  .v-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 20px;
    min-width: 20px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
</style>
