<template lang="pug">
  .v-select-list__item-title(v-html="itemContent")
</template>

<script>
export default {
  name: 'VSelectItemTitle',

  props: {
    search: String,
    title: String
  },

  computed: {
    itemContent () {
      if (this.search && this.search.length) {
        const name = this.title.toLowerCase()
        const search = this.search.toLowerCase()
        const startIndex = name.indexOf(search)
        if (startIndex !== -1) {
          const endIndex = startIndex + search.length
          const middle = this.title.substring(startIndex, endIndex)
          const ending = this.title.substring(endIndex, name.length)
          if (startIndex > 0) {
            const beginning = this.title.substring(0, startIndex)
            return `${beginning}<i>${middle}</i>${ending}`
          }
          return `<i>${middle}</i>${ending}`
        }
      }
      return this.$t(`${this.title}`)
    }
  }
}
</script>

<style>
</style>
