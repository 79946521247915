<template lang="pug">
  v-expansion(
    ref="expiringExpansion"
    :value="badgeInfo.isExpiryRequired")
    v-checkbox(
      :label="'ui.labels.expiring' | translate"
      :disabled="disabled"
      v-model="badgeInfo.isExpiryRequired"
      @input="onExpiringExpansionToggle")
    v-info-icon(:text="$t('file-pond.require_the_upload')")
    template(slot="content" v-if="badgeInfo.isExpiryRequired" )
      span.subtitle-2  {{ 'file-pond.maximal_validity_time' | translate }}
      .interval-select
        .interval-select__col
          input.input-field(
            ref="maxExpiryYears"
            type="number"
            min="0"
            max="20"
            v-mask="'##'"
            :disabled="disabled"
            v-model="maxExpiry.years"
            @click="$refs.maxExpiryYears.focus()"
            @blur="onUpdate")
          span {{ 'ui.labels._years' | translate }}
        .interval-select__col
          input.input-field(
            ref="maxExpiryMonths"
            type="number"
            min="0"
            max="12"
            v-mask="'##'"
            :disabled="disabled"
            v-model="maxExpiry.months"
             @click="$refs.maxExpiryMonths.focus()"
            @blur="onUpdate")
          span {{ 'ui.labels._months' | translate }}
        .interval-select__col
          input.input-field(
            ref="maxExpiryDays"
            type="number"
            min="0"
            max="31"
            v-mask="'##'"
            v-model="maxExpiry.days"
            :disabled="disabled"
             @click="$refs.maxExpiryDays.focus()"
            @blur="onUpdate")
          span {{ 'ui.labels._days' | translate }}
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  name: 'BadgeDetailExpiring',

  directives: { mask },

  props: {
    badgeInfo: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    if (this.badgeInfo && this.badgeInfo.maxExpiryDays) {
      this.fillFields()
    }
  },

  data: () => ({
    maxExpiry: {
      days: 0,
      months: 6,
      years: 0
    }
  }),

  methods: {
    fillFields () {
      this.maxExpiry.years = Math.floor(this.badgeInfo.maxExpiryDays / 365)
      this.maxExpiry.months = Math.floor(this.badgeInfo.maxExpiryDays % 365 / 30)
      this.maxExpiry.days = (this.badgeInfo.maxExpiryDays - this.maxExpiry.years * 365) - (this.maxExpiry.months * 30)
    },

    onExpiringExpansionToggle (value) {
      this.$refs.expiringExpansion.setValue(value)
      this.onUpdate()
    },

    onUpdate () {
      const props = {
        maxExpiryDays: null
      }
      if (this.badgeInfo.isExpiryRequired) {
        props.maxExpiryDays = (this.maxExpiry.years * 365) +
          (this.maxExpiry.months * 30) +
          +this.maxExpiry.days
      }
      this.$emit('update', props)
    }
  },

  watch: {
    badgeInfo: {
      handler () {
        this.fillFields()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
</style>
