<template lang="pug">
  tbody.v-table__body(:class="classes")
    slot(
      v-if="showSlotContent")
    template(v-else-if="!loaded")
      tr.v-table__row.v-table__row--borderless(
        v-for="r in 8")
        td(v-for="c in cols")
          .cell-skeleton
    tr.v-table__row.v-table__row--empty(v-else)
      td(:colspan="cols")
        .v-table__info(v-if="noResult")
          i.icon.icon--no-result
          span {{ 'ui.labels.no_search_resuts' | translate }}
        .v-table__info(v-else)
          i.icon.icon--empty
          span {{ 'ui.labels.nothing_to_show_here' | translate }}

</template>

<script>

export default {
  name: 'VTableBody',

  props: {
    loaded: Boolean,
    empty: Boolean,
    noResult: Boolean,
    cols: {
      type: Number,
      default: 1
    }
  },

  computed: {
    showSlotContent () {
      return this.loaded &&
        !this.empty
    },

    classes () {
      return {
        'v-table__body--empty': !this.showSlotContent
      }
    }
  }
}
</script>

<style>
</style>
