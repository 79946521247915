<template lang="pug">
    .sd-validation-info
        .sd-status-icons__item
                i.fas.fa-user-lock
        .sd-validation-info--text
            p {{ changeText }}
            template(v-if="checkValidatorEmployees && badge.helpers.validatorEmployees.length")
                .sd-validation-info--user(v-for="(item, index) in this.badge.helpers.validatorEmployees" :key="index")
                    v-avatar(:src="item.picture" :size="24")
                    span {{ item.firstName }} {{ item.lastName }}
</template>

<script>
export default {
    props: {
        badge: {
            type: Object,
            default: null
        }
    },
    methods: {
        filteredOnName () {
            return this.badge.helpers.validatorEmployees.map(el => `@${el.firstName}`)
        }
    },
    computed: {
        checkValidatorEmployees () {
            return this.badge.helpers.hasOwnProperty('validatorEmployees')
        },

        changeText () {
            const numberElements = this.badge.helpers?.validatorEmployees?.length || 0
            return numberElements === 1
                ? this.$t('infotext.badge_need_be_validated_by_one')
                : numberElements > 1
                    ? this.$t('infotext.badge_need_be_validated_by_few')
                    : this.$t('infotext.badge_is_not__validators')
        }
    }
}
</script>

<style lang="scss">
.sd-validation-info {
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 16px;
    margin-top: 16px;
    // border-radius: 0.5em;
    // background-color: #f1f0ef;
    // padding: 8px;
    &--text{
        margin: 0;
    }
    &--user{
        display: inline-flex;
        gap: 8px;
        &:not(:last-child){
            margin-right: 8px;
        }
    }
}
</style>
