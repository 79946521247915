<template lang="pug">
  svg(
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg")
    path(
      d="M2.22493 0.810985C2.03632 0.628827 1.78372 0.528033 1.52153 0.530311C1.25933 0.53259 1.00852 0.637759 0.823108 0.823167C0.6377 1.00858 0.532531 1.25939 0.530253 1.52158C0.527974 1.78378 0.628768 2.03638 0.810927 2.22499L6.58593 7.99999L0.809927 13.775C0.714416 13.8672 0.638234 13.9776 0.585825 14.0996C0.533416 14.2216 0.50583 14.3528 0.504676 14.4856C0.503523 14.6184 0.528824 14.75 0.579105 14.8729C0.629386 14.9958 0.703639 15.1075 0.797532 15.2014C0.891424 15.2953 1.00308 15.3695 1.12597 15.4198C1.24887 15.4701 1.38055 15.4954 1.51333 15.4942C1.64611 15.4931 1.77733 15.4655 1.89933 15.4131C2.02134 15.3607 2.13168 15.2845 2.22393 15.189L7.99993 9.41399L13.7749 15.189C13.9635 15.3711 14.2161 15.4719 14.4783 15.4697C14.7405 15.4674 14.9913 15.3622 15.1767 15.1768C15.3622 14.9914 15.4673 14.7406 15.4696 14.4784C15.4719 14.2162 15.3711 13.9636 15.1889 13.775L9.41393 7.99999L15.1889 2.22499C15.3711 2.03638 15.4719 1.78378 15.4696 1.52158C15.4673 1.25939 15.3622 1.00858 15.1767 0.823167C14.9913 0.637759 14.7405 0.53259 14.4783 0.530311C14.2161 0.528033 13.9635 0.628827 13.7749 0.810985L7.99993 6.58599L2.22493 0.809985V0.810985Z"
      :fill="color")
</template>

<script>
export default {
  name: 'IconClose',
  props: {
    color: {
      type: String,
      default: '#252F39'
    },
    height: {
      type: String,
      default: '16'
    },
    width: {
      type: String,
      default: '16'
    }
  }
}
</script>

<style>
svg{
  cursor: pointer;
}
</style>
