<template lang="pug">
.chevron-burger
  v-dropdown.chevron-burger__dropdown(
    autoClose,
    :width="131",
    @toggle="toggleBurger = $event"
  )
    v-btn(text, paddingless)
      icon-burger.chevron-burger__icon(:class="{ active: toggleBurger }")
    template(slot="content")
      v-card(elevation)
        ul
          li(
            v-for="item of menu",
            :class="{active:active && active.id ===item.id}"
            :key="`chevronBurger_${item.id}`",
            @click="changeItem(item)"
          )
            span {{ item.value }}
</template>

<script>
export default {
  name: 'ChevronBurger',
  components: {
    IconBurger: () => import('@/components/svg/icons/IconBurger.vue')
  },
  props: {
    menu: {
      type: Array,
      required: true,
      default: () => []
    },
    active: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    toggleBurger: false
  }),
  methods: {
    changeItem (item) {
      this.$emit('change', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.chevron-burger {
  position: relative;
  height: 48px;
  box-sizing: border-box;

  &__icon {
    color: $body-text-2;
    &:hover {
      color: $body-text;
    }

    &.active {
      color: $color-dark-green;
    }
  }

  &__dropdown {
    position: static;
    width: 16px;
    height: 16px;

    ::v-deep .v-dropdown__content {
      position: absolute;
      left: 100%;
      bottom: 0;
      margin-left: 8px;
    }
    .v-card {
      padding: 4px 0;
    }
    li {
      list-style: none;
      padding: 8px 16px;
      font-size: 16px;
      line-height: 1;
      font-weight: 400;
      color: $body-text;
      cursor: pointer;
      &:hover {
        background-color: $website-color;
      }
      &.active {
        color: $color-dark-green;
      }
    }
  }
}
</style>
