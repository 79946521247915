<template lang="pug">
div.share-container
  v-dialog(
    width="404"
    :value="true")
    v-card
      v-card-head
        .share-title {{ 'ui.labels.share_profile' | translate}}
        icon-close(@click.native="evClose()" color="#A0AAC3" width="14" height="14")
      v-card-content
        div.status-container
          div.select(@click="backToPreviousStep")
            div.circle.circle-active
              icon-check(v-if="isSelectedData" color="#29A687" width="13" height="10")
            span(:class="{ 'sp-active': isSelectedData }") {{ 'ui.labels.select_data' | translate}}
          v-line.progress-line(:color="lineColor")
          div.copy
            div.circle(:class="{ 'circle-active': isSelectedData }")
              icon-check(v-if="linkCopied" color="#29A687" width="13" height="10")
            span(:class="{ 'sp-active': linkCopied }") {{ 'ui.labels.copy_link' | translate}}
        div(v-if="!copyLinkScreen")
          div.select-data-text {{ 'ui.labels.select_fields_you_want_to_share' | translate}}
          v-checkbox(:label="`app.shared.${item}` | translate" v-for="item in defaultShareListIds" :key="item" @input="onChangeInput(item)" :value="selectedData.includes(item)")
        div.copy-link-screen-container(v-if="copyLinkScreen")
          share-profile
          div.footer
            v-input(:value="preparedLink" class="sp-input")
            v-btn(:success="true" @click="copyLink" class="sp-btn-success") {{ 'ui.buttons.copy' | translate}}
      v-card-actions
        div.btn-share-group(v-if="!copyLinkScreen")
          v-btn(:danger="true" :text="true" @click="evClose()") {{ 'ui.buttons.cancel' | translate}}
          v-btn(:success="true" @click="setCopyLinkScreen") {{ 'ui.buttons.share' | translate}}
</template>

<script>
import VLine from '@/components/svg/share-profile/Line.vue'
import ShareProfile from '@/components/svg/share-profile/ShareProfile.vue'
import IconClose from '@/components/svg/icons/IconClose.vue'
import IconCheck from '@/components/svg/icons/IconCheck.vue'
import { mapActions } from 'vuex'

export default {
  name: 'ProfileInfoShare',

  components: {
    VLine,
    IconClose,
    IconCheck,
    ShareProfile
  },

  data: () => ({
    configId: null,
    selectedData: ['isLastNameShorted'],
    copyLinkScreen: false,
    linkCopied: false,
    defaultShareListIds: [
      'isLastNameShorted',
      'isDepartmentVisible',
      'isDescriptionVisible',
      'isEmailVisible',
      'isLocationVisible',
      'isPhoneNumberVisible',
      'isPositionVisible',
      'isProfilePictureVisible'
    ]
  }),

  computed: {
    isSelectedData () {
      return this.selectedData.length && this.copyLinkScreen
    },

    lineColor () {
      return this.isSelectedData ? '#29A687' : '#D8DAE3'
    },

    preparedLink () {
      return `${document.location.origin}/employees/share/${this.configId}`
    },

    preparedPermission () {
      const permissions = {
        id: this.configId ? this.configId : this.$route.params.employee_id,
        data: {}
      }
      for (let item in this.defaultShareListIds) {
        permissions.data[this.defaultShareListIds[item]] = true
        if (!this.selectedData.includes(this.defaultShareListIds[item])) {
          permissions.data[this.defaultShareListIds[item]] = false
        }
      }
      return permissions
    }
  },

  methods: {
    ...mapActions('employee', [
      'createSharedProfile',
      'updateSharedProfile'
    ]),

    evClose () {
      this.$emit('closeShareSlide')
    },

    onChangeInput (id) {
      if (!this.selectedData.includes(id)) {
        this.selectedData.push(id)
      } else {
        this.selectedData = this.selectedData.filter(item => item !== id)
      }
    },

    backToPreviousStep () {
      this.copyLinkScreen = false
      this.linkCopied = false
    },

    async setCopyLinkScreen () {
      const config = {
        data: null
      }
      if (this.configId) {
        config.data = await this.updateSharedProfile(this.preparedPermission)
      } else {
        config.data = await this.createSharedProfile(this.preparedPermission)
      }
      this.configId = config.data.data.urlToken
      this.copyLinkScreen = true
    },

    copyLink () {
      this.linkCopied = true
      navigator.clipboard.writeText(this.preparedLink)
    }
  }
}

</script>

<style lang="scss">
.share-container{
  .v-card__content{
    padding: 0px 24px 24px 24px !important;
  }

  .share-title{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: $h2-title;
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg{
      margin-right: 6px;
      cursor: pointer;
    }
  }

  .v-checkbox{
    width: 50%;
    margin-bottom: 8px;
  }

  .status-container{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;

    .progress-line{
      margin-top: 10px;
      position: absolute;
      left: 18%;
    }

    .check-mark{
      width: 10px;
      height: 13px;
    }

    .copy, .select{
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $body-text-2;

      span{
        padding-top: 8px;
      }

      .circle{
        width: 24px;
        height: 24px;
        border: 1px solid $color-line-dotted;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .circle-active{
        background: $secondary-light-green;
        border: 0;
      }
    }

    .sp-active{
      color: $color-dark-green;
    }
  }
  .copy-link-screen-container{
    text-align: center;

    .footer{
      padding-top: 22px;
      display: flex;

      .v-input{
        background: $light-blue;
        &__field{
          color: $body-text;
        }
      }
    }
  }

  .select-data-text{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: $h2-title;
    padding-bottom: 24px;
  }

  .v-btn{
    height: 40px !important;
  }

  .btn-share-group{
    float: right;
    padding: 16px 0 0 0;

    .v-btn--danger{
      margin-right: 10px;
    }
  }

  .sp-btn-success{
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .sp-input{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

</style>
