<template lang="pug">
  tree-badge(
    :id="node.props.id"
    :pos="pos"
    :level="0"
    :color="color"
    :node="node"
    :size="size"
    :dragable="false"
    :is-selected="isSelected || isFiltered || isSelectedForMove"
    :is-hilighted="isHilighted"
    @click="onNodeSelect")
    //  TODO @right-click="onRightClick" do we need right click here?
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import TreeBadge from './TreeBadge'

export default {
  name: 'TreeHookedBadge',

  props: {
    depth: Number,
    node: Object,
    pos: Object,
    color: String,
    filteredBadges: Array
  },

  components: {
    TreeBadge
  },

  methods: {
    ...mapActions('nodeTmp', [
      'setCursorNode'
    ]),

    onNodeSelect (payload) {
      this.$emit('select')
      this.setCursorNode(payload)
    },

    onRightCLick (payload) {
      this.$emit('right-click', payload)
    }
  },

  computed: {
    ...mapGetters('treeView', [
      'hilightSubBranche'
    ]),

    ...mapGetters('nodeTmp', [
      'cursorNode',
      'selectedNodes'
    ]),

    ...mapGetters('skillFinder', [
      'selected'
    ]),

    size () {
      return 1 - (this.depth / 20)
    },

    isSelected () {
      if (!this.cursorNode) return false
      const editChainNodes = [this.cursorNode, ...this.cursorNode.parents]
      return editChainNodes.some(chainNode => chainNode.props.id === this.node.props.id)
    },

    isSelectedForMove () {
      return this.selectedNodes.some(node => node.props.id === this.node.props.id)
    },

    isFiltered () {
      if (this.filteredBadges) {
        return this.filteredBadges.some(item => item.id === this.node.props.id)
      }
      return false
    },

    isHilighted () {
      return this.hilightSubBranche &&
        this.cursorNode &&
        this.node.props.id === this.cursorNode.props.id
    }
  }
}
</script>

<style scoped>

</style>
