<template lang="pug">
  skill-detail-modal.skill-detail(
    v-if="showModal"
    :pos="pos"
    :zoom="zoom"
    :node="cursorNode"
    :show-steps="params.showSteps"
    :is-gradational="isGradational")

    gradation-tabs(
      v-if="isGradational"
      hide-actions
      style="position: absolute; top: -55px; left: 0; margin: 0"
      :badge-color="chainColor"
      :gradational-badges="badgesArray"
      :active-tab-index="activeTabIndex"
      @setActiveTabIndex="setActiveTabIndex"
      :hideActions="true")

    v-scrollable(
        :min-height="100"
        :bottom-offset="20")
      skill-detail-steps(
        ref="steps"
        v-if="params.showSteps"
        :badge="activeBadge"
        :is-gradational="isGradational"
        @complete="onStepsComplete"
        @close="closeSteps"
        @update="onUpdate"
        @renew="onBadgeRenew")

      .tabs-content
        skill-detail-status-icon(
          v-if="!isCategory"
          :badge="activeBadge")

        //- HEADER
        .skill-detail-header
          //- .category-icon(v-if="!!isCategory")
          //-   svg(height="54" width="54")
          //-     circle(
          //-       :cx="27"
          //-       :cy="27"
          //-       :r="23"
          //-       fill="#fff"
          //-       stroke-width="6"
          //-       :stroke="chainColor")
          //- .skill-detail-header__badge-icon(v-else)
          //-   badge(
          //-     :width="64"
          //-     :height="109"
          //-     :color="chainColor"
          //-     :level="activeBadge.starsCount")

          .skill-detail-header__title
            h4.title(:class="{ 'short-header': isEmployeePage }")
              | {{ activeBadge.displayName }}
            .skill-detail-header__activatedData(v-if='activeBadge.activatedAt && activeBadge.isActivated')
                v-icon checkInCircle
                span  {{ 'ui.labels.activated_on' | translate }}  {{dateInWords(activeBadge.activatedAt)}}
            span(v-if="activeBadge.expiresAt")
              | {{ 'ui.labels._valid_till' | translate }} {{ activeBadge.expiresAt.substring(0, 10) }}
            .flex.skill-detail-action-buttons(v-if="showValidationBtns")
              div
                v-btn.mr-3.skill-detail-badge-approve-btn(
                  small
                  @click="onApproveValidation")
                  | {{ 'ui.buttons.approve' | translate }}
              v-btn.skill-detail-badge-decline-btn(
                small
                danger
                @click="onToggleDeclineModal(true)")
                | {{ 'ui.buttons.decline' | translate }}

        template(v-if="showValidationBtns")
          .skill-detail__alert.mt-2 {{ 'infotext.user_reuest_for_approve' | translate }}
          template(v-if="activeBadge.validation.comment")
            .subtitle {{ 'ui.labels.comment' | translate }}
            div {{ activeBadge.validation.comment }}

        //- ACTIVATION ACTION
        .skill-detail-info
          .skill-detail__alert(v-if="showExpireSoonAlert")
            i.icon.icon--warning-orange
            span {{ 'file-pond.will_expire_soon' | translate }}

          .skill-detail__alert(v-if="validationWasBeenDeclined")
            i.icon.icon--warning-red
            span(style="color: #f44336") {{ $t('infotext.message_badge_declined', { employeeName: validatedBy }) }}

          .skill-btn-wrap(v-if="!showValidationBtns && !searchHeader && showEditBtns")
            .skill-btn(v-if="!isCategory")
              template(v-if="isPending")
                v-dropdown-btn(
                  warning
                  lock-action
                  v-tooltip="{ placement: 'top', content: $t('infotext.this_badge_is_awaiting_confirmation_by_msg') }")
                  span  {{ 'ui.labels.confirmation_pending' | translate }}
                  template(slot="dropdown")
                    v-card(
                      elevation
                      overflow-hidden)
                      v-list-item(
                        @click.native="onCancelValidation")
                        span {{ 'ui.labels.cancel_request' | translate }}
              v-btn(
                v-else-if="validationWasBeenDeclined"
                @click="onToggleBadge")
                | {{ 'ui.buttons.request_again' | translate }}
              v-btn(
                v-else-if="activeBadge.isActivated && !activeBadge.isExpiryRequired"
                :disabled="permissionClickDeactivete(activeTabIndex)"
                danger
                @click="onToggleBadge")
                | {{ 'ui.buttons.deactivate' | translate }}
              v-dropdown-btn(
                v-else-if="activeBadge.isActivated"
                @click="onRenew")
                span {{ 'ui.labels.renew' | translate }}
                template(slot="dropdown")
                  v-card(
                    elevation
                    overflow-hidden)
                    v-list-item(
                      @click.native="onToggleBadge")
                      span {{ 'ui.buttons.deactivate' | translate }}

              v-dropdown-btn(
                ref="dropdownButton"
                v-else-if="showDropdownButtons"
                :disabled="!isButtonDisabled(activeTabIndex)"
                v-tooltip="{placement: 'top', content: $t('infotext.activate_this_badge_this_will_be_visible_to_your_colleagues')}"
                @click="onToggleBadge")
                span {{ 'ui.buttons.activate' | translate }}
                template(slot="dropdown")
                  v-card(
                    elevation
                    overflow-hidden)
                    v-list-item(@click.native="onActivateWithComment")
                      | {{ 'ui.buttons.activate_with_comment' | translate }}

                    //- v-list-item {{ 'ui.buttons.force_activate' | translate }} //- for foreign skills

              v-btn(
                v-else
                :disabled="!isButtonDisabled(activeTabIndex)"
                v-tooltip="$t('infotext.activate_this_badge_this_will_be_visible_to_your_colleagues')"
                @click="onToggleBadge") {{ 'ui.buttons.activate' | translate }}
              span.invalid(style="font-size: 1rem") {{ 'badgeId' | errorText }}
              span.invalid(style="font-size: 1rem") {{ 'expiresAt' | errorText }}

            skill-detail-validators-list(v-if="activeBadge.validationTypeId" :badge="activeBadge")

            skill-detail-comment(v-if="hasComment" :badge="activeBadge" :user-data="foreignEmloyeeDetail")

            skill-detail-actions(
              :class="{'skill-detail-section': !isCategory}"
              ref="detailActions"
              :badge="activeBadge"
              :is-category="isCategory"
              :is-add-to-goal-disabled="isAddToGoalDisabled")

          span.skill-status.active(
            v-if="activeBadge.isActivated && !showEditBtns")
            | {{ 'ui.modals.activated' | translate }}

          .search-btn-wrap(v-if="searchHeader && !isCategory")
            v-btn(
              v-if="!isBadgesFiltered(activeBadge)"
              block
              @click="addToFilter(activeBadge)")
              | {{ 'ui.buttons.add_to_search' | translate }}
            v-btn(
              v-if="isBadgesFiltered(activeBadge)"
              block
              danger
              @click="removeFromFilter(activeBadge)")
              | {{ 'ui.buttons.remove_from_search' | translate }}

        //- REQUIRED BADGES
        skill-detail-required-badges(
          :color="chainColor"
          :badges="[...requiredBadges]")

        //- UPLOAD FILE
        .skill-detail-section(v-if="!isCategory && !isSharePage && (activeBadge.files.length > 0 || isEditable) && !isSkillFinder")
          h5.skill-detail-title {{ 'ui.labels.files_and_certificates' | translate }}:
          skill-detail-file-upload(
            :badge="activeBadge.files"
            :badge-id="activeBadge.id"
            :is-editable="isEditable"
            :employee-id="employeeId")

        //- DESCRIPTION
        skill-detail-description(
          :badge="activeBadge")

        //- DON'T REMOVE
        span.suggest-badge(
          v-if="isCategory && isOnlyBadgeChildren && isHomePage"
          @click="onOpenSuggesModal") {{ 'pages.my.suggest_a_badge' | translate }}

        skill-detail-training-material(
          v-if="!isSharePage"
          :title="'pages.my.training_material' | translate"
          :badge="activeBadge.trainingFiles")

        badge-suggest(
          v-if="openSuggestModal"
          :show="openSuggestModal"
          :categoryForSuggestBadge="suggestedBadge"
          @close-modal="closeSuggestModal")
        decline-suggest-modal(
          v-if="showDeclineModal"
          :show="showDeclineModal"
          :show-reason="false"
          :node="cursorNode"
          @decline="onDeclineValidation($event)"
          @on-close="onToggleDeclineModal(false)")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { NODE_TYPE, COLOR_PRIMARY, ENV, VALIDATION_STATUS } from '@/util/constants.js'

import Badge from '@/components/svg/Badge'
import GradationTabs from '@/components/ui/GradationTabs'

// REFACTOR
import SkillDetailStatusIcon from './skill-detail/SkillDetailSatusIcons'
import SkillDetailModal from './skill-detail/SkillDetailModal'
import SkillDetailActions from './skill-detail/SkillDetailActions'
import SkillDetailComment from './skill-detail/SkillDetailComment'
import SkillDetailDescription from './skill-detail/SkillDetailDescription'
import SkillDetailRequiredBadges from './skill-detail/SkillDetailRequiredBadges'
import SkillDetailSteps from './skill-detail/steps/SkillDetailSteps'
import SkillDetailTrainingMaterial from './skill-detail/SkillDetailTrainingMaterial'
import SkillDetailFileUpload from './skill-detail/SkillDetailFileUpload.vue'
import BadgeSuggest from '@/components/modals/my/BadgeSuggest'
import DeclineSuggestModal from '@/components/modals/builder/DeclineSuggestModal.vue'
import SkillDetailValidatorsList from './skill-detail/SkillDetailValidatorsList.vue'

export default {
  name: 'skill-detailription',

  components: {
    Badge,
    GradationTabs,
    // NEW COMPONENTS
    SkillDetailStatusIcon,
    SkillDetailModal,
    SkillDetailActions,
    SkillDetailComment,
    SkillDetailDescription,
    SkillDetailRequiredBadges,
    SkillDetailSteps,
    SkillDetailTrainingMaterial,
    SkillDetailFileUpload,
    BadgeSuggest,
    DeclineSuggestModal,
    SkillDetailValidatorsList
  },

  props: {
    pos: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    zoom: Number,
    searchHeader: Boolean,
    isAddToGoalDisabled: Boolean,
    isEditable: Boolean,
    employeeId: Number
  },

  data: () => ({
    params: {
      showSteps: false
    },
    currentTab: 0,
    activeTabIndex: 0,
    badgesArray: [],
    requiredBadges: [],
    openSuggestModal: false,
    showDeclineModal: false
  }),

  mounted () {
    this.init()
    this.clearErrors()
  },

  destroyed () {
    if (!this.$store.getters['trees/useNewTree']) {
      if (this.$route.params.badge_id) {
        const index = this.$route.path.indexOf('/badge')
        if (index !== -1) {
          const route = this.$route.path.substring(0, index)
          this.$router.push(route)
        }
      }
    }
    if (this.cursorNode && this.cursorNode.localProps && this.cursorNode.localProps.validationOpened) {
      this.closeSteps()
    }
  },

  methods: {
    ...mapActions('app', [
      'clearErrors'
    ]),

    ...mapActions('tree', [
      'toggleBadge'
    ]),

    ...mapActions('trees', [
      'updateNode',
      'renewBadge'
    ]),

    ...mapActions('badges', [
      'requestBadgeWithValidations',
      'cancelBadgeValidation',
      'approveBadgeValidation',
      'declineBadgeValidation'
    ]),

    ...mapActions('skillFinder', [
      'deleteBadge',
      'addBadge'
    ]),

    ...mapActions('suggestions', [
      'changeRecommendations',
      'updateMyProfileTabs'
    ]),

    init () {
      this.badgesArray = []
      if (this.detail.typeId !== NODE_TYPE.HOOKED_BADGE) {
        this.badgesArray = [this.detail, ...this.detail.gradationalSubBadges]
        const id = this.$route.params.badge_id
        if (id === undefined) {
          let index = 0
          for (const item of this.badgesArray) {
            if (item.isActivated) {
              index++
            } else {
              break
            }
          }
          this.activeTabIndex = index > 0 ? index - 1 : 0
        } else {
           const index = this.badgesArray
            .indexOf(this.badgesArray
              .find(item => item.id === parseInt(id)))
          this.activeTabIndex = index >= 0 ? index : 0
        }
      } else {
        this.badgesArray = [this.detail]
      }
      this.requiredBadges = this.parseRequiredBadge()
      this.currentTab = this.activeTab
    },

    parseRequiredBadge () {
      const requiredList = []
      for (const i in this.badgesArray) {
        if (+i > +this.activeTabIndex) break
        requiredList.push(...this.badgesArray[i].requiredBadges)
      }

      return [...new Map(requiredList.map(item => [item.id, item])).values()]
    },

    setActiveTabIndex (index) {
      this.activeTabIndex = index
    },

    permissionClickDeactivete (index) {
      const count = this.badgesArray.length
      const isNexActive = index < count - 1
        ? this.badgesArray[index + 1].isActivated
        : false
      return isNexActive
    },

    isButtonDisabled (index) { // correct isButtonNotDissabled =)
      const count = this.badgesArray.length
      const isNexActive = index < count - 1
        ? this.badgesArray[index + 1].isActivated
        : false
      return this.everyRequiredBadge() && !isNexActive
    },

    everyRequiredBadge () {
      return ([...this.requiredBadges] || []).every(item => {
        return item.isActivated
      })
    },

    isBadgesFiltered (badge) {
      return Boolean(this.selected.badges
        .find(item => item.id === badge.id))
    },

    removeFromFilter (item) {
      this.deleteBadge(item)
    },

    addToFilter (item) {
      this.addBadge(item)
    },

    openSteps () {
      this.params.showSteps = true
      this.cursorNode.localProps.validationOpened = true
    },

    onBadgeRenew ({ params, payload }) {
      if (this.$route.params.employee_id) {
        params.forEmployeeId = this.$route.params.employee_id
      }
      this.renewBadge({ node: this.cursorNode, queryParams: params, props: payload })
      this.closeSteps()
    },

    closeSteps () {
      this.params.showSteps = false
      this.cursorNode.localProps.validationOpened = false
    },

    onStepsComplete ({ params, payload }) {
      const badge = this.badgesArray.length
        ? this.badgesArray[this.activeTabIndex]
        : this.detail
      if (this.$route.params.employee_id) {
        params.forEmployeeId = this.$route.params.employee_id
      }
      if (this.activeBadge.validationTypeId && !this.validationWasBeenApproved && !this.isEmployeePage) {
        badge.validation = {
          statusId: VALIDATION_STATUS.PENDING
        }
        this.requestBadgeWithValidations({ badge: this.activeBadge, params, payload })
      } else {
        this.toggleBadge({ badge: this.activeBadge, params, payload })
      }
      this.closeSteps()
    },

    onRenew () {
      this.params.showSteps = true
    },

    onUpdate (data) {
      this.updateNode({ node: this.cursorNode, props: data })
    },

    onActivateWithComment () {
      this.activeBadge.isCommenActivation = true
      this.openSteps()
    },

    async onCancelValidation () {
      if (this.activeBadge && this.userInfo) {
        const payload = {
          badgeId: this.activeBadge.id,
          employeeId: this.userInfo.id
        }
        const { error } = await this.cancelBadgeValidation(payload)
        await this.updateMyProfileTabs()
        if (!error) {
          this.activeBadge.validation = null
          this.activeBadge.isActivated = false
        }
      }
    },

    onApproveValidation () {
      const params = {
        forEmployeeId: this.$route.params.employee_id
      }
      this.approveBadgeValidation({
        badgeId: this.activeBadge.id,
        params
      })
      this.activeBadge.validation.statusId = VALIDATION_STATUS.APPROVED
      this.activeBadge.isActivated = true
      this.init()
    },

    async onDeclineValidation (payload) {
      const params = {
        forEmployeeId: this.$route.params.employee_id,
        ...payload
      }
      let result = await this.declineBadgeValidation({
        badgeId: this.activeBadge.id,
        params,
        payload
      })
      if (!result.error) {
        this.init()
        this.showDeclineModal = false
      }
    },

   async onToggleBadge () {
      this.clearErrors()
      let params = {}

      if (this.$route.params.employee_id) {
        params.forEmployeeId = this.$route.params.employee_id
      }

      if (!this.activeBadge.isActivated) {
        this.changeRecommendations(this.activeBadge)
        if (this.activeBadge.isFileRequired ||
          this.activeBadge.isCommentForActivatingRequired ||
          this.activeBadge.isExpiryRequired) {
          this.openSteps()
        } else if (this.activeBadge.validationTypeId && !this.isEmployeePage) {
         await this.requestBadgeWithValidations({
            badge: this.activeBadge,
            node: this.cursorNode
          })
        } else {
         await this.toggleBadge({
            params,
            badge: this.activeBadge,
            node: this.cursorNode
          })
        }
      } else {
        await this.toggleBadge({
          params,
          badge: this.activeBadge,
          node: this.cursorNode
        })
      }
      await this.updateMyProfileTabs()
    },

    dateInWords (data) {
      const newData = new Date(data)
      const formatter = new Intl.DateTimeFormat('en', { month: 'long' })
      return `${newData.getDate()} ${formatter.format(newData)}`
    },

    onOpenSuggesModal () {
      this.suggestedBadge = Object.assign({}, this.activeBadge)
      this.openSuggestModal = true
    },

    closeSuggestModal () {
      this.suggestedBadge = {}
      this.openSuggestModal = false
    },

    onToggleDeclineModal (bool) {
      this.showDeclineModal = bool
    }
  },

  computed: {
    ...mapGetters('app', [
      'userInfo'
    ]),

    ...mapGetters('nodeTmp', [
      'cursorNode'
    ]),

    ...mapGetters('permissions', [
      'currentPermissions'
    ]),

    ...mapGetters('skillFinder', [
      'selected'
    ]),

    ...mapGetters('preferences', [
      'isSharePage'
    ]),

    ...mapGetters('employee', ['foreignEmloyeeDetail']),

    isCategory () {
      return this.detail.typeId === NODE_TYPE.CATEGORY
    },

    isBadge () {
      return this.detail.typeId === NODE_TYPE.BADGE ||
        this.detail.typeId === NODE_TYPE.HOOKED_BADGE
    },

    isGradational () {
      return this.detail.typeId === NODE_TYPE.GRADATIONAL_BADGE
    },

    showModal () {
      return this.activeBadge &&
        !this.activeBadge.isSuggested
    },

    isHomePage () {
      return this.$route.path.includes('my')
    },

    isEmployeePage () {
      return this.$route.path.includes('employee')
    },

    isSkillFinder () {
      return this.$route.path === '/skill-finder'
    },

    canEditForeignSkills () {
      return this.currentPermissions.SKILLTREE_EDIT_FOREIGN_PROFILES
    },

    detail () {
      return this.cursorNode.props
    },

    activeBadge () {
      return this.badgesArray.length
        ? this.badgesArray[this.activeTabIndex]
        : this.detail
    },

    starsCount () {
      return this.activeTabIndex + 1
    },

    chainColor () {
      const chainNodes = [this.cursorNode, ...this.cursorNode.parents]
      const colorNode = chainNodes.find(chainNode => chainNode.props.color)
      return colorNode ? colorNode.props.color : COLOR_PRIMARY
    },

    showDropdownButtons () {
      return ENV.SHOW_ACTIVATE_OR_DECLINE_WITH_COMMENT
    },

    showValidationBtns () {
      return this.isEmployeePage &&
        this.activeBadge.validation !== null &&
        this.activeBadge.validation.statusId === VALIDATION_STATUS.PENDING
    },

    showEditBtns () {
      return this.isEditable
    },

    isPending () {
      return this.activeBadge.validation &&
        this.activeBadge.validation.statusId === VALIDATION_STATUS.PENDING
    },

    validationWasBeenApproved () {
      return this.activeBadge.validation &&
        this.activeBadge.validation.statusId === VALIDATION_STATUS.APPROVED
    },

    validationWasBeenDeclined () {
      return this.activeBadge.validation &&
        this.activeBadge.validation.statusId === VALIDATION_STATUS.DECLINED && !this.activeBadge.isActivated
    },

    showExpireSoonAlert () {
      if (this.activeBadge.isActivated &&
        this.activeBadge.isExpiryRequired &&
        this.activeBadge.expiresAt &&
        this.activeBadge.activatedAt) {
        const start = new Date(String(this.activeBadge.activatedAt).replace(' ', 'T') + '.000Z')
        const end = new Date(this.activeBadge.expiresAt.replace(' ', 'T') + '.000Z')
        const now = Date.now()
        const validTime = end - start
        const activeTime = now - start
        const percents = Math.floor((activeTime / validTime) * 10000) / 100
        return percents >= 80
      }
      return false
    },

    isOnlyBadgeChildren () {
      const childrenbadgeId = this.cursorNode.children.length
        ? this.cursorNode.children[0].props.typeId
        : NODE_TYPE.CATEGORY
      return childrenbadgeId !== NODE_TYPE.CATEGORY
    },

    validatedBy () {
      return this.activeBadge.validation && this.activeBadge.validation.validatedBy &&
        (this.activeBadge.validation.validatedBy.firstName + ' ' + this.activeBadge.validation.validatedBy.lastName)
    },

    hasComment () {
      return Boolean(this.activeBadge.activationComment) || Boolean(this.activeBadge.validation?.comment)
    }
  },

  watch: {
    activeTabIndex (index) {
      this.closeSteps()
      this.clearErrors()
      this.requiredBadges = this.parseRequiredBadge()
    },

    detail () {
      this.init()
    }
  }
}
</script>

<style lang="scss">
  .skill-detail-action-buttons {
    margin: 10px 0;
  }
  .skill-detail-badge-approve-btn {
    padding: 0 24px !important;
  }
  .skill-detail-badge-approve-btn, .skill-detail-badge-decline-btn {
    height: 40px !important;
  }
  .skill-detail {
    border-radius: 5px;
    box-shadow: 2px 2px 20px 0 rgba(37, 47, 57, 0.15);
    background-color: $color-white;

    &__alert {
      position: relative;
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      color: #FEB60E;
      font-size: 15px;

      i {
        position: absolute;
        height: 32px;
        width: 32px;
        left: 0;
      }

      span {
        padding-left: 44px;
        padding-right: 10px;
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    .skill-detail-header {
      display: flex;
      align-items: center;

      &__badge-icon {
        margin-left: -10px;
        margin-right: 8px;
      }

      &__title {
        display: flex;
        flex-direction: column;
        width: calc(100% - 70px);

        h4 {
          overflow: hidden;
          text-overflow: ellipsis;

        }

        > span {
          padding-top: 5px;
        }
      }

      &__activatedData{
        margin-top: 8px;
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        color: $color-dark-green;
      }
    }

    .colleague-suggest {
      padding-top: 8px;
    }

    .suggest-badge {
      display: inline-flex;
      text-decoration: underline;
      font-family: inherit;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        color:$color-primary;
      }
    }

    .tabs-content {
      position: relative;
      padding: 24px;
    }

    .skill-detail-info {
      min-width: 190px;
      margin-top: 16px;

      .skill-name {
        margin: 10px 0;
        font-size: 18px;
        font-weight: 600;
        color: $h2-title;

        &.short-header {
          margin-bottom: 0;
        }
      }

      .skill-status {
        font-size: 15px;
        font-weight: 700;
      }

      .add-goal {
        display: flex;
        align-items: center;
        padding: 0;
        background: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        font-family: inherit;
        font-size: 14px;
        margin: 10px 0 5px;

        svg {
          margin-right: 5px;
        }
      }

      .skill-btn {
        .v-btn {
          padding: 16px 24px !important;
        }
      }
    }

    .required-badges-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      .required-badges-text {
        span {
          display: block;

          &.required-badges-name {
            font-weight: 700;
          }

          &.required-badges-status {
            font-size: 14px;

            &.active {
              color: $color-primary;
            }

            &.disable {
              color: #d4182f;
            }
          }
        }
      }

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }

    .skill-detail-section {
      margin-top: 16px;
      padding-top: 16px;
      // padding-bottom: 16px;
      border-top: 1px solid $color-line-dotted;
      .v-show-hide{
        .btn-show-more{
          padding-left: 0;
        }
      }
    }

    .skill-detail-title {
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      color: $h2-title;
      margin-bottom: 16px;
    }

    .top-employees {
      list-style-position: inside;
      list-style: none;
      counter-reset: custom-counter;
      padding: 0;

      li {
        margin-bottom: 5px;
        counter-increment: custom-counter;

        &:before {
          content: counter(custom-counter) ". ";
          font-weight: 900;
        }
      }
    }

    .show-more {
      display: block;
      margin: 0 auto;
      background-color: transparent;
      color: #17b690;
      border: none;
      cursor: pointer;
      outline: none;
      font-size: 16px;

      &:disabled {
        color: lightgrey;
      }
    }
  }

  .category-icon {
    margin-right: 15px;
  }
</style>
