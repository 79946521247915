<template lang="pug">
  .v-switch(
    :class="classes")
    label.v-switch__label(
      v-if="label")
      | {{ label }}
      slot(name="label")
    v-info-icon(
      v-if="infoIconText"
      :text="infoIconText")
    div.v-switch__toggler(
      :class="togglerClasses")
      label
        input(
          type="checkbox"
          :disabled="disabled"
          :checked="checked"
          @input="toggle")
        span
</template>

<script>
export default {
  name: 'VSwitch',

  props: {
    value: Boolean,
    outline: Boolean,
    icon: Boolean,
    block: Boolean,
    small: Boolean,
    noMargin: Boolean,
    label: String,
    disabled: Boolean,
    loading: Boolean,
    labelPosition: {
      type: String,
      default: 'right'
    },
    infoIconText: String,
    padding: Boolean
  },

  mounted () {
    this.checked = this.value
  },

  updated () {
    if (this.value !== this.checked) {
      this.checked = this.value
    }
  },

  data: () => ({
    checked: false
  }),

  methods: {
    toggle () {
      this.checked = !this.checked
      this.$emit('input', this.checked)
    }
  },

  computed: {
    classes () {
      return [
        this.labelPositionClass,
        this.block ? 'v-switch--block' : '',
        this.noMargin || this.padding ? 'v-switch--no-margin' : '',
        this.padding ? 'v-switch--padding' : ''
      ]
    },

    labelPositionClass () {
      return this.label &&
        `v-switch--label-${this.labelPosition}`
    },

    togglerClasses () {
      return {
        'v-switch__toggler--outline': this.outline,
        'v-switch__toggler--icon': this.icon,
        'v-switch__toggler--small': this.small,
        'v-switch__toggler--disabled': this.disabled,
        'v-switch__toggler--loading': this.loading
      }
    }
  },

  watch: {
    value (value) {
      this.checked = value
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';

  @import './mixins';

  $v-switch-margin: 0px;
  $v-switch-padding-bottom: 1rem;
  $v-switch-label-offset: .25rem;
  $v-switch-base-color: #e8ebf1;

  label {
    margin: 0;
    font-weight: 300;
  }

  .v-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(.v-switch--no-margin) {
      margin-bottom: 1rem;
    }

    &--padding {
      padding-bottom: $v-switch-padding-bottom;
    }

    &--block {
      width: 100%;
    }

    &--label-right {
      flex-direction: row-reverse;

      .v-switch__label {
        margin-left: $v-switch-label-offset;
      }
    }

    &--label-left {
      flex-direction: row;

      .v-switch__label {
        margin-right: $v-switch-label-offset;
      }
    }

    &--label-top {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .v-switch__label {
        margin-bottom: $v-switch-label-offset;
      }
    }

    &--label-bottom {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;

      .v-switch__label {
        margin-top: $v-switch-label-offset;
      }
    }

  }

  .v-switch__toggler {
    display: flex;
    align-items: center;

    &--disabled {
      opacity: .6;
    }

    input:empty {
      height: 0;
      width: 0;
      overflow: hidden;
      position: absolute;
      opacity: 0 !important;
      visibility: hidden;
    }

    input:empty ~ span {
      display: inline-block;
      position: relative;
      float: left;
      width: 1px;
      text-indent: 0;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    input:empty ~ span:before,
    input:empty ~ span:after {
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      content: '';
      -webkit-transition: all 100ms ease-in;
      transition: all 100ms ease-in;
    }

    input:empty ~ span:before {
      background-color: $v-switch-base-color;
    }

    // Tick bg
    input:empty ~ span:after {
      color: lighten($v-switch-base-color, 5%);
      background-color: $color-white;
    }

    input[disabled] {
      cursor: not-allowed;
      ~ span:after,
      ~ span:before {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }

    &:not(.v-switch__toggler--small) {
      $v-switch-width: 54px;
      $v-switch-height: 30px;
      $v-switch-line-height: 26px;
      $v-switch-space: 4px;
      $v-switch-checked-tick-pos: 26px;
      $v-switch-icon-font-size: 0.9em;

      height: $v-switch-height + $v-switch-space * 2;

      @include v-switch-size(
        $v-switch-margin,
        $v-switch-width,
        $v-switch-height,
        $v-switch-line-height,
        $v-switch-space,
        $v-switch-checked-tick-pos,
        $v-switch-icon-font-size
      );
    }

    // Small size
    &--small {
      $v-switch-width: 38px;
      $v-switch-height: 24px;
      $v-switch-line-height: 20px;
      $v-switch-space: 2px;
      $v-switch-checked-tick-pos: 16px;
      $v-switch-icon-font-size: 0.8em;

      height: $v-switch-height + $v-switch-space * 2;

      @include v-switch-size(
        $v-switch-margin,
        $v-switch-width,
        $v-switch-height,
        $v-switch-line-height,
        $v-switch-space,
        $v-switch-checked-tick-pos,
        $v-switch-icon-font-size
      );
    }

    &--outline {
      input:empty ~ span:before {
        border: 2px solid darken($v-switch-base-color, 3%);
        background-color: $v-switch-base-color;
      }

      input:empty ~ span:after {
        color: $color-white;
        background-color: $color-white;
      }

      input:checked {
        // Panel bg
        ~ span:before {
          background-color: $color-white;
        }

        // Tick bg
        ~ span:after {
          background-color: $color-primary;
          opacity: 1;
        }
      }
    }

    &--icon {
      input:empty ~ span:after {
        content: "\f342";
        font-family: "LineAwesome";
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
      }

      input:checked ~ span:after {
        content: "\f17b";
      }
    }

    &:not(.v-switch__toggler--outline) {
      input:empty ~ span:after {
        color: $v-switch-base-color;
        background-color: $color-white;
      }

      input:checked {
        // Panel bg
        ~ span:before {
          background-color: $color-primary;
        }

        // Tick bg
        ~ span:after {
          color: $color-primary;
          opacity: 1;
        }
      }
    }

    &--loading {
      span:before {
        @include skeleton;
      }

      span:after {
        display: none !important;
      }
    }
  }

</style>
