<template lang="pug">
  v-dialog(
    width="620"
    :value="forbiddenModal.show"
    @input="closeForbiddenModal")
    v-card
      v-card-content
        .server-error-modal__inner
          .server-error-modal__icon(
            v-if="!unlink.showSelect")
            i.icon.icon--danger
          .server-error-modal__content
            template(v-if="!unlink.showSelect")
              h2.title {{ forbiddenModal.title }}
              p {{ forbiddenModal.message }}
              p.link-text(
                v-if="forbiddenModal.unlinkTitle !== null"
                @click="unlink.showSelect = true")
                | {{ forbiddenModal.unlinkTitle | translate }}
            template(v-else)
              h2.title {{ forbiddenModal.unlinkTitle | translate }}
              p {{ forbiddenModal.unlinkSubtitle | translate }}
              v-select(
                single
                :options="forbiddenModal.unlinkOptions"
                v-model="unlink.selectedItem")

      v-card-actions
        v-btn(
          text
          @click="onClose") {{ 'ui.buttons.cancel' | translate }}
        v-btn(
          v-if="forbiddenModal.unlinkTitle !== null"
          danger
          :loading="loading"
          :disabled="unlink.selectedItem === null"
          @click="onApply") {{ 'ui.buttons.move_and_delete' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ServerErrorModal',

  data: () => ({
    unlink: {
      showSelect: false,
      selectedItem: null
    },
    disabled: true,
    loading: false
  }),

  methods: {
    ...mapActions('modals', [
      'closeForbiddenModal'
    ]),

    onClose () {
      this.closeForbiddenModal()
    },

    async onApply () {
      if (this.forbiddenModal.deleteCallback !== null) {
        this.loading = true
        const moveToId = this.unlink.selectedItem.id
        await this.forbiddenModal.deleteCallback(moveToId)
        this.loading = false
        this.onClose()
      }
    }
  },

  computed: {
    ...mapGetters('modals', [
      'forbiddenModal'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .server-error-modal {
    &__inner {
      display: flex;
      align-items: center;
    }

    &__icon {
      padding-right: 20px;

      i {
        height: 120px;
        width: 120px;
      }
    }

    &__content {
      width: 100%;
      padding-top: 10px;

      h2 {
        margin-bottom: 15px !important;
      }
    }
  }
</style>
