<template lang="pug">
  .sd-steps(v-if="stepsCount > 0")
    .sd-steps__overlay

      v-card.sd-steps__step(elevation)
        span.sd-steps__status(
          v-if="stepsCount > 1")
          | {{ completedSteps + 1 }} / {{ stepsCount }}

        //- template(
          v-for="(step, index) in stepsFlatArray")

        keep-alive
          skill-detail-step-comment(
            v-if="steps.comment.show && !steps.comment.complete"
            v-model="steps.comment.value"
            :is-first="isFirst(steps.comment)"
            :is-last="isLast(steps.comment)"
            @next="onNext(steps.comment)"
            @close="onClose")

          skill-detail-step-file-upload(
            v-else-if="steps.file.show && !steps.file.complete"
            :badge="badge"
            :is-first="isFirst(steps.file)"
            :is-last="isLast(steps.file)"
            @next="onNext(steps.file)"
            @prev="onPrev(steps.comment)"
            @close="onClose")

          skill-detail-step-expiration-date(
            v-else-if="steps.expiry.show && !steps.expiry.complete"
            v-model="steps.expiry.value"
            :badge="badge"
            :is-first="isFirst(steps.expiry)"
            :is-last="isLast(steps.expiry)"
            @next="onNext(steps.expiry)"
            @prev="onPrev(steps.file)"
            @close="onClose")

        //- .sd-steps__hr-actions
          v-card(elevation)
            v-list
              v-list-item Skip this step
              v-list-item Skip all steps
              v-list-item Cancel

</template>

<script>
import { mapActions } from 'vuex'

import SkillDetailStepComment from './SkillDetailStepComment'
import SkillDetailStepFileUpload from './SkillDetailStepFileUpload'
import SkillDetailStepExpirationDate from './SkillDetailStepExpirationDate'

const reducer = (field) => (accumulator, currentValue) => accumulator + (currentValue[field] ? 1 : 0)

export default {
  name: 'SkillDetailSteps',

  components: {
    SkillDetailStepComment,
    SkillDetailStepFileUpload,
    SkillDetailStepExpirationDate
  },

  props: {
    badge: Object
  },

  data: (vm) => ({
    steps: {
      comment: {
        key: 'comment',
        show: vm.badge.isCommentForActivatingRequired || vm.badge.isCommenActivation,
        complete: false,
        value: null
      },
      file: {
        key: 'file',
        show: vm.badge.isFileRequired,
        complete: false
      },
      expiry: {
        key: 'expiry',
        show: vm.badge.isExpiryRequired,
        complete: false,
        value: null
      }
    }
  }),

  computed: {
    stepsFlatArray () {
      return Object.values(this.steps)
        .filter(item => item.show)
    },

    completedSteps () {
      return this.stepsFlatArray
        .reduce(reducer('complete'), 0)
    },

    stepsCount () {
      return this.stepsFlatArray
        .reduce(reducer('show'), 0)
    }
  },

  methods: {
    ...mapActions('app', [
      'clearErrors'
    ]),

    onNext (el) {
      el.complete = true
      if (this.completedSteps === this.stepsCount) {
        if (!this.badge.isActivated) {
          const payload = this.getPayload()
          this.$emit('complete', payload)
        } else {
          const payload = this.getPayload()
          this.$emit('renew', payload)
        }
      }
    },

    onPrev (el) {
      el.complete = false
    },

    onClose () {
      this.$emit('close')
    },

    getPayload () {
      const params = {}
      const payload = {}
      if (this.steps.expiry.value) {
        const expiresAt = this.steps.expiry.value + ' 00:00:00'
        payload.expiresAt = expiresAt
        this.badge.expiresAt = expiresAt
      }
      if (this.steps.comment.value) {
        payload.comment = this.steps.comment.value
      }
      return { params, payload }
    },

    isFirst (step) {
      const item = this.stepsFlatArray
        .find(el => el.key === step.key)
      return this.stepsFlatArray
        .indexOf(item) === 0
    },

    isLast (step) {
      const item = this.stepsFlatArray
        .find(el => el.key === step.key)
      return this.stepsFlatArray
        .indexOf(item) === this.stepsFlatArray.length - 1
    }
  }
}
</script>

<style lang="scss">
  .sd-steps {

    &__overlay {
      position: absolute;
      padding: 10px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
      border-radius: 5px;
      background-color: rgba(255, 255, 255, .9);
    }

    &__step {
      width: 380px;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
    }

    &__status {
      position: absolute;
      left: 24px;
      bottom: 32px;
    }

    &__hr-actions {
      position: absolute;
      bottom: -165px;
      left: 0;
      right: 0;
    }
  }
</style>
