<template functional>
  <svg :class="[data.class, data.staticClass]" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-1.04904e-05 1.18475C-1.04904e-05 0.96292 0.0844822 0.740198 0.254842 0.570242C0.594266 0.230818 1.14355 0.230818 1.48297 0.570242L7.49995 6.58763L13.5173 0.570242C13.8568 0.230818 14.4065 0.230818 14.7455 0.570242C15.0849 0.909667 15.0849 1.45943 14.7455 1.79878L8.11446 8.42978C7.77503 8.7692 7.22527 8.7692 6.88633 8.42978L0.254923 1.79878C0.0849676 1.62939 -1.04904e-05 1.40707 -1.04904e-05 1.18475Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconChevron'
}
</script>

<style lang="scss">
</style>
