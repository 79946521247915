<template lang="pug">
  v-dialog(
    id="feedback-modal"
    width="520"
    top-offset="60"
    :value="true"
    @input="onClose")
    v-card
      v-card-head
        v-card-head-label
          h2.modal-title {{ 'pages.my.goal' | translate }}
          p.subtitle.subtitle-container(v-if="isEmployeeView") {{ 'ui.labels._of' | translate }}
            v-avatar.read-goal--avatar(
              :size="20"
              :src="foreignEmloyeeDetail.profilePictureUrl || '/img/no-profile-picture.svg'")
            span {{ foreignEmloyeeDetail.firstName + ' ' + foreignEmloyeeDetail.lastName }}
        v-card-head-toolbar
          v-btn(
            icon
            gray
            @click="onClose") x
      v-scrollable(
        id="scroll-panel"
        :bottom-offset="100")
        v-card-content
          GoalsListItem(
            :goal="readGoal"
            :num="null"
            :completed="readGoal.completionStatusId === 'COMPLETED'"
            :failed="readGoal.completionStatusId === 'FAILED'"
            :editFromForeignProfile="editFromForeignProfile"
            :params = "params"
            @on-mark-as="onMarkAs(readGoal)"
            @on-deadline="onDeadline(readGoal)"
            @edit-goal="$emit('edit-goal', readGoal)"
            @delete="onDelete"
            @approve="approve({ goal: readGoal })"
            @reject="reject({ goal: readGoal })"
            @comment="(text) => comment({comment: text, goal: readGoal})")
    mark-goal-modal(
      :show="showMarkAsModal"
      :goal="currentMarkedGoal"
      @close="onCloseMarkAsModal"
      @on-accept="onAccept"
      @on-fail="onFail")
    deadline-modal(
      :show="showDeadlineModal"
      :goal="currentMarkedGoal"
      @close="onCloseDeadlineModal"
      @accept="onSetDeadline")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GoalsListItem from './GoalsListItem'
import MarkGoalModal from './MarkGoalModal'
import DeadlineModal from './DeadlineModal'

export default {
  name: 'ReadGoalModal',

  components: {
    GoalsListItem,
    DeadlineModal,
    MarkGoalModal
  },

  props: {
    readGoal: {
      type: Object,
      default: () => {}
    },
    editFromForeignProfile: Boolean,
    params: {
      type: Object,
      default: () => null
    }
  },

   data: () => ({
    currentMarkedGoal: null,
    showDeadlineModal: false,
    showMarkAsModal: false
  }),

  methods: {
    ...mapActions('goals', [
      'completeGoal',
      'failGoal',
      'removeGoal',
      'updateGoal',
      'approve',
      'reject',
      'comment'
    ]),

    onDeadline (goal) {
      this.currentMarkedGoal = goal
      this.showDeadlineModal = true
    },

    onMarkAs (goal) {
      this.currentMarkedGoal = goal
      this.showMarkAsModal = true
    },

    onFail () {
      this.failGoal({ goal: this.currentMarkedGoal })
      this.onCloseMarkAsModal()
    },

    onAccept () {
      this.completeGoal({ goal: this.currentMarkedGoal })
      this.onCloseMarkAsModal()
    },

    onCloseMarkAsModal () {
      this.currentMarkedGoal = null
      this.showMarkAsModal = false
    },

    onCloseDeadlineModal () {
      this.currentMarkedGoal = null
      this.showDeadlineModal = false
    },

    onSetDeadline () {
      this.updateGoal({ goalId: this.currentMarkedGoal.id,
        values: {
          deadlineAt: this.currentMarkedGoal.deadlineAt
        }
      })
      this.onCloseDeadlineModal()
    },

    onClose () {
      this.$emit('close')
    },

    onDelete () {
      this.removeGoal({ goalId: this.readGoal.id })
      this.onClose()
    }
  },

  computed: {
    ...mapGetters('employee', [
      'foreignEmloyeeDetail'
    ]),

    isEmployeeView () {
      return this.$route.path.includes('employee')
    }
  }
}
</script>

<style lang="scss">
  .v-checkbox label {
    font-size: 1.1rem !important;
    font-weight: 600 !important;
  }
</style>

<style lang="scss" scoped>
  .subtitle-container {
    display: flex;
    align-items: center;
  }

  .read-goal--avatar {
    margin: 0 5px;
  }

  .form-group {
    label {
      font-weight: normal;
      color: $h2-title;
    }
  }

  .create-goal--action__wrapper {
    width: 80%;
  }

  .form-group--badge-row {
    display: flex;
    gap: 10px;

    .form-group {
      width: calc(50% - 5px)
    }
  }

  .create-goal--add-badge__action {
    display: flex;
    justify-content: center;
    margin: 15px 0px;

    span {
      font-size: 1.2rem;
      font-weight: 600;
      cursor: pointer;
      color: $color-primary;

      &:hover {
        color: darken($color-primary, 15%);
      }
    }
  }
</style>
