import { render, staticRenderFns } from "./BadgeSimple.vue?vue&type=template&id=6c4246ad&scoped=true&lang=pug&"
import script from "./BadgeSimple.vue?vue&type=script&lang=js&"
export * from "./BadgeSimple.vue?vue&type=script&lang=js&"
import style0 from "./BadgeSimple.vue?vue&type=style&index=0&id=6c4246ad&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c4246ad",
  null
  
)

export default component.exports