import Vue from 'vue'
import VueRouter from 'vue-router'
import api from '@/api'
import store from '@/store'
import cookie from '@/util/cookie'
import lazyLoading from './lazyLoading'
import { TOKEN_COOKIE, ENV, KEEP_SIGNED_FLAG } from '@/util/constants'

import AppLayout from '@/layouts/AppLayout'
import Auth from '@/views/Auth'
import NotFound from '@/views/info/NotFound'

import TreeView from '@/views/tree-view/TreeView'

import administration from './administration'
import components from './modules/components'
import SharedProfile from '@/components/common/SharedProfile.vue'

Vue.use(VueRouter)

// Silence NavigationDuplicated error globally
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}

const routes = [
  {
    path: '/',
    redirect: '/my',
    component: AppLayout,
    children: [
      { path: '/my', component: TreeView },
      { path: '/my/badge/:badge_id', component: TreeView },
      { path: '/my/goal/:goal_id', component: TreeView },
      { path: '/builder', name: 'BuilderOld', component: TreeView },
      { path: '/builder/badge/:badge_id', component: TreeView },
      { path: '/builder/suggested/:suggested_badge_id', component: TreeView },
      { path: '/skill-finder', component: TreeView },
      { path: '/skill-finder/:id', component: TreeView },
      { path: '/skill-finder/badge/:badge_id', component: TreeView },
      { path: '/employee/:employee_id', component: lazyLoading('employees/Index') },
      { path: '/employee/:employee_id/badge/:badge_id', component: lazyLoading('employees/Index') },
      { path: '/employee/:employee_id/goal/:goal_id', component: lazyLoading('employees/Index') },
      administration,
      {
        path: 'settings',
        component: lazyLoading('settings/Index'),
        redirect: 'settings/global-settings/company',
        children: [
          {
            path: 'global-settings',
            component: lazyLoading('settings/globalSettings/Index'),
            children: [
              { path: 'company', component: lazyLoading('settings/globalSettings/company/Index') },
              { path: 'data', component: lazyLoading('settings/globalSettings/dataSync/Index') },
              { path: 'user', component: lazyLoading('settings/globalSettings/user/Index') },
              { path: 'access-token', component: lazyLoading('settings/globalSettings/accessToken/Index') },
              { path: 'email-settings', component: lazyLoading('settings/globalSettings/emailSettings/Index') },
              { path: 'general-settings', component: lazyLoading('settings/globalSettings/generalSetting/index') }
            ]
          }
        ]
      },
      {
        path: '/profile',
        component: lazyLoading('profile/Index'),
        redirect: 'profile/general',
        children: [
          {
            path: ':id',
            component: lazyLoading('profile/Index')
          }
        ]
      },
      {
        path: '/insights',
        redirect: '/insights/all',
        component: lazyLoading('insights/Index'),
        children: [
          { path: 'all', component: lazyLoading('insights/allSkills/Index') },
          { path: 'goals', component: lazyLoading('insights/Goals/Index') }
        ]
      },
      {
        path: '/skill-gap',
        redirect: '/skill-gap/matrix-list',
        component: lazyLoading('skill-gap-analysis/Index'),
        children: [
          { path: 'matrix-list', component: lazyLoading('skill-gap-analysis/matrix-list/Index') },
          { path: 'matrix-list/:id', component: lazyLoading('skill-gap-analysis/matrix/Index') }
        ]
      },
      {
        path: '/feed',
        component: lazyLoading('feed/Index'),
        redirect: '/feed/all',
        children: [
          { path: 'all', component: lazyLoading('feed/FeedAll/Index') },
          { path: 'unseen', component: lazyLoading('feed/FeedUnseen/Index') },
          { path: 'open-confirmations', component: lazyLoading('feed/openConfirmations/Index') }
        ]
      },
      {
        path: '/builder-new/badge/:badge_id',
        name: 'BuilderNew',
        component: lazyLoading('builder/Index')
      },
      {
        path: '/builder-new',
        name: 'BuilderNew',
        component: lazyLoading('builder/Index')
      }
    ]
  },

  {
    path: '/setup',
    component: lazyLoading('setup/Index'),
    redirect: '/setup/company',
    children: [
      { path: 'company', component: lazyLoading('setup/Index') },
      { path: 'data', component: lazyLoading('setup/Index') },
      { path: 'data/redirect', alias: 'data/redirect', component: lazyLoading('setup/Index') },
      { path: 'employees', component: lazyLoading('setup/Index') },
      { path: 'summary', component: lazyLoading('setup/Index') },
      { path: 'super-admin', component: lazyLoading('setup/Index') }
    ]
  },
  { path: '/mail-settings', component: lazyLoading('settings/mailpreference/Index') },
  { path: '/login', component: Auth },
  { path: '/confirm' },
  { path: '/forgot', component: Auth },
  { path: '/forgot/:confirmationCode', component: Auth },
  { path: '*', component: NotFound },
  { path: '/employees/share/:id', component: SharedProfile },
  { path: '/employees/share/:id/badge/:badge_id', component: SharedProfile },
  components
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const navLogic = (to, path, allow, next, redirectUri = '') => {
  if (to.path.includes(path)) {
    allow ? next() : next({ path: redirectUri || '/my' })
  } else {
    next()
  }
}

router.beforeEach(async (to, from, next) => {
  if (cookie.hasItem(TOKEN_COOKIE)) {
    // Redirected to secured route only after login
    if (localStorage.getItem('savedRedirect')) {
      const redirect = localStorage.getItem('savedRedirect')
      localStorage.removeItem('savedRedirect')
      next(redirect)
    }
    // Update route expires date
    if (cookie.getItem(KEEP_SIGNED_FLAG)) {
      cookie.resetMaxAge(TOKEN_COOKIE)
      cookie.resetMaxAge(KEEP_SIGNED_FLAG)
    }

    const perms = await store.dispatch('permissions/getPermissions')
    if (perms) {
      navLogic(to, 'administration', perms.PAGE_ADMINISTRATION, next)
      navLogic(to, 'administration/employees/add', !store.getters['app/isThirdPartyLoginEnabled'], next)
      navLogic(to, 'settings', true, next) // store.getters['app/isSuperAdmin']
      navLogic(to, 'insights', perms.PAGE_ANALYSIS, next)
      navLogic(to, 'builder', perms.PAGE_SKILLTREE_BUILDER, next)
      navLogic(to, 'skill-finder', perms.PAGE_SKILL_FINDER, next)
      navLogic(to, 'employee/', true, next)
      navLogic(to, 'my', true, next)
      navLogic(to, 'mail-settings', true, next)
      navLogic(to, 'profile', true, next)
      navLogic(to, 'not-found', true, next)
      navLogic(to, 'skill-gap', true, next)
      navLogic(to, 'components', ENV.SHOW_DEBUG_TOOLS, next)
      navLogic(to, 'administration/employees/import', store.getters['setupWizard/employeesCsv'] && !store.getters['app/isThirdPartyLoginEnabled'], next, '/administration/employees')
      navLogic(to, 'feed', true, next)
      if (to.path.includes('employee/') || from.path.includes('employee/')) {
        if (to.path.includes('goal') || to.path.includes('badge') || ((from.path.includes('goal') || from.path.includes('badge')) &&
          to.path.includes('employee/'))) { // fast fix
        } else {
          store.dispatch('trees/setTreeIsLoaded', false)
        }
      }
      store.dispatch('treeView/setShowAllAsActivated',
        to.path.includes('builder') || to.path.includes('skill-finder'))
      if (to.path.includes('login') || to.path.includes('setup')) {
        next({ path: '/my' })
      }
    } else {
      next(false)
    }
  } else {
    if (!to.path.includes('login') && !to.path.includes('forgot')) {
      localStorage.setItem('savedRedirect', to.path)
    }

    if (to.path.includes('setup')) {
      const { data } = await api.loginSetupWithoutToken()
      if (data && data.settings) {
        store.commit('app/SET_THIRD_PARTY_LOGIN_PROVIDER', data.settings.global.thirdPartyLoginProvider)
      }
      data && !data.settings.global.isSetupComplete ? next(true) : next({ path: '/my' })
    } else if (to.path.includes('confirm')) {
      next({ path: `/forgot/${to.query.confirmationCode}?email=${to.query.email}` })
    } else if (to.path.includes('login') || to.path.includes('forgot')) {
      const { data } = await api.loginSetupWithoutToken()
      if (data && data.settings) {
        store.commit('app/SET_THIRD_PARTY_LOGIN_PROVIDER', data.settings.global.thirdPartyLoginProvider)
      }
      store.dispatch('app/setIsThirdPartyLoginEnabled', data ? data.settings.global.isThirdPartyLoginEnabled : false)
      data && !data.settings.global.isSetupComplete ? next({ path: '/setup' }) : next(true)
    } else if (to.path.includes('/share')) {
      next()
    } else if (!(to.path.includes('login') || to.path.includes('forgot'))) {
      next({ path: '/login' })
    }
  }
})

export default router
