<template lang="pug">
  .row-suggested-buttons(
    v-if="showSuggested")
    v-btn(
      :loading="isSaving"
      @click="acceptSuggest")
      span {{ isSaving ? `${$t('ui.buttons.accepting')}...` : 'ui.buttons.accept' | translate  }}

    v-dropdown-btn(
      v-if="showDropdownButtons"
      danger
      :disabled="isSaving"
      @click="declineSuggest") {{ isSaving ? `${$t('ui.buttons.declining')}...` : 'ui.buttons.decline' | translate  }}
        template(slot="dropdown")
          v-card.fixed.width-parents(
            elevation
            overflow-hidden)
            v-list-item(@click.native="declineSuggest")
              | {{ 'ui.buttons.decline' | translate }}
            v-list-item(@click.native="onToggleDeclineModal(true)")
              | {{ 'ui.buttons.decline_with_comment' | translate }}
    v-btn(
      v-else
      danger
      :disabled="isSaving"
      @click="declineSuggest")
      span {{ isSaving ? `${$t('ui.buttons.declining')}...` : 'ui.buttons.decline' | translate  }}

    decline-suggest-modal(
      v-if="showDeclineModal"
      :show="showDeclineModal"
      :node="node"
      @on-close="onToggleDeclineModal(false)")

</template>

<script>
import { mapActions } from 'vuex'
import { ENV } from '@/util/constants'

import DeclineSuggestModal from '../DeclineSuggestModal'

export default {
  name: 'BadgeDetailSuggested',

  components: {
    DeclineSuggestModal
  },

  props: {
    node: Object,
    badgeInfo: Object
  },

  data: () => ({
    isSaving: false,
    showDeclineModal: false
  }),

  methods: {
    ...mapActions('app', [
      'showConfirmDialog'
    ]),

    ...mapActions('trees', [
      'declineSuggestNode',
      'acceptSuggestNode'
    ]),

    async acceptSuggest () {
      // this.clearErrors()
      this.isSaving = true

      const requiredBadgesId = []
      for (const item of this.badgeInfo.requiredBadges) {
        requiredBadgesId.push(item.id)
      }

      const props = {
        name: this.badgeInfo.name,
        description: this.badgeInfo.description,
        isVisible: this.badgeInfo.isVisible,
        requiredBadgeIds: requiredBadgesId,
        parentBadgeId: this.node.parents[0].props.id,
        typeId: this.node.props.typeId
      }
      const options = {
        title: 'ui.modals.do_you_really_want_to_accept_badge',
        params: { node: this.node, props },
        onconfirm: this.acceptSuggestNode
      }
      this.showConfirmDialog(options)
      this.isSaving = false
    },

    async declineSuggest () {
      // this.clearErrors()
      this.isSaving = true
      const options = {
        title: 'ui.modals.do_you_really_want_to_decline_badge',
        params: { node: this.node },
        onconfirm: this.declineSuggestNode
      }
      this.showConfirmDialog(options)
      this.isSaving = false
    },

    onToggleDeclineModal (param) {
      this.showDeclineModal = param
    }
  },

  computed: {
    showSuggested () {
      return this.node.props.isSuggested
    },

    showDropdownButtons () {
      return ENV.SHOW_ACTIVATE_OR_DECLINE_WITH_COMMENT
    }
  }
}
</script>

<style lang="scss" scoped>
  .row-suggested-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
  }
</style>
