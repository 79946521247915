<template lang="pug">
  .skill-detail-section(v-if="badges.length")
    h5.skill-detail-title {{ 'ui.modals.required_badges' | translate }}:
    .required-badges-item(
      v-for="badge in badges"
      :key="badge.id"
      @click="onLinkToBadge(badge)")
      badge(
        :color="color"
        :level="badge.starsCount")
      .required-badges-text
        span.required-badges-name {{ badge.displayName }}
        span.required-badges-status(
          v-if="badge.isActivated"
          :class="{ 'active': badge.isActivated, 'disable': !badge.isActivated }")
          | {{ badge.isActivated ? 'ui.labels.active' : 'ui.labels.disabled' | translate }}

</template>

<script>
import Badge from '@/components/svg/Badge'

export default {
  name: 'SkillDetailRequiredBadges',

  components: {
    Badge
  },

  props: {
    badges: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: null
    }
  },

  methods: {
    onLinkToBadge (item) {
      this.$router.push(`${this.navigateTo}/badge/${item.id}`)
    }
  },

  computed: {
    navigateTo () {
      if (['/my', '/employee'].some(item => this.$route.path.includes(item))) {
        return this.$route.path.split('/')[1] === 'employee' ? '/' + this.$route.path.split('/')[1] + '/' + this.$route.path.split('/')[2]
          : '/' + this.$route.path.split('/')[1]
      } else return '/my'
    }
  }
}
</script>

<style lang="scss">
</style>
