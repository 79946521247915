import api from '@/api'
import router from '@/router'
import helpers from '@/util/helpers'
import { NODE_TYPE, TREE_VIEWS_LIST, TREE } from '@/util/constants'
import {
  prepearNode,
  findNodes,
  findNode,
  findNodeBySubbadgeId,
  updateNodesDisplayNames,
  updateNode,
  removeNode,
  sortSubbadges,
  getNodesByRequiredBadgeId,
  addParents,
  addParentsForHookedBadges
} from '@/util/badge-tree-helpers'
import { filters } from '@/util/treeFilters.js'

const SET_GLOBAL_TREE = 'SET_GLOBAL_TREE'
const SET_DEFAULT_TREE_VIEW = 'SET_DEFAULT_TREE_VIEW'

const ADD_NODE = 'ADD_NODE'
const UPDATE_NODE = 'UPDATE_NODE'
const REMOVE_NODE = 'REMOVE_NODE'
const REMOVE_HOOKED_BADGE = 'REMOVE_HOOKED_BADGE'
const SET_TREE_IS_LOADED = 'SET_TREE_IS_LOADED'
const SET_USE_NEW_TREE = 'SET_USE_NEW_TREE'

const SET_FILTER = 'SET_FILTER'
const LOAD_LAZY_TREE = 'LOAD_LAZY_TREE'
const MULTIPLE_ROOT_NODE = 'MULTIPLE_ROOT_NODE'
const STRUCTURE_CONTEXT_MENU = 'STRUCTURE_CONTEXT_MENU'

const getUseNewTreeValue = () => {
  const value = localStorage.getItem('use-new-tree')
  if (value === null) {
    return value
  }
  return value === 'true'
}

export default {
  namespaced: true,

  state () {
    return {
      globalTree: null, // full version of tree, used for displaying on all pages and editing
      defaultTreeView: null,
      filteredBadges: [],
      rootCategoryPictureUrl: null, // TODO: move to another module
      rootCategoryTitle: null, // TODO: move to another module
      treeIsLoaded: false,
      useNewTree: getUseNewTreeValue(),
      selectedFilter: TREE.ALL_SKILLS,
      loadLazyTree: true, //  switch when download all data tree
      multipleRootNode: 1,
      structureContextMenu: {
        show: false,
        pos: { x: 0, y: 0 },
        isCtrl: false
      }
    }
  },

  getters: {
    flatBadges (state) {
      if (!state.globalTree.children) return []
      return Object.values(helpers.flatArray(state.globalTree))
    },

    selectedTree (state) {
      if (router.app._route.path.includes('/my') || router.app._route.path.includes('/employee')) {
        return filters[state.selectedFilter](state.globalTree)
      } else if (router.app._route.path === '/builder') {
        if (state.multipleRootNode === 1) {
          return filters[TREE.ALL_SKILLS](state.globalTree)
        } else {
          return filters['MULTIPLE_ROOT_NODE'](state.globalTree, state.multipleRootNode)
        }
      } else {
       return filters[TREE.ALL_SKILLS](state.globalTree)
      }
    },
    globalTree: (state) => state.globalTree,

    isLoadLazyTree: (state) => state.loadLazyTree,

    filteredBadges: (state) => state.filteredBadges,

    isEditable: (state) => state.isEditable,

    getDefaultTreeView: (state) => state.defaultTreeView,

    useNewTree: (state) => state.useNewTree,

    treeIsLoaded: (state) => state.treeIsLoaded,

    getTreeViewsToggler: (state) => state.stateTreeViewsToggler,

    rootCategoryPictureUrl: (state) => state.rootCategoryPictureUrl,

    rootCategoryTitle: (state) => state.rootCategoryTitle,

    multipleRootNode: (state) => state.multipleRootNode,

    structureContextMenu: (state) => state.structureContextMenu
  },

  mutations: {
    [SET_FILTER] (state, filterName) {
      state.selectedFilter = filterName
    },

    [LOAD_LAZY_TREE] (state, status) {
      state.loadLazyTree = status
    },

    setRootCategoryPictureUrl (state, url) {
      state.rootCategoryPictureUrl = url || '/img/no-profile-picture.svg'
    },

    setRootCategoryTitle (state, title) {
      state.rootCategoryTitle = title
    },

    // TODO: move to actions
    addParents (state) {
      addParents(state.globalTree)
    },

    // TODO: move to actions
    addParentsForHookedBadges (state) {
      addParentsForHookedBadges(state.globalTree)
    },

    [SET_GLOBAL_TREE] (state, data) {
      state.globalTree = data
    },

    [SET_DEFAULT_TREE_VIEW] (state, data) {
      state.defaultTreeView = data
    },

    [SET_USE_NEW_TREE] (state, data) {
      state.useNewTree = data
      localStorage.setItem('use-new-tree', data)
    },

    [ADD_NODE]: (state, { toNode, newNode }) => {
      toNode.children.push(newNode)
    },

    [UPDATE_NODE] (state, { node, props, updateSubbadges = false }) {
      updateNode(node.props, props, updateSubbadges)
    },

    [REMOVE_NODE] (state, node) {
      removeNode([state.globalTree], { id: node.props.id })
    },

    [REMOVE_HOOKED_BADGE]: (state, node) => {
      const parent = node.parents[0]
      parent.props.hookedBadge = null
    },

    // TODO: move to badge-tree-helpers
    moveBadge (state, { node, badge }) {
      badge.props.parentBadgeId = node.props.id
      // Change parents of moved badge
      badge.parents = [node, ...node.parents]
      if (badge.props.typeId === NODE_TYPE.CATEGORY) {
        // Change parents for all children
        addParents(badge, [node, ...node.parents])
      }
      if (badge.props.hookedBadge) {
        // Change parents of hooked badge
        badge.props.hookedBadge.parents = [badge, ...badge.parents]
      }
      node.children.push(badge)
    },

    addRequired (state, { node, props }) {
      node.props.requiredBadges = [...node.props.requiredBadges, props]
    },

    removeRequired (state, { node, props }) {
      node.props.requiredBadges = node.props.requiredBadges.filter(b => b.id !== props.id)
    },

    addRequiredBadgeInGradationalSubBadge (state, { node, props, badgeId }) {
      node.props.gradationalSubBadges.map(e => {
        if (e.id !== badgeId) { return e }
        e.requiredBadges = [...e.requiredBadges, props]
        return e
      })
    },

    removeRequiredBadgeInGradationalSubBadge (state, { node, props, badgeId }) {
      node.props.gradationalSubBadges.map(e => {
        if (e.id !== badgeId) { return e }
        e.requiredBadges = e.requiredBadges.filter(b => b.id !== props.id)
        return e
      })
    },

    toggleIsGoal (state, badge) {
      badge.isGoal = !badge.isGoal
    },

    toggleIsFavorite (state, badge) {
      badge.helpers.isFavorite = !badge.helpers.isFavorite
    },

    moveActiveBadgesToTop (state, parent = null) {
      // This code is requeired only for SVG tree version
      const moveToTop = (node) => {
        if (node.children.length) {
          if (node.children[0].props.typeId !== NODE_TYPE.CATEGORY) {
            node.children.sort((a, b) => (a.props.isActivated === b.props.isActivated) ? 0 : (a.props.isActivated ? 1 : -1))
            if (parent) {
              node.children.sort((a, b) => (a.localProps.showInput === b.localProps.showInput) ? 0 : (a.localProps.showInput ? 1 : -1))
            }
            node.children.sort((a, b) => (a.props.isSuggested === b.props.isSuggested) ? 0 : (a.props.isSuggested ? 1 : -1))
          } else {
            for (const item of node.children) {
              moveToTop(item)
            }
          }
        }
      }
      moveToTop(parent || state.globalTree)
    },

    addGradationalSubBadge (state, { node, props }) {
      node.props.gradationalSubBadges.push(props)
    },

    updateGradationalSubBadge (state, { node, props }) {
      for (const idx in node.props.gradationalSubBadges) {
        if (node.props.gradationalSubBadges[idx].id === props.id) {
          for (const key in props) {
            node.props.gradationalSubBadges[idx][key] = props[key]
          }
          break
        }
      }
    },

    removeGradationalSubBadge (state, { node, props }) {
      node.props.gradationalSubBadges = node.props.gradationalSubBadges.filter(e => e.id !== props.id)
    },

    moveGradationalSubBadge (state, { node, oldIndex, newIndex }) {
      sortSubbadges(node, oldIndex, newIndex)
    },

    addHookedBadge (state, { node, badge }) {
      node.props.hookedBadge = badge
      badge.parents = [node, ...node.parents]
    },

    removeHookedBadge (state, node) {
      node.props.hookedBadge = null
    },

    setIsEditable (state, value) {
      state.isEditable = value
    },

    [SET_TREE_IS_LOADED]: (state, param) => {
      state.treeIsLoaded = param
    },

    [MULTIPLE_ROOT_NODE]: (state, categoryID) => {
      localStorage.setItem('MULTIPLE_ROOT_NODE', categoryID)
      state.multipleRootNode = categoryID
    },

    [STRUCTURE_CONTEXT_MENU]: (state, props) => {
      for (let key in props) {
        state.structureContextMenu[key] = props[key]
      }
    }
  },

  actions: {
    // --- START: NEW TREE API
    findNodes ({ state }, params = {}) {
      return findNodes([state.globalTree], params)
    },

    findNode ({ state }, params = {}) {
      return findNode([state.globalTree], params)
    },

    getNodeById ({ state }, id) {
      return findNode([state.globalTree], { id })
    },

    getNodeBySubbadgeId ({ state }, id) {
      return findNodeBySubbadgeId([state.globalTree], { id })
    },

    updateRequiredBadge ({ state }, { badgeId, params = {} }) {
      const resultGlobalTree = getNodesByRequiredBadgeId([state.globalTree], { badgeId })
      if (resultGlobalTree && resultGlobalTree.length) {
        for (const idx in resultGlobalTree) {
          const requiredBadgesOfNode = [...resultGlobalTree[idx].props.requiredBadges, ...resultGlobalTree[idx].props.hookedBadge?.props?.requiredBadges || []]
          for (const i in resultGlobalTree[idx].props.gradationalSubBadges) {
            requiredBadgesOfNode.push(...resultGlobalTree[idx].props.gradationalSubBadges[i].requiredBadges)
          }
          const badge = requiredBadgesOfNode.find(el => el.id === badgeId)
          for (const key in params) {
            badge[key] = params[key]
          }
        }
      }
      return resultGlobalTree
    },

    updateNodes ({ commit }, { nodes, props, updateSubbadges = false }) {
      for (const node of nodes) {
        commit(UPDATE_NODE, { node, props, updateSubbadges })
      }
    },

    setCursorNodeById ({ state, dispatch }, id) {
      dispatch('nodeTmp/unsetCursorNode', null, { root: true })
      const node = findNode([state.globalTree], { id })
      dispatch('nodeTmp/setCursorNode', node, { root: true })
    },

    rearrangeTree ({ state, dispatch, getters }) {
      helpers.rearrangeTree(getters.selectedTree)
      // dispatch('treeView/setMinZoom', null, { root: true })
    },
    // --- END: NEW TREE API

    // --- START: FOR CONTEXT MENU ONLY
    addNodes (context, { toNode, nodes }) {
      for (const newNode of nodes) {
        context.commit(ADD_NODE, { toNode, newNode })
      }
      context.dispatch('rearrangeTree')
    },

    removeNodes (context, { nodes }) {
      for (const node of nodes) {
        context.commit(REMOVE_NODE, node)
      }
      context.dispatch('rearrangeTree')
    },

    async moveCategory (context, { toNode, node }) {
      node.localProps.isCut = false
      context.commit(REMOVE_NODE, node)
      context.commit('moveBadge', { node: toNode, badge: node })
      context.dispatch('rearrangeTree')
      await api.badges.updateById(node.props.id, node.props)
    },

    async deleteCategory (context, node) {
      context.commit(REMOVE_NODE, node)
      await api.badges.delete(node.props.id)
    },

    async deleteHookedBadge (context, node) {
      context.commit(REMOVE_HOOKED_BADGE, node)
      await api.badges.delete(node.props.id)
    },
    // --- END: FOR CONTEXT MENU ONLY

    async loadDefaultTreeType ({ commit }) {
      const { data } = await api.settings.defaultView.restore()
      const settingView = typeof data === 'object' ? data.value : data
      const fienObj = TREE_VIEWS_LIST.find(el => el.key === settingView)
      commit(SET_DEFAULT_TREE_VIEW, fienObj)
    },

    async setDefaultTreeType ({ commit, state }, payload) {
      if (payload && payload.key !== state.defaultTreeView?.key) {
        await api.settings.defaultView.store({ value: payload.key })
        commit(SET_DEFAULT_TREE_VIEW, payload)
      }
    },

    async loadTreeById ({ commit, dispatch, getters }, { id = null, lazy = true }) {
      dispatch('nodeTmp/unsetCursorNode', null, { root: true })

      const params = {
        withSuggestedBadges: { value: 1 },
        ...(id && { forEmployeeId: { value: id } }),
        ...(lazy && { lazy: { value: lazy } })
      }
      const { error, data } = await api.getBadgesTree(params)

      if (error?.status === 403) {
        window.history.length > 2 ? router.go(-1) : router.push('/my')
        return
      }

      if (data) {
        const { badges, rootCategoryPictureUrl, rootCategoryTitle } = data
        const tree = helpers.prepareTree(badges)
        const updateTree = (children, treeChildren) => {
          for (let idx in children) {
            if (children[idx].children.length) {
              updateNode(children[idx].props, treeChildren[idx].props)
              updateTree(children[idx].children, treeChildren[idx].children)
            } else {
              const find = treeChildren.find(el => el.props.id === children[idx].props.id)
             if (!children[idx].localProps.tounched) {
              updateNode(children[idx].props, { ...find.props, pos: children[idx].props.pos })
              }
              updateNode(children[idx].localProps, { ...treeChildren[idx].localProps, pos: children[idx].localProps.pos })
            }
          }
        }
        if (!lazy) {
          updateNode(getters.globalTree.props, tree.props)
          updateTree(getters.globalTree.children, tree.children)
          commit(LOAD_LAZY_TREE, false)
        } else {
          // for employee user and lazy tree
          commit(SET_GLOBAL_TREE, JSON.parse(JSON.stringify(tree)))
          commit(LOAD_LAZY_TREE, true)
        }

        commit('setRootCategoryPictureUrl', rootCategoryPictureUrl)
        commit('setRootCategoryTitle', rootCategoryTitle)
        commit('addParents')
        commit('moveActiveBadgesToTop')
        commit('addParentsForHookedBadges')
        dispatch('rearrangeTree')
        commit(SET_TREE_IS_LOADED, true)
      }

      return getters.globalTree
    },

    setTreeIsLoaded (context, param) {
      context.commit(SET_TREE_IS_LOADED, param)
    },

    setUseNewTree (context, data) {
      context.commit(SET_USE_NEW_TREE, data)
    },

    async selectMySkilltree ({ dispatch, commit }) {
      dispatch('nodeTmp/unsetCursorNode', null, { root: true })
      commit(SET_FILTER, TREE.MY_SKILLTREE)
      setTimeout(() => {
        dispatch('rearrangeTree')
      })
    },

    async unselectMySkilltree ({ dispatch, commit }, unsetCursor = true) {
      if (unsetCursor) {
        await dispatch('nodeTmp/unsetCursorNode', null, { root: true })
      }
      commit(SET_FILTER, TREE.ALL_SKILLS)
      dispatch('rearrangeTree')
    },

    async createNode (context, { name, typeId, toNode, templateId = null, useOutsideVisualTree = false }) {
      if (context.getters.isLoadLazyTree) {
        await context.dispatch('badges/getBadgeById', { node: toNode }, { root: true })
      }

      let nodeProps = toNode.props ? toNode.props : toNode.data
      const payload = {
        name: name,
        parentBadgeId: nodeProps.id
      }
      if (templateId === null) {
        payload.typeId = typeId
      }
      if (typeId === NODE_TYPE.GRADATIONAL_BADGE) {
        payload.starsCount = 1
      }
      const newNode = templateId === null
        ? await (async () => {
          const { data } = await api.badges.create(payload)
          const copyData = { ...data }
          if (data.typeId === NODE_TYPE.CATEGORY) {
            context.commit('app/SET_CATEGORY', copyData, { root: true })
          }
          const newData = { ...data, color: data.typeId === NODE_TYPE.CATEGORY ? data.color : null }
          return prepearNode(newData)
        })()
        : await (async () => {
          const { data } = await api.badges.createByTemplate(templateId, payload)
          const node = data.length ? prepearNode(data[0]) : prepearNode(data)
          node.props.color = node.props.typeId === NODE_TYPE.CATEGORY ? node.props.color : null
          if (data.length > 1) {
            for (let i = 1; i < data.length; i++) {
              const el = data[i]
              el.template.name = ''
              node.props.gradationalSubBadges.push(el)
            }
          }

          return node
        })()
      if (!newNode.props.description) {
        newNode.props.description = ''
      }
      if (toNode.parents) {
        newNode.parents = [toNode, ...toNode.parents]
        toNode.localProps.showInput = false
      }

      newNode.localProps.showInput = true
      context.commit(ADD_NODE, { toNode, newNode })
      if (!useOutsideVisualTree) {
        context.commit('moveActiveBadgesToTop', toNode)
        context.dispatch('rearrangeTree')
        context.dispatch('nodeTmp/setCursorNode', newNode, { root: true })
        context.dispatch('nodeTmp/setLastAddedNode', newNode, { root: true })
        context.dispatch('svgTree/zoomToNode', { node: newNode }, { root: true })
      } else {
        context.dispatch('skills/updateItem', {
          id: newNode.props.id,
          ...newNode.props,
          isNew: true
        }, { root: true })
      }
    },

    async moveNodes (context, { toNode, nodes }) {
      for (const node of nodes) {
        node.localProps.isCut = false
        context.commit(REMOVE_NODE, node)
        context.commit('moveBadge', { node: toNode, badge: node })
        context.dispatch('rearrangeTree')
      }
    },

    removeNode (context, { node }) {
      if (node.props.id === 1) return null
      if (node.props.typeId !== NODE_TYPE.HOOKED_BADGE) {
        context.dispatch('nodeTmp/setCursorNode', null, { root: true })
        context.commit(REMOVE_NODE, node)
        context.dispatch('rearrangeTree')
        api.badges.delete(node.props.id)
        context.dispatch('skills/removeItem', { id: node.props.id }, { root: true })
        if (node.props.typeId === NODE_TYPE.CATEGORY) {
          context.commit('app/DELETE_CATEGORY', node.props, { root: true })
        }
      } else {
        context.commit('removeHookedBadge', node.parents[0])
        api.badges.delete(node.props.id)
      }
    },

    async updateNode (context, { node, props, updateSubbadges, queryParams = null }) {
      if (node.localProps) {
        node.localProps.showInput = false
      }
      context.dispatch('nodeTmp/unsetLastAddedNode', null, { root: true })
      if (node.props.id) {
        const prevProps = {}
        for (const key in props) {
          prevProps[key] = node.props[key]
        }
        context.commit('UPDATE_NODE', { node, props, updateSubbadges })
        if (props.name || props.levelName) {
          updateNodesDisplayNames(node, (param) => {
            context.dispatch('trees/updateRequiredBadge', param, { root: true })
          })
        }
        const { error } = await api.badges.updateById(node.props.id, props)
        if (error) {
          context.commit('UPDATE_NODE', { node, props: prevProps, updateSubbadges })
        }
        context.dispatch('skills/updateItem', { id: node.props.id, ...props }, { root: true })
      }
    },

    async suggestBadge (context, { toNodeId, payload }) {
      const { data, error } = await api.badges.suggest(payload)
      const toNode = await context.dispatch('getNodeById', toNodeId)
      const newNode = prepearNode(data)
      newNode.parents = [toNode, ...toNode.parents]
      context.commit(ADD_NODE, { toNode, newNode })
      context.commit('moveActiveBadgesToTop', toNode)
      context.dispatch('rearrangeTree')
      return { data, error }
    },

    async acceptSuggestNode (context, { node, props }) {
      const { error } = await api.acceptSuggestBadge(node.props.id, props)
      if (!error) {
        node.props.isSuggested = false
      }
    },

    async declineSuggestNode (context, { body }) {
      const node = context.rootGetters['nodeTmp/cursorNode']
      context.dispatch('nodeTmp/setCursorNode', node.parents[0], { root: true })
      context.commit(REMOVE_NODE, node)
      context.dispatch('rearrangeTree')
      await api.declineSuggestBadge(node.props.id, body)
    },

    async renewBadge (context, { node, props, queryParams = null }) {
      const { data, error } = await api.badges.renew(node.props.id, queryParams, props)
      if (!error) {
        context.commit('UPDATE_NODE', { node, props })
      }
      return { data }
    },

    async moveBadge (context, { categoryId, badgeId }) {
      const category = await context.dispatch('getNodeById', categoryId)
      const badge = await context.dispatch('getNodeById', badgeId)
      context.commit(REMOVE_NODE, badge)
      context.commit('moveBadge', { node: category, badge: badge })
      context.dispatch('nodeTmp/setCursorNode', null, { root: true })
      context.dispatch('rearrangeTree')
      const node = { ...badge.props }
      if (node.hookedBadge) {
        node.hookedBadge = badge.props.hookedBadge.props
      }
      await api.badges.updateById(badge.props.id, node)
    },

    async addRequired (context, { node, props }) {
      if (node.props.requiredBadges.some(badge => badge.id === props.id)) { return }
      context.commit('addRequired', { node, props })
      await api.addRequiredBadge(node.props.id, props.id)
    },

    async removeRequired (context, { node, props }) {
      context.commit('removeRequired', { node, props })
      await api.removeRequiredBadge(node.props.id, props.id)
    },

    async addRequiredBadgeInGradationalSubBadge (context, { node, props, badgeId }) {
      context.commit('addRequiredBadgeInGradationalSubBadge', { node, props, badgeId })
      await api.addRequiredBadge(badgeId, props.id)
    },

    async removeRequiredBadgeInGradationalSubBadge (context, { node, props, badgeId }) {
      context.commit('removeRequiredBadgeInGradationalSubBadge', { node, props, badgeId })
      await api.removeRequiredBadge(badgeId, props.id)
    },

    toggleIsGoal (context, badge) {
      context.commit('toggleIsGoal', badge)
      context.dispatch('suggestions/setGoalsforSuggestionSkills', { badge: badge }, { root: true })
      return badge.isGoal
    },

    toggleIsFavorite (context, badge) {
      context.commit('toggleIsFavorite', badge)
      return badge.isFavorite
    },

    async addGradationalSubBadge (context, { node, props }) {
      const { data } = await api.createGradationalSubBadge(props)
      if (!data.description) {
        data.description = ''
      }
      context.commit('addGradationalSubBadge', { node, props: data })
    },

    async updateGradationalSubBadge (context, { node, props }) {
      const { data, error } = await api.badges.updateById(props.id, props)
      if (!error) {
        if (props.name) {
          props.displayName = props.name + (props.levelName ? ' ' + props.levelName : '')
          context.dispatch('trees/updateRequiredBadge', {
            badgeId: props.id,
            params: { displayName: props.displayName }
          }, { root: true })
        }
        context.commit('updateGradationalSubBadge', { node, props })
      }
      return data
    },

    async removeGradationalSubBadge (context, { node, props }) {
      context.commit('removeGradationalSubBadge', { node, props })
      await api.removeGradationalSubBadge(props.id)
    },

    async moveGradationalSubBadge (context, { node, oldIndex, newIndex }) {
      context.commit('moveGradationalSubBadge', { node, oldIndex, newIndex })
      const fromBadgeId = oldIndex === 0 ? node.props.id : node.props.gradationalSubBadges[oldIndex - 1].id
      const toBadgeId = newIndex === 0 ? node.props.id : node.props.gradationalSubBadges[newIndex - 1].id
      await api.moveGradationalBadge(fromBadgeId, toBadgeId)
    },

    async setHookedBadge (context, { node, badge }) {
      context.commit(REMOVE_NODE, badge)
      context.commit('addHookedBadge', { node, badge })
      await api.addHookedBadge(node.props.id, badge.props.id)
      badge.props.typeId = NODE_TYPE.HOOKED_BADGE
    },

    async createHookedBadge (context, parentId) {
      const parent = await context.dispatch('getNodeById', parentId)
      const parentName = parent.props.name

      const hookedBadge = {
        props: {
          name: `${parentName} hooked badge`,
          displayName: `${parentName} hooked badge`,
          typeId: NODE_TYPE.HOOKED_BADGE,
          parentBadgeId: parent.props.id,
          requiredBadges: [],
          isActivated: false,
          template: null,
          levelName: null,
          gradationalSubBadges: [],
          description: '',
          color: null,
          activatedAt: null,
          activationComment: null,
          expiresAt: null,
          files: [],
          helpers: {
            isGoal: false,
            isProfileBadge: false,
            isRecommended: false
          },
          hookedBadge: null,
          isCommentForActivatingRequired: 0,
          isExpiryRequired: null,
          isFileRequired: null,
          isGoal: false,
          isSuggested: false,
          isVisible: 1,
          maxExpiryDays: null,
          parentGradationalBadgeId: null,
          pos: parent.props.pos,
          starsCount: 0,
          trainingFiles: [],
          validation: null,
          validationTypeId: null
        },
        parents: [parent, ...parent.parents],
        localProps: { isCut: false, pos: parent.localProps.pos }
      }
      context.commit('addHookedBadge', { node: parent, badge: hookedBadge })
      const { data } = await api.createHookedBadge(hookedBadge.props)
      if (data) {
        hookedBadge.props.id = data.id
      }
      return parent.props.hookedBadge
    }
  }
}
