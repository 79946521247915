<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: {
    chartdata: Object,
    options: Object
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  },

  watch: {
    chartdata: {
      handler () {
        this.renderChart(this.chartdata, this.options)
      }
    }
  }
}
</script>
