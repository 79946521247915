<template lang="pug">
  v-group
    v-path(
      :config="configOutline")
    v-path(
      :config="config"
      @click="onClick"
      @mouseover="onMouseOver"
      @mouseleave="onMouseLeave")
    v-text(v-if="level > 5" :config="textConfig")
    template(v-else)
      v-star( v-for="star of countStars" :key="star.x" :config="star")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const BG_COLOR = '#F8F9FC'
const BADGE_PATH = 'M8,14.59V25.07A6.82,6.82,0,0,0,8.72,28a5,5,0,0,0,1.94,2.18l.61.37a31.26,31.26,0,0,1,2.92,1.79l.46.43a3.56,3.56,0,0,1,.26.36l.09.14h0a2,2,0,0,1,.3-.39l.37-.33a38.3,38.3,0,0,1,3.7-2.36,5.05,5.05,0,0,0,1.9-2.14,6.94,6.94,0,0,0,.72-3V14.59l-7-1.31Z'
const POS_Y = 14.5
const POS_Y2 = 20
const POS_X = 4.3
export default {
  name: 'TreeBadge',

  props: {
    node: Object,
    pos: Object,
    color: {
      type: String,
      default: '#13B389'
    },
    cursorNode: Object,
    isSelected: Boolean,
    level: {
      type: Number,
      default: 1
    }
  },
  data: () => ({
  }),

  mounted () {
    if (this.isCursorNode) {
      this.onClick()
    }
  },

  computed: {
  ...mapGetters('badges/newlyAdded', ['isNewlyBadgesIds']),

  ...mapGetters('settings', ['isEnabledNotification']),

    config () {
      return {
        x: this.pos.x - 15,
        y: this.pos.y - 22.5,
        stroke: this.color,
        strokeWidth: 1.8,
        fill: this.isActivated ? this.color : BG_COLOR,
        scale: {
          x: 1,
          y: 1
        },
        data: BADGE_PATH
      }
    },

    configOutline () {
      return {
        x: this.pos.x - 26,
        y: this.pos.y - 36,
        fill: this.isSelected ? '#ffdbac' : BG_COLOR,
        scale: {
          x: 1.74,
          y: 1.6
        },
        data: BADGE_PATH
      }
    },

    textConfig () {
      return {
        x: this.pos.x - (this.level > 9 ? 6 : 3),
        y: this.pos.y - 6,
        text: this.level,
        fontFamily: 'Calibri',
        fontSize: 12,
        fill: '#fff'
      }
    },

    countStars () {
        return this[`coordinatesLvl${this.level}`]
    },

    isCursorNode () {
     return this.cursorNode &&
        this.cursorNode.props.id === this.node.props.id
    },

    isActivated () {
      return this.node.props.isActivated
    },

    coordinatesLvl1 () {
      return [ {
          ...this.defaultConfigStar,
        x: this.pos.x,
        y: this.pos.y - POS_Y
      }]
    },

    coordinatesLvl2 () {
      return [ {
          ...this.defaultConfigStar,
        x: this.pos.x - POS_X,
        y: this.pos.y - POS_Y
      },
       {
         ...this.defaultConfigStar,
        x: this.pos.x + POS_X,
        y: this.pos.y - POS_Y
      }]
    },

    coordinatesLvl3 () {
      return [ {
          ...this.defaultConfigStar,
        x: this.pos.x - POS_X,
        y: this.pos.y - POS_Y
      },
      {
         ...this.defaultConfigStar,
        x: this.pos.x + POS_X,
        y: this.pos.y - POS_Y
      },
      {
         ...this.defaultConfigStar,
        x: this.pos.x,
        y: this.pos.y - POS_Y2
      } ]
    },

    coordinatesLvl4 () {
      return [ {
          ...this.defaultConfigStar,
        x: this.pos.x - POS_X,
        y: this.pos.y - POS_Y
      },
      {
         ...this.defaultConfigStar,
        x: this.pos.x + POS_X,
        y: this.pos.y - POS_Y
      },
      {
        ...this.defaultConfigStar,
        x: this.pos.x - POS_X,
        y: this.pos.y - POS_Y2 - 1
      },
      {
         ...this.defaultConfigStar,
        x: this.pos.x + POS_X,
        y: this.pos.y - POS_Y2 - 1
      }]
    },
    coordinatesLvl5 () {
      return [ {
          ...this.defaultConfigStar,
        x: this.pos.x - POS_X,
        y: this.pos.y - POS_Y
      },
      {
         ...this.defaultConfigStar,
        x: this.pos.x + POS_X,
        y: this.pos.y - POS_Y
      },
      {
        ...this.defaultConfigStar,
        x: this.pos.x - 8,
        y: this.pos.y - POS_Y2
      },
      {
         ...this.defaultConfigStar,
        x: this.pos.x,
        y: this.pos.y - POS_Y2
      },
      {
         ...this.defaultConfigStar,
        x: this.pos.x + 8,
        y: this.pos.y - POS_Y2
      }]
    },

   defaultConfigStar () {
     return {
    numPoints: 5,
    innerRadius: 1.25,
    outerRadius: 2.5,
    fill: this.color,
    strokeWidth: 0 }
  },

    isMyPage () {
      return this.$route.path.includes('my')
    }

   },

  methods: {
    ...mapActions('badges/newlyAdded', ['updateNewlyItemSeen']),

    onClick () {
      this.node.localProps.pos.x = this.pos.x
      this.node.localProps.pos.y = this.pos.y
      this.$emit('click', this.node)
      if (this.isEnabledNotification && this.isNewlyBadgesIds.includes(this.node.props.id) && this.isMyPage) {
      this.updateNewlyItemSeen(this.node.props.id)
      }
    },

    onMouseOver () {
      document.body.style.cursor = 'pointer'
      this.isHovered = true
    },

    onMouseLeave () {
      document.body.style.cursor = 'default'
      this.isHovered = false
    }
  },

  watch: {
    isCursorNode (value) {
      if (value) {
        this.onClick()
      }
    }
  }
}
</script>

<style>
</style>
