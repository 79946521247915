<template lang="pug">
  transition(name="fadex")
    .onboarding-wrapper(
      v-if="show")
      transition(name="fadex")
        onboarding-step(
          v-if="showStep"
          :width="currentStep.params.width"
          :title="currentStep.content.title"
          :subtitle="currentStep.content.subtitle"
          :placement="currentStep.params.placement"
          :is-first="isFirst"
          :is-last="isLast"
          :position="position"
          @prev="prevStep"
          @next="nextStep"
          @skip="skipAll"
          @skip-walkthrough="skipWalkthrough"
          @finish="finish")
          //- TODO: move component from the slot below to onboarding config object
          //- and use dynamic component here
          template(
            v-if="currentStepIndex === 0"
            slot="content")
            img(src="../../../assets/img/onboarding/skilltree-demo.png")
          select-categories(
            v-if="currentStepIndex === 1"
            slot="content")
          select-skills(
            v-if="currentStepIndex === 2"
            slot="content")
          template(
            v-if="currentStepIndex === 3"
            slot="content")
            img(src="../../../assets/img/onboarding/tree.png")
          template(
            v-if="currentStepIndex === 4"
            slot="content")
            img.full-width(src="../../../assets/img/onboarding/map-demo.gif")
          div(
            v-if="currentStepIndex === 7"
            slot="subtitle")
            | {{ currentStep.content.subtitle | translate }} {{' '}}
            span.active-text(
              @click="openHelpcenter") {{'ui.labels._help_center' | translate }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import OnboardingStep from './OnboardingStep'
import SelectCategories from './select-categories/SelectCategories'
import SelectSkills from './select-skills/SelectSkills'

export default {
  name: 'Onboarding',

  components: {
    OnboardingStep,
    SelectCategories,
    SelectSkills
  },

  mounted () {
    this.calcPosition()
    // For correct work onboarding must starts on '/my' route
    if (!this.$route.path.includes('my')) {
      this.$router.push('/my')
    }
  },

  data: () => ({
    show: true,
    showStep: true,
    targetEl: null,
    currentEl: null,
    position: {}
  }),

  methods: {
    ...mapActions('onboarding', [
      'next',
      'prev',
      'skip'
    ]),

    ...mapActions('trees', [
      'getNodeById',
      'setCursorNode'
    ]),

    ...mapActions('svgTree', [
      'zoomToNode'
    ]),

    async nextStep () {
      if (this.currentStepIndex + 1 === 5) {
        const nodeEl = document.querySelector('.tree-node-badge')
        if (nodeEl) {
          const id = nodeEl.getAttribute('data-id')
          const node = await this.getNodeById(parseInt(id))
          if (node) {
            this.currentEl = nodeEl
            this.zoomToNode({
              node: node,
              beforeZoom: () => { this.showStep = false },
              afterZoom: this.nextStepLogic
            })
          }
        } else {
          // if badge element not found
          // skip 3 steps
          this.next()
          this.next()
          this.next()
        }
      } else {
        this.nextStepLogic()
      }
    },

    nextStepLogic () {
      if (this.currentStep.callbacks.onnext) {
        let params = null
        if (this.currentStepState.selected) {
          params = this.currentStepState.selected
        }
        this.currentStep.callbacks.onnext(this.$store, params)
      }
      this.next()
      if (this.targetEl) {
        if (this.targetEl.onclick) {
          this.targetEl.onclick()
        } else if (this.targetEl.onnodeclick) {
          this.targetEl.onnodeclick()
        }
      }
    },

    prevStep () {
      this.targetEl = null // Forget previous target el for prevent wrong click on next nextStep click
      if (this.currentStep.callbacks.onprev) {
        this.currentStep.callbacks.onprev(this.$store, null)
      }
      if (this.currentStepIndex === 7 && !this.currentEl) {
        this.prev()
      }
      this.prev()
    },

    skipAll () {
      this.show = false
    },

    skipWalkthrough () {
      this.skip()
      this.skipAll()
    },

    openHelpcenter () {
      window.open(`https://skilltree.tawk.help`, '_blank').focus()
    },

    finish () {
      this.show = false
      if (this.currentStep.callbacks.onnext) {
        this.currentStep.callbacks.onnext(this.$store, null)
      }
    },

    calcPosition () {
      if (this.currentStep.target) {
        const placement = this.currentStep.params.placement
        const isVertical = placement === 'top' || placement === 'bottom'
        const isHorizontal = placement === 'left' || placement === 'right'
        const target = this.currentStep.target
        if (target !== 'CURRENT') {
          this.targetEl = typeof target === 'string'
            ? document.querySelector(target)
            : target
        } else {
          this.targetEl = this.currentEl
        }
        if (this.targetEl) {
          // TODO: add logic for tree map pan if badge is not in visible area
          const rect = this.targetEl.getBoundingClientRect()
          const x = rect.x + (isVertical ? rect.width / 2 : rect.width)
          const y = rect.y + (isHorizontal ? rect.height / 2 : rect.height)
          this.position = {
            x: x,
            y: y,
            vertical: isVertical,
            horizontal: isHorizontal
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters('onboarding', [
      'steps',
      'currentStep',
      'currentStepIndex',
      'currentStepState'
    ]),

    isFirst () {
      return this.currentStepIndex === 0
    },

    isLast () {
      return this.currentStepIndex === this.steps.length - 1
    }
  },

  watch: {
    currentStepIndex () {
      this.showStep = false
      setTimeout(() => {
        this.calcPosition()
        this.showStep = true
      }, 700)
    }
  }
}
</script>

<style lang="scss">
  .onboarding-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, .08);
  }
</style>
