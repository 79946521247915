<template lang="pug">
  .navigation-dropdown(
    v-hover="{ over: () => toggled = true, leave: () => toggled = false }")
    .navigation-dropdown__item(
      @click="toggle")
      slot
      .navigation-dropdown__chevron
        v-chevron(
          size="10"
          :color="toggled ? '#13B389' : '#010002'")
    transition(
      name="fade-down"
      mode="out-in")
      .navigation-dropdown__dropdown(
        v-if="toggled")
        v-card(elevation)
          v-card-content
            .navigation-dropdown__list(
              @click="toggled = false")
              router-link.navigation-dropdown__link(
                v-for="(item, index) in items"
                v-if="isVisible(item)"
                :key="index"
                :class="{ 'navigation-dropdown__link--active': $route.path.includes(item.path) }"
                :to="item.path")
                | {{ item.title | translate }}

</template>

<script>
export default {
  name: 'NavigationDropdown',

  props: {
    items: Array,
    permKey: String,
    currentPermissions: Object
  },

  data: () => ({
    toggled: false
  }),

  methods: {
    toggle () {
      this.toggled = !this.toggled
    },

    isVisible (item) {
      if (this.permKey === 'PAGE_ANALYSIS' && !this.currentPermissions[this.permKey]) {
        return !/^\/insights\//.test(item.path)
      }

      return true
    }
  }
}
</script>

<style lang="scss">
  .navigation-dropdown {
    position: relative;

    &__item {
      display: flex;
      align-items: center;
    }

    &__dropdown {
      position: absolute;
      min-width: 180px;
      top: 100%;
      padding-top: 20px;
      left: -18px;
      z-index: 1000;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 20px;
        height: 3px;
        background-color: $color-primary;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__link {
      cursor: pointer;
      color: $h2-title;
      font-size: 14px;
      font-weight: 400;

      &--active {
        color: #14B38A;
        font-weight: 600;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
</style>
