<template lang="pug">
  .base-tree
    component(
      v-if="loaded"
      show-controls
      :is="componentName"
      :tree="tree"
      :editable="editable"
      :filtered-badges="selected.badges"
      @on-pan="moveModal"
      @on-zoom="onTreeZoom"
      @open-modal="openModal"
      @close-modal="closeModal")
    .base-tree__preloader(v-else)
      tree-preloader
    transition(
      name="fade-up"
      mode="out-in")
      skill-description(
        v-if="showModalNodeDetail"
        :pos="modalPos"
        :zoom="zoom"
        :search-header="useForSearch"
        :is-editable="isCursorNodeModalEditable"
        :employee-id="employeeId")
    node-clipboard-debug-menu
    change-tree-look(v-if="this.$route.path.includes('my')")
    change-builder-look(v-if="this.$route.path.includes('builder')")
    export-button(v-if="this.$route.path.includes('builder')")
</template>

<script>
import { mapGetters } from 'vuex'

import Tree from '@/components/tree/Tree'
import Tree2 from '@/components/tree-2/Tree'
import SkillDescription from '@/components/modals/SkillDescription'
import NodeClipboardDebugMenu from '@/debug/modals/NodeClipboardDebugModal'
import TreePreloader from '@/components/svg/TreePreloader'
import ChangeTreeLook from '@/components/controls/ChangeTreeLook'
import ChangeBuilderLook from '@/components/controls/ChangeBuilderLook'
import ExportButton from '@/components/controls/ExportButton'

export default {
  name: 'BaseTreeView',

  props: {
    editable: Boolean,
    useForSearch: Boolean,
    useCursorNodeModal: Boolean,
    isCursorNodeModalEditable: Boolean,
    tree: Object,
    loaded: Boolean,
    employeeId: Number
  },

  components: {
    Tree,
    Tree2,
    SkillDescription,
    NodeClipboardDebugMenu,
    TreePreloader,
    ChangeTreeLook,
    ChangeBuilderLook,
    ExportButton
  },

  data: () => ({
    showDescModal: {
      show: false,
      pos: { x: 0, y: 0 }
    },
    zoom: 0.4
  }),

  computed: {
    ...mapGetters('nodeTmp', [
      'cursorNode'
    ]),

    ...mapGetters('skillFinder', [
      'selected'
    ]),

    componentName () {
      return this.$store.getters['trees/useNewTree'] && this.$route.path.includes('my')
        ? 'tree-2'
        : 'tree'
    },

    showModalNodeDetail () {
      if (this.useCursorNodeModal) {
        return this.cursorNode && this.showDescModal.show
      }
      return false
    },

    modalPos () {
      return {
        x: this.showDescModal.pos.x + 40 * this.zoom,
        y: this.showDescModal.pos.y + 40 * this.zoom - 80
      }
    }
  },

  methods: {
    openModal (payload) {
      this.closeModal()
      this.$nextTick(() => {
        if (payload.node && payload.node.props.id !== 1) {
        this.showDescModal.pos = payload.pos
        this.showDescModal.show = true
      }
      })
    },

    onTreeZoom (zoom) {
      this.zoom = zoom[0]
      this.closeModal()
    },

    closeModal () {
      this.showDescModal.show = false
    },

    moveModal (pan) {
      this.showDescModal.pos.x += pan.x
      this.showDescModal.pos.y += pan.y
    }
  }
}
</script>

<style lang="scss">
  .base-tree {
    height: calc(100vh - 80px);

    &__preloader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
</style>
