<template lang="pug">
  .v-dropdown-btn(
    ref="vDropdownBtn"
    v-click-outside="() => { toggled = false }"
    :class="classes")
    button.v-dropdown-btn__action-btn(
      :disabled="disabled"
      @click="onClick")
      slot

    button.v-dropdown-btn__toggler-btn(
      :disabled="disabled"
      @click="onToggle")
      v-chevron(
        color="#fff"
        :size='12'
        :value="toggled")

    transition(name="fade-down")
      .v-dropdown-btn__content(
        v-show="toggled"
        :style="styleContent"
        @click="toggled = false")
        slot(name="dropdown")

</template>

<script>
export default {
  name: 'VDropdownBtn',

  props: {
    small: Boolean,
    large: Boolean,
    block: Boolean,
    danger: Boolean,
    warning: Boolean,
    disabled: Boolean,
    onlyDropdownActions: Boolean,
    lockAction: Boolean
  },

  data: () => ({
    toggled: false,
    styleContent: {
      height: 'auto'
    }
  }),

  methods: {
    onClick () {
      this.$emit('click')
    },

    closeDropdown () {
      this.toggled = false
    },

    onSelect () {
      this.toggled = false
      this.$emit('toggle')
    },

     onToggle () {
      this.toggled = !this.toggled
      setTimeout(() => {
        this.setStyleForDropdownSlot()
      }, 100)
    },

    setStyleForDropdownSlot () {
        this.styleContent = {}
        const { bottom } = this.$refs.vDropdownBtn.getBoundingClientRect()
        const { clientHeight, clientWidth } = this.$slots.dropdown[0].elm
        if (bottom + clientHeight > window.innerHeight) {
          this.styleContent.bottom = '100%'
          this.styleContent.height = `${clientHeight + 1}px`
          this.styleContent.width = `${clientWidth + 1}px`
        } else {
          this.styleContent.top = '100%'
          this.styleContent.height = 0
          this.styleContent.width = `${clientWidth + 1}px`
        }
    }
  },

  computed: {
    classes () {
      return {
        'v-btn-size--small': this.small,
        'v-btn-size--large': this.large,
        'v-dropdown-btn--block': this.large,
        'v-dropdown-btn--danger': this.danger,
        'v-dropdown-btn--warning': this.warning,
        'v-dropdown-btn--only-dropdown-actions': this.onlyDropdownActions,
        'v-dropdown-btn--lock-action': this.lockAction
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .v-dropdown-btn {
    position: relative;
    display: inline-flex;
    line-height: 1;

    &--block {
      display: flex;
      width: 100%;
    }

    &--danger {
      .v-dropdown-btn__action-btn:not(:disabled),
      .v-dropdown-btn__toggler-btn:not(:disabled) {
        background-color: $color-error;
      }

      .v-dropdown-btn__toggler-btn:not(:disabled) {
        &:hover {
          background-color: lighten($color-orange, 2%);
        }
        &:active {
          background-color: darken($color-orange, 2%);
        }
      }
    }

    &--warning {
      .v-dropdown-btn__action-btn:not(:disabled),
      .v-dropdown-btn__toggler-btn:not(:disabled) {
        background-color: $color-orange;
      }

      .v-dropdown-btn__toggler-btn:not(:disabled) {
        &:hover {
          background-color: lighten($color-orange, 2%);
        }
        &:active {
          background-color: darken($color-orange, 2%);
        }
      }
    }

    &:not(.v-dropdown-btn--lock-action) {
      .v-dropdown-btn--warning {
        .v-dropdown-btn__action-btn:not(:disabled) {
          &:hover {
            background-color: lighten($color-orange, 2%);
          }
          &:active {
            background-color: darken($color-orange, 2%);
          }
        }
      }

      .v-dropdown-btn--danger {
        .v-dropdown-btn__action-btn:not(:disabled) {
          &:hover {
            background-color: lighten($color-error, 2%);
          }
          &:active {
            background-color: darken($color-error, 2%);
          }
        }
      }
    }

    &.v-dropdown-btn--lock-action {
      .v-dropdown-btn__action-btn {
        cursor: default;
      }
    }

    &--warning {
      .v-dropdown-btn__action-btn:not(:disabled),
      .v-dropdown-btn__toggler-btn:not(:disabled) {
        background-color: $color-orange;

        &:hover {
          background-color: lighten($color-orange, 2%);
        }

        &:active {
          background-color: darken($color-orange, 2%);
        }
      }
    }

    // &:not(.v-btn-size--large):not(.v-btn-size--small) {
    //   height: $v-btn-height;
    // }

    &.v-btn-size {
      &--small {
        height: 30px;
        font-size: 13px;
        .v-dropdown-btn__action-btn,
        .v-dropdown-btn__action-btn {
            padding: 6px 24px
        }
      }

      &--large {
        height: $v-btn-height--lg;
      }
    }

    &__action-btn,
    &__toggler-btn {
      box-sizing: border-box;
      border: none;
      color: $color-white;
      background-color: $color-primary;

      &:hover {
        background-color: $color-primary--hover;
      }

      &:active {
        background-color: $color-primary--active;
      }

      &:disabled {
        cursor: not-allowed;
        color: $color-primary--disabled;
        background-color: $color-primary--disabled-2;
      }
    }

    &--only-dropdown-actions {
      .v-dropdown-btn__action-btn {
        pointer-events: none;
      }
    }

    &__action-btn {
      flex-grow: 1;
      padding: 16px 24px;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &__toggler-btn {
      padding: 0 21px;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &__content {
      position: absolute;
      right: 0;
      min-width: 80%;
      width: 100%;
      z-index: 100;
    }
  }
</style>
