<template lang="pug">
  g(
    :transform="pos | svgtranslate({ x: -(halfSize + 23), y: -(halfSize + 23) })"
    :class="{ 'cut': node.localProps.isCut }")
    g
      g
        circle.under-category(:cx="halfSize + 23" :cy="halfSize + 23" :r="halfSize + 13" fill="#F8F9FC")
        circle.under-category(:cx="halfSize + 23" :cy="halfSize + 23" :r="halfSize + 6" fill="#fff" stroke="#ccc" :stroke-width="strokeWidth")
      g(
        @click.stop="onClick"
        @contextmenu.prevent="onRightClick"
        :data-id="id"
        :data-x="pos.x - (halfSize + 23)"
        :data-y="pos.y - (halfSize + 23)"
        :data-depth="depth"
        :data-allow-drop="allowDrop"
        :id="'cat' + this.id"
        v-tooltip="tooltip"
        data-zone="add"
        data-rank="move-category"
        :class="{ 'selected': isSelected, 'hilighted': isHilighted, 'lock-hover': isSkilltreeElementDragging, 'drop-active': activateDrop, 'node-draggable': !isMainNode }"
        class="node category drag-move dropzone")
          circle(class="halo" :cx="halfSize + 23" :cy="halfSize + 23" :r="halfSize + 17" stroke="#ffdbac" stroke-width="6")
          circle(:cx="halfSize + 23" :cy="halfSize + 23" :r="halfSize + 13" fill="#F8F9FC")
          circle(class="hover-effect" :cx="halfSize + 23" :cy="halfSize + 23" :r="halfSize + 6" fill="#fff" :stroke="color" :stroke-width="strokeWidth")
          circle( :cx="halfSize + 23" :cy="halfSize + 23" :r="halfSize" fill="transparent" stroke-width="0")
          svg(v-if="rootCategoryPictureUrl && isMainNode" class="hover-effect" x="23" y="23" width="100" height="100")
            defs
              pattern(id="image" x="0" y="0" patternUnits="userSpaceOnUse" height="100" width="100")
                image(x="0" y="0" height="100%" width="100%" :href="rootCategoryPictureUrl")
            circle(cx="50" cy="50" r="50" fill="url(#image)")
          circle(v-else :cx="halfSize + 23" :cy="halfSize + 23" r="1" :fill="color")
          svg(v-if="allowDrop" class="add-icon" :x="halfSize / 2 + 23" :y="halfSize / 2 + 23" :height="halfSize" :width="halfSize" viewBox="0 0 491.86 491.86")
            path(fill="#3bcca5" d="M465.167,211.614H280.245V26.691c0-8.424-11.439-26.69-34.316-26.69s-34.316,18.267-34.316,26.69v184.924H26.69C18.267,211.614,0,223.053,0,245.929s18.267,34.316,26.69,34.316h184.924v184.924c0,8.422,11.438,26.69,34.316,26.69s34.316-18.268,34.316-26.69V280.245H465.17c8.422,0,26.69-11.438,26.69-34.316S473.59,211.614,465.167,211.614z")
      svg(
        :x="hookedBadgeOffset"
        :y="hookedBadgeOffset"
        :data-id="id"
        :data-zone="allowAddHooked ? 'hooked' : 'add'"
        :data-rank="allowAddHooked ? 'badge' : 'move-category'"
        height="33"
        width="33"
        class="hookedbadge-icon dropzone"
        :class="{ 'selected': isSelected, 'hilighted': isHilighted, 'lock-hover': isSkilltreeElementDragging, 'drop-active': activateDrop, 'node-draggable': !isMainNode }")
        g(:transform="`scale(${hookedBadgeSize})`")
          path(fill="#fff" fill-rule="evenodd" d="M1.23,3.73V16.55a15.12,15.12,0,0,0,7.66,13l4,2.32,4-2.32a15.13,15.13,0,0,0,7.67-13V3.73L12.85,1Z")
          path(fill="#fff" fill-rule="evenodd" stroke="#15b58d" stroke-width="2px" d="M4.08,6.22v10.3A12.3,12.3,0,0,0,9.86,27l3,1.87,3-1.87a12.31,12.31,0,0,0,5.79-10.44V6.22L12.85,4Z")
          path(fill="#fff" fill-rule="evenodd" stroke="#15b58d" stroke-width="2px" d="M8.7,15.43H17")
          path(fill="#fff" fill-rule="evenodd" stroke="#15b58d" stroke-width="2px" d="M12.85,19.58v-8.3")

    svg(
      :x="hookedBadgeOffset - 2"
      :y="hookedBadgeOffset - 8"
      :transform="`scale(${hookedBadgeSize})`"
      height="52"
      width="36"
      viewBox="0 0 36 36")
      slot

</template>

<script>
import { mapGetters } from 'vuex'

import { NODE_TYPE, COLOR_PRIMARY } from '@/util/constants.js'

export default {
  name: 'TreeNodeCategory',

  props: {
    id: {
      type: Number,
      default: 0
    },
    pos: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    color: {
      type: String,
      default: COLOR_PRIMARY
    },
    depth: {
      type: Number,
      default: 1
    },
    node: Object,
    children: {
      type: Array,
      default: () => []
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isHilighted: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    if (this.node.localProps.showInput) {
      this.selectNode()
    }
  },

  methods: {
    onClick (e) {
      this.selectNode(e)
    },

    selectNode (e) {
      const rect = this.$el.getBoundingClientRect()
      const pos = { x: rect.left, y: rect.top }
      this.$emit('select', {
        event: e,
        pos: pos,
        node: this.node,
        el: this.$el
      })
    },

    onRightClick (e) {
      const rect = this.$el.getBoundingClientRect()
      const pos = { x: rect.left, y: rect.top }
      this.$emit('right-click', {
        event: e,
        pos: pos,
        node: this.node,
        el: this.$el
      })
    }
  },

  computed: {
    ...mapGetters('trees', [
      'rootCategoryPictureUrl'
    ]),

    ...mapGetters('dragNDrop', [
      'draggingElementType'
    ]),

    ...mapGetters('nodeTmp', [
      'selectedNodes',
      'hasSelectedNodes',
      'dragEnterNodeId'
    ]),

    size () {
      return 100 / this.depth
    },

    halfSize () {
      return this.size / 2
    },

    strokeWidth () {
      return this.depth < 5
        ? 14 - this.depth * 3
        : 2
    },

    allowAddCategory () {
      return this.children.length === 0 ||
        this.children[0].props.typeId === NODE_TYPE.CATEGORY
    },

    allowAddBadge () {
      return this.children.length === 0 ||
        this.children[0].props.typeId !== NODE_TYPE.CATEGORY
    },

    allowDrop () {
      if (this.node.props.id === 1 && ['badge', 'gradational-badge', 'badge-template'].includes(this.draggingElementType)) {
        return false
      }
      if (this.hasSelectedNodes) {
        if (this.selectedNodes[0].parents.length &&
          this.selectedNodes[0].parents[0].props.id !== this.id) {
          if ((this.selectedNodes[0].props.typeId === NODE_TYPE.BADGE ||
            this.selectedNodes[0].props.typeId === NODE_TYPE.GRADATIONAL_BADGE) &&
            this.allowAddBadge) {
            return true
          } else if (this.selectedNodes[0].props.typeId === NODE_TYPE.CATEGORY &&
            this.selectedNodes[0].props.id !== this.node.props.id &&
            this.allowAddCategory) {
            if (!this.node.parents
              .some(item => item.props.id === this.selectedNodes[0].props.id)) {
              return true
            }
          }
        }
      } else if ((this.draggingElementType === 'category') &&
        this.allowAddCategory) {
        return true
      } else if ((this.draggingElementType === 'badge' ||
        this.draggingElementType === 'gradational-badge' ||
        this.draggingElementType === 'badge-template') &&
        this.allowAddBadge) {
        return true
      }
      return false
    },

    allowAddHooked () {
      return !this.isMainNode && !this.node.props.hookedBadge && this.draggingElementType === 'badge'
    },

    isSkilltreeElementDragging () {
      return this.draggingElementType !== null
    },

    activateDrop () {
      return this.isSkilltreeElementDragging && this.allowDrop
    },

    isMainNode () {
      return !this.node.parents.length
    },

    hookedBadgeOffset () {
      // return this.size // + 12 - this.strokeWidth
      return this.size + 23 - this.strokeWidth - 10 * this.hookedBadgeSize
    },

    hookedBadgeSize () {
      return 1 - (this.depth / 20)
    },

    forbidDropToCategoryTooltip () {
      return !this.activateDrop && this.dragEnterNodeId === this.node.props.id
    },

    tooltip () {
      return { content: this.draggingElementType === 'badge' ? this.$t('infotext.invalid_badge_add') : null,
        show: this.forbidDropToCategoryTooltip,
        trigger: 'manual'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .halo,
  .add-icon,
  .hookedbadge-icon {
    opacity: 0;
    transition: 0.4s;
  }

  .selected {
    .halo {
      opacity: 1;
    }
  }

  .show-add {
    .halo {
      opacity: 1;
      stroke: #3bcca5 !important;
    }

    .add-icon {
      opacity: 1;
    }
  }

  .show-hooked + .hookedbadge-icon{
      opacity: 1;
  }

  .node:not(.lock-hover) {
    &:hover .hover-effect {
      opacity: 0.7;
    }
  }

  .cut {
    .under-category {
      fill: $website-color;
    }

    .category {
      opacity: .5;
    }
  }
</style>
