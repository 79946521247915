<template lang="pug">
  router-link.tabs__item(
    :to="path || ''"
    :class="classes"
    :style="styles"
    @click="onClick")
    slot
</template>

<script>
export default {
  name: 'VTabItem',

  props: {
    small: Boolean,
    active: Boolean,
    color: String,
    colored: Boolean,
    vertical: Boolean,
    path: String
  },

  methods: {
    onClick () {
      this.$emit('click')
    }
  },

  computed: {
    styles () {
      return {
        '--tabs-active-color': this.color
      }
    },

    classes () {
      return {
        'tabs__item--small': this.small,
        'tabs__item--active': this.active,
        'tabs__item--colored': this.colored,
        'tabs__item--vertical': this.vertical
      }
    }
  }
}
</script>

<style lang="scss">

  .tabs__item {
    --tabs-active-color: #{$color-primary};

    display: inline-flex;
    align-items: center;
    color: $color-inactive-text;
    cursor: pointer;
    position: relative;
    padding: 0.75rem 1rem;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    height: 100%;

    &--small {
      font-size: 16px;
      font-weight: 500;
    }

    &:hover {
      color: $color-inactive-text;
    }

    &--colored {
      color: var(--tabs-active-color);

      &:hover {
        color: var(--tabs-active-color);
      }
    }

    &--vertical {
      width: 100%;
      text-align: left;
      font-size: 18px;
      font-weight: 300;
      padding: 0;
      height: 35px;

      &.tabs__item--active {
        font-weight: 700;
      }

      &::after {
        display: none;
      }

      i {
        font-size: 16px;
      }
    }

    &--active {
      color: var(--tabs-active-color);

      &:hover {
        color: var(--tabs-active-color);
      }

      &::after {
        content: "";
        width: 12px;
        height: 3px;
        border-radius: 4px;
        margin: 4px auto 0;
        background-color: var(--tabs-active-color);
        position: absolute;
        left: calc(50% - 6px);
        bottom: 8px;
      }
    }
  }
</style>
