<template lang="pug">
  ul.v-select-list(
    :style="{ 'padding-left': `${level === 0 ? 0 : 40}px` }"
    :class="classes")
    li(
      v-for="(item, index) in items"
      :key="item.id")
      v-select-item(
        :borderless="borderless"
        :search="search"
        :item="item"
        :single="single"
        :level="level"
        :track-by="trackBy"
        :is-selected="isSelected(item)"
        :has-children="hasChildren(item)"
        :has-selected-child="hasSelected(item)"
        :is-last-child="index === items.length - 1"
        :is-item-selectable="isItemSelectable(item)"
        :simple="simple"
        @select="onSelect"
        @close="onClose")
        template(slot="before-title")
          slot(
            name="before-title"
            :item="item")
        template(slot="subtitle")
          slot(
            name="subtitle"
            :item="item")
        template(v-slot:default="slotProps")
          v-select-items(
            :single="single"
            :borderless="borderless"
            :items="item.children"
            :search="search"
            :track-by="trackBy"
            :selected="selected"
            :selected-parent="slotProps.isSelected"
            :is-last-child="index === items.length - 1"
            :level="level + 1"
            :is-item-selectable="isItemSelectable"
            :simple="simple"
            @select="onSelect"
            @close="onClose")
</template>

<script>
import VSelectItem from './VSelectItem'

export default {
  name: 'VSelectItems',

  components: {
    VSelectItem
  },

  props: {
    borderless: Boolean,
    items: Array,
    trackBy: String,
    itemValue: String,
    search: String,
    selected: [Array, Object, Boolean, Number, String],
    selectedParent: Boolean,
    single: Boolean,
    returnObject: Boolean,
    isItemSelectable: Function,
    level: {
      type: Number,
      default: 0
    },
    isLastChild: {
      type: Boolean,
      default: true
    },
    simple: Boolean
  },

  methods: {
    onSelect (item) {
      this.$emit('select', item)
    },

    onClose () {
      this.$emit('close')
    },

    isSelected (item) {
      if (this.single) {
        if (this.returnObject) {
          return this.selected &&
            item[this.itemValue] === this.selected[this.itemValue]
        }
        return this.selected === item[this.itemValue]
      }
      if (this.hasChildren(item)) {
        return !item.children.find(child => {
          return !this.isSelected(child)
        })
      }
      return this.selected
        .map(el => el[this.itemValue])
        .indexOf(item[this.itemValue]) !== -1
    },

    hasChildren (item) {
      return !!(item.children && item.children.length)
    },

    hasSelected (item) {
      if (this.single) {
        return false
      }
      if (this.hasChildren(item)) {
        let result = false
        for (const el of item.children) {
          if (this.hasChildren(el)) {
            result = this.hasSelected(el.children)
          } else {
            result = this.isSelected(el)
          }
          if (result) {
            break
          }
        }
        return result
      }
      return false
    }
  },

  computed: {
    classes () {
      return {
        'border-bottom': !this.borderless && !this.isLastChild,
        'v-select-list__item--simple': this.simple }
    }
  }
}
</script>

<style lang="scss">
  .v-select-list {
    width: 100%;
    display: flex;
    flex-direction: column;

    li {
      list-style: none;
    }

    .v-select-list__item {
      cursor: pointer;
      position: relative;
      color: #646C9A;
      padding: 10px 5px;
      padding-left: 40px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &.border-left {
        border-left: 1px solid #e6e6e6;
      }

      &.border-bottom {
        border-bottom: 1px solid #e6e6e6;
      }

      .v-select-list__item-title {
        text-align: start;
        line-height: 24px;
      }

      .v-select-list__item-subtitle {
        color: $color-inactive-text;
        font-size: 14px;
      }

      .v-select-list__item-before-title {
        margin-right: 15px;
      }

      i {
        color: $h2-title;
        font-style: normal !important;
      }

      &:not(.not-selectable) {
        &:hover {
          background: #eaecf5;
        }
      }

      &.disabled{
        background-color: rgba(0, 0, 0, 0.03);
      }

      &::before {
        content: "";
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        height: 18px;
        width: 18px;
        border: 1px solid #ddd;
        background-color: f0f2fa;
        border-radius: 5px;
      }

      &:not(.single) {
        &.selected::before {
          background: url('../../../assets/img/check.svg') no-repeat center;
          background-color: $light-blue;
          background-size: 10px 10px;
        }

        &.has-selected::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 16px;
          height: 2px;
          width: 10px;
          background-color: $h2-title;
        }
      }

      &.single {
        &::before {
          height: 20px;
          width: 20px;
          border-radius: 50%;
        }

        &.selected::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 22px;
          height: 9px;
          width: 9px;
          border-radius: 12px;
          background-color: $color-primary;
        }
      }

      &.not-selectable {
        cursor: default;

        &::before,
        &::after {
          display: none;
        }
      }

      .chevron-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .v-select-list__item {

    &--simple {
      .v-select-list__item {
        padding: 5px 5px;
        padding-left: 20px;

        &-title {
          color: #707070;
          font-size: 14px;
        }

        &::after {
          display: none;
        }

        &::before {
          display: none;
        }

        &.selected::before {
          display: none;
          // background: url('../../../assets/img/check-primary.svg') no-repeat center !important;
          // content: "";
          // position: absolute;
          // left: 5px;
          // height: 10px;
          // width: 10px;
          // border: none;
          // background-color: transparent !important;
        }
      }
    }
  }
</style>
