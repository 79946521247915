<template lang="pug">
div
  .app-header
    .app-header__inner
      .app-header__mobile-menu
      .app-header__brand(@click="redirectMySkills")
        img.app-header__brand-logo.cursor-pointer(alt="Skilltree" src="../../assets/ui/logo.svg")
      .header-menu
        #search-modal.v-dropdown
          .v-dropdown__toggler
            .header__topbar-item
              search
    .tree-container(v-if="treeIsLoaded")
      base-tree-view(
          useCursorNodeModal
          :loaded="treeIsLoaded"
          :tree="selectedTree")

    drawer-panel(
        side="right"
        v-if="treeIsLoaded"
        :title="$t('pages.profile.profile')"
        :minimize="!drawers.treeElements"
        :width="450"
        :addTopOffset="true"
        @on-toggle="drawers.treeElements = !drawers.treeElements")
          recommended-skills-modal(:employee="getEmployee")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Tree from '@/components/tree/Tree'
import Search from '@/components/common/search/Search'
import DrawerPanel from '@/components/ui/DrawerPanel'
import BaseTreeView from '@/views/tree-view/BaseTreeView'
import RecommendedSkillsModal from '@/views/my/modals/RecommendedSkills/RecommendedSkillsModal'

export default {
  name: 'SharedProfile',

  components: {
    Tree,
    Search,
    DrawerPanel,
    BaseTreeView,
    RecommendedSkillsModal
  },

  data () {
    return {
      drawers: {
        treeElements: false
      }
    }
  },

  computed: {
    ...mapGetters('employee', [
      'sharedProfileConfig'
    ]),

    ...mapGetters('trees', [
      'selectedTree',
      'treeIsLoaded',
      'isLoadLazyTree'
    ]),

    getEmployee () {
      return this.sharedProfileConfig.data.employee
    }
  },

  methods: {
    ...mapActions('preferences', [
      'getDefaultView'
    ]),

    ...mapActions('employee', [
      'getSharedProfile'
    ]),

     ...mapActions('trees', [
      'loadTreeById',
      'selectMySkilltree'
    ]),

    redirectMySkills () {
      this.$router.push({ path: '/my' })
    }
  },

  async mounted () {
    await this.getSharedProfile(this.$route.params.id)
    await this.loadTreeById({ id: null, lazy: true })
    await this.getDefaultView()
    this.selectMySkilltree()
    if (this.isLoadLazyTree) {
       this.loadTreeById({ id: null, lazy: false })
    }
  }
}
</script>

<style lang="scss">
  .header-menu{
    align-items: center !important;
  }
  .base-tree{
    position: relative;
    box-sizing: border-box;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
  }
  .top-offset{
    margin-top: 20px;
  }
</style>
