import api from '@/api'
import { PREFERENCES_VIEWS, TREE_OPTIONS, BUILDER_OPTIONS, PREFERENCES_VIEWS_TOGGLER } from '@/util/constants'

const SET_VIEW = 'GET_VIEW'
const SET_SKILLTREE_LOOK = 'SET_SKILLTREE_LOOK'
const SET_BUILDER_LOOK = 'SET_BUILDER_LOOK'
const SET_VIEW_TOGGLER = 'SET_VIEW_TOGGLER'

export default {
  namespaced: true,

  state () {
    return {
      defaultView: null,
      skilltreeDefaultLook: null,
      builderDefaultLook: null,
      defaultViewToggler: null
    }
  },

  getters: {
    isDefaultView: (state) => state.defaultView,
    isSkilltreeDefaultLook: (state) => state.skilltreeDefaultLook,
    isBuilderDefaultLook: (state) => state.builderDefaultLook,
    isDefaultViewToggler: (state) => state.defaultViewToggler,
    dinamicTogglView: (state) => state.defaultView && state.defaultView.key === 'LAST_SELECTED' ? state.defaultViewToggler : state.defaultView,
    isSharePage: () => window.location.pathname.includes('/share')
  },

  mutations: {
    [SET_VIEW] (state, data) {
      state.defaultView = data
    },

    [SET_SKILLTREE_LOOK] (state, data) {
      state.skilltreeDefaultLook = data
    },

    [SET_BUILDER_LOOK] (state, data) {
      state.builderDefaultLook = data
    },

    [SET_VIEW_TOGGLER] (state, data) {
      state.defaultViewToggler = data
    }
  },

  actions: {
    //  async getAllView ({ commit }) {
    //     const { data } = await api.preferences.defaultView.options()
    //   },

    // async getAllLook ({ commit }) {
    //   const { data } = await api.preferences.defaultLook.options()
    // },

    // async getLast ({ commit }) {
    //   const { data } = await api.preferences.lastSelectedView.options()
    // },

    async getDefaultView ({ commit, getters }) {
      const { data } = !getters.isSharePage ? await api.preferences.defaultView.restore() : { data: { 'value': 'MY_SKILLTREE' } }
      const settingView = typeof data === 'object' ? data.value : data
      const fienObj = PREFERENCES_VIEWS.find(el => el.key === settingView)
      commit(SET_VIEW, fienObj)
    },

    async setDefaultView ({ commit, state }, payload) {
      if (payload && payload.key !== state.defaultView.key) {
       await api.preferences.defaultView.store({ value: payload.key })
       commit(SET_VIEW, payload)
      }
    },

    async getSkilltreeDefaultLook ({ commit }) {
      const { data } = await api.preferences.defaultLook.restoreSkilltree()
      const settingView = typeof data === 'object' ? data.value : data
      const fienObj = TREE_OPTIONS.find(el => el.id === settingView)
      commit(SET_SKILLTREE_LOOK, fienObj)
    },

    async setDefaultSkilltreeLook ({ commit, state }, payload) {
      if (payload && payload.id !== state.skilltreeDefaultLook.id) {
        await api.preferences.defaultLook.storeSkilltree({ value: payload.id })
        commit(SET_SKILLTREE_LOOK, payload)
      }
    },

    async getBuilderDefaultLook ({ commit }) {
      const { data } = await api.preferences.defaultLook.restoreBuilder()
      const settingView = typeof data === 'object' ? data.value : data
      const fienObj = BUILDER_OPTIONS.find(el => el.id === settingView)
      commit(SET_BUILDER_LOOK, fienObj)
      commit('builder/TOGGLE_BUILDER_LOOK', fienObj, { root: true })
    },

    async setBuilderDefaultLook ({ commit, state }, payload) {
      if (payload && payload.id !== state.builderDefaultLook.id) {
        await api.preferences.defaultLook.storeBuilder({ value: payload.id })
        commit(SET_BUILDER_LOOK, payload)
      }
    },

    async getDefaultViewToggler ({ commit, getters }) {
      const { data } = !getters.isSharePage ? await api.preferences.lastSelectedView.restore() : { data: { 'value': 'MY_SKILLTREE' } }
      const settingView = typeof data === 'object' ? data.value : data
      const fienObj = PREFERENCES_VIEWS_TOGGLER.find(el => el.key === settingView)
      commit(SET_VIEW_TOGGLER, fienObj)
    },

    async setDefaultViewToggler ({ commit, state, getters }, payload) {
      if (payload && payload.key !== state.defaultViewToggler?.key) {
        if (!getters.isSharePage) {
          await api.preferences.lastSelectedView.store({ value: payload.key })
        }
        commit(SET_VIEW_TOGGLER, payload)
      }
    },

    changeToggler ({ commit, state }, payload) {
      if (state.defaultView && state.defaultView?.key === 'LAST_SELECTED') {
        commit(SET_VIEW_TOGGLER, payload)
      } else {
        commit(SET_VIEW, payload)
      }
    }
  }
}
