<template lang="pug">
  li.v-list__item(
    :class="classes"
    @click="onClick")
    slot
</template>

<script>
export default {
  name: 'VListItem',

  props: {
    ghost: Boolean,
    newStyleEnabled: Boolean,
    noHover: Boolean,
    newStyleDrop: Boolean
  },

  methods: {
    onClick () {
      this.$emit('click')
    }
  },

  computed: {
    classes () {
      return {
        'v-list__item--ghost': this.ghost,
        'v-list__item--new': this.newStyleEnabled,
        'v-list__item--no_hover': this.noHover,
        'v-list__item--new-drop': this.newStyleDrop
      }
    }
  }
}
</script>

<style lang="scss">

  .v-list__empty {
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 1.2rem;
  }

  .v-list__item {
    cursor: pointer;
    position: relative;
    padding: 1rem;
    display: flex;
    align-items: center;
    line-height: 1;

    &--no_hover {
      background-color: transparent!important;;
    }

    &:hover {
      background-color: #f7f8fa;
    }

    &:not(:first-child) {
      padding-top: 1rem;
    }

    &:not(:last-child) {
      padding-bottom: 1rem;
      border-bottom: 1px dashed #ebedf2;
    }

    &--ghost {
      border: none !important;
    }

    &--new-drop {
      box-sizing: border-box;
      height: 56px;
      .v-list__item-title{
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        margin-left: 16px;
        color: $h2-title;
        span {
          font-weight: 400;
          padding-left: 8px;
          font-size: 14px;
          line-height: 16px;
          color: $body-text-2;
        }
      }
    }

    &--new {
      padding: 0px!important;
      height: 44px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      .v-list__item-content {
        height: inherit;
        background-color: $website-color;
        border-radius: 4px;
      }
      .v-list__item-title{
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        margin-left: 16px;
        color: $color-black;
        span {
          font-weight: 300;
          padding-left: 16px;
          color: $body-text-2;
        }
      }
      .v-list__item-actions{
        width: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &-title {
      color: #6c7293;
      font-size: 1rem;
      font-weight: 300;
      line-height: 19px;
    }

    &-subtitle {
      color: #a7abc3;
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.4;
    }

    &-actions {
      display: flex;
      flex-direction: row;
      align-items: center;

      &--show-on-hover {
        display: none;
      }
    }

    &:hover {
      .v-list__item-actions--show-on-hover {
        display: flex;
      }
    }
  }
</style>
