<template lang="pug">
  .v-dropdown(
    ref="dropdown"
    :class="dropdownClasses"
    v-click-outside="closeDropdown"
    @keydown.esc="closeDropdown")
    .v-dropdown__toggler(
      @click="toggle")
      slot(:toggled="toggled")
    transition(
      :name="transition")
      .v-dropdown__content(
        ref="dropdownContent"
        v-if="toggled || focusSwitchEL"
        :style="styles"
        :class="classes"
        @click="onContentClick")
        slot(name="content")

</template>

<script>
import { windowResizeObserver } from '@/util/helpers'

export default {
  name: 'VDropdown',

  props: {
    flat: Boolean,
    width: [Number, String],
    fixed: Boolean,
    autoClose: Boolean,
    disabled: Boolean,
    widthAuto: Boolean,
    medium: Boolean,
    right: Boolean,
    widthFull: Boolean,
    centerOnMobile: Boolean,
    scrollableFixed: Boolean,
    focusSwitchEL: Boolean
  },

  data: () => ({
    toggled: false,
    isUpwardsDropdown: false
  }),

  updated () {
    this.checkPosition()
  },

  mounted () {
    if (!this.fixed && !this.scrollableFixed) {
      windowResizeObserver.subscribe(this.checkPosition)
    }
  },

  beforeDestroy () {
    if (!this.fixed && !this.scrollableFixed) {
      windowResizeObserver.unsubscribe(this.checkPosition)
    }
  },

  methods: {
    toggle () {
      if (!this.disabled) {
        this.toggled = !this.toggled
        this.$emit('toggle', this.toggled)
        if (this.scrollableFixed && this.toggled) {
          this.setScrollableFixedPosition()
        }
      }
    },

    setScrollableFixedPosition () {
      const topOffset = this.$refs.dropdown.getBoundingClientRect().top
      setTimeout(() => {
          let contentHeight = this.$refs.dropdownContent.firstChild.offsetHeight
          const isTopAlign = (topOffset + contentHeight + 40) >= window.innerHeight
          this.$refs.dropdownContent.style.top = !isTopAlign ? topOffset + 30 + 'px' : topOffset - contentHeight + 'px'
        }, 0)
    },

    onContentClick () {
      if (this.autoClose) {
        this.closeDropdown()
      }
    },

    closeDropdown () {
      this.toggled = false
      this.$emit('toggle', this.toggled)
    },

    openDropdown () {
      this.toggled = true
      this.$emit('toggle', this.toggled)
    },

    checkPosition () {
      if (!this.fixed && !this.scrollableFixed) {
        const percentTopixel = window.innerHeight / 100
        const rect = this.$refs.dropdown.getBoundingClientRect()
        if ((rect.y / percentTopixel) > 75) {
          this.isUpwardsDropdown = true
        } else {
          this.isUpwardsDropdown = false
        }
      }
    }
  },

  computed: {
    classes () {
      return {
        'v-dropdown__content--width-auto': this.widthAuto,
        'v-dropdown__content--right': this.right,
        'v-dropdown__content--width-full': this.widthFull,
        'v-dropdown__content--medium': this.medium,
        'v-dropdown__content--center-on-mobile': this.centerOnMobile
      }
    },

    transition () {
      return this.flat
        ? 'dropdown-flat'
        : 'dropdown'
    },

    styles () {
      return {
        width: this.width ? `${this.width}px` : null
      }
    },

    dropdownClasses () {
      return {
        'open': this.toggled || this.focusSwitchEL,
        'v-dropdown__upwards': this.isUpwardsDropdown,
        'v-dropdown--fixed': this.fixed || this.scrollableFixed }
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_mixins.scss';

  .v-dropdown {
    position: relative;

    &__toggler {
      position: relative;
      cursor: pointer;
      height: 100%;
    }

    &--fixed {
      .v-dropdown__content {
        position: fixed;
        top: auto;
        right: auto;
        left: auto;
        width: 32px;
      }

      .v-dropdown__content > div {
        position: absolute;
        right: 0;
      }
    }
  }

  .v-dropdown__upwards {
    .v-dropdown__content {
        bottom:100% ;
    }

    .v-dropdown__toggler {
      .chevron-wrapper {
          transform: rotate(180deg);
      }
    }
  }

  .v-dropdown__content {
    position: absolute;
    width: 100%;
    z-index: 101;
    left: 0;

    &--width-auto {
      width: auto;
    }

    &--right {
      right: 0;
      left: auto;
    }

    &--medium {
      width: 260px;
    }

    &--width-full {
      width: 359px;
    }

    &--center-on-mobile {
      @include mobile {
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
      }
    }
  }

  .dropdown-enter-active,
  .dropdown-leave-active {
    transition: all .3s;
  }

  .dropdown-enter,
  .dropdown-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }

  .dropdown-flat-enter-active,
  .dropdown-flat-leave-active {
    transition: all .3s;
  }

  .dropdown-flat-enter,
  .dropdown-flat-leave-to {
    opacity: 0;
    transform: translateY(20px) scale(.9);
  }
</style>
