import api from '@/api'

const SET_NEWLY_BADGES = 'SET_NEWLY_BADGES'
const OPEN_BADGE_PANEL = 'OPEN_BADGE_PANEL'
const LAST_SEEN_ID = 'LAST_SEEN_ID'

export default {
  namespaced: true,

  state: () => ({
    newlyBadges: [],
    openBadgePanel: false,
    lastSeenId: null
  }),

  getters: {
    isNewlyBadges: (state) => state.newlyBadges,
    isNewlyBadgesCount: (state) => state.newlyBadges.length,
    isOpenBadgePanel: (state) => state.openBadgePanel,
    isNewlyBadgesIds: (state) => state.newlyBadges.map(el => el.id)
  },

  mutations: {
    [SET_NEWLY_BADGES]: (state, badges) => {
      state.newlyBadges = badges
    },

    [OPEN_BADGE_PANEL]: (state, bool) => {
      state.openBadgePanel = bool
    },

    [LAST_SEEN_ID]: (state, ID) => {
      state.lastSeenId = ID
    }
  },

  actions: {
    async getNewlyBadges (context) {
      const { data } = await api.badges.newlyAdded.list()
      if (data) {
        context.commit(SET_NEWLY_BADGES, data)
      }
    },

    async updateNewlyAllItemsSeen (context) {
       await api.badges.newlyAdded.setAllSeen()
       context.commit(SET_NEWLY_BADGES, [])
    },

    async updateNewlyItemSeen (context, id) {
       if (context.state.lastSeenId === id) return
       context.commit(LAST_SEEN_ID, id)

       await api.badges.newlyAdded.setSeen(id)
       const newList = context.getters.isNewlyBadges.filter(badge => badge.id !== id)
       context.commit(SET_NEWLY_BADGES, newList)
    }
  }
}
