<template lang="pug">
  div
    base-tree-view(
      :editable="isBuilderView"
      :useForSearch="isSkillFinderView"
      :useCursorNodeModal="!isBuilderView"
      :tree="selectedTree"
      :loaded="inited && treeIsLoaded"
      isCursorNodeModalEditable)

    toasts-list(
      v-if="isTreeMyView")

    .panels-layout
      toolBar.center-under-header(v-if="isTreeMyView && treeIsLoaded && inited")

      //- MY SKILLTREE page
      drover-panel-tabs(
        v-if="isTreeMyView"
        side="left"
        :tabs="tabsLeftSidebar"
        :active-tab="activeTab"
        type-chevron="arrow"
        @on-toggle="openLeftSidebar")
          KeepAlive
            component(:is="currentTab")

      drover-panel-tabs(
        v-if="isTreeMyView"
        side="right"
        :width="444"
        :tabs="tabsRightSidebar"
        :active-tab="activeRightTab"
        type-chevron="arrow"
        @on-toggle="openRightSidebar")
          KeepAlive
              component(:is="currentRightTab")

      //- BUILDER page
      drover-panel-tabs(
        v-if="isBuilderView",
        side="left",
        :tabs="tabsLeftBuilderSidebar",
        :active-tab="activeTab",
        :type-chevron="activeTab && activeTab.typeChevron || ''",
        @setWidth="changeSize",
        @on-toggle="openLeftSidebar"
      )
        KeepAlive
          component(:is="currentTab"  :init="treeIsLoaded" :show-full="activeTab && activeTab.showMore" :active-tab="activeTab")

      template-detail(
        v-if="isBuilderView && templateModal"
        :index="templateModal.index")

      modal-node-detail.builder-position-right(
        v-if="isBuilderView && cursorNode"
        :node="cursorNode")

      badge-type-toggle.builder-position-center(
        v-if="isBuilderView")

      //- FINDER page
      search-panel(v-if="isSkillFinderView")

    .available-on-desktop-only(v-if="isBuilderView")
      h1 {{ 'pages.builder.for_desktop_only' | translate }}

</template>

<script>
import { ENV, TREE } from '@/util/constants'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import BaseTreeView from '../tree-view/BaseTreeView'
import ToastsList from './ToastsList'

import DrawerPanel from '@/components/ui/DrawerPanel'
import Goals from '@/views/my/modals/goals/Index'
import ChooseRank from '@/components/modals/builder/ChooseRank'
import TreeElements from '@/components/modals/builder/tree-elements/TreeElements'

import BadgeTypeToggle from '@/components/modals/builder/BadgeTypeToggle'
import TemplateDetail from '@/components/modals/builder/templates/TemplateDetail'
import ModalNodeDetail from '@/components/modals/builder/ModalNodeDetail'

import RecommendedSkillsModal from '@/views/my/modals/RecommendedSkills/RecommendedSkillsModal'
import DroverPanelTabs from '@/components/ui/DroverPanelTabs'

import SearchPanel from '@/views/skill-finder/SearchPanel'
import Favorites from '@/views/my/modals/favorites/index.vue'

import SkillSetupWizard from '@/components/modals/builder/setup-wizard/index.vue'
import Chevron from '@/components/svg/Chevron'
import newBadges from '@/views/my/modals/newBadges/index.vue'
import filterView from '@/components/modals/builder/filter-view/index.vue'
import toolBar from '@/components/common/toolbar/index.vue'

import { initDrop } from '@/util/drag-n-drop.js'

export default {
  name: 'TreeView',

  components: {
    BaseTreeView,
    ToastsList,
    DrawerPanel,
    Goals,
    ChooseRank,
    TreeElements,
    ModalNodeDetail,
    BadgeTypeToggle,
    TemplateDetail,
    RecommendedSkillsModal,
    SearchPanel,
    DroverPanelTabs,
    Favorites,
    SkillSetupWizard,
    Chevron,
    newBadges,
    filterView,
    toolBar
  },

  data: () => ({
    drawers: {
      treeElements: true
    },
    inited: false,
    activeTab: null,
    activeRightTab: null
  }),

  async mounted () {
    this.previousSelectedMultipleRootNode()
    initDrop()
    await this.getBuilderDefaultLook()
    await this.loadDefaultTreeType()
    await this.getDefaultView()
    await this.getDefaultViewToggler()
    await this.loadMatrixList()
    if (this.isBuilderView) {
      this.loadTemplates()
    }
    await this.loadTreeById({ id: null, lazy: true })
    if (this.isLoadLazyTree) {
      this.loadTreeById({ id: null, lazy: false })
    }
    this.inited = true
    if (this.goalId) {
      this.openLeftSidebar(this.tabsLeftSidebar[0])
    }
    if (localStorage.getItem('MULTIPLE_ROOT_NODE') !== null) {
       this.openLeftSidebar(this.tabsLeftBuilderSidebar[2])
    }
  },

  methods: {
    changeSize (size) {
      this.activeTab =
        size.id === 2
          ? { ...this.activeTab, showMore: true }
          : { ...this.activeTab, showMore: false }
    },
    openLeftSidebar (tab) {
      if (this.activeTab && tab.id === this.activeTab.id) {
        this.$store.commit('app/SET_LAST_OPENED_SIDEBAR', tab)
        this.activeTab = null
      } else {
        let checkValidTab = false
        if (this.isTreeMyView) {
          checkValidTab = this.tabsLeftSidebar.some(el => el.title === tab.title)
        } else {
          checkValidTab = this.tabsLeftBuilderSidebar.some(el => el.title === tab.title)
        }

        this.activeTab = checkValidTab ? { ...tab, showMore: false } : this.isTreeMyView ? { ...this.tabsLeftSidebar[0], showMore: false } : { ...this.tabsLeftBuilderSidebar[0], showMore: false }
      }
    },
    openRightSidebar (tab) {
      if (this.activeRightTab && tab.id === this.activeRightTab.id) {
        this.$store.commit('app/SET_LAST_OPENED_SIDEBAR', tab)
        this.activeRightTab = null
        this.closePanleWithNewBadges(false)
      } else {
        const checkValidTab = this.tabsRightSidebar.some(el => el.title === tab.title)
        this.activeRightTab = checkValidTab ? { ...tab, showMore: false } : { ...this.tabsRightSidebar[0], showMore: false }
      }
    },
    ...mapActions('trees', ['loadTreeById', 'loadDefaultTreeType', 'rearrangeTree']),

    ...mapActions('treeView', [
      'setFilterDrawerState',
      'setResultDrawerState',
      'setEmployeeDrawerState',
      'setShowAllAsActivated',
      'setIsRecommendedSkillsDrawerOpened'
    ]),

    ...mapActions('insightsMatrix', ['loadMatrixList']),

    ...mapActions('badges/templates', ['loadTemplates']),

    ...mapActions('preferences', ['getDefaultView', 'getBuilderDefaultLook', 'getDefaultViewToggler']),

    ...mapMutations('trees', { setMultipleRootNode: 'MULTIPLE_ROOT_NODE' }),

    addToFilter (item) {
      this.$emit('addToFilter', item)
    },

    removeFromFilter (item) {
      this.$emit('removeFromFilter', item)
    },
    ...mapMutations('badges/newlyAdded', { closePanleWithNewBadges: 'OPEN_BADGE_PANEL' }),

    async previousSelectedMultipleRootNode () {
       if (localStorage.getItem('MULTIPLE_ROOT_NODE') !== null) {
        const ID = +localStorage.getItem('MULTIPLE_ROOT_NODE')
        await this.setMultipleRootNode(ID)
      }
    }
  },

  computed: {
    ...mapGetters('trees', ['isLoadLazyTree']),

    tabsLeftSidebar () {
      return [
        { id: 0, title: this.$t('pages.my.my_goals'), component: 'Goals' },
        { id: 1, title: this.$t('pages.my.my_favorites'), component: 'Favorites' }
      ]
    },
    tabsLeftBuilderSidebar () {
      return [
      {
        id: 1,
        title: 'Elements',
        component: 'TreeElements'
      },
      {
        id: 0,
        title: 'Explore',
        component: 'SkillSetupWizard',
        typeChevron: 'slider'
      },
      {
        id: 2,
        title: 'pages.builder.structure',
        component: 'filterView',
        typeChevron: 'slider'
      }
    ]
    },
    tabsRightSidebar () {
      const oneItem = { id: 0, title: this.isMyProfile ? this.$t('pages.my.my_profile') : this.$t('pages.profile.profile'), component: 'RecommendedSkillsModal' }
      return this.isEnabledNotification ? [oneItem, { id: 1, title: 'New Badges', component: 'newBadges' }] : [oneItem]
    },
    currentTab () {
      return this.activeTab && this.activeTab.component
    },

    currentRightTab () {
      return this.activeRightTab && this.activeRightTab.component
    },
    ...mapState('trees', ['globalTree', 'treeIsLoaded']),

    ...mapGetters('trees', ['selectedTree']),

    ...mapGetters('preferences', ['isBuilderDefaultLook', 'dinamicTogglView']),

    ...mapGetters('app', ['userInfo']),

    ...mapGetters('nodeTmp', ['cursorNode']),

    ...mapGetters('builder', ['templateModal', 'toggledBuilderLook']),

    ...mapGetters('treeView', [
      'isFilterDrawerOpened',
      'isFilterResultDrawerOpened',
      'isEmployeeDetailDrawerOpened',
      'isRecommendedSkillsDrawerOpened'
    ]),

    ...mapGetters('settings', ['isEnabledNotification']),

    ...mapGetters('badges/newlyAdded', ['isOpenBadgePanel']),

    isNewTree () {
      return this.$route.path.includes('tree')
    },

    isTreeMyView () {
      return (
        this.$route.path.includes('my') || this.$route.path.includes('tree')
      )
    },

    isBuilderView () {
      return this.$route.path.includes('builder')
    },

    isSkillFinderView () {
      return this.$route.path.includes('skill-finder')
    },

    isDevelopment () {
      return ENV.SHOW_DEBUG_TOOLS
    },

    isMyProfile () {
      return this.$route.path.includes('/my')
    },

    goalId () {
      return this.$route.params.goal_id
    }
  },

  watch: {
    $route () {
      this.activeTab = null
      this.activeRightTab = null
      this.$store.commit('app/SET_LAST_OPENED_SIDEBAR', null)
    },
    isBuilderView (value) {
      if (value) {
        this.loadTemplates()
        this.rearrangeTree()
        }
    },

    isSkillFinderView (value) {
      if (value && this.dinamicTogglView.key === TREE.MY_SKILLTREE) {
        this.rearrangeTree()
        }
    },
    toggledBuilderLook (value, oldValue) {
      if (value && oldValue !== null) {
          if (this.toggledBuilderLook.id === 'LIST') this.$router.push({ name: 'BuilderNew' })
          else this.loadTemplates()
      }
    },
    isOpenBadgePanel (value) {
      if (value) {
        this.openRightSidebar(this.tabsRightSidebar[1])
      }
    },
    goalId (value) {
       if (value) {
        this.openLeftSidebar(this.tabsLeftSidebar[0])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/abstracts/_mixins.scss";

@include mobile {
  .panels-layout {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    display: flex;
    flex-direction: column;
  }
}

.available-on-desktop-only {
  display: none;
}

@include desktop {
  .builder-position-left {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .builder-position-right {
    position: absolute;
    top: 20px;
    right: 0px;
  }
  .builder-position-center {
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@include mobile {
  .builder-position-left,
  .builder-position-center,
  .builder-position-right {
    display: none;
  }

  .blured {
    filter: blur(3px);
  }

  .available-on-desktop-only {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 50px);
    width: 100%;
    background: rgba(0, 0, 0, 0.01);
    padding: 0 30px;

    h1 {
      color: $color-primary;
      font-size: 26px;
      text-align: center;
      font-weight: 500;
      background: $color-white;
      padding: 20px;
      border: 1px solid $color-primary;
      border-radius: 4px;
    }
  }
}
</style>
