<template lang="pug">
  .sd-step-files
    v-scrollable
      v-card-head
        h2.title {{ 'ui.labels.files_upload' | translate }}
      v-card-content
        p {{ 'infotext.files_upload_description' | translate }}
        skill-detail-file-upload(
          :badge="badge.files"
          :badge-id="badge.id"
          :is-editable="true"
        )
      v-card-actions
        v-btn(
          text
          @click="onCancel")
          | {{ (isFirst ? 'ui.buttons.cancel' : 'ui.buttons.back') | translate }}
        v-btn.ml-1(
          :disabled="!badge.files.length"
          @click="onAccept")
          | {{ (isLast ? 'ui.buttons.send' : 'ui.buttons.next') | translate }}

</template>

<script>
import SkillDetailFileUpload from './../SkillDetailFileUpload.vue'

export default {
  name: 'SkillDetailStepFileUpload',

  components: {
    SkillDetailFileUpload
  },

  props: {
    badge: Object,
    isFirst: Boolean,
    isLast: Boolean
  },

  data: () => ({ }),

  methods: {
    onCancel () {
      if (this.isFirst) {
        this.$emit('close')
      } else {
        this.$emit('prev')
      }
    },

    onAccept () {
      this.$emit('next')
    }
  }
}
</script>
