<template lang="pug">
  v-dropdown(
    id="notification-modal"
    right
    width-auto
    auto-close
    center-on-mobile)
    .header__topbar-item
      .header__topbar-icon.bell-wrapper
        span.unseen-count(v-show="unseenCount > 0 ") {{ isNewlyBadgesCount > 0 ? unseenCount + 1 : unseenCount}}
        img(src="../../../assets/ui/bell.svg" alt="Bell" v-tooltip="$t('infotext.notifications')")
    v-card.notifincation-dropdown(
      slot="content"
      elevation)
      v-scrollable(
        v-if="notificationPerPage.length || (isEnabledNotification && isNewlyBadgesCount > 0) || trialPeriod"
        :min-height="0"
        :bottom-offset="120")
        notifications-about-new-badges(
          v-if="isEnabledNotification && isNewlyBadgesCount > 0"
          :count="isNewlyBadgesCount"
          :in-panel="false")
        notifications-trial-expire(v-if="trialPeriod" :data-time="trialPeriod")
        .notifications-wrapper
          template(v-for="item in notificationPerPage")
            notifications-results(
              isForDropdown
              :key="item.id"
              :item="item"
              :toggle-id="toggleId"
              @on-toggle-dropdown="onToggleDropdown")
        .notification-do-btn.padding-top-10(
          v-if="notificationPerPage.length")
          span(@click="viewAll") {{ 'ui.notifications.view_all' | translate }}
        .notification-do-btn(
          v-if="notificationPerPage.length")
          span(@click="makeSeenAll") {{ 'ui.notifications.mark_all_as_read' | translate }}
      .empty-list(v-else) {{'ui.labels.list_is_empty' | translate}}

</template>

<script>
import api from '@/api'
import { mapGetters, mapActions } from 'vuex'

import NotificationsResults from './NotificationsResults'
import NotificationsAboutNewBadges from './NotificationsAboutNewBadges.vue'
import NotificationsTrialExpire from './NotificationsTrialExpire.vue'

export default {
  name: 'Notifications',

  components: {
    NotificationsResults,
    NotificationsAboutNewBadges,
    NotificationsTrialExpire
  },

  mounted () {
    this.getNewlyBadges()
    this.onNotificationsPerPageSubscribe()
  },

  data: () => ({
    startSearch: false,
    toggleId: 0
  }),

  methods: {
    ...mapActions('notifications', [
      'onNotificationsPerPageSubscribe',
      'setUnseenCount',
      'setIsSeenMessage'
    ]),
    ...mapActions('badges/newlyAdded', ['getNewlyBadges']),

    onToggleDropdown (id) {
      this.toggleId = id
    },

    makeSeenAll () {
      api.setUnSeenAllNotification()
      this.setIsSeenMessage({ id: null, isSeen: true, all: true })
      this.setUnseenCount(0)
    },

    viewAll () {
      this.$router.push('/feed')
    }

  },

  computed: {
    ...mapGetters('notifications', [
      'notificationPerPage',
      'unseenCount'
    ]),
    ...mapGetters('badges/newlyAdded', ['isNewlyBadges', 'isNewlyBadgesCount']),
    ...mapGetters('settings', ['isEnabledNotification']),
    ...mapGetters('app', ['trialPeriod', 'userInfo'])
  }
}
</script>

<style lang="scss">
  .bell-wrapper {
    position: relative;

    .unseen-count {
      position: absolute;
      background-color: $color-error;
      top:0px;
      left: 50%;
      border-radius:12px;
      font-size:11px;
      font-weight:300;
      height:18px;
      line-height:11px;
      min-width:10px;
      padding: 4px 6px 3px;
      white-space:nowrap;
      color: $color-white;
    }
  }

  .notifincation-dropdown  {
    width: 352px;
  }

  .notifications-wrapper {
    padding-bottom: 10px;

    .padding-top-10 {
      padding-top: 10px;
    }
  }

  .empty-list {
    padding: 15px 10px;
    text-align: center;
  }

  .notification-do-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 12px;

    &:last-child {
      padding-bottom: 20px;
    }

    span {
      font-size: 13px;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        color: $color-primary;
      }
    }

    &.danger{
      span{
        &:hover{
          color: $color-red;
        }
      }
    }
  }
</style>
