
const CHANGE_SIZE_MODAL = 'CHANGE_SIZE_MODAL'

export default {
  namespaced: true,

  state () {
    return {
      fullSizeModal: false
    }
  },

  getters: {
    sizeModal: (state) => state.fullSizeModal
  },

  mutations: {
    [CHANGE_SIZE_MODAL]: (state, value) => {
      state.fullSizeModal = value
    }
   },

  actions: {
    setSizeModal ({ commit }, bool) {
      commit(CHANGE_SIZE_MODAL, bool)
    }
  }
}
