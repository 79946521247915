<template lang="pug">
  validation(
    :badge="badgeSelect"
    :loading="loading"
    :disabled="disabled"
    :selectedUsers="validators"
    @on-change-mode="onChangeMode"
    @on-change-users="onUsersChange")

</template>

<script>
import api from '@/api'
import { VALIDATION_MODE } from '@/util/constants'

import Validation from '../Validation'

export default {
  name: 'BadgeDetailValidation',

  components: {
    Validation
  },

  props: {
    badge: Object,
    form: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    this.loadValidators()
    this.badgeSelect = { ...this.badge }
  },

  data: () => ({
    badgeSelect: {},
    validators: [],
    loading: false
  }),

  computed: {
    onloadUsers () {
      return this.badge.validationTypeId === VALIDATION_MODE.SPECIFIC_USER ||
        this.badge.validationTypeId === VALIDATION_MODE.SUPERVISOR_AND_SPECIFIC_USER
    }
  },

  methods: {
    async loadValidators () {
      this.validators = this.form.validators || []
      if (this.onloadUsers) {
        this.loading = true
        const { data } = await api.badges.validators.list(this.badge.id)
        if (data && data.length) {
          data.forEach(element => {
            element.name = element.firstName + ' ' + element.lastName
          })
          this.validators = data
          this.form.validators = data
        }
        this.loading = false
      }
    },

    onChangeMode (mode) {
      if (this.badge.validationTypeId !== mode) {
        this.badge.validationTypeId = mode
        this.form.validationTypeId = mode
        this.$emit('on-change-mode')
      }
    },

    onUsersChange (users) {
      if (users.length !== this.validators.length) {
        if (users.length > this.validators.length) {
          this.registerValidator(users.map(el => el.id))
        } else if (users.length < this.validators.length) {
          let deletedUserId = null
          for (let item of this.validators) {
            let findedItem = users.find(el => el.id === item.id)
            if (!findedItem) {
              deletedUserId = item.id
              break
            }
          }
          this.deleteValidator(deletedUserId)
        }
        this.validators = [...users]
        this.form.validators = [...users]
      }
    },

    registerValidator (employeeIds) {
      api.badges.validators.subscribe(this.badge.id, { employeeIds })
    },

    deleteValidator (employeeId) {
      api.badges.validators.unsubscribe(this.badge.id, { employeeIds: [employeeId] })
    }
  },

  watch: {
    badge (value) {
      if (value) {
        this.badgeSelect = { ...value }
        this.loadValidators()
      }
    }
  }
}
</script>

<style>
</style>
