<template>
  <svg
    width="44"
    height="80"
    viewBox="0 0 44 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.0773 80C25.7559 77.3063 28.5496 75.2239 33.9731 71.923C36.5502 70.3461 38.6238 68.03 39.9394 65.2588C41.2533 62.6158 41.9584 59.6975 42 56.7295V29.7396L22.0218 26L2 29.7396V56.7322C2.04163 59.7002 2.74667 62.6186 4.06056 65.2615C5.37628 68.0323 7.44929 70.3483 10.0256 71.9257C17.6022 76.528 18.2296 77.3511 22.0773 80Z"
      fill="#E3F6F1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.0734 79C25.5681 76.4061 28.2221 74.4008 33.3744 71.2222C35.8227 69.7037 37.7926 67.4734 39.0425 64.8047C40.2907 62.2597 40.9604 59.4494 41 56.5913V30.6011L22.0207 27L3 30.6011V56.5939C3.03955 59.452 3.70933 62.2623 4.95753 64.8074C6.20747 67.4756 8.17682 69.7058 10.6243 71.2248C17.822 75.6566 18.4181 76.4492 22.0734 79Z"
      stroke="#29A687"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="3 6"
    />
    <path v-for="star in choiceStars" :key="star" fill-rule="evenodd" clip-rule="evenodd" :d="star" fill="#29A687" />
  </svg>
</template>
<script>
const FIVE = [
  'M14.4993 20.2913L17.5894 22L16.9997 18.3814L19.5 15.8198L16.0457 15.292L14.4993 12L12.9543 15.292L9.5 15.8198L12.0003 18.3814L11.4106 22L14.4993 20.2913Z',
  'M21.9993 8.29131L25.0894 10L24.4997 6.38143L27 3.81976L23.5457 3.29199L21.9993 0L20.4543 3.29199L17 3.81976L19.5003 6.38143L18.9106 10L21.9993 8.29131Z',
  'M6.99935 8.29131L10.0894 10L9.49967 6.38143L12 3.81976L8.54567 3.29199L6.99935 0L5.45433 3.29199L2 3.81976L4.50032 6.38143L3.91063 10L6.99935 8.29131Z',
  'M29.4993 20.2913L32.5894 22L31.9997 18.3814L34.5 15.8198L31.0457 15.292L29.4993 12L27.9543 15.292L24.5 15.8198L27.0003 18.3814L26.4106 22L29.4993 20.2913Z',
  'M36.9993 8.29131L40.0894 10L39.4997 6.38143L42 3.81976L38.5457 3.29199L36.9993 0L35.4543 3.29199L32 3.81976L34.5003 6.38143L33.9106 10L36.9993 8.29131Z'
]
const FOUR = [
  'M28.9993 20.2913L32.0894 22L31.4997 18.3814L34 15.8198L30.5457 15.292L28.9993 12L27.4543 15.292L24 15.8198L26.5003 18.3814L25.9106 22L28.9993 20.2913Z',
  'M12.9993 8.29131L16.0894 10L15.4997 6.38143L18 3.81976L14.5457 3.29199L12.9993 0L11.4543 3.29199L8 3.81976L10.5003 6.38143L9.91063 10L12.9993 8.29131Z',
  'M28.9993 8.29131L32.0894 10L31.4997 6.38143L34 3.81976L30.5457 3.29199L28.9993 0L27.4543 3.29199L24 3.81976L26.5003 6.38143L25.9106 10L28.9993 8.29131Z',
  'M12.9993 20.2913L16.0894 22L15.4997 18.3814L18 15.8198L14.5457 15.292L12.9993 12L11.4543 15.292L8 15.8198L10.5003 18.3814L9.91063 22L12.9993 20.2913Z'
]
const THREE = [
  'M14.4993 20.2913L17.5894 22L16.9997 18.3814L19.5 15.8198L16.0457 15.292L14.4993 12L12.9543 15.292L9.5 15.8198L12.0003 18.3814L11.4106 22L14.4993 20.2913Z',
  'M21.9993 8.29131L25.0894 10L24.4997 6.38143L27 3.81976L23.5457 3.29199L21.9993 0L20.4543 3.29199L17 3.81976L19.5003 6.38143L18.9106 10L21.9993 8.29131Z',
  'M29.4993 20.2913L32.5894 22L31.9997 18.3814L34.5 15.8198L31.0457 15.292L29.4993 12L27.9543 15.292L24.5 15.8198L27.0003 18.3814L26.4106 22L29.4993 20.2913Z'
]
const TWO = [
  'M14.4993 20.2913L17.5894 22L16.9997 18.3814L19.5 15.8198L16.0457 15.292L14.4993 12L12.9543 15.292L9.5 15.8198L12.0003 18.3814L11.4106 22L14.4993 20.2913Z',
  'M29.4993 20.2913L32.5894 22L31.9997 18.3814L34.5 15.8198L31.0457 15.292L29.4993 12L27.9543 15.292L24.5 15.8198L27.0003 18.3814L26.4106 22L29.4993 20.2913Z'
]
const ONE = [
  'M21.9993 20.2913L25.0894 22L24.4997 18.3814L27 15.8198L23.5457 15.292L21.9993 12L20.4543 15.292L17 15.8198L19.5003 18.3814L18.9106 22L21.9993 20.2913Z'
]
export default {
  name: 'IconBadgeDottedLine',
  props: {
    level: {
      type: Number,
      default: 1
    }
  },
  computed: {
    choiceStars () {
      let star = null
      switch (this.level) {
        case 1:
          star = ONE
          break
        case 2:
          star = TWO
          break
        case 3:
          star = THREE
          break
        case 4:
          star = FOUR
          break
        case 5:
          star = FIVE
          break
      }
      return star
    }
  }
}
</script>

<style lang="scss"></style>
