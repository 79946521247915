import { GET, PUT } from '@/api/types.js'
import { execute } from '../../utils'

const ENDPOINT_SKILLTREE = 'settings/skilltree/default-look'
const ENDPOINT_BUILDER = 'settings/skilltree/builder/default-look'

export default {
  getDefaultSkilltreeLook () {
    return execute(GET, `${ENDPOINT_SKILLTREE}`)
  },

  setDefaultSkilltreeLook (payload) {
    return execute(PUT, `${ENDPOINT_SKILLTREE}`, payload)
  },

  getDefaultBuilderLook () {
    return execute(GET, `${ENDPOINT_BUILDER}`)
  },

  setDefaultBuilderLook (payload) {
    return execute(PUT, `${ENDPOINT_BUILDER}`, payload)
  }
}
