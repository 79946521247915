import VTable from './VTable'
import VTableHead from './VTableHead'
import VTableBody from './VTableBody'
import VTableRow from './VTableRow'
import VTableSorter from './VTableSorter'

export default {
  VTable,
  VTableHead,
  VTableBody,
  VTableRow,
  VTableSorter
}
