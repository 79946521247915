<template lang="pug">
   div
    profile-info-share(v-if="activeShareModal" @closeShareSlide="setShowShare" :employee="employee")
    .skill-suggestion
      .skill-suggestion__header
        h2.title {{ (isMyProfile ? 'pages.my.my_profile': 'pages.profile.profile') | translate }}
      tabs(:activetab="activetab"
        @tab-change="onTabChange")
      skills-list(
        v-show="activetab === 1"
        :list="getActiveSkills"
        :onLoad="loadActiveSkills"
        )
      skills-list(
        v-show="activetab === 2"
        isSkillProfile
        :list="getSkillProfile"
        :onLoad="loadSkillProfile"
        )
      template(v-if='employee')
        profile-info(v-show="activetab === 3 && employee" :employee="employee" @shareActive="setShowShare")
      template(v-else)
        skills-list(
          v-show="activetab === 3"
          isSuggestions
          :list="getRecommendations"
          :onLoad="onLoadRecommendations"
        )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Tabs from './Tabs'
import SkillsList from './SkillsList'
import ProfileInfo from '@/components/modals/employee/ProfileInfo'
import ProfileInfoShare from '@/components/modals/employee/ProfileInfoShare.vue'

export default {
  components: {
    SkillsList,
    Tabs,
    ProfileInfo,
    ProfileInfoShare
  },
  props: {
    employee: Object
  },

  data: () => ({
    search: '',
    activetab: 1,
    activeShareModal: false
  }),

  computed: {
    ...mapGetters('suggestions',
      ['getRecommendations',
      'getSkillProfile',
      'getActiveSkills']
    ),

    isMyProfile () {
      return this.$route.path.includes('/my')
    }
  },

  methods: {
    ...mapActions('suggestions', [
      'loadActiveSkills',
      'onLoadRecommendations',
      'loadSkillProfile'
    ]),

    onTabChange (tab) {
      this.activetab = tab
    },

    setShowShare () {
      this.activeShareModal = !this.activeShareModal
      this.$emit('setToggleBorder')
    }
  }
}
</script>

<style lang="scss" scoped>
  .skill-suggestion {
    &__header {
      padding: 20px;
      padding-bottom: 18px;
    }
  }
</style>
