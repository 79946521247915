<template lang="pug">
  .drawer-panel__toggler(@click="toggle" :class="`${side}-side`")
    h3(v-if="!value") {{ title | translate }}
      span.drawer-panel__badge_wrapper(v-if="badgeValue && !value")
        v-badge(:value="badgeValue" :color="'orange'")
    .chevron-wrapper(:class="{ toggled: value }")
      chevron(color="#64cbb1" :rotate="value ? 180 : 0")
</template>

<script>
import Chevron from '@/components/svg/Chevron'

export default {
  name: 'DrawerPanelToggler',

  props: {
    title: String,
    side: {
      type: String,
      default: 'left'
    },
    badgeValue: {
      type: Number,
      default: 0
    },
    value: Boolean
  },

  components: {
    Chevron
  },

  methods: {
    toggle () {
      this.$emit('input', !this.value)
    }
  }

}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';

  .drawer-panel__toggler {
    cursor: pointer;
    position: relative;
    padding: 15px 20px;

    h3 {
      position: relative;
      white-space: nowrap;
      color: #666D74;
      font-size: 18px;
      font-weight: 700;
      font-family: "Nunito";
      margin-bottom: 0;
      white-space: nowrap;
    }
      .drawer-panel__badge_wrapper  {
       position: absolute;
       top:-15px;
       right: -15px;
      }
  }

  @include desktop {
    .drawer-panel__toggler {
      margin: 20px 0;

      h3 {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200px;
        text-align: center;
      }

      &.left-side {
        border-left: 1px solid #ccc;

        h3 {
          transform: translateY(-50%) translateX(-50%) rotate(90deg);
        }

        .chevron-wrapper {
          position: absolute;
          right: 50%;
          bottom: 0;
          transform: translateX(50%);
        }
      }

      &.right-side {
        border-right: 1px solid #ccc;

        h3 {
          transform: translateY(-50%) translateX(-50%) rotate(-90deg);
        }

        .chevron-wrapper {
          position: absolute;
          right: 50%;
          bottom: 0;
          transform: translateX(50%) rotate(180deg);
        }
      }
    }
  }

  @include mobile {
    .drawer-panel__toggler {
      .chevron-wrapper {
        position: absolute;
        top: 15px;
        right: 20px;
        transform: rotate(-90deg);
      }
    }
  }
</style>
