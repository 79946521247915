<template lang="pug">
v-dialog(
      width="600"
      :value="show"
      @input="onClose")
  v-card
    v-card-head
      v-card-head-label
        h2.modal-title {{ title | translate }}
      v-card-head-toolbar
        v-btn(
          icon
          gray
          @click="onClose") x
    v-card-content
      .form-group
        label(for="employees") {{ 'ui.labels.select_users' | translate }}
        v-select(
          filterOutside
          :loading="loading"
          :options="searchResultEmployees"
          :value="selectedEmployees"
          @on-change="onChange"
          @search-change="searchEmployees"
          @on-scroll-end="onHandleScroolEnd")

          template(
            slot="before-title"
            slot-scope="{ item }")
            v-avatar(
              :size="40"
              :src="item.profilePictureUrl")
          template(
            v-if="item.hasBadge"
            slot="subtitle"
            slot-scope="{ item }")
            | {{ 'ui.labels._already_has_this_badge' | translate }}

        span.form-text.invalid {{ 'employeeIds' | errorText }}

        template(v-if="selectedEmployees.length")
          scrollable-panel(:max-h="maxContentHeight" :min-h="0")
            v-show-hide
              v-list-item(
                v-for="employee in selectedEmployees"
                :key="employee.id")
                v-avatar.mr-3(
                  :size="40"
                  :src="employee.profilePictureUrl")
                v-list-item-content
                  v-list-item-title {{ employee.name }}
                v-list-item-actions(v-if="!employee.isPreRequired")
                  button.close-btn(
                    @click="onEmployeeRemove(employee)")
                    icon-close(
                      color="#000")
      .empty-list(
          v-show="!selectedEmployees.length")
          | {{ 'ui.labels.list_is_empty'  | translate }}

</template>

<script>
import api from '@/api'
import { mapActions } from 'vuex'

import IconClose from '@/components/svg/IconClose'
import ScrollablePanel from '@/components/ui/ScrollablePanel'

export default {
  name: 'BadgeSuggestToColleague',

  components: {
    IconClose,
    ScrollablePanel
  },

  props: {
    show: Boolean,
    title: String,
    value: {
      type: Array, default: () => []
    }
  },

  data: () => ({
    loading: true,
    selectedEmployees: [],
    searchResultEmployees: [],
    pageNumber: 1,
    search: '',
    scrollRef: null,
    disableScroll: false,
    maxContentHeight: 400
  }),

  mounted () {
    this.selectedEmployees = [...this.value]
    this.loadEmployees()
  },

  destroyed () {
    this.clearErrors()
  },

  methods: {
    ...mapActions('app', [
      'clearErrors'
    ]),

    onChange () {
      this.$emit('on-select', this.selectedEmployees)
    },

    onClose () {
      this.$emit('close')
    },

    onEmployeeRemove (badge) {
      this.selectedEmployees = this.selectedEmployees.filter(el => el.id !== badge.id)
      this.onChange()
    },

    async loadEmployees () {
      if (this.disableScroll) return null
      this.loading = true
      let { data, headers } = await api.onLoadEmployeeForReccomendedBadge(null, this.pageNumber, this.search)
      let parseList = data.map(element => {
        element.employee.name = `${element.employee.firstName} ${element.employee.lastName}`
        element.employee.hasBadge = element.hasBadge
        return element.employee
      })

      parseList = parseList.filter(el => {
        return this.searchResultEmployees.every(item => item.id !== el.id)
      })

      this.searchResultEmployees.push(...parseList)
      if (+headers['x-pagination-page-count'] > this.pageNumber) {
        this.setDibableScroll(false)
      }
      this.loading = false
    },

    async searchEmployees (searchParam) {
      this.setDibableScroll(true)
      this.scrollRef && this.scrollRef.scrollTo({ y: 0 }, 0)
      this.search = searchParam
      this.pageNumber = 1
      let { data } = await api.onLoadEmployeeForReccomendedBadge(null, this.pageNumber, this.search)
      const parseList = data.map(element => {
        element.employee.name = `${element.employee.firstName} ${element.employee.lastName}`
        element.employee.hasBadge = element.hasBadge
        return element.employee
      })
      this.searchResultEmployees = parseList
      this.setDibableScroll(false)
    },

    setDibableScroll (param) {
      this.disableScroll = param
    },

    onHandleScroolEnd (scrollRef) {
      this.pageNumber += 1
      this.scrollRef = scrollRef
      if (!this.disableScroll) {
        this.loadEmployees()
        this.setDibableScroll(true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .empty-list {
    color: $color-inactive-text;
    font-size: 16px;
    padding-bottom: 20px;
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>
