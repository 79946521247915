<template lang="pug">
  v-dialog(
    width="500"
    :value="showDialog")
    v-card
      v-card-head
        h2.modal-title {{ dialogTitle | translate }}
      v-card-content
      v-card-actions
        v-btn(
          text
          @click="discard") {{ 'ui.buttons.no' | translate }}
        v-btn(
          @click="confirm") {{ 'ui.buttons.yes' | translate }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Confirmation',

  methods: {
    ...mapActions('app', [
      'hideConfirmDialog'
    ]),

    async confirm () {
      if (this.confirmDialog.onconfirm &&
        typeof this.confirmDialog.onconfirm === 'function') {
        await this.confirmDialog.onconfirm(this.confirmDialog.params)
      }
      this.hideConfirmDialog()
    },

    discard () {
      this.hideConfirmDialog()
    }
  },

  computed: {
    ...mapGetters('app', [
      'confirmDialog'
    ]),

    dialogTitle () {
      return this.confirmDialog.title
    },

    dialogIsWarning () {
      return this.confirmDialog.warning
    },

    showDialog () {
      return this.confirmDialog.show
    }
  }
}
</script>
