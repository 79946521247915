<template lang="pug">
  .sd-status-icons
    .sd-status-icons__item(
      v-tooltip="$t('file-pond.required_your_skill_profile')"
      v-if="badge.helpers && badge.helpers.isProfileBadge")
      i.far.fa-id-card
      .sd-status-icons__item-status(
        v-if="!badge.isActivated")
        i.icon.icon--warning-orange
    .sd-status-icons__item(
      v-if="badge.isFileRequired"
      v-tooltip="$t('infotext.file_upload_required')")
      i.fas.fa-cloud-upload-alt
    //- .sd-status-icons__item(
    //-   v-if="badge.validationTypeId")
    //-   i.fas.fa-user-lock(
    //-     v-tooltip="{ content: badge.validation ? null : $t('infotext.this_badge_needs_to_be_confirmed_by_massage'), placement: 'bottom-start' }")

</template>

<script>
export default {
  name: 'SkillDetailStatusIcons',

  props: {
    badge: Object
  }
}
</script>

<style lang="scss">
  $sd-status-icon-size: 18px;

  .sd-status-icons {
    position: absolute;
    top: 15px;
    right: 20px;
    display: flex;

    &__item {
      position: relative;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &-status {
        position: absolute;
        right: -7px;
        bottom: 0;
        height: $sd-status-icon-size;
        width: $sd-status-icon-size;

        i {
          height: $sd-status-icon-size;
          width: $sd-status-icon-size;
        }
      }
      i {
      color: #B3B8CE;
      font-size: 18px;
    }
    }
  }
</style>
