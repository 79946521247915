<template lang="pug">
  button.v-menu-item(
    :disabled="disabled"
    @click="$emit('click')")
    slot
</template>

<script>
export default {
  name: 'VMenuItem',

  props: {
    disabled: Boolean
  }
}
</script>

<style lang="scss">
</style>
