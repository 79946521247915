<template lang="pug">
  label.v-checkbox(:class="classes" @change="onChange")
    input.v-checkbox--input( type="checkbox" :value="value" :disabled="disabled")
    .v-checkbox--checkmark(:class="{active: value}")
      tick-icon
    span(v-show="label").v-checkbox--label {{ label }}
</template>

<script>
import tickIcon from '@/components/svg/Checkbox_tickIcon.vue'

export default {
  name: 'VCheckbox',

  components: { tickIcon },

  props: {
    value: [Boolean, Number],
    label: String,
    disabled: Boolean,
    cutLongText: {
      type: Boolean,
      default: true
    },
    cutCountSymbols: {
      type: Number,
      default: 40
    }
  },

  methods: {
    onChange () {
      if (!this.disabled) {
        this.$emit('input', !this.value)
      }
    }
  },

  computed: {
    classes () {
      return {
        'v-checkbox--disabled': this.disabled
      }
    },

    preparedLable () {
      return this.label && this.label.length > this.cutCountSymbols && this.cutLongText
      ? this.label.split('').slice(0, this.cutCountSymbols).join('') + ' ...'
      : this.label
    }
  }
}
</script>

<style lang="scss" scoped>
$size: 16px;
.v-checkbox{
  height: fit-content;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin: 0;
  cursor: pointer;
  &--disabled{
    .v-checkbox--checkmark{
      cursor: no-drop;
    }
  }
  &--input{
    display: none;
  }
  &--label{
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: $h2-title;
  }
  &--checkmark{
    width: $size;
    min-width: $size;
    height: $size;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light-blue;
    border: 1px solid $color-line-dotted;
    border-radius: $border-radius;
    box-sizing: border-box;
    ::v-deep svg{
      display: none;
      width: 10px;
      height: 8px;
    }
    &.active{
      border-color:  $light-blue;
     ::v-deep svg{
        display: block;
      }
    }
  }
}
</style>
