<template lang="pug">
  .sd-training-material(v-if="badge.length")
    h5.skill-detail-title {{ title }}:
    v-show-hide(
      :max-height="90"
      :content-height="badge.length * 30")
      .sd-training-material__list
        .sd-training-material__item(v-for="(item, index) in badge")
          a.sd-training-material__item--data(
            :key="index"
            :href="item.url"
            target="_blank")
            i(:class="setFileTypeIcon(item)")
            span {{ item.name }}
          a(
            download
            :href="item.url")
            i.fa.flaticon-download
</template>

<script>
export default {
  name: 'SkillDetailTrainingMaterial',

  props: {
    badge: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },

  methods: {
    setFileTypeIcon (file) {
      if (file.type.includes('image')) {
        return 'far fa-file-image'
      } else if (file.type.includes('pdf')) {
        return 'far fa-file-pdf'
      }
      return 'far fa-file-archive'
    }
  }

}
</script>

<style lang="scss">
   .sd-training-material {
    margin-top: 15px;
    border-top: 1px solid #eee;
    padding-top: 15px;

    &__item {
      display: flex;
      justify-content: space-between;

      &--data {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 10px;

        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      a {
        color: #777;
        display: flex;
        align-items: center;

        &:hover {
          color: #777;

          span {
            text-decoration: underline;
          }
        }

        i {
          margin-right: 10px;
        }
      }

      &:not(:last-child) {
          margin-bottom: 6px;
      }
    }
  }
</style>
