<template lang="pug">
div(style="position:relative")
    .portlet__head.justify-content-center
      .portlet__head-label
        .portlet__head-title
          h3.text-center Instance
          h5.text-center Please select an instance
    .portlet__body
        v-select(
            :placeholder="$t('ui.dropdown.select_employee')",
            :options="instances",
            track-by="url"
            item-value="code"
            fixed,
            single,
            v-model="selectedInstance"
        )
        .form-controls.m-0
            v-btn(
                name="back",
                data-size="s",
                data-style="slide-up"
                @click="() => $emit('resetStep')"
            ) {{ "ui.buttons.back" | translate }}
            v-btn(
                name="next",
                :disabled="disableContinue",
                data-size="s",
                data-style="slide-up"
                @click="onPresetClick"
            ) {{ "ui.buttons.next" | translate }}
</template>

<script>

export default {
  name: 'AuthLoginSelectInstance',
  props: {
    instances: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    selectedInstance: null
  }),
  methods: {
    onPresetClick () {
        this.$emit('next', this.selectedInstance)
    }
  },
  computed: {
    disableContinue () {
      return !this.selectedInstance
    }
  }
}
</script>
