import { GET, PUT } from '@/api/types.js'
import { execute } from '../../utils'

const ENDPOINT = 'settings/default-view'

export default {
  restore () {
    return execute(GET, `${ENDPOINT}`)
  },

  store (payload) {
    return execute(PUT, `${ENDPOINT}`, payload)
  }
}
