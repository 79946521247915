<template lang="pug">
  .v-list-group
    .v-list-group__header(@click="toggle" :style="stateWrapSlyles" :class="classes")
      .triangle(v-if="hasTriangle" :class="{ 'triangle-toggled': show }")
      .chevron-wrapper(v-if="hasArrows")
        v-chevron(listMode :value="show" color="#64cbb1")
      slot(name="header")

    transition(
      name="v-list-group"
      @before-enter="beforeEnter"
      @enter="enter"
      @bes="beforeLeave"
      @leave="leave")
      .v-list-group__body(
        v-show="show"
        :class="{'v-list-group--transition': this.isAnimated}")
        slot(name="body")

</template>

<script>
import Chevron from '@/components/svg/Chevron'

export default {
  name: 'VListGroup',

  components: {
    Chevron
  },

  props: {
    hasArrows: Boolean,
    hasTriangle: Boolean,
    isHightlited: Boolean,
    isHovered: Boolean,
    openByDefault: Boolean
  },

  mounted () {
    if (this.openByDefault) {
      this.isAnimated = false
      this.show = true
      setTimeout(() => { this.isAnimated = true }, 300)
    }
  },

  data: () => ({
    show: false,
    isAnimated: true
  }),

  methods: {
    toggle () {
      this.show = !this.show
    },

    beforeEnter (el) {
      el.style.height = '0'
    },

    enter (el) {
      el.style.height = el.scrollHeight + 'px'
      setTimeout(() => { el.style.height = 'auto' }, 160)
    },

    beforeLeave (el) {
      el.style.height = el.scrollHeight + 'px'
    },

    leave (el) {
      setTimeout(() => { el.style.height = '0' }, 0)
    }
  },

  computed: {
    stateWrapSlyles () {
      let style = 'padding-left: 10px'
      if (this.hasArrows) {
        style = ''
      } else if (this.hasTriangle) {
        style = 'padding-left: 30px'
      }
      return style
    },

    classes () {
      return {
        'v-list-group--hightlited': this.isHightlited && this.show,
        'v-list-group--hovered': this.isHovered
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .v-list-group {
    width: 100%;
    padding-bottom: 15px;
    border-radius: 6px;

    &__header {
      display: flex;
      width:fit-content;
      align-items: center;
      position: relative;
      padding-left: 35px;
      cursor: pointer;

      .chevron-wrapper {
        display: flex;
        left: 10px;
        position: absolute;
      }

      .triangle {
          display: flex;
          align-items: center;
          left: 12px;
          position: absolute;
          transition: .3s all;
          transform: rotate(180deg);
          cursor: pointer;

        &:before {
            content: '';
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 5px 7px 5px 0;
            border-color: transparent $h2-title transparent transparent;
            display: inline-block;
            vertical-align: middle;
        }
      }

      .triangle-toggled {
         transform: rotate(270deg);
      }
    }

    &__body {
      overflow: hidden;
    }

    &--transition {
      transition: 150ms ease-out;
    }

    &--hightlited {
      background-color: $v-list-hover-color;
    }

    &--hovered {
      &:hover{
        background-color: $v-list-hover-color;
      }
    }
  }
</style>
