<template lang="pug">
     v-dialog(
        id="modal-goal-empty"
        width="520"
        top-offset="60"
        :value="true"
        @input="onClose")
        v-card
            v-card-head
                v-card-head-label
                     h2.modal-title {{ 'pages.my.goal' | translate }}
                v-card-head-toolbar
                     v-btn(
                        icon
                        gray
                        @click="onClose") x
            v-card-content
                v-list-empty
                    i.icon.icon--sm.icon--no-result
                    span {{ 'ui.labels.no_results' | translate }}

</template>
<script>
export default {
    methods: {
        onClose () {
            this.$emit('close')
        }
    }
}
</script>
