<template lang="pug">
  transition(name="fade-up")
    .overlay(
      v-if="show"
      style="z-index: 100"
      @click.self="onCloseByOverlay")
      .modal-dialog
        .modal-content
          .modal-header(v-if="!isLoadingModal")
            div
              h5.modal-title {{ title }}
              span.modal-subtitle(v-if="subtitle") {{ subtitle }}
            button.close(type="button" @click="onClose")
              span(aria-hidden="true") ×
          .modal-body(v-if="hasBodySlot && !isLoadingModal")
            slot(name="body")
          .modal-footer(v-if="!isLoadingModal")
            slot(name="footer")
          .modal-preloader-wrap(v-if="isLoadingModal")
            v-preloader
</template>

<script>
export default {
  name: 'UiModal',

  props: {
    title: String,
    show: Boolean,
    subtitle: String,
    preventOverlayClick: Boolean,
    isLoadingModal: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClose () {
      this.$emit('on-close')
    },

    onCloseByOverlay () {
      if (!this.preventOverlayClick) {
        this.$emit('on-close')
      }
    }
  },

  computed: {
    hasBodySlot () {
      return !!this.$slots['body']
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-content {
    padding: 8px !important;
    width: 100%;

    .modal-header {
      border: none;
      padding: 1.25rem;

      h5 {
        font-size: 20px;
        font-weight: 600;
        color: $color-black;
      }

      .close {
        min-width: 45px;
      }
    }

    .modal-body {
      padding: 2rem 1.25rem;
    }

    .modal-footer {
      border: none;
      padding: 1.25rem;
    }

    .modal-subtitle{
      display: block;
      font-size: 1.25rem;
      line-height: 1.4rem;
    }
  }
  .modal-preloader-wrap {
    position: relative;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
