<template lang="pug">
  .tree-views-toggler(:class="{'expand':!expand, 'hover': hover, 'hide': hide}")
    v-card
      .tree-views-toggler__header(
        @click="toggle"
        v-click-outside="()=>toggled=false")
        icon-skillTree.toolbar-icon
        template(v-show="expand || hover")
          span.tree-views-toggler__header-text {{ dinamicTogglView && dinamicTogglView.name | translate }}
          .tree-views-toggler__header-chevron
            v-chevron(:value="toggled")
      v-list(v-if="toggled")
        template(v-for="item in unselectedOptions")
          v-list-item(@click="onSelect(item)")
            .tree-views-toggler__item {{ item.name | translate }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PREFERENCES_VIEWS_TOGGLER } from '@/util/constants.js'
import iconSkillTree from '@/components/svg/SkillTree.vue'

export default {
  name: 'TreeViewsToggler',
  components: { iconSkillTree },
  props: {
    expand: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    },
    hide: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    toggled: false
  }),

  computed: {
    ...mapGetters('preferences', [
        'dinamicTogglView'
      ]),
    unselectedOptions () {
      return PREFERENCES_VIEWS_TOGGLER
        .filter(item => item.key !== this.dinamicTogglView.key)
    }
  },
 async mounted () {
    this.onSelect(this.dinamicTogglView)
  },

  methods: {
    ...mapActions('trees', [
      'selectMySkilltree',
      'unselectMySkilltree'
    ]),
    ...mapActions('preferences', [
      'setDefaultViewToggler',
      'setDefaultView',
      'changeToggler'
    ]),

    toggle () {
      if (this.expand) {
        this.toggled = !this.toggled
      }
    },
    onClose () {
      this.toggled = false
    },

    onSelect (item) {
      switch (item.id) {
        case 0:
          this.selectMySkilltree()
          break
        case 1:
          this.unselectMySkilltree()
          break
      }
      this.setDefaultViewToggler(item)
      this.changeToggler(item)
      this.onClose()
    }
  }
}
</script>

<style lang="scss">
  .tree-views-toggler {
    overflow: hidden;
    &__header {
      position: relative;
      display: grid;
      grid-template-columns: 20px 1fr 16px;
      align-items: center;
      gap: 14px;
      width: 100%;
      padding: 12px 14px;
      font-weight: 600;
      line-height: 20px;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &-text {
        text-align: center;
      }

      &-chevron {
        height: 100%;
        display: flex;
        align-items: center;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
    }

    &__item {
      width: 100%;
      text-align: center;
    }
  }
</style>
