<template lang='pug'>
v-dropdown.toolbar-search(
    width="100%"
    auto-close
    center-on-mobile
    :class="{'expand':!expand, 'hover': hover, 'hide': hide}"
    :disabled="!search.length"
    ref="toolbarSearch")
    v-card(elevation)
      .toolbar-search--field(@click="focusInput" v-click-outside="blurInput")
        icon-search( classes="toolbar-icon")
        .toolbar-search--input(v-show="expand || hover")
          input.search-form__input(
            ref="toolbarSearchField"
            v-focus
            v-model="search"
            type="text"
            :placeholder="`${$t('ui.labels.search')}...`")
        .icon-input-clear(v-show="search.length && expand && !hide" @click="clearSearch")
            icon-close
    template(slot="content")
      v-card.toolbar-search--content
        v-scrollable(
          v-if="result.length > 0"
          :min-height="50"
          :bottom-offset="80"
          @scroll-complete="onScrollEnd")
          .result-wrapper
            .search-result
              search-result-badges(:items="result" :show-title="false")
        .search__message(v-else)
          | {{ 'ui.labels.no_record_found' | translate}}
</template>

<script>
import api from '@/api'
import SearchResultBadges from '../search/SearchResultBadges'
import iconClose from '../../svg/VInputSearch_CloseIcon'
import iconSearch from '@/components/svg/icons/IconSearch.vue'
export default {
  name: 'searchBar',
  components: {
    SearchResultBadges,
    iconClose,
    iconSearch
  },
  props: {
    expand: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    },
    hide: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: '',
    result: [],
    page: 1,
    blockScroll: false
  }),
  methods: {
    clearSearch () {
      this.search = ''
      this.result = []
      this.$refs.toolbarSearch.$refs.dropdown.__vue__.closeDropdown()
    },

    focusInput () {
      this.$refs.toolbarSearchField.focus()
    },

    blurInput () {
      this.$refs.toolbarSearchField.blur()
    },

   async setSearchData () {
      const params = { q: this.search, page: this.page }
      const { data, headers } = await api.badges.search(params)
      if (data) {
        this.result = this.page > 1 ? [...this.result, ...data] : data
        if (+headers['x-pagination-page-count'] > this.page) {
          this.blockScroll = false
        } else {
          this.blockScroll = true
        }
      }
    },

    onScrollEnd () {
      if (!this.blockScroll) {
        this.blockScroll = true
        this.page++
        this.setSearchData()
      }
    }
  },

  watch: {
    async search (value) {
      if (!value.length) {
        this.clearSearch()
        return
      }
      this.page = 1
      await this.setSearchData()
      this.$refs.toolbarSearch.$refs.dropdown.__vue__.openDropdown()
    },

    expand (value) {
      if (!value) {
        this.clearSearch()
      }
    },

    hide (value) {
      if (value) {
        this.$refs.toolbarSearch.$refs.dropdown.__vue__.closeDropdown()
      }
    }
  }
}
</script>

<style lang='scss'>
.toolbar-search{

  &--field {
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 12px 14px;
  }

  &--input {
    display: flex;
    align-items: center;
    width: 100%;
    line-height: 16px;

    input {
      width: 100%;
      height: 16px;
      padding: 0!important;
      border: 0;
    }
  }
}

.result-wrapper {
  width: 100%;
  margin: 0;
  padding-bottom: 15px;
}

.icon-input-clear {
    min-width: 16px;
    height: 16px;
    transition: all 0.2s;
    opacity: 0.8;
    cursor: pointer;
    svg{
      vertical-align: baseline;
    }

    &:hover {
      opacity: 1;
    }
  }
</style>
