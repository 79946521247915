<template lang="pug">
  span.v-chip(
    :style="styles"
    :class="classes"
    @click="onClick")
    span.v-chip__content
      slot
</template>

<script>
export default {
  name: 'VChip',

  props: {
    color: String,
    colored: Boolean,
    outline: Boolean
  },

  methods: {
    onClick () {
      this.$emit('click')
    }
  },

  computed: {
    styles () {
      let styles = {}
      if (this.colored) {
        styles['--v-chip-bg-color'] = this.color
      }
      return styles
    },

    classes () {
      return {
        'v-chip--outline': this.outline
      }
    }
  }
}
</script>

<style lang="scss">
  @import './_variables.scss';

  .v-chip {
    --v-chip-text-color: #{$color-white};
    --v-chip-bg-color: #{$color-primary};

    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
    padding: 0 12px;
    height: 32px;
    background-color: var(--v-chip-bg-color);
    border: $v-chip-border-width solid var(--v-chip-bg-color);

    &__content {
      color: var(--v-chip-text-color);
    }

    &--outline {
      background-color: transparent;
      border: $v-chip-border-width solid var(--v-chip-bg-color);

      .v-chip__content {
        color: var(--v-chip-bg-color);
      }
    }

    .v-editable__input {
      color: var(--v-chip-text-color);
      background: transparent;
      box-shadow: none;
    }
  }
</style>
