<template lang="pug">
 .sf--search-panel__chips(v-if="badges.length")
    .sf--search-panel__chips--container
      .sf--search-panel__chips--item(v-for="item in badges" :key="item.id")
          span.sf--search-panel__chips--item--text {{ item.name }}
          span.sf--search-panel__chips--item--text(v-if="item.levelName") |
          span.sf--search-panel__chips--item--text(v-if="item.levelName") {{ item.levelName }}
          .sf--search-panel__chips--item--delete(v-tooltip="$t('infotext.remove_badge')"
            @click="$emit('detete', item)")
</template>

<script>

export default {
  name: 'SearchPanelInput',

  props: {
    badges: Array
  }
}
</script>

<style lang="scss" scoped>

.sf--search-panel {
    &__chips {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      &--container {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        gap: 12px;
        flex-wrap: wrap;
        padding: 0;
      }

      &--item {
        display: flex;
        align-items: center;
        padding: 0px 10px;
        background-color: #EBFFFA;
        height: 30px;
        width: fit-content;
        border: 1px solid rgba(82, 63, 105, 0.05);
        border-radius: 3px;

        &--text {
          color: #828282;
          font-size: 13px;
          line-height: 14px;

          &:nth-child(2){
            margin: 0 3px;
            padding-bottom: 1px;
          }
        }

        &--delete {
          margin-left: 15px;
          height: 100%;
          max-height: 14px;
          min-width: 14px;
          min-height: 14px;
          width: 100%;
          max-width: 14px;
          background-color: #20C49B;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          &::before {
            content: "×";
            font-weight: bold;
            width: 6.5px;
            line-height: 12px;
            text-align: center;
            font-size: 12px;
            color:white;
          }

          &:hover {
            background-color: lighten(#20C49B, 3%)
          }
        }
      }
    }
}
</style>
