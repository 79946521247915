import api from '@/api'
import { parseQueryFormObject, parseBadgesArrays, parsaDataToPideChart, flatBadges, flatBadge } from '../helpers'

const SET_GENERAL_STATS = 'SET_GENERAL_STATS'
const SET_UPDATE_REF = 'SET_UPDATE_REF'
const SET_SELECTED_BAR = 'SET_SELECTED_BAR'
const SET_FILTERS = 'SET_FILTERS'
const RESET_FILTERS = 'RESET_FILTERS'
const SET_BAR_CHART = 'SET_BAR_CHART'
const SET_BADGES = 'SET_BADGES'
const SET_MAX_CHART_VALUE = 'SET_MAX_CHART_VALUE'

export default {
  namespaced: true,

  state () {
    return {
      generalStats: null,
      selectedBar: null,
      barChartData: {
        datasets: [],
        labels: []
      },
      maxChartValue: 0,
      updateRef: () => {},
      badges: [],
      isInitialized: false,
      filters: null
    }
  },

  getters: {
    selectedBar: (state) => state.selectedBar,
    generalStats: (state) => state.generalStats,
    barChartData: (state) => state.barChartData,
    maxChartValue: (state) => state.maxChartValue,
    badges: (state) => state.badges,
    filters: (state) => state.filters
  },

  mutations: {
    [SET_BAR_CHART] (state, { data }) {
      for (const key in state.barChartData) {
        state.barChartData[key] = !state.isInitialized ? [...state.barChartData[key], ...data[key]] : data[key]
      }
      state.isInitialized = true
      state.updateRef()
    },

    [SET_BADGES] (state, data) {
      state.badges = data
    },

    [SET_MAX_CHART_VALUE] (state, data) {
      state.maxChartValue = data
    },

    [SET_GENERAL_STATS] (state, data) {
      state.generalStats = data
    },

    [SET_SELECTED_BAR] (state, data) {
      const chartRows = state.barChartData.datasets.length
      const reversedIndexes = Array.from({ length: chartRows }, (v, i) => i).reverse()
      let selectedSubIndexChart = null

      if (data && data.subIndex !== null) {
        if (data.isFromDrawerPanel) {
          selectedSubIndexChart = reversedIndexes[state.badges[data.index][data.subIndex + 1].index]
        } else {
          selectedSubIndexChart = data.subIndex
          data.subIndex = state.badges[data.index].findIndex(el => el.index === reversedIndexes[data.subIndex]) - 1
        }
      }
      state.selectedBar = data

      let firstIndex = 0
      let lastIndexWithvalue = null
      for (const i in state.barChartData.datasets) {
        const dataset = state.barChartData.datasets[i]
        if (data && !dataset.data[data.index] && lastIndexWithvalue === null) {
          firstIndex = +i + 1
        } else if (data && dataset.data[data.index]) {
          if (selectedSubIndexChart !== null && selectedSubIndexChart < +i) {
            if (lastIndexWithvalue === null) {
              lastIndexWithvalue = firstIndex
            }
          } else {
            lastIndexWithvalue = +i
          }
        }
      }

      for (const i in state.barChartData.datasets) {
        const dataset = state.barChartData.datasets[i]

        const setDatasetBorders = () => {
          dataset.borderWidth = Array(data.index).fill(0)
          dataset.borderWidth[data.index] = 2
        }

        if (data) {
          setDatasetBorders()
          if (lastIndexWithvalue === +i && +i === firstIndex) {
            dataset.borderSkipped = false
            dataset.skipVerticalBorders = false
          } else if (firstIndex === +i) {
            dataset.borderSkipped = 'top'
            dataset.skipVerticalBorders = false
          } else if (lastIndexWithvalue === +i) {
            dataset.borderSkipped = 'bottom'
            dataset.skipVerticalBorders = false
          } else if (lastIndexWithvalue > +i && firstIndex < +i) {
            dataset.skipVerticalBorders = true
          } else {
            dataset.borderWidth = 0
            dataset.skipVerticalBorders = false
          }
        } else {
          dataset.borderWidth = 0
        }
      }

      state.updateRef()
    },

    [SET_FILTERS] (state, data) {
      state.filters = data
    },

    [SET_UPDATE_REF] (state, data) {
      state.updateRef = data
    },

    [RESET_FILTERS] (state) {
      state.filters = null
    }
  },

  actions: {
    async loadgeneralStats (context, item) {
      const filters = parseQueryFormObject(context.state.filters || {})
      const { data } = await api.insights.getGoalsStats(filters)
      if (data) {
        data.pieChartData = parsaDataToPideChart(data)
        context.commit(SET_GENERAL_STATS, data)
      }
    },

    async loadInsights (context) {
      const filters = parseQueryFormObject(context.state.filters || {})
      const { data } = await api.insights.getGoals(filters)
      if (data) {
        const chartData = {}
        chartData.labels = data.map(el => el.name)
        const { badges, maxChartValue } = flatBadges(data)
        context.commit(SET_BADGES, badges)
        const parsed = parseBadgesArrays(badges, true)
        chartData.datasets = parsed
        context.commit(SET_BAR_CHART, { data: chartData })
        context.commit(SET_MAX_CHART_VALUE, maxChartValue)
      }
    },

    async  selectBar (context, item) {
      context.commit(SET_SELECTED_BAR, item)
      try {
        const badges = [...context.state.badges]
        if (item && badges[item.index] && badges[item.index][0] && !badges[item.index][0].isLoaded) {
          const filters = parseQueryFormObject(context.state.filters || {})
          const { data } = await api.insights.getGoalsStatsByBadgeId(badges[item.index][0].id, filters)
          if (data) {
            const sorted = [...badges[item.index]].sort((a, b) => a.employeeCount < b.employeeCount ? 1 : -1)
            const flatten = flatBadge(data, true)
            for (const i in sorted) {
              for (const fi in flatten) {
                if (sorted[i].id === flatten[fi].id && !flatten[fi].mainBadge) {
                  flatten[fi].index = +i
                }
              }
            }
            badges[item.index] = flatten
            context.commit(SET_BADGES, badges)
          }
        }
      } catch (e) {

      }
    },

    setUpdateRef (context, ref) {
      context.commit(SET_UPDATE_REF, ref)
    },

    setBadgesLoaded (context, param = false) {
      for (const i in context.state.badges) {
        if (context.state.badges[i] && context.state.badges[i][0]) {
          context.state.badges[i][0].isLoaded = param
        }
      }
    },

    setFilters (context, data) {
      context.commit(SET_FILTERS, data)
      context.commit(SET_SELECTED_BAR, null)
      context.dispatch('setBadgesLoaded', false)
      context.dispatch('loadInsights')
      context.dispatch('loadgeneralStats')
    },

    resetFilters (context) {
      context.commit(RESET_FILTERS)
      context.commit(SET_SELECTED_BAR, null)
      context.dispatch('setBadgesLoaded', false)
      context.dispatch('loadgeneralStats')
      context.dispatch('loadInsights')
    },

    async getEmployeesByBadgeId (context, { badgeId, filters, isAll = false }) {
      let res = {}
      const additionalFilters = parseQueryFormObject(context.state.filters || {})
      if (isAll) {
        res = await api.insights.getGoalsEmployeesByBadgeIdAll(badgeId, { ...filters, ...additionalFilters })
      } else {
        res = await api.insights.getGoalsEmployeesByBadgeId(badgeId, { ...filters, ...additionalFilters })
      }
      return res
    }

  }
}
