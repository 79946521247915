<template lang="pug">
v-dialog.modal-tutorial( :value="show", @input="closeModal")
  v-card
    v-btn.modal-tutorial--close(paddingless, text, @click="closeModal")
      icon-close.m-0
    v-card-content.modal-tutorial--content
      iframe(
        width="100%",
        height="416",
        :src="videoUrl",
        title="YouTube video player",
        frameborder="0",
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
        allowfullscreen
      )
      v-checkbox.modal-tutorial--content_checkbox(
          :label="$t('ui.labels.do_not_show_this_tutorial_again')"
          :value="!showTutorialAfterLogin"
          @input="changeInvisible")
</template>

<script>
import IconClose from '@/components/svg/IconClose.vue'
import { mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  name: 'tutorialModal',
  components: { IconClose },
  props: {
    show: {
        type: Boolean,
        required: true,
        default: false
    }
  },

  computed: {
    ...mapGetters('app', ['showTutorialAfterLogin', 'userInfo']),

    videoUrl () {
      let baseUrl = 'https://www.youtube-nocookie.com/embed/'

      if (this.userInfo?.language?.id === 'de') {
        return baseUrl + 'vsQkHSVIlEU'
      }

      return baseUrl + 'SE2dCtv4N5k'
    }
  },

  methods: {
    ...mapMutations('app', {
        closeTutorial: 'SHOW_TUTORIAL'
    }),
    ...mapActions('app', ['updateShowTutorialAfterLogin']),

    closeModal () {
      this.closeTutorial(false)
    },

    changeInvisible (value) {
      this.updateShowTutorialAfterLogin(!value)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-tutorial {
  padding: 5px;
  &--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &_checkbox{
      margin-top: 15px !important;
    }
  }
  &--close {
    position: relative;
    left: calc(100% - 30px);
    top: 10px;
    &:hover {
      ::v-deep path {
        fill: $body-text;
      }
    }
  }
}
</style>
