import api from '@/api'

const parseMatches = (data, context) => {
  const userMatches = (matches) => {
    const parsedMatches = []
    const matchKeys = ['locations', 'departments', 'badges']
    for (const key of matchKeys) {
      parsedMatches.push(...context.state.selected[key].map((item) => {
        return {
          name: key === 'badges' ? item.displayName : item.name,
          id: item.id,
          isMatch: !!matches[key].find(el => el.id === item.id)
        }
      }))
    }
    return parsedMatches
  }

  for (const i in data) {
    data[i].matches = userMatches(data[i].matches)
    const isNotFullMatch = data[i].matches.find(el => !el.isMatch)
    if (!isNotFullMatch) {
      data[i].isFullMatch = true
    }
  }

  return data
}
const RESET_ADVANCED_FILTERS = 'RESET_ADVANCED_FILTERS'
const SET_DEPARTMENTS = 'SET_DEPARTMENTS'
const SET_LOCATIONS = 'SET_LOCATIONS'
const RESET_BADGES = ' RESET_BADGES'
const ADD_BADGE = 'ADD_BADGE'
const DELETE_BADGE = 'DELETE_BADGE'
const SET_FILTERED_EMPLOYEES = 'SET_FILTERED_EMPLOYEES'
const IS_EMPLOYEES_LOADING = 'IS_EMPLOYEES_LOADING'
const SET_FILTERED_HEADERS = 'SET_FILTERED_HEADERS'
const SET_SEARCH_PAGE = 'SET_SEARCH_PAGE'
const SET_SEARCH_SORT = 'SET_SEARCH_SORT'

export default {
  namespaced: true,

  state () {
    return {
      options: {
        locations: [],
        departments: []
      },
      selected: {
        badges: [],
        locations: [],
        departments: [],
        type: {}
      },
      filteredEmployees: [],
      isEmployeesLoading: false,
      filteredHeaders: {},
      searchEmployeesPage: 1,
      searchEmployeesSort: '-fullMatch'
    }
  },

  getters: {
    options: (state) => state.options,
    selected: (state) => state.selected,
    filteredEmployees: (state) => state.filteredEmployees,
    isEmployeesLoading: (state) => state.isEmployeesLoading,
    filteredHeaders: (state) => state.filteredHeaders,
    searchEmployeesSort: (state) => state.searchEmployeesSort
  },

  mutations: {
    [SET_DEPARTMENTS] (state, options) {
      state.options.departments = options
    },

    [SET_LOCATIONS] (state, options) {
      state.options.locations = options
    },

    [SET_FILTERED_EMPLOYEES] (state, data) {
      state.filteredEmployees = data
    },

    updateSelectedLocations (state, value) {
      state.selected.locations = value
    },

    updateSelectedDepartments (state, value) {
      state.selected.departments = value
    },

    updateSelectedType (state, value) {
      state.selected.type = value
    },

    [RESET_ADVANCED_FILTERS] (state) {
      state.selected.badges = []
      state.selected.locations = []
      state.selected.departments = []
      state.selected.type = {}
    },

    [RESET_BADGES] (state) {
      state.selected.badges = []
    },

    [DELETE_BADGE] (state, item) {
      const index = state.selected.badges.indexOf(item)
      if (index !== -1) {
        state.selected.badges.splice(index, 1)
      }
    },

    [ADD_BADGE] (state, item) {
      const index = state.selected.badges.findIndex(el => item.id === el.id)
      if (index === -1) {
        state.selected.badges.push(item)
      }
    },

    [IS_EMPLOYEES_LOADING] (state, value) {
      state.isEmployeesLoading = value
    },

    [SET_FILTERED_HEADERS] (state, value) {
      state.filteredHeaders = value
    },

    [SET_SEARCH_PAGE] (state, value) {
      state.searchEmployeesPage = value
    },

    [SET_SEARCH_SORT] (state, value) {
      state.searchEmployeesSort = value
    }
  },

  actions: {

    resetAll (context) {
      context.commit(SET_SEARCH_PAGE, 1)
      context.commit(SET_SEARCH_SORT, null)
      context.commit(RESET_ADVANCED_FILTERS)
      context.commit(RESET_BADGES)
      context.commit(SET_FILTERED_EMPLOYEES, [])
      context.commit(SET_FILTERED_HEADERS, {})
    },

    async loadDepartments (context) {
      const { data } = await context.dispatch('departments/loadDepartments', {}, { root: true })
      context.commit(SET_DEPARTMENTS, data)
    },

    async loadLocations (context) {
      const { data } = await context.dispatch('locations/loadLocations', {}, { root: true })
      context.commit(SET_LOCATIONS, data)
    },

    async addBadge (context, item) {
      context.commit(SET_SEARCH_PAGE, 1)
      context.commit(ADD_BADGE, item)
      context.dispatch('searchEmployees')
    },

    async deleteBadge (context, item) {
      context.commit(SET_SEARCH_PAGE, 1)
      context.commit(DELETE_BADGE, item)
      context.dispatch('searchEmployees')
    },

    async searchEmployees (context) {
      context.commit(IS_EMPLOYEES_LOADING, true)
      const params = {
        badgeIds: context.state.selected.badges.map(item => item.id),
        locationIds: context.state.selected.locations.map(item => item.id),
        departmentIds: context.state.selected.departments.map(item => item.id),
        page: context.state.searchEmployeesPage
      }

      if (context.state.selected.type.id === 'FULL') params.fullMatch = 1
      if (context.state.searchEmployeesSort) params.sort = context.state.searchEmployeesSort
      let { error, data, headers } = await api.employees.filter(params)

      if (data) {
        data = parseMatches([...data], context)
        context.commit(SET_FILTERED_EMPLOYEES, context.state.searchEmployeesPage > 1 ? [...context.state.filteredEmployees, ...data] : data)
        context.commit(SET_FILTERED_HEADERS, headers)
      } else if (error) {
        context.commit(SET_FILTERED_EMPLOYEES, [])
        context.commit(SET_FILTERED_HEADERS, {})
      }
      context.commit(IS_EMPLOYEES_LOADING, false)
    },

    onLoadMoreEmployees (context) {
      context.commit(SET_SEARCH_PAGE, context.state.searchEmployeesPage + 1)
      if (+context.state.filteredHeaders['x-pagination-page-count'] >= context.state.searchEmployeesPage) {
        context.dispatch('searchEmployees')
      }
    },

    setSearchPage (context, page = 1) {
      context.commit(SET_SEARCH_PAGE, page)
    },

    setSort (context, sort = null) {
      context.commit(SET_SEARCH_PAGE, 1)
      context.commit(SET_SEARCH_SORT, sort)
      context.commit(SET_FILTERED_EMPLOYEES, [])
      context.dispatch('searchEmployees')
    }
  }
}
