<template functional>
<svg :class="[data.class, data.staticClass]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0348 24C13.6902 22.8028 14.9473 21.8773 17.3879 20.4102C18.5476 19.7094 19.4807 18.68 20.0727 17.4483C20.664 16.2737 20.9813 14.9767 21 13.6575V1.66206L12.0098 0L3 1.66206V13.6587C3.01873 14.9779 3.336 16.2749 3.92725 17.4496C4.51933 18.681 5.45218 19.7104 6.61153 20.4114C10.021 22.4569 10.3033 22.8227 12.0348 24Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: 'IconBadge'
}
</script>

<style scoped></style>
