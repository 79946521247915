<template lang="pug">
  .form-gradational-badge
    gradation-tabs(
      :hideActions="form.template !== null"
      :gradationalBadges="gradationalBadges"
      :activeTabIndex="activeTabIndex"
      @setActiveTabIndex="setActiveTabIndex"
      @didAddGradationalSubBadge="didAddGradationalSubBadge"
      @didRemoveGradationalSubBadge="didRemoveGradationalSubBadge"
      @didMoveGradationalSubBadge="didMoveGradationalSubBadge"
      :max-tabs-number="null")
    .tabs-content
      badge-detail(
        :badge-width="30"
        :index="activeTabIndex"
        :node="node"
        :form="activeTab"
        :pre-required-badges="preRequiredBadges"
        :elevation="elevation"
        @remove="removeBadge"
        @addRequired="addRequiredBadge"
        @removeRequired="removeRequiredBadge")
    .loading-overlay(v-if="isLoading")
      .loading-indicator
</template>

<script>
import { mapActions } from 'vuex'
import { NODE_TYPE } from '@/util/constants'
import GradationTabs from '@/components/ui/GradationTabs'
import BadgeDetail from './badge-detail/BadgeDetail'

export default {
  components: {
    GradationTabs,
    BadgeDetail
  },

  props: {
    form: Object,
    node: Object,
    elevation: Boolean
  },

  data: () => ({
    activeTabIndex: 0,
    preRequiredBadges: [],
    isLoading: false
  }),

  mounted () {
    this.init()
  },

  methods: {
    ...mapActions('trees', [
      'addGradationalSubBadge',
      'removeGradationalSubBadge',
      'moveGradationalSubBadge'
    ]),

    init () {
      if (this.gradationalBadges.length <= this.activeTabIndex) {
        this.setActiveTabIndex(0)
      }
    },

    setActiveTabIndex (index) {
      this.activeTabIndex = index
    },

    async didAddGradationalSubBadge () {
      const props = {
        parentBadgeId: this.lastBadgeProps.id,
        typeId: NODE_TYPE.GRADATIONAL_SUB_BADGE,
        name: this.lastBadgeProps.name + ' Copy'
      }
      this.isLoading = true
      await this.addGradationalSubBadge({ node: this.node, props: props })
      this.init()
      this.isLoading = false
      this.setActiveTabIndex(this.gradationalBadges.length - 1)
    },

    async didRemoveGradationalSubBadge () {
      this.isLoading = true
      await this.removeGradationalSubBadge({ node: this.node, props: this.activeBadgeProps })
      this.init()
      this.isLoading = false
      this.setActiveTabIndex(this.gradationalBadges.length - 1)
    },

    async didMoveGradationalSubBadge (oldIndex, newIndex) {
      this.isLoading = true
      await this.moveGradationalSubBadge({ node: this.node, oldIndex, newIndex })
      this.isLoading = false
      this.setActiveTabIndex(newIndex)
    },

    removeBadge () {
      this.$emit('remove')
    },

    addRequiredBadge (props) {
      this.$emit('addRequired', props)
    },

    removeRequiredBadge (badge) {
      this.$emit('removeRequired', badge)
    }
  },

  computed: {
    activeBadgeProps () {
      let badgeCount = this.gradationalBadges.length
      if (badgeCount === 0 || badgeCount <= this.activeTabIndex) {
        return this.node.props
      }
      return this.gradationalBadges[this.activeTabIndex]
    },

    lastBadgeProps () {
      let badgeCount = this.gradationalBadges.length
      if (badgeCount === 0) {
        return this.node.props
      }
      return this.gradationalBadges[badgeCount - 1]
    },

    activeTab () {
      return this.gradationalBadges[this.activeTabIndex]
    },

    gradationalBadges () {
      return [this.node.props, ...this.node.props.gradationalSubBadges]
    }
  },

  watch: {
    node () {
      // WARNING: DON'T USE "DEEP" HERE!!!
      // IT CRASH THE APP
      this.init()
    }

    // activeTab (value) {
    //   const badges = [this.form, ...this.form.gradationalSubBadges]
    //   const preRequiredBadges = []
    //    if (this.activeTabIndex > 0) {
    //      preRequiredBadges.push(badges[this.activeTabIndex - 1])
    //   }
    //     const result = []
    //     preRequiredBadges.forEach(item => {
    //       const badge = {
    //         isPreRequired: true,
    //         typeId: item.typeId,
    //         starsCount: item.starsCount,
    //         name: item.name,
    //         displayName: item.displayName
    //       }
    //       result.push(badge)
    //     })
    //   this.preRequiredBadges = preRequiredBadges
    // }
  }
}
</script>

<style lang="scss" scoped>
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 5px;

    .loading-indicator {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url('../../../assets/img/preloader.svg') no-repeat;
      background-size: 24px;
    }
  }
</style>
