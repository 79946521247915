<template lang="pug" functional>
  .v-list__item-subtitle(:class="[data.staticClass, data.class]")
    slot
</template>

<script>
export default {
  name: 'VListItemSubtitle'
}
</script>

<style>
</style>
