/* eslint-disable */
import Vue from 'vue'

import Hammer from 'hammerjs'

import { NODE_TYPE } from "./constants"
import { prepearNode } from './badge-tree-helpers'

const rearrangeTreeNew = (tree) => {
  const rearrangeChild = (items, depth = 1, start = 0, sector = 2 * Math.PI) => {
    if (!items.length)
      return items
    const childCount = items.length
    const step = depth === 1
      ? sector / childCount
      : sector / (childCount - 1)
    let angle = start
    items.forEach(item => {
      item.localProps.angle = depth === 2
        ? angle < Math.PI
          ? angle
          : -(Math.PI * 2 - angle)
        : angle
      item.localProps.step = step
      if (item.children.length) {
        const stopValue = item.children.length * 0.2
        const substep = Math.min(Math.PI / 2 - 0.01, stopValue)
        const subsector = item.children.length > 1
          ? 0 - substep / 2
          : 0
        item.children = rearrangeChild(item.children, depth + 1, subsector, substep)
      }
      angle += step
    })
    return items
  }
  tree.children = rearrangeChild(tree.children)
  return tree
}

const rearrangeTree = (tree) => {
  rearrangeTreeNew(tree);

  const badgesDoubleSideHeight = 150,
      badgesSingleSideHeight = 70,
      badgesHorizontalSpacing = 100,
      badgesVerticalSpacing = 30,
      spacing = 50

  if (!tree.props)
    return tree;

  tree.props.pos = { x: 0, y: 0 }

  const hasBadges = (nodes) => {
    for (const i in nodes)
      if (nodes[i].props.typeId !== NODE_TYPE.CATEGORY)
        return true
    return false
  }

  const height = (node) => {
    if (hasBadges(node.children) || node.children.length == 0)
      return node.children.length > 4
        ? badgesDoubleSideHeight
        : badgesSingleSideHeight

    let result = 0;
    for (const i in node.children)
      result += height(node.children[i])

    result += (node.children.length - 1) * spacing

    return result
  }

  const nodesMinY = (nodes) => {
    let nodesHeight = nodes.reduce((acc, node) => acc + height(node), 0)
    nodesHeight += (nodes.length - 1) * spacing
    return tree.props.pos.y - nodesHeight / 2
  }

  const arrangeBadges = (badges, parentPos, isLeftDirection = true) => {
    let upperRow = badges.filter((_, i) => i < 4 || i >= 8 && i % 2 == 0),
        lowerRow = badges.filter((_, i) => i >= 4 && i < 8 || i >= 8 && i % 2 > 0)

    let arrangeRow = (nodes, isUpperSide = true) => {
      nodes.forEach((node, i) => {
        let offset = (i + 1) * badgesHorizontalSpacing,
            x = parentPos.x,
            y = parentPos.y

        x += isLeftDirection ? -offset : offset
        y += isUpperSide ? -badgesVerticalSpacing : badgesVerticalSpacing
        if (node.props.pos) {
          node.props.pos.x = x
          node.props.pos.y = y
        }
      })
    }

    arrangeRow(upperRow)
    arrangeRow(lowerRow, false)
  }

  let arrangeNodes = (nodes, parentPos, minY, isLeftDirection = true, depth = 0) => {
    let currentY = minY

    if (nodes.length === 0) {
      return
    }

    if (hasBadges(nodes)) {
      arrangeBadges(nodes, parentPos, isLeftDirection)
      return
    }

    nodes.forEach(node => {
      let nodeHeight = height(node),
        offset = depth < 2 ? 250 - depth * 70 : 70,
        x = parentPos.x,
        y = currentY + nodeHeight / 2

      x += isLeftDirection ? -offset : offset

      node.props.pos = {
        x: x,
        y: y,
        minY: currentY
      }

      currentY += nodeHeight + spacing
    })

    let ys = nodes.map(node => Math.abs(node.props.pos.y - parentPos.y)),
        radius = Math.max(...ys) * 1.5

    nodes.forEach(node => {
      if (radius == 0) {
        return
      }

      let relativeY = node.props.pos.y - parentPos.y,
          deltaX = radius * Math.cos(Math.asin(relativeY / radius))

      node.props.pos.x = parentPos.x + (isLeftDirection ? -deltaX : deltaX)
    })

    let xs = nodes.map(node => node.props.pos.x),
        minX = Math.min(...xs),
        maxX = Math.max(...xs),
        offset = depth < 2 ? 250 - depth * 70 : 70,
        diff = isLeftDirection ? maxX - parentPos.x + offset : minX - parentPos.x - offset

    nodes.forEach(node => {
      node.props.pos.x -= diff
    })

    nodes.forEach(node => {
      arrangeNodes(node.children, node.props.pos, node.props.pos.minY, isLeftDirection, depth + 1)
      delete node.props.pos.minY
    })
  }

  let nodeWeight = (node, weight = 2) => {
    node.children.forEach(node => {
      weight += node.props.typeId === NODE_TYPE.CATEGORY ? 2 : 1
      weight += nodeWeight(node, 0)
    })
    return weight
  }

  let nodesWithWeights = [],
      pushToLeft = true,
      leftItems = [],
      rightItems = []

  tree.children.forEach(node => {
    let item = {
      node: node,
      weight: nodeWeight(node)
    }
    nodesWithWeights.push(item)
  })

  while (nodesWithWeights.length) {
    let maxWeight = 0
    let maxWeightIndex = 0
    nodesWithWeights.forEach((item, index) => {
      if (maxWeight > item.weight) {
        maxWeight = item.weight
        maxWeightIndex = index
      }
    })

    let item = nodesWithWeights.splice(maxWeightIndex, 1)[0]
    if (pushToLeft) leftItems.push(item.node)
    else rightItems.push(item.node)
    pushToLeft = !pushToLeft
  }

  let leftMinY = nodesMinY(leftItems),
    rightMinY = nodesMinY(rightItems)

  arrangeNodes(rightItems, tree.props.pos, rightMinY, false)
  arrangeNodes(leftItems, tree.props.pos, leftMinY)
}

const prepareTree = (node, level = 0) => {
  prepearNode(node, level)
  node.children.forEach(child => prepareTree(child, level + 1))
  return node
}

const flatArray = tree => {
  let flat = {}

  let nextLevel = (node, parentNode = null) => {
    flat[node.props.id] = node

    if (node.children) {
      node.children.forEach(child => nextLevel(child, node))
    }
  }
  nextLevel(tree)

  return flat
}

const parseVersion = (value) => {
  if (process.env.NODE_ENV === 'development') {
    return value
  }

  let vers = value.split('.')
  let prebuild = +(vers[vers.length - 1].split('-')[0])
  vers.splice([vers.length - 1], 1)
  if (prebuild > 0) {
    vers.push(prebuild + '')
  }
  vers = vers.join('.')
  return vers
}

const removeRepeats = (array, prop) => {
  return array.map(item => {
    return item[prop]
  }).map((item, index, arr) => {
    return arr.indexOf(item) === index && index
  }).filter(item => {
    return array[item]
  }).map(item => {
    return array[item]
  })
}

const addQueryParams = (url, params) => {
  let paramsLine = ''
  for (const key in params) {
    if (params[key].value !== null && params[key].value !== undefined) {
      if (Array.isArray(params[key].value)) {
        for (const item of params[key].value) {
          paramsLine += paramsLine ? '&' : '?'
          paramsLine += `${key}[]=${encodeURIComponent(item)}`
        }
      } else {
        paramsLine += paramsLine ? '&' : '?'
        paramsLine += key + '=' + encodeURIComponent(params[key].value)
      }
    } else if(params[key].value === null) {
      paramsLine += paramsLine ? '&' : '?'
      paramsLine += key
    }
  }
  return url + paramsLine
}

export const windowResizeObserver = (function () {
  let observers = []
  return {
    init () {
      window.onresize = () => {
        for (let item of observers) {
          item()
        }
      }
    },
    subscribe (obs, key) {
      if (!window.onresize) {
        this.init()
      }
      if (typeof obs === 'function') {
        observers.push(obs)
      }
    },
    unsubscribe (obs) {
      let index = observers.indexOf(obs)
      if (index !== -1) {
        observers.splice(index, 1)
      }
    }
  }
}())

const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type:mime });
}

const minZoom = () => {
  return 0.01
  const width = window.screen.width
  let minZoom = 0.2
  if (768 <= width && width < 1024) {
    minZoom = 0.25
  } else if (1024 <= width && width < 1920) {
    minZoom = 0.4
  } else if (1920 <= width && width < 2500) {
    minZoom = 0.5
  } else if (2500 <= width && width < 3500) {
    minZoom = 0.7
  } else if (3500 <= width ) {
    minZoom = 0.8
  }
  return minZoom
}

const customEventsHandler = () => ({
  // Halt all touch events
  haltEventListeners: ['touchstart',
    'touchend',
    'touchmove',
    'touchleave',
    'touchcancel'],

  // Init custom events handler
  init: function (options) {
    let instance = options.instance
    let initialScale = 1
    let pannedX = 0
    let pannedY = 0
    // Init Hammer
    this.hammer = Hammer(options.svgElement)

    this.hammer.get('pinch').set({ enable: true })

    // Handle double tap
    this.hammer.on('doubletap', (ev) => {
      options.instance.zoomIn()
    })

    // Handle pan
    this.hammer.on('panstart panmove', (ev) => {
      if (instance.isPanEnabled()) {
        // On pan start reset panned variables
        if (ev.type === 'panstart') {
          pannedX = 0
          pannedY = 0
        }

        // Pan only the difference
        instance.panBy({ x: ev.deltaX - pannedX, y: ev.deltaY - pannedY })
        pannedX = ev.deltaX
        pannedY = ev.deltaY
      }
    })

    // Handle pinch
    this.hammer.on('pinchstart pinchmove', (ev) => {
      // On pinch start remember initial zoom
      if (ev.type === 'pinchstart') {
        initialScale = instance.getZoom()
        instance.zoomAtPoint(initialScale * ev.scale, { x: ev.center.x, y: ev.center.y })
      }

      instance.zoomAtPoint(initialScale * ev.scale, { x: ev.center.x, y: ev.center.y })
    })

    // Prevent moving the page on some devices when panning over SVG
    options.svgElement.addEventListener('touchmove', (e) => { e.preventDefault() })
  },

  // Destroy custom events handler
  destroy () {
    this.hammer.destroy()
  }
})

const pSBC = (p, c0, c1, l) => {
  let r; let g; let b; let P; let f; let t; let h
  let i = parseInt
  let m = Math.round; let a = typeof (c1) === 'string'
  if (typeof (p) !== 'number' || p < -1 || p > 1 || typeof (c0) !== 'string' || (c0[0] !== 'r' && c0[0] !== '#') || (c1 && !a)) return null
  const pSBCr = (d) => {
    let n = d.length
    let x = {}
    if (n > 9) {
      [r, g, b, a] = d = d.split(',')
      n = d.length
      if (n < 3 || n > 4) return null
      x.r = i(r[3] === 'a' ? r.slice(5) : r.slice(4))
      x.g = i(g)
      x.b = i(b)
      x.a = a ? parseFloat(a) : -1
    } else {
      if (n === 8 || n === 6 || n < 4) return null
      if (n < 6)d = '#' + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : '')
      d = i(d.slice(1), 16)
      if (n === 9 || n === 5) {
        x.r = d >> 24 & 255
        x.g = d >> 16 & 255
        x.b = d >> 8 & 255
        x.a = m((d & 255) / 0.255) / 1000
      } else {
        x.r = d >> 16
        x.g = d >> 8 & 255
        x.b = d & 255
        x.a = -1
      }
    } return x
  }
  h = c0.length > 9
  h = a ? c1.length > 9 ? true : c1 === 'c' ? !h : false : h
  f = pSBCr(c0)
  P = p < 0; t = c1 && c1 !== 'c' ? pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }
  p = P ? p * -1 : p
  P = 1 - p
  if (!f || !t) return null
  if (l) {
    r = m(P * f.r + p * t.r)
    g = m(P * f.g + p * t.g)
    b = m(P * f.b + p * t.b)
  } else {
    r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)
    g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)
    b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5)
  }
  a = f.a
  t = t.a
  f = a >= 0 || t >= 0
  a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0
  if (h) return 'rgb' + (f ? 'a(' : '(') + r + ',' + g + ',' + b + (f ? ',' + m(a * 1000) / 1000 : '') + ')'
  else return '#' + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)
}

const escapeHTML = (unsafe) => {
  return Vue.filter('escapeHTML')(unsafe)
}

export default {
  rearrangeTree,
  rearrangeTreeNew,
  prepareTree,
  flatArray,
  removeRepeats,
  addQueryParams,
  windowResizeObserver,
  dataURLtoFile,
  minZoom,
  customEventsHandler,
  pSBC,
  parseVersion,
  escapeHTML
}
