<template lang="pug">
  .skills-wrapper
    input.search-field(
      type="text"
      :placeholder="'ui.inputs.search_by_name' | translate"
      v-model="search")
    transition(name="fadex")
      div(v-if="loaded")
        v-tabs.mt-3.mb-2(
          centered
          :options="options"
          v-model="selectedTab")
          template(
            slot="item"
            slot-scope="{ item, active }")
            v-badge(
              :color="item.color"
              :show="!!item.count"
              :value="item.count")
              v-tab-item(
                small
                colored
                :color="item.color"
                :active="active")
                | {{ item.title }}
        vue-scroll.mb-5
          template(v-if="options.length")
            v-chip-group(
              :options="categories"
              v-model="selected"
              @add="onSelectItem"
              @remove="onUnselectItem")
        .summary {{ selected.length }}  {{ 'app.onboarding.default.labels._topics_selected' | translate }}

</template>

<script>
import api from '@/api'

import { mapGetters, mapActions } from 'vuex'

import { NODE_TYPE } from '@/util/constants.js'

const getSubCategories = (category, color) => {
  let result = []
  category.subBadges.forEach(element => {
    if (element.typeId === NODE_TYPE.CATEGORY) {
      result.push({
        id: element.id,
        title: element.name,
        color: color
      })
      let subResult = getSubCategories(element, color)
      if (subResult.length) {
        result = result.concat(subResult)
      }
    }
  })
  return result
}

export default {
  name: 'SkillsSelect',

  async mounted () {
    if (!this.currentStepState.options) {
      await this.loadCategories()
    } else {
      this.options = this.currentStepState.options
      this.selected = this.currentStepState.selected
    }
    this.loaded = true
  },

  data: () => ({
    search: '',
    selectedTab: 0,
    options: [],
    selected: [],
    loaded: false
  }),

  methods: {
    ...mapActions('onboarding', [
      'setState'
    ]),

    async loadCategories () {
      const { data } = await api.getBadgesTree()
      this.options = data.badges.subBadges.map(item => {
        const subCategories = getSubCategories(item, item.color)
        return {
          count: 0,
          title: item.name,
          color: item.color,
          subCategories: subCategories
        }
      })
      this.options = this.options
        .filter(item => item.subCategories.length)
    },

    onSelectItem () {
      this.options[this.selectedTab].count++
    },

    onUnselectItem () {
      this.options[this.selectedTab].count--
    }
  },

  computed: {
    ...mapGetters('onboarding', [
      'currentStepState'
    ]),

    categories () {
      if (this.options.length) {
        return this.options[this.selectedTab]
          .subCategories.filter(item => item.title
            .toLowerCase()
            .includes(this.search
              .toLowerCase()))
      }
      return []
    }
  },

  watch: {
    selected (value) {
      this.setState({
        options: this.options,
        selected: value
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .skills-wrapper {
    width: 100%;
    min-height: 40vh;
    max-height: 70vh;
    overflow: hidden;
    padding: 15px 0;

    .search-field {
      width: 100%;
      max-width: 300px;
      border: none;
      border-radius: 4px;
      background-color: #eee;
      margin-top: 5px;
      margin-bottom: 10px;
      padding: 4px 12px;
      font-size: 14px;
    }

    .chips-wrapper {
      min-height: 20vh;
    }
  }

  .skills-select {
    column-count: 3;
    column-gap: 0;
    margin-bottom: 20px !important;

    .skills-select__col {
      display: inline-block;
      width: 100%;
      padding: 0 10px;
      margin-bottom: 20px;
    }
  }
</style>
