<template lang="pug">
  svg(:width="width" :height="height" :viewbox="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg")
    path(fill-rule="evenodd" clip-rule="evenodd" d="M12.7167 0.304867C13.0944 0.711357 13.0944 1.37041 12.7167 1.7769L5.71223 9.31405C4.86227 10.2287 3.48421 10.2287 2.63425 9.31405L0.283321 7.09703C-0.0944404 6.69054 -0.0944404 6.03149 0.28332 5.625C0.66108 5.21851 1.27355 5.21851 1.65131 5.625L4.00224 7.84202C4.09668 7.94364 4.2498 7.94364 4.34424 7.84202L11.3487 0.304867C11.7264 -0.101622 12.3389 -0.101622 12.7167 0.304867Z" :fill="color")
</template>

<script>
export default {
  name: 'IconCheck',
  props: {
    color: {
      type: String,
      default: '#29A687'
    },
    height: {
      type: String,
      default: '12'
    },
    width: {
      type: String,
      default: '16'
    }
  },
  computed: {
    viewBox () {
      return `0 0 ${this.width} ${this.height}`
    }
  }
}
</script>

<style>
</style>
