<template lang="pug">
  v-text(:config="config")
</template>

<script>
const TEXT_HEIGHT = 150
const LINE_HEIGHT = 1.2

export default {
  name: 'TreeCategory',

  props: {
    pos: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    direction: {
      type: Number,
      default: 1
    },
    isBadge: Boolean,
    width: Number,
    depth: Number,
    text: String
  },

  computed: {
    config () {
      return {
        x: this.x,
        y: this.y,
        text: this.text,
        height: this.isBadge ? TEXT_HEIGHT : 'auto',
        width: this.textWidth,
        fontSize: this.fontSize,
        fontStyle: this.weight,
        fontFamily: 'Nunito',
        lineHeight: LINE_HEIGHT,
        fill: 'black',
        align: this.align,
        verticalAlign: 'bottom'
      }
    },

    fontSize () {
      return this.depth < 5
        ? 22 - (this.depth - 1) * 4
        : 10
    },

    align () {
      return this.depth > 1
        ? this.direction < 0
          ? 'right'
          : 'left'
        : 'center'
    },

    x () {
      const x = this.depth === 1 ? (this.pos.x - this.textWidth / 2) : this.pos.x - 5
      return this.direction < 0 ? (x - this.textWidth) : x
    },

    y () {
      if (this.depth === 1) {
        return this.pos.y - 10
      }
      return this.isBadge
        ? this.pos.y + this.fontSize * LINE_HEIGHT - TEXT_HEIGHT
        : this.pos.y - this.fontSize * LINE_HEIGHT
    },

    weight () {
      switch (this.depth) {
        case 1:
          return 700
        case 2:
          return 600
        case 3:
          return 400
        default:
          return 400
      }
    },

    textWidth () {
      return this.width === undefined
        ? Math.max((450 / this.depth), 120)
        : this.width
    }
  }
}
</script>

<style>
</style>
