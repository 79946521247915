<template lang="pug">
  v-scrollable(:min-height="0" :max-height="300" :bottom-offset="60")
    v-card.autocomplete-wrapper(@mouseenter.native="mouseHandler('enter')" @mouseleave.native="mouseHandler('leave')" overflowHidden transparent id="autocomplete-search" v-if="showCard")
      .autocomplete-list
        .v-list__item(@click="selectOption(item)" v-for="item in options" :key="item.id") {{ item.name }}
</template>

<script>
import vDropdownDetachedList from '@/components/ui/v-dropdown-detached-list/vDropdownDetachedList.vue'
import api from '@/api'
import { mapState } from 'vuex'

export default {
  name: 'AutocompleteSearch',

  props: {
    searchText: String
  },

  data: () => ({
    isLoaded: false,
    options: []
  }),

  components: {
    vDropdownDetachedList
  },

  watch: {
    async searchText (newVal, oldVal) {
      if (newVal.length - oldVal.length === 1 || newVal.length - oldVal.length === -1) {
        this.isLoaded = false
        try {
          const { data } = await api.badges.skills.external.search({ q: this.searchText })
          this.options = data ?? []
          this.isLoaded = true
        } catch (e) {
          // TODO add error handler
        }
      } else {
        this.isLoaded = true
        this.options = []
      }
    }
  },

  computed: {
    ...mapState('svgTree', [
      'svgpanzoom'
    ]),

    showCard () {
      return this.isLoaded && this.options.length
    }
  },

  methods: {
    mouseHandler (state) {
      switch (state) {
        case 'enter':
          this.svgpanzoom.disableZoom()
          break
        case 'leave':
          this.svgpanzoom.enableZoom()
          break
      }
    },

    selectOption (item) {
      this.$emit('set-text', item)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/sass/abstracts/_variables.scss';

.autocomplete {
  &-wrapper {
    color: $body-text;
    border-radius: 13px;
    font-size: 12px;
    font-weight: normal;
    background: $color-white;
  }

  &-list {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
}
</style>

<style lang="scss">
.autocomplete {
  &-list {
    .v-list__item:not(:last-child) {
      border: none !important;
    }

    .v-list__item {
      padding: 16px 1rem;
    }
  }
}
</style>
