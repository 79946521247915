<template lang="pug" functional>
  .v-menu
    slot
</template>

<script>
export default {
  name: 'VMenu'
}
</script>

<style lang="scss">
  @import './_variables.scss';

  .v-menu {
    padding: 8px 0;
    display: flex;
    flex-direction: column;

    .v-menu-item {
      width: 100%;
      font-size: 15px;
      color: black;
      padding: 4px 0 4px $v-menu-left-offset;
      border: none;
      text-align: left;
      background-color: white;

      &:hover {
        background-color: rgba(134, 131, 131, 0.15);
      }

      &:disabled,
      &[disabled] {
        opacity: .5;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .v-divider {
      margin-left: $v-menu-left-offset;
      width: calc(100% - #{$v-menu-left-offset});
    }
  }
</style>
