<template lang="pug">
  v-stage.tree-container(
    ref="stage"
    :config="configKonva"
    @wheel="onWheel"
    @dragmove="onDragMove"
    @click.self="onCloseModal")
    v-layer(v-if="tree && tree.props")
      v-group(
        ref="baseGroup"
        :config="configGroup")
        tree-node(
          :node="tree"
          :cursorNode="cursorNode"
          :focusNode="focusNode"
          @click="onSelect"
          @open-modal="onOpenModal"
          @close-modal="onCloseModal")
</template>

<script>
import Konva from 'konva'
import { mapActions, mapGetters } from 'vuex'
import { windowResizeObserver } from '@/util/helpers.js'
import TreeNode from './TreeNode'
import { PREFERENCES_VIEWS_TOGGLER } from '@/util/constants.js'

const SCALE_BY = 1.05
const MIN_ZOOM = 0.1
const MAX_ZOOM = 6

export default {
  name: 'Tree',

  props: {
    tree: {
      type: Object,
      default: () => null
    }
  },

  components: {
    TreeNode
  },

  async mounted () {
    this.onResize()
    windowResizeObserver.subscribe(this.onResize)
    if (this.badgeId !== undefined) {
      this.unselectMySkilltree()
      this.changeToggler(PREFERENCES_VIEWS_TOGGLER[1])
      const node = await this.getNodeBySubbadgeId(this.badgeId)
      this.setCursorNode(node)
    }
  },

  beforeDestroy () {
    windowResizeObserver.unsubscribe(this.onResize)
  },

  data: () => ({
    configKonva: {
      width: 0,
      height: 0,
      draggable: true
    },
    configGroup: {
      x: 0,
      y: 0,
      scale: {
        x: 1,
        y: 1
      }
    },
    zoomShift: {
      x: 0,
      y: 0
    },
    panShift: {
      x: 0,
      y: 0
    }
  }),

  computed: {
    ...mapGetters('nodeTmp', [
      'cursorNode'
    ]),
     ...mapGetters('trees', ['isLoadLazyTree']),

    focusNode () {
      return this.cursorNode ||
        this.tree
    },

    badgeId () {
      return parseInt(this.$route.params.badge_id)
    }
  },

  watch: {
    async badgeId (value) {
      if (value) {
        const node = await this.getNodeBySubbadgeId(value)
        const changeHookOrBadge = node.props.id !== this.badgeId && node?.props?.hookedBadge ? node?.props?.hookedBadge : node
        this.onSelect(changeHookOrBadge)
        this.onOpenModal()
      } else {
         this.onSelect(this.tree)
      }
    }
  },

  methods: {
    ...mapActions('nodeTmp', [
      'setCursorNode',
      'unsetCursorNode'

    ]),

    ...mapActions('trees', [
      'getNodeBySubbadgeId',
      'unselectMySkilltree'
    ]),

    ...mapActions('preferences', [
      'changeToggler'
    ]),

    ...mapActions('badges', [
      'getBadgeById'
    ]),

    onResize () {
      const parent = this.$refs.stage.$parent.$el
      if (parent) {
        const width = parent.offsetWidth
        const height = parent.offsetHeight
        this.configKonva.width = width
        this.configKonva.height = height
        this.configGroup.x = width / 2
        this.configGroup.y = height / 2
      }
    },

    onWheel (e) {
      e.evt.preventDefault()
      const stage = this.$refs.stage.getNode()
      const group = this.$refs.baseGroup.getNode()
      const oldScale = group.scaleX()
      const pointer = stage.getPointerPosition()
      pointer.x -= this.panShift.x
      pointer.y -= this.panShift.y
      const mousePointTo = {
        x: (pointer.x - group.x()) / oldScale,
        y: (pointer.y - group.y()) / oldScale
      }
      const newScale = e.evt.deltaY > 0 ? oldScale * SCALE_BY : oldScale / SCALE_BY
      if (MIN_ZOOM < newScale && newScale < MAX_ZOOM) {
        group.scale({ x: newScale, y: newScale })
        const newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale
        }
        group.position(newPos)
        this.zoomShift.x = group.x() - this.configKonva.width / 2
        this.zoomShift.y = group.y() - this.configKonva.height / 2
        this.onCloseModal()
      }
    },

    onPan (node) {
      if (node) {
        const stage = this.$refs.stage.getNode()
        const group = this.$refs.baseGroup.getNode()
        const scale = group.scaleX()
        const pos = node.localProps.pos
        const x = stage.x()
        const y = stage.y()
        const shiftX = x + pos.x * scale
        const shiftY = y + pos.y * scale
        const tween = new Konva.Tween({
          node: stage,
          duration: 1,
          x: (x - shiftX) - this.zoomShift.x,
          y: (y - shiftY) - this.zoomShift.y,
          easing: Konva.Easings.EaseInOut
        })
        tween.play()
      }
    },

    onDragMove (e) {
      const x = e.evt.movementX
      const y = e.evt.movementY
      this.panShift.x += x
      this.panShift.y += y
      this.$emit('on-pan', { x, y })
    },

   async onSelect (node) {
      if (this.isLoadLazyTree) {
        await this.getBadgeById({ node: node })
      }
      if (this.badgeId !== undefined && this.badgeId !== node.props.id) {
        this.$router.push(`/my/badge/${node.props.id}`)
      } else {
        this.onPan(node)
        this.setCursorNode(node)
      }
    },

    onOpenModal () {
      const group = this.$refs.baseGroup.getNode()
      const scale = group.scaleX()
      this.$emit('open-modal', {
        node: this.cursorNode,
        pos: {
          x: this.configKonva.width / 2 + 20 * scale,
          y: this.configKonva.height / 2 + 100 * scale
        }
      })
    },

    onCloseModal () {
      this.$emit('close-modal')
    }
  }
}
</script>

<style lang="scss">
  .tree-container {
    height: 100%;
    width: 100%;
  }
</style>
