<template lang="pug">
  .badge-type-switch-wrapper
    v-switch(
      icon
      outline
      no-margin
      small
      :label="'pages.builder.show_suggested_badges' | translate"
      label-position="right"
      :value="showSuggestBadges"
      @input="setShowSuggestBadges")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'BadgeTypeToggle',

  methods: {
    ...mapActions('treeView', [
      'setShowSuggestBadges'
    ])
  },

  computed: {
    ...mapGetters('treeView', [
      'showSuggestBadges'
    ])
  }
}
</script>

<style lang="scss">
  .badge-type-switch-wrapper{
    display: flex;
    justify-content: center;
    height: 70px;
    background-color: white;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.15);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0 25px;

    .v-switch__label{
      margin-left: 15px !important;
      font-size: 14px;
    }
  }
</style>
