import { execute, addQueryParams } from '../utils'
import { GET, PUT, POST, DELETE } from '../consts'

const ENDPOINT = 'badges/skill-matrix'
const COLLABORATORS_ENDPOINT = 'collaborators'
const ANALYSIS_ENDPOINT = 'analysis'
const BADGES_ENDPOINT = 'badges'
const EMPLOYEES_ENDPOINT = 'employees'
const DEPARTMENT_ENDPOINT = 'departments'
const LOCATION_ENDPOINT = 'locations'

export default {
  list (params = {}) {
    return execute(GET, addQueryParams(`${ENDPOINT}`, params))
  },

  create (payload) {
    return execute(POST, ENDPOINT, payload)
  },

  copy (id) {
    return execute(POST, `${ENDPOINT}/${id}`)
  },

  getById (id) {
    return execute(GET, `${ENDPOINT}/${id}`)
  },

  updateById (id, payload) {
    return execute(PUT, `${ENDPOINT}/${id}`, payload)
  },

  removeById (id) {
    return execute(DELETE, `${ENDPOINT}/${id}`)
  },

  analysis (id) {
    return execute(GET, `${ENDPOINT}/${id}/${ANALYSIS_ENDPOINT}`)
  },

  listBadges (id) {
    return execute(GET, `${ENDPOINT}/${id}/${BADGES_ENDPOINT}`)
  },

  addBadges (id, payload) {
    return execute(POST, `${ENDPOINT}/${id}/${BADGES_ENDPOINT}`, payload)
  },

  removeBadges (id, payload) {
    return execute(DELETE, `${ENDPOINT}/${id}/${BADGES_ENDPOINT}`, payload)
  },

  listEmployees (id) {
    return execute(GET, `${ENDPOINT}/${id}/${EMPLOYEES_ENDPOINT}`)
  },

  listEmployeesAll (id) {
    return execute(GET, `${ENDPOINT}/${id}/${EMPLOYEES_ENDPOINT}/all`)
  },

  addEmployees (id, payload) {
    return execute(POST, `${ENDPOINT}/${id}/${EMPLOYEES_ENDPOINT}`, payload)
  },

  removeEmployees (id, payload) {
    return execute(DELETE, `${ENDPOINT}/${id}/${EMPLOYEES_ENDPOINT}`, payload)
  },

  listCollaborators (id) {
    return execute(GET, `${ENDPOINT}/${id}/${COLLABORATORS_ENDPOINT}`)
  },

  addCollaborators (id, payload) {
    // EXAMPLE: payload = { employeeIds: [1], typeId: 'FULL_ACCESS' }
    return execute(POST, `${ENDPOINT}/${id}/${COLLABORATORS_ENDPOINT}`, payload)
  },

  removeCollaborators (id, payload) {
    // EXAMPLE: payload = { employeeIds: [1] }
    return execute(DELETE, `${ENDPOINT}/${id}/${COLLABORATORS_ENDPOINT}`, payload)
  },

  setCollaboratorsType (id, payload) {
    // EXAMPLE: payload = { employeeIds: [1], typeId: 'FULL_ACCESS' }
    return execute(PUT, `${ENDPOINT}/${id}/${COLLABORATORS_ENDPOINT}`, payload)
  },

  searchCollaborators (id, payload) {
    return execute('GET', addQueryParams(`${ENDPOINT}/${id}/${COLLABORATORS_ENDPOINT}/search`, payload))
  },

  listDepartments (id) {
    return execute(GET, `${ENDPOINT}/${id}/${DEPARTMENT_ENDPOINT}`)
  },

  listLocation (id) {
    return execute(GET, `${ENDPOINT}/${id}/${LOCATION_ENDPOINT}`)
  },

  removeDepartment (id, payload) {
    return execute(DELETE, `${ENDPOINT}/${id}/${DEPARTMENT_ENDPOINT}`, payload)
  },

  removeLocation (id, payload) {
    return execute(DELETE, `${ENDPOINT}/${id}/${LOCATION_ENDPOINT}`, payload)
  },

  addDepartment (id, payload) {
    return execute(POST, `${ENDPOINT}/${id}/${DEPARTMENT_ENDPOINT}`, payload)
  },

  addLocation (id, payload) {
    return execute(POST, `${ENDPOINT}/${id}/${LOCATION_ENDPOINT}`, payload)
  }
}
