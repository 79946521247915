<template functional>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.01"
      d="M20 26C23.3137 26 26 23.3137 26 20C26 16.6863 23.3137 14 20 14C16.6863 14 14 16.6863 14 20C14 23.3137 16.6863 26 20 26Z"
      fill="white"
    />
    <path
      opacity="0.01"
      d="M0 0V40H40V0H0ZM20 28.5C15.3 28.5 11.5 24.7 11.5 20C11.5 15.3 15.3 11.5 20 11.5C24.7 11.5 28.5 15.3 28.5 20C28.5 24.7 24.7 28.5 20 28.5Z"
      fill="white"
    />
    <path
      d="M20 26C23.3137 26 26 23.3137 26 20C26 16.6863 23.3137 14 20 14C16.6863 14 14 16.6863 14 20C14 23.3137 16.6863 26 20 26Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.85 19C36.675 17.025 34.425 14.125 31.375 11.7C28.35 9.25 24.45 7.25 20 7.25C15.6 7.25 11.7 9.275 8.675 11.7C5.625 14.125 3.325 17.025 2.15 18.975C1.95 19.3 1.95 19.7 2.15 20C3.35 21.975 5.625 24.875 8.675 27.3C11.7 29.75 15.6 31.75 20 31.75C24.4 31.75 28.3 29.75 31.325 27.325C34.375 24.9 36.65 22 37.85 20.025C38.05 19.7 38.05 19.3 37.85 19ZM20 28.5C15.3 28.5 11.5 24.7 11.5 20C11.5 15.3 15.3 11.5 20 11.5C24.7 11.5 28.5 15.3 28.5 20C28.5 24.7 24.7 28.5 20 28.5Z"
      fill="currentColor"
    />
    <path
      d="M20 26C23.3137 26 26 23.3137 26 20C26 16.6863 23.3137 14 20 14C16.6863 14 14 16.6863 14 20C14 23.3137 16.6863 26 20 26Z"
      fill="white"
    />
    <path
      d="M20 26C23.3137 26 26 23.3137 26 20C26 16.6863 23.3137 14 20 14C16.6863 14 14 16.6863 14 20C14 23.3137 16.6863 26 20 26Z"
      fill="currentColor"
    />
  </svg>
</template>

    <script>
export default {
  name: 'IconOpenEye'
}
</script>

    <style>
</style>
