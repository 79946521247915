<template lang="pug">
  v-card.explore
    v-card-head.explore-head.p-0
      h2.title {{'ui.labels.explore' | translate}}
    v-card-content.explore-content.p-0
      .explore-grid
        template(v-if="showFull")
          .explore-column.flex-column
            explore-title(:title="$t(`ui.labels.search`)" step="1")
            explore-search(@selectBadge="selectBadge")
          .explore-column.flex-column(:class="[{ 'border-0 ': !showFull }]")
            explore-title(:title="$t(`ui.labels.detail_of_skill`)" step="2")
            div.explore-description--empty(v-if="!badgeInfo")
              p {{ 'pages.builder.please_select_a_skill_to_view_details' | translate }}
            .explore-description(v-else)
              h5.explore-description--title.title {{ firstLetterUpperCase(badgeInfo.name) }}
              .explore-description--buttons
                v-btn(:disabled="!!isItemAddedToList" @click="addBookmark") {{ 'ui.labels.add_to_bookmark' | translate }}
              .explore-description--templates
                .explore-description--template
                  p {{ 'ui.labels.using' | translate }}
                  explore-drop-list.template-list( :data="myTemplates" badge-type="TEMPLATE" @selectItem="selectTemplate")
                  p.text-lowercase {{ 'ui.labels.template' | translate }}
              .explore-description--desc
                h6 {{ 'ui.labels.description' | translate }}:
                scrollable-panel(:maxH="196")
                  p {{ badgeInfo.description }}
        .explore-column.explore-bookmark(:class="{'p-0':!showFull}")
          explore-title(:title="bookmarkTitle" step="3" :show-step="showFull")
          .explore-bookmark__actions(v-show="selected.length")
            v-checkbox(:value="isAllSelected", @input="onToggleSelectAll")
            span {{ selected.length }} {{ 'ui.labels._items_selected' | translate }}
            v-dropdown(right, fixed, auto-close, width-auto)
              v-btn(icon, gray)
                i.fa.fa-ellipsis-v
              template(slot="content")
                div(style="width: 180px")
                  v-card(elevation)
                    v-list-item(@click="onSelectAll")
                      v-list-item-title {{ 'ui.labels.select_all' | translate }}
                    v-list-item(@click="onUnselectAll")
                      v-list-item-title {{ 'ui.labels.unselect_all' | translate }}
                    v-list-item(@click="onRemoveSelected")
                      v-list-item-title.danger {{ 'ui.labels.remove_selected' | translate }}
          empty-bookmarks(
            v-if="!bookmarks.length"
            :full="showFull"
            @click="expandWindow")
          v-scrollable(v-else :min-height="20" :max-height="!selected.length ? 385 : 264" :bottom-offset="10")
            v-list.badges-list
              v-list-item.badge-item.drag-drop-2(
                v-for="item in bookmarks",
                :key="item.id"
                data-type="bookmark"
                data-rank="badge-template"
                :data-template-id="item.badgeTemplateId"
                :data-id="item.id"
                @mousedown="onSelect({ selected: true, item: item })")
                span(:style="{order: 2}")
                  icon-badge.sw-badge(:level="badgeLevel(item)" :width="24" :height="40" :color="'#A0AAC3'")
                v-checkbox(
                  :value="selected.indexOf(item) !== -1"
                  @input="onSelect({ selected: !(selected.indexOf(item) !== -1), item: item })"
                  :style="{order: 1}")
                v-list-item-content(:style="{order: 3}" v-tooltip="{ content: firstLetterUpperCase(item.name), placement: 'top-start' }")
                  v-list-item-title.badge-title.truncate-text-2
                    | {{firstLetterUpperCase(item.name)}}
                  v-list-item-subtitle.badge-subtitle.truncate-text-2
                    span.badge-subtitle.truncate-text-2(@click="openModalChangeTemplate(item)")
                      span {{ item.templateName || 'ui.labels.none' | translate }}
                      icon-chevron.badge-subtitle__chevron
                v-list-item-actions.badge-actions
                  v-btn(icon, danger, @click.native="deleteBookmark(item)")
                    icon-delete
          .explore-bookmark--footer(v-if="selected.length")
            v-btn(class="add" @click="addInTree" :disabled="!selectedCategory")
              | {{ 'ui.labels.add' | translate }}
            .explore-description--template.p-0.w-100
              p to
              explore-drop-list.categories-list(v-if='categories.length' :data="categories" badge-type="CATEGORY" @selectItem="selectCategory")
      #badge-template-drag-n-drop-fix
    v-dialog(
      :width="320"
      :height="280"
      v-model="showChangeTemplateModal")
      explore-modal-chage-template(
        :templates="myTemplates"
        :selected="selectedBookmark"
        @changeTemplate="changeTemplate"
        @close="showChangeTemplateModal=false")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { prepearNode } from '@/util/badge-tree-helpers'
import { initDrop } from '@/util/drag-n-drop'
import ScrollablePanel from '@/components/ui/ScrollablePanel'
import IconChevron from '@/components/svg/icons/IconChevron.vue'
import IconDelete from '@/components/svg/icons/IconDelete.vue'
import ExploreSearch from './components/ExploreSearch.vue'
import ExploreTitle from './components/ExploreTitle.vue'
import ExploreModalChageTemplate from './components/ExploreModalChageTemplate.vue'
import ExploreDropList from './components/ExploreDropList.vue'
import IconBadge from '@/components/svg/skillWizard/Badge.vue'

export default {
  name: 'ProfileInfoShare',

  components: {
    ScrollablePanel,
    IconChevron,
    IconDelete,
    ExploreSearch,
    ExploreTitle,
    ExploreModalChageTemplate,
    ExploreDropList,
    EmptyBookmarks: () => import('./components/EmptyBookmarks.vue'),
    IconBadge
  },
  props: {
    showFull: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: Object,
      default: () => null
    },
    init: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    badgeInfo: null,
    selectedTemplate: null,
    selectedCategory: null,
    selectedBookmark: null,
    selected: [],
    isAllSelected: false,
    showChangeTemplateModal: false
  }),

  async mounted () {
    await this.getBookmarkSkills()
    await this.loadCategories()
    initDrop(null, null, null, this.addBadgeByTemplate)
  },
  computed: {
    ...mapGetters('badges/bookmarks', ['bookmarks']),
    ...mapGetters('badges/templates', ['templates']),
    ...mapGetters('app', ['categories']),
    ...mapGetters('nodeTmp', ['cursorNode', 'dragEnterNodeId']),
    ...mapGetters('dragNDrop', ['draggingElementType']),

    isItemAddedToList () {
      return this.badgeInfo
        ? this.bookmarks.find(item => item.skillId === this.badgeInfo.id)
        : true
    },
    myTemplates () {
      return [
        {
          name: 'none',
          id: null
        },
        ...this.templates
      ]
    },
    bookmarkTitle () {
      return this.showFull
        ? this.$t(`ui.labels.bookmark`)
        : this.$t(`ui.labels.list_of_added_skills`)
    }
  },

  methods: {
    ...mapActions('badges/templates', ['createSkillWizardBookmark']),
    ...mapActions('badges/bookmarks', [
      'getBookmarkSkills',
      'addBookmarkSkill',
      'deleteBookmarkSkill',
      'updateBookmarkSkill',
      'deleteAllBookmarkSkills'
    ]),
    ...mapActions('app', ['loadCategories']),
    ...mapActions('dragNDrop', ['setDraggingElements']),
    ...mapActions('trees', ['createNode', 'moveNodes', 'getNodeById', 'updateNode']),
    ...mapActions('skillWizard', ['setSizeModal']),

    selectBadge (data) {
      this.badgeInfo = data
    },
    selectTemplate (data) {
      this.selectedTemplate = data
    },

    selectCategory (data) {
      this.selectedCategory = data
    },

    async addBookmark (addToUiBookmark = true) {
      const res = await this.addBookmarkSkill({
        payload: {
          skillId: this.badgeInfo.id,
          badgeTemplateId: this.selectedTemplate?.id
            ? this.selectedTemplate.id
            : null
        },
        addToUiBookmark
      })
      return res
    },

    async deleteBookmark (badge) {
      await this.deleteBookmarkSkill(badge.id)
      this.onSelect({ selected: false, item: badge })
    },
    joinDescription (item) {
      return item.template ? `${item.template.prependDescription}\n\n${item.description || ''}\n\n${item.template.appendDescription || ''}` : item.description
    },

    async addInTree () {
      const payload = {
        parentBadgeId: this.selectedCategory.id,
        bookmarkIds: this.selected.map(el => el.id)
      }
      const resp = await this.createSkillWizardBookmark(payload)

      if (!resp.hasOwnProperty('error')) {
        await this.moveNodes({
          toNode: await this.getNodeById(this.selectedCategory.id),
          nodes: [
            ...resp.data.map(item => {
              item.description = this.joinDescription(item)
              this.updateNode({ node: { props: item }, props: { description: item.description } })
              if (item.gradationalSubBadges.length) {
                item.gradationalSubBadges.forEach(subBadge => {
                  this.updateNode({ node: { props: subBadge }, props: { description: this.joinDescription(subBadge) } })
                })
              }
              return prepearNode(item)
            })
          ]
        })
        this.selected.forEach(el => {
          this.deleteBookmark(el)
        })
        this.onUnselectAll()
      }
    },

    async addInTreeSearch () {
      try {
        let badge = this.bookmarks.find(item => item.skillId === this.badgeInfo.id)
        let payload
        if (!badge) {
          badge = await this.addBookmark(false)
          payload = {
            parentBadgeId: this.selectedCategory.id,
            bookmarkIds: [badge.id]
          }
        } else {
          payload = {
            parentBadgeId: this.selectedCategory.id,
            bookmarkIds: [badge.id]
          }
        }
        const resp = await this.createSkillWizardBookmark(payload)
        if (!resp.hasOwnProperty('error')) {
          await this.moveNodes({ toNode: await this.getNodeById(this.selectedCategory.id), nodes: [prepearNode(resp.data[0])] })
          this.deleteBookmark(this.badgeInfo)
        }
      } catch (e) {}
    },

    changeTemplate (badgeTemplateId) {
      this.updateBookmarkSkill({
        id: this.selectedBookmark.id,
        badgeTemplateId: badgeTemplateId
      })
      this.showChangeTemplateModal = false
    },

    openModalChangeTemplate (data) {
      this.selectedBookmark = data
      this.showChangeTemplateModal = true
    },

    onSelect (data) {
      const index = this.selected
        .map(object => object.id)
        .indexOf(data.item.id)
      if (data.selected) {
        if (index !== -1) return
        this.selected.push(data.item)
      } else {
        if (index !== -1) {
          this.selected.splice(index, 1)
        }
      }
    },

    onToggleSelectAll (value) {
      if (value) {
        this.onSelectAll()
      } else {
        this.onUnselectAll()
      }
    },

    onSelectAll () {
      for (const item of this.bookmarks) {
        const index = this.selected.indexOf(item)
        if (index === -1) {
          this.selected.push(item)
        }
      }
      this.isAllSelected = true
    },

    onUnselectAll () {
      this.selected = []
      this.isAllSelected = false
    },

    onRemoveSelected () {
      for (let item of this.selected) {
        this.deleteBookmark(item)
      }
    },

    async addBadgeByTemplate (id) {
      const payload = {
        parentBadgeId: this.dragEnterNodeId,
        bookmarkIds: [id]
      }
      const resp = await this.createSkillWizardBookmark(payload)
      if (!resp.hasOwnProperty('error')) {
        this.onUnselectAll()
        this.deleteBookmarkSkill(id)
        await this.moveNodes({
          toNode: await this.getNodeById(this.dragEnterNodeId),
          nodes: [
            ...resp.data.map(item => {
              item.description = this.joinDescription(item)
              this.updateNode({ node: { props: item }, props: { description: item.description } })
              if (item.gradationalSubBadges.length) {
                item.gradationalSubBadges.forEach(subBadge => {
                  this.updateNode({ node: { props: subBadge }, props: { description: this.joinDescription(subBadge) } })
                })
              }
              return prepearNode(item)
            })
          ]
        })
      } else {
        this.onUnselectAll()
      }
    },
    firstLetterUpperCase (text = '') {
      return text ? text[0].toUpperCase() + text.substring(1) : text
    },
    badgeLevel (badge) {
      const item = this.myTemplates.find(el => el.id === badge.badgeTemplateId)
      return item.hasOwnProperty('levels') ? item.levels.length : 0
    },

    expandWindow () {
      this.setSizeModal(true)
    }
  },

  watch: {
    init (value) {
      if (value) {
        initDrop(
          this.addCategory,
          this.addBadge,
          this.addGradationalBadge,
          this.addBadgeByTemplate
        )
      }
    },
    selected (value) {
      this.setDraggingElements(value)
    },

    categories (value) {
      if (value.length) this.selectCategory(value[0])
      else this.selectCategory(null)
    },

    templates (value) {
      if (value.length) this.selectTemplate(value[0])
      else this.selectTemplate(null)
    },

    activeTab (value) {
      initDrop(null, null, null, this.addBadgeByTemplate)
    }
  }
}
</script>

<style lang="scss" scoped>
.explore {
  max-height: 521px;
  height: 521px;
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
  box-sizing: border-box;

  &-head {
    margin-bottom: 24px;
  }

  &-content {
    height: 100%;
  }

  &-grid {
    display: flex;
    height: 100%;
  }

  &-column {
    width: calc(276px + 16px);
    box-sizing: border-box;

    &:first-child {
      padding-right: 16px;
    }

    &:nth-child(2) {
      width: calc(276px + 32px + 2px);
      padding-left: 16px;
      padding-right: 16px;
      border-right: 1px solid $color-line-dotted;
      border-left: 1px solid $color-line-dotted;
    }

    &:last-child {
      padding-left: 16px;
    }
  }

  .badge-item {
    gap: 12px;
    border: none !important;
    background: url("~@/assets/img/line.svg") bottom center/100% no-repeat;
    &.active {
      .v-list__item-title {
        color: $color-dark-green;
      }
    }

    &:hover {
      .badge-actions {
        display: block !important;
      }
    }
  }

  .badge-title {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: $body-text;
  }

  .badge-subtitle {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    color: $body-text-2;
    cursor: pointer;

    &__chevron {
      width: 8px;
      height: 8px;
    }
  }

  .badge-actions {
    order: 4;
    display: none;

    .v-btn--icon {
      width: 24px;
      height: 24px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  // column # 3
  &-description {
    &--title {
      margin-bottom: 16px;
    }

    &--empty {
      height: 100%;
      max-width: 200px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: $body-text-2;
      }
    }

    &--buttons {
      margin-bottom: 12px;
      ::v-deep .v-btn {
        background-color: $color-dark-green;
        padding: 16px 24px;

        &__content {
          font-size: 16px;
          line-height: 16px;
          font-weight: 400;
        }
      }
    }

    &--templates {
      border-bottom: 1px solid $color-line-dotted;
      margin-bottom: 16px;
    }

    &--template {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-bottom: 16px;

      p {
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        color: $body-text;
        margin: 0;
        &.text-lowercase {
          text-transform: lowercase;
        }
      }
      .template-list,
      .categories-list{
        max-width: 100%;
        display: inline-grid;
      }
    }

    &--desc {
      h6 {
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        color: $h2-title;
        margin-bottom: 8px;
      }

      p {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: #252F39;
      }
    }
  }

  // column #1
  &-bookmark {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    &__title {
      position: absolute;
      top: -45px;
    }

    &__actions {
      padding: 12px 4px 12px 8px;
      display: flex;
      align-items: center;

      .v-dropdown {
        margin-left: auto;
      }
    }
    .badge-item {
      padding: 12px 4px 12px 8px;
      height: 64px;
      gap:16px;

      .v-list__item-content {
        gap: 4px;
      }
    }

    &--footer{
      display: flex;
      gap:16px;
      margin-top: auto;
    }
    .add{
      background-color: $color-dark-green;
      padding: 16px 24px;
    }

    .add ::v-deep .v-btn {

      &__content {
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
      }
    }
  }
}

.truncate-text-2 {
  max-width: 152px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
