<template lang="pug">
.notifications-new-badges
  .notifications-new-badges--container
    .notifications-new-badges--icon
      notifications-trial-expire-icon
    .notifications-new-badges--text
      h5.notifications-new-badges--text_title
        | {{ $t('ui.modals.your_trial_will_expire', { days, hours}) }}
      .notifications-new-badges--text_action
        .notification-do-btn.p-0
          span(@click="openTutorial") {{ $t('ui.buttons.tutorial') }}
        .notification-do-btn.p-0
          span.upgrade(@click="doUpgrate") {{ $t('ui.buttons.upgrade') }}
</template>

<script>
import NotificationsTrialExpireIcon from '@/components/svg/NotificationsTrialExpireIcon.vue'
import mixinTrialPeriod from '@/mixins/trial-period.js'
export default {
  name: 'NotificationsTrialExpire',
  components: { NotificationsTrialExpireIcon },
  mixins: [mixinTrialPeriod]
}
</script>

<style lang="scss" scoped>
.notifications-new-badges {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 20px;
  background: $color-white;
  margin-right: 8px;

  &--container {
    display: flex;
    gap: 20px;
    background: $website-color;
    border-radius: 4px;
    padding: 16px;
  }

  &--icon {
    max-width: 60px;
    min-width: 60px;
    height: 64px;
  }

  &--text {
    &_title {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: $h2-title;
      margin-bottom: 12px;
    }

    &_action {
      display: flex;
      justify-content: space-between;
      gap: 5px;
    }
  }

  .notification-do-btn {
    width: fit-content;
    .upgrade {
      color: #f68a37;
      &:hover {
        color: #e96b0b;
      }
    }
  }
}
</style>
