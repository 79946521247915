<template lang="pug">
  transition(
    name="slide-right"
    mode="out-in")
    auth-login(
      v-if="step === 1"
      :step="step",
      :login="login",
      :loading="loading"
      :keep-logged="keep_logged"
      @on-loading="setLoading"
      @login-preset="loginPreset"
      @change-loggin="changeLogginCheckbox")
    auth-login-password(
      v-else-if="step === 2"
      :step="step",
      :login="login",
      :loading="loading"
      :keep-logged="keep_logged"
      @go-back="goBack"
      @change-loggin="changeLogginCheckbox")
    forgot-password-confirm(
      v-else-if="step === 3",
      :selectedInstance="selectedInstance"
      :isSetNewPasswordNeeded="isSetNewPasswordNeeded"
      @next="nextStep",
      @resetStep="resetStep"
      :setNewPasswordTitle="true")
    forgot-password-set-new(
      v-else-if="step === 4"
      :selectedInstance="selectedInstance"
      :setNewPasswordTitle="true",
      @resetStep="resetStep")
    auth-login-select-instance(
       v-else-if="step === 6"
       :instances="instances"
       @resetStep="resetStep"
       @next="nextStepAfterInstance($event)",
    )

</template>

<script>
import api from '@/api'
import { ENV } from '@/util/constants'
import { mapActions, mapGetters } from 'vuex'

import AuthLogin from './AuthLogin'
import ForgotPasswordConfirm from './forgot/ForgotPasswordConfirm'
import ForgotPasswordSetNew from './forgot/ForgotPasswordSetNew'
import AuthLoginPassword from './AuthLoginPassword'
import AuthLoginSelectInstance from './AuthLoginSelectInstance.vue'

export default {
  name: 'AuthLoginWrap',

  mounted () {
    this.clearErrors()
    this.email = this.loginEmail
  },

  components: {
    AuthLogin,
    AuthLoginPassword,
    ForgotPasswordConfirm,
    ForgotPasswordSetNew,
    AuthLoginSelectInstance
  },

  data: () => ({
    loading: false,
    step: 1,
    isSetNewPasswordNeeded: false,
    keep_logged: false,
    instances: [],
    selectedInstance: null,
    email: ''
  }),

  methods: {
    ...mapActions('app', [
      'initApp',
      'clearErrors',
      'setPasswordResetCode',
      'setLoginEmail'
    ]),

    async login (email, password, keepLogged) {
      this.loading = true
      this.email = email
      this.clearErrors()
      if (this.step === 1) {
        await this.loginCheck(email)
      } else if (this.step === 2) {
        await this.singIn(email, password, keepLogged)
      }
      this.loading = false
    },

    async loginCheck (email) {
      const data = {
        email: email
      }
      const result = await api.loginCheck(data)

      if (!result) return null
      if (result.hasOwnProperty('instances') && result.instances.length) {
        this.instances = result.instances
        this.step = 6
      } else if (result.isSetNewPasswordNeeded) {
        this.isSetNewPasswordNeeded = true
        const result = await this.resetPassword(email)
        if (result) {
          this.step = 3
        }
      } else {
        this.isSetNewPasswordNeeded = false
        this.step = 2
      }
      return true
    },

    async singIn (email, password, keepLogged = false) {
      const data = {
        email: email,
        password: password
      }

      if (this.selectedInstance) {
        data.instanceCode = this.selectedInstance.code
      }
      const response = await api.login(data, keepLogged)
      if (response) {
        await this.initApp()
        this.$router.push('/my')
      } else {
        if (this.step !== 2) this.step = 2
        this.password = ''
      }
      this.loading = false
      return true
    },

    async resetPassword (email) {
      this.loading = true
      let payload = {
        email: email
      }
      if (this.selectedInstance) {
        payload.instanceCode = this.selectedInstance.code
      }
      let { error, data } = await api.passwordReset(payload)
      if (!error) {
        if (data && data.resetPasswordCode) {
          this.setPasswordResetCode(data.resetPasswordCode)
        }
        return true
      }
      return false
    },

    async loginPreset (email, password) {
      this.loading = true

      const result = await api.loginCheck({ email: email })

      if (!result) {
        this.loading = false
        return null
      }
      if (result.isSetNewPasswordNeeded) {
        this.isSetNewPasswordNeeded = true
        const result = await this.resetPassword(email)
        if (result) {
          this.step = 3
        }
      } else {
        this.isSetNewPasswordNeeded = false
        await this.singIn(email, password)
      }
      this.loading = false
    },

    setLoading (param) {
      this.loading = param
    },

    nextStep () {
      this.step++
    },

    resetStep () {
      this.step = 1
    },

    goBack () {
      this.step--
    },

    changeLogginCheckbox (payload) {
      this.keep_logged = payload
    },

   async nextStepAfterInstance (payload) {
      this.selectedInstance = payload

      if (payload.isSetNewPasswordNeeded) {
        this.isSetNewPasswordNeeded = true
        const result = await this.resetPassword(this.email)
        if (result) {
          this.step = 3
        }
      } else {
        this.isSetNewPasswordNeeded = false
        this.step = 2
      }
    }
  },

  computed: {
    ...mapGetters('app', ['loginEmail', 'errors', 'isThirdPartyLoginEnabled']),

    isEmailInvalid () {
      return this.errors.find(item => item.field === 'email')
    },

    isPasswordInvalid () {
      return this.errors.find(item => item.field === 'password')
    },

    isSupportModeEnabled () {
      return ENV.IS_SUPPORT_MODE_ENABLED
    }
  },

  watch: {
    email (value) {
      this.setLoginEmail(value)
    }
  }
}
</script>

<style lang="scss" >
  h5 {
    color:#666D74
  }
</style>
