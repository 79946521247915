
<template>
<svg :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="9.25" :stroke="color" stroke-width="3.5"/>
</svg>
</template>
<script>
export default {
  name: 'IconBadge',
  props: {
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    },
    color: {
      type: String,
      default: '#29A687'
    }
  }
}
</script>

<style lang="scss"></style>
