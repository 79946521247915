import {
  GET_SKILL_NODE,
  GET_ITEM_BY_ID,
  GET_ITEM_BY_ID_X,
  GET_ITEM_BY_PATH
} from '@/util/v-table-tree.js'

import {
  NODE_TYPE
} from '@/util/constants.js'

const SET_ITEMS = 'SET_ITEMS'
const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM'
const CREATE_CHILD_ITEM = 'CREATE_CHILD_ITEM'

export default {
  namespaced: true,

  state () {
    return {
      items: [],
      selected: null
    }
  },

  getters: {
    items: (state) => state.items,
    selected: (state) => state.selected,
    arrAllGlobalTree (state) {
      const arr = []
      const add = (array) => {
          arr.push({
            id: array.data.id,
            displayName: array.data.displayName,
            parentId: array.data.parentId,
            children: array.children,
            color: array.data.color,
            typeId: array.data.typeId
          })
          for (let el of array.children) {
            add(el)
          }
        }
        add(state.items[0].children[0])
      return arr
    }
  },

  mutations: {
    [SET_ITEMS] (state, data) {
      state.items = data
    },

    [SET_SELECTED_ITEM] (state, data) {
      state.selected = data
    },

    [CREATE_CHILD_ITEM] (state, { toNode, newNode }) {
      const itemData = GET_ITEM_BY_PATH(state.items, toNode.path)
      if (itemData.node) {
        itemData.node.isExpanded = true
        itemData.node.children.push(newNode)
      }
    }
  },

  actions: {
    async loadSkills ({ dispatch, commit, rootGetters }, { lazy = false }) {
      const data = await dispatch('trees/loadTreeById', { id: null, lazy: lazy }, { root: true })

      const root = {
        props: {
          id: null,
          typeId: 'CATEGORY'
        },
        children: [data]
      }

      commit(SET_ITEMS, [GET_SKILL_NODE(root)])
    },

    async addItem (context, { toNode, typeId }) {
      const node = await context.dispatch('trees/getNodeById', toNode.data?.id || toNode.props?.id, { root: true })
      if (context.rootGetters['trees/isLoadLazyTree']) {
        await context.dispatch('badges/getBadgeById', { node: node }, { root: true })
       }
      const name = `${node.props.name} (${node.children.length + 1})`
      const newNode = {
        data: {
          id: -1,
          parentId: toNode.data.id,
          name: name,
          typeId: typeId,
          isNew: true,
          errorText: null
        },
        children: [],
        isEditing: true,
        isLeaf: typeId !== NODE_TYPE.CATEGORY
      }
      context.commit(CREATE_CHILD_ITEM, { toNode, newNode })
    },

    async updateItem ({ state }, item) {
      const node = item.isNew
        ? GET_ITEM_BY_ID(state.items, -1)
        : GET_ITEM_BY_ID(state.items, item.id)
      item.isNew = false
      if (node) {
        node.isEditing = false
        for (const key in item) {
          node.data[key] = item[key]
        }
      }
    },

    async removeItem ({ state, commit }, item) {
      if (state.items.length) {
        const { node, items } = GET_ITEM_BY_ID_X(state.items, item.id)
        const index = items.indexOf(node)
        if (index !== -1) {
          items.splice(index, 1)
        }
      }
      commit(SET_SELECTED_ITEM, null)
    },

    async selectItem ({ commit, dispatch, rootGetters }, item) {
      if (item) {
        // if badge templates not loaded
        if (!rootGetters['badges/templates/templates'].length) {
          // wait for loading
          await dispatch('badges/templates/loadTemplates', null, { root: true })
        }
        // and then get node (node detail modal require templates be loaded)
        const node = await dispatch('trees/getNodeById', item.data?.id || item.props?.id, { root: true })
        if (rootGetters['trees/isLoadLazyTree']) {
          dispatch('badges/getBadgeById', { node: node }, { root: true })
        }

        commit(SET_SELECTED_ITEM, node)
      } else {
        commit(SET_SELECTED_ITEM, item)
      }
    }
  }
}
