import { MODAL_TYPES } from '@/util/constants'

const SET_CONFIRM_MODAL = 'SET_CONFIRM_MODAL'
const UNSET_CONFIRM_MODAL = 'UNSET_CONFIRM_MODAL'

const SET_FORBIDDENT_MODAL = 'SET_FORBIDDEN_MODAL'
const UNSET_FORBIDDEN_MODAL = 'UNSET_FORBIDDEN_MODAL'

export default {
  namespaced: true,

  state () {
    return {
      confirm: {
        show: false,
        content: {
          title: null,
          subtitle: null
        },
        props: {
          vertical: false,
          type: MODAL_TYPES.WARNING_RED
        },
        buttons: {
          cancel: {
            label: 'ui.buttons.cancel',
            callback: null
          },
          apply: {
            label: 'ui.buttons.apply',
            callback: null,
            params: null
          }
        }
      },
      forbidden: {
        show: false,
        title: null,
        message: null,
        type: MODAL_TYPES.DANGER,
        unlinkTitle: null,
        unlinkSubtitle: null,
        unlinkOptions: [],
        deleteCallback: null
      }
    }
  },

  getters: {
    confirmModal: (state) => state.confirm,
    forbiddenModal: (state) => state.forbidden
  },

  mutations: {
    [SET_CONFIRM_MODAL] (state, data) {
      if (data.content) {
        if (data.content.title !== undefined) {
          state.confirm.content.title = data.content.title
        }
        if (data.content.subtitle !== undefined) {
          state.confirm.content.subtitle = data.content.subtitle
        }
      }
      if (data.props) {
        if (data.props.type !== undefined) {
          state.confirm.props.type = data.props.type
        }
        if (data.props.vertical !== undefined) {
          state.confirm.props.vertical = data.props.vertical
        }
      }
      if (data.buttons) {
        if (data.buttons.cancel) {
          if (data.buttons.cancel.label) {
            state.confirm.buttons.cancel.label = data.buttons.cancel.label
          }
          if (data.buttons.cancel.callback) {
            state.confirm.buttons.cancel.callback = data.buttons.cancel.callback
          }
        }
        if (data.buttons.apply) {
          if (data.buttons.apply.label) {
            state.confirm.buttons.apply.label = data.buttons.apply.label
          }
          if (data.buttons.apply.callback) {
            state.confirm.buttons.apply.callback = data.buttons.apply.callback
          }
          if (data.buttons.apply.params) {
            state.confirm.buttons.apply.params = data.buttons.apply.params
          }
        }
      }
      state.confirm.show = true
    },

    [UNSET_CONFIRM_MODAL] (state) {
      state.confirm.show = false
      state.confirm.content.title = null
      state.confirm.content.subtitle = null
      state.confirm.props.vertical = false
      state.confirm.props.type = MODAL_TYPES.DANGER
      state.confirm.buttons.cancel.label = 'ui.buttons.cancel'
      state.confirm.buttons.cancel.callback = null
      state.confirm.buttons.apply.label = 'ui.buttons.apply'
      state.confirm.buttons.apply.callback = null
      state.confirm.buttons.apply.params = null
    },

    [SET_FORBIDDENT_MODAL] (state, data) {
      for (const key in data) {
        state.forbidden[key] = data[key]
      }
      state.forbidden.show = true
    },

    [UNSET_FORBIDDEN_MODAL] (state) {
      state.forbidden.show = false
      state.forbidden.title = null
      state.forbidden.message = null
      state.forbidden.unlinkTitle = null
      state.forbidden.unlinkSubtitle = null
      state.forbidden.unlinkOptions = []
      state.forbidden.deleteCallback = null
    }
  },

  actions: {
    openConfirmModal (context, data) {
      context.commit(SET_CONFIRM_MODAL, data)
    },

    closeConfirmModal (context) {
      context.commit(UNSET_CONFIRM_MODAL)
    },

    openForbiddenModal (context, data) {
      context.commit(SET_FORBIDDENT_MODAL, data)
    },

    closeForbiddenModal (context) {
      context.commit(UNSET_FORBIDDEN_MODAL)
    }
  }
}
