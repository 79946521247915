import { mapMutations } from 'vuex'
const LINK = 'https://meetings-eu1.hubspot.com/arman-mafi/skilltree-demo-standard'
export default {
    data: () => ({
        days: 0,
        hours: 0,
        timer: null
    }),
    props: {
        dataTime: {
            type: String
        }
    },
    methods: {
        ...mapMutations('app', {
            showTutorial: 'SHOW_TUTORIAL'
        }),
        doUpgrate () {
           return window.open(LINK, '_blank')
        },

        openTutorial () {
           this.showTutorial(true)
        },

        onClose () {
            this.$emit('close')
        },

        doCalcCountDown () {
            const countDownDate = new Date(this.dataTime).getTime()

            const now = new Date().getTime()
            const distance = countDownDate - now

            const days = Math.floor(distance / (1000 * 60 * 60 * 24))
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))

            this.days = days
            this.hours = hours

            if (distance < 0) {
              clearInterval(this.time)
            }
        },

        calcCountDown () {
            this.doCalcCountDown()
            this.timer = setInterval(this.doCalcCountDown, 1000)
        }
    },
    mounted () {
        this.calcCountDown()
    },
    destroyed () {
        this.timer = null
    }
}
