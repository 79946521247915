<template lang="pug">
  .toasts-layout
    v-toast(
      v-for="item in items"
      :key="item.id"
      :text="item.content | translate"
      @remove="removeToast(item)")
      .flex.flex-end
        v-btn(
          v-if="item.close"
          small
          text
          @click="item.onclose(item)") {{ item.close | translate }}
        v-btn(
          v-if="item.approve"
          small
          @click="item.onapprove(item)") {{ item.approve | translate }}
</template>

<script>
export default {
  name: 'ToastsList',

  mounted () {
    // const showIntroToast = this.$store.getters['trees/useNewTree'] === null
    // if (showIntroToast) {
    //   this.items.push(
    //     {
    //       id: 0,
    //       content: 'toasts.new_tree_available',
    //       approve: 'toasts.try_it_now',
    //       close: 'toasts.use_old',
    //       onapprove: this.applyNewTreeLook,
    //       onclose: this.onDiscard
    //     }
    //   )
    // }
  },

  data: () => ({
    items: []
  }),

  methods: {
    applyNewTreeLook (item) {
      this.$store.dispatch('trees/setUseNewTree', true)
      this.removeToast(item)
      this.items.push({
        id: 1,
        content: 'toasts.you_can_change_it_later',
        approve: 'ui.buttons.ok',
        close: 'toasts.switch_back',
        onapprove: this.removeToast,
        onclose: this.onSwitchBack
      })
    },

    onDiscard (item) {
      this.$store.dispatch('trees/setUseNewTree', false)
      this.removeToast(item)
    },

    onSwitchBack (item) {
      this.$store.dispatch('trees/setUseNewTree', false)
      this.removeToast(item)
    },

    removeToast (item) {
      const index = this.items.findIndex(el => el.id === item.id)
      if (index !== -1) {
        this.items.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss">
  .toasts-layout {
    position: fixed;
    top: 100px;
    right: 60px;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }
</style>
