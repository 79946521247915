import { GET, PUT } from '@/api/types.js'
import { execute } from '../../../../utils'

const ENDPOINT = 'preferences/email/reminders/suggestions'

export default {
  restore () {
    return execute(GET, `${ENDPOINT}`)
  },

  store (payload) {
    return execute(PUT, `${ENDPOINT}`, payload)
  },

  options () {
    return execute(GET, `${ENDPOINT}/options`)
  }
}
