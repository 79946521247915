<template lang="pug">
  .notification-modal-element(
    :style="columnsStyle"
    @click="parseNavigate(item)")
    .notification-wrapper
      .unread-dot(
        :style="unreadDotStyles")
      .notification-wrapper--container(:class="containerClasees")
        .colums
          span.notification-title {{ title }}
          span.notification-message {{ message }}
          span.notification-message(v-if="!isForDropdown") {{ item.createdAt }}
          span.notification-message.notification-message--btn-more(
            v-if="(isConfirmationDeclined && isConfirmationDeclinedComments) || (isValidationRequestPending && isValidationRequestComments)"
            @click.stop="openModalReadMore")
            | {{'ui.buttons.read_more' | translate}}
        .notification-wrapper--container__actions(v-if="isActionItem")
          template(v-if="showActionsBtns")
            v-btn(
              small
              :disabled="loadConfirm"
              @click.native.stop="onConfirm")
              | {{ 'ui.buttons.approve' | translate }}
            v-btn(
              small
              danger
              :disabled="loadDecline"
              @click.native.stop="onDeclineWithComment()")
              | {{ 'ui.buttons.decline' | translate }}
          span(v-else-if="isConfirmationApproved || isConfirmationDeclined || isValidationRequestInvalid"
            :style="actionColors") {{ actionText }}
    .more-icon-container(
      ref="moreIconNotification"
      v-if="!hideMoreIcon")
      i.flaticon-more-1.more-icon-p(
        v-click-outside="closeDropdown"
        @click.stop='toggleDropdown')
      transition(name="fade-down")
        .dropdown-wrapper(
          v-if="dropdownOpened"
          :style="position")
          p(@click.stop="item.isSeen ? markUnread() : markRead()")
            | {{ item.isSeen ? 'ui.notifications.mark_as_unread' : 'ui.notifications.mark_as_read' | translate }}
          p(@click="parseNavigate(item)")
            | {{  'ui.notifications.open_badge_details' | translate }}

</template>

<script>
import api from '@/api'
import moment from 'moment'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { NOTIFICATIONS_TYPES } from '@/util/constants'

export default {
  name: 'NotificationsResults',

  props: {
    item: Object,
    toggleId: Number,
    hideMoreIcon: Boolean,
    isForDropdown: Boolean
  },

  data: () => ({
    showDrapdown: false,
    loadConfirm: false,
    loadDecline: false
  }),

  methods: {
    ...mapActions('notifications', [
      'setUnseenCount',
      'setIsSeenMessage',
      'getNotification'
    ]),

    ...mapActions('badges', [
      'approveBadgeValidation',
      'declineBadgeValidation'
    ]),
    ...mapActions('goals', [
      'setShowReadGoalModal'
    ]),

     ...mapMutations('notifications', {
      setShowReadMoreNotifications: 'SHOW_READ_MORE_NOTIFICATIONS'
    }),

    ...mapMutations('badges', {
      setDeclineMode: 'SHOW_DECLINE_MODE'
    }),

    parseNavigate (item) {
      if (item.typeId === NOTIFICATIONS_TYPES.SUGGEST_BADGE && item.data.badge) {
        this.$router.push(`/builder/suggested/${item.data.badge.id}`)
      } else if (item.typeId === NOTIFICATIONS_TYPES.SUGGEST_BADGE_STATUS && item.data.badge) {
        this.$router.push(`/builder/badge/${item.data.badge.id}`)
      } else if (item.typeId === NOTIFICATIONS_TYPES.RECOMMEND_BADGE) {
        this.$router.push(`/my/badge/${item.data.recommendedBadge.id}`)
      } else if (item.typeId === NOTIFICATIONS_TYPES.BADGE_VALIDATION_REQUEST ||
          item.typeId === NOTIFICATIONS_TYPES.BADGE_VALIDATION_STATUS) {
        this.$router.push(`/employee/${item.data.employeeId}/badge/${item.data.badgeId}`)
      } else if (item.typeId === NOTIFICATIONS_TYPES.BADGE_EXPIRY) {
        this.$router.push(`/my/badge/${item.data.badge.id}`)
      } else if (item.typeId === NOTIFICATIONS_TYPES.GOAL_NEW) {
        item.data.goal
          ? this.$router.push(`/employee/${item.data.employee.id}/goal/${item.data.goal.id}`)
          : this.setShowReadGoalModal(true)// show modal when empty data
      } else if (item.typeId === NOTIFICATIONS_TYPES.GOAL_EMPLOYEE_NEW_COMMENT) {
        item.data.goal
          ? this.$router.push(`/employee/${item.data.comment.employee.id}/goal/${item.data.goal.id}`)
          : this.setShowReadGoalModal(true)// show modal when empty data
      } else if (item.typeId === NOTIFICATIONS_TYPES.GOAL_DEADLINE_APPROACHING ||
        item.typeId === NOTIFICATIONS_TYPES.GOAL_DEADLINE_TODAY ||
        item.typeId === NOTIFICATIONS_TYPES.GOAL_APPROVAL_STATUS ||
        item.typeId === NOTIFICATIONS_TYPES.GOAL_COMPLETION_STATUS ||
        item.typeId === NOTIFICATIONS_TYPES.GOAL_SUPERVISOR_NEW_COMMENT) {
          item.data.goal
            ? this.$router.push(`/my/goal/${item.data.goal.id}`)
            : this.setShowReadGoalModal(true)// show modal when empty data
      }

      if (!item.isSeen) {
        this.setSeen()
      }
    },

    closeDropdown () {
      this.showDrapdown = false
    },

    toggleDropdown () {
      if (!this.showDrapdown) {
        this.$emit('on-toggle-dropdown', this.item.id)
      }
      this.showDrapdown = !this.showDrapdown
    },

    markUnread () {
      if (this.item.isSeen) {
        this.setUnseen()
      }
      this.showDrapdown = false
    },

    markRead () {
      if (!this.item.isSeen) {
        this.setSeen()
      }
      this.showDrapdown = false
    },

    setUnseen () {
      const newCount = this.unseenCount + 1
      this.setUnseenCount(newCount)
      this.setIsSeenMessage({ id: this.item.id, isSeen: false })
      api.setUnSeenNotification(this.item.id)
    },

    setSeen () {
      const newCount = this.unseenCount - 1
      this.setUnseenCount(newCount)
      this.setIsSeenMessage({ id: this.item.id, isSeen: true })
      api.setSeenNotification(this.item.id)
    },

    async onConfirm () {
      if (!this.loadConfirm) {
        this.loadConfirm = true

        const params = {
          forEmployeeId: this.item.data.employeeId
        }
        await this.approveBadgeValidation({
          badgeId: this.item.data.badgeId,
          params
        })

        await this.getNotification({ id: this.item.id, update: true })
        if (!this.item.isSeen) {
          this.setSeen()
        }
      }
    },
    onDeclineWithComment () {
      this.setDeclineMode({ show: true, node: this.item })
      if (!this.item.isSeen) {
          this.setSeen()
        }
    },

    async onDecline () {
      if (!this.loadDecline) {
        this.loadDecline = true

        const params = {
          forEmployeeId: this.item.data.employeeId
        }
        await this.declineBadgeValidation({
          badgeId: this.item.data.badgeId,
          params
        })
        await this.getNotification({ id: this.item.id, update: true })
        if (!this.item.isSeen) {
          this.setSeen()
        }
      }
    },

    openModalReadMore () {
      const comment = this.isValidationRequestPending ? this.item.data.validation.comment : this.item.data.validation.declineComment
      this.setShowReadMoreNotifications({ show: true, comment: comment })
    },

    truncateString (str, maxLength) {
      return str.length <= maxLength ? str : str.substring(0, maxLength) + '...'
    }

  },

  computed: {
    ...mapGetters('notifications', [
      'unseenCount'
    ]),

    ...mapGetters('app', [
      'userInfo'
    ]),
    title () {
      switch (this.item.typeId) {
        case NOTIFICATIONS_TYPES.SUGGEST_BADGE:
          return this.$t('ui.notifications.title_new_badge_suggested')
        case NOTIFICATIONS_TYPES.SUGGEST_BADGE_STATUS:
          return this.$t(`ui.notifications.${this.isSuggestedBadgeApproved ? 'suggested_badge_approved' : 'suggested_badge_declined'}`)
        case NOTIFICATIONS_TYPES.RECOMMEND_BADGE:
          return this.$t('ui.notifications.title_recommended_badge')
        case NOTIFICATIONS_TYPES.BADGE_VALIDATION_REQUEST:
          return this.$t('ui.notifications.badge_confirmation_requested')
        case NOTIFICATIONS_TYPES.BADGE_VALIDATION_STATUS:
          return this.$t(`ui.notifications.${this.isConfirmationApproved ? 'badge_confirmed' : 'badge_declined'}`)
        case NOTIFICATIONS_TYPES.BADGE_EXPIRY:
          return this.$t('ui.notifications.badge_expiry')
        case NOTIFICATIONS_TYPES.GOAL_NEW:
          return this.$t('ui.notifications.goal_approval_requested')
        case NOTIFICATIONS_TYPES.GOAL_EMPLOYEE_NEW_COMMENT:
          return this.$t('ui.notifications.new_comment')
        case NOTIFICATIONS_TYPES.GOAL_DEADLINE_APPROACHING:
          return this.$t('ui.notifications.goal_reminder')
        case NOTIFICATIONS_TYPES.GOAL_DEADLINE_TODAY:
          return this.$t('ui.notifications.goal_expiring')
        case NOTIFICATIONS_TYPES.GOAL_COMPLETION_STATUS:
          return this.$t('ui.notifications.new_goal_status')
        case NOTIFICATIONS_TYPES.GOAL_SUPERVISOR_NEW_COMMENT:
          return this.$t('ui.notifications.new_comment')
        case NOTIFICATIONS_TYPES.GOAL_APPROVAL_STATUS:
          return this.$t('ui.notifications.aprroval_status', {
            status: this.item.data.approvalStatusId === 'REJECTED' ? this.$t('ui.labels.rejected') : this.$t('ui.labels.approved')
          })
        default:
          return this.$t('ui.notifications.no_info')
      }
    },

    message () {
      switch (this.item.typeId) {
        case NOTIFICATIONS_TYPES.SUGGEST_BADGE:
          return this.$t('ui.notifications.message_new_badge_suggested', {
            employeeName: this.item.data.employeeName || '',
            badgeName: this.item.data.badgeName || ''
          })
        case NOTIFICATIONS_TYPES.SUGGEST_BADGE_STATUS:
          return this.$t(`ui.notifications.${this.isSuggestedBadgeApproved ? 'message_suggested_badge_approved' : 'message_suggested_badge_declined'}`, {
            employeeName: this.item.data.employeeName || '',
            badgeName: this.item.data.badgeName || ''
          })
        case NOTIFICATIONS_TYPES.RECOMMEND_BADGE:
          return this.$t('ui.notifications.message_recommended_badge', {
            employeeName: `${this.item.createdBy?.firstName + ' ' + this.item.createdBy?.lastName}`,
            badgeName: `${this.item.data.recommendedBadge ? this.item.data.recommendedBadge.name : ''}`
          })
        case NOTIFICATIONS_TYPES.BADGE_VALIDATION_REQUEST:
          if (this.item.data.validation?.comment) {
            return this.$t('ui.notifications.message_confirmation_badge_withComment', {
              employeeName: this.item.data.employeeName || '',
              badgeName: this.item.data.badgeName || '',
              comment: this.truncateString(this.item.data.validation.comment, 40) || ''
            })
          }
          return this.$t('ui.notifications.message_confirmation_badge', {
            employeeName: this.item.data.employeeName || '',
            badgeName: this.item.data.badgeName || ''
          })
        case NOTIFICATIONS_TYPES.BADGE_VALIDATION_STATUS:
          if (this.isConfirmationDeclinedComments && !this.isConfirmationApproved) {
            return this.$t(`ui.notifications.message_declined_badge_withComment`, {
              employeeName: this.item.data.validationEmployeeName || '',
              badgeName: this.item.data.badgeName || '',
              comment: this.truncateString(this.isConfirmationDeclinedComments, 40) || ''
            })
          }
          return this.$t(`ui.notifications.${this.isConfirmationApproved ? 'message_confirmed_badge' : 'message_declined_badge'}`, {
            employeeName: this.item.data.validationEmployeeName || '',
            badgeName: this.item.data.badgeName || ''
          })
        case NOTIFICATIONS_TYPES.BADGE_EXPIRY:
          return this.$t('ui.notifications.message_badge_expiry', {
            date: moment(new Date(this.item.data.expiresAt || null)).format('DD.MM.YYYY'),
            badgeName: this.item.data.badgeName || ''
          })
        case NOTIFICATIONS_TYPES.GOAL_NEW:
          return this.$t('ui.notifications.message_new_goal', {
            employeeName: this.item.data.employeeName || '',
            goalTitle: this.item.data.goalTitle || ''
          })
        case NOTIFICATIONS_TYPES.GOAL_EMPLOYEE_NEW_COMMENT:
          return this.$t('ui.notifications.message_new_comment', {
            employeeName: this.item.data.employeeName || '',
            goalTitle: this.item.data.goalTitle || ''
          })
        case NOTIFICATIONS_TYPES.GOAL_DEADLINE_APPROACHING:
          return this.$t('ui.notifications.message_deadline_approaching', {
            goalTitle: this.item.data.goalTitle || '',
            goalDeadline: this.item.data.deadlineAt || ''
          })
        case NOTIFICATIONS_TYPES.GOAL_DEADLINE_TODAY:
          return this.$t('ui.notifications.message_goal_expiring_today', {
            goalTitle: this.item.data.goalTitle || ''
        })
        case NOTIFICATIONS_TYPES.GOAL_COMPLETION_STATUS:
          return this.$t('ui.notifications.message_goal_competition_status', {
            goalTitle: this.item.data.goalTitle || '',
            employeeName: this.item.data.supervisorName || '',
            status: this.item.data.completionStatusId === 'COMPLETED' ? this.$t('ui.labels.completed') : this.$t('ui.labels.failed')
          })
        case NOTIFICATIONS_TYPES.GOAL_APPROVAL_STATUS:
          return this.$t('ui.notifications.message_goal_approval_status', {
            goalTitle: this.item.data.goalTitle || '',
            employeeName: this.item.data.supervisorName || '',
            status: this.item.data.approvalStatusId === 'REJECTED' ? this.$t('ui.labels.rejected') : this.$t('ui.labels.approved')
          })
        case NOTIFICATIONS_TYPES.GOAL_SUPERVISOR_NEW_COMMENT:
          return this.$t('ui.notifications.message_goal_supervisor_comment', {
            goalTitle: this.item.data.goalTitle || '',
            employeeName: this.item.data.supervisorName || ''
          })
        default:
          return this.$t('ui.notifications.no_info')
      }
    },

    position () {
      if (!this.$refs.moreIconNotification) return
      const info = this.$refs.moreIconNotification.getBoundingClientRect()
      return `left: ${info.x + 20}px; top: ${info.y + 25}px;`
    },

    dropdownOpened () {
      return this.showDrapdown && this.toggleId === this.item.id
    },

    columnsStyle () {
      return {
        fontWeight: this.item.isSeen ? 400 : 600,
        backgroundColor: this.dropdownOpened ? 'rgba(0,0,0,0.04)' : null
      }
    },

    unreadDotStyles () {
      return {
        backgroundColor: this.item.isSeen
          ? 'transparent'
          : '#13b389'
      }
    },

    showActionsBtns () {
      return this.item.data.validation &&
        this.item.data.validation?.statusId === 'PENDING'
    },

    isSuggestedBadgeApproved () {
      return this.item.data.isAccepted === true
    },

    isConfirmationApproved () {
      return this.item.data.validation &&
        this.item.data.validation?.statusId === 'APPROVED'
    },

    isConfirmationDeclined () {
      return this.item.data.validation &&
        this.item.data.validation?.statusId === 'DECLINED'
    },

    isValidationRequestPending () {
      return this.item.typeId === NOTIFICATIONS_TYPES.BADGE_VALIDATION_REQUEST && this.item.data.validation?.statusId === 'PENDING'
    },

    isValidationRequestInvalid () {
      return this.item.typeId === NOTIFICATIONS_TYPES.BADGE_VALIDATION_REQUEST && this.item.data.validation?.statusId === 'INVALID'
    },

    isActionItem () {
      return this.item.typeId === NOTIFICATIONS_TYPES.BADGE_VALIDATION_REQUEST &&
        (this.showActionsBtns || this.isConfirmationApproved || this.isConfirmationDeclined || this.isValidationRequestInvalid)
    },

    containerClasees () {
      return { 'notification-wrapper--container__dropdown__actions': this.isForDropdown && this.isActionItem }
    },

    actionColors () {
      let color = '#666D74'

      if (this.isConfirmationDeclined) {
        color = '#F85061'
      } else if (this.isConfirmationApproved) {
        color = '#13B389'
      } else if (this.isValidationRequestInvalid) {
        color = '#6385D4'
      }

      return { color: color }
    },

    actionText () {
      if (this.item.typeId === NOTIFICATIONS_TYPES.BADGE_VALIDATION_REQUEST) {
        if (this.isConfirmationApproved || this.isConfirmationDeclined) {
          const confirmationText = this.isConfirmationApproved ? 'message_confirmation_accepted' : 'message_confirmation_declined'
          const validator = this.item.data.validation.validatedBy
          const employeeName = `${validator?.firstName} ${validator?.lastName}`

          return validator.id === this.userInfo.id
            ? this.$t(`ui.notifications.${confirmationText}_by_you`)
            : this.$t(`ui.notifications.${confirmationText}`, {
                employeeName: employeeName
              })
        } else if (this.isValidationRequestInvalid) {
          return this.$t('ui.notifications.message_confirmation_request_is_not_valid')
        }
      }

      return this.$t('ui.notifications.no_info')
    },

    isConfirmationDeclinedComments () {
      return this.item.data.validation && this.item.data.validation?.declineComment
    },

    isValidationRequestComments () {
      return this.item.data.validation && this.item.data.validation?.comment
    }
  }
}
</script>

<style lang="scss" scoped>
  .notification-modal-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding: 12px 12px 12px 2px;

    &:hover {
      background-color: rgba(0,0,0,0.04);
    }

    .notification-wrapper {
      display: flex;
      width: 100%;
      align-items: center;

      &--container {
        display: flex;

        align-items: center;
        width: 100%;
        justify-content: space-between;

        &__actions{
          font-size: 1.1rem;
          width: 300px;
          display: flex;
          justify-content: center;
          padding: 0 1rem;

          span {
            text-align:center;
          }

          button:nth-child(1) {
            margin-right: 1rem;
          }
        }
      }

      &--container__dropdown__actions{
        flex-direction: column;
        align-items: flex-start;

        .notification-wrapper--container__actions {
          width: 100%;
          padding: 0;
          padding-top: 10px;
          flex-wrap: wrap;
          justify-content: flex-start;

          span {
            text-align: start ;
          }
        }
      }

      .colums {
        display: flex;
        flex-direction: column;

        .notification-title {
          color: black;
          font-size: 14px;
          margin-bottom: 5px;
        }

        .notification-message {
          font-size: 12px;
          &--btn-more{
            text-decoration: underline;

            &:hover {
              cursor: pointer;
              color: $color-primary;
            }
          }
        }
      }

      .unread-dot {
          min-width: 7px;
          width:7px;
          height: 7px;
          border-radius: 50%;
          margin: 0 10px;
      }
    }

    .notification-detail-icon-wrapper {
      min-width: 40px;
      width:40px;
      height:40px;
      margin-right: 10px;

      .notification-detail-icon {
        width:100%;
        height:100%;
      }
    }

    .more-icon-container {
      position: relative;
      margin-right: 5px;

      .more-icon-p {
        padding: 2px;
      }

      .dropdown-wrapper {
        box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12);
        width: 250px;
        margin-left:-20px;
        display: block;
        z-index: 4;
        position: fixed;
        background-color: white;

        p {
          margin:0;
          padding: 10px 10px;

          &:hover {
            background-color: rgba(0,0,0,0.04);
          }
        }
      }
    }
  }
</style>
