<template lang="pug">
  div
    .v-select-list__item(
      :class="classes"
      @mousedown.stop="onClick")
      .select-area(
        v-if="!simple"
        @mousedown.stop.prevent="selectItem")

      .v-select-list__item-before-title(
        v-if="hasBeforeTitle")
        slot(name="before-title")

      .u-stack-panel--vertical
        v-select-item-title(
          :search="search"
          :title="item[trackBy]")

        .v-select-list__item-subtitle(
          v-if="hasSubtitle")
          slot(name="subtitle")

      .chevron-wrapper(
        v-if="hasChildren")
        chevron(
          color="#abb5bf"
          :rotate="chevronAngle")

    //- Children elements here
    transition(name="fadex")
      slot(
        v-if="showChildren && hasChildren"
        :is-selected="isSelected")
</template>

<script>
import Chevron from '@/components/svg/Chevron'

import VSelectItemTitle from './VSelectItemTitle'

export default {
  name: 'VSelectItem',

  components: {
    Chevron,
    VSelectItemTitle
  },

  props: {
    item: Object,
    level: Number,
    search: String,
    trackBy: String,
    isSelected: Boolean,
    isItemSelectable: Boolean,
    hasChildren: Boolean,
    hasSelectedChild: Boolean,
    isLastChild: Boolean,
    borderless: Boolean,
    single: Boolean,
    simple: Boolean
  },

  mounted () {
    if (this.search) {
      this.showChildren = true
    }
  },

  data: () => ({
    showChildren: false
  }),

  methods: {
    toggleChildren () {
      this.showChildren = !this.showChildren
    },

    onClick (e) {
      if (this.hasChildren) {
        this.showChildren = !this.showChildren
      } else if (this.isItemSelectable) {
        this.selectItem()
        this.$emit('close')
      }
    },

    selectItem () {
      if (!this.isItemSelectable || (this.simple && this.isSelected)) return
      this.$emit('select', { item: this.item, isSelected: this.isSelected })
    }
  },

  computed: {
    chevronAngle () {
      return this.showChildren ? -90 : 90
    },

    hasBeforeTitle () {
      return !!this.$slots['before-title']
    },

    hasSubtitle () {
      return !!this.$slots['subtitle']
    },

    hasBottomBorder () {
      return !this.isLastChild ||
        (this.hasChildren && this.showChildren)
    },

    classes () {
      return {
        'selected': this.isSelected,
        'has-selected': !this.isSelected && this.hasSelectedChild,
        'border-bottom': !this.borderless && this.hasBottomBorder,
        'border-left': !this.borderless && this.level > 0,
        'single': this.single,
        'not-selectable': !this.isItemSelectable
      }
    }
  },

  watch: {
    search () {
      this.showChildren = true
    }
  }
}
</script>

<style scoped lang="scss">
$area-select: 5px;
  .select-area {
    position: absolute;
    height: 18px;
    width: 18px;
    left: 12px;
    top: 50%;
    z-index: 100;
    transform: translateY(-50%);
    &::after {
      content: '';
      display: flex;
      position: absolute;
      transform: translate(-$area-select, -$area-select);
      box-sizing: content-box;
      width: inherit;
      height: inherit;
      padding: $area-select;
    }
  }
</style>
