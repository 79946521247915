<template lang="pug">
  div
    .portlet__head.justify-content-center
      .portlet__head-label
        .portlet__head-title
          h3.text-center {{ isSetNewPasswordNeeded ? $t("pages.auth.confirmation_code" ) : $t("pages.auth.reset_password" ) }}
          h5.text-center {{ 'pages.auth.you_received_a_confirmation_code_pre_mail_please_enter_it_here' | translate }}
    .portlet__body
      form.kt-form(@submit.prevent="confirmPasswordReset")
        .form-group(:class="{ 'is-invalid': isCodeInvalid }")
          input.form-control(
            :placeholder="'pages.auth.confirmation_code' | translate"
            v-model="code")
          span.form-text.invalid {{ 'resetPasswordCode'| errorText }}
          span.form-text.invalid {{ 'email'| errorText }}
        .form-controls
          a(@click="resetStep") {{ 'pages.auth.sign_in'  | translate}}
          .text-right
            v-btn(
              :loading="loading"
              ) {{ 'ui.buttons.confirm' | translate }}
</template>

<script>
import api from '@/api'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ForgotPasswordConfirm',

  async mounted () {
    this.clearErrors()
    this.code = this.passwordResetCode
    if (this.passwordResetCode) {
      await this.confirmPasswordReset()
    }
  },

  data: () => ({
    code: '',
    loading: false
  }),

  props: {
    setNewPasswordTitle: {
      type: Boolean,
      default: false
    },
    isSetNewPasswordNeeded: {
      type: Boolean,
      default: false
    },
    selectedInstance: {
      type: Object,
      default: () => {}
    }
  },

  destroyed () {
    this.setPasswordResetCode('')
  },

  methods: {
    ...mapActions('app', ['setPasswordResetCode', 'clearErrors']),

    async confirmPasswordReset () {
      this.loading = true
      let payload = {
        resetPasswordCode: this.code,
        email: this.loginEmail
      }
      if (this.selectedInstance) {
        payload.instanceCode = this.selectedInstance.code
      }
      if (await api.passwordResetConfirm(payload)) {
        this.$emit('next')
      }
      this.loading = false
    },

    resetStep () {
      if (this.$route.path.includes('login')) {
        this.$emit('resetStep')
      } else {
        this.$router.push('/login')
      }
    }
  },

  computed: {
    ...mapGetters('app', ['passwordResetCode', 'errors', 'loginEmail']),

    isCodeInvalid () {
      return this.errors.find(item => item.field === 'resetPasswordCode')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-controls {
  a {
    color: $color-primary;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
      &:hover {
      color: #0c6e54;
      text-decoration: underline;
    }
  }
}

</style>
