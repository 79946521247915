<template functional>
<svg :class="props.classes" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.4733 13.5267L11.9999 11.0733C12.96 9.87626 13.4249 8.35686 13.2991 6.82752C13.1733 5.29819 12.4664 3.87518 11.3236 2.85109C10.1808 1.82699 8.68914 1.27966 7.15522 1.32164C5.62129 1.36361 4.16175 1.9917 3.0767 3.07675C1.99164 4.1618 1.36356 5.62135 1.32158 7.15527C1.27961 8.68919 1.82694 10.1809 2.85103 11.3237C3.87512 12.4664 5.29814 13.1734 6.82747 13.2992C8.3568 13.425 9.87621 12.9601 11.0733 12L13.5266 14.4533C13.5886 14.5158 13.6623 14.5654 13.7436 14.5993C13.8248 14.6331 13.9119 14.6505 13.9999 14.6505C14.0879 14.6505 14.1751 14.6331 14.2563 14.5993C14.3376 14.5654 14.4113 14.5158 14.4733 14.4533C14.5934 14.329 14.6606 14.1629 14.6606 13.99C14.6606 13.8171 14.5934 13.651 14.4733 13.5267ZM7.33327 12C6.41029 12 5.50804 11.7263 4.74061 11.2135C3.97318 10.7007 3.37504 9.9719 3.02183 9.11918C2.66862 8.26646 2.57621 7.32815 2.75627 6.4229C2.93634 5.51766 3.38079 4.68614 4.03344 4.03349C4.68608 3.38085 5.5176 2.93639 6.42285 2.75633C7.32809 2.57626 8.2664 2.66868 9.11913 3.02189C9.97185 3.37509 10.7007 3.97323 11.2135 4.74066C11.7262 5.50809 11.9999 6.41034 11.9999 7.33332C11.9999 8.571 11.5083 9.75799 10.6331 10.6332C9.75793 11.5083 8.57095 12 7.33327 12Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'IconSearch',
  props: {
    classes: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
</style>
