<template lang="pug">
  .sf--search-panel
    .st-container(style="position:relative")
      v-card
        .sf--search-panel__content
          .sf--search-panel__content--search
            search-panel-input(
              ref="searchPanel"
              :selectedSearchBadge="null"
              @on-select-search="onSelectSearch")
            .sf--search-panel__content--search--actions
              v-btn(
                outlinedText
                @click="onShowFilters")
                | {{ 'ui.labels.advanced_filters' | translate }}
          chips
      search-result
    advanced-filters(
      :show="showFilters"
      @close="onCloseFilters")
</template>

<script>
import { mapActions } from 'vuex'
import SearchPanelInput from './common/SearchPanelInput'
import Chips from './common/Chips'
import AdvancedFilters from './modals/AdvancedFilters'
import SearchResult from './modals/SearchResult'

export default {
  name: 'SearchPanel',

  components: {
    SearchPanelInput,
    Chips,
    AdvancedFilters,
    SearchResult
  },

  mounted () {
    this.loadDepartments()
    this.loadLocations()
  },

  data: () => ({
    showFilters: false
  }),

  methods: {
    ...mapActions('skillFinder', [
      'loadDepartments',
      'loadLocations',
      'addBadge'
    ]),

    onCloseFilters () {
      this.showFilters = false
    },

    onShowFilters () {
      this.showFilters = true
    },

    onSelectSearch (item) {
      this.addBagdeToList(item)
    },

    addBagdeToList (item) {
      if (item) {
        this.addBadge(item)
        this.$refs.searchPanel.resetSearch()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .sf--search-panel {
    display: block;
    position: absolute;
    width: 100%;
    top: 15px;

    &__content {
      padding: 1rem 1.5rem;

      &--search {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .v-select {
          margin-left: 1rem;
          width: 250px;
        }

        .v-btn {
          margin-left: 20px;
        }

        &--actions {
          display: flex;
          justify-content: space-between;
          min-width: fit-content;
        }
      }
    }
  }
</style>
