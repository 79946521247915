<template lang="pug">
.skill-suggestion-tabs
  .skill-suggestion-tabs--card(v-for="item in tabs"
    :key="item.tabId"
    :class="{'skill-suggestion-tabs--active' : activetab === item.tabId}"
    @click="onTabChange(item.tabId)")
    span.skill-suggestion-tabs--count
      i(v-if="item.iconClass" :class="item.iconClass")
      span(v-else) {{ item.count }}
    span.skill-suggestion-tabs--title {{ item.title }}
    .skill-suggestion-tabs--cursor
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    listOfSkills: Array,
    activetab: {
      type: Number,
      default: 1
    }
  },

  methods: {
    onTabChange (tab) {
      this.$emit('tab-change', tab)
    }
  },

  computed: {
    ...mapGetters('suggestions', [
      'activeSkillsCount',
      'recommendationsCount',
      'skillProfileCount'
    ]),
    employeeId () {
      if (this.$route.path.includes('/share')) {
        return this.$route.params.id
      } else {
        return this.$route.params.employee_id
      }
    },
    tabs () {
      const tab0 = {
          tabId: 1,
          title: this.$t('pages.my.active_skills'),
          count: this.activeSkillsCount
        }
       const tab1 = {
        tabId: 2,
        title: this.$t('pages.my.skill_profile'),
        count: this.skillProfileCount
      }
      const tab2 = {
        tabId: 3,
        title: this.$t('pages.my.suggestions'),
        count: this.recommendationsCount
      }
      const tab3 = {
        tabId: 3,
        title: this.$t('ui.labels.profile'),
        iconClass: 'fa fa-user'
      }
      return this.employeeId ? [tab3, tab0, tab1] : [tab0, tab1, tab2]
    }
  },
  mounted () {
    this.employeeId ? this.onTabChange(3) : this.onTabChange(this.activetab)
  }
}
</script>

<style lang="scss" scoped>
.skill-suggestion-tabs {
  width:100%;
  display: flex;
  justify-content: space-between;

  &--card{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 20px;
    width: 33.3%;
    cursor: pointer;

    &:nth-child(1) {
      span {
        color: $color-primary;
      }

      div {
        background-color: $color-primary;
      }
    }
     &:nth-child(2) {
      span {
        color: $color-orange;
      }

      div {
        background-color: $color-orange;
      }
    }
     &:nth-child(3) {
      span {
        color: $color-lighten-grey;
      }

      div {
        background-color:  $color-lighten-grey;
      }
    }
  }

  &--active {
    background-color: $light-blue;

    .skill-suggestion-tabs--cursor {
      display: inline;
    }
  }

  &--count {
    font-weight: bold;
    font-size: 1.8rem;
    padding-bottom: 8px;
  }

  &--title {
    color: $color-black !important;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 8px;
  }

  &--cursor {
    display: none;
    height: 2px;
    width: 16px;
    border-radius: 4px;
    position: absolute;
    bottom: 14px;
  }
}
</style>
