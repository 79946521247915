<template lang="pug">
  .v-select(
    :style="styles"
    :class="classes")
    .v-select__inner
      dropdown(
        ref="dropdown"
        :full="!fixed"
        :fixed="fixed"
        :disabled="disabled"
        :drawer-mode="isMobile"
        :position="position"
        :is-for-table="isForTable"
        @toggle="toggle"
        @after-toggle="afterToggle"
        @toggle-for-table="toggleForTable")

        .v-select__field
          input.v-select__field-input(
            ref="input"
            v-if="isForTable && toggled || !isForTable"
            :class="inputFieldClasses"
            :value="search"
            :tabindex="tabindex"
            :placeholder="inputPlaceholder"
            :disabled="disabled"
            @focus="onInputFocus(true)"
            @blur="onInputFocus(false)"
            @input="onSearch")
          span(
            v-if="isForTable && !toggled"
            :title="inputTitle") {{ inputTitle }}
          .chevron-wrapper(v-if="enableClear && search" @click="search = ''")
            close-icon
          .chevron-wrapper(v-else)
            i.flaticon2-search-1(v-if="searchChevron")
            chevron(
              v-else
              color="#646c9a"
              :width="12"
              :height="12"
              :rotate="toggled ? -90 : 90")

        .v-select-content(
          slot="content"
          :class="{ mobile: isMobile }")

          //- Input field and toggler for mobile only
          .v-select__field(v-if="isMobile")
            .v-select__toggler(
              @click="closeDropdown")
              chevron
            input.v-select__field-input(
              ref="input"
              v-if="toggled"
              v-focus
              :value="search"
              @input="onSearch")

          //- dropdown items list
          v-scrollable(
            v-if="hasFilteredOptions"
            :min-height="minContentHeight"
            @scroll-complete="onScrollComplete")
            .v-select__items
              v-select-items(
                borderless
                :single="single"
                :items="filteredOptions"
                :search="search"
                :track-by="trackBy"
                :item-value="itemValue"
                :return-object="returnObject"
                :selected="selected"
                :is-item-selectable="isItemSelectable"
                :simple="simple"
                @select="selectItem"
                @close="closeDropdown")

                template(
                  slot="before-title"
                  slot-scope="{ item }")
                  slot(
                    name="before-title"
                    :item="item")

                template(
                  slot="subtitle"
                  slot-scope="{ item }")
                  slot(
                    name="subtitle"
                    :item="item")
            .v-select__loader(v-if="loading || loadingOutside")
              v-preloader(:size="24")
            .v-select-info__create-option(
              v-if="enableCreteOption && search && hasntMatchOptions"
              @click="onOptionCreate")
              i.flaticon2-add-1
              span {{ 'ui.buttons.create' | translate }}
                strong "{{ search }}"

          .v-select__loader(v-else-if="loading || loadingOutside")
              v-preloader(:size="24")
          .v-select-info(v-else)
            .v-select-info__no-options
              | {{ 'ui.dropdown.no_options' | translate }}
            .v-select-info__create-option(
              v-if="enableCreteOption && search"
              @click="onOptionCreate")
              i.flaticon2-add-1
              span {{ 'ui.buttons.create' | translate }}
                strong "{{ search }}"

    v-select-selected-chips(
      v-if="chips && !single"
      :track-by="trackBy"
      :options="options"
      :selected="selected"
      :simple="simple"
      @select="selectItem"
      @unselect="selectItem")

</template>

<script>
import helpers from '@/util/helpers'

import Chevron from '@/components/svg/Chevron'
import Dropdown from './VSelectDropdown'
import VScrollable from '@/components/ui/v-scrollable/VScrollable'
import closeIcon from '../../svg/VInputSearch_CloseIcon'

import VSelectItems from './VSelectItems'
import VSelectSelectedChips from './VSelectSelectedChips'

const MOBILE_MAX_WIDTH = 800

export default {
  name: 'VSelect',

  components: {
    Chevron,
    Dropdown,
    VScrollable,
    VSelectItems,
    VSelectSelectedChips,
    closeIcon
  },

  props: {
    value: [Array, Object, Boolean, Number, String],
    options: {
      type: Array,
      default: () => []
    },
    trackBy: {
      type: String,
      default: 'name'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    returnObject: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: null
    },
    skeleton: Boolean,
    useApi: Boolean, // If "true" component try to use "loadCallback", "searchCallback" and "pagination" props
    pagination: { // Pagination object from store
      type: Object,
      default: () => ({})
    },
    loadCallback: { // Works only with "useApi=true"
      type: Function,
      default: () => ([])
    },
    searchCallback: { // Works only with "useApi=true"
      type: Function,
      default: () => ([])
    },
    fixed: Boolean,
    single: Boolean,
    chips: Boolean,
    disabled: Boolean,
    filterOutside: Boolean,
    enableCreteOption: Boolean,
    isForTable: Boolean,
    nullable: Boolean,
    hideBottomMargin: Boolean,
    isItemSelectable: {
      type: Function,
      default: () => { return true }
    },
    horizontalMostBorders: {
      type: Object,
      default: () => ({ left: 0, right: 0 })
    },
    tabindex: {
      type: Number,
      default: 0
    },
    simple: Boolean,
    searchChevron: Boolean,
    loadingOutside: Boolean,
    enableClear: {
      type: Boolean,
      default: false
    }
  },

  async mounted () {
    helpers.windowResizeObserver.subscribe(this.checkForMobile)
    this.checkForMobile()
    if (this.useApi) {
      await this.loadCallback({})
    }
    this.selected = this.value
  },

  beforeDestroy () {
    helpers.windowResizeObserver.unsubscribe(this.checkForMobile)
  },

  data: () => ({
    search: null,
    toggled: false,
    selected: [],
    isMobile: false,
    position: {
      x: 0, y: 0
    },
    loading: false,
    hasFocusInput: false
  }),

  methods: {
    selectItem ({ item, isSelected }) {
      if (!this.single) {
        if (item.children && item.children.length) {
          for (const child of item.children) {
            this.selectItem({ item: child, isSelected: isSelected })
          }
        } else {
          const index = this.selected
            .map(item => item.id)
            .indexOf(item.id)
          if (!isSelected && index === -1) {
            this.selected.push(item)
            this.$emit('add', item)
          } else if (isSelected && index !== -1) {
            this.selected.splice(index, 1)
            this.$emit('remove', item)
          }
        }
      } else {
        if (this.returnObject) {
          this.$emit('input', item)
          this.$emit('update', item)
        } else {
          this.$emit('input', item[this.itemValue])
        }
      }
    },

    async onSearch (event) {
      this.search = event.target.value
      if (this.useApi) {
        this.loading = true
        const params = {
          page: 1,
          q: this.search
        }
        await this.searchCallback({ params })
        this.loading = false
      }
    },

    async onScrollComplete (scrollRef) {
      if (this.useApi && !this.loading) {
        if (this.pagination.page < this.pagination.pages) {
          const params = {
            page: this.pagination.page + 1
          }
          this.loading = true
          if (this.search) {
            params.q = this.search
            await this.searchCallback({ params, isPushed: true })
          } else {
            await this.loadCallback({ params, isPushed: true })
          }
          this.loading = false
        }
      }
      this.$emit('on-scroll-end', scrollRef)
    },

    onOptionCreate () {
      this.$emit('on-create', this.search)
    },

    closeDropdown () {
      if (this.toggled) {
        this.$refs.dropdown.closeDropdown()
        this.$emit('on-change')
      }
    },

    openDropdown () {
      if (!this.toggled) {
        this.$refs.dropdown.toggle()
        this.$emit('on-open-dropdown', true)
      }
    },

    findString (item) {
      return this.search === null ||
        item[this.trackBy]
          .toLowerCase()
          .includes(this.search
            .toLowerCase())
    },

    findItem (items) {
      return items.filter((item) => {
        if (item.children) {
          item.children = this.findItem(item.children)
        }
        return !!(item.children && item.children.length) || this.findString(item)
      })
    },

    async clearInput () {
      this.search = null
      if (this.$refs.input) {
        this.$refs.input.value = null
      }
      if (this.useApi) {
        this.loading = true
        const params = {
          page: 1,
          q: this.search
        }
        await this.searchCallback({ params })
        this.loading = false
      }
    },

    checkForMobile () {
      this.isMobile = window.innerWidth <= MOBILE_MAX_WIDTH
    },

    // VADIM magic methods starts here
    onInputFocus (value) {
      if (value && !this.isForTable) {
        this.openDropdown()
      } else if (!value) {
        this.closeDropdown()
      }
    },

    toggle (value) {
      this.hasFocusInput = value
      this.toggled = value
      this.$emit('on-open-dropdown', value)
      if (this.isForTable) {
        setTimeout(() => {
          if (this.$refs.input) {
            this.$refs.input.focus()
          }
        }, 0)
      }
    },

    getPosition () {
      let x = this.$refs.dropdown.$el.getBoundingClientRect().left
      if (this.isForTable) {
        if (this.horizontalMostBorders.right < (x + this.$refs.dropdown.$el.offsetWidth)) {
          const diff = (x + this.$refs.dropdown.$el.offsetWidth) - this.horizontalMostBorders.right
          x = x - diff
        }
        if (this.horizontalMostBorders.left > x) {
          x = this.horizontalMostBorders.left
        }
      }
      this.position.y = this.$refs.dropdown.$el.getBoundingClientRect().top
      this.position.x = x
    },

    afterToggle () {
      this.getPosition()
    },

    toggleForTable (value) {
      this.$emit('on-open-dropdown-for-table', value)
      if (!value) {
        this.$emit('on-change')
      }
    }
  },

  computed: {
    inputTitle () {
      if (this.single) {
        if (this.returnObject) {
          return (this.selected && this.$t(this.selected[this.trackBy])) ||
            this.placeholder ||
            this.$t('ui.labels.select_option')
        }
        const item = this.options
          .find(item => item[this.itemValue] === this.value)
        return item && this.$t(item[this.trackBy])
      }
      if (this.selected.length && !this.simple) {
        return `${this.selected.length} ${this.$t('ui.labels._options_selected')}`
      }
      return this.placeholder ||
        this.$t('ui.labels.select_option')
    },

    isSelectValueInPlaceholder () {
      if (this.hasFocusInput) return false
      if (this.single) return !!(this.selected && this.selected[this.trackBy])
      else if (this.selected.length) return true
      else return false
    },

    inputPlaceholder () {
      let text = ''
      if (!this.hasFocusInput) {
        text = this.inputTitle
      } else {
        text = this.$t('ui.labels.type_for_search')
        if (this.enableCreteOption) {
          return `${text} ${this.$t('ui.labels._or_create_new_option')}`
        }
      }
      return text
    },

    filteredOptions () {
      let options = this.options
      if (this.nullable && options.length) {
        const nullableObj = { id: null }
        nullableObj[this.trackBy] = this.$t('ui.labels.none')
        options = [nullableObj, ...this.options]
      }
      if (!this.filterOutside && !this.useApi) {
        const tmp = JSON.parse(JSON.stringify({ value: options }))
        return this.findItem(tmp.value)
      }
      return options
    },

    hasFilteredOptions () {
      return !!this.filteredOptions.length
    },

    hasntMatchOptions () {
      return this.search &&
        !!this.filteredOptions
          .find(el => el.name
            .toLowerCase() !== this.search
              .toLowerCase())
    },

    minContentHeight () {
      return this.filteredOptions.length >= 3 ? 120 : 40
    },

    hasLastItemSlot () {
      return !!this.$slots['last-item']
    },

    styles () {
      return {
        'margin-bottom': this.isForTable || this.hideBottomMargin ? `${0}px` : '15px'
      }
    },

    inputFieldClasses () {
      return {
        'v-select__field-input__active-placeholder': this.isSelectValueInPlaceholder,
        'v-select__field-input__active-placeholder--single': this.isSelectValueInPlaceholder && this.single
      }
    },

    classes () {
      return {
        'v-select--disabled': this.disabled,
        'v-select--simple': this.simple,
        'v-select--toggled': this.toggled,
        'v-select--loading': this.skeleton
      }
    }
  },

  watch: {
    search (value) {
      if (this.filterOutside) {
        this.$emit('search-change', value)
      }
    },

    value (value, newValue) {
      if (value !== newValue) {
        this.selected = value
      }
    },

    selected (value) {
      if (this.returnObject) {
        this.$emit('input', value)
      }
    },

    toggled (value) {
      // TODO: Refator scroll lock logic
      // make something like: this.$scroll.lock()
      const srollPanel = document.getElementsByClassName('__panel')[0]
      const scrollByIDPanel = document.getElementById('scroll-panel')
      const srollPanelHorizontal = document.getElementById('scroll-panel-horizontal')

      if (!value) {
        setTimeout(this.clearInput, 310)
        if (srollPanel) {
          srollPanel.classList.remove('block-root-scroll')
        }
        if (scrollByIDPanel) {
          scrollByIDPanel.firstChild.classList.remove('block-root-scroll')
        }

        if (srollPanelHorizontal) {
          srollPanelHorizontal.firstChild.classList.remove('block-root-scroll')
        }
      } else {
        if (srollPanel) {
          srollPanel.classList.add('block-root-scroll')
        }
        if (scrollByIDPanel) {
          scrollByIDPanel.firstChild.classList.add('block-root-scroll')
        }

        if (srollPanelHorizontal) {
          srollPanelHorizontal.firstChild.classList.add('block-root-scroll')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';

  .v-select {
    width: 100%;

    &--disabled {
      opacity: .5;
    }

    &--loading {
      border-radius: 5px;
      @include skeleton;
    }

    &--loading &__inner {
      opacity: 0;
      visibility: hidden;
    }

    &__inner {
      position: relative;
    }

    &__items {
      width:100%;
      margin-right: -15px;
    }

    &__loader {
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__field {
      position: relative;
      height: 40px;
      min-width: 100px;
      border-radius: 5px;
      color: #646C9A;
      font-weight: 500;
      overflow: hidden;
      background-color: $light-blue;

      .chevron-wrapper {
        position: absolute;
        z-index: 9;
        right: 0;
        top: 0;
        height: 100%;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        .flaticon2-search-1 {
          color: $color-primary
        }
      }

      span {
        padding: 8px 20px;
        color: #646C9A;
        line-height: 26px;
        display: inline-block;
        text-align: start;
        white-space: nowrap;
        overflow: hidden;
        width: calc(100% - 20px);
        text-overflow: ellipsis
      }
    }

    &__field-input {
      left: 0;
      position: absolute;
      border: none;
      overflow: hidden;
      // height: 26px;
      // width: calc(100% - 20px);
      // margin-bottom: 8px;
      width: 100%;
      background: none;
      padding: 8px 25px 8px 20px;
      z-index: 9;

      &::placeholder {
        color: $input-placeholder;
        font-weight: 400;
        text-overflow: ellipsis;
      }

      &[placeholder] {
        color: #646c9a;
        font-weight: 600;
        text-overflow: ellipsis;
      }

      &__active-placeholder {
        &::placeholder {
          font-weight: 600;
          color: #646c9a;
        }
      }

      // &--without-focus {
      //   width: calc(100% - 20px);
      //   &::placeholder {
      //     color: #646c9a;
      //     text-overflow: ellipsis;
      //   }
      // }
    }

    &-content {
      position: relative;
      border-radius: 4px;
      background-color: $light-blue;
      overflow: hidden;

      &.mobile {
        z-index: 101;
        height: 100vh;

        .v-select__field {
          position: relative;
          padding-left: 35px;
          box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

          .v-select__toggler {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              transform: rotate(180deg)
            }
          }
        }
      }
    }

    &-info__no-options,
    &-info__create-option {
      color: #646C9A;
      padding: 15px 20px;
      display: flex;
      align-items: center;
    }

    &-info__create-option {
      cursor: pointer;

      &:hover {
        background-color: #eaecf5;
      }

      i {
        margin-right: 10px;
      }

      span {
        margin-right: 5px;
      }
    }

    &--simple {
      .v-select {
        &__field {
          background-color: $color-white;
          border: 1px solid rgba(82, 63, 105, 0.05);
          color: $color-grey;
          font-size: 14px;
        }

        &-content {
          background-color: $color-white;
        }

        &__field-input {
          &[placeholder] {
            color: #707070;
            font-weight: 600;
            text-overflow: ellipsis;
          }

          &__active-placeholder {
            &::placeholder {
              font-weight: 400;
              color: $input-placeholder;
            }
          }

        }

        &-info__no-options {
          padding: 5px 5px;
          padding-left: 20px;
          color: #707070;
          font-size: 14px;
        }
      }

      .v-select__field-input__active-placeholder--single {
        &::placeholder {
          color: #707070;
      }
    }
    .v-dropdown__content {
      box-shadow: 0px 10px 15px 0px rgba(82, 63, 105, 0.15);
    }
  }
}

</style>
