<template lang="pug">
  .v-stepper-header
    slot
</template>

<script>
export default {
  name: 'VStepperHeader'
}
</script>

<style lang="scss">
  .v-stepper-header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
