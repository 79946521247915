import { execute, cancelableRequest, addQueryParams } from '@/api/utils.js'
import { GET, PUT, POST, DELETE } from '@/api/types.js'

import templates from './templates'
import validation from './validation'
import validators from './validators'
import skills from './skills'
import newlyAdded from './newly-added'

const ENDPOINT = 'badges'

const cancelable = cancelableRequest(execute)

export default {
  templates,
  validation,
  validators,
  skills,
  newlyAdded,

  list (params = {}) {
    return execute(GET, addQueryParams(`${ENDPOINT}`, params))
  },

  search (params = {}) {
    return cancelable.execute(GET, addQueryParams(`${ENDPOINT}/search`, params))
  },

  getById (id, params) {
    return execute(GET, addQueryParams(`${ENDPOINT}/${id}`, params))
  },

  create (payload) {
    return execute(POST, ENDPOINT, payload)
  },

  update (id, params = {}, payload) {
    return execute(PUT, addQueryParams(`${ENDPOINT}/${id}`, params), payload)
  },

  updateById (id, payload) {
    return execute(PUT, `${ENDPOINT}/${id}`, payload)
  },

  delete (id, payload) {
    return execute(DELETE, `${ENDPOINT}/${id}`, payload)
  },

  deleteMany (payload) {
    return execute(DELETE, `${ENDPOINT}`, payload)
  },

  createByTemplate (id, payload) {
    return execute(POST, `${ENDPOINT}?withTemplateId=${id}`, payload)
  },

  updateTemplate (id, payload) {
    return execute(PUT, `${ENDPOINT}/${id}/template`, payload)
  },

  subtree (id) {
    return execute(GET, `${ENDPOINT}/${id}/tree`)
  },

  colors () {
    return execute(GET, `${ENDPOINT}/colors`)
  },

  required (id) {
    return execute(GET, `${ENDPOINT}/required-badges?badgeId=${id}`)
  },

  move (toCategoryId, payload) {
    return execute(PUT, `${ENDPOINT}/move?toCategoryId=${toCategoryId}`, payload)
  },

  copy (toCategoryId, payload) {
    return execute(PUT, `${ENDPOINT}/copy?toCategoryId=${toCategoryId}`, payload)
  },

  show (payload) {
    return execute(PUT, `${ENDPOINT}/show`, payload)
  },

  hide (payload) {
    return execute(PUT, `${ENDPOINT}/hide`, payload)
  },

  toggleActivated (badgeId, queryParams = {}, payload = null) {
    const params = {
      badgeId,
      ...queryParams
    }
    return execute(PUT, addQueryParams(`${ENDPOINT}/toggle-activated`, params), payload)
  },

  renew (badgeId, queryParams = {}, payload = null) {
    const params = {
      badgeId,
      ...queryParams
    }
    return execute(POST, addQueryParams(`${ENDPOINT}/renew`, params), payload)
  },

  suggest (payload) {
    return execute(POST, `${ENDPOINT}/suggest`, payload)
  },

  linkTemplate (id, templateId) {
    return execute(POST, `${ENDPOINT}/${id}/link?toTemplateId=${templateId}`)
  },

  unlinkTemplate (id) {
    return execute(DELETE, `${ENDPOINT}/${id}/unlink?fromTemplate`)
  },

  linkPosition (positionId, payload) {
    return execute(POST, `${ENDPOINT}/link?toPositionId=${positionId}`, payload)
  },

  unlinkPosition (positionId, payload) {
    return execute(DELETE, `${ENDPOINT}/unlink?fromPositionId=${positionId}`, payload)
  },

  approveValidation (id, employeeId) {
    return execute(POST, `${ENDPOINT}/${id}/approve?forEmployeeId=${employeeId}`)
  },

  declineValidation (id, employeeId) {
    return execute(POST, `${ENDPOINT}/${id}/decline?forEmployeeId=${employeeId}`)
  },

  registerValidators (id, payload) {
    return execute(POST, `${ENDPOINT}/${id}/validators`, payload)
  },

  deregisterValidators (id, payload) {
    return execute(DELETE, `${ENDPOINT}/${id}/validators`, payload)
  },

  getBadgeValidators (id) {
    return execute(GET, `${ENDPOINT}/${id}/validators`)
  },

  requestBadgeWithValidations (id, payload) {
    return execute(POST, `${ENDPOINT}/${id}/request`, payload)
  },

  skillRecommendationsList () {
    return execute(GET, `${ENDPOINT}/recommendations`)
  },

  skillRecommendationDiscard (id) {
    return execute(PUT, `${ENDPOINT}/${id}/recommendations/discard`)
  },

  activeSkillsList (params) {
    return execute(GET, addQueryParams(`${ENDPOINT}/skills/active`, params))
  },

  skillProfileList (params) {
    return execute(GET, addQueryParams(`${ENDPOINT}/skills/profile`, params))
  },

  searchSkills (params) {
    return cancelable.execute(GET, addQueryParams(`${ENDPOINT}/skills/search`, params))
  },

  onlyNotActivated () {
    return execute(GET, `${ENDPOINT}?onlyNotActivated=1`)
  },

  favorites (params) {
    return execute(GET, addQueryParams(`${ENDPOINT}/favorites`, params))
  },

  favoritesAdd (ID) {
    return execute(POST, `${ENDPOINT}/${ID}/favorites`)
  },

  favoritesDelete (ID) {
    return execute(DELETE, `${ENDPOINT}/${ID}/favorites`, { badgeId: ID })
  },

  favoritesAllDelete () {
    return execute(DELETE, `${ENDPOINT}/favorites`)
  },

  createSkillWizardBookmark (payload) {
    return execute(POST, `${ENDPOINT}?fromSkillWizardBookmarks`, payload)
  }
}
