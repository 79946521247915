<template lang="pug">
  .v-donut(:style="styles")
    svg.donut(
      width="100%"
      height="100%"
      :viewBox="viewBox")
      circle.donut-background(
        cx="21"
        cy="21"
        r="17"
        :style="{ fill: this.backgroundColor }")
      template(v-for="s in count")
        circle.donut-segment(
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          :stroke="getColor(s)"
          :stroke-width="stroke"
          :stroke-dasharray="dashArray"
          :stroke-dashoffset="24 - percent * (s - 1)")
      text(
        v-if="showLabel"
        x="50%"
        y="53%"
        font-size="10"
        font-weight="700"
        dominant-baseline="middle"
        text-anchor="middle")
        | {{ value }}/{{ count }}
      text(
        v-if="showFillingPercent"
        x="54%"
        y="65%"
        font-size="9px"
        font-weight="700"
        :fill="colorPercent"
        dominant-baseline="middle"
        text-anchor="middle")
        | {{ fillingPercent }}
      text(
        v-if="showFillingPercent"
        :x="calcPercentX()"
        y="67%"
        font-size="6px"
        font-weight="700"
        :fill="colorPercent"
        dominant-baseline="middle"
        text-anchor="middle")
        | %
    .v-donut__inner(v-if="!showLabel && !showFillingPercent")
      slot

</template>

<script>
const MAX_COUNT = 24
export default {
  name: 'VDonut',

  props: {
    value: {
      type: Number,
      default: 0
    },
    count: {
      type: Number,
      default: 4
    },
    color: {
      type: String,
      default: '#13B389'
    },
    iconColor: {
      type: String,
      default: null
    },
    secondaryColor: {
      type: String,
      default: '#EBEDF0'
    },
    backgroundColor: {
      type: String,
      default: 'transparent'
    },
    space: {
      type: Number,
      default: 2
    },
    stroke: {
      type: Number,
      default: 4
    },
    size: {
      type: Number,
      default: 50
    },
    viewBox: {
      type: String,
      default: '0 0 42 42'
    },
    colorPercent: {
      type: String,
      defaults: '#252F39'
    }
  },

  data: () => ({
    showLabel: false
  }),

  mounted () {
    this.showLabel = !this.$slots.default
  },

  computed: {
   fillingPercent () {
      return this.value > this.count ? 100 : Math.round((this.value / this.count) * 100)
   },
   showFillingPercent () {
      return this.count >= MAX_COUNT
   },
    percent () {
      return 100 / this.count
    },

    dashArray () {
      if (this.count === 1) {
        return [0]
      }
      if (!this.showFillingPercent) {
        return [
          this.percent - this.space,
          100 - this.percent + this.space
        ]
      } else {
        return [
            Math.round(this.percent) + 0.1,
            Math.round(100 - this.percent) - 0.1
          ]
      }
    },

    styles () {
      return {
        color: this.iconColor || this.color,
        height: `${this.size}px`,
        width: `${this.size}px`
      }
    }
  },

  methods: {
    getColor (s) {
      return s <= this.value
        ? this.color
        : this.secondaryColor
    },
    calcPercentX () {
      switch (this.fillingPercent.toString().length) {
        case 1:
          return '69%'
        case 2:
          return '77%'
        case 3:
          return '85%'
      }
    }
  }
}
</script>

<style lang="scss">
  .v-donut {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;

    &__inner {
      position: absolute;
      display: flex;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .donut-background {
      opacity: .2;
    }
  }
</style>
