<template lang="pug">
  .goals(:key="activetab")
    .goals__header
      h2.title {{ isEmployeeView ? 'pages.my.goals' : 'pages.my.my_goals' | translate }}
      button.link-text(
        v-if="canAddGoals || !isEmployeeView"
        @click="openNewGoalModal")
        | + {{ 'pages.my.add_new_goal' | translate}}
    .goals__tabs
      tabs(:activetab="activetab" :tabs="tabs" @tab-change="onTabChange")
    new-goal-modal(
      v-if="showGoalModal"
      @close="onCloseNewGoalModal"
      :editedGoal="editedGoal")
    read-goal-modal(
      v-if="showReadGoalModal"
      @close="onCloseReadGoalModal"
      :readGoal="readedGoal"
      :params = "{'openComments': true}"
      :editFromForeignProfile="canAddGoals"
      @edit-goal="onEditFromRead")
    goals-list(
      v-show="activetab === 1" :list="goals.inProgress || []"
      :editFromForeignProfile="canAddGoals"
      @edit-goal="onEdit" )
    goals-list(
      v-show="activetab === 2"
      :list="goals.completed || []"
      completed
      :editFromForeignProfile="canAddGoals"
      @edit-goal="onEdit" )
    goals-list(
      v-show="activetab === 3"
      :list="goals.failed || []"
      failed
      :editFromForeignProfile="canAddGoals"
      @edit-goal="onEdit" )
    read-goal-modal-empty(
      v-if="getShowReadGoalModal"
      @close="setShowReadGoalModal(false)")
</template>

<script>
import api from '@/api'
import { mapActions, mapGetters } from 'vuex'
import Tabs from './Tabs'
import NewGoalModal from './NewGoalModal'
import ReadGoalModal from './ReadGoalModal'
import GoalsList from './GoalsList'
import ReadGoalModalEmpty from './ReadGoalModalEmpty.vue'

export default {
  components: {
    Tabs,
    NewGoalModal,
    GoalsList,
    ReadGoalModal,
    ReadGoalModalEmpty
  },

  data: (vm) => ({
    showGoalModal: false,
    activetab: 0,
    editedGoal: null,
    goalsIsLoaded: false,
    ownUsers: [],
    readedGoal: null,
    showReadGoalModal: false,
    tabs: [{
        tabId: 1,
        title: vm.$t('ui.labels.in_progress')
      },
      {
        tabId: 2,
        title: vm.$t('ui.labels.completed')
      },
      {
        tabId: 3,
        title: vm.$t('ui.labels.failed')
      }]
  }),

  async mounted () {
   await this.init()
    setTimeout(() => {
      this.activetab = 1
    }, 100)
  },

  destroyed () {
    this.activetab = 0
  },

  methods: {
    ...mapActions('goals', [
      'loadGoals',
      'removeGoal',
      'setShowReadGoalModal',
      'getSingleGoal'
    ]),

    async init () {
      this.goalsIsLoaded = false
      if (this.$route.path.includes('employee')) {
        await this.loadGoals(+this.employeeId)
        if (this.haveOwnEditProfilePermissions && this.userInfo.department) {
          let { data } = await api.departments.loadEmployeesIdsOnly(this.userInfo.department.id)
          this.ownUsers = data
        }
      } else {
        await this.loadGoals()
      }
      this.goalsIsLoaded = true
      if (this.goalId) {
        this.findGoalRead()
      }
    },

    findGoalRead () {
      if (this.goalsIsLoaded) {
        for (const key in this.goals) {
          const goal = this.goals[key].find(el => el.id === +this.goalId)
          if (goal) {
            this.readedGoal = goal
            this.showReadGoalModal = true
            break
          }
        }
      }
    },

    openNewGoalModal () {
      this.showGoalModal = true
    },

    onCloseNewGoalModal () {
      this.showGoalModal = false
      this.editedGoal = null
    },

    onCloseReadGoalModal () {
      this.showReadGoalModal = false
      this.readedGoal = null
      if (this.goalId) {
        this.$router.push(this.isEmployeeView ? `/employee/${this.employeeId}` : '/my')
      }
    },

    onTabChange (tab) {
      this.activetab = tab
    },

    onEditFromRead () {
      this.editedGoal = this.readedGoal
      this.openNewGoalModal()
      this.onCloseReadGoalModal()
    },

    onEdit (goal) {
      this.editedGoal = goal
      this.openNewGoalModal()
    }
  },

  computed: {
    ...mapGetters('goals', [
      'goals',
      'getShowReadGoalModal'
    ]),

    ...mapGetters('app', [
      'userInfo'
    ]),

    ...mapGetters('permissions', [
      'currentPermissions'
    ]),

    haveOwnEditProfilePermissions () {
      return this.isEmployeeView &&
        this.currentPermissions.SKILLTREE_EDIT_FOREIGN_PROFILES_OWN_EMPLOYEES
    },

    canAddGoals () {
      if (this.currentPermissions.SKILLTREE_EDIT_ALL_EMPLOYEES_PROFILES) {
        return true
      }
      return this.currentPermissions.SKILLTREE_EDIT_OWN_EMPLOYEES_PROFILES ||
        this.ownUsers.some(el => el.id === +this.employeeId)
    },

    isEmployeeView () {
      return this.$route.path.includes('employee')
    },

    employeeId () {
      return this.$route.params.employee_id
    },

    goalId () {
      return this.$route.params.goal_id
    }
  },

  watch: {
    employeeId () {
      this.init()
    },

   async goalId () {
      if (this.goalId) {
       await this.getSingleGoal(this.goalId)
        this.findGoalRead()
      } else {
        this.onCloseReadGoalModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .goals {
    &__header {
      display: flex;
      justify-content: space-between;
      padding: 24px;
    }
    &__tabs{
      padding: 0 24px 24px;
    }
  }
</style>
