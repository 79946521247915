<template lang="pug">
  v-group(:config="configGroup")
    v-rect(:config="configRect")
    v-text(:config="configText")
</template>

<script>
const BG_COLOR = '#F8F9FC'
const SIZE = 1.5
const HEIGHT = 15 * SIZE
const FONT_SIZE = 9 * SIZE

export default {
  name: 'TreeCategoryChildrenCount',

  props: {
    color: String,
    pos: {
      type: Object,
      default: () => ({})
    },
    count: {
      type: Number,
      default: 0
    }
  },

  computed: {
    configGroup () {
      return {
        ...this.pos
      }
    },

    width () {
      return 15 * SIZE + (this.count.toString().length - 1) * 5 * SIZE
    },

    configRect () {
      return {
        height: HEIGHT,
        width: this.width,
        cornerRadius: 10 * SIZE,
        fill: this.color,
        strokeWidth: 1,
        stroke: BG_COLOR
      }
    },

    configText () {
      return {
        y: 0,
        height: HEIGHT + 2 * SIZE,
        text: this.count,
        width: this.width,
        fill: 'white',
        fontSize: FONT_SIZE,
        fontFamily: 'Nunito',
        align: 'center',
        verticalAlign: 'middle'
      }
    }
  }
}
</script>

<style>
</style>
