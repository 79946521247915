const SET_IS_GOALS_DRAWER_OPENED = 'SET_IS_GOALS_DRAWER_OPENED'
const SET_IS_FILTER_DRAWER_OPENED = 'SET_IS_FILTER_DRAWER_OPENED'
const SET_IS_RESULT_DRAWER_OPENED = 'SET_IS_RESULT_DRAWER_OPENED'
const SET_IS_EMPLOYEE_DRAWER_OPENED = 'SET_IS_EMPLOYEE_DRAWER_OPENED'
const SET_IS_RECOMMENDED_SKILLS_DRAWER_OPENED = 'SET_IS_RECOMMENDED_SKILLS_DRAWER_OPENED'

const SET_ALL_AS_ACTIVATED = 'SET_ALL_AS_ACTIVATED'
const SET_HILIGHT_SUB_BRANCHE = 'SET_HILIGHT_SUB_BRANCHE'
const SET_SHOW_SUGGEST_BADGES = 'SET_SUGGEST_BADGES'
const SET_CALLBACK_FOR_SET_MIN_ZOOM = 'SET_CALLBACK_FOR_SET_MIN_ZOOM'
const SET_HIGHLIGHTED_TEMPLATE_ID = 'SET_HIGHLIGHTED_TEMPLATE_ID'

export default {
  namespaced: true,

  state: () => ({
    isGoalsDrawerOpened: false,
    isFilterDrawerOpened: true,
    isFilterResultDrawerOpened: false,
    isEmployeeDetailDrawerOpened: true,
    showAllAsActivated: false,
    hilightSubBranche: false,
    showSuggestBadges: false,
    setMinZoomCallback: null,
    highlightedTemplateId: null,
    isRecommendedSkillsDrawerOpened: false
  }),

  getters: {
    isGoalsDrawerOpened: (state) => state.isGoalsDrawerOpened,
    isFilterDrawerOpened: (state) => state.isFilterDrawerOpened,
    isFilterResultDrawerOpened: (state) => state.isFilterResultDrawerOpened,
    isEmployeeDetailDrawerOpened: (state) => state.isEmployeeDetailDrawerOpened,
    showAllAsActivated: (state) => state.showAllAsActivated,
    hilightSubBranche: (state) => state.hilightSubBranche,
    showSuggestBadges: (state) => state.showSuggestBadges,
    highlightedTemplateId: (state) => state.highlightedTemplateId,
    isRecommendedSkillsDrawerOpened: (state) => state.isRecommendedSkillsDrawerOpened
  },

  mutations: {
    [SET_IS_GOALS_DRAWER_OPENED]: (state, value) => {
      state.isGoalsDrawerOpened = value
    },

    [SET_IS_FILTER_DRAWER_OPENED]: (state, value) => {
      state.isFilterDrawerOpened = value
    },

    [SET_IS_RESULT_DRAWER_OPENED]: (state, value) => {
      state.isFilterResultDrawerOpened = value
    },

    [SET_IS_EMPLOYEE_DRAWER_OPENED]: (state, value) => {
      state.isEmployeeDetailDrawerOpened = value
    },

    [SET_ALL_AS_ACTIVATED] (state, value) {
      state.showAllAsActivated = value
    },

    [SET_HILIGHT_SUB_BRANCHE] (state, value) {
      state.hilightSubBranche = value
    },

    [SET_SHOW_SUGGEST_BADGES] (state, value) {
      state.showSuggestBadges = value
    },

    [SET_CALLBACK_FOR_SET_MIN_ZOOM] (state, callback) {
      state.setMinZoomCallback = callback
    },

    [SET_HIGHLIGHTED_TEMPLATE_ID] (state, value) {
      state.highlightedTemplateId = value
    },

    [SET_IS_RECOMMENDED_SKILLS_DRAWER_OPENED]: (state, value) => {
      state.isRecommendedSkillsDrawerOpened = value
    }
  },

  actions: {
    setGoalsDrawerState ({ commit }, state) {
      commit(SET_IS_GOALS_DRAWER_OPENED, state)
    },

    setFilterDrawerState ({ commit }, state) {
      commit(SET_IS_FILTER_DRAWER_OPENED, state)
    },

    setResultDrawerState ({ commit }, state) {
      commit(SET_IS_RESULT_DRAWER_OPENED, state)
    },

    setEmployeeDrawerState ({ commit }, state) {
      commit(SET_IS_EMPLOYEE_DRAWER_OPENED, state)
    },

    setShowAllAsActivated ({ commit }, value) {
      commit(SET_ALL_AS_ACTIVATED, value)
    },

    setHilightSubBranche ({ commit }, value) {
      commit(SET_HILIGHT_SUB_BRANCHE, value)
    },

    setShowSuggestBadges ({ commit, state }, value) {
      if (value && state.setMinZoomCallback) {
        state.setMinZoomCallback()
      }
      commit(SET_SHOW_SUGGEST_BADGES, value)
    },

    // TODO: move to svgTree module
    setMinZoom ({ state }) {
      if (state.setMinZoomCallback) {
        state.setMinZoomCallback()
      }
    },

    setHighlightedTemplateId ({ commit }, value) {
      commit(SET_HIGHLIGHTED_TEMPLATE_ID, value)
    },

    setIsRecommendedSkillsDrawerOpened (context, state) {
      context.commit(SET_IS_RECOMMENDED_SKILLS_DRAWER_OPENED, state)
    }
  }
}
