import api from '@/api'

const SET_HYBRID_LOAD = 'SET_HYBRID_LOAD'
const ADD_HYBRID_LOAD = 'ADD_HYBRID_LOAD'
const SET_PAGINATION_PARAMS = 'SET_PAGINATION_PARAMS'

export default {
  namespaced: true,

  state () {
    return {
      hybridSearchData: [],
      hybridPaginationParams: {}
    }
  },

  getters: {
    hybridSearchData: (state) => state.hybridSearchData,
    hybridPaginationParams: (state) => state.hybridPaginationParams
  },

  mutations: {
    [SET_HYBRID_LOAD] (state, data) {
      state.hybridSearchData = data
    },

    [ADD_HYBRID_LOAD] (state, data) {
      state.hybridSearchData = [...state.hybridSearchData, ...data]
    },

    [SET_PAGINATION_PARAMS] (state, params) {
      for (const key in params) {
        state.hybridPaginationParams[key] = params[key]
      }
    }
  },

  actions: {
    async hybridSearch (context, { params = {} }) {
      const { data, headers } = await api.hybridSearch(params)
      if (!data) return
      const paginationParams = {
        ...params
      }
      paginationParams.perPage = (headers && parseInt(headers['x-pagination-per-page'])) || 0
      paginationParams.totalCount = (headers && parseInt(headers['x-pagination-total-count'])) || 0
      paginationParams.pages = (headers && parseInt(headers['x-pagination-page-count'])) || 1
      paginationParams.total = paginationParams.totalCount
      if (!paginationParams.page) {
        paginationParams.page = 1
      }

      context.commit(SET_PAGINATION_PARAMS, paginationParams)

      if (paginationParams.page > 1) {
        context.commit(ADD_HYBRID_LOAD, data)
      } else {
        context.commit(SET_HYBRID_LOAD, data)
      }

      return { data, headers }
    }
  }
}
