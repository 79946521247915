<template lang="pug">
  .goals-list-item
    .goals-list-item__num(v-if="num")
      span.goals-list-item--title {{ num }}.
    .goals-list-item--body
      .goals-list-item--between-container
        span.goals-list-item--title {{ goal.title }}
        v-dropdown(
            v-if="goal.helpers.canBeUpdatedByCurrentEmployee || goal.helpers.canBeDeletedByCurrentEmployee"
            right
            scrollable-fixed
            auto-close
            width-auto)
            v-btn(
              icon
              gray)
              i.fa.fa-ellipsis-v
            template(slot="content")
              div(style="width:180px")
                v-card(elevation)
                  v-list-item(
                    v-if="goal.helpers.canBeUpdatedByCurrentEmployee"
                    @click="$emit('edit-goal')")
                      v-list-item-content
                        v-list-item-title {{ 'pages.my.edit_goal' | translate }}
                  v-list-item(
                    v-if="goal.helpers.canBeDeletedByCurrentEmployee"
                    @click="$emit('delete')")
                    v-list-item-content
                      v-list-item-title
                        span.danger {{ 'pages.my.delete_goal' | translate }}
                  v-list-item(
                    v-if="(isEmployeeView && goal.helpers.canBeUpdatedByCurrentEmployee) || goal.approvalStatusId === 'NOT_NEEDED'"
                    @click="$emit('on-mark-as')")
                    v-list-item-content
                      v-list-item-title
                        span.success {{ 'pages.my.mark_goal_as' | translate }}
      v-show-hide(:max-height="maxHeighDesc")
        div.goals-list-item--description(v-html="descriptionHtml")
      .goals-list-item--between-container(style="margin-top: 5px; margin-bottom: 10px")
        span.goals-list-item--description {{ 'pages.my.deadline' | translate }}
          span(v-if="!goal.helpers.canBeUpdatedByCurrentEmployee")  {{ goal.deadlineAt | calculateTimetoFutureDate }}
          span.goals-list-item--deadline(v-else-if="goal.deadlineAt" @click="$emit('on-deadline')") {{ goal.deadlineAt | calculateTimetoFutureDate }}
          span.goals-list-item--deadline(v-else @click="$emit('on-deadline')") {{ 'pages.my._set_deadline' | translate }}
        span.goals-list-item--status(:class="statusClasses") {{ status }}
      .goals-list-item--badges-row
        .goals-list-item--badges
          .goals-list-item--badges--item(
              v-for="(item, idx) in goal.badges"
              :key="idx"
              v-tooltip="escapeHTML(item.displayName)"
              @click="onSelectBadge(item)")
            badge(
              width="30"
              height="40"
              :color="item.color"
              :level="item.starsCount || 3")
        v-btn(
          v-if="!showAppovalActions && goal.helpers.canBeUpdatedByCurrentEmployee"
          icon
          @click="toggleComments")
          i.fas.fa-comment-dots.comment-icon
      .goals-list-item--actions(v-if="!completed && !failed" :style="actionsStyles")
        .goals-list-item--supervisor__actions(v-if="showAppovalActions")
          v-btn(
            :disabled="!goal.helpers.canBeUpdatedByCurrentEmployee"
            small
            @click="$emit('approve')") {{ 'ui.buttons.approve' | translate }}
          v-btn(
            :disabled="!goal.helpers.canBeUpdatedByCurrentEmployee"
            danger
            small
            outlinedText
            text
            @click="$emit('reject')") {{ 'ui.buttons.reject' | translate }}
        span
        v-btn(
          v-if="showAppovalActions && goal.helpers.canBeUpdatedByCurrentEmployee"
          icon
          @click="toggleComments")
          i.fas.fa-comment-dots.comment-icon
      .goals-list-item--comments(
        v-if="openComments && goal.reason && goal.helpers.canBeUpdatedByCurrentEmployee")
        .goals-list-item--comments__user
          v-avatar(
            :size="30"
            :src="employee.profilePictureUrl || '/img/no-profile-picture.svg'")
          span.goals-list-item--comments__user--name {{ employee.firstName + ' ' + employee.lastName }}
        .goals-list-item--comments__message {{ goal.reason }}
        .goals-list-item--comments__reply(v-if="!goal.comments.length")
            i.fa.flaticon-reply.success(@click="toggleReply")
      template(v-if="openComments")
        .goals-list-item--comments(v-for="(item, idx) in goal.comments" :ref="`commetn_${idx}`" )
          .goals-list-item--comments__header
            .goals-list-item--comments__user
              v-avatar(
                :size="30"
                :src="item.employee.profilePictureUrl || '/img/no-profile-picture.svg'")
              span.goals-list-item--comments__user--name {{ item.employee.firstName + ' ' + item.employee.lastName }}
            v-dropdown(
              v-if="userInfo.id === item.employee.id"
              right
              scrollable-fixed
              auto-close
              width-auto)
              v-btn(
                icon
                gray
                style="margin-right: -8px")
                i.fa.fa-ellipsis-v
              template(slot="content")
                div(style="width:180px")
                  v-card(elevation)
                    v-list-item(@click="deleteComment(item)")
                      v-list-item-content
                        v-list-item-title
                          span.danger {{ 'ui.buttons.delete' | translate }}
          .goals-list-item--comments__message {{ item.comment }}
          .goals-list-item--comments__reply(v-if="goal.comments.length === (idx + 1)")
              i.fa.flaticon-reply.success(@click="toggleReply")
      .goals-list-item--comments__reply-form(v-if="openComments && (openReply || !goal.reason && !goal.comments.length)")
        textarea.form-control(
            :placeholder="'ui.inputs.reply' | translate"
            rows="4"
            v-model="replyValue")
        v-btn(
          small
          :disabled="!replyValue"
          @click="sendComment")
          | {{ 'ui.buttons.send' | translate }}
</template>

<script>
import Vue from 'vue'
import api from '@/api'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Badge from '@/components/svg/Badge'
import marked from 'marked'
import { MAX_HEIGTH_DESCRIPTION_BADGE } from '@/util/constants.js'

import helpers from '@/util/helpers'

export default {
  name: 'GoalsListItem',

  components: {
    Badge
  },

  props: {
    goal: Object,
    num: Number,
    completed: Boolean,
    failed: Boolean,
    params: {
      type: Object,
      default: () => null
    }
  },

  data: () => ({
    openComments: false,
    replyValue: '',
    openReply: false

  }),

  mounted () {
    if (this.params && this.params.hasOwnProperty('openComments')) {
      this.openComments = this.params.openComments
      if (this.goal.comments) {
        setTimeout(() => {
          this.$refs[`commetn_${this.goal.comments.length - 1}`][0].scrollIntoView({ behavior: 'smooth' })
        })
      }
    }
  },

  methods: {
    toggleComments () {
      this.openComments = !this.openComments
    },

    toggleReply () {
      this.openReply = !this.openReply
    },

    sendComment () {
      this.toggleReply()
      this.$emit('comment', { comment: {
        comment: this.replyValue,
        employee: this.userInfo
      } })
      this.replyValue = ''
    },

    onSelectBadge (badge) {
      this.$router.push(`${this.navigateTo}/badge/${badge.id}`)
    },

    deleteComment (item) {
      const findedcommentIndex = this.goal.comments.findIndex(el => el.id === item.id)
      if (findedcommentIndex !== -1) {
        this.goal.comments.splice(findedcommentIndex, 1)
      }
      api.goals.deleteComment(item.id)
    },

    escapeHTML (unsafe) {
      return helpers.escapeHTML(unsafe)
    }
  },

  filters: {
    calculateTimetoFutureDate (date) {
      if (date) {
        const m = moment(date)
        const today = moment().startOf('day')
        const days = Math.round(moment.duration(m - today).asDays())
        switch (days) {
          case 1:
            return Vue.i18n.translate('ui.labels._tomorrow')
          case 0:
            return Vue.i18n.translate('ui.labels._today')
          case -1:
            return Vue.i18n.translate('ui.labels._yesterday')
          default:
            const locale = Vue.i18n.locale()
            return moment(new Date(`${date}T23:59:59`), 'DD.MM.YYYY').locale(locale).fromNow()
        }
      }
      return 'unset'
    }
  },

  computed: {
    ...mapGetters('app', [
      'userInfo'
    ]),

    ...mapGetters('employee', ['foreignEmloyeeDetail']),

    status () {
      if (this.completed) {
        return this.$t('ui.labels.completed')
      } else if (this.failed) {
        return this.$t('ui.labels.failed')
      } else if (this.isApproved) {
        return this.$t('ui.labels.approved')
      } else if (this.isRejected) {
        return this.$t('ui.labels.rejected')
      } else if (this.isEmployeeView) {
        return this.$t('ui.labels.pending')
      } else if (this.goal.approvalStatusId === 'NOT_NEEDED') {
        return ''
      }

      return this.$t('ui.labels.pending_approval')
    },

    actionsStyles () {
      return !this.isEmployeeView || !this.showAppovalActions ? 'padding-top: 5px' : ''
    },

    statusClasses () {
      return {
        'danger': this.failed || this.isRejected,
        'success': this.completed || (this.isApproved && !this.failed)
      }
    },

    isEmployeeView () {
      return this.$route.path.includes('employee')
    },

    isRejected () {
      return this.goal.approvalStatusId === 'REJECTED'
    },

    isApproved () {
      return this.goal.approvalStatusId === 'APPROVED'
    },

    showAppovalActions () {
      return this.isEmployeeView && this.goal.approvalStatusId === 'UNAPPROVED' && this.goal.helpers.canBeUpdatedByCurrentEmployee
    },

    employee () {
      return this.isEmployeeView ? this.foreignEmloyeeDetail : this.userInfo
    },

    navigateTo () {
      if (['/my', '/employee'].some(item => this.$route.path.includes(item))) {
        return this.$route.path.split('/')[1] === 'employee' ? '/' + this.$route.path.split('/')[1] + '/' + this.$route.path.split('/')[2]
          : '/' + this.$route.path.split('/')[1]
      } else return '/my'
    },

    descriptionHtml () {
      const description = helpers.escapeHTML(this.goal.description || '')
      return marked(description)
    },

    maxHeighDesc () {
      return MAX_HEIGTH_DESCRIPTION_BADGE
    }
  }
}
</script>

<style lang="scss" scoped>

  .goals-list-item {
    display: flex;

    &:not(last-child) {
      padding-bottom: 10px;
    }

    &__num {
      display: flex;
      align-items: center;
      height: 32px;
      padding-right: 10px;

    }

    &--between-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--title {
      font-weight: bold;
      color: #495160;
      font-size: 15px;
    }

    &--description {
      color: #A0AAC3;
      font-size: 13px;

      ::v-deep img {
        width: 100%;
      }
    }

    &--badges-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

    }

    &--status {
      font-size: 13px;
      font-weight: 700;
      color: #B3B8CE;
    }

    &--deadline  {
      text-decoration: underline;
      margin-left: 5px;
      cursor: pointer;

      &:hover {
        color: #495160;
      }
    }

    &--body {
      display: flex;
      flex-direction: column;
      width: 100%;
      ::v-deep .v-show-hide{
        .btn-show-more{
          padding-left: 0;
        }
      }
    }

    &--badges {
      margin-left: -5px;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      &--item {
        cursor: pointer;
      }
    }

    &--supervisor__actions {
      display: flex;
      gap: 15px;
    }

    &--actions {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
    }

    &--comments {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px #00000029;
      border-radius: 6px;
      margin-top: 15px;
      padding: 10px;

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__user {
        display: flex;
        align-items: center;

        &--name {
          font-size: 12px;
          font-weight: 600;
          color: #707070;
          margin-left: 6px;
        }
      }

      &__message {
        margin-top: 6px;
        font-size: 12px;
        line-height: 16px;
        color: #A0AAC3;
        word-break: break-word;
      }

      &__reply {
        display: flex;
        justify-content: flex-end;

        i {
          font-size: 14px;
          cursor: pointer;
        }
      }
    }

    &--comments__reply-form {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      align-items: flex-end;

      .v-btn {
        margin-top: 10px;
      }
    }

    .comment-icon {
      color: #6D7587;
      font-size: 16px !important;
    }
  }
</style>
