<template lang="pug">
  .v-expasion-checkbox
    .v-expasion-checkbox__header(@click="toggle")
      v-checkbox(v-model="item.checked" :label="item[trackBy]" @input="(value)=>onChange(value, item)" @click.native.stop)
      .triangle(:class="{ 'toggled': show }" v-if="hasBodySlot")

    transition(
      name="v-expasion-checkbox"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave")
      .v-expasion-checkbox__body(v-show="show")
        slot(name="body")
</template>

<script>
import Chevron from '@/components/svg/Chevron'
export default {
  name: 'VExpansionCheckbox',

  components: {
    Chevron
  },

  props: {
    item: Object,
    trackBy: {
      type: String,
      default: 'name'
    }
  },

  data: () => ({
    show: false
  }),

  methods: {
    toggle () {
      if (this.hasBodySlot) {
        this.show = !this.show
      } else {
        this.item.checked = !this.item.checked
        this.onChange(this.item.checked, this.item)
      }
    },

    beforeEnter (el) {
      el.style.height = '0'
    },

    enter (el) {
      el.style.height = el.scrollHeight + 'px'
      setTimeout(() => { el.style.height = 'auto' }, 160)
    },

    beforeLeave (el) {
      el.style.height = el.scrollHeight + 'px'
    },

    leave (el) {
      setTimeout(() => { el.style.height = '0' }, 0)
    },

    onChange (value, item) {
      if (item.children) {
        for (let i in item.children || []) {
          item.children[i].checked = value
          this.onChange(value, item.children[i])
        }
      }
      if (!value && item.parents && item.parents.length) {
        for (let i in item.parents) {
          item.parents[i].checked = value
        }
      }
    }
  },

  computed: {
    hasBodySlot () {
      return !!this.$slots['body']
    }
  }
}

</script>

<style lang="scss" scoped>

  .v-expasion-checkbox {
    width: 100%;

    &__header {
      display: flex;
      width:fit-content;
      justify-content: center;
      position: relative;
      padding-right: 3px;
      gap: 8px;
      &:not(:last-child){
        margin-bottom: 8px;
      }

      &--title {
        cursor: pointer;
        padding-right: 12px;
        padding-top: 4px;
        line-height: 20px;
      }

      .triangle{
          display: flex;
          align-items: center;
          transition: .3s all;
          transform: rotate(180deg);
          cursor: pointer;

        &:before {
            content: '';
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 5px 7px 5px 0;
            border-color: transparent $body-text transparent transparent;
            display: inline-block;
            vertical-align: middle;
        }
      }

      .toggled {
          transform: rotate(270deg);
      }
    }

    &__body {
      overflow: hidden;
      transition: 150ms ease-out;
      padding-left: 28px ;
    }

  }

</style>
