<template lang="pug">
</template>

<script>
import cookie from '@/util/cookie'
import { mapActions, mapGetters } from 'vuex'
import { ENV, TOKEN_COOKIE, INSTANCE_URL } from '@/util/constants'
import { getDomainFromUrl } from '@/util/utils.js'

const logoutRedirectUri = '/logout/redirect'
const BASE_URL = process.env.VUE_APP_BASE_URL
const parsedHref = window.location.href.split('/')
const hostname = parsedHref[0] + '//' + parsedHref[2]

const providesList = {
  'AZURE_AD': 'azure',
  'GOOGLE_CLOUD': 'google'
}

export default {
  name: 'AzureLoginHandler',

  props: {
    thirdPartyData: {
      type: Object,
      default: () => ({
        loading: false
      })
    }
  },

  data: () => ({
    logInHref: '',
    token: null,
    currentLoginWindow: {},
    interval: null,
    currentLogoutWindow: {},
    logoutInterval: null,
    hrefLogout: '',
    redirectUrl: ''
  }),

  methods: {
    ...mapActions('app', [
      'initApp',
      'setIsThirdPartyDevLoginEnabled'
    ]),

    async login () {
      await this.initApp()
      this.$router.push('/')
      if (!this.isThirdPartyLoginEnabled && this.isDevelopment) {
        this.setIsThirdPartyDevLoginEnabled(true)
      }
    },

    onClickauth (provider) {
      if (this.thirdPartyData.loading) return
      try {
        this.thirdPartyData.loading = true
        const debugEndpointUrl = localStorage.getItem('endpointUrl') || ''
        const newWin = window.open(`${debugEndpointUrl || BASE_URL}/login/oauth/${providesList[provider]}?redirectUri=${hostname}/login`, 'auth', 'width=580,height=680,resizable=no')

        this.currentLoginWindow = newWin

        this.interval = setInterval(() => {
          try {
            this.logInHref = newWin.location.href || ''
            if (newWin && newWin.closed) {
              this.thirdPartyData.loading = false
            }
          } catch (e) {
            if (newWin && newWin.closed) {
              this.thirdPartyData.loading = false
            }
          }
        }, 500)

        newWin.onload = () => {
          this.logInHref = newWin.location.href || ''
          if (newWin && newWin.closed) {
            this.thirdPartyData.loading = false
          }
        }
      } catch (e) {
      }
    }
  },

  computed: {
    ...mapGetters('app', [
      'isThirdPartyLoginEnabled',
      'thirdPartyLoginProvider'
    ]),

    provider () {
      if (this.thirdPartyLoginProvider) {
        switch (this.thirdPartyLoginProvider.id) {
          case 'AZURE_AD':
            return 'azure'
          case 'GOOGLE_CLOUD':
            return 'google'
        }
      }
      return null
    },

    isDevelopment () {
      return ENV.SHOW_DEBUG_TOOLS
    }
  },

  watch: {
    logInHref: {
      handler (value) {
        if (value) {
          const url = new URL(value)
          this.token = url.searchParams.get('token')
          this.redirectUrl = url.searchParams.get('redirectUrl')
        }
      },
      deep: true
    },

    hrefLogout: {
      handler (value) {
        if (value === logoutRedirectUri) {
          clearInterval(this.logoutInterval)
          this.currentLogoutWindow.close()
        }
      },
      deep: true
    },

    token (value) {
      if (value) {
        const domain = getDomainFromUrl(window.location.href)
        cookie.setItem(TOKEN_COOKIE, value, 0, domain)
        cookie.setItem(INSTANCE_URL, this.redirectUrl, 0, domain)
        clearInterval(this.interval)
        this.currentLoginWindow.close()
        this.login()
        if (this.redirectUrl) {
          window.location.href = `${this.redirectUrl}?${TOKEN_COOKIE}=${value}`
        }
      }
    }

  }
}
</script>

<style lang="scss" scoped>
</style>
