<template lang="pug">
.tree-container
  svg-pan-zoom.svg-pan-zoom(
    center
    zoom-enabled
    :fit="false"
    :controlIconsEnabled="false"
    :dblClickZoomEnabled="false"
    :minZoom="minZoom"
    :maxZoom="maxZoom"
    :onZoom="onZoomChange"
    :beforePan="onPan"
    :customEventsHandler="customEventsHandler"
    @svgpanzoom="registerSvgPanZoom")
      svg.tree-svg(
        id="treeSvg"
        @keydown.esc="discardNodeChanges"
        @click.self="clickOutsideTree"
        @dblclick.self="clickOutsideTree")
        tree-node(
          v-if="tree.props"
          :node="tree"
          :depth="1"
          :zoom="zoom"
          :filtered-badges="filteredBadges"
          :is-editable="editable"
          @node-select="selectNodeLogic"
          @node-right-click="onOpenContextMenu")
        g(ref="topSVGLayer")
  //- Outside SVG
  template(v-if="showControls")
    zoom-buttons(
      :value="zoom"
      :min-zoom="minZoom"
      :max-zoom="maxZoom"
      :zoom-step="zoomStep"
      @on-zoom="changeZoom")
    context-menu(
      :pos="nodeContextMenu.pos"
      :show="nodeContextMenu.show"
      :is-ctrl="nodeContextMenu.isCtrl"
      @close="closeContextMenu")

</template>

<script>
import TWEEN from '@tweenjs/tween.js'
import helpers from '@/util/helpers.js'

import { NODE_TYPE } from '@/util/constants'
import { isSpecialKeyPressed } from '@/util/utils'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { initDragable, unsetDragable } from '@/util/drag-n-drop.js'

import ZoomButtons from '@/components/controls/ZoomButtons'
import ContextMenu from '@/components/modals/ContextMenu'
import TreeNode from './TreeNode'
import { PREFERENCES_VIEWS_TOGGLER } from '@/util/constants.js'

export default {
  name: 'tree',

  components: {
    ZoomButtons,
    ContextMenu,
    TreeNode
  },

  props: {
    tree: {
      type: Object,
      default: () => {}
    },
    editable: {
      type: Boolean,
      default: false
    },
    filteredBadges: {
      type: Array,
      default: () => []
    },
    showControls: Boolean
  },

  destroyed () {
    this.unsetContextMenuNode()
  },

  mounted () {
    requestAnimationFrame(this.animate)
    if (this.editable) {
      initDragable(this.svgpanzoom)
    }
    if (this.badgeId) {
      this.zoomToBadge(this.badgeId)
    }
    if (this.suggestedBadgeId) {
      this.zoomToBadge(this.suggestedBadgeId)
    }
    window.addEventListener('resize', () => { this.setMinZoom() })
    this.setMinZoom(true)
    this.setTopLayerEl(this.$refs.topSVGLayer)
    this.$store.commit('treeView/SET_CALLBACK_FOR_SET_MIN_ZOOM', this.setMinZoom)
    if (!this.badgeId && this.employeeId) {
      this.selectMySkilltree()
      this.changeToggler(PREFERENCES_VIEWS_TOGGLER[0])
    } else if ((this.badgeId && this.employeeId) || this.badgeId) {
      this.unselectMySkilltree()
      this.changeToggler(PREFERENCES_VIEWS_TOGGLER[1])
    }
  },

  data: () => ({
    svgpanzoom: null,
    minZoom: helpers.minZoom(),
    maxZoom: 3,
    zoomStep: 0.4,
    zoom: 1,
    selectedNodePos: { x: 0, y: 0 },
    selectedEl: null,
    isPanedBefore: false,
    customEventsHandler: helpers.customEventsHandler()
  }),

  methods: {
    ...mapActions('trees', [
      'removeNode',
      'getNodeById',
      'getNodeBySubbadgeId',
      'selectMySkilltree',
      'unselectMySkilltree',
      'rearrangeTree'
    ]),

    ...mapActions('treeView', [
      'setHilightSubBranche',
      'setShowSuggestBadges'
    ]),

    ...mapActions('app', [
      'showConfirmDialog'
    ]),

    ...mapActions('svgTree', [
      'initSvgtree',
      'zoomToNode',
      'setTreeOffset'
    ]),

    ...mapActions('svgTopLayer', [
      'setTopLayerEl'
    ]),

    ...mapActions('nodeTmp', [
      'selectNode',
      'unselectNode',
      'isNodeSelected',
      'setCursorNode',
      'unsetCursorNode',
      'setContextMenuNode',
      'unsetContextMenuNode',
      'unselectAllNodes',
      'discardNodeChanges',
      'setDragedBefore',
      'setCtrlPressedBefore'
    ]),

    ...mapActions('preferences', [
      'changeToggler'
    ]),

    ...mapActions('badges', [
      'getBadgeById'
    ]),

    ...mapMutations('nodeTmp', {
      setPropsNodeContextMenu: 'SET_PROPS_NODE_CONTEXT_MENU'
    }),

    ...mapMutations('trees', {
      setMultipleRootNode: 'MULTIPLE_ROOT_NODE',
      setStructureContextMenu: 'STRUCTURE_CONTEXT_MENU'
      }),

    setMinZoom (zoomBy = false) {
      if (!this.tree.children.length) {
        this.svgpanzoom.disableZoom()
      } else {
        this.svgpanzoom.enableZoom()
      }
      this.svgpanzoom.updateBBox()
      const sizes = this.svgpanzoom.getSizes()
      let boxWindth, boxheight, factor, widthFactor, heightFactor
      boxWindth = sizes.viewBox.width * sizes.realZoom
      boxheight = sizes.viewBox.height * sizes.realZoom
      widthFactor = window.innerWidth / boxWindth
      heightFactor = (window.innerHeight - 80) / boxheight
      factor = heightFactor >= widthFactor
        ? (widthFactor * sizes.realZoom) * 0.65
        : (heightFactor * sizes.realZoom) * 0.65
      factor = factor > 2.5
        ? 2.5
        : factor

      this.minZoom = factor
      this.svgpanzoom.setMinZoom(factor)
      if (zoomBy) {
        this.svgpanzoom.zoomBy(factor / 0.85 * 0.95)
        this.svgpanzoom.center()
      }
    },

    onNodeRemove () {
      const options = {
        title: 'ui.modals.do_you_really_want_to_delete_this',
        params: { node: this.cursorNode },
        onconfirm: this.removeNode
      }
      this.showConfirmDialog(options)
    },

    registerSvgPanZoom (svgpanzoom) {
      this.svgpanzoom = svgpanzoom
      this.initSvgtree(svgpanzoom)
      this.svgpanzoom.zoom(this.zoom)
      this.svgpanzoom.center()
    },

    onZoomChange (value) {
      this.zoom = value[0]
      this.$emit('on-zoom', value)
    },

    onPan (oldPan, newPan) {
      this.isPanedBefore = true
      const pan = {
        x: newPan.x - oldPan.x,
        y: newPan.y - oldPan.y
      }
      this.setTreeOffset(newPan)
      this.calcSelectedNodePos()
      const pos = {
        x: this.nodeContextMenu.pos.x + pan.x,
        y: this.nodeContextMenu.pos.y + pan.y
      }
      this.setPropsNodeContextMenu({ pos })
      this.$emit('on-pan', pan)
    },

    calcSelectedNodePos () {
      if (this.selectedEl) {
        const rect = this.selectedEl.getBoundingClientRect()
        const width = rect.right - rect.left
        this.selectedNodePos.x = rect.left + width / 2
        this.selectedNodePos.y = rect.top - (20 * this.zoom + 30)
      }
    },

    changeZoom (value) {
      const transition = { zoom: this.zoom }
      const tween = new TWEEN.Tween(transition)
      tween.to({ zoom: value }, 400)
        .onUpdate(() => {
          this.zoom = transition.zoom
          this.svgpanzoom.zoom(this.zoom)
        })
        .start()
    },

   async selectNodeLogic (payload) {
    // get data badge when there is only a tree(not full data) structure
    if (this.isLoadLazyTree) {
      const params = {}
      if (this.employeeId) {
        params.forEmployeeId = this.employeeId
      }
      await this.getBadgeById({ node: payload.node, params })
    }
      if (!(this.cursorNode && this.cursorNode.localProps.validationOpened)) {
        if (!this.dragndrop.dragedBefore) {
          payload.node.localProps.el = payload.el
          if (payload.event &&
            this.editable &&
            isSpecialKeyPressed(payload.event)) {
            if (payload.node.props.typeId === NODE_TYPE.BADGE ||
              payload.node.props.typeId === NODE_TYPE.GRADATIONAL_BADGE) {
              if (this.cursorNode) {
                this.unsetCursorNode()
              }
              if (this.hasSelectedNodes) {
                if (this.selectedNodes[0].props.typeId === NODE_TYPE.CATEGORY ||
                  this.selectedNodes[0].props.typeId === NODE_TYPE.HOOKED_BADGE) {
                  this.unselectAllNodes()
                }
              }
              this.toggleNodeSelect(payload.node)
            }
          } else {
            this.unselectAllNodes()
            this.setCursorNode(payload.node)
            if (payload.node &&
              payload.node.props.typeId !== NODE_TYPE.CATEGORY) {
              this.toggleNodeSelect(payload.node)
            }
            this.onNodeSelect(payload)
          }
        } else {
          this.setDragedBefore(false)
        }
      }
    },

    async toggleNodeSelect (node) {
      if (!await this.isNodeSelected(node)) {
        this.selectNode(node)
      } else {
        this.unselectNode(node)
      }
    },

    onNodeSelect (payload) {
      if (this.lastAddedNode &&
        this.lastAddedNode !== payload.node) {
        this.discardNodeChanges()
      }
      this.setPropsNodeContextMenu({ show: false })
      this.selectedEl = payload.el
      this.calcSelectedNodePos()
      this.$emit('open-modal', payload)
    },

    onOpenContextMenu (payload) {
      this.setStructureContextMenu({ show: false })
      if (this.editable) {
        if (payload.node !== this.cursorNode) {
          this.unsetCursorNode()
        }
        const props = {
          isCtrl: isSpecialKeyPressed(payload.event),
          pos: {
            x: payload.pos.x + 30 * this.zoom,
            y: payload.pos.y + 30 * this.zoom - 80
          },
          show: true
        }
        this.setPropsNodeContextMenu(props)
        this.setContextMenuNode(payload.node)
      }
    },

    closeContextMenu () {
      this.setPropsNodeContextMenu({ show: false, isCtrl: false })
      this.unsetContextMenuNode()
    },

    clickOutsideTree (e) {
      if (!this.lastAddedNode && !this.isPanedBefore) {
        if (this.cursorNode && !this.cursorNode.localProps.validationOpened) {
          this.selectedEl = null
          this.unsetCursorNode()
          this.$emit('click-outside-tree')
        }
      } else {
        this.isPanedBefore = false
      }
      if (!isSpecialKeyPressed(e)) {
        this.unselectAllNodes()
      }
    },

    animate (time) {
      requestAnimationFrame(this.animate)
      TWEEN.update(time)
    },

    async zoomToBadge (id) {
      const node = await this.getNodeBySubbadgeId(id)
      if (node) {
        const payload = {
          node: node,
          pos: { x: 0, y: 0 }
        }
        this.zoomToBadgeLogic(payload)
        const changeHookOrBadge = node.props.id !== this.badgeId && node?.props?.hookedBadge ? node?.props?.hookedBadge : node
        if (this.isLoadLazyTree) {
          const params = {}
          if (this.employeeId) {
            params.forEmployeeId = this.employeeId
          }
          await this.getBadgeById({ node: changeHookOrBadge, params })
        }
        this.setCursorNode(changeHookOrBadge)
      }
    },

    zoomToBadgeLogic (payload) {
      this.zoomToNode({
        node: payload.node,
        beforeZoom: () => { this.$emit('close-modal') },
        afterZoom: (payload) => { this.$emit('open-modal', payload) }
      })
    }
  },

  computed: {
    ...mapGetters('nodeTmp', [
      'cursorNode',
      'lastAddedNode',
      'selectedNodes',
      'hasSelectedNodes',
      'dragndrop',
      'nodeContextMenu'
    ]),

    ...mapGetters('preferences', ['dinamicTogglView']),

    ...mapGetters('trees', ['isLoadLazyTree', 'multipleRootNode']),

    badgeId () {
      return parseInt(this.$route.params?.badge_id) || null
    },
    employeeId () {
      return parseInt(this.$route.params?.employee_id)
    },

    suggestedBadgeId () {
      if (this.$route.params.suggested_badge_id) {
        this.setShowSuggestBadges(true)
      }
      return parseInt(this.$route.params.suggested_badge_id) || null
    },

    isBuilderView () {
      return this.$route.path.includes('builder')
    }
  },

  watch: {
    editable (value) {
      if (value) initDragable(this.svgpanzoom)
      else unsetDragable()
    },

   async  badgeId (value) {
     await this.unselectMySkilltree(false)
     this.changeToggler(PREFERENCES_VIEWS_TOGGLER[1])
      if (value !== null) {
        setTimeout(() => {
          this.zoomToBadge(value)
        }, 1)
      }
    },

    async suggestedBadgeId (value) {
      if (value !== null) {
        this.setMultipleRootNode(1)
        await this.rearrangeTree()
        await this.setMinZoom(true)
        this.zoomToBadge(value)
      }
    },

    multipleRootNode (value) {
      this.zoomToBadge(value)
    },

    async dinamicTogglView (newValue, oldValue) {
      setTimeout(async () => {
        await this.setMinZoom(!this.badgeId)
      }, 1)
    },

    isBuilderView (value) {
      setTimeout(async () => {
        await this.setMinZoom()
      }, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .tree-container {
    height: 100%;
    width: 100%;

    .svg-pan-zoom {
      height: 100%;
      width: 100%;
    }

    .tree-svg {
      height: 100%;
      width: 100%;
    }

    .draged-node {
      opacity: 0.8;
    }
  }
</style>
