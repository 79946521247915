<template lang="pug">
div(ref="detachedPistionRef" style="position: relative")
   transition(name="dropdown")
      .v-dropdown-detached(
         v-click-outside="closeDropdown"
         ref="vDropdownSearchList"
         v-if="toggled")
         .v-dropdown-detached--absolute(:style="!isTop ? {top:0} : {bottom: 0}")
            .v-dropdown-detached--wrapper
               .v-dropdown-detached--content.my-2(
                  ref="content"
                  :class="[{transition_all: enableContentTransition}, {order_2: !isTop}]")
                  vue-scroll(@handle-scroll="onScroll")
                     div(style="background-color: white" ref="heightTracker")
                        slot(name="content")
               .v-dropdown-detached--component(ref="component" :class="{order_1: !isTop}")
                  slot(name="component")
</template>

<script>
import { windowResizeObserver } from '@/util/helpers'
import ResizeObserver from '@juggle/resize-observer'
const DEFAULT_OFFSET = 200
export default {
   props: {
      disabledScrollId: String,
      bottomAlign: Boolean,
      topAlign: Boolean
   },

   data: (vm) => ({
    toggled: false,
    resizeObserver: null,
    enableContentTransition: false,
    isTop: vm.topAlign
  }),

  methods: {
   open () {
      this.toggled = true
      this.setScrollableFixedPosition()
      this.toggleParentScroll()
   },

   setScrollableFixedPosition () {
      let { top: topOffset, width } = this.$refs.detachedPistionRef.getBoundingClientRect()
      setTimeout(() => {
         this.initContentResizeObserver()
         this.$refs.vDropdownSearchList.style.top = `${topOffset}px`
         this.$refs.vDropdownSearchList.style.width = `${width}px`
        }, 0)
   },

   toggleParentScroll () {
      if (this.disabledScrollId) {
         const scrollByIDPanel = document.getElementById(this.disabledScrollId)
         this.toggled
            ? scrollByIDPanel.firstChild.classList.add('block-root-scroll')
            : scrollByIDPanel.firstChild.classList.remove('block-root-scroll')
      }
   },

   setContentHeight (contentheight) {
      if (this.$refs.content) {
         const topOffset = this.$refs.detachedPistionRef.getBoundingClientRect().top
         const componentHeight = this.$refs.component.getBoundingClientRect().height
         const windowHeight = window.innerHeight

         if (!this.bottomAlign && !this.topAlign && (windowHeight - (topOffset + componentHeight + 32 + 20 + 7)) > DEFAULT_OFFSET) {
            this.isTop = false
         } else if (!this.bottomAlign && !this.topAlign) {
            this.isTop = true
         }
         if (this.isTop && (contentheight + componentHeight) > (topOffset - 80 - 20 - 32 - 7)) { // 80px = header height, 20px = standart offset, 32-padding, 7 - margin top component
            contentheight = topOffset - 80 - 20 - 32 - 7 - componentHeight
         }
         if (!this.isTop && (topOffset + componentHeight + contentheight + 32 + 20 + 7 > windowHeight)) {
            contentheight = windowHeight - topOffset - componentHeight - 32 - 20 - 7
         }

         this.$refs.content.style.height = `${contentheight}px`
         this.enableContentTransition = true
      }
   },

   closeDropdown () {
      this.resizeObserver.unobserve(this.$refs.heightTracker)
      this.toggled = false
      this.toggleParentScroll()
      this.$emit('toggle', this.toggled)
      if (!this.toggled) {
         this.enableContentTransition = false
      }
   },

   checkPosition () {
      if (this.toggled) {
         this.setScrollableFixedPosition()
      }
   },

   initContentResizeObserver () {
      this.resizeObserver = new ResizeObserver(entries => {
         if (this.toggled) {
            this.setContentHeight(entries[0].contentRect.height)
         }
      })
      this.resizeObserver.observe(this.$refs.heightTracker)
    },

   onScroll (vertical, horizontal, nativeEvent) {
      this.$emit('on-scroll')
      const scrollHeight = nativeEvent.target.scrollHeight - nativeEvent.target.clientHeight
      let scrollTop = vertical.scrollTop
      if (!Number.isInteger(scrollTop) && window.devicePixelRatio > 1) { // fix ZOOM 100+
        scrollTop = Math.ceil(scrollTop)
      }
      if (Math.ceil(scrollTop) >= scrollHeight) {
        this.$emit('scroll-complete', this.$refs.scroll)
      }
    }
  },

  mounted () {
   windowResizeObserver.subscribe(this.closeDropdown)
  },

  beforeDestroy () {
   if (this.toggled) {
      this.resizeObserver.unobserve(this.$refs.heightTracker)
   }
   windowResizeObserver.unsubscribe(this.closeDropdown)
  }
}
</script>

<style lang="scss">
   .v-dropdown-detached {
      position: fixed;
      z-index: 2;
   }
   .v-dropdown-detached--content {
      position: relative;
      height: 100%;
      overflow: hidden;
      padding-right: 12px;
   }

   .v-dropdown-detached--component {
      padding-right: 12px;
   }
   .v-dropdown-detached--wrapper {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
      border-radius: 4px;
      padding: 16px 4px 16px 16px;
      box-sizing: border-box;
   }
   .v-dropdown-detached--absolute {
      position: absolute;
      width: 100%;
   }
   .order_1{
      order:1;
   }
   .order_2{
      order:2;
   }
</style>
