import Vue from 'vue'
import Vuex from 'vuex'

import app from './app/app'
import trees from './tree/trees'
import setupWizard from './app/setupWizard'
import skillFinder from './skillfinder'
import treeView from './tree/treeView'
import debug from './app/debug'
import notifications from './app/notifications'

import badges from './badges'
import builder from './builder'
import departments from './administration/departments'
import employees from './administration/employees'
import employee from './employee'
import goals from './goals'
import locations from './administration/locations'
import organization from './administration/organization'
import skills from './administration/skills'
import modals from './modals'
import onboarding from './onboarding/index'
import dragNDrop from './tree/drag-n-drop'
import permissions from './administration/permissions'
import positions from './administration/positions'
import roles from './administration/roles'
import nodeTmp from './tree/node-tmp'
import svgTree from './tree/svg-tree'
import svgTopLayer from './tree/svg-top-layer'
import tree from './tree/tree'
import insightsAllSkills from './insights/all-skills'
import insightsGoals from './insights/goals'
import suggestions from './suggestions'
import insightsMatrix from './insights/matrix'
import preferences from './app/preferences'
import skillMatrix from './skillMatrix'
import skillWizard from './skillWizard'
import settings from './settings'

import search from './search/index'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    badges,
    onboarding,
    trees,
    setupWizard,
    skillFinder,
    treeView,
    debug,
    notifications,
    departments,
    employees,
    employee,
    goals,
    locations,
    organization,
    skills,
    modals,
    permissions,
    positions,
    roles,
    dragNDrop,
    svgTree,
    svgTopLayer,
    nodeTmp,
    tree,
    builder,
    insightsAllSkills,
    insightsGoals,
    suggestions,
    insightsMatrix,
    preferences,
    search,
    skillMatrix,
    skillWizard,
    settings
  }
})

export default store
