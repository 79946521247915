<template>
  <svg
    width="60"
    height="64"
    viewBox="0 0 60 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="11.5" cy="50.0848" rx="9.5" ry="3.5" fill="#F0F2FA" />
    <path
      d="M42.3168 5.51834C42.0741 4.61279 42.6115 3.68198 43.5171 3.43934C44.4227 3.1967 45.3535 3.7341 45.5961 4.63965L46.7631 8.99485C47.0057 9.90041 46.4683 10.8312 45.5628 11.0739C44.6572 11.3165 43.7264 10.7791 43.4838 9.87354L42.3168 5.51834Z"
      fill="#29A687"
    />
    <path
      d="M22.1984 5.51834C21.9557 4.61279 22.4931 3.68198 23.3987 3.43934C24.3042 3.1967 25.235 3.7341 25.4777 4.63965L26.6447 8.99485C26.8873 9.90041 26.3499 10.8312 25.4444 11.0739C24.5388 11.3165 23.608 10.7791 23.3653 9.87354L22.1984 5.51834Z"
      fill="#29A687"
    />
    <path
      d="M39.9285 40.2821H12.8943C11.7208 40.1982 9.51191 39.1378 10.0652 35.5668C10.6184 31.9958 12.6429 20.8342 13.5859 15.6998L14.4032 10.9216C14.5709 9.55945 15.9247 6.8225 19.9987 6.77221C24.0727 6.72191 40.4734 6.75125 48.1646 6.77221C52.0625 7.40091 53.2571 8.2811 54.3258 14.8196L57.9095 35.7554C58.2867 38.0187 57.6203 40.2821 53.1942 40.2821L40.8087 40.2621C40.5237 40.2756 40.2302 40.2821 39.9285 40.2821Z"
      fill="#30B091"
    />
    <path
      opacity="0.8"
      d="M51.8983 15.6998H15.0564C14.1133 20.8342 12.0889 31.9958 11.5356 35.5668C10.9824 39.1378 13.1912 40.1982 14.3648 40.2821H41.399C45.0455 40.2821 47.4974 39.339 48.2519 36.3212L51.8983 15.6998Z"
      fill="#21856C"
    />
    <path
      d="M50.4279 15.6998H13.5859C12.6429 20.8342 10.6184 31.9958 10.0652 35.5668C9.51191 39.1378 11.7208 40.1982 12.8943 40.2821H39.9285C43.575 40.2821 46.027 39.339 46.7814 36.3212L50.4279 15.6998Z"
      fill="#D8DAE3"
    />
    <path
      d="M50.4279 15.6998H13.5859C12.6428 20.8342 10.8699 30.7384 10.3166 34.3094C9.76338 37.8804 11.9722 38.9408 13.1458 39.0247H40.18C43.8265 39.0247 46.2784 38.0816 47.0329 35.0638L50.4279 15.6998Z"
      fill="#E3F6F1"
    />
    <path
      d="M27.6688 17.8374H22.0104L21.0674 23.4957H26.6628L27.6688 17.8374Z"
      fill="#94D3C3"
    />
    <path
      d="M26.4742 24.5016H20.8159L19.8728 30.16H25.4683L26.4742 24.5016Z"
      fill="#94D3C3"
    />
    <path
      d="M25.3426 31.1659H19.6843L18.7412 36.8242H24.3367L25.3426 31.1659Z"
      fill="white"
    />
    <path
      d="M19.8101 24.5016H14.1518L13.2087 30.16H18.8042L19.8101 24.5016Z"
      fill="#94D3C3"
    />
    <path
      d="M18.6783 31.1659H13.02L12.0769 36.8242H17.6724L18.6783 31.1659Z"
      fill="white"
    />
    <path
      d="M34.3961 17.8374H28.7377L27.7947 23.4957H33.3901L34.3961 17.8374Z"
      fill="#94D3C3"
    />
    <path
      d="M33.2015 24.5016H27.5432L26.6001 30.16H32.1956L33.2015 24.5016Z"
      fill="#94D3C3"
    />
    <path
      d="M32.0696 31.1659H26.4113L25.4683 36.8242H31.0637L32.0696 31.1659Z"
      fill="white"
    />
    <path
      d="M41.1231 17.8374H35.4648L34.5217 23.4957H40.1172L41.1231 17.8374Z"
      fill="#94D3C3"
    />
    <path
      d="M39.9285 24.5016H34.2702L33.3271 30.16H38.9226L39.9285 24.5016Z"
      fill="#94D3C3"
    />
    <path
      d="M38.7969 31.1659H33.1386L32.1956 36.8242H37.791L38.7969 31.1659Z"
      fill="white"
    />
    <path
      d="M47.8502 17.8374H42.1918L41.2488 23.4957H46.8442L47.8502 17.8374Z"
      fill="#94D3C3"
    />
    <path
      d="M46.6556 24.5016H40.9973L40.0542 30.16H45.6497L46.6556 24.5016Z"
      fill="white"
    />
    <path
      d="M22.1067 4.80306C22.2508 3.8767 23.1186 3.24259 24.045 3.38673C24.9713 3.53087 25.6055 4.39867 25.4613 5.32503L24.6022 10.8463C24.4319 11.9411 23.4063 12.6905 22.3115 12.5202C21.5536 12.4023 21.0347 11.6922 21.1527 10.9343L22.1067 4.80306Z"
      fill="#21856C"
    />
    <path
      d="M42.2908 4.80306C42.4349 3.8767 43.3027 3.24259 44.2291 3.38673C45.1554 3.53087 45.7895 4.39867 45.6454 5.32503L44.7863 10.8463C44.6159 11.9411 43.5904 12.6905 42.4956 12.5202C41.7376 12.4023 41.2188 11.6922 41.3368 10.9343L42.2908 4.80306Z"
      fill="#21856C"
    />
    <rect
      x="22.3677"
      y="3.12574"
      width="3.39499"
      height="7.90383"
      rx="1.6975"
      transform="rotate(8.84418 22.3677 3.12574)"
      fill="#29A687"
    />
    <path
      d="M42.2908 4.80306C42.4349 3.8767 43.3027 3.24259 44.2291 3.38673C45.1554 3.53087 45.7895 4.39867 45.6454 5.32503L44.9522 9.78025C44.808 10.7066 43.9402 11.3407 43.0139 11.1966C42.0875 11.0524 41.4534 10.1846 41.5975 9.25828L42.2908 4.80306Z"
      fill="#29A687"
    />
    <path
      opacity="0.8"
      d="M57.6605 46.3225C56.9208 40.3263 51.4602 36.0651 45.464 36.8048C43.7636 37.0146 42.2028 37.604 40.856 38.4775L35 34.4526L37.632 41.6986C36.2751 43.7832 35.6179 46.3395 35.9463 49.0014C36.6861 54.9976 42.1467 59.2588 48.1429 58.5191C54.1391 57.7793 58.4003 52.3187 57.6605 46.3225Z"
      fill="url(#paint0_radial_3806_21706)"
    />
    <path
      d="M53.6896 47.9807L37.8793 56.3617L29.5239 51.0479L29.5931 49.5337L44.8818 41.4989L45.5296 41.1584L53.7531 46.5926L53.6896 47.9807Z"
      fill="#29A687"
    />
    <path
      opacity="0.5"
      d="M37.8792 56.3617L37.937 55.0998L38.7148 54.6814L52.9752 47.011L53.753 46.5926L53.6895 47.9806L37.8792 56.3617Z"
      fill="#21856C"
    />
    <path
      d="M37.9778 54.2051L31.1 49.759L38.2945 45.9004C38.4377 45.7995 38.6244 45.6959 38.849 45.603L45.489 42.0417L52.2605 46.5243L45.2604 50.2888C45.2132 50.3168 45.1659 50.3421 45.1192 50.3647L37.9778 54.2051Z"
      fill="white"
    />
    <path
      d="M38.5754 49.4388C36.5294 47.7267 37.5357 46.3665 38.2947 45.9004C39.144 45.302 41.5278 44.6067 44.2685 46.6127C47.0092 48.6186 45.9777 49.9498 45.1193 50.3647C43.3955 51.2917 41.1329 51.579 38.5754 49.4388Z"
      fill="#29A687"
    />
    <path
      d="M46.4517 45.8792C46.0131 45.557 45.8554 45.0932 46.1193 44.8524C46.3832 44.6116 46.7618 44.6289 47.3811 44.9101C48.0005 45.1914 48.2298 45.7077 47.9659 45.9485C47.5544 46.324 46.8187 46.1489 46.4517 45.8792Z"
      fill="#29A687"
    />
    <path
      d="M36.0855 51.3481C35.647 51.0259 35.4892 50.5621 35.7531 50.3213C36.017 50.0805 36.3956 50.0978 37.015 50.379C37.6344 50.6603 37.8636 51.1766 37.5997 51.4174C37.1882 51.7929 36.4525 51.6178 36.0855 51.3481Z"
      fill="#29A687"
    />
    <path
      d="M37.8792 56.3617L37.9369 55.0998L30.452 50.1067L29.593 49.5337L29.5237 51.0479L37.8792 56.3617Z"
      fill="#21856C"
    />
    <path
      opacity="0.39"
      d="M16.1526 47.3583C13.6776 48.5494 12.3935 49.3175 10.7037 50.3093L11.4883 50.3574C13.1781 49.3656 14.4622 48.5976 16.9372 47.4064C18.1135 46.837 19.0853 45.9554 19.7335 44.8697C20.3779 43.8358 20.76 42.6744 20.8504 41.4754L22.52 30.5681L13.7889 28.527L13.0044 28.4789L21.5981 30.5681L20.0658 41.4272C19.9754 42.6262 19.5933 43.7876 18.9489 44.8215C18.3007 45.9073 17.3289 46.7889 16.1526 47.3583Z"
      fill="#A0AAC3"
    />
    <path
      d="M15.7858 47.3909C13.5551 48.5967 12.3967 49.3723 10.8726 50.3741L11.5772 50.4174C13.1013 49.4157 14.2596 48.6401 16.4903 47.4343C17.5505 46.858 18.429 45.9707 19.018 44.8814C19.6031 43.844 19.9535 42.6807 20.042 41.4816L21.6085 30.5681L13.7771 28.5809L13.0726 28.5376L20.9039 30.5247L19.3375 41.4382C19.2489 42.6373 18.8985 43.8006 18.3134 44.8381C17.7244 45.9274 16.846 46.8146 15.7858 47.3909Z"
      fill="#21856C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8731 50.3741C12.3972 49.3723 13.5555 48.5967 15.7863 47.3909C16.8464 46.8146 17.7249 45.9274 18.3139 44.8381C18.899 43.8006 19.2494 42.6373 19.3379 41.4382L20.9044 30.5247L13.073 28.5376L5.04287 29.5737L3.47632 40.4882C3.42081 41.6893 3.62957 42.8862 4.08644 43.9861C4.54094 45.1378 5.30677 46.1235 6.2901 46.8226C9.18281 48.8637 9.41163 49.2115 10.8731 50.3741Z"
      fill="#29A687"
    />
    <defs>
      <radialGradient
        id="paint0_radial_3806_21706"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(47 46.9526) rotate(-131.348) scale(16.6508 14.2893)"
      >
        <stop stop-color="#E3F6F1" />
        <stop offset="1" stop-color="#B1DED4" />
      </radialGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  names: 'NotificationsTrialExpireIcon'
}
</script>
