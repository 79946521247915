<template lang="pug">
  .v-toast
    .v-toast__icon
      i.fa.fa-info-circle
    .v-toast__content
      p(v-html="text")
      slot
</template>

<script>
export default {
  name: 'VToast',

  props: {
    text: String
  },

  methods: {
    remove () {
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss">
  .v-toast {
    position: relative;
    display: flex;
    align-items: stretch;
    min-width: 240px;
    max-width: 400px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 2px 2px 20px 0 rgba(37, 47, 57, .15);
    opacity: 0;
    animation: toastin 1s cubic-bezier(0.25,0.1,0.25,1) forwards;
    animation-delay: .5s;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &__icon {
      padding: 20px;
      padding-right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 34px;
        opacity: .4;
      }
    }

    &__content {
      padding: 15px 20px;
      width: calc(100% - 54px);
      display: flex;
      flex-direction: column;

      p:not(:last-child) {
        margin-bottom: 10px;
      }

      .flex {
        justify-content: flex-end;
      }
    }

    &__remove-btn {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }

  @keyframes toastin {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
