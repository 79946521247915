import { render, staticRenderFns } from "./ArrowTop.vue?vue&type=template&id=47386a9f&scoped=true&functional=true&"
import script from "./ArrowTop.vue?vue&type=script&lang=js&"
export * from "./ArrowTop.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "47386a9f",
  null
  
)

export default component.exports