<template lang="pug">
    v-card
        v-card-head
          h2.title {{'ui.labels.change_template' | translate}}
          v-btn(icon, gray, @click="$emit('close')")
            icon-close(color="#A0AAC3")
        v-card-content
          .search-result__field.mb-3
            v-input-search(
              :placeholder="'ui.inputs.type_to_search' | translate"
              type="text"
              v-model="search"
            )
          v-scrollable( :min-height="20" :max-height="330" :bottom-offset="10")
            template(v-if="templates.length")
              v-list-item(
                v-for="temp in filterTemplatesChange"
                :key="`${temp.name}_${temp.id}`"
                @click="$emit('changeTemplate',temp.id)")
                v-radio(
                  :id="temp.id"
                  :active="selected && selected.badgeTemplateId === temp.id"
                )
                v-list-item-title(:class="{active: selected && selected.badgeTemplateId === temp.id}")
                  | {{firstLetterUpperCase(temp.name)}}
              v-list-empty(v-if="!filterTemplatesChange.length")
                .icon.icon--sm.icon--no-result
                span {{ 'ui.labels.no_results' | translate }}
</template>
<script>
import IconClose from '@/components/svg/IconClose.vue'
export default {
  name: 'ExploreModalChageTemplate',
  components: {
    IconClose
  },
  props: {
    templates: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Object,
      defaults: () => null
    }
  },
  data: () => ({ search: '' }),
  computed: {
    filterTemplatesChange () {
      return this.templates.filter(temp =>
        temp.name.toUpperCase().includes(this.search.toUpperCase())
      )
    }
  },
  methods: {
    firstLetterUpperCase (text = '') {
      return text ? text[0].toUpperCase() + text.substring(1) : text
    }
  }
}
</script>
<style lang="scss" scoped>
.search-result__field {
  .v-inputserch {
    margin-bottom: 7px;
    padding: 0 16px 0 0;

    ::v-deep input {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      padding: 12px 8px 12px 16px;
      border-radius: 5px 0 0 5px;
    }
  }
}
.v-list__item {
  gap: 8px;
  padding: 12px 8px;
  border: 0!important;
  background: url("~@/assets/img/line.svg") bottom center/100% no-repeat;

  &:hover {
    background-color: $website-color;
  }
  ::v-deep .v-radio {
    &__state-icon {
      margin: 0;
      width: 24px;
      height: 24px;
      &::after {
        width: 16px;
        height: 16px;
      }
    }
  }

  .v-list__item-title {
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    color: $body-text;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &.active {
      color: $color-dark-green;
    }
  }
}
</style>
