<template lang="pug">
v-card.builder-filter
  v-card-head
    h2.title {{'pages.builder.structure' | translate}}
  v-card-content
    v-input.input-field(
      type="text"
      placeholder="Input name category"
      :value="searchCategory"
      @input="writeSearchText($event)")
        template(slot="append")
          div( v-show="searchCategory.length" @click="clearSearchText()")
            close-icon
    div.builder-filter__skeletons(v-if="globalTree === null")
      v-skeleton.w-100
      v-skeleton.w-100
      v-skeleton.w-100
    div.builder-filter--container(v-else)
      .builder-filter--collapse(@click="toggleCollapse")
        span  {{ (collapse ? 'ui.buttons.collapse_all' : 'ui.buttons.expand_all') | translate }}
      v-scrollable(:bottom-offset="20" :scrolling-x="true" ref="container")
        filter-view-list(
          v-if="!loading"
          :item="globalTree"
          :value="searchCategory"
          :showSuggestBadges="showSuggestBadges"
          :selected-category-id="multipleRootNode"
          :collapse="collapse"
          :open-ids="openIds"
          @selectedCategory="selectedCategory($event)"
          @onOpenContextMenu="onOpenContextMenu($event)")
        context-menu(
          :pos="structureContextMenu.pos"
          :show="structureContextMenu.show"
          :is-ctrl="structureContextMenu.isCtrl"
          @close="closeContextMenu()")
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { isSpecialKeyPressed, debounce } from '@/util/utils'
import filterViewList from './filter-view-list.vue'
import contextMenu from './context-menu.vue'
import closeIcon from '@/components/svg/VInputSearch_CloseIcon'

export default {
  name: 'filterView',
  components: {
    filterViewList, contextMenu, closeIcon
  },
  data: () => ({
    searchCategory: '',
    nodeContextMenu: {
      show: false,
      pos: { x: 0, y: 0 },
      isCtrl: false
    },
    collapse: false,
    openIds: [],
    loading: true
  }),

  async mounted () {
    try {
      if (this.globalTree) {
        const selectedNode = await this.getNodeById(this.multipleRootNode)
        if (selectedNode) {
         this.openIds = selectedNode.parents.reduce((sum, el) => ([...sum, el.props.id]), [])
        }
      }
    } finally {
      this.loading = false
    }
  },

  computed: {
    ...mapGetters('trees', ['globalTree', 'multipleRootNode', 'structureContextMenu']),
    ...mapGetters('treeView', ['showSuggestBadges']),
    ...mapGetters('nodeTmp', ['cursorNode'])
  },

  methods: {
    ...mapMutations('trees', {
      setMultipleRootNode: 'MULTIPLE_ROOT_NODE',
      setStructureContextMenu: 'STRUCTURE_CONTEXT_MENU'
      }),

    ...mapActions('trees', ['rearrangeTree', 'getNodeById']),

    ...mapActions('nodeTmp', [
      'unsetContextMenuNode',
      'setContextMenuNode',
      'setCursorNode',
      'unsetCursorNode'
    ]),

    ...mapMutations('nodeTmp', {
      setPropsNodeContextMenu: 'SET_PROPS_NODE_CONTEXT_MENU'
    }),

    writeSearchText (text) {
      this.searchCategory = text
      this.collapse = false
      debounce(() => {
          this.collapse = !!text
        })
    },

    redirectToBuilder () {
      this.$router.push({ path: '/builder' })
    },

    selectedCategory (category) {
        this.redirectToBuilder()
        this.setCursorNode(category)
        this.setMultipleRootNode(category.props.id)
        this.rearrangeTree()
    },
    onOpenContextMenu ({ event, category }) {
      this.setPropsNodeContextMenu({ show: false })
      if (category.node !== this.cursorNode) {
          this.unsetCursorNode()
        }
      const { top, bottom, right, height, width } = event.target.getBoundingClientRect()
      const { top: topContainer, height: heightContainer } = this.$refs.container.$el.getBoundingClientRect()
      const HEIGHT_CONTEXT_MENU = 140

      const pos = { x: 0, y: 0 }
      pos.x = Math.abs(right - width)
      pos.y = window.innerHeight - bottom > HEIGHT_CONTEXT_MENU
        ? Math.abs(topContainer - top - height)
        : Math.abs(heightContainer - HEIGHT_CONTEXT_MENU - height)

      this.setStructureContextMenu({ show: true, isCtrl: isSpecialKeyPressed(event), pos: pos })
      this.setContextMenuNode(category)
    },

    closeContextMenu () {
      this.setPropsNodeContextMenu({ show: false })
      this.setStructureContextMenu({ show: false, isCtrl: false })
      this.unsetContextMenuNode()
    },

    toggleCollapse () {
      this.collapse = !this.collapse
    },

    clearSearchText () {
      this.searchCategory = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.builder-filter{
  box-sizing: border-box;
  height: 100%;
  &--container{
   height: calc(100% - 40px - 20px - 20px);
   .v-scrollable{
    min-height: 100%;
   }
  }
  &--collapse{
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 10px
  }
  &__skeletons{
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.input-field{
    background-color: $light-blue;
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    outline: none;
    font-size: 1rem;
    margin-bottom: 20px;
}
</style>
