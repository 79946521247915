<template lang="pug">
  .badge-detail-description
    .form-item
      .form-item_header
        label {{ 'ui.labels.description' | translate }}
        v-btn(
          icon
          v-tooltip="$t('infotext.edit_the_description')"
          @click="editing = true")
          i.fa(:class="editing ? 'fa-eye' : 'fa-edit'")
      v-show-hide(:max-height="maxHeighDesc")
        div(v-html="descriptionHtml")

    markdown-editor-modal(
      v-if="editing"
      :badge="badge"
      :template-description="templateDescription"
      @on-close="editing = false"
      @on-save="onSave")

</template>

<script>
import marked from 'marked'

import MarkdownEditorModal from '../MarkdownEditorModal'
import { MAX_HEIGTH_DESCRIPTION_BADGE } from '@/util/constants.js'

import helpers from '@/util/helpers'

const renderer = {
  link (href, title, text) {
    const escapedText = text.toLowerCase().replace(/[^\w]+/g, '-')
    return `<a name="${escapedText}" title=${title}  href="${href}" target="_blank">
              ${text}
            </a>`
  }
}

marked.use({ renderer })
marked.setOptions({ breaks: true })

export default {
  name: 'BadgeDetailDescription',

  props: {
    badge: Object,
    templateDescription: String
  },

  components: {
    MarkdownEditorModal
  },

  data: () => ({
    editing: false
  }),

  methods: {
    onSave (value) {
      this.editing = false
      this.$emit('change', value)
    }
  },

  computed: {
    descriptionHtml () {
      const description = helpers.escapeHTML(this.badge.description || '')
      return marked(description)
    },
    maxHeighDesc () {
      return MAX_HEIGTH_DESCRIPTION_BADGE
    }
  }
}
</script>

<style lang="scss">
  .badge-detail-description {
    ul {
      padding-left: 20px;
    }
    .v-show-hide{
        .btn-show-more{
          padding-left: 0;
        }
      }
  }
</style>
