<template lang="pug">
  .v-chevron-component(
    :style="{ transform: listMode ? `rotate(${value ? 90 : 0 }deg)` : `rotate(${value ? -90 : 90 }deg)` }")
    svg(
      x="0px"
      y="0px"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 185.343 185.343"
      :width="size"
      :height="size")
      g
        path(
          :fill="color"
          d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175 l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934 c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z")

</template>

<script>
export default {
  name: 'VChevron',

  props: {
    color: {
      type: String,
      default: '#010002'
    },
    size: {
      type: [String, Number],
      default: 15
    },
    listMode: Boolean,
    value: Boolean
  }
}
</script>

<style lang="scss" scoped>
  .v-chevron-component {
    display: flex;
    justify-content: center;
    transition: transform .3s ;
    cursor: pointer;

    svg {
      vertical-align: baseline;
    }
  }
</style>
