import { execute } from '../../utils'

const ENDPOINT = 'badges/templates'

export default {
  list () {
    return execute('GET', `${ENDPOINT}`)
  },

  create (payload) {
    return execute('POST', `${ENDPOINT}`, payload)
  },

  updateById (id, payload) {
    return execute('PUT', `${ENDPOINT}/${id}`, payload)
  },

  deleteById (id) {
    return execute('DELETE', `${ENDPOINT}/${id}`)
  },

  createLevel (payload) {
    return execute('POST', `${ENDPOINT}/levels`, payload)
  },

  updateLevel (id, payload) {
    return execute('PUT', `${ENDPOINT}/levels/${id}`, payload)
  },

  deleteLevel (id) {
    return execute('DELETE', `${ENDPOINT}/levels/${id}`)
  },

  getBagesWithTemplates (id) {
    return execute('GET', `${ENDPOINT}/${id}?withBadges=1`)
  },

  registerTemplateValidators (id, payload) {
    return execute('POST', `${ENDPOINT}/levels/${id}/validators`, payload)
  },

  deregisterTemplateValidators (id, payload) {
    return execute('DELETE', `${ENDPOINT}/levels/${id}/validators`, payload)
  },

  getTemplateLevelValidators (id) {
    return execute('GET', `${ENDPOINT}/levels/${id}/validators`)
  }
}
