<template lang="pug">
.notifications-new-badges
  .notifications-new-badges--container
    .notifications-new-badges--icon
      notifications-new-badges(:count="count")
    .notifications-new-badges--text
      h5.notifications-new-badges--text_title {{ count }} {{ 'ui.notifications.new_badges' | translate}}
      .notifications-new-badges--text_action(v-if="!inPanel")
        .notification-do-btn.p-0
          span(@click="onMarkAllAsSeen") {{ 'ui.buttons.mark_all_as_seen' | translate }}
        .notification-do-btn.p-0
          span(@click="onOpen") {{ 'ui.buttons.open' | translate }}
      .notifications-new-badges--text_action(v-else)
        .notification-do-btn.p-0.danger.ml-auto
          span(@click="onMarkAllAsSeen") {{ 'ui.buttons.clear_list' | translate }}
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import NotificationsNewBadges from '@/components/svg/NotificationsNewBadges.vue'
export default {
  names: 'NotificationsAboutNewBadges',
  components: { NotificationsNewBadges },
  props: {
    count: {
      type: Number,
      default: 0
    },
    inPanel: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions('badges/newlyAdded', ['updateNewlyAllItemsSeen']),

    ...mapMutations('badges/newlyAdded', {
      openPanleWithNewBadges: 'OPEN_BADGE_PANEL'
    }),

    async onMarkAllAsSeen () {
      await this.updateNewlyAllItemsSeen()
    },

    onOpen () {
      this.openPanleWithNewBadges(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.notifications-new-badges {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 20px;
  background: $color-white;
  margin-right: 8px;

  &--container {
    display: flex;
    gap: 20px;
    background: $website-color;
    border-radius: 4px;
    padding: 16px;
  }

  &--icon {
    max-width: 60px;
    min-width: 60px;
    height: 64px;
  }

  &--text {
    &_title {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: $h2-title;
      margin-bottom: 12px;
    }

    &_action {
      display: flex;
      justify-content: space-between;
      gap: 5px;
    }
  }

  .notification-do-btn {
    width: fit-content;
  }
}
</style>
