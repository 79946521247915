<template lang="pug">
  .header-layout
    app-header
    popup-message
    .main-view(ref="mainView")
      vue-scroll
        .scroll-inner
          transition(name="fadex" mode="out-in")
            router-view

</template>

<script>
import AppHeader from './AppHeader'
import PopupMessage from './PopupMessage'

export default {
  name: 'AppLayout',

  components: {
    AppHeader,
    PopupMessage
  },

  mounted () {
    if (window.innerWidth <= 680) {
      const offset = window.innerWidth <= 680 ? 50 : 80
      // const offset = 50
      this.$refs.mainView.style.height = (window.innerHeight - offset) + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
  .header-layout {
    .main-view {
      position: relative;
      margin-top: 80px;
      height: calc(100vh - 80px);
    }
  }

  @media screen and (max-width: 1000px) {
    .header-layout {
      .main-view {
        margin-top: 100px;
        height: calc(100vh - 100px);
      }

      .scroll-inner {
        padding-bottom: 100px;
      }
    }
  }

  @media screen and (max-width: 680px) {
    .header-layout {
      .main-view {
        margin-top: 50px;
      }
    }
  }
</style>
