const SET_DRAGGING_ELEMENT_TYPE = 'SET_DRAGGING_ELEMENT_TYPE'
const SET_DRAGGING_ELEMENTS = 'SET_DRAGGING_ELEMENTS'

export default {
  namespaced: true,

  state () {
    return {
      draggingElementType: null, // It's for "Skilltree elements" panel,
      draggingElements: []
    }
  },

  getters: {
    draggingElementType (state) {
      return state.draggingElementType
    },

    draggingElements (state) {
      return state.draggingElements
    }
  },

  mutations: {
    [SET_DRAGGING_ELEMENT_TYPE] (state, type) {
      state.draggingElementType = type
    },

    [SET_DRAGGING_ELEMENTS] (state, payload) {
      if (Array.isArray(payload)) {
        state.draggingElements = payload
        return
      }

      state.draggingElements.push(payload)
    }
  },

  actions: {
    setDraggingElementType (context, type) {
      context.commit(SET_DRAGGING_ELEMENT_TYPE, type)
    },

    setDraggingElements (context, payload) {
      context.commit(SET_DRAGGING_ELEMENTS, payload)
    }
  }
}
