<template lang="pug">
  v-dialog(
    id="feedback-modal"
    width="500"
    :value="true"
    @input="onClose")
    v-card
      v-card-head
        v-card-head-label
          h2.modal-title {{ 'ui.labels.feedback' | translate }}
          p.subtitle {{ 'pages.feedback.feedback_first_part' | translate }}
            br
            | {{ 'pages.feedback.feedback_second_part' | translate }}
        v-card-head-toolbar
          v-btn(
            icon
            gray
            @click="onClose") x
      v-card-content
        .form-group
          label {{ 'ui.labels.subject' | translate }}
          input.form-control(
                name="feedback-subject"
                :placeholder="'ui.inputs.enter_subject' | translate"
                v-model="values.subject")
          span.form-text.invalid(name="feedback-subject-error") {{ 'subject' | errorText }}
        .form-group
              label {{ 'ui.labels.type' | translate }}
              v-select(trackBy='title'
                      :placeholder="'ui.dropdown.select_type' | translate "
                      :options="typeOptions"
                      single
                      v-model="values.type")
              span.form-text.invalid {{ 'type' | errorText }}
        .form-group
              label {{ 'ui.labels.message' | translate }}
              textarea.form-control(
                      name="feedback-message"
                      :placeholder="'ui.inputs.enter_message' | translate "
                      rows="4"
                      v-model="values.message")
              span.form-text.invalid(name="feedback-message-error") {{ 'message' | errorText }}
      v-card-actions
        v-btn(
          name="feedback-send"
          :loading="onCreating"
          @click="onFormSend") {{ onCreating === true ? `${$t('ui.buttons.sending')}...` : 'ui.buttons.send' | translate  }}
</template>
<script>

import api from '@/api'
import { mapActions } from 'vuex'

export default {
  name: 'Feedback',

  async mounted () {
    const { error, data } = await api.getContactTypes()
    if (!error) {
      data.forEach(el => {
        el.title = this.$t(`pages.feedback.${el.id.toLowerCase()}`)
      })

      this.values.type = data.find(el => el.id === 'BUG_REPORT')
      this.typeOptions = data
    }
  },

  destroyed () {
    this.clearErrors()
  },

  data: () => ({
    showForm: false,
    onCreating: false,
    typeOptions: [],
    values: {
      type: {},
      subject: '',
      message: ''
    }
  }),

  methods: {
    ...mapActions('app', ['clearErrors', 'setPopupMessage', 'toggleFeedbackModal']),

    async onFormSend () {
      this.clearErrors()
      this.onCreating = true
      const body = {
        typeId: this.values.type.id,
        subject: this.values.subject,
        message: this.values.message
      }

      const { data } = await api.sendMailToHelpCenter(body)
      if (data) {
        Object.keys(this.values).forEach(el => { this.values[el] = '' })
        this.values.type = this.typeOptions.find(el => el.id === 'BUG_REPORT')
        this.onClose()
        this.setPopupMessage({
          message: this.$t('infotext.your_message_has_been_sent_thank_you_very_much_for_helping_us_improve_skilltree')
        })
      }
      this.onCreating = false
    },

    onClose () {
      this.toggleFeedbackModal(false)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
