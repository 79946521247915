import api from '@/api'
const SET_CURSOR_NODE = 'SET_CURSOR_NODE'
const UNSET_CURSOR_NODE = 'UNSET_CURSOR_NODE'
const SET_PREV_CURSOR_NODE = 'SET_PREV_CURSOR_NODE'

const SET_CONTEXT_MENU_NODE = 'SET_CONTEXT_MENU_NODE'
const UNSET_CONTEXT_MENU_NODE = 'UNSET_CONTEXT_MENU_NODE'

const SET_LAST_ADDED_NODE = 'SET_LAST_ADDED_NODE'
const UNSET_LAST_ADDED_NODE = 'UNSET_LAST_ADDED_NODE'
const DISCARD_CHANGES = 'DISCARD_CHANGES'

const SELECT_NODE = 'SELECT_NODE'
const UNSELECT_NODE = 'UNSELECT_NODE'
const UNSELECT_ALL_NODES = 'UNSELECT_ALL_NODES'

const SET_CLIPBOARD = 'SET_CLIPBOARD'
const UNSET_CLIPBOARD = 'UNSET_CLIPBOARD'

const SET_CUT_FOR_SELECTED_NODES = 'SET_CUT_FOR_SELECTED_NODES'
const UNSET_CUT_FOR_SELECTED_NODES = 'UNSET_CUT_FOR_SELECTED_NODES'
const UNSET_CUT_FOR_CLIPBOARD_NODES = 'UNSET_CUT_FOR_CLIPBOARD_NODES'

const SET_DRAGED_BEFORE = 'SET_DRAGED_BEFORE'
const SET_DRAG_ENTER_NODE_ID = 'SET_DRAG_ENTER_NODE_ID'

const REMOVE_FILE = 'REMOVE_FILE'
const ADD_FILE = 'ADD_FILE'
const SET_PROPS_NODE_CONTEXT_MENU = 'SET_PROPS_NODE_CONTEXT_MENU'

export default {
  namespaced: true,

  state () {
    return {
      cursorNode: null, // Selected node by left click. Hilights path from main node to this node.
      prevCursorNode: null,
      contextMenuNode: null, // Selected node by right click. Hilights only selected node outline.
      lastAddedNode: null, // Handler for last created node.
      selectedNodes: [],
      clipboard: [],
      dragndrop: {
        dragedBefore: false
      },
      dragEnterNodeId: null,
      nodeContextMenu: {
        show: false,
        pos: { x: 0, y: 0 },
        isCtrl: false
      }
    }
  },

  getters: {
    cursorNode: (state) => state.cursorNode,
    contextMenuNode: (state) => state.contextMenuNode,
    lastAddedNode: (state) => state.lastAddedNode,
    selectedNodes: (state) => state.selectedNodes,
    hasSelectedNodes: (state) => !!state.selectedNodes.length,
    clipboard: (state) => state.clipboard,
    dragndrop: (state) => state.dragndrop,
    dragEnterNodeId: (state) => state.dragEnterNodeId,
    nodeContextMenu: (state) => state.nodeContextMenu
  },

  mutations: {
    [SET_CURSOR_NODE] (state, node) {
      state.cursorNode = node
    },

    [UNSET_CURSOR_NODE] (state) {
      state.cursorNode = null
    },

    [SET_PREV_CURSOR_NODE] (state, node) {
      state.prevCursorNode = node
    },

    [SET_CONTEXT_MENU_NODE] (state, node) {
      state.contextMenuNode = node
    },

    [UNSET_CONTEXT_MENU_NODE] (state) {
      state.contextMenuNode = null
    },

    [SET_LAST_ADDED_NODE] (state, node) {
      state.lastAddedNode = node
    },

    [UNSET_LAST_ADDED_NODE] (state) {
      state.lastAddedNode = null
    },

    [DISCARD_CHANGES] (state) {
      if (state.lastAddedNode) {
        state.lastAddedNode.localProps.showInput = false
        state.lastAddedNode = null
      }
    },

    [SELECT_NODE] (state, node) {
      if (node !== null) {
        state.selectedNodes.push(node)
      }
    },

    [UNSELECT_NODE] (state, node) {
      if (node !== null) {
        const index = state.selectedNodes
          .map(item => item.props.id)
          .indexOf(node.props.id)
        if (index !== -1) {
          state.selectedNodes.splice(index, 1)
        }
      }
    },

    [UNSELECT_ALL_NODES] (state) {
      state.selectedNodes = []
    },

    [SET_CLIPBOARD] (state, nodes) {
      state.clipboard = [...nodes]
    },

    [UNSET_CLIPBOARD] (state) {
      state.clipboard = []
    },

    [SET_CUT_FOR_SELECTED_NODES] (state) {
      state.selectedNodes.forEach(item => {
        item.localProps.isCut = true
      })
    },

    [UNSET_CUT_FOR_SELECTED_NODES] (state) {
      state.selectedNodes.forEach(item => {
        item.localProps.isCut = false
      })
    },

    [UNSET_CUT_FOR_CLIPBOARD_NODES] (state) {
      state.clipboard.forEach(item => {
        item.localProps.isCut = false
      })
    },

    [SET_DRAGED_BEFORE] (state, value) {
      state.dragndrop.dragedBefore = value
    },

    [SET_DRAG_ENTER_NODE_ID] (state, value) {
      state.dragEnterNodeId = value
    },

    [ADD_FILE] (state, file) {
      if (state.cursorNode.props.id === file.badgeId) {
        state.cursorNode.props.files.push(file.files)
      } else {
        for (let i = 0; i < state.cursorNode.props.gradationalSubBadges.length; i++) {
          if (state.cursorNode.props.gradationalSubBadges[i].id === file.badgeId) {
            state.cursorNode.props.gradationalSubBadges[i].files.push(file.files)
            break
          }
        }
      }
    },

    [REMOVE_FILE] (state, file) {
      if (state.cursorNode.props.id === file.badgeId) {
        state.cursorNode.props.files = state.cursorNode.props.files.filter(el => el.uuid !== file.uuid)
      } else {
        for (let i = 0; i < state.cursorNode.props.gradationalSubBadges.length; i++) {
          if (state.cursorNode.props.gradationalSubBadges[i].id === file.badgeId) {
            state.cursorNode.props.gradationalSubBadges[i].files = state.cursorNode.props.gradationalSubBadges[i].files.filter(el => el.uuid !== file.uuid)
            break
          }
        }
      }
    },

    [SET_PROPS_NODE_CONTEXT_MENU] (state, props) {
      for (let key in props) {
        state.nodeContextMenu[key] = props[key]
      }
    }
  },

  actions: {
    setCursorNode (context, node) {
      context.commit(SET_CURSOR_NODE, node)
    },

    unsetCursorNode (context) {
      context.commit(SET_PREV_CURSOR_NODE, context.state.cursorNode)
      context.commit(UNSET_CURSOR_NODE)
    },

    restoreCursorNode (context) {
      context.commit(SET_CURSOR_NODE, context.state.prevCursorNode)
    },

    setContextMenuNode (context, node) {
      context.commit(SET_CONTEXT_MENU_NODE, node)
    },

    unsetContextMenuNode (context) {
      context.commit(UNSET_CONTEXT_MENU_NODE)
    },

    setLastAddedNode (context, node) {
      context.commit(SET_LAST_ADDED_NODE, node)
    },

    unsetLastAddedNode (context) {
      context.commit(UNSET_LAST_ADDED_NODE)
    },

    discardNodeChanges (context) {
      context.commit(DISCARD_CHANGES)
      context.dispatch('svgTopLayer/restoreAllElementsFromTopLayer', null, { root: true })
    },

    isNodeSelected (context, node) {
      if (node !== null) {
        return context
          .state
          .selectedNodes
          .map(item => item.props.id)
          .some(item => item === node.props.id)
      }
      return false
    },

    selectNode (context, node) {
      context.commit(SELECT_NODE, node)
    },

    unselectNode (context, node) {
      context.commit(UNSELECT_NODE, node)
    },

    unselectAllNodes (context) {
      context.commit(UNSELECT_ALL_NODES)
    },

    setClipboard (context) {
      context.commit(SET_CLIPBOARD, context.state.selectedNodes)
    },

    clearClipboard (context) {
      context.commit(UNSET_CUT_FOR_CLIPBOARD_NODES)
      context.commit(UNSET_CLIPBOARD)
    },

    setCutForSelectedNodes (context) {
      context.commit(SET_CUT_FOR_SELECTED_NODES)
    },

    unsetCutForSelectedNodes (context) {
      context.commit(UNSET_CUT_FOR_SELECTED_NODES)
    },

    setDragedBefore (context, value) {
      context.commit(SET_DRAGED_BEFORE, value)
      if (value) {
        context.commit(UNSET_CURSOR_NODE)
      }
    },

    setDragEnterNodeId (context, value = null) {
      context.commit(SET_DRAG_ENTER_NODE_ID, value)
    },

  async removeFile ({ commit }, payload) {
      await api.storage.deleteFile(payload.url)
      commit(REMOVE_FILE, payload)
    },

   addFile ({ commit }, payload) {
      commit(ADD_FILE, payload)
    }
  }
}
