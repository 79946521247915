import { NODE_TYPE } from '@/util/constants.js'
import helpers from '@/util/helpers.js'
let pieDiagramColors = ['#34856c', '#393f4e', '#94a0ce', '#c76b31', '#c6b15e', '#ec762e', '#7e884d', '#d49e89', '#3c9874',
  '#e38a57', '#2d7264', '#2f605c', '#345055', '#555d75', '#747ea0', '#b8fffa', '#3c9874', '#4c9060', '#b57f3b' ]
let empltyPieData = {
  empty: true,
  labels: [],
  datasets: [
    {
      backgroundColor: '#ABB5BF',
      data: [100]
    }
  ]
}
const copyArray = (array) => {
  return JSON.parse(JSON.stringify(array))
}

export const getSubCategories = (childrens, color) => {
  let result = []
  const setName = (obj) => obj.hasOwnProperty('name') ? 'name' : 'displayName'
  childrens.forEach(element => {
    if (element.typeId !== NODE_TYPE.HOOKED_BADGE) {
      let obj = {
        id: element.id,
        name: element.name || element.displayName,
        checked: false,
        children: getSubCategories(element.subBadges, color).sort((a, b) => a[setName(a)].toLowerCase() < b[setName(b)].toLowerCase() ? -1 : 1)
      }
      result.push(obj)
    }
  })
  return result
}

export const parseQueryFormObject = (object) => {
  let qwObj = {}
  for (let key in object) {
    if (object[key].length) {
      qwObj[key] = { value: object[key].join(',') }
    }
  }
  return qwObj
}

export const parseBadgesArrays = (array, isGoal = false) => {
  let chartData = []
  let maxRows = 0
  for (let fi in array) {
    let subArray = []
    if (isGoal) {
      subArray = [...array[fi]].sort((a, b) => a.employeeCount < b.employeeCount ? 1 : -1)
    } else {
      subArray = [...array[fi]]
    }

    let colored = subArray.map((el, index) => {
      return helpers.pSBC(index * 0.15, el.color, '#F8F9FC')
    }).reverse()

    let hoverColors = subArray.map((el, index) => {
      return helpers.pSBC(index * 0.15, el.color, '#000')
    })

    maxRows = subArray.length - 1 || 0
    for (let si = 0; si <= maxRows; si++) {
      if (!chartData[si]) {
        chartData[si] = {}
        chartData[si].data = Array(+fi + 1).fill(null)
        chartData[si].borderColor = '#000'
        chartData[si].backgroundColor = Array(+fi + 1).fill('transparent')
        chartData[si].tooltipLabels = Array(+fi + 1).fill(null)
        chartData[si].hoverBackgroundColor = Array(+fi + 1).fill('transparent')
      }
      let toolptip = null
      let employeeCount = null
      if (subArray[si]) {
        toolptip = {
          count: subArray[si].employeeCount,
          label: subArray[si].levelName
        }
        employeeCount = (subArray.length > 1 && subArray.length - 1 !== +si)
          ? Math.abs(subArray[si + 1].employeeCount - subArray[si].employeeCount) : subArray[si].employeeCount
      }
      chartData[si].data[fi] = employeeCount
      chartData[si].backgroundColor[fi] = colored[si]
      chartData[si].hoverBackgroundColor[fi] = hoverColors[si]
      chartData[si].tooltipLabels[fi] = toolptip
    }
  }

  return [...chartData].reverse()
}

export const parsaDataToPideChart = (data, isGoals) => {
  let defaultPieChartData = [
    {
      parseKey: 'departments',
      label: 'ui.labels.departments',
      tooltip: isGoals ? 'infotext.insights_pie_department_goal_info' : 'infotext.insights_pie_department_info'
    },
    {
      parseKey: 'locations',
      label: 'ui.labels.locations',
      tooltip: isGoals ? 'infotext.insights_pie_location_goal_info' : 'infotext.insights_pie_location_info'
    }
  ]

  for (let i in defaultPieChartData) {
    let arrayForParse = data[defaultPieChartData[i].parseKey] || []
    let isEmpty = !arrayForParse.find(el => el.employeeCount)

    if (isEmpty) {
      defaultPieChartData[i].chartData = { ...empltyPieData, dataKey: defaultPieChartData[i].parseKey }
      continue
    }

    defaultPieChartData[i].chartData = {
      labels: arrayForParse.map(el => el.name),
      datasets: [
        {
          backgroundColor: arrayForParse.map((_, idx) => {
            if (idx >= pieDiagramColors.legnth) {
              pieDiagramColors.push(...pieDiagramColors)
            }
            return pieDiagramColors[idx]
          }),
          data: arrayForParse.map(el => el.employeeCount || el.allChildEmployeeCount || 0)
        }
      ]
    }
  }
  return defaultPieChartData
}

export const flatBadges = (data) => {
  let maxChartValue = 0
  let badges = data.map(el => {
    if (el.employeeCount > maxChartValue) {
      maxChartValue = el.employeeCount
    }
    const mainBadge = {
      color: el.color,
      levelName: el.levelName || el.name,
      id: el.id,
      name: el.name,
      employeeCount: el.employeeCount
    }
    return [mainBadge, ...(el.gradationalSubBadges || [])]
  })

  return {
    badges,
    maxChartValue
  }
}

export const flatBadge = (badge, isGoals = false) => {
  const mainBadge = {
    color: badge.color,
    id: badge.id,
    displayName: badge.displayName || badge.name,
    description: badge.description,
    pieChartData: parsaDataToPideChart({ departments: badge.departments, locations: badge.locations }, isGoals),
    isLoaded: true,
    requiredBadges: badge.requiredBadges || [],
    parent: badge.parent,
    currentTab: 1
  }

  for (let i in badge.gradationalSubBadges || []) {
    badge.gradationalSubBadges[i].pieChartData = parsaDataToPideChart({
      departments: copyArray(badge.gradationalSubBadges[i].departments),
      locations: copyArray(badge.gradationalSubBadges[i].locations) }, isGoals)
    badge.gradationalSubBadges[i].color = mainBadge.color
    badge.gradationalSubBadges[i].requiredBadges = badge.gradationalSubBadges[i].requiredBadges || []
    badge.gradationalSubBadges[i].currentTab = 1
    delete badge.gradationalSubBadges[i].locations
    delete badge.gradationalSubBadges[i].departments
  }
  return [{ ...mainBadge, displayName: badge.name, mainBadge: true }, mainBadge, ...(badge.gradationalSubBadges || [])]
}
