<template lang="pug">
  .v-pagination(v-if="maxPages > 1" )
    v-dropdown(width='200' ref="dropdown")
      .v-pagination--active-area
        span {{ renderText }}
      div(slot="content" style="background-color: white")
        v-list-item(@click.native="goStart"
          :class="{'disabled-list-item': disableToTheBegin}" ) {{'ui.dropdown.to_the_beginning' | translate}}
        v-list-item(@click.native="goEnd"
          :class="{'disabled-list-item': disableInTheEnd}") {{"ui.dropdown.in_the_end" | translate}}

    .v-pagination--action
      v-btn(icon @click="onPrevPage" :disabled="page===1")
        i.fa.fa-chevron-left
    .v-pagination--action
    v-btn(icon @click="onNextPage" :disabled="page===maxPages")
      i.fa.fa-chevron-right
</template>

<script>

export default {
  name: 'VPagination',

  props: {
    initialPage: Number,
    perPage: Number,
    totalCount: Number
  },

  mounted () {
    this.page = this.initialPage || 1
  },

  data: () => ({
    page: 1
  }),

  methods: {
    onPrevPage () {
      this.page--
      this.onChangePage()
    },

    onNextPage () {
      this.page++
      this.onChangePage()
    },

    goStart () {
      if (!this.disableToTheBegin) {
        this.page = 1
        this.$refs.dropdown.closeDropdown()
        this.onChangePage()
      }
    },

    goEnd () {
      if (!this.disableInTheEnd) {
        this.page = this.maxPages
        this.$refs.dropdown.closeDropdown()
        this.onChangePage()
      }
    },

    onChangePage () {
      this.$emit('on-page-change', this.page || 1)
    }
  },

  computed: {
    maxPages () {
      return Math.ceil(this.totalCount / this.perPage)
    },

    renderText () {
      const currentMinPageCount = (this.page - 1) * this.perPage + 1
      const currentMaxPageCount = this.page * this.perPage > this.totalCount ? this.totalCount : this.page * this.perPage
      return `${currentMinPageCount} - ${currentMaxPageCount} ${this.$t('ui.labels._of')} ${this.totalCount || 0}`
    },

    disableToTheBegin () {
      return this.page === 1
    },

    disableInTheEnd () {
      return this.page === this.maxPages
    }
  },

  watch: {
    initialPage (value) {
      this.page = value
    }
  }
}
</script>

<style lang="scss">

  .v-pagination {
    display: flex;
    align-items: center;
    height: 40px;

    &--active-area {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      padding:  0 1rem;
      cursor: pointer;

      &:hover {
         background-color: #f7f8fa;
      }
    }

    &--action {
      margin-left: 0.5rem
    }

    .disabled-list-item {
      cursor: default;
      color: #ccc !important;
      &:hover {
        background-color: transparent;
      }
    }
  }

</style>
