<template lang="pug">
  transition(name="fade")
    .node-clipboard-debug-modal(
      v-if="showModal")
      span.label {{ label }}
      button.btn.btn--undo(
        @click="clearClipboard")
        i.fas.fa-undo

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NodeClipboardDebugModal',

  methods: {
    ...mapActions('nodeTmp', [
      'clearClipboard'
    ])
  },

  computed: {
    ...mapGetters('nodeTmp', [
      'clipboard'
    ]),

    showModal () {
      return this.clipboard.length > 0
    },

    label () {
      if (this.clipboard.length) {
        const action = this.clipboard[0].localProps.isCut
          ? 'cut'
          : 'copy'
        return `You ${action} ${this.clipboard.length} element(s)`
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .node-clipboard-debug-modal {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 16px;
    border-radius: 20px;
    background-color: $color-white;
    box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.1);

    .label {
      margin: 0;
      margin-right: 5px;
    }

    .btn--undo {
      color: $body-text;
      padding: 0;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #f0f0f0;
      }

      i {
        padding: 0;
      }
    }
  }
</style>
