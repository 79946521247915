<template lang="pug">
  transition(name="slide-down" mode="out-in")
    .app-popup-message(v-if="popupMessage")
      span.app-popup-message-text {{ popupMessage.message }}
      span.close-btn-popup-message(@click="onClose" aria-hidden="true") ×
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PopupMessage',

  data: () => ({
    timoutRef: null
  }),

  computed: {
    ...mapGetters('app', [
      'popupMessage'
    ])
  },

  methods: {
    ...mapActions('app', [
      'setPopupMessage'
    ]),

    onClose () {
      this.setPopupMessage(null)
    }
  },

  watch: {
    popupMessage () {
      if (this.popupMessage) {
        this.timoutRef = setTimeout(() => { this.onClose() }, 10000)
      } else if (this.timoutRef) {
        clearTimeout(this.timoutRef)
      }
    }
  }
}
</script>

<style lang="scss">
  .app-popup-message{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color:#1dc9b7;
    width:100%;
    z-index: 9;
    padding-right: 40px;
    color:white;

    .app-popup-message-text{
      font-weight: bold;
    }

    .close-btn-popup-message {
      padding-bottom: 2px;
      position: absolute;
      right: 20px;
      cursor: pointer;
      font-weight: 800;
      color:white;
      font-size: 1.5rem;

      &:hover{
        color: darken(white, 10%);

      }
    }
  }
</style>
