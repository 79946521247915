const SET_EMPLOYEES_CSV = 'SET_EMPLOYEES_CSV'
const SET_SCROLL_POS_Y = 'SET_SCROLL_POS_Y'
const SET_SETUP_TABLE_EMPLOYEE = 'SET_SETUP_TABLE_EMPLOYEE'
export default {
  namespaced: true,

  state () {
    return {
      employeesCsv: null,
      srollToPosY: 0,
      setupTableEmployees: {}
    }
  },

  getters: {
    employeesCsv: (state) => state.employeesCsv,
    srollToPosY: (state) => state.srollToPosY,
    setupTableEmployees: (state) => state.setupTableEmployees
  },

  mutations: {
    [SET_EMPLOYEES_CSV] (context, csv) {
      context.employeesCsv = csv
    },

    [SET_SCROLL_POS_Y] (context, param) {
      context.srollToPosY = param
    },

    [SET_SETUP_TABLE_EMPLOYEE] (context, obj) {
      context.setupTableEmployees = obj
    }
  },

  actions: {
    setEmployeesCsv (context, csv) {
      context.commit(SET_EMPLOYEES_CSV, csv)
    },

    setSrollPosY (context, param) {
      context.commit(SET_SCROLL_POS_Y, param)
    },

    setTableEmployee (context, obj) {
      context.commit(SET_SETUP_TABLE_EMPLOYEE, obj)
    }

  }
}
