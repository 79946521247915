import { execute, cancelableRequest, addQueryParams } from '../utils'

const ENDPOINT = 'roles'

const cancelable = cancelableRequest(execute)

export default {
  list (params = {}) {
    return execute('GET', addQueryParams(ENDPOINT, params))
  },

  create (payload) {
    return execute('POST', ENDPOINT, payload)
  },

  getById (id, params = {}) {
    return execute('GET', addQueryParams(`${ENDPOINT}/${id}`, params))
  },

  updateById (id, payload) {
    return execute('PUT', `${ENDPOINT}/${id}`, payload)
  },

  deleteById (id) {
    return execute('DELETE', `${ENDPOINT}/${id}`)
  },

  listOwners (id, params) {
    return cancelable.execute('GET', addQueryParams(`${ENDPOINT}/${id}/owners`, params))
  }
}
