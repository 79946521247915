<template lang="pug">
  .v-radio(@click="onSelect")
    .v-radio__state-icon(
      :class="classes")
    label.v-radio__label {{ label }}

</template>

<script>
export default {
  name: 'VRadio',

  props: {
    id: [String, Number],
    active: Boolean,
    label: String
  },

  mounted () {
    this.isSelected = this.active
  },

  data: () => ({
    isSelected: false
  }),

  computed: {
    classes () {
      return {
        'v-radio__state-icon--active': this.isSelected
      }
    }
  },

  watch: {
    active (value) {
      this.isSelected = value
    }
  },

  methods: {
    onSelect () {
      this.$emit('select', this.id)
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables.scss';
  .v-radio {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__state-icon {
      position: relative;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      border: 1px solid $body-text;
      background-color: $color-white;
      margin-right: 10px;

      &--active {
        border-color: $color-primary;

        &::after {
          content: "";
          position: absolute;
          height: 10px;
          width: 10px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background-color: $color-primary;
        }
      }
    }

    &__label {
      font-size: 14px;
      margin: 0;
      cursor: inherit;
    }
  }
</style>
