import api from '@/api'
import {
  parseQueryFormObject,
  getSubCategories,
  parseBadgesArrays,
  parsaDataToPideChart,
  flatBadges,
  flatBadge
} from '../helpers'

const SET_GENERAL_STATS = 'SET_GENERAL_STATS'
const SET_UPDATE_REF = 'SET_UPDATE_REF'
const SET_SELECTED_BAR = 'SET_SELECTED_BAR'
const SET_FILTERS = 'SET_FILTERS'
const RESET_FILTERS = 'RESET_FILTERS'
const SET_BAR_CHART = 'SET_BAR_CHART'
const SET_BADGES = 'SET_BADGES'
const SET_MAX_CHART_VALUE = 'SET_MAX_CHART_VALUE'
const SET_SKILLS_TREE = 'SET_SKILLS_TREE'
const SET_COMPANY_INFO = 'SET_COMPANY_INFO'
const SET_LAZY_SKILLS_TREE = 'SET_LAZY_SKILLS_TREE'

export default {
  namespaced: true,

  state () {
    return {
      generalStats: null,
      selectedBar: null,
      barChartData: {
        datasets: [],
        labels: []
      },
      maxChartValue: 0,
      updateRef: () => {},
      badges: [],
      isInitialized: false,
      skillsTree: [],
      filters: null,
      companyInfo: null,
      lazySkillsTree: true
    }
  },

  getters: {
    selectedBar: (state) => state.selectedBar,
    generalStats: (state) => state.generalStats,
    barChartData: (state) => state.barChartData,
    maxChartValue: (state) => state.maxChartValue,
    badges: (state) => state.badges,
    skillsTree: (state) => state.skillsTree,
    filters: (state) => state.filters,
    companyInfo: (state) => state.companyInfo,
    lazySkillsTree: (state) => state.lazySkillsTree
  },

  mutations: {
    [SET_BAR_CHART] (state, { data }) {
      for (let key in state.barChartData) {
        state.barChartData[key] = !state.isInitialized ? [ ...state.barChartData[key], ...data[key] ] : data[key]
      }
      state.isInitialized = true
      state.updateRef()
    },

    [SET_BADGES] (state, data) {
      state.badges = data
    },

    [SET_COMPANY_INFO] (state, data) {
      state.companyInfo = data
    },

    [SET_SKILLS_TREE] (state, data) {
      state.skillsTree = data
    },

    [SET_MAX_CHART_VALUE] (state, data) {
      state.maxChartValue = data
    },

    [SET_GENERAL_STATS] (state, data) {
      state.generalStats = data
    },

    [SET_SELECTED_BAR] (state, data = null) {
      const chartRows = state.barChartData.datasets.length
      let reversedIndexes = Array.from({ length: chartRows }, (v, i) => i).reverse()
      let selectedSubIndexChart = null

      if (data && data.subIndex !== null) {
        if (data.isFromDrawerPanel) {
          selectedSubIndexChart = reversedIndexes[data.subIndex]
        } else {
          selectedSubIndexChart = data.subIndex
          data.subIndex = reversedIndexes[data.subIndex]
        }
      }
      state.selectedBar = data

      let firstIndex = 0
      let lastIndexWithvalue = null
      for (let i in state.barChartData.datasets) {
        const dataset = state.barChartData.datasets[i]
        if (data && !dataset.data[data.index] && lastIndexWithvalue === null) {
          firstIndex = +i + 1
        } else if (data && dataset.data[data.index]) {
          if (selectedSubIndexChart !== null && selectedSubIndexChart < +i) {
            if (lastIndexWithvalue === null) {
              lastIndexWithvalue = firstIndex
            }
          } else {
            lastIndexWithvalue = +i
          }
        }
      }

      for (let i in state.barChartData.datasets) {
        const dataset = state.barChartData.datasets[i]

        const setDatasetBorders = () => {
          dataset.borderWidth = Array(data.index).fill(0)
          dataset.borderWidth[data.index] = 2
        }

        if (data) {
          setDatasetBorders()
          if (lastIndexWithvalue === +i && +i === firstIndex) {
            dataset.borderSkipped = false
            dataset.skipVerticalBorders = false
          } else if (firstIndex === +i) {
            dataset.borderSkipped = 'top'
            dataset.skipVerticalBorders = false
          } else if (lastIndexWithvalue === +i) {
            dataset.borderSkipped = 'bottom'
            dataset.skipVerticalBorders = false
          } else if (lastIndexWithvalue > +i && firstIndex < +i) {
            dataset.skipVerticalBorders = true
          } else {
            dataset.borderWidth = 0
            dataset.skipVerticalBorders = false
          }
        } else {
          dataset.borderWidth = 0
        }
      }
      state.updateRef()
    },

    [SET_FILTERS] (state, data) {
      state.filters = data
    },

    [SET_UPDATE_REF] (state, data) {
      state.updateRef = data
    },

    [RESET_FILTERS] (state) {
      state.filters = null
    },

    [SET_LAZY_SKILLS_TREE] (state, value) {
      state.lazySkillsTree = value
    }
  },

  actions: {
    async loadgeneralStats (context, item) {
      let filters = parseQueryFormObject(context.state.filters || {})
      let { data } = await api.insights.getStats(filters)
      if (data) {
        data.pieChartData = parsaDataToPideChart(data)
        context.commit(SET_GENERAL_STATS, data)
      }
    },

    async loadInsights (context) {
      let filters = parseQueryFormObject(context.state.filters || {})
      let { data } = await api.insights.get(filters)
      if (data) {
        let chartData = {}
        chartData.labels = data.map(el => el.name)
        let { badges, maxChartValue } = flatBadges(data)
        context.commit(SET_BADGES, badges)
        let parsed = parseBadgesArrays(badges)
        chartData.datasets = parsed
        context.commit(SET_BAR_CHART, { data: chartData })
        context.commit(SET_MAX_CHART_VALUE, maxChartValue)
      }
    },

    async  selectBar (context, item) {
      context.commit(SET_SELECTED_BAR, item)
      try {
        let badges = [...context.state.badges]
        if (item && badges[item.index] && badges[item.index][0] && !badges[item.index][0].isLoaded) {
          let filters = parseQueryFormObject(context.state.filters || {})
          let { data } = await api.insights.getStatsByBadgeId(badges[item.index][0].id, filters)
          if (data) {
            badges[item.index] = flatBadge(data)
            context.commit(SET_BADGES, badges)
          }
        }
      } catch (e) {

      }
    },

    setUpdateRef (context, ref) {
      context.commit(SET_UPDATE_REF, ref)
    },

    setBadgesLoaded (context, param = false) {
      for (let i in context.state.badges) {
        if (context.state.badges[i] && context.state.badges[i][0]) {
          context.state.badges[i][0].isLoaded = param
        }
      }
    },

    setFilters (context, data) {
      context.commit(SET_FILTERS, data)
      context.commit(SET_SELECTED_BAR, null)
      context.dispatch('setBadgesLoaded', false)
      context.dispatch('loadInsights')
      context.dispatch('loadgeneralStats')
    },

    resetFilters (context) {
      context.commit(SET_SELECTED_BAR, null)
      context.commit(RESET_FILTERS)
      context.dispatch('setBadgesLoaded', false)
      context.dispatch('loadInsights')
      context.dispatch('loadgeneralStats')
    },

    async loadSkillsTree (context, { lazy }) {
      const params = { lazy: { value: lazy } }
      const { data } = await api.getBadgesTree(params)
      const setName = (obj) => obj.hasOwnProperty('name') ? 'name' : 'displayName'
      let skills = data.badges.subBadges.map(item => {
        let subCategories = getSubCategories(item.subBadges, item.color)
        return {
          count: 0,
          name: item.name || item.displayName,
          checked: false,
          id: item.id,
          children: subCategories.sort((a, b) => a[setName(a)].toLowerCase() < b[setName(b)].toLowerCase() ? -1 : 1)
        }
      }).sort((a, b) => a[setName(a)].toLowerCase() < b[setName(b)].toLowerCase() ? -1 : 1)

      context.commit(SET_SKILLS_TREE, skills)
      context.commit(SET_LAZY_SKILLS_TREE, lazy)
    },

    async loadCompanyInfo (context) {
      const data = await api.getSettingCompanyInfo()
      if (data) {
        context.commit(SET_COMPANY_INFO, data)
      }
    },

    async getEmployeesByBadgeId (context, { badgeId, filters, isAll = false }) {
      let res = {}
      let additionalFilters = parseQueryFormObject(context.state.filters || {})
      if (isAll) {
        res = await api.insights.getEmployeesByBadgeIdAll(badgeId, { ...filters, ...additionalFilters })
      } else {
        res = await api.insights.getEmployeesByBadgeId(badgeId, { ...filters, ...additionalFilters })
      }
      return res
    }
  }
}
