const SET_TEMPLATE_MODAL = 'SET_TEMPLATE_MODAL'
const TOGGLE_BUILDER_LOOK = 'TOGGLE_BUILDER_LOOK'

export default {
  namespaced: true,

  state () {
    return {
      templateModal: null,
      builderLook: null
    }
  },

  getters: {
    templateModal: (state) => state.templateModal,
    toggledBuilderLook: (state) => state.builderLook
  },

  mutations: {
    [SET_TEMPLATE_MODAL] (state, data) {
      state.templateModal = data
    },
    [TOGGLE_BUILDER_LOOK] (state, data) {
      state.builderLook = data
    }
  },

  actions: {
    openTemplateCreateModal (context, ops) {
      const options = {
        ...ops,
        mode: 'CREATE'
      }
      context.commit(SET_TEMPLATE_MODAL, options)
    },

    openTemplateEditModal (context, ops) {
      const options = {
        ...ops,
        mode: 'EDIT'
      }
      context.commit(SET_TEMPLATE_MODAL, options)
    },

    closeTemplateModal (context) {
      context.commit(SET_TEMPLATE_MODAL, null)
    }
  }
}
