<template lang="pug">
.goals--list
  v-scrollable(
    :bottom-offset="40"
    :min-height="0")
    .goals--list--container
      template(v-for="(item, idx) in list")
        GoalsListItem(
          :goal="item"
          :num="idx + 1"
          :key="idx"
          :completed="completed"
          :failed="failed"
          :editFromForeignProfile="editFromForeignProfile"
          @on-mark-as="onMarkAs(item)"
          @on-deadline="onDeadline(item)"
          @edit-goal="$emit('edit-goal', item)"
          @delete="removeGoal({ goalId: item.id })"
          @approve="approve({ goal: item })"
          @reject="reject({ goal: item })"
          @comment="(text) => comment({comment: text, goal: item})")
  v-list-empty(v-if="!list.length")
    i.icon.icon--sm.icon--no-result
    span {{ 'ui.labels.no_results' | translate }}
  MarkGoalModal(:show="showMarkAsModal" :goal="currentMarkedGoal" @close="onCloseMarkAsModal" @on-accept="onAccept" @on-fail="onFail")
  DeadlineModal(:show="showDeadlineModal" :goal="currentMarkedGoal" @close="onCloseDeadlineModal"  @accept="onSetDeadline" )
</template>

<script>
import { mapActions } from 'vuex'
import GoalsListItem from './GoalsListItem'
import MarkGoalModal from './MarkGoalModal'
import DeadlineModal from './DeadlineModal'

export default {
  name: 'GoalsList',

  components: {
    GoalsListItem,
    DeadlineModal,
    MarkGoalModal
  },

  props: {
    onLoad: {
      type: Function,
      default: () => {}
    },
    completed: Boolean,
    failed: Boolean,
    list: Array,
    editFromForeignProfile: Boolean
  },

  data: () => ({
    currentMarkedGoal: null,
    showDeadlineModal: false,
    showMarkAsModal: false
  }),

  methods: {
    ...mapActions('goals', [
      'completeGoal',
      'failGoal',
      'removeGoal',
      'updateGoal',
      'approve',
      'reject',
      'comment'
    ]),

    onDeadline (goal) {
      this.currentMarkedGoal = goal
      this.showDeadlineModal = true
    },

    onMarkAs (goal) {
      this.currentMarkedGoal = goal
      this.showMarkAsModal = true
    },

    onFail () {
      this.failGoal({ goal: this.currentMarkedGoal })
      this.onCloseMarkAsModal()
    },

    onAccept () {
      this.completeGoal({ goal: this.currentMarkedGoal })
      this.onCloseMarkAsModal()
    },

    onCloseMarkAsModal () {
      this.currentMarkedGoal = null
      this.showMarkAsModal = false
    },

    onCloseDeadlineModal () {
      this.currentMarkedGoal = null
      this.showDeadlineModal = false
    },

    onSetDeadline () {
      this.updateGoal({ goalId: this.currentMarkedGoal.id,
        values: {
          deadlineAt: this.currentMarkedGoal.deadlineAt
        }
      })
      this.onCloseDeadlineModal()
    }
  }
}
</script>

<style lang="scss" scoped>

.goals--list{
  padding-bottom: 20px;

  &--container {
    padding:0 20px;
  }
}
</style>
