<template lang="pug">
.skill-suggestion__skils
    .skill-suggestion__skils__list
        skills-list-header(
          :isSuggestions="isSuggestions"
          :sortedLabels="sortedLabels"
          :currentSortLabel="currentSortLabel"
          :isSkillProfile="isSkillProfile"
          @on-sort="onSort")
        v-scrollable(
          :bottom-offset="100"
          updatable
          :min-height="0"
          @scroll-complete="onScrollEnd")
            div(v-if="!isSkillProfile")
              v-list-item(v-for="item in list" :key="item.id" @click="onSelect(item)")
                  badge(
                      :width="30"
                      :color="item.color"
                      :level="item.starsCount")
                  v-list-item-content
                    v-list-item-title
                      span.skill-suggestion__skils__list--title {{ item.name }}
                    v-list-item-subtitle
                      span {{ item.levelName }}
                  v-list-item-actions
                    v-btn(
                      icon
                      v-tooltip="$t('ui.labels.open_details')"
                      @click.native.stop="onSelect(item)")
                        i.fas.fa-arrow-right
                    v-btn(
                      v-if="isSuggestions"
                      danger
                      icon
                      :disabled="allowDiscard"
                      v-tooltip="$t('ui.labels.discard')"
                      @click.native.stop="onDiscard(item)")
                        i.fa.fa-trash-alt
            skills-profile-list(v-else
              :list="list"
              @on-select="onSelect")
            .skill-suggestion__skils__skeleton(v-if='!loaded || isPreloadPagination')
              div.skill-suggestion__skils__skeleton--item(
                  v-for="i in 3"
                  :key="i")
                v-skeleton(
                  width="100%"
                  height="66px")
        v-list-empty(v-if="!list.length  && loaded")
          i.icon.icon--sm.icon--no-result
          span {{ 'ui.labels.no_results' | translate }}
</template>

<script>
import { mapActions } from 'vuex'
import Badge from '@/components/svg/Badge'
import SkillsListHeader from './SkillsListHeader'
import SkillsProfileList from './SkillsProfileList'

export default {
  name: 'SkillsList',

  components: {
    Badge,
    SkillsListHeader,
    SkillsProfileList
  },

  props: {
    isSuggestions: Boolean,
    onLoad: Function,
    isSkillProfile: Boolean,
    list: Array
  },

  mounted () {
    this.init()
  },

  data: (vm) => ({
    page: 1,
    loaded: false,
    sortParams: {
      sort: vm.isSkillProfile ? 'levelGap' : 'name'
    },
    blockScroll: true,
    isPreloadPagination: false,
    currentSortLabel: vm.isSkillProfile ? 'levelGap' : 'name',
    sortedLabels: {
      name: !vm.isSkillProfile,
      levelGap: vm.isSkillProfile,
      starsCount: false
    },
    allowDiscard: false
  }),

  methods: {
    ...mapActions('suggestions', [
      'onDeclineSuggestionSkill'
    ]),

     async init () {
      this.blockScroll = true
      this.page = 1
      this.loaded = false
      await this.loadData()
      this.loaded = true
    },

    onSort (fieldName, order) {
      if (this.loaded) {
        const params = {
          sort: order ? `-${fieldName}` : fieldName
        }
        this.sortParams = JSON.parse(JSON.stringify(params, {}))
        this.init()
        for (const key in this.sortedLabels) {
          if (key === fieldName) {
            this.sortedLabels[key] = !this.sortedLabels[key]
            this.currentSortLabel = fieldName
          } else {
            this.sortedLabels[key] = false
          }
        }
      }
    },

    async loadData () {
      const filters = {}
        filters.page = this.page
        filters.sort = this.sortParams.sort
        let employeeId = this.$route.params.employee_id
        if (employeeId) {
          filters.forEmployeeId = employeeId
        }

        const { headers, data } = await this.onLoad(filters)
        if (data) {
          this.isPreloadPagination = false
          this.usersLength = headers['x-pagination-total-count']
          if (+headers['x-pagination-page-count'] > this.page) {
            this.blockScroll = false
          } else {
            this.blockScroll = true
          }
        }
    },

    onScrollEnd () {
      if (!this.blockScroll) {
        this.isPreloadPagination = true
        this.blockScroll = true
        this.page++
        this.loadData()
      }
    },

    async onDiscard (item) {
      this.allowDiscard = true
      await this.onDeclineSuggestionSkill(item.id)
      this.allowDiscard = false
    },

    onSelect (item) {
      if (this.$route.path.includes('/share')) {
        this.$router.push(`/employees/share/${this.$route.params.id}/badge/${item.id}`)
      } else {
        this.$router.push(`${this.navigateTo}/badge/${item.id}`)
      }
    }
  },
  computed: {
    navigateTo () {
      if (['/my', '/employee'].some(item => this.$route.path.includes(item))) {
        return this.$route.path.split('/')[1] === 'employee' ? '/' + this.$route.path.split('/')[1] + '/' + this.$route.path.split('/')[2]
          : '/' + this.$route.path.split('/')[1]
      } else return '/my'
    }
  }

}
</script>

<style lang="scss" scoped>

  .skill-suggestion__skils {
    padding-bottom:20px;

    span {
      color: $body-text ;
    }

    &__list {

      &--title {
        font-size: 14px;
        font-weight: bold;
      }

      .v-list__item {
        padding: 5px 20px !important;
      }

      .v-list__item-content {
        padding: 0 15px;
      }

    }

    &__skeleton {
      &--item {
        padding-top:10px
      }
    }
  }
</style>
