import { NODE_TYPE, STRUCTURE_BASE_BADGE } from '@/util/constants'

export const prepearNode = (node, level = 0) => {
  node.props = {
    pos: { x: 0, y: 0 },
    ...JSON.parse(JSON.stringify(STRUCTURE_BASE_BADGE))
  }
  for (const field in node) {
    if (field !== 'subBadges' && field !== 'props') {
      node.props[field] = node[field]
      delete node[field]
    }
    if (field === 'template' && node.props[field]) {
      (node.gradationalSubBadges || []).forEach(el => {
        if (el.template) el.template.name = ''
      })
      node.props[field].name = ''
    }
  }

  if (node.props.hookedBadge) {
    node.props.hookedBadge = prepearNode(node.props.hookedBadge)
  }
  node.children = []
  if (node.subBadges) {
    let childrenWithoutHooked = node.subBadges.filter(item => {
      return item.typeId !== 'HOOKED_BADGE'
    })
    node.children = [...childrenWithoutHooked]
    delete node.subBadges
  }
  node.localProps = {
    level: level,
    showInput: false,
    isCut: false,
    pos: { x: 0, y: 0 },
    angle: 0,
    step: 0,
    tounched: false
  }
  return node
}

const filtersCallback = (node, params) => {
  const compareKeys = (objA, objB) => {
    for (const key in objB) {
      if (objB[key] !== null && typeof objB[key] === 'object' && objA[key] !== null) {
        if (!Array.isArray(objB[key])) {
          if (!compareKeys(objA[key], objB[key])) {
            return false
          }
        } else {
          return objA[key].length === objB[key].length
        }
      } else {
        if (objA[key] !== objB[key]) {
          return false
        }
      }
    }
    return true
  }
  return compareKeys(node.props, params)
}

export const findNodes = (nodes, params = {}) => {
  const result = nodes.filter(node => filtersCallback(node, params))
  for (const node of nodes) {
    result.push(...findNodes(node.children, params))
  }
  return result
}

export const findNode = (nodes, params) => {
  let result = nodes.find(node => filtersCallback(node, params))
  if (result === undefined) {
    for (const node of nodes) {
      if (node.props.hookedBadge && node.props.hookedBadge.props.id === params.id) {
        result = node.props.hookedBadge
        break
      }
      result = findNode(node.children, params)
      if (result !== undefined) {
        break
      }
    }
  } else {
    result.parent = nodes
  }
  return result
}

export const findNodeBySubbadgeId = (nodes, { id }) => {
  let result = nodes.find(node => {
    return !!(node.props.id === id ||
        (node.props.gradationalSubBadges || []).find(el => el.id === id) ||
        (node.props.hookedBadge?.props.id === id))
  })
  if (result === undefined) {
    for (const node of nodes) {
      result = findNodeBySubbadgeId(node.children, { id })
      if (result !== undefined) {
        break
      }
    }
  }
  return result
}

export const getNodesByRequiredBadgeId = (nodes, { badgeId }) => {
  let result = nodes.filter(node => {
    let requiredBadgesOfNode = [...node.props.requiredBadges || []]
    for (let i in node.props.gradationalSubBadges) {
      if (node.props.gradationalSubBadges[i]?.requiredBadges) {
        requiredBadgesOfNode.push(...node.props.gradationalSubBadges[i].requiredBadges)
      }
    }
    if (node.props?.hookedBadge?.props) {
      requiredBadgesOfNode.push(...node.props.hookedBadge.props.requiredBadges)
    }
    return !!(requiredBadgesOfNode.find(el => el.id === badgeId))
  })
  for (const node of nodes) {
    result.push(...getNodesByRequiredBadgeId(node.children, { badgeId }))
  }
  return result
}

export const updateNodesDisplayNames = (node, updateRequiredBadgeCb) => {
  node.props.displayName = node.props.name + (node.props.levelName ? ' ' + node.props.levelName : '')
  if (updateRequiredBadgeCb) {
    updateRequiredBadgeCb({ badgeId: node.props.id, params: { displayName: node.props.displayName } })
  }
  for (const subbadge of node.props.gradationalSubBadges) {
    subbadge.displayName = subbadge.name + (subbadge.levelName ? ' ' + subbadge.levelName : '')
    if (updateRequiredBadgeCb) {
      updateRequiredBadgeCb({ badgeId: subbadge.id, params: { displayName: subbadge.displayName } })
    }
  }
  return node
}

export const updateNode = (node, props, updateSubbadges = false) => {
  const updateKeys = (objA, objB) => {
    if (!objB || !objA) return
    for (const key in objB) {
      if (objB[key] !== null && typeof objB[key] === 'object' && !Array.isArray(objB[key])) {
          if (!objA[key]) {
            objA[key] = {}
          }
          updateKeys(objA[key], objB[key])
      } else {
        objA[key] = objB[key]
      }
    }
    return node
  }
  if (updateSubbadges) {
    for (const subbadge of node.gradationalSubBadges) {
      updateKeys(subbadge, props)
    }
  }
  return updateKeys(node, props)
}

export const removeNode = (nodes, params) => {
  const node = findNode(nodes, params)
  if (node) {
    const index = node.parent.indexOf(node)
    node.parent.splice(index, 1)
  }
}

export const sortSubbadges = (node, oldIndex, newIndex) => {
  if (oldIndex !== newIndex) {
    if (oldIndex === 0) {
      const tmp = node.props.gradationalSubBadges.splice(0, 1)[0]

      const gradationalSubBadges = Array.from(node.props.gradationalSubBadges)
      node.props.gradationalSubBadges = []

      const newGradationalSubBadge = {}
      Object.assign(newGradationalSubBadge, node.props)

      newGradationalSubBadge.typeId = NODE_TYPE.GRADATIONAL_SUB_BADGE
      gradationalSubBadges.splice(newIndex - 1, 0, newGradationalSubBadge)

      tmp.gradationalSubBadges = gradationalSubBadges
      tmp.pos = newGradationalSubBadge.pos
      tmp.typeId = NODE_TYPE.GRADATIONAL_BADGE

      node.props = tmp
    } else if (newIndex === 0) {
      const tmp = node.props.gradationalSubBadges.splice(oldIndex - 1, 1)[0]

      const gradationalSubBadges = Array.from(node.props.gradationalSubBadges)
      node.props.gradationalSubBadges = []

      const newGradationalSubBadge = {}
      Object.assign(newGradationalSubBadge, node.props)

      newGradationalSubBadge.typeId = NODE_TYPE.GRADATIONAL_SUB_BADGE
      gradationalSubBadges.splice(0, 0, newGradationalSubBadge)

      tmp.gradationalSubBadges = gradationalSubBadges
      tmp.pos = newGradationalSubBadge.pos
      tmp.typeId = NODE_TYPE.GRADATIONAL_BADGE

      node.props = tmp
    } else {
      const tmp = node.props.gradationalSubBadges.splice(oldIndex - 1, 1)[0]
      node.props.gradationalSubBadges.splice(newIndex - 1, 0, tmp)
    }
    node.props.starsCount = 1
    node.props.gradationalSubBadges.forEach((item, index) => {
      item.starsCount = index + 2
    })
  }
  return node
}

export const filterMyTree = (nodes) => {
 if (!nodes) return null

  const checkStatusInGradational = (badgeProps) => {
    return !!(badgeProps.gradationalSubBadges || []).find(el => el.helpers?.isProfileBadge || el.helpers?.isGoal || el.helpers?.isRecommended)
  }
  const getLastCategoriesInChain = (node, newNode) => {
    if (node.props.typeId === NODE_TYPE.CATEGORY) {
      const result = []
      if (node.children[0]?.props.typeId === NODE_TYPE.CATEGORY) {
        for (const indx in node.children) {
          newNode.children[indx] = { ...node.children[indx], children: [ ...node.children[indx].children ] }
          result.push(...getLastCategoriesInChain(node.children[indx], newNode.children[indx]))
        }
        return result
      }

      const activated = node.children
          .filter(item => item.props.helpers?.isProfileBadge ||
            item.props.isActivated ||
            item.props.helpers?.isGoal ||
            item.props.helpers?.isRecommended ||
            checkStatusInGradational(item.props))

      if (activated.length) {
          newNode.children = activated
          return [newNode]
      }

      return []
    }
    return []
  }
  let copyTree = {}
  const getMyTreeBadges = (nodes) => {
    const newNodes = []
    for (const key in nodes) {
      const node = nodes[key]
      copyTree[key] = { ...node }
      copyTree[key].children = []
      if (node.localProps.level === 1 &&
        node.props.typeId === NODE_TYPE.CATEGORY) {
        if (node.children.length) {
          if (node.children[0]?.props.typeId === NODE_TYPE.CATEGORY) {
            const result = []
            for (const indx in node.children) {
              copyTree[key].children[indx] = { ...node.children[indx], children: [ ...node.children[indx].children ] }
              result.push(...getLastCategoriesInChain(node.children[indx], copyTree[key].children[indx]))
            }
            copyTree[key].children = result
          } else {
            copyTree[key].children = node.children
              .filter(item => {
                if (item.props.helpers?.isProfileBadge ||
                  item.props.isActivated ||
                  item.props.helpers?.isGoal ||
                  item.props.helpers?.isRecommended) return true

                const subBadges = item.props.gradationalSubBadges.filter(subItem => subItem.helpers?.isProfileBadge ||
                  subItem.isActivated ||
                  subItem.helpers?.isGoal ||
                  subItem.helpers?.isRecommended)

                return !!subBadges.length
              })
          }
        }
      }
      newNodes.push(copyTree[key])
    }
    return newNodes
      .filter(item => item.children.length)
  }
  copyTree = { ...nodes, children: getMyTreeBadges(nodes.children) }
  return copyTree
}

export const addParents = (node, parents = []) => {
  node.parents = parents
  if (node.children) {
    const childParents = [node, ...parents]
    node.children.forEach(child => addParents(child, childParents))
  }
}

export const addParentsForHookedBadges = (node) => {
  if (node.props.hookedBadge && node.props.hookedBadge !== null) {
    node.props.hookedBadge.parents = [node, ...node.parents]
  }
  if (node.children) {
    node.children.forEach(child => addParentsForHookedBadges(child))
  }
}

export const moveBranch = (node, shift) => {
  node.props.pos.x += shift.x
  node.props.pos.y += shift.y
  if (node.children) {
    node.children.forEach(child => moveBranch(child, shift))
  }
}

export const filterMultipleRootNode = (tree, categoryID) => {
  const maincategory = tree ? findNode([tree], { id: categoryID }) : null
  return maincategory
}
