<template lang="pug">
  .v-badge-select(
    :class="classes")
    .v-badge-select__inner
      dropdown(
        class="badge-dropdown"
        ref="dropdown"
        fixed
        fullsize
        :disabled="disabled"
        :drawer-mode="isMobile"
        :position="position"
        @toggle="toggle"
        @after-toggle="afterToggle"
        @toggle-for-table="toggleForTable"
        :hideOnSelfClick="true")

        .v-badge-select__field(:style="{'min-width': `${width + 10}px`}")
          badge.v-badge-select__field-input(
            v-if="showBadgeLevel"
            :width="width"
            :level="showBadgeLevel"
            :color="color")
          .chevron-wrapper
            chevron(
              color="#646c9a"
              :width="12"
              :height="12"
              :rotate="toggled ? -90 : 90")
        .v-badge-select-content(
          slot="content"
          :class="{ mobile: isMobile }")

          //- Input field and toggler for mobile only
          .v-badge-select__field(v-if="isMobile")
            .v-badge-select__toggler(
              @click="closeDropdown")
              chevron
            badge.v-badge-select__field-input(
              v-if="showBadgeLevel"
              :width="width"
              :level="showBadgeLevel"
              :color="color"
              @click="selectItem(index)")

          //- dropdown items list
          v-scrollable(
            v-if="options.length"
            :min-height="minContentHeight")
            .v-badge-select__items
              ul.v-badge-select-list(
                :class="classes")
                li(
                  v-for="(item, index) in options"
                  :key="item.id"
                  @click="selectItem(index)"
                  :class="{ 'active': selected === index + nonSelectorTabsAmount }")
                  img.icon-close(
                    v-if="selected === index + nonSelectorTabsAmount && !hideActions"
                    src="@/assets/img/icon-close.svg"
                    alt="icon-close"
                    @click.self.stop="removeTab")
                  badge(
                    :width="width"
                    :level="item.starsCount || index + nonSelectorTabsAmount + 1"
                    :color="selected === index + nonSelectorTabsAmount? color: 'gray'")
                li.add-badge(
                    v-if="!hideActions"
                    @click="onOptionCreate")
                    img.icon-add-badge(
                      src="@/assets/img/icon-plus.svg"
                      alt="icon-plus")
            .v-badge-select__loader(v-if="loading")
              v-preloader(:size="24")
          .v-badge-select__loader(v-else-if="loading")
            v-preloader(:size="24")
          .v-badge-select-info(v-else)
            .v-badge-select-info__create-option(
              v-if="hideActions"
              @click="onOptionCreate")
              i.flaticon2-add-1
              span {{ 'ui.buttons.create' | translate }}
                strong "{{ search }}"

</template>

<script>
import helpers from '@/util/helpers'

import Chevron from '@/components/svg/Chevron'
import Dropdown from '@/components/ui/v-select/VSelectDropdown'
import VScrollable from '@/components/ui/v-scrollable/VScrollable'

import VSelectSelectedChips from '@/components/ui/v-select/VSelectSelectedChips'
import Badge from '@/components/svg/Badge.vue'

const MOBILE_MAX_WIDTH = 800

export default {
  name: 'VBadgeSelect',

  components: {
    Chevron,
    Dropdown,
    VScrollable,
    VSelectSelectedChips,
    Badge
  },

  props: {
    nonSelectorTabsAmount: Number,
    width: Number,
    level: Number,
    color: String,
    selected: {
      type: Number,
      default: () => null
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: null
    },
    skeleton: Boolean,
    disabled: Boolean,
    hideActions: {
      type: Boolean,
      default: false
    },
    horizontalMostBorders: {
      type: Object,
      default: () => ({ left: 0, right: 0 })
    }
  },

  async mounted () {
    helpers.windowResizeObserver.subscribe(this.checkForMobile)
    this.checkForMobile()
  },

  beforeDestroy () {
    helpers.windowResizeObserver.unsubscribe(this.checkForMobile)
  },

  data: () => ({
    search: null,
    toggled: false,
    isMobile: false,
    position: {
      x: 0, y: 0
    },
    loading: false
  }),

  methods: {
    removeTab () {
      this.$emit('removeTab')
    },
    selectItem (index) {
      this.$emit('input', index + this.nonSelectorTabsAmount)
      this.closeDropdown()
    },

    onOptionCreate () {
      this.$emit('on-create')
    },

    closeDropdown () {
      if (this.toggled) {
        this.$refs.dropdown.closeDropdown()
        this.$emit('on-change')
      }
    },

    openDropdown () {
      if (!this.toggled) {
        this.$refs.dropdown.toggle()
        this.$emit('on-open-dropdown', true)
      }
    },

    checkForMobile () {
      this.isMobile = window.innerWidth <= MOBILE_MAX_WIDTH
    },

    toggle (value) {
      this.toggled = value
      this.$emit('on-open-dropdown', value)
    },

    getPosition () {
      let x = this.$refs.dropdown.$el.getBoundingClientRect().left
      this.position.y = this.$refs.dropdown.$el.getBoundingClientRect().top
      this.position.x = x
    },

    afterToggle () {
      this.getPosition()
    },

    toggleForTable (value) {
      this.$emit('on-open-dropdown-for-table', value)
      if (!value) {
        this.$emit('on-change')
      }
    }
  },

  computed: {
    showBadgeLevel () {
      if (this.options.length) {
        if (this.selected > this.nonSelectorTabsAmount - 1) {
          return this.selected + 1
        }
        return this.options.length + this.nonSelectorTabsAmount
      } else {
        return 0
      }
    },

    minContentHeight () {
      return this.options.length >= 3 ? 120 : 40
    },

    classes () {
      return {
        'v-badge-select--disabled': this.disabled,
        'v-badge-select--toggled': this.toggled,
        'v-badge-select--loading': this.skeleton
      }
    }
  },

  watch: {
    toggled (value) {
      // TODO: Refator scroll lock logic
      // make something like: this.$scroll.lock()
      const srollPanel = document.getElementsByClassName('__panel')[0]
      const scrollByIDPanel = document.getElementById('scroll-panel')
      const srollPanelHorizontal = document.getElementById('scroll-panel-horizontal')

      if (!value) {
        setTimeout(this.clearInput, 310)
        if (srollPanel) {
          srollPanel.classList.remove('block-root-scroll')
        }
        if (scrollByIDPanel) {
          scrollByIDPanel.firstChild.classList.remove('block-root-scroll')
        }

        if (srollPanelHorizontal) {
          srollPanelHorizontal.firstChild.classList.remove('block-root-scroll')
        }
      } else {
        if (srollPanel) {
          srollPanel.classList.add('block-root-scroll')
        }
        if (scrollByIDPanel) {
          scrollByIDPanel.firstChild.classList.add('block-root-scroll')
        }

        if (srollPanelHorizontal) {
          srollPanelHorizontal.firstChild.classList.add('block-root-scroll')
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/sass/abstracts/_mixins.scss';
.v-badge-select {
  position: relative;
  &--disabled {
    opacity: .5;
  }

  &--loading {
    border-radius: 5px;
    @include skeleton;
  }

  &--loading &__inner {
    opacity: 0;
    visibility: hidden;
  }

  &__inner {
    position: relative;
    width: 100%;
  }

  &__items {
    width:100%;
    margin-right: -15px;
  }

  &__loader {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__field {
    position: relative;
    border-radius: 5px;
    color: #646C9A;
    font-weight: 500;
    overflow: hidden;

    .chevron-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .flaticon2-search-1 {
        color: $color-primary
      }
    }

    span {
      padding: 8px 20px;
      color: #646C9A;
      line-height: 26px;
      display: inline-block;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      width: calc(100% - 20px);
      text-overflow: ellipsis
    }
  }

  &__field-input {
    left: 0;
    position: absolute;
    border: none;
    overflow: hidden;
    width: 100%;
    background: none;
    padding: 8px 25px 8px 20px;
    z-index: 9;

    &::placeholder {
      color: $input-placeholder;
      font-weight: 400;
      text-overflow: ellipsis;
    }

    &[placeholder] {
      color: #646c9a;
      font-weight: 600;
      text-overflow: ellipsis;
    }

    &__active-placeholder {
      &::placeholder {
        font-weight: 600;
        color: #646c9a;
      }
    }
  }

  &-content {
    padding: 6px 7px 6px 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #FFFFFF;
    overflow: hidden;
    box-shadow: -5px 10px 12px 0px rgba(0, 0, 0, 0.02);

    &.mobile {
      z-index: 101;
      height: 100vh;

      .v-badge-select__field {
        position: relative;
        padding-left: 35px;
        box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

        .v-badge-select__toggler {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            transform: rotate(180deg)
          }
        }
      }
    }
  }

  &-info__no-options,
  &-info__create-option {
    color: #646C9A;
    padding: 15px 20px;
    display: flex;
    align-items: center;
  }

  &-info__create-option {
    cursor: pointer;

    &:hover {
      background-color: #eaecf5;
    }

    i {
      margin-right: 10px;
    }

    span {
      margin-right: 5px;
    }
  }

  &-list {
    li {
      display: flex;
      justify-content: center;
      position: relative;

      .icon-close {
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;
        z-index: 100;
        visibility: hidden;
        transition: all .2s;
      }
      &:hover {
        .icon-close {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .active {
      background: #FFFFFF;
    }

    .add-badge{
      height: 50px;
    }
  }
}

.badge-dropdown .v-dropdown{
  &__content {
    width: 100% !important;
    top: 100%!important;
    bottom: 0 !important;
    left: 0 !important;
    position: absolute !important;
    box-sizing: content-box;
  }
}

.skill-detail-modal{
  border-top-right-radius: 0;
}
.icon-add-badge {
  width: 15px;
}

</style>
