<template lang="pug">
  .v-stepper
    slot
</template>

<script>
export default {
  name: 'VStepper',

  props: {
    value: Number,
    notSelectable: Boolean
  },

  data: () => ({
    steps: [],
    views: [],
    activeStep: null,
    activeStepIndex: 0
  }),

  mounted () {
    const header = this.$slots.default
      .find(item => item.componentOptions.tag === 'v-stepper-header')
    if (header) {
      this.steps = header.child.$children
        .filter(item => item.$options._componentTag === 'v-stepper-step')
      if (this.steps.length) {
        this.steps[0].isActive = true
        this.steps.map(item => item.$on('select', () => {
          if (!this.notSelectable) {
            this.onStepSelect()
          }
        }))
      }
    }

    const items = this.$slots.default
      .find(item => item.componentOptions.tag === 'v-stepper-items')
    if (items) {
      this.views = items.child.$children
        .filter(item => item.$options._componentTag === 'v-stepper-content')
      if (this.views.length) {
        this.views[0].isVisible = true
      }
    }
  },

  methods: {
    onStepSelect (step) {
      this.activeStep = step
      let isActive = true
      this.steps.forEach((item, index) => {
        item.isActive = isActive
        if (item.step === this.activeStep) {
          isActive = !isActive
          this.activeStepIndex = index
          this.$emit('input', index)
        }
      })
      this.views.forEach(item => {
        item.isVisible = item.step === this.activeStep
      })
    },

    prevStep () {
      if (this.activeStepIndex > 0) {
        const item = this.steps[this.activeStepIndex - 1]
        this.onStepSelect(item.step)
      }
    },

    nextStep () {
      if (this.activeStepIndex < this.steps.length - 1) {
        const item = this.steps[this.activeStepIndex + 1]
        this.onStepSelect(item.step)
      }
    }
  },

  watch: {
    value (value) {
      this.activeStepIndex = value
    }
  }
}
</script>

<style>
</style>
