<template lang="pug">
  ul.v-tabs(:class="classes")
    li(
      v-for="(item, index) in options"
      :key="index"
      @click="selectItem(index)")
      slot(
        v-if="hasItemSlot"
        name="item"
        :item="item"
        :active="isSelected(index)")
      v-tab-item(
        v-else
        :color="item.color"
        :path="item.path"
        :colored="colored"
        :active="isSelected(index)")
        | {{ item[trackBy] | translate }}
</template>

<script>
import VTabItems from './VTabItem'

export default {
  name: 'VTabs',

  components: {
    VTabItems
  },

  props: {
    value: Number,
    centered: Boolean,
    colored: Boolean,
    options: Array,
    trackBy: {
      type: String,
      default: 'title'
    }
  },

  mounted () {
    this.slectedItem = this.value !== undefined
      ? this.value
      : 0
  },

  data: () => ({
    slectedItem: 0
  }),

  methods: {
    selectItem (index) {
      this.slectedItem = index
      this.$emit('input', index)
    },

    isSelected (index) {
      return this.slectedItem === index
    }
  },

  computed: {
    hasItemSlot () {
      return !!this.$scopedSlots['item']
    },

    classes () {
      return {
        'v-tabs--centered': this.centered
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .v-tabs {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    &--centered {
      justify-content: center;
    }

    li {
      margin-right: 1.5rem;
    }
  }
</style>
