import { execute } from '../../utils'

const ENDPOINT = 'badges'
const VALIDATORS = 'validators'

export default {
  list (id) {
    return execute('GET', `${ENDPOINT}/${id}/${VALIDATORS}`)
  },

  subscribe (id, payload) {
    return execute('POST', `${ENDPOINT}/${id}/${VALIDATORS}`, payload)
  },

  unsubscribe (id, payload) {
    return execute('DELETE', `${ENDPOINT}/${id}/${VALIDATORS}`, payload)
  }
}
