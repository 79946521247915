<template lang="pug">
  v-dialog(
    width="500"
    :value="show"
    @input="onClose")
    v-card
      v-card-head
        v-card-head-label
          h2.modal-title {{ 'ui.buttons.decline_with_comment' | translate }}
        v-card-head-toolbar
          v-btn(
            icon
            gray
            @click="onClose") x
      v-card-content
        .form-group(v-if="showReason")
          label {{ 'ui.labels.reason' | translate }}
          v-select(trackBy='name'
                    :placeholder="'ui.dropdown.select_reason' | translate "
                    :options="reasonOptions"
                    single
                    v-model="values.declineReason")
          span.form-text.invalid {{ 'declineReasonId' | errorText }}
        .form-group(style="margin:0")
          label {{ 'ui.labels.comment' | translate }}
          textarea.form-control(
                    :placeholder="'ui.inputs.enter_comment' | translate "
                    rows="6"
                    v-model="values.declineComment")
          span.form-text.invalid {{ 'declineComment' | errorText }}
      v-card-actions
        v-btn(
          :loading="onCreating"
          @click="onFormSend") {{ onCreating === true ? `${$t('ui.buttons.sending')}...` : 'ui.buttons.send' | translate  }}
</template>
<script>

import api from '@/api'
import { mapActions, mapGetters } from 'vuex'

import UiModal from '@/components/ui/Modal'

export default {
  name: 'DeclineSuggestModal',

  components: {
    UiModal
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    showReason: {
      type: Boolean,
      default: true
    },
    node: Object
  },

  async created () {
    const { data } = await api.getDeclineReasons()
    this.reasonOptions = data
  },

  destroyed () {
    this.clearErrors()
  },

  data: () => ({
    reasonOptions: [],
    onCreating: false,
    values: {
      declineReason: {},
      declineComment: ''
    }
  }),

  methods: {
    ...mapActions('app', ['clearErrors', 'setPopupMessage']),
    ...mapActions('trees', ['declineSuggestNode']),

    async onFormSend () {
      this.onCreating = true
      const body = {}
      body.declineReasonId = this.values.declineReason.id
      body.declineComment = this.values.declineComment
      if (this.node?.props?.isSuggested) {
        await this.declineSuggestNode({ body })
        this.onClose()
      } else {
        this.$emit('decline', {
          declineComment: this.values.declineComment,
          badgeId: this.node?.data?.badgeId || this.node?.props?.id })
      }
      this.onCreating = false
    },

    onChangeSelect (value) {
      this.values.declineReason = value
      this.values.declineComment = (value.defaultComment || '').replace('{firstName}', this.userInfo.firstName) || ''
    },

    onClose () {
      this.$emit('on-close')
    }
  },
  computed: {
    ...mapGetters('app', ['userInfo'])
  }
}
</script>

<style lang="scss" scoped>
@import "~simplemde/dist/simplemde.min.css";

</style>
