<template lang="pug">
v-dropdown.explore-dropdown(:width="240", right, autoClose)
  .explore-dropdown__selected(:style="{ color: badgeColor }")
    icon-badge-dotted-line.explore-dropdown--badge(
      v-if="(badgeType === 'TEMPLATE' && !badgeLevel) || (badgeType === 'TEMPLATE' && badgeLevel > 5)",
      :level="badgeLevel"
    )
    icon-badge-dotted-line-level.explore-dropdown--badge_stars(
      v-else-if="badgeType === 'TEMPLATE' && badgeLevel <= 5",
      :level="badgeLevel"
    )
    icon-badge-category(v-else, :color="badgeColor", :width="22", :height="22")
    span {{ firstLetterUpperCase((selected && selected.name) || '') }}
    icon-chevron.explore-dropdown--chevron
  template(slot="content")
    v-card(elevation)
      .explore-dropdown__search
        v-input-search(
          :placeholder="'ui.inputs.type_to_search' | translate",
          type="text",
          v-model="search"
        )
      v-scrollable(:min-height="20", :bottom-offset="10")
        template(v-if="data.length")
          v-list-item(
            v-for="temp in filterData",
            :key="temp.name + temp.id",
            @click="selectItem(temp)"
          )
            v-radio(
              :id="temp.id",
              :active="selected && selected.id === temp.id"
            )
            v-list-item-title
              | {{ firstLetterUpperCase(temp.name) }}
          v-list-empty(v-if="!filterData.length")
            .icon.icon--sm.icon--no-result
            span {{ 'ui.labels.no_results' | translate }}
</template>

<script>
import IconChevron from '@/components/svg/icons/IconChevron.vue'
import IconBadgeDottedLine from '@/components/svg/icons/IconBadgeDottedLine.vue'
import IconBadgeDottedLineLevel from '@/components/svg/icons/IconBadgeDottedLineLevel.vue'
// import IconBadgeCategory from '@/components/svg/CategoryCircle'
import IconBadgeCategory from '@/components/svg/skillWizard/IconCategory.vue'

export default {
  name: 'ExploreDropList',
  components: {
    IconChevron,
    IconBadgeDottedLine,
    IconBadgeDottedLineLevel,
    IconBadgeCategory
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    badgeType: {
      type: String,
      default: 'TEMPLATE'
      // or CATEGORY
    }
  },
  data: () => ({
    selected: null,
    search: ''
  }),
  mounted () {
    this.selectItem(this.data[0])
  },
  computed: {
    filterData () {
      return this.data.filter((el) =>
        el.name.toUpperCase().includes(this.search.toUpperCase())
      )
    },
    badgeLevel () {
      return !this.selected
        ? 0
        : this.selected.hasOwnProperty('starsCount')
        ? this.selected.starsCount
        : this.selected.hasOwnProperty('levels')
        ? this.selected.levels.length
        : 0
    },
    badgeColor () {
      return !this.selected
        ? '#29A687'
        : this.selected.hasOwnProperty('color')
        ? this.selected.color
        : '#29A687'
    }
  },
  methods: {
    selectItem (item) {
      this.selected = item
      this.$emit('selectItem', item)
    },

    firstLetterUpperCase (text = '') {
      return text ? text[0].toUpperCase() + text.substring(1) : text
    }
  }
}
</script>

<style lang="scss" scoped>
.truncate-text-2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.explore-dropdown {
  &__selected {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: inherit;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      width: auto;
      white-space: nowrap;
    }
  }
  &--badge {
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    &_stars {
      min-width: 16px;
      max-width: 16px;
      height: 32px;
      margin-right: 6px;
    }
  }
  &__search {
    margin-bottom: 8px;
    ::v-deep input {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      padding-right: 8px;
    }
  }
  &--chevron {
    min-width: 12px;
    width: 12px;
    height: 6px;
    color: $body-text-2;
  }

 ::v-deep .v-dropdown__content {
    top: 100%;
    margin-top: 4px;
  }
 ::v-deep .v-dropdown__toggler {
    overflow: hidden;
  }

  .v-card {
    padding: 8px;
  }

  .v-list__item {
    gap: 8px;
    padding: 12px 8px;
    border: 0;

    &:hover {
      background-color: $website-color;
    }
    ::v-deep .v-radio {
      &__state-icon {
        margin: 0;
        width: 24px;
        height: 24px;
        &::after {
          width: 16px;
          height: 16px;
        }
      }
    }

    .v-list__item-title {
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      color: $body-text;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>
