<template lang="pug">
  v-group
    template(v-for="(badge, index) in badges")
      tree-line-badge(
        v-if="checkForRecommended(badge)"
        :begin="parentPos"
        :end="getPos(index)"
        :color="lineColor"
        :direction="direction")
    tree-line-badge(
      v-if="selectedIndex !== -1"
      color="#ffdbac"
      :stroke-width="4"
      :begin="parentPos"
      :end="getPos(selectedIndex)"
      :direction="direction")
    template(v-for="(badge, index) in badges")
      tree-label-and-badge(
        v-if="checkForRecommended(badge)"
        :key="badge.props.id"
        :badge="badge"
        :color="color"
        :depth="depth"
        :cursorNode="cursorNode"
        :direction="direction"
        :pos-badge="getPos(index)"
        :pos-label="getPos(index, 0, -40)"
        :focus-chain="isBadgeInFocusChain(badge)"
        @onClick="onClick")
</template>

<script>
import TreeLineBadge from './TreeLineBadge'
import TreeLabelAndBadge from './TreeLabelAndBadge'

const BADGE_STEP = 120

export default {
  name: 'TreeBadgeBranch',

  components: {
    TreeLineBadge,
    TreeLabelAndBadge
  },

  props: {
    color: String,
    lineColor: String,
    parentPos: Object,
    badges: Array,
    direction: Number,
    depth: Number,
    selectedChain: Array,
    cursorNode: Object
  },

  methods: {
    onClick (node) {
      this.$emit('click', node)
    },

    getPos (index, shiftX = 0, shiftY = 0) {
      return {
        x: this.parentPos.x + this.direction * ((BADGE_STEP * index) + Math.max((450 - 50 * this.depth), 200)) + shiftX,
        y: this.parentPos.y - 40 + shiftY
      }
    },

    isBadgeInFocusChain (badge) {
      return this.selectedChain
        .some(item => item.props.id === badge.props.id)
    },
    checkForRecommended (badge) {
      return !badge.props.isSuggested
    }
  },

  computed: {
    selectedIndex () {
      if (this.cursorNode) {
        return this.badges
          .findIndex(item => item.props.id === this.cursorNode.props.id)
      }
      return -1
    }
  }
  // computed: {
  //   hasCursorNode () {
  //     return this.badges
  //       .some(item => item.props.id === this.cursorNode.props.id)
  //   }
  // }
}
</script>

<style lang="scss">
</style>
