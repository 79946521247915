<template lang="pug">
  svg(
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    :width="width")
    path(
      fill-rule="nonzero"
      d="M32 3.223L28.777 0 16 12.777 3.223 0 0 3.223 12.777 16 0 28.777 3.223 32 16 19.223 28.777 32 32 28.777 19.223 16z"
      :fill="color")
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 15
    },
    color: {
      type: String,
      default: '#dfe2ea'
    }
  }
}
</script>
