<template lang="pug">
svg(:width="width" height="1" :viewbox="viewbox" xmlns="http://www.w3.org/2000/svg")
  line(x1="0.5" y1="0.5" :x2="width" y2="0.499979" :stroke="color" stroke-linecap="round" stroke-dasharray="6 6")
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#D8DAE3'
    },

    width: {
      type: String,
      default: '240'
    }
  },

  computed: {
    viewbox () {
      return `0 0 ${this.width} 1`
    }
  }
}
</script>
