<template lang="pug">
  .chips-wrapper
    template(v-if="!hasSelected && !simple")
      span.label {{ 'ui.labels.no_items_selected' | translate }}
    template(v-else-if="hasAllSelected && !simple")
      span.label {{ 'ui.labels.you_selected_all_items' | translate }}
    template(v-else)
      template(v-if="hasCategories")
        span.label {{ 'ui.labels.you_select' | translate }}
        template(v-for="item in options")
          template(v-if="hasChildren(item)")
            v-select-selected-chips-group(
              :group="item"
              :track-by="trackBy"
              :selected="getSelected(item.children)"
              :unselected="getUnselected(item.children)"
              @select="itemSelect")
          template(v-else)
            .chip(v-if="isSelected(item)"
              @click="itemSelect({ item: item, isSelected: true })")
              | {{ item[trackBy] }}
      template(v-if="!hasCategories && !hasAllSelected && showUnselectedItems && !simple")
        span.label {{ 'ui.labels.you_select_all_items_except' | translate }}
        .chip(v-for="item in unselectedItems" :key="item.id"
          @click="itemSelect({ item: item, isSelected: false })")
          span.chip__label(style='cursor:pointer') {{ item[trackBy] }}
      template(v-else)
        .chip(v-for="item in selected" :key="item.id")
          span.chip__label {{ item[trackBy] }}
          .chip__delete(@click="itemUnselect({ item: item, isSelected: true })")
            span ×

</template>

<script>
import VSelectSelectedChipsGroup from './VSelectSelectedChipsGroup'

export default {
  name: 'VSelectSelectedChips',

  components: {
    VSelectSelectedChipsGroup
  },

  props: {
    trackBy: String,
    options: Array,
    selected: Array,
    simple: Boolean
  },

  methods: {
    itemSelect (payload) {
      this.$emit('select', payload)
    },

    itemUnselect (payload) {
      this.$emit('unselect', payload)
    },

    isSelected (item) {
      if (this.hasChildren(item)) {
        return !item.children.find(child => {
          return !this.isSelected(child)
        })
      }
      return this.selected
        .map(el => el.id)
        .indexOf(item.id) !== -1
    },

    getSelected (items) {
      const result = []
      for (const item of items) {
        if (this.hasChildren(item)) {
          result.push(...this.getSelected(item.children))
        } else {
          if (this.isSelected(item)) {
            result.push(item)
          }
        }
      }
      return result
    },

    getUnselected (items) {
      let result = []
      for (let item of items) {
        if (this.hasChildren(item)) {
          result.push(...this.getUnselected(item.children))
        } else {
          if (!this.isSelected(item)) {
            result.push(item)
          }
        }
      }
      return result
    },

    hasChildren (item) {
      return !!(item.children && item.children.length)
    }
  },

  computed: {
    hasSelected () {
      return !!this.selected.length
    },

    hasAllSelected () {
      if (this.hasSelected) {
        return !this.options.some(item => {
          return !this.isSelected(item)
        })
      }
      return false
    },

    hasCategories () {
      return this.options.some(item => {
        return this.hasChildren(item)
      })
    },

    unselectedItems () {
      if (this.hasSelected &&
        !this.hasCategories &&
        !this.hasAllSelected) {
        return this.getUnselected(this.options)
      }
      return []
    },

    showUnselectedItems () {
      return this.selected.length / this.options.length >= 0.8
    }
  }
}
</script>

<style lang="scss">
  .chips-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .label {
      font-size: 12px;
      padding: 3px;
      padding-left: 0;
      margin-right: 5px;
      margin-top: 7px;
      margin-bottom: 0px;
    }

    .chip {
      margin-top: 7px;
      padding-left: 17px;
      margin-right: 5px;
      display: flex;
      align-items: center;
      padding: 0px 10px;
      background-color: #EBFFFA;
      height: 30px;
      width: fit-content;
      border: 1px solid rgba(82, 63, 105, 0.05);
      border-radius: 3px;

      &__label {
          color: #828282;
          font-size: 13px;
          line-height: 14px;

          &:nth-child(2){
            margin: 0 3px;
            padding-bottom: 1px;
          }
        }

        &__delete {
          margin-left: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 14px;
          width: 14px;
          background-color: #20C49B;
          border-radius: 10px;
          cursor: pointer;

          span {
            font-weight: bold;
            line-height: 14px;
            font-size: 14px;
            color:white
          }

          &:hover {
            background-color: lighten(#20C49B, 3%)
          }
        }
    }
  }
</style>
