<template lang="pug">
div.filter-view-list(v-if="isCategory(item) && (isIncludes(item) || filteringByValue(item.children))" :class="{'root-category':item.props.id === 1 }")
  h5(
    :class="[{active:item.props.id===selectedCategoryId}]"
    @click="selectedCategory(item)"
    @click.right.prevent="onOpenContextMenu({event:$event,category:item})")
    div
      v-chevron(v-show="item.children.length && isCategory(item.children[0])" list-mode :value="toggle" @click.stop.native="onToggle()")
    icon-category-circle(:color="setColor")
    | {{ item.props.name }} {{calculateNumberBadges(item) ? `(${calculateNumberBadges(item)})`: ''}}
  ul(v-if="toggle && item.children.length && isCategory(item.children[0])")
      li(v-for="child of sortByAlphabetical(item.children)" :key="child.props.id")
        filter-view-list(
        :item="child"
        :value="value"
        :showSuggestBadges="showSuggestBadges"
        :selected-category-id="selectedCategoryId"
        :collapse="collapse"
        :open-ids="openIds"
        @selectedCategory="selectedCategory($event)"
        @onOpenContextMenu="onOpenContextMenu($event)")
</template>

<script>
import iconCategoryCircle from '@/components/svg/CategoryCircle'
import { NODE_TYPE, COLOR_PRIMARY } from '@/util/constants'

export default {
  name: 'filterViewList',
  components: { iconCategoryCircle },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => null
    },
    value: {
      type: String,
      default: ''
    },
    selectedCategoryId: {
      type: Number,
      default: 1
    },
    showSuggestBadges: {
      type: Boolean,
      default: false
    },
    collapse: {
      type: Boolean,
      default: false
    },

    openIds: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    toggle: false
  }),

  mounted () {
    if (this.item.props.id === 1 || this.openIds.includes(this.item.props.id)) {
        this.toggle = true
    }
  },

  computed: {
    setColor () {
      if (this.item.props.color) {
        return this.item.props.color
      } else {
        const parentColor = this.item.parents.find(el => el.props.color)
        return parentColor ? parentColor.props.color : COLOR_PRIMARY
      }
    }
  },

  watch: {
    collapse (state) {
      this.toggle = state
    }
  },
  methods: {

    onOpenContextMenu ({ event, category }) {
      this.$emit('onOpenContextMenu', { event, category })
    },

    selectedCategory (category) {
      this.$emit('selectedCategory', category)
    },
    filteringByValue (children) {
      const result = children.some(el => {
        return el.children.length && this.isCategory(el.children[0])
          ? this.filteringByValue(el.children) || this.isIncludes(el)
          : this.isIncludes(el) && this.isCategory(el)
      })
      return result
    },
    isIncludes (item) {
      return item.props.name.toLowerCase().includes(this.value.toLowerCase())
    },
    isCategory (item) {
      return item.props.typeId === NODE_TYPE.CATEGORY
    },
    onToggle () {
      this.toggle = !this.toggle
    },
    calculateNumberBadges (category) {
        let count = 0
        const calcCount = (node) => {
          if (node.children.length >= 1) {
          for (let item of node.children) {
            if (item.props.typeId === 'CATEGORY') {
              calcCount(item)
            } else {
              count = this.showSuggestBadges
                      ? count + 1
                      : !item.props.isSuggested
                        ? count + 1
                        : count
            }
          }
        }
        }
        calcCount(category)
      return count
    },

  sortByAlphabetical (array) {
    if (!array.length) return []
    const newArray = [...array]
    newArray.sort((a, b) => {
      const titleA = a.props.name.toLowerCase()
      const titleB = b.props.name.toLowerCase()
      if (titleA < titleB) {
        return -1
      }
      if (titleA > titleB) {
        return 1
      }
      return 0
    })

    return newArray
  }
  }
}
</script>

<style lang="scss" scoped>
.filter-view-list {
  position: relative;
  width: max-content;
  &:not(.root-category){
    margin-left: 20px;
  }

  h5{
    cursor: pointer;
    width: fit-content;
    display: grid;
    grid-template-columns: 15px auto 1fr;
    gap: 4px;
    align-items: center;
    margin: 0 0 4px 0;
    &.active{
      color: $color-primary;
      text-decoration: underline;
    }
  }

  li{
    font-weight: 500;
    list-style: none;
    display: flex;
    align-items: center;
    &:hover{
        color: green;
    }
  }
}
</style>
