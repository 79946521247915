import VCard from './VCard'
import VCardActions from './VCardActions'
import VCardContent from './VCardContent'
import VCardTitle from './VCardTitle'
import VCardHead from './VCardHead'
import VCardHeadLabel from './VCardHeadLabel'
import VCardHeadToolbar from './VCardHeadToolbar'

export default {
  VCard,
  VCardActions,
  VCardContent,
  VCardTitle,
  VCardHead,
  VCardHeadLabel,
  VCardHeadToolbar
}
