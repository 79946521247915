import api from '@/api'

const TOGGLE_BADGE = 'TOGGLE_BADGE'

export default {
  namespaced: true,

  state () {
    return {
    }
  },

  getters: {
  },

  mutations: {
    [TOGGLE_BADGE] (state, badge) {
      badge.isActivated = !badge.isActivated

      if (badge.isActivated) {
        badge.activatedAt = new Date()
      }
    }
  },

  actions: {
    async toggleBadge (context, { badge, params = {}, payload = null, toggleFromStore = true, node }) {
      if (toggleFromStore) {
        context.commit(TOGGLE_BADGE, badge)
      }
      if (payload && payload.comment) badge.activationComment = payload.comment
      const { error, data } = await api.badges.toggleActivated(badge.id, params, payload)
      if (!error && node) {
        const badges = node.props?.gradationalSubBadges ? [node.props, ...node.props.gradationalSubBadges] : [node.props]
        const currenBadgeIndex = badges.findIndex(el => el.id === badge.id)
        const params = {
          isActivated: badge.isActivated
        }
        context.dispatch('trees/updateRequiredBadge', { badgeId: badge.id, params }, { root: true })
        if (badge.isActivated) {
          for (const i in badges) {
            if (+i >= +currenBadgeIndex) break
              badges[i].isActivated = true
              context.dispatch('trees/updateRequiredBadge', { badgeId: badges[i].id, params }, { root: true })
          }
        }
      }
      return { error, data }
    }
  }
}
