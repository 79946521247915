<template lang="pug">
  v-scrollable(
    :bottom-offset="100")
    .profile-info
      .profile(v-if="employeeDetail")
        .profile-header
          .profile-img
            img(:src="employeeDetail.profilePictureUrl || '/img/no-profile-picture.svg'")
          .profile-name-wrapper
            span.profile-name-wrapper__username {{ employeeDetail.firstName }}  {{ employeeDetail.lastName }}
            span.profile-name-wrapper__position(
              v-if="position") {{ position.name }}
        ul.profile-icons-desc
          li(v-if="department")
            i
              img(src="@/assets/img/department.svg")
            span {{ department.name}}
          li(v-if="location")
            i
              img(src="@/assets/img/world.svg")
            span {{ location.name }}
        .profile-description {{ employeeDetail.description }}
        div.share-profile(@click="shareActive" v-if="allowShare")
          icon-fat-right-arrow.right-arrow
          span {{ 'ui.labels.share_profile' | translate}}
        span.show-contact-data(
          v-if="!loadContactData && !employeeDetail.hasContact"
          @click="getContactData")
          icon-notebook.notebook
          | {{ 'ui.labels.show_contact_data' | translate }}
        div.loading-indicator-wrap(v-else-if="loadContactData && !employeeDetail.hasContact")
          span.loading-indicator
        ul.profile-contacts(v-if="employeeDetail.hasContact")
          li(v-if="employeeDetail.phoneNumber")
            i
              img(src="@/assets/img/phone.svg")
            a(:href="`tel:${employeeDetail.phoneNumber}`") {{ employeeDetail.phoneNumber }}
          li(v-if="employeeDetail.email")
            i
              img(src="@/assets/img/mail.svg")
            a(:href="`mailto:${employeeDetail.email}`") {{ employeeDetail.email }}
          li(v-if="employeeDetail.slackId")
            i
              img(src="@/assets/img/slack.svg")
            span {{ employeeDetail.slackId }}

</template>

<script>
import api from '@/api'
import IconFatRightArrow from '@/components/svg/icons/IconFatRightArrow.vue'
import IconNotebook from '@/components/svg/icons/IconNotebook.vue'
import { mapGetters } from 'vuex'

export default {

  components: {
    IconNotebook,
    IconFatRightArrow
  },

  props: {
    employee: Object,
    minimize: Boolean
  },

  data: () => ({
    employeeDetail: null,
    position: null,
    department: null,
    location: null,
    loadContactData: false
  }),

  computed: {
    ...mapGetters('permissions', [
      'currentPermissions'
    ]),

    allowShare () {
      return this.currentPermissions && this.currentPermissions.SKILLTREE_SHARE_PROFILE
    }
  },

  methods: {
    async getContactData () {
      this.loadContactData = true
      const { data } = await api.getEmployeeContact(this.employee.id)
      if (data) {
        this.employeeDetail.hasContact = true
        this.employeeDetail = { ...this.employeeDetail, ...data }
        this.loadContactData = false
      }
    },

    shareActive () {
      this.$emit('shareActive')
    }
  },

  mounted () {
    this.employeeDetail = this.employee
    if (this.employeeDetail.department) {
      this.department = this.employeeDetail.department
    } else if (this.employeeDetail.position && this.employeeDetail.position.department) {
      this.department = this.employeeDetail.department = this.employeeDetail.position.department
    }
    this.location = this.employeeDetail.location
    this.position = this.employeeDetail.position
    if (!this.employee.id) {
      this.employeeDetail.hasContact = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .profile-info {
    padding: 20px;
    border-radius: 7px;
    min-height: 200px;
    display: flex;

    .profile {
      width: 86%;
      .profile-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .profile-img {
          border: 3px solid lightgrey;
          border-radius: 50%;
          overflow: hidden;
          width: 75px;
          height: 75px;
          min-width: 75px;

          img {
            display: block;
            width: 100%;
          }
        }

        .profile-name-wrapper {
          display: flex;
          flex-direction: column;
          padding-left: 10px;

          &__username {
              color: $h2-title;
              font-size: 14px;
              font-weight: 700;
            }

          &__position {
            font-weight: 500;
            font-size: 14px;
            color: rgba(37, 47, 57, 0.5);
          }
        }
      }

          .show-contact-data, .share-profile {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: $body-text;
            text-decoration-line: underline;
            cursor: pointer;
            padding-bottom: 6px;

            .notebook, .right-arrow{
              margin-right: 9px;
            }
          }

          .loading-indicator-wrap {
            width: 100%;
            height: 21px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .loading-indicator {
            height: 16px;
            width: 16px;
            background: url('../../../assets/img/preloader.svg') no-repeat;
            background-size: 15px;
          }

      .profile-position {
        color: $color-black;
        margin: 20px 0;
      }

      .profile-icons-desc {
        margin-top: 25px;
        margin-bottom: 15px;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          margin-bottom: 5px;

          i {
            display: block;
            width: 15px;
            margin-right: 10px;
            flex-shrink: 0;

            img {
              display: block;
            }
          }
        }
      }

      .profile-description {
        margin: 30px 0 25px;
      }

      .profile-contacts {
        padding: 0;
        border-top: 1px solid $color-line-dotted;
        padding-top: 18px;
        margin-top: 22px;

        li {
          display: flex;
          align-items: center;
          padding: 5px 0;

          i {
            display: block;
            width: 15px;
            padding: 1px;
            border: 1px solid lightgrey;
            border-radius: 2px;
            margin-right: 10px;

            img {
              display: block;
            }
          }

          a {
            word-break: break-word;
          }
        }
      }
    }
  }
</style>
