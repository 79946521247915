<template lang="pug">
div
  validation(v-if="!loading" :badge="badgeSelect"
    :selectedUsers="validators"
    :loading="loadingValidators"
    @on-change-mode="onChangeMode"
    @on-change-users="onUsersChange")
  v-skeleton(v-else width="100%" height="100px")
</template>

<script>

import Validation from '../../Validation'
import api from '@/api'
import { VALIDATION_MODE } from '@/util/constants'

export default {
  name: 'BadgeDetailValidation',

  components: {
    Validation
  },

  props: {
    badge: Object,
    loading: Boolean
  },

  mounted () {
    this.init()
  },

  data: () => ({
    badgeSelect: {},
    validators: [],
    loadingValidators: false
  }),

  methods: {
    init () {
      this.loadValidators()
      this.badgeSelect = { ...this.badge }
    },

    async  loadValidators () {
      this.validators = this.badge.validators || []
      if (this.onloadUsers && this.badge.id) {
        this.loadingValidators = true
        let { data } = await api.badges.templates.getTemplateLevelValidators(this.badge.id)
        if (data && data.length) {
          data.forEach(element => {
            element.name = element.firstName + ' ' + element.lastName
          })
          this.validators = data
          this.badge.validators = data
        }
        this.loadingValidators = false
      }
    },

    onChangeMode (mode, allow = false) {
      if (this.badge.validationTypeId !== mode || allow) {
        this.badge.validationTypeId = mode
        this.badgeSelect.validationTypeId = mode
        this.$emit('on-change-mode')
        this.loadValidators()
      }
    },

    async onUsersChange (users) {
      if (users.length !== this.validators.length) {
        if (users.length > this.validators.length) {
          await this.registerValidator(users.map(el => el.id))
        } else if (users.length < this.validators.length) {
          let deletedUserId = null
          for (let item of this.validators) {
            let findedItem = users.find(el => el.id === item.id)
            if (!findedItem) {
              deletedUserId = item.id
              break
            }
          }
          await this.deleteValidator(deletedUserId)
        }
        this.validators = [...users]
        this.badge.validators = [...users]
      }
    },

    async deleteValidator (employeeId) {
     await api.badges.templates.deregisterTemplateValidators(this.badge.id, { employeeIds: [employeeId] })
     this.fetchChangeModeWhenUserChange()
    },

    async registerValidator (employeeIds) {
      await api.badges.templates.registerTemplateValidators(this.badge.id, { employeeIds })
      this.fetchChangeModeWhenUserChange()
    },

    fetchChangeModeWhenUserChange () {
      if (this.badge.validationTypeId === VALIDATION_MODE.SPECIFIC_USER) {
        this.onChangeMode(VALIDATION_MODE.SPECIFIC_USER, true)
      } else if (this.badge.validationTypeId === VALIDATION_MODE.SUPERVISOR_AND_SPECIFIC_USER) {
        this.onChangeMode(VALIDATION_MODE.SUPERVISOR_AND_SPECIFIC_USER, true)
      }
    }
  },

  computed: {
    onloadUsers () {
      return this.badge.validationTypeId === VALIDATION_MODE.SPECIFIC_USER ||
        this.badge.validationTypeId === VALIDATION_MODE.SUPERVISOR_AND_SPECIFIC_USER
    }
  },

  watch: {
    badge () {
      this.init()
    }
  }
}
</script>

<style>
</style>
