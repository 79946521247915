<template lang="pug">
  v-card(:elevation="elevation")
    v-card-head
      v-card-head-label
        h2.title {{ 'ui.labels.category' | translate }}
      v-card-head-toolbar
        category-icon(
          :size="40"
          :color="props.color")
    v-scrollable(
      :bottom-offset="10")
      v-card-content
        .form-item
          .form-item_header
            label(for="name") {{ 'ui.labels.name' | translate }}
          input.input-field(
            name="Name"
            type="text"
            v-model="props.name"
            @focus="focusName = true"
            @blur.stop="onBlurInput"
            @keyup.enter="onBlurInput")
          span.error-msg {{ 'name' | errorText }}
        .form-item
          .form-item_header
            .color-icon-wrapp
              label(for="color-category") {{ 'pages.builder.color' | translate }}
              v-info-icon(:text="$t('infotext.choose_a_color_for_this_element')")
          v-color-select(
            reset-color
            v-model="props.color"
            @input="updateChanges")
        .form-item
          .form-item_header
            label {{ 'ui.labels.description' | translate }}
            v-btn(
              icon
              v-tooltip="$t('infotext.edit_the_description')"
              @click="editing = !editing")
              i.fa(:class="editing ? 'fa-eye' : 'fa-edit'")
          markdown-editor-modal(
            v-if="editing"
            :isSaving="isSaving"
            :badge="node.props"
            @on-save="onSaveDescription"
            @on-close="editing = false")
          div(v-html="descriptionHtml")
        .form-item
          v-checkbox(
            :label="'pages.builder.visible' | translate"
            v-model="props.isVisible"
            @input="updateChanges")
        v-btn(
          text
          danger
          paddingless
          v-hover="{ over: () => setHilightSubBranche(true), leave: () => setHilightSubBranche(false) }"
          @click="removeCategory")
          i.fa.fa-trash-alt.mr-2
          span {{ 'ui.buttons.remove' | translate }}
</template>

<script>
import marked from 'marked'
import { mapActions } from 'vuex'
import { COLOR_PRIMARY } from '@/util/constants'

import CategoryIcon from '@/components/svg/CategoryCircle'

import VColorSelect from '@/components/ui/v-color-select/VColorSelect'
import MarkdownEditorModal from './MarkdownEditorModal'

import helpers from '@/util/helpers'

const renderer = {
  link (href, title, text) {
    const escapedText = text.toLowerCase().replace(/[^\w]+/g, '-')
    return `<a name="${escapedText}" title=${title}  href="${href}" target="_blank">
              ${text}
            </a>`
  }
}

marked.use({ renderer })
marked.setOptions({ breaks: true })

export default {
  name: 'Category',

  components: {
    VColorSelect,
    CategoryIcon,
    MarkdownEditorModal
  },

  props: {
    node: Object,
    elevation: Boolean
  },

  mounted () {
    this.init()
  },

  data: () => ({
    props: {
      name: '',
      color: null,
      description: '',
      isVisible: true
    },
    editing: false,
    initialDescription: '',
    prevInputValue: '',
    isSaving: false,
    focusName: false
  }),

  destroyed () {
    if (this.focusName) {
      this.onBlurInput()
    }
  },
  methods: {
    ...mapActions('app', [
      'clearErrors'
    ]),

    ...mapActions('trees', [
      'updateNode'
    ]),

    ...mapActions('treeView', [
      'setHilightSubBranche'
    ]),

    init () {
      this.initialDescription = this.node.props.description
      this.prevInputValue = this.node.props.name
      this.props.color = this.node.props.color || this.getChainColor()
      this.props.name = this.node.props.name
      this.props.description = this.node.props.description
      this.props.isVisible = this.node.props.isVisible
    },

    async onSaveDescription (form) {
      this.props.description = form.description
      await this.updateChanges()
      this.editing = false
    },

    async updateChanges () {
      this.clearErrors()
      this.isSaving = true
      const node = {
        node: this.node,
        props: this.props
      }
      await this.updateNode(node)
      this.isSaving = false
    },

    onBlurInput () {
      if (this.prevInputValue !== this.props.name) {
        this.updateChanges()
      }
      this.focusName = false
    },

    removeCategory () {
      this.$emit('remove')
    },

    getChainColor () {
      const chainNodes = [this.node, ...this.node.parents]
      const node = chainNodes.find(chainNode => chainNode.props.color)
      return node ? node.props.color : COLOR_PRIMARY
    },

    resetToParentColor () {
      const chainNodes = [...this.node.parents]
      const node = chainNodes.find(chainNode => chainNode.props.color)
      this.color = node ? node.props.color : COLOR_PRIMARY
    }
  },

  computed: {
    descriptionHtml () {
      const description = helpers.escapeHTML(this.props.description || '')
      return marked(description)
    },

    categoryName () {
      return this.node.props.name
    }
  },

  watch: {
    node () {
      this.init()
    },

    categoryName (value) {
      if (this.props.name !== value) {
        this.props.name = value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
