import api from '@/api'

import { prepearNode, updateNode } from '@/util/badge-tree-helpers'
import { NODE_TYPE, VALIDATION_STATUS } from '@/util/constants'
import templates from './templates'
import bookmarks from './bookmarks'
import newlyAdded from './newly-added'

const SET_BADGES = 'SET_BADGES'
const ADD_BADGES = 'ADD_BADGES'
const SET_PAGINATION_PARAMS = 'SET_PAGINATION_PARAMS'
const SET_FAVORITES = 'SET_FAVORITES'
const ADD_FAVORITES = 'ADD_FAVORITES'
const DELETE_FAVORITES = 'DELETE_FAVORITES'
const SET_PAGINATION_PARAMS_FAVORITES = 'SET_PAGINATION_PARAMS_FAVORITES'
const SHOW_DECLINE_MODE = 'SHOW_DECLINE_MODE'

const bindSubbadges = (badge, subbadges, parentId) => {
  const index = subbadges
    .findIndex(item => item.parentBadgeId === parentId)
  if (index !== -1) {
    const subbadge = subbadges.splice(index, 1)[0]
    badge.props.gradationalSubBadges.push(subbadge)
    bindSubbadges(badge, subbadges, subbadge.id)
  }
}

export default {
  namespaced: true,

  modules: {
    templates,
    bookmarks,
    newlyAdded
  },

  state () {
    return {
      badges: [],
      paginationParams: {},
      favorites: [],
      favoritesPaginationParams: {},
      showDeclineModal: {
        show: false,
        node: null
      }
    }
  },

  getters: {
    badges: (state) => state.badges,
    paginationParams: (state) => state.paginationParams,
    getFavorites: (state) => state.favorites,
    favoritesPaginationParams: (state) => state.favoritesPaginationParams,
    showDeclineModal: (state) => state.showDeclineModal
  },

  mutations: {
    [SET_BADGES] (state, data) {
      state.badges = data
    },

    [ADD_BADGES] (state, data) {
      state.badges = [...state.badges, ...data]
    },

    [SET_PAGINATION_PARAMS] (state, params) {
      for (const key in params) {
        state.paginationParams[key] = params[key]
      }
    },

    [SET_FAVORITES] (state, data) {
      state.favorites = data
    },

    [ADD_FAVORITES] (state, data) {
      state.favorites.push(data)
    },

    [DELETE_FAVORITES] (state, id) {
      state.favorites = state.favorites.filter(el => el.id !== id)
    },

    [SET_PAGINATION_PARAMS_FAVORITES] (state, data) {
      state.favoritesPaginationParams = data
    },

    [SHOW_DECLINE_MODE] (state, data) {
      for (let key in data) {
        state.showDeclineModal[key] = data[key]
      }
    }
  },

  actions: {
    async loadBadges (context, { params = {}, isPushed = false }) {
      params = {
        ...params,
        noCategories: 1
      }
      const { data, headers } = await api.badges.list(params)

      const paginationParams = {
        page: params.page
      }

      paginationParams.perPage = (headers && parseInt(headers['x-pagination-per-page'])) || 0
      paginationParams.totalCount = (headers && parseInt(headers['x-pagination-total-count'])) || 0
      paginationParams.pages = (headers && parseInt(headers['x-pagination-page-count'])) || 1
      paginationParams.total = paginationParams.totalCount

      if (!paginationParams.page) {
        paginationParams.page = 1
      }

      context.commit(SET_PAGINATION_PARAMS, paginationParams)

      for (const key in data) {
        data[key].checked = false
      }

      if (paginationParams.page > 1 && isPushed) {
        context.commit(ADD_BADGES, data)
      } else {
        context.commit(SET_BADGES, data)
      }
      return { data, headers }
    },

    async searchBadges (context, { params = {}, isPushed = false }) {
      params = {
        ...params,
        noCategories: 1
      }
      const { data, headers } = await api.badges.search(params)

      if (data) {
        const paginationParams = {
          page: params.page,
          q: params.q
        }

        paginationParams.perPage = (headers && parseInt(headers['x-pagination-per-page'])) || 0
        paginationParams.totalCount = (headers && parseInt(headers['x-pagination-total-count'])) || 0
        paginationParams.pages = (headers && parseInt(headers['x-pagination-page-count'])) || 1
        paginationParams.total = paginationParams.totalCount

        context.commit(SET_PAGINATION_PARAMS, paginationParams)

        for (const key in data) {
          data[key].checked = false
        }

        if (paginationParams.page > 1 && isPushed) {
          context.commit(ADD_BADGES, data)
        } else {
          context.commit(SET_BADGES, data)
        }
      }

      return { data, headers }
    },

    async moveBadges (context, { toNode, nodes }) {
      context.dispatch('trees/moveNodes', { toNode, nodes }, { root: true })
      await api.badges.move(toNode.props.id, {
        badgeIds: nodes.map(item => item.props.id)
      })
    },

    async copyBadges (context, { toNode, nodes }) {
      const { data } = await api.badges.copy(toNode.props.id, {
        badgeIds: nodes.map(item => item.props.id)
      })

      const badges = data
        .filter(item => item.typeId === NODE_TYPE.BADGE ||
          item.typeId === NODE_TYPE.GRADATIONAL_BADGE)

      const subbadges = data
        .filter(item => item.typeId === NODE_TYPE.GRADATIONAL_SUB_BADGE)

      const result = []

      for (const badge of badges) {
        prepearNode(badge)
        badge.parents = [toNode, ...toNode.parents]
        if (badge.props.typeId === NODE_TYPE.GRADATIONAL_BADGE) {
          bindSubbadges(badge, subbadges, badge.props.id)
        }
        result.push(badge)
      }

      context.dispatch('trees/addNodes', { toNode, nodes: result }, { root: true })
    },

    async deleteBadges (context, { nodes }) {
      context.dispatch('trees/removeNodes', { nodes }, { root: true })
      await api.badges.deleteMany({ badgeIds: nodes.map(item => item.props.id) })
    },

    async showBadges (context, { nodes }) {
      context.dispatch('trees/updateNodes', { nodes, props: { isVisible: true } }, { root: true })
      await api.badges.show({ badgeIds: nodes.map(item => item.props.id) })
    },

    async hideBadges (context, { nodes }) {
      context.dispatch('trees/updateNodes', { nodes, props: { isVisible: false } }, { root: true })
      await api.badges.hide({ badgeIds: nodes.map(item => item.props.id) })
    },

    linkTemplate (context, { id, templateId }) {
      return api.badges.linkTemplate(id, templateId)
    },

    async unlinkTemplate (context, { id }) {
      await api.badges.unlinkTemplate(id)
      const node = await context.dispatch('trees/getNodeById', id, { root: true })
      const params = {
        nodes: [node],
        props: {
          template: null
        },
        updateSubbadges: true
      }
      await context.dispatch('trees/updateNodes', params, { root: true })
    },

    async requestBadgeWithValidations (context, { badge, params, payload, node }) {
      badge.validation = {}
      badge.validation.statusId = VALIDATION_STATUS.PENDING
      const { data, error } = await api.badges.validation.request(badge.id, params, payload)
      if (data && !error) {
        badge.validation = data
        badge.isActivated = data.statusId === VALIDATION_STATUS.APPROVED
        badge.activationComment = badge.activationComment ? badge.activationComment : data.comment
        if (node) {
          const badges = node.props?.gradationalSubBadges ? [node.props, ...node.props.gradationalSubBadges] : [node.props]
          const currenBadgeIndex = badges.findIndex(el => el.id === badge.id)
          if (badge.isActivated) {
            for (const i in badges) {
              if (+i >= +currenBadgeIndex) break
                badges[i].isActivated = true
                context.dispatch('trees/updateRequiredBadge', { badgeId: badges[i].id, params: { isActivated: badge.isActivated } }, { root: true })
            }
          }
        }
      } else if (error) {
        badge.validation = null
      }
      return { data, error }
    },

    cancelBadgeValidation (context, { badgeId, employeeId }) {
      return api.badges.validation.cancel(badgeId, employeeId)
    },

   async approveBadgeValidation (context, { badgeId, params }) {
      const response = await api.badges.validation.approve(badgeId, params)
      const filters = { 'page': 1, 'sort': 'name' }
      if (params?.forEmployeeId) {
        filters.forEmployeeId = params.forEmployeeId
      }
      await context.dispatch('suggestions/updateMyProfileTabs', filters, { root: true })
      return response
    },

    async declineBadgeValidation (context, { badgeId, params, payload }) {
      const response = await api.badges.validation.decline(badgeId, params, payload)
      const node = await context.dispatch('trees/getNodeById', badgeId, { root: true })
      node.props.validation = response.data
      return response
    },

   async setFavorites ({ commit, getters }, params) {
      const { data, headers } = await api.badges.favorites(params)

      const paginationParams = {
        page: params?.page || 1
      }
      paginationParams.perPage = (headers && parseInt(headers['x-pagination-per-page'])) || 0
      paginationParams.totalCount = (headers && parseInt(headers['x-pagination-total-count'])) || 0
      paginationParams.pages = (headers && parseInt(headers['x-pagination-page-count'])) || 1
      paginationParams.total = paginationParams.totalCount

      commit(SET_PAGINATION_PARAMS_FAVORITES, paginationParams)
      if (paginationParams.page > 1) {
        commit(SET_FAVORITES, [...getters.getFavorites, ...data])
      } else {
        commit(SET_FAVORITES, data)
      }
    },

    async addFavorites ({ commit }, id) {
      const { data } = await api.badges.favoritesAdd(id)
      commit(ADD_FAVORITES, data)
    },

    async deleteFavorites ({ commit }, id) {
     await api.badges.favoritesDelete(id)
      commit(DELETE_FAVORITES, id)
    },

    async favoritesAllDelete ({ commit }) {
      await api.badges.favoritesAllDelete()
      commit(SET_FAVORITES, [])
    },

    async getBadgeById (contex, { node, params }) {
      if (node.localProps.tounched) return
      const nameProps = node.props ? 'props' : 'data'
      const { data } = await api.badges.getById(node[nameProps].id, params)
      node.localProps.tounched = true
      updateNode(node[nameProps], data)
      return data
    }
  }
}
