<template lang="pug">
.skill-suggestion-tabs
  button.skill-suggestion-tabs--card(
    v-for="item in tabs"
    :key="item.tabId"
    :class="{'skill-suggestion-tabs--active' : activetab === item.tabId}"
    @click="onTabChange(item.tabId)")
    span.skill-suggestion-tabs--title.subtitle {{ item.title }}
    .skill-suggestion-tabs--cursor
</template>

<script>

export default {
  props: {
    activetab: {
      type: Number,
      default: 1
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
  }),

  methods: {
    onTabChange (tab) {
      this.$emit('tab-change', tab)
    }
  }
}
</script>

<style lang="scss" scoped>

.skill-suggestion-tabs {
  width:100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &--card {
    position: relative;
    border:0;
    background: 0;
    outline: 0;
    display: flex;
    flex-direction: column;

    &:not(:last-child){
        margin-right: 24px;
    }

    &:nth-child(1) {
       div {
        background-color: $color-primary;
      }
     }

    &:nth-child(2) {
       div {
        background-color: $color-blue ;
      }
     }

    &:nth-child(3) {
       div {
        background-color: $color-red;
      }
     }
      div {
        width: 100%;
      }
  }

  &--active {
    &:nth-child(1) {
      .skill-suggestion-tabs--title {
        color: $color-primary;
      }
    }
    &:nth-child(2) {
      .skill-suggestion-tabs--title {
        color: $color-blue;
      }
    }
    &:nth-child(3) {
      .skill-suggestion-tabs--title {
        color: $color-red;
      }
    }

    .skill-suggestion-tabs--cursor {
      display: inline;
    }
  }

  &--title {
    color: $body-text-2;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
  }

  &--cursor {
    display: none;
    height: 2px;
    border-radius: 4px;
    position: absolute;
    bottom: -1px;
  }
}
</style>
