<template lang="pug">
  div
    .form-item
      .form-item_header
        label {{ 'pages.builder.upload_training_material' | translate }}
          v-info-icon(:text="$t('infotext.training_material_upload_msg')")
      file-pond(
        ref="filePond"
        name="file"
        allow-multiple
        :server="serverOptions"
        :allowImagePreview="false"
        :labelInvalidField="'file-pond.labelInvalidField' | translate"
        :labelFileWaitingForSize="'file-pond.labelFileWaitingForSize' | translate"
        :labelFileSizeNotAvailable="'file-pond.labelFileSizeNotAvailable' | translate"
        :labelFileLoading="'file-pond.labelFileLoading' | translate"
        :labelIdle="'file-pond.labelIdle' | translate"
        :labelFileProcessing="'file-pond.labelFileProcessing' | translate"
        :labelFileProcessingComplete="'file-pond.labelFileProcessingComplete' | translate"
        :labelFileProcessingAborted="'file-pond.labelFileProcessingAborted' | translate"
        :labelFileProcessingError="'file-pond.labelFileProcessingError' | translate"
        :labelFileProcessingRevertError="'file-pond.labelFileProcessingRevertError' | translate"
        :labelFileRemoveError="'file-pond.labelFileRemoveError' | translate"
        :labelTapToCancel="'file-pond.labelTapToCancel' | translate"
        :labelTapToRetry="'file-pond.labelTapToRetry' | translate"
        :labelTapToUndo="'file-pond.labelTapToUndo' | translate"
        @removefile="onRemove")
      .bd-training-material(v-if="trainingFiles.length")
          .bd-training-material__header {{ 'ui.labels.uploaded_files' | translate }}
          v-show-hide(
            :max-height="102"
            :content-height="trainingFiles.length * 34")
            .bd-training-material__list
              .bd-training-material__item(v-for="(item, index) in trainingFiles || []")
                a.bd-training-material__item--data(
                  :key="index"
                  :href="item.url"
                  target="_blank")
                  i(:class="setFileTypeIcon(item)")
                  span {{ item.name }}
                v-btn(
                  icon
                  danger
                  @click="onRemoveFromList(item)")
                  i.fa.fa-trash-alt
</template>

<script>
import api from '@/api'
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js'

import cookie from '@/util/cookie'
import { BASE_URL, TOKEN_COOKIE, ENV } from '@/util/constants'

// Import styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

// Create FilePond component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)
const endpointUrl = ENV.SHOW_DEBUG_TOOLS ? localStorage.getItem('endpointUrl') : null
const HEADERS = {
  'Authorization': 'Bearer ' + cookie.getItem(TOKEN_COOKIE)
}

export default {
  name: 'BadgeDetailTrainingMaterials',

  components: {
    FilePond
  },

  props: {
    badge: Object
  },

  destroyed () {
    if (this.badge.uploadedTrainingFiles) {
      this.trainingFiles.push(...this.badge.uploadedTrainingFiles)
      this.badge.uploadedTrainingFiles = []
    }
  },

  methods: {
    setFileTypeIcon (file) {
      if (file.type.includes('image')) {
        return 'far fa-file-image'
      } else if (file.type.includes('pdf')) {
        return 'far fa-file-pdf'
      }
      return 'far fa-file-archive'
    },

    onLoad (data) {
      if (data) {
        const file = JSON.parse(data)
        if (!this.badge.uploadedTrainingFiles) {
          this.badge.uploadedTrainingFiles = []
        }
        this.badge.uploadedTrainingFiles.push(file)
      }
    },

    onRemoveFromList (data) {
      const trainingFile = this.trainingFiles
        .find(item => item.uuid === data.uuid)
      const index = this.trainingFiles
        .indexOf(trainingFile)
      this.trainingFiles.splice(index, 1)
      api.storage.deleteFile(trainingFile.url)
    },

    onRemove (error, data) {
      if (!error) {
        if (data.status !== 5) {
          let uploadedFile = (this.badge.uploadedTrainingFiles || []).find(el => el.name === data.file.name)
          if (uploadedFile) {
            const index = this.badge.uploadedTrainingFiles
              .indexOf(uploadedFile)
            this.badge.uploadedTrainingFiles.splice(index, 1)
            api.storage.deleteFile(uploadedFile.url)
          }
        }
      }
    }
  },

  computed: {
    trainingFiles () {
      return this.badge.trainingFiles || [] // TODO: refactor
    },

    serverOptions () {
      return {
        url: endpointUrl || BASE_URL,
        process: {
          url: `/uploads/badges/${this.badge.id}`,
          headers: HEADERS,
          onload: this.onLoad
        },
        load: null,
        revert: null
      }
    }
  },

  watch: {
    badge () {
      if (this.badge.uploadedTrainingFiles) {
        this.trainingFiles.push(...this.badge.uploadedTrainingFiles)
        this.badge.uploadedTrainingFiles = []
      }
      this.$refs.filePond.removeFiles()
    }
  }
}
</script>

<style lang="scss" scoped>
  .bd-training-material {
    &__header {
      font-size: 18px;
      margin-bottom: 4px;
    }

    &__item {
      display: flex;
      justify-content: space-between;

      &--data {
        color: #777;
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 300px;

        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &:hover {
          color: #777;

          span {
            text-decoration: underline;
          }
        }

        i {
          margin-right: 10px;
        }
      }

      &:not(:last-child) {
          margin-bottom: 2px;
      }
    }
  }
</style>
