import { execute, cancelableRequest, addQueryParams } from '@/api/utils.js'
import { GET } from '@/api/types.js'
import bookmarks from './bookmarks'

const ENDPOINT = 'badges/skills/external'

const cancelable = cancelableRequest(execute)

export default {
  bookmarks,

  search (params) {
    return cancelable.execute(GET, addQueryParams(`${ENDPOINT}`, params))
  }
}
