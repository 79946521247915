<template lang="pug" functional>
  .v-list__item-actions(
    :class="[data.staticClass, data.class, { 'v-list__item-actions--show-on-hover': props.showOnHover }]")
    slot
</template>

<script>
export default {
  name: 'VListItemActions',

  props: {
    showOnHover: Boolean
  }
}
</script>

<style>
</style>
