<template lang="pug">
  g(:transform="`scale(${size})`")
    g(
      :transform="pos | svgtranslate({ x: -20, y: -20 })"
      :class="{ 'cut': node.localProps.isCut }")
      g(:transform="{ x: 3.5, y: 7.5 } | svgtranslate")
        path.cls-1.under-badge(fill="#ccc" d="M4.89,5.07,15.44,2.68,26,5.07V15.16a13.5,13.5,0,0,1-6.36,11.3l-4.2,2.38-4.19-2.38a13.5,13.5,0,0,1-6.36-11.3Z")
        path.cls-2.under-badge(fill="#ccc" d="M8.73,8.38l6.71-1.65,6.72,1.65v7a8.44,8.44,0,0,1-4.05,7.18l-2.67,1.64-2.66-1.64a8.44,8.44,0,0,1-4-7.18Z")
      g(@click.stop="onClick"
        ref="movedBadge"
        data-rank="move-gradational"
        class="drag-move node-draggable tree-node-badge"
        :data-id="id"
        :data-depth="depth"
        :data-x="pos.x - 20"
        :data-y="pos.y - 20"
        :class="{ 'selected': isSelected,  'hilighted': isHilighted }"
        @contextmenu.prevent="onRightClick")
        path.halo(d="M3 7.44854V23.6468C2.9999 26.9307 3.96866 30.1607 5.81518 33.033C7.6617 35.9053 10.3253 38.3254 13.5554 40.0658L19 43L24.4446 40.0658C27.6747 38.3254 30.3383 35.9053 32.1848 33.033C34.0313 30.1607 35.0001 26.9307 35 23.6468V7.44854L19 4L3 7.44854Z")
        g.badge(:transform="{ x: 3.5, y: 0 } | svgtranslate")
          path.cls-1(:fill="isSuggested ? '#ccc' : color" d="M13.13,34.09s0,0,0,0l0,0c.06.07.11.16.14.2L15,37l1.69-2.64a.74.74,0,0,1,.09-.12l.15-.12c.57-.43,1-.72,3.48-2.21a7,7,0,0,0,2.68-3A8.92,8.92,0,0,0,24,25.07V12.93l-9-1.68L6,12.93V25.07a8.92,8.92,0,0,0,.93,3.84,7,7,0,0,0,2.68,3c.17.11,3,1.74,3.32,2l.2.18Z")

</template>

<script>
import { COLOR_PRIMARY } from '@/util/constants'

export default {
  name: 'TreeNodeBadge',

  props: {
    id: Number,
    color: {
      type: String,
      default: COLOR_PRIMARY
    },
    level: {
      type: [String, Number],
      default: 0
    },
    pos: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    depth: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 1
    },
    node: Object,
    isSelected: {
      type: Boolean,
      default: false
    },
    isHilighted: {
      type: Boolean,
      default: false
    },
    isSuggested: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    if (this.node.localProps.showInput) {
      this.selectNode()
    }
    this.$refs.movedBadge.onnodeclick = this.onClick
  },

  methods: {
    onClick (e) {
      this.selectNode(e)
    },

    selectNode (e) {
      const rect = this.$el.getBoundingClientRect()
      const pos = { x: rect.left, y: rect.top }
      this.$emit('select', {
        event: e,
        pos: pos,
        node: this.node,
        el: this.$refs.movedBadge
      })
    },

    onRightClick (e) {
      const rect = this.$el.getBoundingClientRect()
      const pos = { x: rect.left, y: rect.top }
      this.$emit('right-click', {
        event: e,
        pos: pos,
        node: this.node,
        el: this.$el
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .selected {
    .halo {
      opacity: 1;
    }
  }

  .cls-1,
  .cls-2 {
    fill-rule: evenodd;
  }

  .cls-1 {
    stroke: $website-color;
    stroke-width: 4px;
  }

  .cut {
    .under-badge {
      fill: $website-color;
    }

    .badge {
      opacity: .5;
    }
  }
</style>
