const load = (path) => () => import(`@/debug/components/${path}.vue`)

export default {
  path: '/components',
  redirect: '/components/v-select',
  component: load('Components'),
  children: [
    { path: 'v-select', component: load('v-select/VSelectDemo') },
    { path: 'v-image-select', component: load('v-image-select/VImageSelectDemo') },
    { path: 'v-color-select', component: load('v-color-select/VColorSelectDemo') },
    { path: 'v-tabs', component: load('v-tabs/VTabsDemo') },
    { path: 'v-badge', component: load('v-badge/VBadgeDemo') },
    { path: 'v-btn', component: load('v-btn/VBtnDemo') },
    { path: 'v-chip', component: load('v-chip/VChipDemo') }
  ]
}
