<template lang="pug">
  .v-inputserch
    input.v-inputserch--input(
      v-focus="autoFocus"
      v-model="inputVal"
      type="text"
      @focus="isFocus(true)"
      @blur="isFocus(false)"
      :placeholder="placeholder")
    .v-inputserch--icon(v-if="!showSearchClear")
      search-icon
    .v-inputserch--icon(v-else @click="clearinput")
      close-icon
</template>

<script>
import closeIcon from '../../svg/VInputSearch_CloseIcon'
import searchIcon from '@/components/svg/icons/IconSearch.vue'

export default {
  name: 'VInputSearch',
  components: {
    closeIcon,
    searchIcon
  },
  props: {
    value: String,
    type: {
      type: String,
      default: 'text'
    },
    placeholder: String,
    autoFocus: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    clearinput () {
      this.inputVal = ''
    },
    isFocus (bool) {
      this.$emit('focus', bool)
    }
  },

  computed: {
    inputVal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    showSearchClear () {
      return this.inputVal !== ''
    }
  }
}
</script>
<style lang="scss" scoped>

.v-inputserch {
    position: relative;
    display: flex;
    background: $light-blue;
    border-radius: 5px;
    width: 100%;
    padding: 0px 1rem 0px 1.5rem;
    height: 40px;
    z-index: 1;

    &--icon {
      display: flex;
      cursor: pointer;
      align-items: center;
    }

    &--input {
      width: 100%;
      color: $body-text;
      font-size: 14px;
      font-weight: 400;
      border: 0;
      padding: 0;
      background: $light-blue;
      &::placeholder {
        font-weight: 400;
        color: $body-text-2;
      }
    }
}

</style>
