<template lang="pug">
  .v-show-hide--dropdown(ref="showHideWrapper" :style="constainerStyles")
    .v-show-hide--dropdown__inner(
      ref="inner"
      :style="styles"
      :class="{'v-show-hide--dropdown__inner--toggled ' : toggled}")
      div(
        name="slide-left"
        mode="out-in"
        tag="ul"
        class="v-show-hide--dropdown__list")
        slot
        button.btn-transparent.v-show-hide--dropdown--button(
          v-if="hasHiddenItems"
          @click="toggle"
          :style="hiddenButtonStyles")
          | {{ label | translate }}
      div()
</template>

<script>
import { windowResizeObserver } from '@/util/helpers'

export default {
  name: 'VShowHide',

  props: {
    maxHeight: {
      type: Number,
      default: 30
    }
  },

  mounted () {
    this.reinit()
    windowResizeObserver.subscribe(this.reinit())
  },

  updated () {
    this.reinit()
  },

  beforeDestroy () {
    if (!this.fixed) {
      windowResizeObserver.unsubscribe(this.reinit())
    }
  },

  data: () => ({
    toggled: false,
    containerWidth: 0,
    contentWidth: 0
  }),

  methods: {
    toggle () {
      this.toggled = !this.toggled
    },

    reinit () {
      if (this.$refs.inner && this.getListEl()) {
        this.containerWidth = this.$refs.showHideWrapper.clientWidth
        this.contentWidth = this.$refs.inner.clientWidth

        if (this.contentWidth < this.containerWidth) {
          this.toggled = false
        }
      }
    },

    getListEl () {
      return this.$refs.inner ? this.$refs.inner.childNodes[0] : []
    }
  },

  computed: {
    label () {
      return this.toggled
        ? 'ui.buttons.hide'
        : 'ui.buttons.show_more'
    },

    styles () {
      const height = this.toggled
        ? '100%'
        : this.maxHeight + 'px'
      return `height: ${height}`
    },

    constainerStyles () {
      return `min-height: ${this.maxHeight}px`
    },

    hiddenButtonStyles () {
      return this.toggled ? 'top: calc(100% - 30px); right: 15px' : ''
    },

    hasHiddenItems () {
      return this.contentWidth && this.contentWidth >= this.containerWidth
    }

  }
}
</script>

<style lang="scss" scoped>

  .v-show-hide--dropdown {
    position: relative;
    display: flex;
    width: 100%;

    &__inner {
      overflow: hidden;
    }

    &__list {
      position: relative;
      background-color: white;
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
      padding: 0;
    }

    &__inner--toggled {
      overflow: unset;
      position: absolute;
      left: -15px;

      .v-show-hide--dropdown__list {
        padding: 0 15px 30px 15px
      }
    }

    .v-show-hide--dropdown--button {
      position: absolute;
      right: 0;
      top:0;
      background-color: white;
      min-height: 30px;
      color: $color-primary;
      padding-left: 13px;
    }
  }
</style>
