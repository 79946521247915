<template lang="pug">
  v-list-item(
    :newStyleEnabled="newStyleEnabled",
    :ghost="ghost",
    :noHover="noHover"
    :newStyleDrop="newStyleDrop")
    v-list-item-content.flex-row.align-center
      div(:class="classesBadge")
        badge(
          width="24"
          :color="item.color"
          :level="item.starsCount"
          class="iconMarginLeft")
      v-list-item-title
        strong {{ item.name }}
        span {{ item.levelName }}
    v-list-item-actions
      v-btn(
          v-if="active"
          icon
          success
          @click="$emit('onAdd', item)")
          i.fa.fa-plus
      v-btn(
          v-if="!active && !iconClose"
          icon
          danger
          @click="$emit('onRemove', item)")
          i.fa.fa-minus
      v-btn(v-if="iconClose" icon small danger @click="$emit('onRemove', item)")
        v-icon close

</template>

<script>
import Badge from '@/components/svg/Badge'

export default {
  name: 'SkillsDragItem',

  props: {
    item: Object,
    active: Boolean,
    iconClose: Boolean,
    newStyleEnabled: Boolean,
    ghost: Boolean,
    noHover: Boolean,
    classesBadge: String,
    newStyleDrop: Boolean
  },

  components: {
    Badge
  }
}
</script>

<style lang="scss">

</style>
