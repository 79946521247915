<template lang="pug">
  .skill-detail__actions(v-if="!isCategory")
    //- button.add-goal(
    //-   v-tooltip="$t('infotext.add_to_your_list_of_goals')"
    //-   v-if="!isCategory && isAddToGoalDisabled === false"
    //-   @click="toggleGoal")
    //-   star(:color="isSkillGoal")
    //-   | {{ 'ui.buttons.add_to_my_goals' | translate }}

    button.link-text.text-left.w-100(
      v-if="!isEmployeePage"
      @click="onOpenModalSuggestBadgeToColleague")
      v-icon reset
      | {{  'pages.my.recommend_this_badge_to_colleague' | translate }}

    button.link-text.text-left.w-100(@click="!!checkInFavorites ? removeFromFavorites() : addToFavorites()")
      v-icon bookmark
      | {{ !!checkInFavorites ? 'pages.my.remove_from_favorites' : 'pages.my.add_to_favorites'| translate }}

    badge-suggest-to-colleague(
      v-if="openSuggestToColleagueModal"
      :show="openSuggestToColleagueModal"
      :suggestedBadge="badge"
      @close-modal="onCloseModalSuggestBadgeToColleague")

</template>

<script>
import { mapActions } from 'vuex'
import { COLOR_PRIMARY } from '@/util/constants.js'

import Star from '@/components/svg/Star'
import BadgeSuggestToColleague from '@/components/modals/my/BadgeSuggestToColleague'

export default {
  name: 'SkillDetailActions',

  components: {
    Star,
    BadgeSuggestToColleague
  },

  props: {
    badge: {
      type: Object,
      default: null
    },
    isCategory: Boolean,
    isAddToGoalDisabled: Boolean
  },

  data: () => ({
    suggestedBadge: {},
    openSuggestToColleagueModal: false
  }),

  computed: {

    isSkillGoal () {
      return this.badge.isGoal
        ? COLOR_PRIMARY
        : '#DFE2EA'
    },

    isEmployeePage () {
      return this.$route.path.includes('employee')
    },

    checkInFavorites () {
      return this.badge.helpers.isFavorite
    }
  },

  methods: {
    ...mapActions('trees', [
      'toggleIsGoal',
      'toggleIsFavorite'
    ]),

    ...mapActions('goals', [
      'addGoal',
      'removeGoal'
    ]),

    ...mapActions('badges', [
      'addFavorites',
      'deleteFavorites'
    ]),

    async toggleGoal () {
      const toggled = await this.toggleIsGoal(this.badge)
      const params = {
        badge: this.badge,
        goal: {
          badge: this.badge
        },
        employeeId: +this.$route.params.employee_id
      }
      if (toggled) {
        this.addGoal(params)
      } else {
        this.removeGoal(params)
      }
    },

    addToFavorites () {
      this.addFavorites(this.badge.id)
      this.toggleIsFavorite(this.badge)
    },

    removeFromFavorites () {
      this.deleteFavorites(this.badge.id)
      this.toggleIsFavorite(this.badge)
    },

    onOpenModalSuggestBadgeToColleague () {
      this.suggestedBadge = Object.assign({}, this.badge)
      this.openSuggestToColleagueModal = true
    },

    onCloseModalSuggestBadgeToColleague () {
      this.suggestedBadge = {}
      this.openSuggestToColleagueModal = false
    }
  }
}
</script>

<style>
</style>
