<template lang="pug">
v-dialog(
      width="500"
      :value="show"
      @input="closeModal")
  v-card
    v-card-head
      v-card-head-label
        h2.modal-title {{ 'pages.my.recommend_a_badge_to_colleague' | translate }}
      v-card-head-toolbar
          v-btn(
            icon
            gray
            @click="closeModal") x
    v-card-content
        .form-group
          label(for="employee-suggest") {{ 'pages.my.please_select_colleagues' | translate }}
          v-select(
            fixed
            filterOutside
            :loading="loading"
            :options="searchResultEmployees"
            :is-item-selectable="(item) => { return !item.hasBadge }"
            v-model="selectedEmployees"
            @search-change="searchEmployees"
            @on-scroll-end="onHandleScroolEnd")
            template(
              slot="before-title"
              slot-scope="{ item }")
              v-avatar(
                :size="40"
                :src="item.profilePictureUrl")
            template(
              v-if="item.hasBadge"
              slot="subtitle"
              slot-scope="{ item }")
              | {{ 'ui.labels._already_has_this_badge' | translate }}

          span.form-text.invalid {{ 'employeeIds' | errorText }}

          template(v-if="selectedEmployees.length")
            v-scrollable
              v-show-hide
                v-list-item(
                  v-for="employee in selectedEmployees"
                  :key="employee.id")
                  v-avatar.mr-3(
                    :size="40"
                    :src="employee.profilePictureUrl")
                  v-list-item-content
                    v-list-item-title {{ employee.name }}
                  v-list-item-actions(v-if="!employee.isPreRequired")
                    button.close-btn(
                      @click="onEmployeeRemove(employee)")
                      icon-close(
                        color="#000")
        .empty-list(
          v-show="!selectedEmployees.length")
          | {{ 'ui.labels.list_is_empty'  | translate }}
    v-card-actions
      v-btn(
        text
        @click="closeModal") {{ 'ui.buttons.cancel' | translate }}

      v-btn(
        :loading="onCreating"
        @click="onCreate") {{ 'ui.buttons.suggest' | translate }}

</template>

<script>
import api from '@/api'
import { mapActions } from 'vuex'

import IconClose from '@/components/svg/IconClose'

export default {
  name: 'BadgeSuggestToColleague',

  components: {
    IconClose
  },

  props: {
    show: Boolean,
    suggestedBadge: Object
  },

  data: () => ({
    onCreating: false,
    loading: true,
    selectedEmployees: [],
    searchResultEmployees: [],
    pageNumber: 1,
    search: '',
    scrollRef: null,
    disableScroll: false
  }),

  mounted () {
    this.loadEmployees()
  },

  destroyed () {
    this.clearErrors()
  },

  methods: {
    ...mapActions('app', [
      'getBadges',
      'clearErrors'
    ]),

    async loadEmployees () {
      if (this.disableScroll) return null
      this.loading = true
      let { data, headers } = await api.onLoadEmployeeForReccomendedBadge(this.suggestedBadge.id, this.pageNumber, this.search)
      let parseList = data.map(element => {
        element.employee.name = `${element.employee.firstName} ${element.employee.lastName}`
        element.employee.hasBadge = element.hasBadge
        return element.employee
      })

      parseList = parseList.filter(el => {
        return this.searchResultEmployees.every(item => item.id !== el.id)
      })

      this.searchResultEmployees.push(...parseList)
      if (+headers['x-pagination-page-count'] > this.pageNumber) {
        this.setDibableScroll(false)
      }
      this.loading = false
    },

    async searchEmployees (searchParam) {
      this.setDibableScroll(true)
      this.scrollRef && this.scrollRef.scrollTo({ y: 0 }, 0)
      this.search = searchParam
      this.pageNumber = 1
      let { data } = await api.onLoadEmployeeForReccomendedBadge(this.suggestedBadge.id, this.pageNumber, this.search)
      const parseList = data.map(element => {
        element.employee.name = `${element.employee.firstName} ${element.employee.lastName}`
        element.employee.hasBadge = element.hasBadge
        return element.employee
      })
      this.searchResultEmployees = parseList
      this.setDibableScroll(false)
    },

    setDibableScroll (param) {
      this.disableScroll = param
    },

    async onCreate () {
      this.onCreating = true
      const employeeIds = []
      for (let item of this.selectedEmployees) {
        employeeIds.push(item.id)
      }
      const body = {}
      body.badgeId = this.suggestedBadge.id
      body.employeeIds = employeeIds
      const { error } = await api.onReccomendBadgeToColleague(body)
      if (!error) {
        this.closeModal()
      }
      this.onCreating = false
    },

    closeModal () {
      this.$emit('close-modal')
    },

    onEmployeeRemove (badge) {
      this.selectedEmployees = this.selectedEmployees.filter(el => el.id !== badge.id)
    },

    onHandleScroolEnd (scrollRef) {
      this.pageNumber += 1
      this.scrollRef = scrollRef
      if (!this.disableScroll) {
        this.loadEmployees()
        this.setDibableScroll(true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    .empty-list {
      color: $color-inactive-text;
      font-size: 16px;
      padding-bottom: 30px;
      width: 100%;
      height: calc(100% - 60px);
      display: flex;
      align-items: center;
      justify-content: center;
  }

</style>
