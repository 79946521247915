<template lang="pug">
  .form-style.form-small
    h2.title {{ 'pages.builder.create' | translate }}

    .ranks
      button.btn-transparent.btn-rank(
        :class="{ 'disabled': !allowAddCategory }")
        i.drag-drop.oval-img(
          data-rank="category"
          v-tooltip="hiddenToopTip ? null : $t('infotext.drag_and_drop_a_new_category')")
        span(@click="addCategory") {{ 'ui.labels.category' | translate }}

      button.btn-transparent.btn-rank(
        :class="{ 'disabled': !allowAddBadge }")
        i.drag-drop.badge-img(
          data-rank="badge"
          v-tooltip="hiddenToopTip ? null : $t('infotext.drag_and_drop_a_new_badge_without_levels')")
        span(@click="addBadge") {{ 'ui.labels.badge' | translate }}

      button.btn-transparent.btn-rank(
        :class="{ 'disabled': !allowAddBadge }")
        i.drag-drop.gradational-badge-img(
          data-rank="gradational-badge"
          v-tooltip="hiddenToopTip ? null : $t('infotext.drag_and_drop_a_new_badge_with_levels')")
        span(@click="addGradationalBadge") {{ 'ui.labels.gradational_badge' | translate }}

</template>

<script>
import { NODE_TYPE } from '@/util/constants.js'
import { initDrop } from '@/util/drag-n-drop.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    init: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    if (this.init) {
      initDrop(this.addCategory, this.addBadge, this.addGradationalBadge)
    }
  },

  methods: {
    ...mapActions('trees', [
      'createNode'
    ]),

    addCategory () {
      if (this.allowAddCategory) {
        const parentName = this.cursorNode.props.name
        const badgesCount = this.cursorNode.children.length + 1
        this.createNewNode(`${parentName} - ${this.$t('ui.other._category')} (${badgesCount})`, NODE_TYPE.CATEGORY)
      }
    },

    addBadge () {
      if (this.allowAddBadge) {
        const parentName = this.cursorNode.props.name
        const badgesCount = this.cursorNode.children.length + 1
        this.createNewNode(`${parentName} - ${this.$t('ui.other._badge')} (${badgesCount})`, NODE_TYPE.BADGE)
      }
    },

    addGradationalBadge () {
      if (this.allowAddBadge) {
        const parentName = this.cursorNode.props.name
        const badgesCount = this.cursorNode.children.length + 1
        this.createNewNode(`${parentName} - ${this.$t('ui.other._gradational_badge')} (${badgesCount})`, NODE_TYPE.GRADATIONAL_BADGE)
      }
    },

    async createNewNode (name, type) {
      const data = {
        toNode: this.cursorNode,
        name: name,
        typeId: type
      }
      this.createNode(data)
    }
  },

  computed: {
    ...mapGetters('app', [
      'isDragDropMode',
      'hiddenToopTip'
    ]),

    ...mapGetters('nodeTmp', [
      'cursorNode',
      'lastAddedNode',
      'selectedBadges'
    ]),

    allowAddCategory () {
      return this.cursorNode &&
        !!this.cursorNode.children
    },

    allowAddBadge () {
      return this.cursorNode &&
        !!this.cursorNode.children
    }
  },

  watch: {
    init (value) {
      if (value) {
        initDrop(this.addCategory, this.addBadge, this.addGradationalBadge)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
