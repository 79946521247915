import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuexI18n from 'vuex-i18n'
/* eslint-disable */
import filters from '@/util/filters'
import directives from '@/util/directives'
/* eslint-enable */
import VueSimplemde from 'vue-simplemde'
import 'simplemde/dist/simplemde.min.css'

import { ENV } from '@/util/constants.js'

import vuescroll from 'vuescroll'
import SvgPanZoom from 'vue-svg-pan-zoom'
import VCalendar from 'v-calendar'
import Paginate from 'vuejs-paginate'
import VueLadda from 'vue-ladda'
import VTooltip from 'v-tooltip'
import VueInputAutowidth from 'vue-input-autowidth'
import VueDragDrop from 'vue-drag-drop'
import VueKonva from 'vue-konva'
import VueClipboard from 'vue-clipboard2'

import translationsObject from './i18n/index'

// OPEN: TEST NEW COMPONENT STRUCTURE
import VActionSelector from '@/components/ui/v-action-selector/VActionSelector'
import VAvatar from '@/components/ui/v-avatar/index.js'
import VBadge from '@/components/ui/v-badge/VBadge'
import VBadgeSelector from '@/components/ui/v-badge-selector/VBadgeSelector'
import VBaguette from '@/components/ui/v-baguette/VBaguette'
import VBtn from '@/components/ui/v-btn/VBtn'
import VCard from '@/components/ui/v-card/index.js'
import VCharts from '@/components/ui/v-charts/index.js'
import VCheckbox from '@/components/ui/v-checkbox/VCheckbox'
import VChevron from '@/components/ui/v-chevron/VChevron'
import VChip from '@/components/ui/v-chip/VChip'
import VChipGroup from '@/components/ui/v-chip/VChipGroup'
import VDonut from '@/components/ui/v-donut/VDonut'
import VDialog from '@/components/ui/v-dialog/VDialog'
import VDivider from '@/components/ui/v-divider/VDivider'
import VDropdown from '@/components/ui/v-dropdown/VDropdown'
import VDrawer from '@/components/ui/v-drawer/VDrawer'
import VDropdownBtn from '@/components/ui/v-dropdown-btn/VDropdownBtn'
import VEditable from '@/components/ui/v-editable/VEditable'
import VExpansion from '@/components/ui/v-expansion/VExpansion'
import VExpansionCheckbox from '@/components/ui/v-expansion-checkbox/VExpansionCheckbox'
import VFlipable from '@/components/ui/v-flipable/VFlipable'
import VIcon from '@/components/ui/v-icon/VIcon'
import VInput from '@/components/ui/v-input/VInput'
import VInfoIcon from '@/components/ui/v-info-icon/VInfoIcon'
import VList from '@/components/ui/v-list/index.js'
import VMenu from '@/components/ui/v-menu/index.js'
import VPagination from '@/components/ui/v-pagination/VPagination'
import VPreloader from '@/components/ui/v-preloader/VPreloader'
import VRadio from '@/components/ui/v-radio/index.js'
import VScrollable from '@/components/ui/v-scrollable/VScrollable'
import VSelect from '@/components/ui/v-select/VSelect'
import VSlider from '@/components/ui/v-slider/VSlider'
import VShowHide from '@/components/ui/v-show-hide/VShowHide'
import VSkeleton from '@/components/ui/v-skeleton/VSkeleton'
import VStepper from '@/components/ui/v-stepper/index.js'
import VSwitch from '@/components/ui/v-switch/VSwitch'
import VTable from '@/components/ui/v-table/index.js'
import VTableTree from '@/components/ui/v-table-tree/VTableTree'
import VTabs from '@/components/ui/v-tabs/index.js'
import VToast from '@/components/ui/v-toast/VToast'
import VExpansionPanel from '@/components/ui/v-expansion-panel/VExpansionPanel'
import VShowHideDropdown from '@/components/ui/v-show-hide-dropdown/VShowHideDropdown'
import VInputSearch from './components/ui/v-input-search/VInputSearch'

import { datadogRum } from '@datadog/browser-rum'

const registerComponents = (components) => {
  for (let key in components) {
    Vue.component(key, components[key])
  }
}

registerComponents(VAvatar)
registerComponents(VCard)
registerComponents(VCharts)
registerComponents(VList)
registerComponents(VMenu)
registerComponents(VRadio)
registerComponents(VTable)
registerComponents(VTabs)
registerComponents(VStepper)

Vue.component('VActionSelector', VActionSelector)
Vue.component('VBadge', VBadge)
Vue.component('VBadgeSelector', VBadgeSelector)
Vue.component('VBaguette', VBaguette)
Vue.component('VBtn', VBtn)
Vue.component('VCheckbox', VCheckbox)
Vue.component('VChevron', VChevron)
Vue.component('VChip', VChip)
Vue.component('VChipGroup', VChipGroup)
Vue.component('VCheckbox', VCheckbox)
Vue.component('VDonut', VDonut)
Vue.component('VDialog', VDialog)
Vue.component('VDivider', VDivider)
Vue.component('VDrawer', VDrawer)
Vue.component('VDropdown', VDropdown)
Vue.component('VDropdownBtn', VDropdownBtn)
Vue.component('VEditable', VEditable)
Vue.component('VExpansion', VExpansion)
Vue.component('VExpansionCheckbox', VExpansionCheckbox)
Vue.component('VFlipable', VFlipable)
Vue.component('VIcon', VIcon)
Vue.component('VInput', VInput)
Vue.component('VInputSearch', VInputSearch)
Vue.component('VInfoIcon', VInfoIcon)
Vue.component('VPagination', VPagination)
Vue.component('VPreloader', VPreloader)
Vue.component('VScrollable', VScrollable)
Vue.component('VSelect', VSelect)
Vue.component('VShowHide', VShowHide)
Vue.component('VSkeleton', VSkeleton)
Vue.component('VSlider', VSlider)
Vue.component('VSwitch', VSwitch)
Vue.component('VTableTree', VTableTree)
Vue.component('VToast', VToast)
Vue.component('VExpansionCheckbox', VExpansionCheckbox)
Vue.component('VExpansionPanel', VExpansionPanel)
Vue.component('VShowHideDropdown', VShowHideDropdown)

const scrollConfig = {
  ops: {
    scrollPanel: {
      scrollingX: false
    },
    rail: {
      background: '#000',
      size: '6px',
      opacity: 0.1
    },
    bar: {
      disable: false,
      keepShow: true,
      background: '#c1c1c1',
      size: '6px',
      opacity: 1
    }
  },
  name: 'vue-scroll'
}

const toolTipOptions = {
  defaultPlacement: 'bottom'
}

Vue.use(VueClipboard)
Vue.use(VTooltip, toolTipOptions)
Vue.use(VCalendar)
Vue.use(vuescroll, scrollConfig)
Vue.use(VueInputAutowidth)
Vue.use(VueDragDrop)
Vue.use(VueKonva)

Vue.component('svg-pan-zoom', SvgPanZoom)
Vue.component('paginate', Paginate)
Vue.component('vue-ladda', VueLadda)
Vue.component('vue-simplemde', VueSimplemde)
Vue.directive('tooltip', VTooltip.VTooltip)

Vue.config.productionTip = false

Vue.use(vuexI18n.plugin, store)
Vue.i18n.add('translations', translationsObject['translations'])
Vue.i18n.set('translations')
Vue.i18n.fallback('translations')

if (ENV.DATADOG_ENABLED) {
  const datadogConfig = {
    applicationId: ENV.DATADOG_APPLICATION_ID,
    clientToken: ENV.DATADOG_CLIENT_TOKEN,
    site: ENV.DATADOG_SITE,
    service: ENV.DATADOG_SERVICE,
    env: ENV.DATADOG_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  }

  datadogRum.init(datadogConfig)

  datadogRum.startSessionReplayRecording()
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// Cypress automatically sets window.Cypress by default
if (window.Cypress) {
  window.__store__ = store
}
