export const BASE_URL = process.env.VUE_APP_BASE_URL

export const BASE_AUTH_URL = process.env.VUE_APP_SIGNIN_URL

export const APP_DEMO_URL = process.env.VUE_APP_DEMO_URL_SUFFIX

export const INSTANCE_URL = 'instanceUrl'

export const COLOR_PRIMARY = '#13B389'

export const TREE_SCALE = 1.3

export const TOKEN_COOKIE = 'sessionToken'
export const KEEP_SIGNED_FLAG = 'keepSignedInFlag'

export const NODE_TYPE = {
  BADGE: 'BADGE', // 1
  CATEGORY: 'CATEGORY', // 2
  GRADATIONAL_BADGE: 'GRADATIONAL_BADGE', // 10
  GRADATIONAL_SUB_BADGE: 'GRADATIONAL_SUBBADGE', // 11
  HOOKED_BADGE: 'HOOKED_BADGE'// 12
}

export const NOTIFICATIONS_TYPES = {
  SUGGEST_BADGE: 'SUGGEST_BADGE',
  SUGGEST_BADGE_STATUS: 'SUGGEST_BADGE_STATUS',
  RECOMMEND_BADGE: 'RECOMMEND_BADGE',
  BADGE_VALIDATION_REQUEST: 'BADGE_VALIDATION_REQUEST',
  BADGE_VALIDATION_STATUS: 'BADGE_VALIDATION_STATUS',
  BADGE_EXPIRY: 'BADGE_EXPIRY',
  GOAL_NEW: 'GOAL_NEW',
  GOAL_DEADLINE_APPROACHING: 'GOAL_DEADLINE_APPROACHING',
  GOAL_DEADLINE_TODAY: 'GOAL_DEADLINE_TODAY',
  GOAL_APPROVAL_STATUS: 'GOAL_APPROVAL_STATUS',
  GOAL_COMPLETION_STATUS: 'GOAL_COMPLETION_STATUS',
  GOAL_SUPERVISOR_NEW_COMMENT: 'GOAL_SUPERVISOR_NEW_COMMENT',
  GOAL_EMPLOYEE_NEW_COMMENT: 'GOAL_EMPLOYEE_NEW_COMMENT'
}

export const MODAL_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  WARNING_RED: 'warning-red',
  WARNING_ORANGE: 'warning-orange',
  DANGER: 'danger'
}

export const OS_TYPES = {
  WIN: 'Win',
  MAC: 'Mac',
  UNIX: 'X11',
  LINUX: 'Linux'
}

export const VALIDATION_MODE = {
  SUPERVISOR: 'SUPERVISOR',
  SUPERVISOR_AND_SPECIFIC_USER: 'SUPERVISOR_AND_SPECIFIC_USER',
  SPECIFIC_USER: 'SPECIFIC_USER'
}

export const VALIDATION_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED'
}

export const DATA_SYNC_TYPES = {
  CLOUD_PROVIDER: 'CLOUD_PROVIDER',
  MANUAL: 'MANUAL',
  CSV_IMPORT: 'CSV_IMPORT'
}

export const HYBRID_SEARCH_TYPES = {
  EMPLOYEE: 'EMPLOYEE',
  LOCATION: 'LOCATION',
  DEPARTMENT: 'DEPARTMENT',
  BADGE: 'BADGE',
  POSITION: 'POSITION'
}

export const ENV = new Proxy(process.env, {
  get (target, name) {
    const value = target['VUE_APP_' + name]

    switch (value) {
      case 'true': return true
      case 'false': return false
      default: break
    }

    if (!isNaN(value)) {
      return parseFloat(value)
    }

    try {
      return JSON.parse(value)
    } catch (e) {}

    return value
  }
})

export const TREE_VIEWS_LIST = [
  { id: 0, name: 'pages.my.my_skilltree', key: 'MY_PROFILE' },
  { id: 1, name: 'pages.my.all_skills', key: 'ALL_SKILLS' }
]

export const PREFERENCES_VIEWS = [
  { id: 0, name: 'pages.my.my_skilltree', key: 'MY_SKILLTREE' },
  { id: 1, name: 'pages.my.all_skills', key: 'ALL_SKILLS' },
  { id: 2, name: 'pages.settings.save_selection', key: 'LAST_SELECTED' }
]

export const PREFERENCES_VIEWS_TOGGLER = [
  { id: 0, name: 'pages.my.my_skilltree', key: 'MY_SKILLTREE' },
  { id: 1, name: 'pages.my.all_skills', key: 'ALL_SKILLS' }
]

export const TREE_OPTIONS = [
  {
    id: 'OLD',
    title: 'pages.settings.old_tree',
    use: false
  },
  {
    id: 'NEW',
    title: 'pages.settings.new_tree',
    use: true
  }
]

export const BUILDER_OPTIONS = [
  {
    id: 'TREE',
    title: 'pages.settings.builder_tree_view',
    use: false
  },
  {
    id: 'LIST',
    title: 'pages.settings.builder_list_view',
    use: true
  }
]

export const EMAIL_REMINDERS_PREFERENCES_OPTIONS = [
  {
    value: 'DAILY',
    title: 'app.preferences.email.reminders.options.daily',
    use: false
  },
  {
    value: 'WEEKLY',
    title: 'app.preferences.email.reminders.options.weekly',
    use: false
  },
  {
    value: 'TWO_WEEKS',
    title: 'app.preferences.email.reminders.options.two_weeks',
    use: false
  },
  {
    value: 'MONTHLY',
    title: 'app.preferences.email.reminders.options.monthly',
    use: false
  },
  {
    value: 'NEVER',
    title: 'app.preferences.email.reminders.options.never',
    use: false
  }
]

export const MAX_HEIGTH_DESCRIPTION_BADGE = 150

export const TREE = {
  ALL_SKILLS: 'ALL_SKILLS',
  MY_SKILLTREE: 'MY_SKILLTREE'
}

export const STRUCTURE_BASE_BADGE = {
  displayName: '',
  description: '',
  color: '#ffa024',
  createdAt: null,
  hookedBadgeId: null,
  activatedAt: null,
  expiresAt: null,
  files: [],
  isActivated: false,
  isCommentForActivatingRequired: 0,
  isExpiryRequired: null,
  isFileRequired: null,
  isGoal: false,
  isSuggested: false,
  isVisible: 1,
  levelName: null,
  maxExpiryDays: null,
  name: '',
  parentGradationalBadgeId: null,
  requiredBadges: [],
  starsCount: 0,
  template: {},
  trainingFiles: [],
  typeId: null,
  validationTypeId: null,
  validation: {},
  gradationalSubBadges: [],
  subBadges: [],
  hookedBadge: null,
  helpers: {
      isProfileBadge: false,
      isGoal: false,
      isRecommended: false,
      isFavorite: false
  },
  activationComment: null
}
