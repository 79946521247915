<template lang="pug">
  .gradation-tabs
    slick-list.tablist(
      axis="x"
      append-to=".tablist"
      :distance="5"
      :value="gradationalBadges"
      @sort-end="sortend"
      ref="tablist")
      slick-item.ui-tab(
        v-for="(item, index) in getGradationalBadges"
        :disabled="disableDragAndDrop || hideActions"
        :index="index"
        :key="index"
        :class="{ 'active': activeTabIndex === index }"
        @click.native="onTabClick(index)")
        img.icon-close(
          v-if="gradationalBadges.length > 1 && !hideActions"
          src="@/assets/img/icon-close.svg"
          alt="icon-close"
          @click.stop="removeTab")
        badge(
          :width="badgeWidth"
          :level="item.starsCount || index + 1"
          :color="badgeColor")
      .ui-tab.add-badge(
        v-if="showAddButton && !showSelector"
        @click="addTab")
        img.icon-add-badge(
          src="@/assets/img/icon-plus.svg"
          alt="icon-plus")
      v-badge-selector.ui-tab(
        v-if="showSelector"
        :placeholder="$t('ui.dropdown.select_employee')"
        :options="getGradationalBadgesSelector"
        :selected="activeTabIndex"
        single
        :width="badgeWidth"
        :color="badgeColor"
        @input="onTabClick"
        :class="{ 'active': activeTabIndex >= amountOfTabsWithoutSelector }"
        @on-create="addTab"
        @removeTab="removeTab"
        :hideActions="hideActions"
        :non-selector-tabs-amount="amountOfTabsWithoutSelector")

</template>

<script>
import { SlickList, SlickItem } from 'vue-slicksort'

import Trash from '@/components/svg/Trash.vue'
import Badge from '@/components/svg/Badge.vue'

export default {
  name: 'GradationTabs',

  components: {
    SlickList,
    SlickItem,
    Trash,
    Badge
  },

  props: {
    maxTabsNumber: {
      type: Number,
      default: 5
    },
    hideActions: Boolean,
    gradationalBadges: [Array],
    badgeColor: String,
    activeTabIndex: {
      default: 0,
      type: Number
    },
    disableDragAndDrop: Boolean,
    badgeWidth: {
      type: Number,
      default: 50
    }
  },

  data: () => ({
    dragData: {},
    clientWidth: 0
  }),

  mounted () {
    this.clientWidth = this.$parent.$refs.gradationTabsWrapper?.clientWidth || this.$refs.tablist.$el.clientWidth
  },

  methods: {
    onTabClick (index) {
      this.$emit('setActiveTabIndex', index)
    },

    addTab () {
      this.$emit('didAddGradationalSubBadge')
    },

    removeTab () {
      this.$emit('didRemoveGradationalSubBadge')
    },

    sortend ({ oldIndex, newIndex }) {
      this.$emit('didMoveGradationalSubBadge', oldIndex, newIndex)
    }
  },

  computed: {
    amountOfTabsWithoutSelector () {
      let selectorCellsCount = 2
      if (this.clientWidth > 400) selectorCellsCount = 3
       return Math.floor(this.clientWidth / this.badgeWidth) - selectorCellsCount
    },

    getGradationalBadges () {
      let badges = this.gradationalBadges
      if (this.showSelector) {
          return badges.slice(0, this.amountOfTabsWithoutSelector)
      }
      return badges
    },

    getGradationalBadgesSelector () {
      let badges = this.gradationalBadges
      return badges.slice(this.amountOfTabsWithoutSelector)
    },

    showAddButton () {
      return (!this.maxTabsNumber || this.gradationalBadges.length < this.maxTabsNumber) && !this.hideActions
    },
    showSelector () {
        return this.amountOfTabsWithoutSelector < this.gradationalBadges.length
    }
  }
}
</script>

<style lang="scss" scoped>
  .tablist {
    padding: 0;
    display: flex;

    .ui-tab {
      position: relative;
      padding: 5px;
      padding-bottom: 0;
      cursor: pointer;
      background: $light-blue;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      display: flex;
      justify-content: center;
      min-width: 50px;
      min-height: 55px;

      &.v-badge-select {
        flex: 1;
      }

      .icon-close {
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;
        z-index: 100;
        visibility: hidden;
        transition: all .2s;
      }

      .add-badge {
        height: 10px;
        width: 10px;
      }
      .icon-add-badge{
        object-fit: none;
      }

      &.active {
        background-color: #FFFFFF;

        &:hover {
          .icon-close {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
</style>
