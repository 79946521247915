<template lang="pug">
v-dropdown.export-button(autoClose, right, medium)
  v-btn.export-button--btn(text, paddingless, @click="openExport")
    i.fa.fa-file-export(style={color:'#ABB5BF', fontSize: '14px !important', margin: 0})
</template>

<script>
export default {
  name: 'ExportButton',
  methods: {
    openExport () {
      window.open(process.env.VUE_APP_BASE_URL + '/badges/export', '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
  .export-button {
    position: absolute;
    bottom: 20px;
    right: 40px;
    &--btn{
      background-color: $color-white!important;
      color: $primary-lighten;
      width: 40px;
      height: 40px;
      border: 1px solid $inactive-btn!important;
      display: flex!important;
      align-items: center;
      justify-content: center!important;
      margin: 0;
      svg{
        width: 20px;
        height: 20px;
        color:$color-inactive-text;
      }
    }
    .active {
      span{
          color: $primary-lighten;
      }
    }
  }
  </style>
