<template lang="pug">
  div.login-services
    template
      button.login-services-btn(@click="onClickAuth('AZURE_AD')")
        img(:src="iconMicrosoft" alt="microsoft-sign-in")
        span Sign in with Microsoft
      button.login-services-btn(@click="onClickAuth('GOOGLE_CLOUD')")
        img( :src="iconGoogle" alt="google-sign-in")
        span Sign in with Google
    azure-login-handler(
      ref="azureLoginHandler"
      :thirdPartyData="thirdPartyData")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AzureLoginHandler from './AzureLoginHandler'
import iconGoogle from '@/assets/img/icon-google.svg'
import iconMicrosoft from '@/assets/img/icon-microsoft.svg'

export default {
  name: 'AzureRedirect',

  components: {
    AzureLoginHandler
  },

  data: () => ({
    thirdPartyData: {
      loading: false
    },
    iconGoogle,
    iconMicrosoft
  }),

  methods: {
    ...mapActions('debug', ['setShowDebugModal']),

    onClickAuth (provider) {
      this.$refs.azureLoginHandler.onClickauth(provider)
    },

    openDebug () {
      this.setShowDebugModal(true)
    }
  },

  computed: {
    ...mapGetters('app', [
      'thirdPartyLoginProvider'
    ])
  }
}
</script>

<style lang="scss" scoped>
.login-services{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
    &-btn {
      display: grid;
      align-items: center;
      grid-template-columns: 24px 1fr;
      gap: 15px;
      text-align: center;
      padding: 8px;
      border: 0;
      border-radius: 4px;
      background-color: $color-white;
      box-shadow: 0px 0px 6px $body-text-2;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
</style>
