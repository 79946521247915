<template lang="pug">
</template>

<script>
import { ENV } from '@/util/constants'

const AUTH_SERVER_URL = process.env.VUE_APP_AUTH_SERVER_URL
const parsedHref = window.location.href.split('/')
const hostname = parsedHref[0] + '//' + parsedHref[2]

export default {
  name: 'InstanceConnect',

  data: () => ({
    logInHref: '',
    redirectData: {
      supportToken: '',
      instanceName: '',
      endpointUrl: ''
    },
    currentInstanceWindow: {},
    interval: null,
    loading: false
  }),

  methods: {
    async onHandleData () {
      for (let key in this.redirectData) {
        localStorage.setItem(key, this.redirectData[key])
      }
      this.$emit('on-load', { ...this.redirectData })
    },

    connect () {
      if (this.loading) return
      try {
        this.loading = true

        const newWin = window.open(`${AUTH_SERVER_URL}/go/support?redirectUri=${hostname}/login`, 'auth', 'width=580,height=680,resizable=no')

        this.currentInstanceWindow = newWin

        this.interval = setInterval(() => {
          try {
            this.logInHref = newWin.location.href || ''
            if (newWin && newWin.closed) {
              this.loading = false
            }
          } catch (e) {
            if (newWin && newWin.closed) {
              this.loading = false
            }
          }
        }, 500)

        newWin.onload = () => {
          this.logInHref = newWin.location.href || ''
          if (newWin && newWin.closed) {
            this.loading = false
          }
        }
      } catch (e) {
      }
    }

  },

  computed: {
    isDevelopment () {
      return ENV.SHOW_DEBUG_TOOLS
    }
  },

  watch: {
    logInHref: {
      handler (value) {
        if (value) {
          const url = new URL(value)
          let data = {}
          for (let key in this.redirectData) {
            data[key] = url.searchParams.get(key)
          }
          this.redirectData = { ...data }
        }
      },
      deep: true
    },

    redirectData (value) {
      if (value.supportToken && value.instanceName && value.endpointUrl) {
        clearInterval(this.interval)
        this.currentInstanceWindow.close()
        this.loading = false
        this.onHandleData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
