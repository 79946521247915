<template lang="pug">
  button.v-btn(
    :class="classes"
    :disabled="disabled || loading"
    @click="onClick")
    .v-btn__preloader(v-if="loading")
      v-preloader(:size="preloaderSize")
    span.v-btn__content
      slot

</template>

<script>
export default {
  name: 'VBtn',

  props: {
    text: Boolean,
    fab: Boolean,
    block: Boolean,
    icon: Boolean,
    outline: Boolean,
    danger: Boolean,
    info: Boolean,
    success: Boolean,
    warning: Boolean,
    gray: Boolean,
    disabled: Boolean,
    loading: Boolean,
    large: Boolean,
    small: Boolean,
    paddingless: Boolean,
    stopPropagation: Boolean,
    outlinedText: Boolean,
    reset: Boolean
  },

  methods: {
    onClick (event) {
      if (this.stopPropagation) {
        event.stopPropagation()
      }
      this.$emit('click')
    }
  },

  computed: {
    classes () {
      return {
        'v-btn--text': this.text,
        'v-btn--fab': this.fab,
        'v-btn--block': this.block,
        'v-btn--icon': this.icon,
        'v-btn--outline': this.outline,
        'v-btn--loading': this.loading,
        'v-btn--danger': this.danger,
        'v-btn--info': this.info,
        'v-btn--success': this.success,
        'v-btn--warning': this.warning,
        'v-btn--gray': this.gray,
        'v-btn--large': this.large,
        'v-btn--small': this.small,
        'v-btn--paddingless': this.paddingless,
        'v-btn--outlined-text': this.outlinedText,
        'v-btn--reset': this.reset
      }
    },

    preloaderSize () {
      if (this.large) {
        return 25
      } else if (this.small) {
        return 15
      }
      return 20
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-btn {
    color: $color-white;
    border: none;
    border-radius: $border-radius;
    background-color: $color-primary;
    padding: 12px 24px;
    font-weight: normal;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &__content {
      line-height: 1;
      display: flex;
      align-items: center;
    }

    &:not(&--icon):not(&--fab) {
      // &:not(.v-btn--large):not(.v-btn--small) {
      //   height: $v-btn-height;
      // }

      &.v-btn--large {
        height: $v-btn-height--lg;
      }

      &.v-btn--small {
        height: 30px;
        font-size: 13px;
      }

      min-width: 80px;
    }

    i {
      padding: 0 2px;
      font-size: 13px;
      line-height: 1;

      &.fa {
        font-family: "Font Awesome 5 Free" !important;
        font-size: 13px !important;
        font-weight: 900 !important;
      }
    }

    &:not(&--text):not(&--icon):not(&--outlined-text) {
      &:hover {
        background-color: lighten($color-primary, 2%);
      }

      &:active {
        background-color: darken($color-primary, 2%);
      }

      &:disabled {
        cursor: not-allowed;
        color: $color-primary--disabled;
        background-color: $color-primary--disabled-2;
      }
    }

    &--text,
    &--icon {
      color: $color-black;
      background-color: transparent;

      &:hover {
        color: $color-primary--hover;
      }

      &:active {
        color: $color-primary--active;
      }

      &:disabled {
        cursor: not-allowed;
        color: $color-primary--disabled;
      }
    }

    &--text {
      i {
        margin-right: 0.5rem;
      }
    }

    &--icon,
    &--fab {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      padding: 0;

      &.v-btn--small {
        height: 24px;
        width: 24px;
        .v-btn__content {
          width: 14px;
          height: 14px;
        }
      }
    }

    &--icon {
      &:hover {
        background-color: rgba($color-primary, 0.1);
      }

      &:active {
        color: $color-white;
        background-color: $color-primary--active;
      }

      &:disabled {
        color: $color-primary--disabled;
        background-color: $color-primary--disabled-2;
      }
    }

    &--outline {
      &:hover {
        background-color: transparent;
        border: 1px solid $color-primary--hover;
      }

      &:active {
        color: $color-primary--active;
        border-color: $color-primary--active;
        background-color: transparent;
      }

      &:disabled {
        border: none;
        background-color: transparent;
      }
    }

    &--outlined-text {
      background-color: transparent;
      border: 1px solid $color-primary;
      color: $color-primary;

      &:hover {
        background-color: transparent;
        border: 1px solid $color-primary--hover;
        color:  $color-primary--hover;
      }
    }

    &--block {
      display: flex;
      width: 100%;
    }

    &--reset {
      height: auto !important;
      padding: 0 !important;
      min-width: 0 !important;
    }

    &--loading {
      position: relative;

      .v-btn__content {
        visibility: hidden;
      }
    }

    &--paddingless {
      padding: 0 !important;
      min-width: 0 !important;
    }

    &__preloader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    // DANGER STYLES
    &--danger:not(&--fab) {
      color: $color-error;

      &:hover {
        color: lighten($color-error, 3%);
      }

      &:active {
        color: darken($color-error, 3%);
      }
    }

    &--danger:not(&--icon):not(&--text) {
      color: $color-white;
      background-color: $color-error;

      &:hover {
        background-color: lighten($color-error, 2%) !important;
      }

      &:active {
        background-color: darken($color-error, 2%);
      }

      &:disabled {
        cursor: not-allowed;
        color: $color-primary--disabled !important;
        background-color: $color-primary--disabled-2 !important;
      }
    }

    &--danger {
      &.v-btn--icon:not(.v-btn--outline) {
        &:hover {
          background-color: rgba($color-error, 0.1);
        }

        &:active {
          color: $color-white;
          background-color: $color-error;
        }
      }

      &.v-btn--outline, &.v-btn--outlined-text {
        &:hover {
          border-color: lighten($color-error, 10%);
        }

        &:active {
          border-color: darken($color-error, 10%);
        }
      }

      &.v-btn--outlined-text {
        border-color: lighten($color-error, 10%);
      }
    }

    // INFO STYLES
    &--info:not(&--fab) {
      color: $color-info;

      &:hover {
        color: lighten($color-info, 3%);
      }

      &:active {
        color: darken($color-info, 3%);
      }
    }

    &--info:not(&--icon):not(&--text) {
      color: $color-white;
      background-color: $color-info;

      &:hover {
        background-color: lighten($color-info, 2%);
      }

      &:active {
        background-color: darken($color-info, 2%);
      }
    }

    &--info {
      &.v-btn--icon:not(.v-btn--outline) {
        &:hover {
          background-color: rgba($color-info, 0.1);
        }

        &:active {
          color: $color-white;
          background-color: $color-info;
        }
      }

      &.v-btn--outline {
        &:hover {
          border-color: lighten($color-info, 10%);
        }

        &:active {
          border-color: darken($color-info, 10%);
        }
      }
    }

    // WARNING STYLES
    &--warning:not(&--fab) {
      color: $color-orange;

      &:hover {
        color: lighten($color-orange, 3%);
      }

      &:active {
        color: darken($color-orange, 3%);
      }
    }

    &--warning:not(&--icon):not(&--text) {
      color: $color-white;
      background-color: $color-orange;

      &:hover {
        background-color: lighten($color-orange, 2%);
      }

      &:active {
        background-color: darken($color-orange, 2%);
      }
    }

    &--warning {
      &.v-btn--icon:not(.v-btn--outline) {
        &:hover {
          background-color: rgba($color-orange, 0.1);
        }

        &:active {
          color: $color-white;
          background-color: $color-orange;
        }
      }

      &.v-btn--outline {
        &:hover {
          border-color: lighten($color-orange, 10%);
        }

        &:active {
          border-color: darken($color-orange, 10%);
        }
      }
    }

    // SUCCESS STYLES
    &--success:not(&--fab) {
      color: $color-primary;

      &:hover {
        color: lighten($color-primary, 3%);
      }

      &:active {
        color: darken($color-primary, 3%);
      }
    }

    &--success:not(&--icon):not(&--text) {
      color: $color-white;
      background-color: $color-primary;

      &:hover {
        background-color: lighten($color-primary, 2%);
      }

      &:active {
        background-color: darken($color-primary, 2%);
      }
    }

    &--success {
      &.v-btn--icon:not(.v-btn--outline) {
        &:hover {
          background-color: rgba($color-primary, 0.1);
        }

        &:active {
          color: $color-white;
          background-color: $color-primary;
        }
      }

      &.v-btn--outline {
        &:hover {
          border-color: lighten($color-primary, 10%);
        }

        &:active {
          border-color: darken($color-primary, 10%);
        }
      }
    }

    &--gray {
      &:hover {
        color: $color-black;
        background-color: rgba($color-black, 0.03);
      }

      &:active {
        color: $color-black;
        background-color: rgba($color-black, 0.04);
      }
    }
  }
</style>
