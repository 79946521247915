<template lang="pug">
  .not-found
    .back-text  {{ 'pages.not_found.404' | translate }}
    .inner
      i.ufo
      h2 {{ 'pages.not_found.nothing_to_show_here' | translate }}...
      button.btn.btn-primary(@click="goToMainPage") {{ 'pages.not_found.return_to_skilltree' | translate }}
</template>

<script>
export default {
  name: 'NotFound',

  methods: {
    goToMainPage () {
      this.$router.push('/my')
    }
  }
}
</script>

<style lang="scss" scoped>
  .not-found {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 100;

      h2 {
        margin-bottom: 25px;
      }

      .ufo {
        display: inline-block;
        height: 120px;
        width: 120px;
        background: url('../../assets/icons/info/ufo.svg') no-repeat center;
        margin-bottom: 35px;
      }
    }

    .back-text {
      position: absolute;
      font-size: 800px;
      opacity: 0.05;
      z-index: 0;
    }
  }
</style>
