import Vue from 'vue'
import store from '@/store'

Vue.filter('errorText', (field) => {
  let item = store.getters['app/errors'].find(err => err.field === field)
  return item ? item.message : ''
})

Vue.filter('truncateText', (source, size = 20) => {
  if (size === 0) return source
  else return source && (source.length > size ? source.slice(0, size - 1) + '…' : source)
})

Vue.filter('svgtranslate', (pos, shift = { x: 0, y: 0 }) => {
  return `translate(${pos.x + shift.x}, ${pos.y + shift.y})`
})

Vue.filter('formdate', (value) => {
  if (value && value instanceof Date) {
    return value
      .toISOString()
      .substring(0, 10)
      .split('-')
      .reverse()
      .join('/')
  }
  return value
})

Vue.filter('escapeHTML', (unsafe) => {
  return unsafe.replace(/[&<"']/g, (m) => {
    switch (m) {
      case '&':
        return '&amp;'
      case '<':
        return '&lt;'
      case '"':
        return '&quot;'
      default:
        return '&#039;'
    }
  })
})
