<template lang="pug">
    .explore-column--title
        .explore-column--title_step(v-if="showStep") {{step}}
        h4 {{ title }}
</template>
<script>
export default {
  name: 'ExploreTitle',
  props: {
    title: {
      type: String,
      default: ''
    },
    step: {
      type: [Number, String],
      default: 1
    },
    showStep: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss" scoped>
.explore-column--title {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;

  &_step {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $light-blue;
    cursor: default;
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    color: $body-text;
    text-align: center;
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: $body-text;
  }
}
</style>
