<template lang="pug">
  .v-stepper-items
    slot
</template>

<script>
export default {
  name: 'VStepperItems'
}
</script>

<style lang="scss">
</style>
