import { GET, POST, DELETE, PUT } from '@/api/types.js'
import { addQueryParams, execute } from '../../utils'

const ENDPOINT = '/login/credentials'

export default {
  getTokensList (params = {}) {
    return execute(GET, addQueryParams(ENDPOINT, params))
  },

  generateToken (payload) {
    return execute(POST, `${ENDPOINT}`, payload)
  },

  getExpiringList () {
    return execute(GET, `${ENDPOINT}/expirations`)
  },

  deleteToken (payload) {
    return execute(DELETE, `${ENDPOINT}/${payload}`)
  },

  updateToken (payload, id) {
    return execute(PUT, `${ENDPOINT}/${id}`, payload)
  }
}
