<template lang="pug">
.chevron-slider
  .chevron-slider__grid
    button.chevron-slider__btn(
      v-for="(item) of menu",
      :key="`chevronSlider_${item.id}`",
      :class="[{ active: (selected && selected.id === item.id) || (selected && selected.id > item.id) }, { hover: hover.id >= item.id}]",
       @mouseover="setHover(item.id)"
       @mouseleave="setHover(null)"
      @click="changeItem(item)"
    )
</template>

<script>
export default {
  name: 'ChevronSlider',
  props: {
    menu: {
      type: Array,
      required: true,
      default: () => []
    },
    active: {
      type: Object,
      default: () => null
    },
    activeTab: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    selected: null,
    hover: {}
  }),

  watch: {
    active (val) {
      this.selected = val
    }
  },
  mounted () {
    this.selected = this.activeTab ? this.menu[1] : this.active
  },
  methods: {
    changeItem (item) {
      this.$emit('change', item)
    },
    setHover (ID) {
      this.hover = ID != null ? { id: ID } : {}
    }
  }
}
</script>

<style lang="scss" scoped>
.chevron-slider {
  height: 72px;
  box-sizing: border-box;
  &:hover {
    width: auto;
    .chevron-slider__btn {
      &:first-child {
        width: 8px;
      }

      &:nth-child(2) {
        width: 23px;
      }

      &:nth-child(3) {
        width: 48px;
      }
    }
  }
  &__grid {
    height: 100%;
    display: flex;
    gap: 2px;
  }

  &__btn {
    height: inherit;
    border-radius: 1px;
    border: 0;
    background-color: $color-line-dotted;

    &.active {
      background-color: $body-text-2;
    }
      &.hover {
        background-color: $color-dark-green;
      }

    &:first-child {
      width: 2px;
    }

    &:nth-child(2) {
      width: 2px;
    }

    &:nth-child(3) {
      width: 6px;
    }
  }
}
</style>
