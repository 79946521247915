<template lang="pug">
  svg(width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg")
    path(opacity="0.01" d="M0 0H16V16H0V0Z" fill="white")
    path(fill-rule="evenodd" clip-rule="evenodd" d="M13.7 2.79998V13.2C13.7 13.86 13.16 14.4 12.5 14.4H3.50005C2.84005 14.4 2.30005 13.86 2.30005 13.2V11.4H4.10005C4.93005 11.4 5.60005 10.73 5.60005 9.89998C5.60005 9.06998 4.93005 8.39998 4.10005 8.39998H2.30005V7.59998H4.10005C4.93005 7.59998 5.60005 6.92998 5.60005 6.09998C5.60005 5.26998 4.93005 4.59998 4.10005 4.59998H2.30005V2.79998C2.30005 2.13998 2.84005 1.59998 3.50005 1.59998H12.5C13.16 1.59998 13.7 2.13998 13.7 2.79998Z" fill="#666D74")
    path(d="M4.8001 6.10002C4.8001 6.49002 4.4901 6.80002 4.1001 6.80002H2.3001C1.9101 6.80002 1.6001 6.49002 1.6001 6.10002C1.6001 5.71002 1.9101 5.40002 2.3001 5.40002H4.1001C4.4901 5.40002 4.8001 5.71002 4.8001 6.10002Z" fill="#666D74")
    path(d="M4.8001 9.90007C4.8001 10.2901 4.4901 10.6001 4.1001 10.6001H2.3001C1.9101 10.6001 1.6001 10.2901 1.6001 9.90007C1.6001 9.51007 1.9101 9.20007 2.3001 9.20007H4.1001C4.4901 9.20007 4.8001 9.51007 4.8001 9.90007Z" fill="#666D74")
</template>

<script>
export default {
  name: 'IconNotebook'
}
</script>

<style>
</style>
