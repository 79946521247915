import { render, staticRenderFns } from "./VChevron.vue?vue&type=template&id=56c13868&scoped=true&lang=pug&"
import script from "./VChevron.vue?vue&type=script&lang=js&"
export * from "./VChevron.vue?vue&type=script&lang=js&"
import style0 from "./VChevron.vue?vue&type=style&index=0&id=56c13868&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56c13868",
  null
  
)

export default component.exports