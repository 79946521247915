<template lang="pug">
  .page-login
    .form-wrapper
      .auth-form
        .text-center
          img.img-logo(src="../assets/ui/logo-horizontal.png")
        .portlet
          transition(name="slide-right" mode="out-in")
            auth-login-wrap(
              v-if="showLogin")
            auth-forgot(
              v-else)
    footer.text-center
      span(@click="onShowVersion") {{ 'ui.other.v' | translate }}{{ versionName }}
</template>

<script>
import { mapGetters } from 'vuex'

import AuthLoginWrap from '@/components/common/auth/AuthLoginWrap'
import AuthForgot from '@/components/common/auth/AuthForgot'
import helpers from '@/util/helpers'

export default {
  name: 'Auth',

  components: {
    AuthLoginWrap,
    AuthForgot
  },

  mounted () {
    if (process.env.NODE_ENV !== 'development') {
      window.addEventListener('keydown', (e) => {
        if (e.which === 18) {
          this.keyDown = true
        }
      })

      document.addEventListener('keyup', (e) => {
        if (e.which === 18) {
          this.keyDown = false
          this.onShowVersion(false)
        }
      })
    }
  },

  data: () => ({
    keyDown: false,
    versionIsClicked: false
  }),

  methods: {
    onShowVersion (param = true) {
      this.versionIsClicked = param
    }
  },

  computed: {
    ...mapGetters('app', ['version', 'buildHash']),

    showLogin () {
      return this.$route.path.includes('login')
    },

    versionName () {
      if ((this.keyDown && this.versionIsClicked) || process.env.NODE_ENV === 'development') {
        return this.version + ` (${this.buildHash})`
      }
      return helpers.parseVersion(this.version)
    }
  }
}
</script>

<style lang="scss">
  .page-login {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 30px;
    height: 100vh;

     .under-modal-action-btns {
      position: absolute;
      top: calc(100% + 20px);
      left: 50%;
      transform: translate(-50%, -50%);

      span {
        font-size: 13px;
        font-weight: bold;
        text-decoration: none;
      }
    }

    .form-wrapper {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }

    .auth-form {
      width: 410px;
    }

    .img-logo {
      width: 157px;
      height: 50px;
      margin-bottom: 40px;
    }

    .portlet {
      position: relative;
      // overflow: hidden;
    }

    .login-services {
      margin-top: 40px;
    }

    .form-controls {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        font-size: 13px;
        font-weight: bold;
      }
      .form-controls-right {
        justify-content: flex-end;
      }
      button {
        font-weight: bold;
      }
    }

    footer {
      font-size: 11px;
    }
  }

  @media screen and (max-width: 450px) {
    .page-login {
      align-items: stretch;
      padding-left: 20px;
      padding-right: 20px;

      .auth-form {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 375px) {
    .page-login {
      .auth-form {
        .form-controls {
          margin-top: 20px;
          flex-direction: column-reverse;

          a {
            margin-top: 20px;
          }
        }
      }
    }
  }
</style>
