import api from '@/api'

const SET_ACTIVE_SKILLS_COUNT = 'SET_ACTIVE_SKILLS_COUNT'
const SET_RECOMMENDATIONS_COUNT = 'SET_RECOMMENDATIONS_COUNT'
const SET_SKILL_PROFILE_COUNT = 'SET_SKILL_PROFILE_COUNT'
const SET_RECOMMENDATIONS = 'SET_RECOMMENDATIONS'
const SET_SKILL_PROFILE = 'SET_SKILL_PROFILE'
const SET_ACTIVE_SKILLS = 'SET_ACTIVE_SKILLS'

export default {
  namespaced: true,

  state () {
    return {
      activeSkillsCount: 0,
      recommendationsCount: 0,
      skillProfileCount: 0,
      recommendations: [],
      skillProfile: [],
      activeSkills: []
    }
  },

  getters: {
    activeSkillsCount: (state) => state.activeSkillsCount,
    recommendationsCount: (state) => state.recommendationsCount,
    skillProfileCount: (state) => state.skillProfileCount,
    getRecommendations: (state) => state.recommendations,
    getSkillProfile: (state) => state.skillProfile,
    getActiveSkills: (state) => state.activeSkills
  },

  mutations: {
    [SET_ACTIVE_SKILLS_COUNT] (state, data) {
      state.activeSkillsCount = data
    },

    [SET_RECOMMENDATIONS_COUNT] (state, data) {
      state.recommendationsCount = data
    },

    [SET_SKILL_PROFILE_COUNT] (state, data) {
      state.skillProfileCount = data
    },

    [SET_RECOMMENDATIONS] (state, data) {
      state.recommendations = data
    },

    [SET_SKILL_PROFILE] (state, data) {
      state.skillProfile = data
    },

    [SET_ACTIVE_SKILLS] (state, data) {
      state.activeSkills = data
    }
  },

  actions: {
    async loadActiveSkills ({ commit, state }, filters) {
      if (!filters) {
        filters = { 'page': 1, 'sort': 'name' }
      }
      const { data, headers } = await api.badges.activeSkillsList(filters)
      commit(SET_ACTIVE_SKILLS_COUNT, headers['x-pagination-total-count'] || 0)
      commit(SET_ACTIVE_SKILLS, filters.page === 1 ? data : [...state.activeSkills, ...data])
      return { headers, data }
    },

    async loadSkillProfile ({ commit, state }, filters) {
      const { data, headers } = await api.badges.skillProfileList(filters)
      commit(SET_SKILL_PROFILE_COUNT, data.totalCount ? (data.currentCount || 0) + '/' + data.totalCount : 0)
      commit(SET_SKILL_PROFILE, filters.page === 1 ? data.results : [...state.skillProfile, ...data.results])
      return { headers, data: data.results }
    },

    async onLoadRecommendations ({ commit }, filters) {
      const { data, headers } = await api.badges.skillRecommendationsList(filters)
      commit(SET_RECOMMENDATIONS_COUNT, headers['x-pagination-total-count'] || 0)
      commit(SET_RECOMMENDATIONS, data)
      return { headers, data }
    },

    async onDeclineSuggestionSkill ({ commit, state }, id) {
      await api.badges.skillRecommendationDiscard(id)
      commit(SET_RECOMMENDATIONS_COUNT, state.recommendationsCount - 1)
      const newData = state.recommendations.filter(el => el.id !== id)
      commit(SET_RECOMMENDATIONS, newData)
    },

    changeRecommendations ({ commit, state }, data) {
      const idx = state.recommendations.findIndex(el => el.id === data.id)
      const newData = state.recommendations.filter(el => el.id !== data.id)
      commit(SET_RECOMMENDATIONS, newData)
      if (idx >= 0) {
        commit(SET_RECOMMENDATIONS_COUNT, state.recommendationsCount > 0 ? state.recommendationsCount - 1 : state.recommendationsCount)
      }
    },

    async updateMyProfileTabs ({ commit, dispatch }, filters) {
      if (!filters) {
         filters = { 'page': 1, 'sort': 'name' }
      }
      await dispatch('loadActiveSkills', filters)
      await dispatch('loadSkillProfile', filters)
      await dispatch('onLoadRecommendations', filters)
    }

  }
}
