<template lang="pug">
.new-badge
  template(v-if="isNewlyBadgesCount > 0")
    notifications-about-new-badges(
      :count="isNewlyBadgesCount",
      :in-panel="true"
    )
    v-scrollable(:bottom-offset="100", updatable, :min-height="0")
      .new-badge--main
        .row-new-badges
          span {{ 'pages.my.new_badges' | translate }}
          arrow-top
        v-list-item(
          v-for="item in isNewlyBadges",
          :key="item.id",
          @click="onSelect(item)"
        )
          badge(
            :width="30",
            :color="item.color",
            :level="item && item.maxStarsCount ? item.maxStarsCount : item.starsCount"
          )
          v-list-item-content
            v-list-item-title
              span.new-badge--main_title {{ item.name }}
            v-list-item-subtitle
              span {{ item && item.maxStarsCount ? "" : item.levelName  }}
          v-list-item-actions
            v-btn(
              icon,
              v-tooltip="$t('ui.labels.open_details')",
              @click.native.stop="onSelect(item)"
            )
              arrow-top.arrow-right
  v-list-empty.no-badges(v-else)
    no-badges.no-badges--svg
    span.no-badges--text {{ 'pages.my.no_badges' | translate }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NotificationsNewBadges from '@/components/svg/NotificationsNewBadges.vue'
import NotificationsAboutNewBadges from '@/components/common/notifications/NotificationsAboutNewBadges.vue'
import Badge from '@/components/svg/Badge'
import NoBadges from '@/components/svg/NoBadges.vue'
import ArrowTop from '@/components/svg/ArrowTop.vue'
export default {
  name: 'newBadges',
  components: {
    NotificationsNewBadges,
    Badge,
    NotificationsAboutNewBadges,
    NoBadges,
    ArrowTop
  },
  computed: {
    ...mapGetters('badges/newlyAdded', ['isNewlyBadges', 'isNewlyBadgesCount']),

    navigateTo () {
      if (
        ['/my', '/employee'].some((item) => this.$route.path.includes(item))
      ) {
        return this.$route.path.split('/')[1] === 'employee'
          ? '/' +
              this.$route.path.split('/')[1] +
              '/' +
              this.$route.path.split('/')[2]
          : '/' + this.$route.path.split('/')[1]
      } else return '/my'
    }
  },
  methods: {
    ...mapActions('badges/newlyAdded', ['updateNewlyItemSeen']),

    onSelect (item) {
      this.updateNewlyItemSeen(item.id)
      if (this.$route.path.includes('/share')) {
        this.$router.push(
          `/employees/share/${this.$route.params.id}/badge/${item.id}`
        )
      } else {
        this.$router.push(`${this.navigateTo}/badge/${item.id}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new-badge {
  &--main {
    padding-left: 24px;
    padding-right: 24px;
    &_title {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: $body-text;
    }
  }
  .v-list__item {
    gap: 16px;
    padding: 6px 3px;
  }
  .row-new-badges {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $body-text;
    border-bottom: 1px dashed $color-line-dotted;
    padding-bottom: 12px;
    padding-left: 48px;
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
  ::v-deep .arrow-right {
    width: 14px;
    height: 14px;
    transform: rotate(90deg);
    color: $body-text;
  }
  .no-badges {
    max-width: 235px;
    margin: 72px auto;
    &--svg {
      width: 152px;
      height: 104px;
      margin-bottom: 32px;
    }
    &--text {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: $body-text-2;
    }
  }
}
</style>
