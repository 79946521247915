<template lang="pug">
  div
    .portlet__head.justify-content-center
      .portlet__head-label
        .portlet__head-title
          h4 {{$t("pages.auth.set_new_password" )}}
    .portlet__body
      form.kt-form(@submit.prevent="setNewPassword")
        .form-group(:class="{ 'is-invalid': isPasswordInvalid }")
          input(
            type="password"
            autocomplete="new-password"
            class="form-control"
            :placeholder="'pages.auth.new_password' | translate "
            v-model="password_0")
          span.form-text.invalid {{ 'password' | errorText }}
        .form-group(:class="{ 'is-invalid': !isPasswordConfirmed }")
          input(
            type="password"
            autocomplete="new-password"
            class="form-control"
           :placeholder="'ui.buttons.confirm' | translate "
            v-model="password_1")
          span.form-text.invalid(v-if="!isPasswordConfirmed") {{ 'pages.auth.password_is_not_confirmed'  | translate}}
        .row.form-controls
          .col-md-12.text-right
            v-btn(
              :loading="loading"
              ) {{ 'pages.auth.set_new_password'  | translate}}
</template>

<script>
import api from '@/api'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ForgotPasswordSetNew',

  mounted () {
    this.clearErrors()
  },

  data: () => ({
    password_0: '',
    password_1: '',
    isPasswordConfirmed: true,
    loading: false
  }),
  props: {
    setNewPasswordTitle: {
      type: Boolean,
      default: false
    },
    selectedInstance: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapActions('app', ['initApp', 'clearErrors']),

    async setNewPassword () {
      this.loading = true
      if (this.password_0 !== this.password_1) {
        this.isPasswordConfirmed = false
      } else {
        this.isPasswordConfirmed = true
        let payload = {
          password: this.password_0
        }
        if (this.selectedInstance) {
          payload.instanceCode = this.selectedInstance.code
        }
        if (await api.setNewPassword(payload)) {
          this.setNewPasswordTitle ? this.$emit('resetStep') : this.$router.push('/login')
        }
      }
      this.loading = false
    }
  },

  computed: {
    ...mapGetters('app', ['errors']),

    isPasswordInvalid () {
      return this.errors.find(item => item.field === 'password')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
