<template lang="pug" functional>
  thead.v-table__head
    tr.v-table__row
      slot
</template>

<script>
export default {
  name: 'VTableHead'
}
</script>

<style>
</style>
