<template functional>
  <svg width="24" height="24" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.01" width="24" height="22" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M10.105 16.9995C14.0117 16.9995 17.1787 13.8655 17.1787 9.99951C17.1787 6.13352 14.0117 2.99951 10.105 2.99951C6.1983 2.99951 3.03131 6.13352 3.03131 9.99951C3.03131 13.8655 6.1983 16.9995 10.105 16.9995ZM10.105 18.9995C15.1279 18.9995 19.1997 14.9701 19.1997 9.99951C19.1997 5.02895 15.1279 0.999512 10.105 0.999512C5.08211 0.999512 1.01025 5.02895 1.01025 9.99951C1.01025 14.9701 5.08211 18.9995 10.105 18.9995Z"
          fill="#ccc"/>
    <rect opacity="0.01" x="12.8843" y="8.25" width="10.1053" height="12.7323" fill="white"/>
    <path
      d="M17.5801 20.8916C15.594 19.557 14.9985 18.9772 14.4058 18.395C13.7839 17.7843 13.397 17.2155 13.169 16.6753C12.9392 16.1309 12.8843 15.6473 12.8843 15.2308V9.87947C12.8843 9.59129 13.0867 9.34113 13.3727 9.27581L17.7938 8.26613C17.8412 8.2553 17.8892 8.25 17.9369 8.25V21C17.8118 21 17.6871 20.9635 17.5801 20.8916Z"
      fill="#29A687"/>
    <path
      d="M18.2939 20.8916C20.28 19.557 20.8755 18.9772 21.4683 18.395C22.0902 17.7843 22.4771 17.2155 22.7051 16.6753C22.9348 16.1309 22.9897 15.6473 22.9897 15.2308V9.87947C22.9897 9.59129 22.7873 9.34113 22.5013 9.27581L18.0803 8.26613C18.0329 8.2553 17.9848 8.25 17.9371 8.25V21C18.0622 21 18.1869 20.9635 18.2939 20.8916Z"
      fill="#29A687"/>
    <rect opacity="0.01" x="13.3896" y="9.875" width="9.09474" height="9" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M17.937 10.7749C18.1567 10.7749 18.3349 10.9512 18.3349 11.1687V17.5812C18.3349 17.7986 18.1567 17.9749 17.937 17.9749C17.7172 17.9749 17.5391 17.7986 17.5391 17.5812V11.1687C17.5391 10.9512 17.7172 10.7749 17.937 10.7749Z"
          fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M21.5747 14.3752C21.5747 14.5927 21.3966 14.7689 21.1768 14.7689L14.6968 14.7689C14.4771 14.7689 14.2989 14.5927 14.2989 14.3752C14.2989 14.1577 14.4771 13.9814 14.6968 13.9814L21.1768 13.9814C21.3966 13.9814 21.5747 14.1577 21.5747 14.3752Z"
          fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'HookedBadgeAddIcon'
}
</script>

<style scoped>

</style>
