import { execute, cancelableRequest, addQueryParams } from '../utils'

const ENDPOINT = 'locations'

const cancelable = cancelableRequest(execute)

export default {
  list (params = {}) {
    return execute('GET', addQueryParams(ENDPOINT, params))
  },

  listEmployees (id = null, params = {}) {
    return id !== null
      ? cancelable.execute('GET', addQueryParams(`${ENDPOINT}/${id}/employees`, params))
      : cancelable.execute('GET', addQueryParams(`${ENDPOINT}/employees`, params))
  },

  listAllEmployees (id, params = {}) {
    return cancelable.execute('GET', addQueryParams(`${ENDPOINT}/${id}/employees/all`, params))
  },

  tree (params) {
    return execute('GET', addQueryParams(`${ENDPOINT}/tree`, params))
  },

  create (payload) {
    return execute('POST', ENDPOINT, payload)
  },

  sort (id = null, payload) {
    return id !== null
      ? execute('PUT', `${ENDPOINT}/${id}/sort`, payload)
      : execute('PUT', `${ENDPOINT}/sort`, payload)
  },

  move (id, toId) {
    return execute('PUT', `${ENDPOINT}/${id}/move?toLocationId=${toId}`)
  },

  moveMany (toId, payload) {
    return execute('PUT', `${ENDPOINT}/move?toLocationId=${toId}`, payload)
  },

  moveEmployees (fromId, toId) {
    return execute('POST', `${ENDPOINT}/${fromId}/move?toLocationId=${toId}`)
  },

  getById (id) {
    return execute('GET', `${ENDPOINT}/${id}`)
  },

  updateById (id, payload) {
    return execute('PUT', `${ENDPOINT}/${id}`, payload)
  },

  deleteById (id) {
    return execute('DELETE', `${ENDPOINT}/${id}`)
  }
}
