<template lang="pug">
  .skill-detail-section(v-if="badge.description")
    h5.skill-detail-title {{ 'ui.labels.description' | translate }}:
    v-show-hide(:max-height="maxHeighDesc")
      div(v-html="descriptionHtml")
</template>

<script>
import marked from 'marked'
import { MAX_HEIGTH_DESCRIPTION_BADGE } from '@/util/constants.js'

import helpers from '@/util/helpers'

const renderer = {
  link (href, title, text) {
    const escapedText = text.toLowerCase().replace(/[^\w]+/g, '-')
    return `<a name="${escapedText}" title=${title}  href="${href}" target="_blank">
              ${text}
            </a>`
  }
}

marked.use({ renderer })
marked.setOptions({ breaks: true })

export default {
  name: 'SkillDetailDescription',

  props: {
    badge: {
      type: Object,
      default: null
    }
  },

  computed: {
    descriptionHtml () {
      const description = helpers.escapeHTML(this.badge.description || '')
      return marked(description)
    },

    maxHeighDesc () {
      return MAX_HEIGTH_DESCRIPTION_BADGE
    }
  }
}
</script>

<style lang="scss">
</style>
