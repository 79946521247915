<template lang="pug">
  v-line(:config="config")
</template>

<script>
const BG_COLOR = '#F8F9FC'

export default {
  name: 'TreeBadge',

  props: {
    begin: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    end: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    color: {
      type: String,
      default: '#13B389'
    },
    isSelected: Boolean,
    faded: Boolean
  },

  computed: {
    config () {
      return {
        points: [this.begin.x, this.begin.y, this.end.x, this.end.y],
        strokeWidth: 4,
        ...this.stroke
      }
    },

    stroke () {
      return this.faded
        ? {
          strokeLinearGradientStartPoint: { x: this.begin.x, y: this.begin.y },
          strokeLinearGradientEndPoint: { x: this.end.x, y: this.end.y },
          strokeLinearGradientColorStops: [0, this.strokeColor, 1, BG_COLOR]
        }
        : {
          stroke: this.strokeColor
        }
    },

    strokeColor () {
      return this.isSelected ? '#ffdbac' : this.color
    }
  }
}
</script>

<style>
</style>
