<template lang="pug">
  .tree-elements
    h3.title-2 {{ 'pages.builder.templates' | translate}}
      v-info-icon(:text="$t('infotext.templates_allow_you_to_manage_multiple_message')")
    .tree-elements__list
      template(v-if="!templateIsLoaded")
        .tree-elements__item(v-for="i in [1,2,3]")
          v-skeleton
      .tree-elements__empty(v-else-if="templateIsLoaded && !templates.length")
        i.icon.icon--sm.icon--empty
      template(v-else)
        v-scrollable(
          :bottom-offset="190")
          .tree-elements__item(
            v-for="item in templates"
            class="drag-drop-2"
            data-rank="badge-template"
            :data-template-id="item.id"
            v-hover="{ over: () => selectTemplate(item), leave: () => selectTemplate(null) }")
            .tree-elements__item-icon
                badge(color="#8598DB" width="27" :level="item.levels.length")
            v-editable(
              v-if="item.isNew"
              :init-state="true"
              @close="(text) => onCloseEditable(text, item)")
              span {{ item.name }}
            .tree-elements__item-label(
                v-else
                v-tooltip="!allowAddBadge ? $t('infotext.select_category_on_tree_or_drag_icon_for_create') : $t('infotext.click_or_drag_icon_for_create')"
                @click="addBadge(item)") {{ item.name | truncateText(18) }}
            .tree-elements__item-actions.tree-elements__item-actions--show-on-item-hover
              v-btn(
                icon
                info
                @click="onOpedEditTemppalte(item.id)")
                i.fa.fa-edit
        #badge-template-drag-n-drop-fix

    span.link-text(
      @click="createTemplate")
      | + {{ 'pages.builder.new_template' | translate}}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Badge from '@/components/svg/Badge'

export default {
  name: 'ElementTemplates',

  props: {
    allowAddBadge: Boolean
  },

  components: {
    Badge
  },

  methods: {
    ...mapActions('builder', [
      'openTemplateEditModal'
    ]),

    ...mapActions('badges/templates', [
      'selectTemplate',
      'createTemplate',
      'updateTemplate'
    ]),

    ...mapActions('treeView', [
      'setHighlightedTemplateId'
    ]),

    addBadge (item) {
      if (this.allowAddBadge) {
        this.$emit('add-badge-by-template', item.id)
      }
    },

    onOpedEditTemppalte (id) {
      this.openTemplateEditModal({ id: id })
    },

    onHighlightTemplate (id) {
      this.setHighlightedTemplateId(id)
    },

    onCloseEditable (text, item) {
      item.isNew = false
      if (item.name !== text) {
        this.updateTemplate({ ...item, name: text })
      }
    }
  },

  computed: {
    ...mapGetters('badges/templates', [
      'templates',
      'templateIsLoaded'
    ])
  }
}
</script>

<style lang="scss" scoped>
.tree-elements__item-icon{
  cursor: not-allowed;
}
.drag-drop-2{
  .tree-elements__item-label, .tree-elements__item-icon{
    cursor: move;
  }
}
.tree-elements__list{
  user-select: none;
}
</style>
