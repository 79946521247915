import { render, staticRenderFns } from "./VExpansionCheckbox.vue?vue&type=template&id=0fd64047&scoped=true&lang=pug&"
import script from "./VExpansionCheckbox.vue?vue&type=script&lang=js&"
export * from "./VExpansionCheckbox.vue?vue&type=script&lang=js&"
import style0 from "./VExpansionCheckbox.vue?vue&type=style&index=0&id=0fd64047&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fd64047",
  null
  
)

export default component.exports