<template lang="pug">
v-dialog(
    :width="900"
    :value="show"
    @input="onClose")
    v-card
      v-card-head
        v-card-head-label
          h3.title {{ 'ui.labels.advanced_filters' | translate }}
        v-card-head-toolbar
          button.close(type="button" @click="onClose" style="min-width: 0px; height:25px")
            span(aria-hidden="true") ×
      v-card-content
        .sf--advanced-filters__form-item
          label.label {{ 'ui.labels.locations' | translate }}
          v-select(
            simple
            search-chevron
            chips
            :placeholder="'ui.dropdown.select_location' | translate"
            :options="options.locations"
            v-model="selected.locations"
            )
        .sf--advanced-filters__form-item
          label.label {{ 'ui.labels.departments' | translate }}
          v-select(
            chips
            search-chevron
            simple
            :placeholder="'ui.dropdown.select_department' | translate"
            :options="options.departments"
            v-model="selected.departments"
            )
        .sf--advanced-filters__form-item
          label.label {{ 'ui.labels.result_type' | translate }}
          v-select(
            single
            search-chevron
            simple
            :placeholder="'ui.dropdown.select_type' | translate"
            :options="matchTypes"
            v-model="type"
          )
      v-card-actions
        v-btn(
          style="width:180px"
          @click="apply") {{ 'ui.buttons.apply_filter'  | translate}}
        v-btn(
          style="width:180px"
          outlinedText
          @click="reset") {{ 'ui.buttons.reset'  | translate}}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'AdvancedFilters',

  components: {

  },

  props: {
    show: Boolean
  },

  mounted () {
    this.type = this.matchTypes[0]
    setTimeout(() => {
      this.isDataChanged = false
    }, 0)
  },

  data: (vm) => ({
    matchTypes: [
      { name: vm.$t('pages.finder.full_and_partial_match'), id: 'PARTIAL_AND_FULL' },
      { name: vm.$t('pages.finder.only_full_match'), id: 'FULL' }],
    isDataChanged: false
  }),

  methods: {
    ...mapActions('skillFinder', [
      'resetAll',
      'searchEmployees',
      'setSearchPage']),

    apply () {
      if (this.isDataChanged) {
        this.setSearchPage(1)
        this.searchEmployees()
      }
      this.onClose()
      this.isDataChanged = false
    },

    onClose () {
      this.$emit('close')
    },

    reset () {
      this.resetAll()
      this.onClose()
    }
  },
  computed: {
    ...mapGetters('skillFinder', [
      'options',
      'selected'
    ]),

    locations: {
      get () {
        return this.$store.state.skillFinder.selected.locations
      },
      set (value) {
        this.$store.commit('skillFinder/updateSelectedLocations', value)
      }
    },

    departments: {
      get () {
        return this.$store.state.skillFinder.selected.departments
      },
      set (value) {
        this.$store.commit('skillFinder/updateSelectedDepartments', value)
      }
    },

    type: {
      get () {
        return this.$store.state.skillFinder.selected.type
      },
      set (value) {
        this.$store.commit('skillFinder/updateSelectedType', value)
      }
    }
  },
  watch: {
    type () {
      this.isDataChanged = true
    },

      locations () {
      this.isDataChanged = true
    },

    departments () {
      this.isDataChanged = true
    }
  }
}
</script>

<style lang="scss" scoped>

  .sf--advanced-filters{
    &__form-item {
      margin-top: 1.5rem
    }
  }
</style>
