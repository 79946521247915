<template lang="pug">
  .v-stepper-step(
    :class="classes"
    @click="onSelect")
    .v-stepper-step__number
      iconCheckbox(v-if="isStepTaken" height='10' width='13')
    .v-stepper-step__label
      slot
</template>

<script>
import iconCheckbox from '@/components/svg/icons/IconCheck.vue'

export default {
  name: 'VStepperStep',

  components: {
    iconCheckbox
  },

  props: {
    step: [String, Number],
    activeStep: [String, Number]
  },

  data: () => ({
    isActive: false
  }),

  computed: {
    classes () {
      return {
        'v-stepper-step--active': this.isStepActive,
        'v-stepper-step--old': this.isStepTaken
      }
    },

    isStepTaken () {
      return this.activeStep > this.step
    },

    isStepActive () {
      return +this.activeStep === +this.step
    }
  },

  methods: {
    onSelect () {
      this.$emit('select', this.step)
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables.scss';

  .v-stepper-step {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__number {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid $color-line-dotted;
      margin-bottom: 8px;
        svg {
          width: 13px;
          height: 10px;
        }
    }

    &__label {
      color: $body-text-2;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
    }
// class for old step
    &--old{
      .v-stepper-step__number {
        background: $secondary-light-green;
        border-color: $secondary-light-green;
      }
      .v-stepper-step__label{
        color:$color-dark-green;
      }
    }
// class for active step
    &--active{
      .v-stepper-step__number {
        background: $secondary-light-green;
        border-color: $secondary-light-green;
      }
      .v-stepper-step__label{
        color:$green-middle;
      }
    }
  }
</style>
