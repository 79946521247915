import api from '@/api'
import {
  GET_ITEM_BY_PATH,
  GET_ITEM_BY_ID,
  GET_LOCATION_NODE,
  SORT_NODES_BY_TYPES,
  RECURSIVE_UPDATE_PARENT_NODES
} from '@/util/v-table-tree.js'

const SET_LOCATIONS = 'SET_LOCATIONS'
const SET_LOCATIONS_TREE = 'SET_LOCATIONS_TREE'

const ADD_LOCATION = 'ADD_LOCATION'
const ADD_ITEM = 'ADD_ITEM'
const REMOVE_ITEM = 'REMOVE_ITEM'
const REMOVE_LOCATION = 'REMOVE_LOCATION'
const UPDATE_LOCATION = 'UPDATE_LOCATION'
const UPDATE_ITEM = 'UPDATE_ITEM'
const SET_ITEM_TYPE = 'SET_ITEM_TYPE'

const SELECT_LOCATION = 'SELECT_LOCATION'
const UNSELECT_LOCATION = 'UNSELECT_LOCATION'
const SELECT_UNASSIGNED = 'SELECT_UNASSIGNED'

const SET_LINKED_EMPLOYEES = 'SET_LINKED_EMPLOYEES'
const MOVE_EMPLOYEES = 'MOVE_EMPLOYEES'
const ADD_EMPLOYEE = 'ADD_EMPLOYEE'
const REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE'

const SET_EXTERNAL_DRAG_MODE = 'SET_EXTERNAL_DRAG_MODE'
const SET_CLIPBOARD = 'SET_CLIPBOARD'

export default {
  namespaced: true,

  state () {
    return {
      locations: [],
      locationsTree: [],
      selected: null,
      selectedLocation: null,
      externalDragMode: false,
      clipboard: []
    }
  },

  getters: {
    locations: (state) => state.locations,
    locationsTree: (state) => state.locationsTree,
    selectedLocation: (state) => state.selectedLocation,
    externalDragMode: (state) => state.externalDragMode
  },

  mutations: {
    [SET_LOCATIONS] (state, data) {
      state.locations = data
    },

    [SET_LOCATIONS_TREE] (state, data) {
      state.locationsTree = data
      if (state.locationsTree.length) {
        state.locationsTree[0].children = SORT_NODES_BY_TYPES(data[0].children)
      }
    },

    [SELECT_LOCATION] (state, node) {
      const itemData = GET_ITEM_BY_PATH(state.locationsTree, node.path)
      state.selected = itemData
      itemData.node.data.pagination = {
        page: 0,
        pages: 1,
        total: 1
      }
      state.selectedLocation = itemData.node
      if (!itemData.node.isExpanded) {
        itemData.node.isExpanded = true
      }
      itemData.node.isNotAssignedSelected = false
    },

    [SELECT_UNASSIGNED] (state, node) {
      if (state.selected) {
        state.selected.node.isSelected = false
        state.selected.node.isNotAssignedSelected = false
      }
      const itemData = GET_ITEM_BY_PATH(state.locationsTree, node.path)
      itemData.node.data.pagination = {
        page: 0,
        pages: 1,
        total: 1
      }
      itemData.node.isSelected = true
      itemData.node.isNotAssignedSelected = true
      state.selected = itemData
      state.selectedLocation = itemData.node
    },

    [UNSELECT_LOCATION] (state) {
      state.selectedLocation = null
      state.selected = null
    },

    [ADD_LOCATION] (state, data) {
      state.locations.push(data)
    },

    [ADD_ITEM] (state, { toNode, newNode }) {
      if (toNode) {
        const itemData = GET_ITEM_BY_PATH(state.locationsTree, toNode.path)
        if (itemData.node) {
          itemData.node.isLeaf = false
          itemData.node.isExpanded = true
          itemData.node.children.push(newNode)
          itemData.node.children = SORT_NODES_BY_TYPES(itemData.node.children)
          if (!itemData.parentNode) {
            state.locationsTree = SORT_NODES_BY_TYPES(state.locationsTree)
          } else {
            itemData.parentNode.children = SORT_NODES_BY_TYPES(itemData.parentNode.children)
          }
        }
      } else {
        if (state.locationsTree.length) {
          const items = state.locationsTree[0].children
          items.push(newNode)
          state.locationsTree[0].children = SORT_NODES_BY_TYPES(items)
        }
      }
    },

    [UPDATE_ITEM] (state, { node, fields }) {
      const itemData = GET_ITEM_BY_PATH(state.locationsTree, [...node.path])
      if (itemData.node) {
        for (const key in fields) {
          itemData.node.data[key] = fields[key]
        }
        itemData.node.isEditing = false
        if (itemData.parentNode) {
          itemData.parentNode.children = SORT_NODES_BY_TYPES(itemData.parentNode.children)
        }
      }
    },

    [SET_ITEM_TYPE] (state, { node, isLeaf }) {
      const item = GET_ITEM_BY_ID(state.locationsTree, node.data.id)
      item.isLeaf = isLeaf
    },

    [REMOVE_ITEM] (state, node) {
      const itemData = GET_ITEM_BY_PATH(state.locationsTree, node.path)
      if (itemData.node) {
        itemData.parent.splice(itemData.index, 1)
        if (itemData.parentNode && itemData.parentNode.children.length === 0) {
          itemData.parentNode.isLeaf = true
        }
      }
    },

    [UPDATE_LOCATION] (state, data) {
      const index = state.locations
        .map(item => item.id)
        .indexOf(data.id)
      if (index !== -1) {
        state.locations[index] = data
      }
    },

    [REMOVE_LOCATION] (state, data) {
      const index = state.locations
        .map(item => item.id)
        .indexOf(data.id)
      if (index !== -1) {
        state.locations.splice(index, 1)
      }
    },

    [SET_LINKED_EMPLOYEES] (state, { data, pagination }) {
      if (state.selectedLocation) {
        state.selectedLocation.data.employees = pagination.page > 1
          ? [...state.selectedLocation.data.employees, ...data]
          : data
        state.selectedLocation.data.pagination = pagination
      }
    },

    [MOVE_EMPLOYEES] (state, node) {
      const itemData = GET_ITEM_BY_PATH(state.locationsTree, node.path)
      if (state.selectedLocation.data.id !== itemData.node.data.id) {
        if (itemData.node) {
          for (const index in state.clipboard) {
            const item = state.clipboard[index]
            itemData.node.data.employees.push(item)
            const index2 = state.selectedLocation.data.employees.indexOf(item)
            if (index2 !== -1) {
              state.selectedLocation.data.employees.splice(index2, 1)
              state.selectedLocation.data.pagination.total -= 1
            }
          }
        }
      }
    },

    [ADD_EMPLOYEE] (state, data) {
      if (state.selectedLocation) {
        state.selectedLocation.data.employees.push(data)
        state.selectedLocation.data.pagination.total += 1
      }
    },

    [REMOVE_EMPLOYEE] (state, data) {
      if (state.selectedLocation) {
        const index = state.selectedLocation.data.employees
          .map(item => item.id)
          .indexOf(data.id)
        if (index !== -1) {
          state.selectedLocation.data.employees.splice(index, 1)
          state.selectedLocation.data.pagination.total -= 1
        }
      }
    },

    [SET_EXTERNAL_DRAG_MODE] (state, data) {
      state.externalDragMode = data
    },

    [SET_CLIPBOARD] (state, data) {
      state.clipboard = data
    }
  },

  actions: {
    async loadLocations (context, params = {}) {
      const { data, headers } = await api.locations.list({ all: true, sort: 'name', ...params })
      context.commit(SET_LOCATIONS, data)
      return { headers, data }
    },

    async loadLocationsTree (context, params = {}) {
      const { data } = await api.locations.tree({ all: true, ...params })
      context.commit(SET_LOCATIONS_TREE, [GET_LOCATION_NODE(data)])
      return { data }
    },

    async loadLinkedEmployees (context, node) {
      const page = context.state.selectedLocation
        ? context.state.selectedLocation.data.pagination.page + 1
        : 1
      const pages = context.state.selectedLocation
        ? context.state.selectedLocation.data.pagination.pages
        : 1
      if (page <= pages) {
        const params = {
          page: page
        }
        const nodeId = node
          ? node.data.id
          : context.state.selectedLocation.data.id
        const { data, headers } = nodeId !== null
          ? context.state.selectedLocation.isNotAssignedSelected
            ? await api.locations.listEmployees(nodeId, params)
            : await api.locations.listAllEmployees(nodeId, params)
          : await api.locations.listEmployees(null, params)
        const pagination = {
          page,
          pages: (headers && parseInt(headers['x-pagination-page-count'])) || 1,
          total: (headers && parseInt(headers['x-pagination-total-count'])) || 0
        }
        context.commit(SET_LINKED_EMPLOYEES, { data: data || [], pagination })
      }
    },

    createLocationInTree (context, { toNode }) {
      const name = toNode
        ? `${toNode.data.name} sublocation ${toNode.children.length + 1}`
        : `Location ${context.state.locationsTree[0].children.length + 1}`
      const newNode = {
        data: {
          id: -1,
          name: name,
          employeeCount: 0,
          employees: [],
          parentId: toNode
            ? toNode.data.id
            : null,
          isNew: true,
          errorText: null
        },
        isEditing: true,
        isLeaf: true,
        children: []
      }
      context.commit(ADD_ITEM, { toNode, newNode })
    },

    selectLocation (context, node) {
      context.commit(SELECT_LOCATION, node)
    },

    selectUnassigned (context, node) {
      context.commit(SELECT_UNASSIGNED, node)
    },

    async updateItem (context, { node, fields }) {
      if (node.data.isDeleted) return
      context.commit(UPDATE_ITEM, { node, fields })
      const payload = {
        ...fields,
        parentId: node.data.parentId
      }
      const { data, errorData } = node.data.isNew
        ? await api.locations.create(payload)
        : await api.locations.updateById(node.data.id, fields)
      if (data) {
        if (fields.isNew) {
          context.commit(ADD_LOCATION, data)
        }
        fields.id = data.id
        fields.isNew = false
        fields.errorText = null
      } else if (errorData) {
        const error = errorData.data.find(item => item.field === 'name')
        fields.errorText = error && error.message
      }
      context.commit(UPDATE_ITEM, { node, fields })
    },

    async onMoveItems (context, { nodes, params }) {
      // SORT ITEMS
      if (params.placement !== 'inside') {
        const { parentNode } = GET_ITEM_BY_PATH(context.state.locationsTree, [...params.node.path])

        if (parentNode) {
          parentNode.children = SORT_NODES_BY_TYPES(parentNode.children)
        }

        const nodeId = parentNode
          ? parentNode.data.id
          : null

        const ids = parentNode
          ? parentNode.children.map(item => item.data.id)
          : context.state.locationsTree.map(item => item.data.id)

        const payload = {
          locationIds: ids
        }
        api.locations.sort(nodeId, payload)
      }

      // MOVE ITEMS
      const parentId = params.placement === 'inside'
        ? params.node.data.id
        : (() => {
          const { parentNode } = GET_ITEM_BY_PATH(context.state.locationsTree, [...params.node.path])
          return parentNode
            ? parentNode.data.id
            : null
        })()

      const toLocationId = parentId !== null
        ? parentId
        : ''

      if (params.node.isLeaf && params.placement === 'inside') {
        context.commit(SET_ITEM_TYPE, { node: params.node, isLeaf: false })
      }

      const payload = {
        locationIds: nodes.map(node => node.data.id)
      }
      api.locations.moveMany(toLocationId, payload)

      const prevNodePath = nodes[0].path.slice(0, nodes[0].path.length - 1)
      const prevNodeData = GET_ITEM_BY_PATH(context.state.locationsTree, prevNodePath)
      if (prevNodeData.node && prevNodeData.node.children.length === 0) {
        prevNodeData.node.isLeaf = true
      }
    },

    removeItem (context, { node }) {
      const employeeCount = node.isLeaf
        ? node.data.employeeCount
        : node.data.allChildEmployeeCount
      if (employeeCount) {
        const parent = GET_ITEM_BY_ID(context.state.locationsTree, node.data.parentId)
        parent.data.employeeCount += employeeCount
      }
      context.commit(REMOVE_ITEM, node)
      if (!node.data.isNew) {
        context.dispatch('deleteLocation', node.data)
      }
    },

    addEmployee (context, data) {
      const items = context.state.locationsTree

      // decrease employee count in previous location
      if (data.location) {
        const location = GET_ITEM_BY_ID(items, data.location.id)
        location.data.employeeCount -= 1
        if (!location.data.isLeaf) {
          location.data.allChildEmployeeCount -= 1
        }
        RECURSIVE_UPDATE_PARENT_NODES(items, location.data.parentId, (node) => {
          node.data.allChildEmployeeCount -= 1
        })
      }

      // increase employee count in new location
      const node = context.state.selectedLocation
      node.data.employeeCount += 1
      if (!node.isLeaf) {
        node.data.allChildEmployeeCount += 1
      }
      RECURSIVE_UPDATE_PARENT_NODES(items, node.data.parentId, (node) => {
        node.data.allChildEmployeeCount += 1
      })

      // link employee to new location
      const payload = {
        employeeIds: [data.id]
      }
      const id = node.data.id || ''
      api.employees.linkLocation(id, payload)
      context.commit(ADD_EMPLOYEE, data)

      // update employee data
      const employee = {
        id: data.id,
        location: {
          id: node.data.id,
          name: node.data.name
        }
      }
      context.dispatch('employees/updateLocalEmployee', employee, { root: true })
    },

    removeEmployee (context, data) {
      const node = context.state.selectedLocation
      const payload = {
        employeeIds: [data.id]
      }
      api.employees.unlinkLocation(node.data.id, payload)

      node.data.employeeCount -= 1
      if (context.state.selected.parentNode) {
        context.state.selected.parentNode.data.employeeCount += 1
      }
      context.commit(REMOVE_EMPLOYEE, data)

      // update employee data
      const employee = {
        id: data.id
      }
      if (context.state.selected && context.state.selected.parentNode) {
        employee.location = {
          id: context.state.selected.parentNode.data.id,
          name: context.state.selected.parentNode.data.name
        }
      }
      context.dispatch('employees/updateLocalEmployee', employee, { root: true })
    },

    setClipboardItems (context, data) {
      context.commit(SET_CLIPBOARD, data)
    },

    async moveEmployeesTo (context, node) {
      const count = context.state.clipboard.length
      const items = context.state.locationsTree

      context.commit(MOVE_EMPLOYEES, node)

      // update prev node employees count
      const prevNode = context.state.selectedLocation
      prevNode.data.employeeCount -= count
      if (!prevNode.isLeaf) {
        prevNode.data.allChildEmployeeCount -= count
      }
      RECURSIVE_UPDATE_PARENT_NODES(items, prevNode.data.parentId, (node) => {
        node.data.allChildEmployeeCount -= count
      })

      // update new node employees count
      node.data.employeeCount += count
      if (!node.isLeaf) {
        node.data.allChildEmployeeCount += count
      }
      RECURSIVE_UPDATE_PARENT_NODES(items, node.data.parentId, (node) => {
        node.data.allChildEmployeeCount += count
      })

      const id = node.data.id
      const payload = {
        employeeIds: context.state.clipboard
          .map(item => item.id)
      }
      if (payload.employeeIds.length) {
        await api.employees.linkLocation(id, payload)
      }
      for (const item of context.state.clipboard) {
        item.location = {
          id: node.data.id,
          name: node.data.name
        }
      }
      context.commit(SET_CLIPBOARD, [])
    },

    activateExternalDragMode (context) {
      context.commit(SET_EXTERNAL_DRAG_MODE, true)
    },

    deactivateExternalDragMode (context) {
      context.commit(SET_EXTERNAL_DRAG_MODE, false)
    },

    // OLD METHODS
    async createLocation (context, payload) { // deprecated
      const { error, data } = await api.locations.create(payload)
      if (data) {
        context.commit(ADD_LOCATION, data)
      }
      return { error, data }
    },

    updateLocation (context, item) {
      context.commit(UPDATE_LOCATION, item)
      api.locations.updateById(item.id, item)
    },

    async deleteLocation (context, item) {
      const { error } = await api.locations.deleteById(item.id)
      if (!error) {
        context.commit(REMOVE_LOCATION, item)
      } else {
        // TODO: Add error code check here
        await context.dispatch('loadLocations')
        const modal = {
          title: error.data.name,
          message: error.data.message,
          unlinkTitle: 'pages.administration.move_employees_to_different_location',
          unlinkSubtitle: 'pages.administration.select_new_location_for_employees',
          unlinkOptions: context.state
            .locations
            .filter(el => el.id !== item.id),
          deleteCallback: async (toId) => {
            await api.locations.moveEmployees(item.id, toId)
            await context.dispatch('deleteLocation', item)
          }
        }
        context.dispatch('modals/openForbiddenModal', modal, { root: true })
      }
    }
  }
}
