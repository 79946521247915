import api from '@/api'

const SET_ROLES = 'SET_ROLES'
const ADD_ROLE = 'ADD_ROLE'
const UPDATE_ROLE = 'UPDATE_ROLE'
const REMOVE_ROLE = 'REMOVE_ROLE'

const SET_CURRENT_ROLE = 'SET_CURRENT_ROLE'
const UNSET_CURRENT_ROLE = 'UNSET_CURRENT_ROLE'
const SET_CURRENT_ROLE_LOADING = 'SET_CURRENT_ROLE_LOADING'

export default {
  namespaced: true,

  state () {
    return {
      roles: [],
      currentRole: null,
      currentRoleLoading: false
    }
  },

  getters: {
    roles: (state) => state.roles,
    currentRole: (state) => state.currentRole,
    currentRoleLoading: (state) => state.currentRoleLoading
  },

  mutations: {
    [SET_ROLES] (state, data) {
      state.roles = data
    },

    [ADD_ROLE] (state, role) {
      state.roles.push(role)
    },

    [UPDATE_ROLE] (state, role) {
      for (const item of state.roles) {
        if (item.id === role.id) {
          for (const key in item) {
            item[key] = role[key]
            state.currentRole[key] = role[key]
          }
          break
        }
      }
    },

    [REMOVE_ROLE] (state, role) {
      const index = state.roles
        .map(item => item.id)
        .indexOf(role.id)
      if (index !== -1) {
        state.roles.splice(index, 1)
      }
    },

    [SET_CURRENT_ROLE] (state, role) {
      state.currentRole = role
    },

    [UNSET_CURRENT_ROLE] (state) {
      state.currentRole = null
    },

    [SET_CURRENT_ROLE_LOADING] (state, data) {
      state.currentRoleLoading = data
    }
  },

  actions: {
    async loadRoles (context) {
      const { data } = await api.roles.list()
      // TODO: add error check here
      context.commit(SET_ROLES, data)
    },

    async createRole (context, payload) {
      const { data } = await api.roles.create(payload)
      // TODO: add error check here
      context.commit(ADD_ROLE, data)
    },

    getRole (context, { id, params }) {
      return api.roles.getById(id, params)
    },

    async getRoleOwners (context, { id, params }) {
      const { data, headers } = await api.roles.listOwners(id, params)
      // TODO: add error check here
      return { data: data || [], headers: headers || {} }
    },

    async readRole (context, id) {
      context.commit(SET_CURRENT_ROLE_LOADING, true)
      const { data } = await api.roles.getById(id)
      // TODO: add error check here
      context.commit(SET_CURRENT_ROLE, data)
      context.commit(SET_CURRENT_ROLE_LOADING, false)
    },

    unsetCurrentRole (context) {
      context.commit(UNSET_CURRENT_ROLE)
    },

    async updateRole (context, payload) {
      const { data } = await api.roles.updateById(payload.id, payload)
      context.commit(UPDATE_ROLE, data)
    },

    async deleteRole (context, payload) {
      await api.roles.deleteById(payload.id)
      context.commit(REMOVE_ROLE, payload)
      // TODO: await context.dispatch('loadPermissions')
    }
  }
}
