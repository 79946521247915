<template lang="pug">
  div.action-selector
    v-select.action-selector--select(
      name='action'
      label="name"
      single
      hideBottomMargin
      :disabled="disabled || loading"
      :placeholder="'ui.inputs.choose_action' | translate"
      :options="filteredSelectedOption"
      :value="selectedOption"
      @input="onChangeOptions"
    )

    v-select.action-selector--select(
      v-if="selectedActionOptions.length"
      label='name'
      single
      hideBottomMargin
      :disabled="disabled || loading"
      :options="selectedActionOptions"
      :value="selectedActionOption"
      @input="onChangeActionOptions")

    v-btn(
      v-show="showApply"
      :loading="loading"
      :disabled="disabledApply || disabled"
      @click="applyChanges")
      | {{ 'ui.buttons.apply' | translate }}

</template>

<script>

export default {
  name: 'VActionSelector',

  props: {
    disabled: Boolean,

    options: {
      type: Array,
      default: () => []
    },

    selectedActionOptions: {
      type: Array,
      default: () => []
    },

    selectedOption: {
      type: Object,
      default: () => {}
    },

    selectedActionOption: {
      type: Object,
      default: () => {}
    },

    loading: Boolean

  },

  methods: {
    applyChanges () {
      this.$emit('on-apply')
    },

    onChangeOptions (value) {
      this.$emit('on-change-option', value)
    },

    onChangeActionOptions (value) {
      this.$emit('on-change-action-option', value)
    }
  },

  computed: {
    showApply () {
      return !!this.selectedOption.id
    },

    filteredSelectedOption () {
      return this.options.filter(el => el.show !== false)
    },

    disabledApply () {
      return this.selectedOption.labelKey && !this.selectedActionOption.id
    }
  }
}
</script>

<style lang="scss" scoped>
.action-selector {
  display: flex;
  align-items: center;

  &--select {
    width:250px;
    padding-right: 2rem;
  }
}
</style>
