<template lang="pug">
  .v-editable(
    @click="onComponentClick")
    .v-editable__controls
      input.v-editable__input(
        v-if="toggled"
        v-focus
        v-model="text"
        v-autowidth="autowidthOptions"
        ref="input"
        :style="inputStyles"
        :class="{ 'v-editable__input--ghost': ghost }"
        @blur="onBlur"
        @click.stop
        @keyup.enter="onEnter")
      .v-editable__inner(
        v-else
        v-tooltip="iconEditTooltip"
        :title="title")
        slot
      span.v-editable__icon(
        v-if="!disabled && !hideIcon"
        @mousedown.stop
        @mouseup.stop)
        i.fa.fa-check(
          v-if="toggled")
        i.fa.fa-pencil-alt(
          v-else
          :class="pencilClass"
          @click="onIconClick")
    .v-editable__error-text(
      v-if="errorText") {{ errorText }}

</template>

<script>
export default {
  name: 'VEditable',

  props: {
    block: Boolean,
    errorText: String,
    initState: Boolean,
    disabled: Boolean,
    hideIcon: Boolean,
    iconEditTooltip: {
      type: String,
      default: null
    },
    ghost: Boolean,
    toggleByIconOnly: Boolean,
    pencilClass: String
  },

  mounted () {
    this.initInputStyles()
    if (this.initState) {
      this.toggleEdit()
    }
  },

  data: () => ({
    text: '',
    toggled: false,
    toggledByBlur: false,
    inputStyles: {
      height: '16px',
      fontSize: '16px',
      fontWeight: '500'
    },
    autowidthOptions: {
      maxWidth: '300px',
      minWidth: '20px',
      comfortZone: 0
    }
  }),
  beforeDestroy () {
    this.onBlur()
  },

  methods: {
    onComponentClick () {
      if (!this.toggleByIconOnly) {
        this.toggleEdit()
      }
    },

    onIconClick () {
      if (this.toggleByIconOnly) {
        this.toggleEdit()
      }
    },

    toggleEdit () {
      if (!this.disabled) {
        if (!this.toggledByBlur) {
          this.toggled = !this.toggled
          if (this.$slots.default.length) {
            const el = this.$slots.default[0].children[0]
            if (this.toggled) {
              this.text = el.text
            } else {
              if (this.text !== el.text) {
                this.onChange()
              }
              this.onClose()
            }
          }
        } else {
          this.toggledByBlur = false
        }
      }
    },

    openEdit () {
      this.toggled = true
      if (this.$slots.default.length) {
        const el = this.$slots.default[0].children[0]
        this.text = el.text
      }
    },

    async onBlur () {
      this.toggleEdit()
      this.toggledByBlur = true
      setTimeout(() => { this.toggledByBlur = false }, 500)
    },

    initInputStyles () {
      if (this.$slots.default.length) {
        const el = this.$slots.default[0].elm
        const computedStyle = window.getComputedStyle(el, null)
        this.inputStyles.fontSize = computedStyle.getPropertyValue('font-size')
        this.inputStyles.fontWeight = computedStyle.getPropertyValue('font-weight')
        this.inputStyles.height = computedStyle.getPropertyValue('height')
      }
    },

    onEnter () {
      this.$refs.input.blur()
      this.toggledByBlur = false
    },

    onChange () {
      this.$emit('change', this.text)
    },

    onClose () {
      this.$emit('close', this.text)
    }
  },

  computed: {
    classes () {
      return {
        'v-editable--block': this.block
      }
    },

    title () {
      if (this.$slots.default.length) {
        const el = this.$slots.default[0].children[0]
        return el.text
      }
      return ''
    }
  },

  watch: {
    initState (value) {
      if (value) {
        this.openEdit()
      }
    }
  }
}
</script>

<style lang="scss">
  .v-editable {
    display: flex;
    flex-direction: column;

    &--block {
      width: 100%;
    }

    &__controls {
      display: inline-flex;
      align-items: center;
    }

    &__inner {
      display: inline-flex;
      align-items: center;
    }

    &__inner > * {
      margin: 0 !important;
      display: inline-block;
      max-width: 300px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__input {
      line-height: 1;
      border: none;
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.18);

      &--ghost {
        background-color: transparent;
        box-shadow: none;
      }
    }

    &__icon {
      cursor: pointer;
      margin-left: 10px;

      .fa {
        font-family: "Font Awesome 5 Free" !important;
        font-weight: 900 !important;
      }
    }

    &__error-text {
      color: red;
      font-size: 12px;
    }
  }
</style>
