<template>
  <svg
    :width="width"
    :height="!level || level>5 ?  height/1.48 : height"
    :viewBox="`0 0 ${height} ${!level || level>5 ? height*1.4 : height*2}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <template v-if="!level">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.0773 54C23.7559 51.3063 26.5496 49.2239 31.9731 45.923C34.5502 44.3461 36.6238 42.03 37.9394 39.2588C39.2533 36.6158 39.9584 33.6975 40 30.7295V3.73964L20.0218 0L0 3.73964V30.7322C0.0416333 33.7002 0.746667 36.6186 2.06056 39.2615C3.37628 42.0323 5.44929 44.3483 8.02562 45.9257C15.6022 50.528 16.2296 51.3511 20.0773 54Z"
        :fill="color"
      />
    </template>
    <template v-else-if="level <=5">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.0773 80C25.7559 77.3063 28.5496 75.2239 33.9731 71.923C36.5502 70.3461 38.6238 68.03 39.9394 65.2588C41.2533 62.6158 41.9584 59.6975 42 56.7295V29.7396L22.0218 26L2 29.7396V56.7322C2.04163 59.7002 2.74667 62.6186 4.06056 65.2615C5.37628 68.0323 7.44929 70.3483 10.0256 71.9257C17.6022 76.528 18.2296 77.3511 22.0773 80Z"
        :fill="color"
      />
      <path
        v-for="star in choiceStars"
        :key="star"
        fill-rule="evenodd"
        clip-rule="evenodd"
        :d="star"
        :fill="color"
      />
    </template>
    <template v-else>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.0773 54C23.7559 51.3063 26.5496 49.2239 31.9731 45.923C34.5502 44.3461 36.6238 42.03 37.9394 39.2588C39.2533 36.6158 39.9584 33.6975 40 30.7295V3.73964L20.0218 0L0 3.73964V30.7322C0.0416333 33.7002 0.746667 36.6186 2.06056 39.2615C3.37628 42.0323 5.44929 44.3483 8.02562 45.9257C15.6022 50.528 16.2296 51.3511 20.0773 54Z"
        :fill="color"
      />
      <text class="badge-svg-text" :x="positionText.x" :y="positionText.y">{{level}}</text>
    </template>
  </svg>
</template>
<script>
const FIVE = [
  'M14.4993 20.2913L17.5894 22L16.9997 18.3814L19.5 15.8198L16.0457 15.292L14.4993 12L12.9543 15.292L9.5 15.8198L12.0003 18.3814L11.4106 22L14.4993 20.2913Z',
  'M21.9993 8.29131L25.0894 10L24.4997 6.38143L27 3.81976L23.5457 3.29199L21.9993 0L20.4543 3.29199L17 3.81976L19.5003 6.38143L18.9106 10L21.9993 8.29131Z',
  'M6.99935 8.29131L10.0894 10L9.49967 6.38143L12 3.81976L8.54567 3.29199L6.99935 0L5.45433 3.29199L2 3.81976L4.50032 6.38143L3.91063 10L6.99935 8.29131Z',
  'M29.4993 20.2913L32.5894 22L31.9997 18.3814L34.5 15.8198L31.0457 15.292L29.4993 12L27.9543 15.292L24.5 15.8198L27.0003 18.3814L26.4106 22L29.4993 20.2913Z',
  'M36.9993 8.29131L40.0894 10L39.4997 6.38143L42 3.81976L38.5457 3.29199L36.9993 0L35.4543 3.29199L32 3.81976L34.5003 6.38143L33.9106 10L36.9993 8.29131Z'
]
const FOUR = [
  'M28.9993 20.2913L32.0894 22L31.4997 18.3814L34 15.8198L30.5457 15.292L28.9993 12L27.4543 15.292L24 15.8198L26.5003 18.3814L25.9106 22L28.9993 20.2913Z',
  'M12.9993 8.29131L16.0894 10L15.4997 6.38143L18 3.81976L14.5457 3.29199L12.9993 0L11.4543 3.29199L8 3.81976L10.5003 6.38143L9.91063 10L12.9993 8.29131Z',
  'M28.9993 8.29131L32.0894 10L31.4997 6.38143L34 3.81976L30.5457 3.29199L28.9993 0L27.4543 3.29199L24 3.81976L26.5003 6.38143L25.9106 10L28.9993 8.29131Z',
  'M12.9993 20.2913L16.0894 22L15.4997 18.3814L18 15.8198L14.5457 15.292L12.9993 12L11.4543 15.292L8 15.8198L10.5003 18.3814L9.91063 22L12.9993 20.2913Z'
]
const THREE = [
  'M14.4993 20.2913L17.5894 22L16.9997 18.3814L19.5 15.8198L16.0457 15.292L14.4993 12L12.9543 15.292L9.5 15.8198L12.0003 18.3814L11.4106 22L14.4993 20.2913Z',
  'M21.9993 8.29131L25.0894 10L24.4997 6.38143L27 3.81976L23.5457 3.29199L21.9993 0L20.4543 3.29199L17 3.81976L19.5003 6.38143L18.9106 10L21.9993 8.29131Z',
  'M29.4993 20.2913L32.5894 22L31.9997 18.3814L34.5 15.8198L31.0457 15.292L29.4993 12L27.9543 15.292L24.5 15.8198L27.0003 18.3814L26.4106 22L29.4993 20.2913Z'
]
const TWO = [
  'M14.4993 20.2913L17.5894 22L16.9997 18.3814L19.5 15.8198L16.0457 15.292L14.4993 12L12.9543 15.292L9.5 15.8198L12.0003 18.3814L11.4106 22L14.4993 20.2913Z',
  'M29.4993 20.2913L32.5894 22L31.9997 18.3814L34.5 15.8198L31.0457 15.292L29.4993 12L27.9543 15.292L24.5 15.8198L27.0003 18.3814L26.4106 22L29.4993 20.2913Z'
]
const ONE = [
  'M21.9993 20.2913L25.0894 22L24.4997 18.3814L27 15.8198L23.5457 15.292L21.9993 12L20.4543 15.292L17 15.8198L19.5003 18.3814L18.9106 22L21.9993 20.2913Z'
]
export default {
  name: 'IconBadge',
  props: {
    level: {
      type: Number,
      default: 1
    },
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 80
    },
    color: {
      type: String,
      default: '#29A687'
    }
  },
  computed: {
    choiceStars () {
      let star = null
      switch (this.level) {
        case 1:
          star = ONE
          break
        case 2:
          star = TWO
          break
        case 3:
          star = THREE
          break
        case 4:
          star = FOUR
          break
        case 5:
          star = FIVE
          break
      }
      return star
    },
    positionText () {
      return {
        x: this.level >= 10 ? '5' : '12',
        y: this.level >= 10 ? '33' : '33'
      }
    }
  }
}
</script>

<style lang="scss">
.badge-svg-text{
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  fill: $color-white;
}
</style>
