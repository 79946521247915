<template lang="pug">
  div
    .portlet__head.justify-content-center
      .portlet__head-label
        .portlet__head-title
          h3.text-center {{ 'pages.auth.reset_password' | translate }}
          h5.text-center {{ 'pages.auth.we_will_send_you_a_reset_code_per_email' | translate }}
    .portlet__body
      form.kt-form(@submit.prevent="resetPassword")
        .form-group(:class="{ 'is-invalid': isEmailInvalid }")
          input(class="form-control"
            :placeholder="'pages.auth.e_mail' | translate"
            v-model="email")
          span.form-text.invalid {{ 'email' | errorText }}
        .form-controls
          router-link(to="/login") {{ 'pages.auth.sign_in'  | translate}}
          .text-right
            v-btn(
              :loading="loading"
              ) {{ 'pages.auth.send_code' | translate }}
</template>
<script>
import api from '@/api'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ForgotPassword',

  mounted () {
    this.email = this.loginEmail
  },

  data: () => ({
    email: '',
    loading: false
  }),

  methods: {
    ...mapActions('app', ['setPasswordResetCode', 'setLoginEmail']),

    async resetPassword () {
      this.loading = true
      let payload = {
        email: this.email
      }
      let { error, data } = await api.passwordReset(payload)

      if (data && data.resetPasswordCode) {
        this.setPasswordResetCode(data.resetPasswordCode)
      }
      if (!error) {
        this.$emit('next')
      }
      this.loading = false
    }
  },

  computed: {
    ...mapGetters('app', ['loginEmail', 'errors']),

    isEmailInvalid () {
      return this.errors.find(item => item.field === 'email')
    }
  },

  watch: {
    email (value) {
      this.setLoginEmail(value)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
