<template lang="pug">
  v-group
    v-circle(:config="configOutline")
    v-circle(
      :config="config"
      @click="onClick"
      @mouseover="onMouseOver"
      @mouseleave="onMouseLeave")
    v-circle(:config="configDot")
    v-rect(:config="imgConfig")
    tree-category-children-count(
      v-if="depth > 1"
      :color="color"
      :count="childrenCount"
      :pos="childrenCountLabelPos")
    slot(:radius="radius")
    svg(
      :x="hookedBadgeOffset - 2"
      :y="hookedBadgeOffset - 8"
      :transform="`scale(${hookedBadgeSize})`"
      height="45"
      width="33"
      viewBox="0 0 33 33")
      slot
</template>

<script>
import { mapGetters } from 'vuex'
import TreeCategoryChildrenCount from './TreeCategoryChildrenCount'

const BG_COLOR = '#F8F9FC'

export default {
  name: 'TreeCategory',

  components: {
    TreeCategoryChildrenCount
  },

  props: {
    pos: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    color: {
      type: String,
      default: '#13B389'
    },
    childrenCount: Number,
    isActivated: Boolean,
    isSelected: Boolean,
    depth: Number
  },

  data: () => ({
    isHovered: false,
    propsCompanyLogo: {
      image: null,
      width: 1,
      height: 1
    }
  }),

  computed: {
    ...mapGetters('trees', [
      'rootCategoryPictureUrl'
    ]),

    hookedBadgeSize () {
      return 1 - (this.depth / 20)
    },

    size () {
      if (this.depth >= 3) {
        return 100 / 3
      } else {
        return 100 / this.depth
      }
    },

    hookedBadgeOffset () {
      // return this.size // + 12 - this.strokeWidth
      return this.size + 23 - this.strokeWidth - 10 * this.hookedBadgeSize
    },

    imgConfig () {
      return {
        x: -45,
        y: -45,
        fillPatternImage: this.propsCompanyLogo.image,
        width: 90,
        height: 90,
        cornerRadius: 50,
        fillPatternScaleX: (90 / this.propsCompanyLogo.width) || 1,
        fillPatternScaleY: (90 / this.propsCompanyLogo.height) || 1
      }
    },

    config () {
      return {
        x: this.pos.x,
        y: this.pos.y,
        radius: this.radius,
        fill: BG_COLOR,
        stroke: this.isActivated ? this.color : '#eee',
        strokeWidth: this.strokeWidth,
        opacity: this.isHovered ? 0.7 : 1
      }
    },

    configDot () {
      return {
        x: this.pos.x,
        y: this.pos.y,
        radius: 1,
        fill: this.color,
        opacity: this.isHovered ? 0.7 : 1
      }
    },

    configOutline () {
      return {
        x: this.pos.x,
        y: this.pos.y,
        radius: this.radius + this.strokeWidth / 2 + 2,
        fill: BG_COLOR,
        stroke: '#ffdbac',
        strokeWidth: this.isSelected ? 6 : 0
      }
    },

    childrenCountLabelPos () {
      return {
        x: this.pos.x + this.radius * 0.35,
        y: this.pos.y - this.radius * 1.2
      }
    },

    radius () {
      if (this.depth >= 3) {
        return 40 / 3 + 5
      } else {
        return 40 / this.depth + 5
      }
    },

    strokeWidth () {
      return Math.max(7 / this.depth, 3)
    }
  },

  methods: {
    onClick () {
      this.$emit('click')
    },

    onMouseOver () {
      document.body.style.cursor = 'pointer'
      this.isHovered = true
    },

    onMouseLeave () {
      document.body.style.cursor = 'default'
      this.isHovered = false
    }
  },

  created () {
    const image = new window.Image()
    image.src = this.rootCategoryPictureUrl
    image.onload = () => {
      this.propsCompanyLogo.image = image
      this.propsCompanyLogo.width = image.width
      this.propsCompanyLogo.height = image.height
    }
  }
}
</script>

<style>
</style>
