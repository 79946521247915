<script>
export default {
  props: {
    block: Boolean,
    left: Boolean,
    center: Boolean,
    fixed: Boolean,
    stack: Boolean
  },

  render (h) {
    return h('div', {
      class: {
        'v-card__actions': true,
        'v-card__actions--left': this.left,
        'v-card__actions--center': this.center,
        'v-card__actions--fixed': this.fixed,
        'v-card__actions--stack': this.stack,
        'v-card__actions--block': this.block
      }
    }, this.$slots.default)
  }
}
</script>
