<template lang="pug">
  .skill-detail-modal(
    :style="styles"
    ref="gradationTabsWrapper")
    slot
</template>

<script>
const MODAL_WIDTH = 370

export default {
  name: 'SkillDetailModal',

  props: {
    pos: Object,
    zoom: Number,
    node: Object,
    isGradational: Boolean,
    showSteps: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    modalHeight: 0,
    prevModalHeight: 0,
    prevNode: null
  }),

  updated () {
    const getHeight = () => {
      let isNewNode = false
      if (this.prevNode && this.node) {
        isNewNode = this.prevNode.props.id !== this.node.props.id
      }
      if ((this.$refs.gradationTabsWrapper &&
          (this.$refs.gradationTabsWrapper.clientHeight > this.prevModalHeight) && !isNewNode) ||
          (this.$refs.gradationTabsWrapper && isNewNode)) {
        this.modalHeight = this.$refs.gradationTabsWrapper.clientHeight
        this.prevModalHeight = this.modalHeight
        this.prevNode = Object.assign({}, this.node)
      }
    }
    setTimeout(() => getHeight(), 5)
  },

  computed: {
    styles () {
      const styles = {}
      const treeViewHeight = window.innerHeight - 90
      const modalOffset = this.isGradational ? 54 : 0
      let modalHeight = this.modalHeight + modalOffset
      if (this.showSteps && modalHeight <= 350) {
        modalHeight = 350 + modalOffset
      }
      const treeNodeWidth = 40 * this.zoom
      const posX = this.pos.x + MODAL_WIDTH + 10 >= window.innerWidth
        ? this.pos.x - MODAL_WIDTH - treeNodeWidth
        : this.pos.x
      const topShift = modalHeight + this.pos.y > treeViewHeight
        ? Math.abs(this.pos.y + modalHeight - treeViewHeight)
        : 0
      const posY = this.pos.y + modalOffset - topShift
      styles.top = `${posY}px`
      styles.left = `${posX}px`
      if (this.isGradational) {
        styles['border-top-left-radius'] = '0px'
      }
      styles['width'] = `${MODAL_WIDTH}px`
      return styles
    }
  }
}
</script>

<style lang="scss">
  .skill-detail-modal {
    position: absolute;
  }
</style>
