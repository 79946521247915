<template lang="pug">
  .sd-step-date
    v-card-head
      h2.title {{ 'ui.labels.expiration_date' | translate }}
    v-card-content
      p {{ 'infotext.expiration_date_description' | translate }}
      .form-item
        v-date-picker(
          mode="single"
          color="green"
          v-model="date"
          :attributes="attrs"
          :popover="{ placement: 'top', visibility: 'click' }"
          :max-date="maxDate")
          template(
            v-slot="{ inputValue, inputEvents }")
            input.form-control(
              v-on="inputEvents"
              :value="inputValue"
              :placeholder="'ui.labels.expiration_date' | translate")
          template(
            v-if="date"
            v-slot:footer)
            .flex.justify-center.mb-3
              v-btn(
                icon
                danger
                @click="date = null")
                i.fas.fa-trash

      p {{ 'ui.labels.maximum_date_is' | translate }} #[span.text-link(@click="date = maxDate" v-tooltip="$t('file-pond.choose_the_latest_possible_date_for_confirmation')") {{ maxDate | formdate }}]
    v-card-actions
      v-btn(
        text
        @click="onCancel")
        | {{ (isFirst ? 'ui.buttons.cancel' : 'ui.buttons.back') | translate }}
      v-btn.ml-1(
        :disabled="!date"
        :loading="loading"
        @click="onAccept")
        | {{ (isLast ? 'ui.buttons.send' : 'ui.buttons.next') | translate }}

</template>

<script>
export default {
  name: 'SkillDetailStepExpirationDate',

  props: {
    badge: Object,
    value: String,
    isFirst: Boolean,
    isLast: Boolean,
    lastDate: Boolean
  },

  mounted () {
    if (this.badge.maxExpiryDays) {
      const date = new Date()
      date.setDate(date.getDate() + this.badge.maxExpiryDays)
      this.maxDate = date
    }
  },

  data: () => ({
    attrs: [
      {
        key: 'today',
        highlight: {
          color: 'green'
        },
        dates: new Date()
      }
    ],
    date: null,
    maxDate: null,
    loading: false
  }),

  methods: {
    onCancel () {
      if (this.isFirst) {
        this.$emit('close')
      } else {
        this.$emit('prev')
      }
    },

    onAccept () {
      this.$emit('next')
    }
  },

  watch: {
    date (value) {
      this.$emit('input', value ? value.toISOString().substring(0, 10) : null)
    }
  }
}
</script>

<style lang="scss">
</style>
