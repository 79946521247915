<template lang="pug">
  .v-dropdown(
    :class="dropdownClasses"
    ref="dropdown"
    v-click-outside="closeDropdown")
    transition(name="fadex")
    .v-dropdown__toggler(
      :style="togglerStyles"
      @click="onOpen")
      slot
    transition(
      :name="drawerMode ? 'right-drawer' : 'fade-down'")
      .v-dropdown__content(
        ref="dropdownContent"
        v-show="toggledDropdown"
        :style="contentStyles"
        :class="contentClasses"
        @click="onContentClick")
        slot(
          v-if="showContent"
          name="content")
</template>

<script>
import { mapActions } from 'vuex'
import { windowResizeObserver } from '@/util/helpers'

export default {
  name: 'Dropdown',

  props: {
    fixed: Boolean,
    full: Boolean,
    sm: Boolean,
    drawerMode: Boolean,
    position: Object,
    disabled: Boolean,
    hideOnClick: Boolean,
    isForTable: Boolean,
    handleAltKeyPressed: Boolean,
    hideOnSelfClick: Boolean
  },

  data: () => ({
    toggled: false,
    showContent: false,
    toggledDropdown: false,
    isUpwardsDropdown: false
  }),

  updated () {
    this.checkPosition()
  },

  mounted () {
    if (!this.fixed) {
      windowResizeObserver.subscribe(this.checkPosition)
    }
  },

  beforeDestroy () {
    if (!this.fixed) {
      windowResizeObserver.unsubscribe(this.checkPosition)
    }
  },

  methods: {
    ...mapActions('app', ['setOpenWithAltKeyPressed']),

    onOpen (e) {
      if (this.hideOnSelfClick && this.toggled) {
        this.closeDropdown()
      } else if (this.isForTable) {
        this.toggleForTable()
      } else {
        if (this.handleAltKeyPressed) {
          this.setOpenWithAltKeyPressed(e.altKey)
        }
        this.toggle()
      }
    },

    toggle () {
      if (!this.disabled) {
        if (!this.toggled && this.fixed) {
          this.$emit('beforeToggle')
        }

        if (!this.toggled) {
          if (this.drawerMode) {
            // Hot fix for iOS devices
            this.moveDropdownContentToRootNode()
          }
          if (!this.fixed) {
            this.toggledDropdown = true
          }
          this.toggled = true
        }
        this.$emit('toggle', this.toggled)

        if (this.fixed) {
          setTimeout(() => {
            this.$emit('after-toggle')
            if (this.position) {
              this.$refs.dropdownContent.style.left = (this.position.x) + 'px'
              this.$refs.dropdownContent.style.top = (this.position.y + 40) + 'px'
            }
            setTimeout(this.setScrollableFixedPosition(), 200)
            this.toggledDropdown = true
          }, 0)
        }
      }
    },

    setScrollableFixedPosition () {
      const topOffset = this.$refs.dropdown.getBoundingClientRect().top
      let contentHeight = this.$refs.dropdownContent.firstChild.offsetHeight
      const isTopAlign = (topOffset + contentHeight + 40) >= window.innerHeight
      if (isTopAlign) {
        this.$refs.dropdownContent.style.top = topOffset - contentHeight + 'px'
      }
    },

    checkPosition () {
      if (!this.fixed) {
        const percentTopixel = window.innerHeight / 100
        const rect = this.$refs.dropdown.getBoundingClientRect()
        if ((rect.y / percentTopixel) > 70) {
          this.isUpwardsDropdown = true
        } else {
          this.isUpwardsDropdown = false
        }
      }
    },

    toggleForTable () {
      this.$emit('toggle-for-table', true)
    },

    closeForTable () {
      this.$emit('toggle-for-table', false)
    },

    moveDropdownContentToRootNode () {
      let drawerWrapper = this.$root.$children[0].$refs.drawerWrapper
      let drawer = this.$refs.dropdownContent
      drawer.parentNode.removeChild(drawer)
      drawerWrapper.appendChild(drawer)
    },

    closeDropdown (e) {
      if (this.handleAltKeyPressed) {
        this.setOpenWithAltKeyPressed(false)
      }
      this.toggled = false
      this.toggledDropdown = false
      this.$emit('toggle', this.toggled)
    },

    onContentClick () {
      if (this.hideOnClick) {
        this.toggled = false
        this.$emit('toggle', this.toggled)
      }
    }
  },

  computed: {
    togglerStyles () {
      return {
        'z-index': this.toggled ? 10 : null
      }
    },

    contentStyles () {
      return {
        'width': this.contentWidth
      }
    },

    dropdownClasses () {
      return { 'v-dropdown__upwards': this.isUpwardsDropdown }
    },

    contentWidth () {
      // this.toggled change to this.showContent
      if (this.fixed && this.showContent) {
        return this.$refs.dropdown.offsetWidth + 'px'
      }
      return null
    },

    contentClasses () {
      return {
        'sm': this.sm,
        'full': this.full,
        'drawer-mode': this.drawerMode,
        'fixed': this.fixed
      }
    }
  },

  watch: {
    toggled (value) {
      if (value) {
        this.showContent = true
      } else {
        setTimeout(() => {
          this.showContent = false
        }, 310)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-dropdown {
    position: relative;

    &__toggler {
      position: relative;
      cursor: pointer;
      height: 100%;
    }

    &__content {
      position: absolute;
      width: 320px;
      border-radius: 4px;
      background-color: $color-white;
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      z-index: 101;

      &:not(.fixed) {
        right: 0;
      }

      &.sm {
        width: 260px;
      }

      &.full {
        width: 100%;
      }

      &.fixed {
        position: fixed;
        width: 270px;
      }

      &.drawer-mode {
        position: fixed;
        float: left;
        top: 0;
        bottom: 0;
        z-index: 100;
        width: 100%;
      }
    }
}

.v-dropdown__upwards {
  .v-dropdown__content {
       bottom:100% ;
  }

  .v-dropdown__toggler {
    .chevron-wrapper {
        transform: rotate(180deg);
    }
  }
}

  @media screen and (max-width: 680px) {
    .v-dropdown {
      position: static;
      margin-left: 1px;

      &__content {
        left: 0;
        right: 0;
        width: 100%;

        &.sm {
          width: 100%;
        }

        &.fixed {
          width: 100%;
        }
      }
    }
  }
</style>
