<template lang="pug">
  hr.v-divider(
    :class="classes")
</template>

<script>
export default {
  name: 'VDivider',

  props: {
    inCard: Boolean,
    dashed: Boolean
  },

  computed: {
    classes () {
      return {
        'v-divider--in-card': this.inCard,
        'v-divider--dashed': this.dashed
      }
    }
  }
}
</script>

<style lang="scss">
  .v-divider {
    // width: 100%;
    flex: 1 1 0px;
    display: block;
    border-color: rgba(0, 0, 0, .12);
    margin-top: .75rem;
    margin-bottom: .75rem;

    &--in-card {
      width: calc(100% - 48px);
      margin: 0 24px;
    }

    &--dashed {
      height: 1px!important;
      max-height: 1px!important;
      border: 0!important;
      background: url('~@/assets/img/line.svg') center center/cover no-repeat;
    }
  }
</style>
