<template lang='pug'>
.toolbar(:class="[activeTab]")
  search-bar(
    :expand="checkIfActivated(tabs.search)"
    :hover="hoverSearchBar"
    :hide="checkIfActivated(tabs.search) && hoverViewsToggler"
    @click.native="setTab(tabs.search)"
    @mouseenter.native="setHoverSearchBar(true)"
    @mouseleave.native="setHoverSearchBar(false)" )
  tree-views-toggler(
    :expand="checkIfActivated(tabs.views)"
    :hover="hoverViewsToggler"
    :hide="checkIfActivated(tabs.views) && hoverSearchBar"
    @click.native='setTab(tabs.views)'
    @mouseenter.native="setHoverViewsToggler(true)"
    @mouseleave.native="setHoverViewsToggler(false)")
</template>

<script>
import SearchBar from '@/components/common/search-bar/index.vue'
import TreeViewsToggler from '@/components/common/tree-views-toggler/TreeViewsToggler'

const TABS = {
  views: 'views',
  search: 'search'
}
export default {
  name: 'toolbar',
  components: { SearchBar, TreeViewsToggler },
   data: () => ({
    activeTab: TABS.views,
    hoverSearchBar: false,
    hoverViewsToggler: false
  }),
  methods: {
    setTab (nameTab) {
      this.activeTab = nameTab
    },
    checkIfActivated (nameTab) {
        return nameTab === this.activeTab
    },
    setHoverSearchBar (value) {
      this.hoverSearchBar = value
    },
    setHoverViewsToggler (value) {
      this.hoverViewsToggler = value
    }
  },
  computed: {
    tabs () {
      return TABS
    }
  }
}
</script>
<style lang='scss'>
.toolbar {
  display: flex;
  gap: 10px;
  width: 408px;
  &.search{
    justify-content: flex-start;
  }
  &.views{
    justify-content: flex-end;
  }
}
.toolbar-search,
.tree-views-toggler {
  width: 350px;
  min-width: 48px;
  transition-property: all;
  transition-timing-function: linear;
  transition-duration: 0.2s;
    &.expand {
      width: 48px;
    }
    &.hover {
      width: 350px;
    }
    &.hide {
      width: 48px;
    }
}
.toolbar-icon{
  min-width: 20px;
  width: 20px;
  height: 20px;
}
</style>
