import api from '@/api'

const SET_PERMISSIONS = 'SET_PERMISSIONS'
const SET_CURRENT_PERMISSIONS = 'SET_CURRENT_PERMISSIONS'

export default {
  namespaced: true,

  state () {
    return {
      permissions: [],
      currentPermissions: null
    }
  },

  getters: {
    permissions: (state) => state.permissions,
    currentPermissions: (state) => state.currentPermissions
  },

  mutations: {
    [SET_PERMISSIONS] (state, data) {
      state.permissions = data
    },

    [SET_CURRENT_PERMISSIONS] (state, data) {
      state.currentPermissions = data
    }
  },

  actions: {
    async loadPermission (context) {
      const { data } = await api.permissions.list()
      context.commit(SET_PERMISSIONS, data)
    },

    async loadCurrentPermissions (context) {
      const { data } = await api.permissions.getCurrent()
      context.commit(SET_CURRENT_PERMISSIONS, data)
    },

    async getPermissions (context) {
      if (!context.state.currentPermissions) {
        await context.dispatch('loadCurrentPermissions')
      }
      return context.state.currentPermissions
    },

    async linkRole (context, { id, roleId }) {
      await api.permissions.linkRole(id, roleId)
    },

    async unlinkRole (context, { id, roleId }) {
      await api.permissions.unlinkRole(id, roleId)
    }
  }
}
