<template lang="pug">
.demo-alert(:style="{top:`${offset}px`}")
  span.demo-alert--text
    | {{ $t('ui.modals.your_trial_will_expire', { days, hours }) }}
  .demo-alert--buttons
    v-btn.tutorial(outlinedText small :class="[{'bounce':isBounce}]" @click="openTutorial") {{ $t('ui.buttons.tutorial') }}
    v-btn.upgrade(outlinedText small @click="doUpgrate") {{ $t('ui.buttons.upgrade') }}
  v-btn.demo-alert--close(icon @click="onClose")
    close-icon(color="#fff", width="14" )
</template>

<script>
import CloseIcon from '@/components/svg/IconClose.vue'
import mixinTrialPeriod from '@/mixins/trial-period.js'
import { mapGetters } from 'vuex'
export default {
  name: 'DemoAlert',
  components: { CloseIcon },
  props: {
    offset: {
      type: Number,
      default: 80
    }
  },
  mixins: [mixinTrialPeriod],

  data: () => ({
    isBounce: false
  }),
  computed: {
     ...mapGetters('app', { showTutorialAfterLogin: 'showTutorialAfterLogin', isShowTutorial: 'showTutorial' })
  },
  watch: {
    isShowTutorial (value) {
      this.isBounce = !value
    }
  }
}
</script>

<style lang="scss" scoped>
.demo-alert {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 50px;
  background: $color-dark-green;
  padding: 20px 32px;
  box-sizing: border-box;
  &--text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $color-white;
  }
  &--buttons {
    display: flex;
    gap: 8px;
    margin-left: auto;
    ::v-deep .tutorial {
      background-color: transparent;
      border: 1px solid $color-white;
      color: $color-white;
      &:hover {
        background-color: $color-white;
        border: 1px solid transparent;
        color: $hover-green;
      }
    }
    ::v-deep .upgrade {
      border-color: #f68a37;
      font-weight: bold;
      background-image: linear-gradient(154deg, #f68a37 22%, #d06724 100%);
      box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
      color: $color-white;
      transition: all 1s ease;
      transform: scale(1);
      transition-duration: 300ms;
      &:hover {
        border-color: #f68a37;
        color: $color-white;
        transform: scale(1.07) perspective(1px);
        transition-duration: 300ms;
      }
    }
    .bounce {
      animation: bounce 1.5s;
    }
  }
  &--close.v-btn{
    width: 16px;
    height: 16px;
  }

  @keyframes bounce {
    0% {
      transform: scale(1.0);
    }
    25% {
      transform: scale(1.15);
    }
    50% {
      transform: scale(1.0);
    }
    75% {
      transform: scale(1.15);
    }
    100% {
      transform: scale(1.0);
    }
  }
}

</style>
