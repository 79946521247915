import api from '@/api'
// import router from '@/router'

const INC_STEP = 'INC_STEP'
const DEC_STEP = 'DEC_STEP'
const SET_STATE = 'SET_STATE'

export default {
  namespaced: true,

  state: () => ({
    steps: [
      {
        content: {
          title: 'app.onboarding.step01.title',
          subtitle: 'app.onboarding.step01.subtitle',
          state: {}
        },
        params: {
          stepsBack: 0,
          width: '520px',
          placement: 'center'
        },
        callbacks: {
          onprev: null,
          onnext: null
        }
      },
      {
        content: {
          title: 'app.onboarding.step02.title',
          subtitle: 'app.onboarding.step02.subtitle',
          state: {}
        },
        params: {
          stepsBack: 1,
          width: '850px',
          placement: 'center'
        },
        callbacks: {
          onprev: null,
          onnext: (store, params) => {
            if (params) {
              let payload = { selectedOnBoardingCategoryIds: params.map(item => item.id) }
              api.updateEmployeeSettings(payload)
            }
          }
        }
      },
      {
        content: {
          title: 'app.onboarding.step03.title',
          subtitle: 'app.onboarding.step03.subtitle',
          state: {}
        },
        params: {
          stepsBack: 1,
          width: '850px',
          placement: 'center'
        },
        callbacks: {
          onprev: null,
          onnext: null
        }
      },
      {
        content: {
          title: 'app.onboarding.step04.title',
          subtitle: 'app.onboarding.step04.subtitle',
          state: {}
        },
        params: {
          stepsBack: 1,
          width: '420px',
          placement: 'center'
        },
        callbacks: {
          onprev: null,
          onnext: null
        }
      },
      {
        content: {
          title: 'app.onboarding.step05.title',
          subtitle: 'app.onboarding.step05.subtitle',
          state: {}
        },
        params: {
          stepsBack: 1,
          width: '520px',
          placement: 'center'
        },
        callbacks: {
          onprev: null,
          onnext: null
        }
      },
      {
        target: 'CURRENT',
        content: {
          title: 'app.onboarding.step06.title',
          subtitle: 'app.onboarding.step06.subtitle',
          state: {}
        },
        params: {
          stepsBack: 1,
          width: '420px',
          placement: 'bottom',
          highlight: true
        },
        callbacks: {
          onprev: (store, params) => {
            store.dispatch('nodeTmp/unsetCursorNode')
          },
          onnext: null
        }
      },
      {
        target: '.skill-btn',
        content: {
          title: 'app.onboarding.step07.title',
          subtitle: 'app.onboarding.step07.subtitle',
          state: {}
        },
        params: {
          stepsBack: 1,
          width: '420px',
          placement: 'bottom'
        },
        callbacks: {
          onprev: (store, params) => {
            store.dispatch('nodeTmp/unsetCursorNode')
          },
          onnext: (store, params) => {
            store.dispatch('nodeTmp/unsetCursorNode')
          }
        }
      },
      // {
      //   target: '.menu__item--finder',
      //   content: {
      //     title: 'app.onboarding.step08.title',
      //     subtitle: 'app.onboarding.step08.subtitle',
      //     state: {}
      //   },
      //   params: {
      //     stepsBack: 2,
      //     width: '420px',
      //     placement: 'bottom',
      //     highlight: true
      //   },
      //   callbacks: {
      //     onprev: () => {
      //       router.push('/my')
      //     },
      //     onnext: () => {
      //       router.push('/skill-finder')
      //     }
      //   }
      // },
      {
        content: {
          title: 'app.onboarding.step09.title',
          subtitle: 'app.onboarding.step09.subtitle',
          state: {}
        },
        params: {
          stepsBack: 2,
          width: '420px',
          placement: 'center'
        },
        callbacks: {
          onprev: null,
          onnext: () => {
            api.updateEmployeeSettings({
              didShowOnBoarding: true,
              selectedOnBoardingCategoryIds: []
            })
          }
        }
      }
    ],
    currentStepIndex: 0
  }),

  getters: {
    steps: (state) => state.steps,
    currentStep: (state) => state.steps[state.currentStepIndex],
    currentStepIndex: (state) => state.currentStepIndex,
    currentStepState: (state) => state.steps[state.currentStepIndex].content.state
  },

  mutations: {
    [INC_STEP]: (state) => {
      state.currentStepIndex++
    },

    [DEC_STEP]: (state, value) => {
      state.currentStepIndex -= value
    },

    [SET_STATE]: (state, value) => {
      state.steps[state.currentStepIndex].content.state = value
    }
  },

  actions: {
    next (context) {
      context.commit(INC_STEP)
    },

    prev (context) {
      let stepsBack = context.getters.currentStep.params.stepsBack
      context.commit(DEC_STEP, stepsBack)
    },

    setState (content, data) {
      content.commit(SET_STATE, data)
    },

    skip () {
      api.updateEmployeeSettings({
        didShowOnBoarding: true
      })
    }
  }
}
