<template lang="pug">
  .v-show-hide
    .v-show-hide__inner(
      ref="inner"
      :style="styles"
      :class="classes")
      div(
        name="slide-left"
        mode="out-in"
        tag="ul"
        class="v-show-hide__list")
        slot
    button.btn-transparent.btn-show-more(
      v-if="hasHiddenItems"
      @click="toggle")
      | {{ label | translate }}

</template>

<script>
export default {
  name: 'VShowHide',

  props: {
    itemsToShow: {
      type: Number,
      default: 3
    },
    maxHeight: {
      type: Number,
      default: 0
    },
    disableOffset: Boolean
  },

  mounted () {
    this.reinit()
  },

  updated () {
    this.reinit()
  },

  data: () => ({
    toggled: false,
    itemsCount: 0,
    contentHeight: 0,
    visibleHeight: 0
  }),

  methods: {
    toggle () {
      this.toggled = !this.toggled
    },

    reinit () {
      if (this.$refs.inner && this.getListEl()) {
        this.contentHeight = this.getListEl().clientHeight

        // calculate visible items height
        const nodes = this.getListEl().childNodes
        this.itemsCount = nodes.length
        const itemsCount = this.itemsCount < this.itemsToShow
          ? this.itemsCount
          : this.itemsToShow
        let height = 0
        for (let index = 0; index < itemsCount; index++) {
          height += nodes[index].clientHeight
        }
        this.visibleHeight = height

        // for fix bug with transition component
        setTimeout(() => {
          const nodes = this.getListEl() ? this.getListEl().childNodes : null
          if (nodes && this.itemsCount !== nodes.length) {
            this.reinit()
          }
        }, 300)

        // reset toggle state
        if (this.itemsCount <= this.itemsToShow && this.toggled && !this.maxHeight) {
          this.toggled = false
        }
      }
    },

    getListEl () {
      return this.$refs.inner ? this.$refs.inner.childNodes[0] : []
    }
  },

  computed: {
    label () {
      return this.toggled
        ? 'ui.buttons.hide'
        : 'ui.buttons.show_more'
    },

    styles () {
      let maxHeightOfNotToggled = Math.min(this.contentHeight, this.maxHeight || this.visibleHeight)
      const height = this.toggled
        ? this.contentHeight
        : maxHeightOfNotToggled + (this.disableOffset ? 0 : 10)
      return `height: ${height}px`
    },

    classes () {
      return {
        'v-show-hide--hidden': this.hasHiddenItems && !this.toggled
      }
    },

    hasHiddenItems () {
      return (!this.maxHeight && this.itemsCount > this.itemsToShow) || (this.maxHeight && this.contentHeight > this.maxHeight)
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-show-hide {

    &__inner {
      overflow: hidden;
    }

    &__list {
      padding: 0;
    }

    &--hidden {
      mask-image: -webkit-linear-gradient(
        rgba(0, 0, 0, 1) 60%,
        rgba(0, 0, 0, 0) 100%);
    }
  }
</style>
