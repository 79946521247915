<template lang="pug" functional>
  span.v-badge(
    :style="{ '--v-badge-bg-color': props.color }")
    span.v-badge__value(
      :class="{ 'v-badge__value--visible': props.show }") {{ props.value }}
    slot
</template>

<script>
export default {
  name: 'VBadge',

  props: {
    color: {
      type: String,
      default: '#f44336'
    },
    value: [String, Number],
    show: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.v-badge {
    --v-badge-bg-color: #{$color-red};
    position: relative;
    height: 100%;
    display: inline-flex;
    align-items: center;

    &__value {
      z-index: 10;
      position: absolute;
      left: calc(100% - 12px);
      bottom: calc(100% - 12px);
      color: $color-white;
      font-size: 12px;
      padding: 2px 5px;
      border-radius: 17px;
      line-height: 1;
      text-align: center;
      text-indent: 0;
      white-space: nowrap;
      height: 20px;
      min-width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--v-badge-bg-color);
      transition: .3s;
      opacity: 0;
      transform: scale(0);

      &--visible {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
</style>
