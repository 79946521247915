<template lang="pug">
  transition(name="fadex")
    .v-overlay(
      v-if="value"
      @mouseup="onMouseUp"
      @mousedown.self="isMouseDownOutside = true")
        .v-dialog(
          :style="styles")
          .v-dialog__inner
            slot
</template>

<script>
export default {
  name: 'Dialog',

  props: {
    value: Boolean,
    width: {
      type: [Number, String],
      default: 800
    },
    topOffset: {
      type: [Number, String],
      default: 0
    },
    height: {
      type: [Number, String],
      default: 'auto'
    },
    lockoverlay: Boolean
  },

  data: () => ({
    isMouseDownOutside: false
  }),

  methods: {
    toggle () {
      if (!this.lockoverlay) {
        this.$emit('input', false)
      }
    },

    onMouseUp () {
      if (this.isMouseDownOutside) {
        this.toggle()
      }
      this.isMouseDownOutside = false
    }
  },

  computed: {
    styles () {
      return {
        width: `${this.width}px`,
        marginTop: `${this.topOffset}px`,
        height: this.height === 'auto' ? 'auto' : `${this.height}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .2);
    z-index: 102;
  }

  .v-dialog {
    max-height: 100vh;
    &__inner{
      height: inherit;
    }
  }
</style>
