<template lang="pug">
  v-dialog(
    :width="width"
    :value="confirmModal.show"
    @input="closeConfirmModal")
    v-card
      v-card-content
        .confirmation-modal__content(
          :class="contentClasses")
          .icon-wrapper
            i.icon(:class="iconClass")
          .confirmation-modal__content--body
            h2.title {{ confirmModal.content.title }}
            p.subtitle(v-if="confirmModal.content.subtitle") {{ confirmModal.content.subtitle }}
      v-card-actions
        v-btn(
          text
          @click="onCancel") {{ confirmModal.buttons.cancel.label | translate }}
        v-btn(
          :loading="loading"
          :danger="isDanger"
          @click="onApply") {{ confirmModal.buttons.apply.label | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { MODAL_TYPES } from '@/util/constants'

export default {
  name: 'ConfirmModal',

  data: () => ({
    loading: false
  }),

  methods: {
    ...mapActions('modals', [
      'closeConfirmModal'
    ]),

    onCancel () {
      if (this.confirmModal.buttons.cancel.callback) {
        const { params } = this.confirmModal.buttons.cancel
        this.confirmModal.buttons.cancel.callback(params)
      }
      this.closeConfirmModal()
    },

    async onApply () {
      this.loading = true
      if (this.confirmModal.buttons.apply.callback) {
        const { params } = this.confirmModal.buttons.apply
        await this.confirmModal.buttons.apply.callback(params)
      }
      this.closeConfirmModal()
      this.loading = false
    }
  },

  computed: {
    ...mapGetters('modals', [
      'confirmModal'
    ]),

    width () {
      return this.isVertical
        ? 450
        : 620
    },

    iconClass () {
      return `icon--${this.confirmModal.props.type}`
    },

    isVertical () {
      return this.confirmModal.props.vertical
    },

    isDanger () {
      return this.confirmModal.props.type === MODAL_TYPES.DANGER ||
        this.confirmModal.props.type === MODAL_TYPES.WARNING_RED
    },

    contentClasses () {
      return {
        'confirmation-modal__content--vertical': this.isVertical
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .confirmation-modal__content {
    display: flex;
    align-items: center;

    &--body {
      display: flex;
      flex-direction: column;
    }

    &:not(.confirmation-modal__content--vertical) {
      padding-top: 20px;

      .icon-wrapper {
        padding-right: 20px;
      }

      i {
        height: 120px;
        width: 120px;
      }
    }

    &--vertical {
      flex-direction: column;
      padding-top: 30px;
      padding-bottom: 30px;

      i {
        margin-bottom: 20px;
      }

      h2 {
        text-align: center;
        max-width: 80%;
      }
    }
  }
</style>
