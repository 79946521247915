<template lang="pug">
  .v-baguette(
    :style="styles"
    :class="classes")
    slot
</template>

<script>
export default {
  name: 'VBaguette',

  props: {
    color: {
      type: String,
      default: '#666D74'
    },
    backgroundColor: {
      type: String,
      default: '#F0F2FA'
    },
    vertical: Boolean
  },

  computed: {
    styles () {
      return {
        color: this.color,
        backgroundColor: this.backgroundColor
      }
    },

    classes () {
      return {
        'v-baguette--vertical': this.vertical
      }
    }
  }
}
</script>

<style lang="scss">
  .v-baguette {
    min-width: 120px;
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 10px;
    line-height: 1.4;

    &--vertical {
      min-width: 0;
      min-height: 120px;
      flex-direction: column-reverse;

      span {
        writing-mode: vertical-lr;
        text-orientation: mixed;
        transform: rotate(180deg);
      }

      svg {
        margin-top: 10px;
        transform: rotate(-90deg);
      }

      > span:not(:last-child) {
        margin-top: 20px;

        &::after {
          bottom: -10px;
        }
      }
    }

    &:not(&--vertical) svg {
      margin-right: 10px;
    }

    span {
      white-space: nowrap;
    }

    &:not(&--vertical) > span:not(:last-child) {
      margin-right: 20px;

      &::after {
        right: -10px;
      }
    }

    > span:not(:last-child) {
      position: relative;

      &::after {
        content: "|";
        position: absolute;
        opacity: .5;
      }
    }
  }
</style>
