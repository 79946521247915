<template lang="pug">
  v-dialog(
    width="500"
    :value="show"
    @input="closeModal")
    v-card
      v-card-head
        v-card-head-label
          h2.modal-title {{ 'pages.my.suggest_a_badge' | translate }}
          p.subtitle {{ 'infotext.are_you_missing_one_of_your_skills_use_this_form_the_send_a_suggestion_to_your_skill_manager' | translate }}
        v-card-head-toolbar
            v-btn(
              icon
              gray
              @click="closeModal") x
      v-scrollable(
        :bottom-offset="40")
        v-card-content
          .form-group
              label {{ 'ui.labels.name' | translate }}
              input.form-control(:placeholder="'ui.inputs.enter_badge_name' | translate " v-model="name")
              span.form-text.invalid {{ 'name' | errorText }}
          .form-group
              label(for="required-badges") {{ 'pages.builder.required_badge' | translate }}
              v-info-icon(:text="$t('infotext.here_you_can_add_existing_badges_as_requiments_for_your_skill')")
              v-select(
                fixed
                name="required-badges"
                v-model="selectedReqBadges"
                trackBy="displayName"
                :options="badges"
                :loading="loading"
                @on-open-dropdown="onOpenDropdown")
              .required-badges(v-if="selectedReqBadges.length")
                v-show-hide
                  node-list-item(v-for="badge in selectedReqBadges"
                    :key="badge.id"
                    :data="badge"
                    :show-remove="!badge.isPreRequired"
                    @remove="onRequiredBadgeRemove(badge)")
          .form-group
              label {{ 'ui.labels.description' | translate }}
              textarea.form-control(
                :placeholder="'ui.inputs.enter_description' | translate "
                rows="4"
                v-model="badgeDescription")
      v-card-actions
        v-btn(
          text
          @click="closeModal") {{ 'ui.buttons.cancel'  | translate }}
        v-btn(
          :loading="onCreating"
          @click="onCreate") {{ 'ui.buttons.suggest'  | translate }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { NODE_TYPE } from '@/util/constants.js'

import NodeListItem from '@/components/common/NodeListItem'

export default {
  name: 'BadgeSuggest',

  components: {
    NodeListItem
  },

  props: {
    show: Boolean,
    categoryForSuggestBadge: Object
  },

  data: () => ({
    onCreating: false,
    name: '',
    selectedParentCategory: {},
    loading: true,
    selectedReqBadges: [],
    badgeDescription: '',
    onOpenDropdownStatus: false
  }),

  mounted () {
    this.loadCategories()
    this.selectedParentCategory = this.categoryForSuggestBadge
    this.loadRequiredBadges()
  },

  destroyed () {
    this.clearErrors()
  },

  methods: {
    ...mapActions('app', [
      'loadCategories',
      'getBadges',
      'clearErrors',
      'setPopupMessage'
    ]),

    ...mapActions('trees', [
      'suggestBadge'
    ]),

    async onCreate () {
      this.onCreating = true
      const requiredBadgesId = []
      for (const item of this.selectedReqBadges) {
        requiredBadgesId.push(item.id)
      }
      const payload = {
        name: this.name,
        parentBadgeId: this.selectedParentCategory.id,
        typeId: NODE_TYPE.BADGE,
        description: this.badgeDescription,
        requiredBadgesId
      }
      const { error } = await this.suggestBadge({
        toNodeId: this.selectedParentCategory.id,
        payload
      })
      if (!error) {
        this.closeModal()
        this.setPopupMessage({
          message: this.$t('infotext.awesome_your_suggestion_has_been_sent_to_your_skill_manager')
        })
      }
      this.onCreating = false
    },

    closeModal () {
      this.$emit('close-modal')
    },

    async loadRequiredBadges () {
      this.loading = true
      await this.getBadges()
      this.loading = false
    },

    onRequiredBadgeRemove (badge) {
      this.selectedReqBadges = this.selectedReqBadges
        .filter(el => el.id !== badge.id)
    },

    onOpenDropdown (value) {
      if (this.onOpenDropdownStatus !== value) {
        this.onOpenDropdownStatus = value
      }
    }
  },

  computed: {
    ...mapGetters('app', [
      'categories',
      'badges'
    ]),

    badgeTypes () {
      return [
        { name: this.$t('ui.labels.simple'), typeId: NODE_TYPE.BADGE },
        { name: this.$t('ui.labels.gradational'), typeId: NODE_TYPE.GRADATIONAL_BADGE }
      ]
    }
  }
}
</script>

<style scoped>
</style>
