<template lang="pug">
  table.v-table
    slot
</template>

<script>
export default {
  name: 'VTable'
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_mixins.scss';

  .v-table {
    width: 100%;
    border-collapse: collapse;
    &.table-fixed{
      table-layout: fixed;

      td{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__info {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__row {
      &--empty {
        height: 40vh;
        text-align: center;
      }

      th {
        font-size: 14px;
        font-weight: 600;
        padding: 1rem 0.75rem;
        border-bottom: 1px solid $v-table-row-border-color;
      }

      td {
        padding: 0.75rem;
        text-overflow: ellipsis;

        &.v-table__cell--visible-on-hover > * {
          opacity: 0;
        }
      }

      &:not(:last-child) {
        td {
          border-bottom: 1px solid $v-table-row-border-color;
        }
      }

      &:hover {
        .v-table__cell--visible-on-hover > * {
          opacity: 1;
        }
      }

      &--borderless {
        td {
          border-bottom: 1px solid transparent !important;
        }
      }
    }

    &__body {
      .v-table__row {
        &:nth-of-type(odd) {
          .v-checkbox {
            input + label {

              &::before {
                background: white;
              }
            }
          }
          background-color: $light-blue;
        }
      }

      &--empty {
        td {
          border-color: transparent;
        }

        .v-table__row {
          background-color: transparent !important;
        }
      }
    }

    .cell-skeleton {
      height: 33px;
      width: 100%;
      border-radius: 5px;

      @include skeleton;
    }
  }
</style>
