import { mapActions, mapGetters, mapMutations } from 'vuex'
import { NODE_TYPE, MODAL_TYPES } from '@/util/constants.js'

export default {
  name: 'ContextMenu',

  data: () => ({
    isUpdating: false
  }),

  props: {
    pos: Object,
    show: Boolean,
    isCtrl: Boolean
  },

  methods: {
    ...mapActions('trees', [
      'createNode',
      'updateNode',
      'moveCategory',
      'deleteCategory',
      'deleteHookedBadge',
      'rearrangeTree',
      'getNodeById'
    ]),

    ...mapActions('badges', [
      'moveBadges',
      'copyBadges',
      'deleteBadges',
      'showBadges',
      'hideBadges'
    ]),

    ...mapActions('modals', [
      'openConfirmModal'
    ]),

    ...mapActions('nodeTmp', [
      'selectNode',
      'unselectNode',
      'unselectAllNodes',
      'unsetContextMenuNode',
      'isNodeSelected',
      'setCutForSelectedNodes',
      'unsetCutForSelectedNodes',
      'setClipboard',
      'clearClipboard',
      'discardNodeChanges',
      'setCursorNode'
    ]),

    ...mapMutations('trees', { setMultipleRootNode: 'MULTIPLE_ROOT_NODE' }),

    copyBadge () {
      this.clearClipboard()
      this.setClipboard()
      this.closeContextMenu()
    },

    async cutNode () {
      this.clearClipboard()
      this.setCutForSelectedNodes()
      this.setClipboard()
      this.closeContextMenu()
    },

    async pasteBadge () {
      if (this.allowPasteToCategory) {
        if (this.clipboard.length) {
          if (this.clipboard[0].localProps.isCut) {
            if (this.clipboard[0].props.typeId === NODE_TYPE.CATEGORY) {
              await this.moveCategory({
                toNode: this.contextMenuNode,
                node: this.clipboard[0]
              })
            } else {
              await this.moveBadges({
                toNode: this.contextMenuNode,
                nodes: this.clipboard
              })
            }
          } else {
            await this.copyBadges({
              toNode: this.contextMenuNode,
              nodes: this.clipboard
            })
          }
        }
      }
      this.unselectAllNodes()
      this.clearClipboard()
      this.closeContextMenu()
    },

    deleteNode () {
      const options = {
        content: {
          title: `Do you want to delete ${this.selectedNodes.length} element(s)?`
        },
        props: {
          type: MODAL_TYPES.WARNING_RED
        },
        buttons: {
          apply: {
            label: 'ui.buttons.delete',
            callback: this.onDelete
          }
        }
      }
      this.openConfirmModal(options)
      this.closeContextMenu()
    },

    async onDelete () {
      if (this.selectedNodes[0].props.typeId === NODE_TYPE.CATEGORY) {
        const getMultipleRootNode = await this.getNodeById(this.multipleRootNode)
        const openIds = getMultipleRootNode.parents.reduce((sum, el) => ([...sum, el.props.id]), [])
        if (openIds.includes(this.selectedNodes[0].props.id) || this.selectedNodes[0].props.id === this.multipleRootNode) {
          await this.setMultipleRootNode(this.selectedNodes[0].parents[0].props.id)
        }
        await this.deleteCategory(this.selectedNodes[0])
      } else if (this.selectedNodes[0].props.typeId === NODE_TYPE.HOOKED_BADGE) {
        await this.deleteHookedBadge(this.selectedNodes[0])
      } else {
        await this.deleteBadges({
          nodes: this.selectedNodes
        })
      }
      this.setCursorNode()
      this.unselectAllNodes()
      this.clearClipboard()
      this.rearrangeTree()
    },

    addCategory () {
      if (this.allowAddCategory) {
        this.createNewNode(
          this.getNewNodeName(
            this.$t('ui.other._category')),
          NODE_TYPE.CATEGORY)
      }
    },

    addBadge () {
      if (this.allowAddBadge) {
        this.createNewNode(
          this.getNewNodeName(
            this.$t('ui.other._badge')),
          NODE_TYPE.BADGE)
      }
    },

    addGradationalBadge () {
      if (this.allowAddBadge) {
        this.createNewNode(
          this.getNewNodeName(
            this.$t('ui.other._gradational_badge')),
          NODE_TYPE.GRADATIONAL_BADGE)
      }
    },

    getNewNodeName (name) {
      const parentName = this.contextMenuNode.props.name
      const badgesCount = this.contextMenuNode.children.length + 1
      return `${parentName} - ${name} (${badgesCount})`
    },

    async createNewNode (name, type) {
      this.discardNodeChanges()
      const payload = {
        toNode: this.contextMenuNode,
        name: name,
        typeId: type
      }
      this.createNode(payload)
      this.closeContextMenu()
    },

    async changeInvisible (value) {
      this.isUpdating = true
      if (this.contextMenuNode.props.typeId !== NODE_TYPE.CATEGORY) {
        if (value) {
          await this.showBadges({ nodes: this.selectedNodes })
        } else {
          await this.hideBadges({ nodes: this.selectedNodes })
        }
      } else {
        this.contextMenuNode.props.isVisible = value
        await this.updateNode({
          node: this.contextMenuNode,
          props: this.contextMenuNode.props
        })
      }
      this.isUpdating = false
    },

    closeContextMenu () {
      this.$emit('close')
    }
  },

  computed: {
    ...mapGetters('nodeTmp', [
      'selectedNodes',
      'contextMenuNode',
      'hasSelectedNodes',
      'clipboard'
    ]),

    ...mapGetters('trees', ['multipleRootNode']),

    position () {
      return `left: ${this.pos.x}px; top: ${this.pos.y}px;`
    },

    allowCopy () {
      return this.contextMenuNode.props.typeId !== NODE_TYPE.CATEGORY &&
        this.contextMenuNode.props.typeId !== NODE_TYPE.HOOKED_BADGE
    },

    allowCut () {
      return ((this.contextMenuNode.props.typeId !== NODE_TYPE.CATEGORY &&
        this.contextMenuNode.props.typeId !== NODE_TYPE.HOOKED_BADGE) ||
        (this.contextMenuNode.props.typeId === NODE_TYPE.CATEGORY &&
        !this.selectedNodes.some(item => item.props.typeId !== NODE_TYPE.CATEGORY))) &&
        !this.isRootNode
    },

    allowDelete () {
      return (this.contextMenuNode.props.typeId !== NODE_TYPE.CATEGORY ||
        this.selectedNodes.length < 2) && !this.isRootNode
    },

    allowAddCategory () {
      return this.contextMenuNode.children && (this.contextMenuNode.children.length === 0 ||
        this.contextMenuNode.children[0].props.typeId === NODE_TYPE.CATEGORY) &&
        this.contextMenuNode.props.typeId === NODE_TYPE.CATEGORY
    },

    allowPasteToCategory () {
      const isTargetNodeACategory = this.contextMenuNode.props
        .typeId === NODE_TYPE.CATEGORY

      if (!isTargetNodeACategory) {
        return false
      }

      if (this.contextMenuNode.props.id === this.clipboard[0].props.id) {
        return false
      }

      const isTargetNodeWithCategories = this.contextMenuNode.children
        .some(item => item.props.typeId === NODE_TYPE.CATEGORY)

      const isTargetAChildOfSource = this.contextMenuNode.parents
        .some(item => item.props.id === this.clipboard[0].props.id)

      const hasClipboardCategories = this.clipboard
        .some(item => item.props.typeId === NODE_TYPE.CATEGORY)

      if (hasClipboardCategories &&
        isTargetNodeWithCategories &&
        !isTargetAChildOfSource) {
        return true
      }

      if (!hasClipboardCategories &&
        !isTargetNodeWithCategories) {
        return true
      }

      return false
    },

    allowAddBadge () {
      return this.contextMenuNode.children && (this.contextMenuNode.children.length === 0 ||
        this.contextMenuNode.children[0].props.typeId !== NODE_TYPE.CATEGORY) &&
        this.contextMenuNode.props.typeId === NODE_TYPE.CATEGORY
    },

    allowToggleVisibility () {
      return (this.contextMenuNode.props.typeId !== NODE_TYPE.CATEGORY ||
        this.selectedNodes.length < 2) && !this.isRootNode
    },

    isRootNode () {
      return !this.contextMenuNode.parents.length
    }
  },

  watch: {
    async contextMenuNode (node) {
      // Select node when context menu opens
      if (node !== null) {
        if (!await this.isNodeSelected(node)) {
          if (!this.isCtrl && this.hasSelectedNodes) {
            this.unselectAllNodes()
          }
          if (node.props.typeId !== NODE_TYPE.CATEGORY ||
            (node.props.typeId === NODE_TYPE.CATEGORY && !this.hasSelectedNodes)) {
            this.selectNode(node)
          }
        }
      }
    }
  }
}
