<template lang="pug">
  v-dialog(
    :width="620"
    :value="show"
    @input="cancel")
    v-card
      v-card-head
        v-card-head-label
          h2.modal-title {{ title | translate }}
        v-card-head-toolbar
          v-btn(
            icon
            gray
            @click="cancel") x
      v-card-content
        .simple-modal__content
          .icon-wrapper
            i.icon(:class="iconClass")
          .simple-modal__content--body
            slot(name="body")
      v-card-actions
        v-btn(
          :text="!isWarning"
          @click="cancel") {{  cancelBtnTitle || 'ui.buttons.cancel' | translate }}
        v-btn(
          :danger="isWarning"
          @click="confirm") {{  confirmBtnTitle || 'ui.buttons.confirm' | translate }}

</template>

<script>

export default {

  name: 'SimpleModal',

  props: {
    title: String,
    confirmBtnTitle: String,
    cancelBtnTitle: String,
    type: String,
    show: Boolean,
    isWarning: Boolean
  },

  methods: {
    confirm () {
      this.$emit('on-confirm')
    },

    cancel () {
      this.$emit('on-cancel')
    }
  },

  computed: {
    iconClass () {
      return `icon--${this.type}`
    }

  }
}
</script>

<style lang="scss" scoped>
  .simple-modal__content {
    display: flex;
    padding-top: 20px;

      .icon-wrapper {
        padding-right: 20px;
      }

      i {
        height: 120px;
        width: 120px;
      }
  }
</style>
