<template lang="pug">
  v-group(
    ref="node")
    template(v-if="parentNode && isNodeInFocus")
      tree-line(
        v-if="isCategory"
        :begin="parentNodePos"
        :end="pos"
        :color="lineColor"
        :is-selected="isSelected")
    template(v-if="hasBadges(node.children) && isSelected")
      tree-badge-branch(
        :color="chainColor"
        :lineColor="lineColor"
        :badges="node.children"
        :parentPos="pos"
        :direction="direction"
        :depth="depth + 1"
        :cursorNode="cursorNode"
        :selectedChain="selectedChain"
        @click="onClick")
    template(v-else)
      tree-node(
        v-for="(item, index) in node.children"
        :key="index"
        :index="index"
        :node="item"
        :depth="depth + 1"
        :totalAngleShift="angleShift"
        :parentAngleShift="nodeAngleShift + (node.localProps.angle || 0)"
        :parentNode="node"
        :parentNodePos="pos"
        :parentDirection="direction"
        :cursorNode="cursorNode"
        :focusNode="focusNode"
        :isPrevNodeVisible="isNodeInFocus"
        @click="onClick"
        @open-modal="onOpenModal"
        @close-modal="onCloseModal"
        @animate-parent-angle-shift="onAnimateAngleShift")
    template(v-if="isNodeInFocus")
      tree-category(
        :depth="depth"
        :pos="pos"
        :color="chainColor"
        :children-count="node.children.length"
        :is-selected="isSelected"
        :is-activated="isActivated"
        @click="onClick")
        template(v-slot="slotProps")
          tree-hooked-badge(
            v-if="node.props.hookedBadge"
            :color="chainColor"
            :depth="depth + 1"
            :node="node.props.hookedBadge"
            :pos="{x: pos.x + slotProps.radius * 0.65, y: pos.y + slotProps.radius * 1}"
            @select="onClick")
        //  TODO @right-click="onRightClick" do we need right click here?
      tree-label(
        :pos="labelPos"
        :depth="depth"
        :direction="direction"
        :text="node.props.displayName")
</template>

<script>
import TWEEN from '@tweenjs/tween.js'
import helpers from '@/util/helpers.js'
import { NODE_TYPE } from '@/util/constants.js'

import TreeCategory from './TreeCategory'
import TreeLine from './TreeLine'
import TreeLabel from './TreeLabel'
import TreeBadgeBranch from './TreeBadgeBranch'
import TreeHookedBadge from './TreeHookedBadge'

const animate = (time) => {
    requestAnimationFrame(animate)
    TWEEN.update(time)
}
requestAnimationFrame(animate)

export default {
  name: 'TreeNode',

  components: {
    TreeCategory,
    TreeLine,
    TreeLabel,
    TreeBadgeBranch,
    TreeHookedBadge
  },

  props: {
    node: Object,
    focusNode: Object,
    cursorNode: Object,
    parentNode: Object,
    isPrevNodeVisible: Boolean,
    index: Number,
    totalAngleShift: {
      type: Number,
      default: 0
    },
    parentAngleShift: {
      type: Number,
      default: 0
    },
    parentNodePos: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    parentDirection: {
      type: Number,
      default: 1
    },
    depth: {
      type: Number,
      default: 1
    }
  },

  mounted () {
    if (this.isCursorNode) {
      this.onClick()
    }
  },

  data: () => ({
    nodeAngleShift: 0,
    nodeAxisShift: 0
  }),

  computed: {
    isCursorNode () {
      return this.cursorNode &&
        this.cursorNode.props.id === this.node.props.id
    },

    radius () {
      if (this.node.localProps.step < Math.PI / 2) {
        return Math.max(50 / Math.sin(this.node.localProps.step), 300)
      }
      return 300
    },

    pos () {
      if (this.depth > 1) {
        return {
          x: this.parentNodePos.x + Math.cos(this.node.localProps.angle + this.angleShift) * (this.radius + this.nodeAxisShift),
          y: this.parentNodePos.y + Math.sin(this.node.localProps.angle + this.angleShift) * this.radius
        }
      }
      return { x: 0, y: 0 }
    },

    labelPos () {
      if (this.depth > 1) {
        const shift = 60 / this.depth + 30
        return {
          x: this.parentNodePos.x + Math.cos(this.node.localProps.angle + this.angleShift) * (this.radius + shift + this.nodeAxisShift),
          y: this.parentNodePos.y + Math.sin(this.node.localProps.angle + this.angleShift) * (this.radius + shift)
        }
      }
      return { x: 0, y: 80 }
    },

    angleShift () {
      return this.parentAngleShift + this.totalAngleShift
    },

    direction () {
      return this.pos.x < this.parentNodePos.x ? -1 : 1
    },

    isSelected () {
      if (!this.cursorNode) return false
      const chain = [this.cursorNode, ...this.cursorNode.parents]
      return chain.some(chainNode => chainNode.props.id === this.node.props.id || chainNode.props.id === this.node?.hookedBadge?.props.id)
    },

    focusChain () {
      return [this.focusNode, ...this.focusNode.parents]
    },

    isNodeInFocusChain () {
      return this.selectedChain.some(item => item.props.id === this.node.props.id)
    },

    isParentNodeInFocusChain () {
      if (!this.parentNode) return false
      return this.selectedChain.some(item => item.props.id === this.parentNode.props.id)
    },

    selectedChain () {
      if (!this.cursorNode) return []
      return [this.cursorNode, ...this.cursorNode.parents]
    },

    hasSelectedChildren () {
      return this.isSelected && this.node.props.id !== this.cursorNode.props.id
    },

    // is current node visible on screen
    isNodeInFocus () {
      return this.focusChain.some(item => item.props.id === this.node.props.id) ||
        (this.node.parents.length && this.focusChain[0].props.id === this.node.parents[0].props.id) ||
        this.focusChain.some(item => item.props.id === this.node.parents[0].props.id)
    },

    isActivated () {
      if (this.node.props.typeId !== NODE_TYPE.CATEGORY) {
        return this.node.props.isActivated
      }
      return true
    },

    isCategory () {
      return this.node.props.typeId === NODE_TYPE.CATEGORY
    },

    chainColor () {
      const chain = [this.node, ...this.node.parents]
      const colorNode = chain.find(chainNode => chainNode.props.color)
      return colorNode ? colorNode.props.color : '#13B389'
    },

    lineColor () {
      return helpers.pSBC(0.55, this.chainColor, '#F8F9FC')
    }
  },

  methods: {
    onClick (node) {
      if ((!node || this.nodeAngleShift === 0) && this.depth > 1) {
        this.$emit('close-modal')
        const angleShift = this.getAngleShift()
        const to = {
          angle: this.depth === 2
            ? -angleShift
            : -this.node.localProps.angle
        }
        this.$emit('animate-parent-angle-shift', to)
        // ... and animate shift by X axis below
        const direction = this.pos.x < 0 ? -1 : 1
        if (this.node.props.typeId === NODE_TYPE.CATEGORY) {
          this.onAnimateAxisShift({ x: this.radius / 2 }, true)
          this.node.localProps.pos.x = direction * (this.depth - 1) * this.radius * 1.5
        } else {
          this.node.localProps.pos.x = direction * (this.depth - 1) * this.radius * 1.25
        }
        this.node.localProps.pos.y = 0
      }
      this.$emit('click', node || this.node)
    },

    getAngleShift () {
      const absoluteAngleShift = this.node.localProps.angle + this.parentAngleShift
      let angleShift = 0
      if (absoluteAngleShift <= Math.PI / 2) {
        angleShift = this.node.localProps.angle
      } else if (absoluteAngleShift <= Math.PI) {
        angleShift = -(Math.PI - this.node.localProps.angle)
      } else if (absoluteAngleShift <= Math.PI * 1.5) {
        angleShift = (this.node.localProps.angle - Math.PI)
      } else if (absoluteAngleShift <= Math.PI * 2) {
        angleShift = -(Math.PI * 2 - this.node.localProps.angle)
      } else {
        angleShift = -(Math.PI * 2 - this.node.localProps.angle)
      }
      return angleShift
    },

    onAnimateAngleShift (to) {
      const from = {
        angle: this.nodeAngleShift
      }
      new TWEEN.Tween(from)
        .to(to, 700)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate(() => {
          this.nodeAngleShift = from.angle
        })
        .start()
    },

    onAnimateAxisShift (to, fireEvent = false) {
      const from = {
        x: this.nodeAxisShift
      }
      new TWEEN.Tween(from)
        .to({ x: to.x }, 700)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate(() => {
          this.nodeAxisShift = from.x
        })
        .onComplete(() => {
          if (fireEvent) {
            this.$emit('open-modal', this.direction)
          }
        })
        .start()
    },

    onOpenModal (direction) {
      this.$emit('open-modal', direction)
    },

    onCloseModal () {
      this.$emit('close-modal')
    },

    hasBadges (nodes = []) {
      if (nodes.length) {
        return nodes.some(item => item.props.typeId === NODE_TYPE.BADGE ||
          item.props.typeId === NODE_TYPE.GRADATIONAL_BADGE)
      }
      return false
    }
  },

  watch: {
    isSelected (value) {
      if (!value) {
        // resets node axis shift on unselect
        this.onAnimateAxisShift({ x: 0 })
        this.$emit('close-modal')
      }
    },

    hasSelectedChildren (value) {
      if (!value && this.depth > 1) {
        // resets node rotation when node has no selected children
        this.onAnimateAngleShift({ angle: 0 })
      }
    },

    isCursorNode (value) {
      if (value) {
        this.onClick()
      }
    }
  }
}
</script>

<style>
</style>
