<template lang="pug">
.explore-search
  v-input-search.explore-search--field(
    auto-focus
    v-model="search",
    :placeholder="$t(`ui.inputs.type_to_search`)"
    @input="updateSearch"
  )
  v-list-empty(v-if="!badges.length && !loading")
      no-result-icon
      span {{ $t(`ui.notifications.no_skills_found`) }}
  v-scrollable(
    ref="refScroll"
    :min-height="20"
    :max-height="336"
    :bottom-offset="10"
    @scroll-complete="onNextPage")
    v-list.badges-list(v-if="badges.length")
      v-list-item.badge-item(
        v-for="item in badges",
        :key="`badges_${item.id}`",
        :class="{ active: selectedItem && item.id === selectedItem.id }"
        @click="selectBadge(item)"
      )
        slot(name="icon")
          icon-badge.badge-icon
        v-list-item-content(v-tooltip="{ content: firstLetterUpperCase(item.name), placement: 'top-start' }")
          v-list-item-title.badge-title
            | {{ firstLetterUpperCase(item.name) }}
    template(v-if="loading")
      v-list-item.badge-item
        v-skeleton.w-100
      v-list-item.badge-item
        v-skeleton.w-100
</template>

<script>
import { mapActions } from 'vuex'
import iconBadge from '@/components/svg/skill-matrix/IconBadge.vue'
import scrollPaginationSkeletonsMixin from '@/mixins/scroll-pagination-skeletons.mixin.js'
import NoResultIcon from '@/components/svg/images/NoResult.vue'
export default {
  name: 'ExploreSearch',

  mixins: [scrollPaginationSkeletonsMixin],

  components: {
    iconBadge,
    NoResultIcon
  },

  data: () => ({
    search: '',
    page: 1,
    badges: [],
    loading: false,
    paginationParams: {},
    selectedItem: null
  }),

  async mounted () {
    await this.updateSearch({ create: true })
    this.initScrollPagination(
      { pagination: this.paginationParams },
      this.updateSearch
    )
  },

  watch: {
    search () {
      this.$refs.refScroll.scrollToTop()
    }
  },

  methods: {
    ...mapActions('badges/templates', ['setBadgeAutocomplete']),

    async updateSearch (props = {}) {
      this.calculetedPage(props)
      this.loading = true
      const { data, headers } = await this.setBadgeAutocomplete({
        q: this.search,
        page: this.page
      })
      this.setBadges(data)
      this.createPagination(headers)
      this.loading = false
    },

    calculetedPage (props) {
      this.page =
        props.hasOwnProperty('create') || typeof props === 'string'
          ? 1
          : this.paginationParams.page + 1
    },

    setBadges (data) {
      if (!data) return
      this.badges = this.page === 1 ? data : [...this.badges, ...data]
    },

    createPagination (headers) {
      this.paginationParams.page = this.page
      this.paginationParams.perPage =
        (headers && parseInt(headers['x-pagination-per-page'])) || 0
      this.paginationParams.totalCount =
        (headers && parseInt(headers['x-pagination-total-count'])) || 0
      this.paginationParams.pages =
        (headers && parseInt(headers['x-pagination-page-count'])) || 1
      this.paginationParams.total = this.paginationParams.totalCount
    },

    firstLetterUpperCase (text = '') {
      return text[0].toUpperCase() + text.substring(1)
    },

    selectBadge (badge) {
      this.selectedItem = badge
      this.$emit('selectBadge', badge)
    }
  }
}
</script>

<style lang="scss" scoped>
.explore-search {
  height: 100%;
  display: flex;
  flex-direction: column;
  &--field.v-inputserch {
    margin-bottom: 7px;
    padding: 0 16px 0 0;

    ::v-deep input {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      padding: 12px 8px 12px 16px;
      border-radius: 5px 0 0 5px;
    }
  }
  .v-list__empty {
    height: 100%;
    svg{
      width: 100px;
      height: 78px;
    }
    span {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: $body-text-2;
      text-align: center;
      margin-top: 30px;
    }
  }
  ::v-deep .badge {
    &-item {
      padding: 12px 8px;
      height: 48px;
      gap: 16px;
      border: none;
      background: url("~@/assets/img/line.svg") bottom center/100% no-repeat;
      color: $body-text;

      &.active {
        color: $color-dark-green;
        .badge-icon {
          color: $color-dark-green;
        }
      }
    }

    &-icon {
      width: 24px;
      height: 24px;
      color: $body-text-2;
    }

    &-title {
      max-width: 210px;
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;
      color: inherit;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>
