import { PUT } from '@/api/types.js'
import { execute } from '../../utils'

const ENDPOINT = '/preferences/demo/show-tutorial-after-login'

export default {
  updateShowTutorialAfterLogin (value) {
    return execute(PUT, `${ENDPOINT}`, { value })
  }
}
