import { execute, cancelableRequest, addQueryParams } from '../utils'

const ENDPOINT = 'positions'

const cancelable = cancelableRequest(execute)

export default {
  list (params = {}) {
    return execute('GET', addQueryParams(ENDPOINT, params))
  },

  listEmployees (id, params = {}) {
    return cancelable.execute('GET', addQueryParams(`${ENDPOINT}/${id}/employees`, params))
  },

  listBadges (id, params = {}) {
    return execute('GET', addQueryParams(`${ENDPOINT}/${id}/badges`, params))
  },

  create (payload) {
    return execute('POST', ENDPOINT, payload)
  },

  sort (id, payload) {
    return id
      ? execute('PUT', `${ENDPOINT}/${id}/sort`, payload)
      : execute('PUT', `${ENDPOINT}/sort`, payload)
  },

  move (id, toId) {
    return execute('PUT', `${ENDPOINT}/${id}/move?toDepartmentId=${toId}`)
  },

  moveMany (toId, payload) {
    return execute('PUT', `${ENDPOINT}/move?toDepartmentId=${toId}`, payload)
  },

  moveEmployees (fromId, toId) {
    return execute('POST', `${ENDPOINT}/${fromId}/move?toPositionId=${toId}`)
  },

  getById (id) {
    return execute('GET', `${ENDPOINT}/${id}`)
  },

  updateById (id, payload) {
    return execute('PUT', `${ENDPOINT}/${id}`, payload)
  },

  deleteById (id) {
    return execute('DELETE', `${ENDPOINT}/${id}`)
  },

  copy (id) {
    return execute('POST', `${ENDPOINT}/${id}/copy`)
  }
}
