<template lang="pug">
  svg(width="14" height="12" viewbox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg")
    path(d="M8.14677 0.34745C8.41597 0.242886 8.72175 0.314373 8.91668 0.527445L13.2167 5.22745C13.4614 5.49492 13.4614 5.90498 13.2167 6.17246L8.91668 10.8725C8.72175 11.0855 8.41597 11.157 8.14677 11.0525C7.87757 10.9479 7.70022 10.6887 7.70022 10.4V8.15185C6.90416 7.83287 6.02017 7.72102 5.14098 8.00587C4.12211 8.33599 2.97023 9.24418 1.92517 11.3153C1.77914 11.6047 1.4535 11.756 1.13815 11.6809C0.822789 11.6059 0.60022 11.3241 0.60022 11C0.60022 7.64979 1.74632 5.591 3.38983 4.40162C4.74596 3.42022 6.35401 3.09567 7.70022 3.0194V0.999955C7.70022 0.711163 7.87757 0.452014 8.14677 0.34745Z" fill="#666D74")
</template>

<script>
export default {
  name: 'IconFatRightArrow'
}
</script>

<style>
</style>
