<template lang="pug">
  div
    .zoom-buttons
      button.btn-main.btn-add-color.zoom-btn(
        @click="zoomIn"
        :disabled="maxZoom - value < this.zoomStep / 10 ")
        img(src="@/assets/img/icon-plus.svg" alt="icon-plus")
      button.btn-main.btn-add-color.zoom-btn(
        @click="zoomOut"
        :disabled="value - minZoom < this.zoomStep / 10 ")
        img(src="@/assets/img/icon-minus.svg" alt="icon-minus")
</template>

<script>
export default {
  props: ['value', 'zoomStep', 'minZoom', 'maxZoom'],

  methods: {
    zoomIn () {
      this.$emit('on-zoom', this.value + this.zoomStep)
    },

    zoomOut () {
      this.$emit('on-zoom', this.value - this.zoomStep)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';

  .zoom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90px;
    position: absolute;
    bottom: 20px;
    right: 140px;

    @include mobile {
      display: none;
    }
  }
  .zoom-btn{
    margin: 0;
  }
</style>
