<template functional>
  <svg
    :class="[data.class, data.staticClass]"
    width="100"
    height="78"
    viewBox="0 0 100 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.7183 71.4C35.5687 71.6544 34.4143 71.892 33.2503 72.0768C20.4535 74.1312 5.91194 70.8768 1.26554 57.2352C-0.844065 51.0384 -0.114465 43.44 3.62714 37.9752C6.87433 33.2328 11.9311 30.8712 16.4479 27.636C19.6063 25.3752 19.7839 21.4248 19.8919 17.8848C20.0215 13.6752 20.7943 9.99599 23.9167 6.92879C30.0199 0.928786 40.6375 -0.909614 48.7087 1.27919C53.6623 2.62079 58.0303 5.59918 61.9447 8.82478C63.5311 10.1304 65.1247 11.5224 67.0759 12.1656C70.9783 13.452 74.5831 11.3568 78.3895 10.8528C81.4421 10.4862 84.5381 10.8312 87.4351 11.8608C93.1111 13.8144 98.3191 18.276 99.5527 24.3648C100.878 30.888 98.2831 38.0304 94.0327 42.9768C90.4567 47.1312 85.6327 51.168 86.7367 57.2208C87.7783 62.916 89.0287 68.5008 84.1111 72.9504C80.3095 76.3848 74.3839 76.8864 69.2695 75.7224C64.9783 74.7456 61.0591 72.78 57.1135 70.9728C53.3167 69.2352 49.4335 68.9688 45.2623 69.5904C42.3823 70.02 39.5671 70.7688 36.7279 71.4"
      fill="#E3F5F1"
    />
    <path
      d="M84.5911 21.6551L72.9679 60.1199H24.5599C21.1135 60.1199 18.0391 58.0895 16.8799 55.0487L12.5599 21.0119C11.7007 18.7535 13.4863 16.3799 16.0471 16.3799H37.3303L39.7879 21.6599L84.5911 21.6551Z"
      fill="#21856C"
    />
    <path
      d="M20.3913 54.6932L18.0249 23.5796H68.8497L49.6497 50.1884L20.3913 54.6932Z"
      fill="#94D3C3"
    />
    <path
      d="M23.0096 51.4202L22.0496 26.7002L71.9696 26.2202L65.7296 50.7002L23.0096 51.4202Z"
      fill="#D7F9EF"
    />
    <path
      d="M73.0783 34.9346H55.6399V36.2882H73.0783V34.9346Z"
      fill="#AAF6EE"
    />
    <path
      d="M83.4416 20.016C84.0121 20.0164 84.5748 20.1486 85.0858 20.4022C85.5968 20.6558 86.0422 21.0241 86.3875 21.4783C86.7327 21.9325 86.9683 22.4603 87.0759 23.0205C87.1836 23.5807 87.1603 24.1583 87.008 24.708L85.1528 31.4088L78.08 57.3912C77.8658 58.1767 77.3987 58.8697 76.7509 59.363C76.1032 59.8563 75.311 60.1223 74.4968 60.12H23.6648C23.0947 60.1198 22.5323 59.988 22.0214 59.7348C21.5106 59.4816 21.0651 59.1138 20.7197 58.6602C20.3743 58.2065 20.1383 57.6793 20.0302 57.1195C19.922 56.5597 19.9445 55.9825 20.096 55.4328L26.4968 32.2368C26.7807 31.206 27.3952 30.2968 28.2459 29.649C29.0965 29.0013 30.1364 28.6507 31.2056 28.6512H57.5624L59.8664 20.0112L83.4416 20.016Z"
      fill="#29A687"
    />
    <path
      d="M48.4494 40.9797C48.4494 41.3357 48.3438 41.6837 48.146 41.9797C47.9482 42.2757 47.6671 42.5064 47.3382 42.6427C47.0093 42.7789 46.6474 42.8146 46.2982 42.7451C45.949 42.6756 45.6283 42.5042 45.3766 42.2525C45.1248 42.0007 44.9534 41.68 44.884 41.3308C44.8145 40.9817 44.8501 40.6198 44.9864 40.2909C45.1226 39.9619 45.3533 39.6808 45.6493 39.483C45.9453 39.2853 46.2934 39.1797 46.6494 39.1797C46.8857 39.1797 47.1198 39.2262 47.3382 39.3167C47.5566 39.4072 47.755 39.5398 47.9222 39.7069C48.0893 39.874 48.2219 40.0725 48.3123 40.2909C48.4028 40.5092 48.4494 40.7433 48.4494 40.9797Z"
      fill="#D7F9EF"
    />
    <path
      d="M62.1295 40.9797C62.1295 41.3357 62.024 41.6837 61.8262 41.9797C61.6284 42.2757 61.3473 42.5064 61.0184 42.6427C60.6895 42.7789 60.3275 42.8146 59.9784 42.7451C59.6292 42.6756 59.3085 42.5042 59.0567 42.2525C58.805 42.0007 58.6336 41.68 58.5641 41.3308C58.4947 40.9817 58.5303 40.6198 58.6666 40.2909C58.8028 39.9619 59.0335 39.6808 59.3295 39.483C59.6255 39.2853 59.9735 39.1797 60.3295 39.1797C60.8069 39.1797 61.2648 39.3693 61.6023 39.7069C61.9399 40.0445 62.1295 40.5023 62.1295 40.9797Z"
      fill="#D7F9EF"
    />
    <path
      d="M57.6799 51.4202H49.2799C49.0572 51.4202 48.8435 51.3317 48.686 51.1742C48.5284 51.0167 48.4399 50.803 48.4399 50.5802C48.4399 50.3574 48.5284 50.1438 48.686 49.9863C48.8435 49.8287 49.0572 49.7402 49.2799 49.7402H57.6799C57.9027 49.7402 58.1164 49.8287 58.2739 49.9863C58.4314 50.1438 58.5199 50.3574 58.5199 50.5802C58.5199 50.803 58.4314 51.0167 58.2739 51.1742C58.1164 51.3317 57.9027 51.4202 57.6799 51.4202Z"
      fill="#D7F9EF"
    />
    <path
      d="M48.3848 65.0662C47.9609 65.0662 47.5543 65.2346 47.2546 65.5343C46.9548 65.8341 46.7864 66.2407 46.7864 66.6646C46.7864 66.2407 46.618 65.8341 46.3182 65.5343C46.0185 65.2346 45.6119 65.0662 45.188 65.0662C45.3979 65.0662 45.6057 65.0248 45.7997 64.9445C45.9936 64.8642 46.1698 64.7464 46.3182 64.598C46.4667 64.4496 46.5844 64.2734 46.6647 64.0794C46.745 63.8855 46.7864 63.6777 46.7864 63.4678C46.7864 63.6777 46.8277 63.8855 46.9081 64.0794C46.9884 64.2734 47.1061 64.4496 47.2546 64.598C47.403 64.7464 47.5792 64.8642 47.7731 64.9445C47.967 65.0248 48.1749 65.0662 48.3848 65.0662Z"
      fill="#B0E7D8"
    />
    <path
      d="M87.5096 44.0089C87.3944 43.9157 87.262 43.8462 87.1199 43.8043C86.9779 43.7623 86.8289 43.7487 86.6816 43.7643C86.5343 43.7799 86.3915 43.8244 86.2614 43.8952C86.1313 43.966 86.0163 44.0617 85.9232 44.1769C86.1095 43.9457 86.1968 43.6502 86.1663 43.3549C86.1357 43.0596 85.9897 42.7883 85.76 42.6001C85.8754 42.6931 86.0079 42.7625 86.15 42.8042C86.2922 42.846 86.4412 42.8594 86.5885 42.8435C86.7358 42.8277 86.8786 42.783 87.0086 42.712C87.1387 42.641 87.2534 42.5451 87.3464 42.4297C87.1588 42.663 87.0711 42.961 87.1026 43.2587C87.1341 43.5565 87.2821 43.8296 87.5144 44.0185"
      fill="#76CBB5"
    />
    <path
      d="M10.0711 44.0664C9.92263 44.2148 9.80485 44.3911 9.7245 44.585C9.64414 44.779 9.60278 44.9869 9.60278 45.1968C9.60278 45.4068 9.64414 45.6146 9.7245 45.8086C9.80485 46.0026 9.92263 46.1788 10.0711 46.3272C9.92267 46.1787 9.74645 46.061 9.55249 45.9806C9.35853 45.9003 9.15065 45.8589 8.9407 45.8589C8.73076 45.8589 8.52287 45.9003 8.32892 45.9806C8.13496 46.061 7.95873 46.1787 7.8103 46.3272C7.95878 46.1788 8.07655 46.0026 8.15691 45.8086C8.23727 45.6146 8.27862 45.4068 8.27862 45.1968C8.27862 44.9869 8.23727 44.779 8.15691 44.585C8.07655 44.3911 7.95878 44.2148 7.8103 44.0664C7.95873 44.2149 8.13496 44.3327 8.32892 44.413C8.52287 44.4934 8.73076 44.5347 8.9407 44.5347C9.15065 44.5347 9.35853 44.4934 9.55249 44.413C9.74645 44.3327 9.92267 44.2149 10.0711 44.0664Z"
      fill="#B0E7D8"
    />
    <path
      d="M32.3312 52.8818C32.5089 53.1981 32.8047 53.431 33.1538 53.5295C33.5029 53.6281 33.8768 53.5842 34.1936 53.4074C34.0366 53.495 33.8983 53.6126 33.7868 53.7536C33.6753 53.8946 33.5926 54.0562 33.5436 54.2292C33.4945 54.4022 33.4801 54.5831 33.501 54.7617C33.522 54.9402 33.5779 55.1129 33.6656 55.2699C33.4884 54.9532 33.1927 54.7197 32.8436 54.6208C32.4945 54.5218 32.1203 54.5653 31.8032 54.7418C31.9602 54.6545 32.0984 54.5371 32.2099 54.3963C32.3214 54.2555 32.404 54.0941 32.4531 53.9214C32.5021 53.7486 32.5166 53.5678 32.4957 53.3895C32.4748 53.2111 32.4189 53.0386 32.3312 52.8818Z"
      fill="#B0E7D8"
    />
    <path
      d="M15.224 64.2315C15.4013 64.5401 15.4831 64.8943 15.4592 65.2493C15.4353 65.6044 15.3066 65.9444 15.0895 66.2264C14.8724 66.5084 14.5766 66.7197 14.2394 66.8336C13.9023 66.9476 13.5389 66.959 13.1953 66.8664C12.8516 66.7739 12.5431 66.5816 12.3087 66.3138C12.0744 66.046 11.9246 65.7147 11.8785 65.3618C11.8323 65.0089 11.8918 64.6503 12.0494 64.3312C12.207 64.0121 12.4557 63.7469 12.764 63.5691C13.1782 63.3316 13.6697 63.268 14.1308 63.3922C14.5919 63.5163 14.9849 63.8181 15.224 64.2315Z"
      fill="#B0E7D8"
    />
    <path
      d="M79.6326 76.1997C79.6326 76.4793 79.5497 76.7526 79.3944 76.9851C79.2391 77.2175 79.0183 77.3987 78.76 77.5057C78.5017 77.6127 78.2175 77.6407 77.9432 77.5862C77.669 77.5316 77.4172 77.397 77.2195 77.1993C77.0218 77.0016 76.8871 76.7497 76.8326 76.4755C76.778 76.2013 76.806 75.9171 76.913 75.6588C77.02 75.4005 77.2012 75.1797 77.4337 75.0244C77.6661 74.869 77.9394 74.7861 78.219 74.7861C78.5939 74.7861 78.9535 74.9351 79.2186 75.2002C79.4837 75.4653 79.6326 75.8248 79.6326 76.1997Z"
      fill="#B0E7D8"
    />
    <path
      d="M92.9119 52.44C92.9119 52.7196 92.829 52.9929 92.6737 53.2253C92.5184 53.4578 92.2976 53.639 92.0393 53.746C91.781 53.853 91.4968 53.881 91.2225 53.8264C90.9483 53.7719 90.6964 53.6372 90.4988 53.4395C90.3011 53.2418 90.1664 52.99 90.1119 52.7157C90.0573 52.4415 90.0853 52.1573 90.1923 51.899C90.2993 51.6407 90.4805 51.4199 90.713 51.2646C90.9454 51.1093 91.2187 51.0264 91.4983 51.0264C91.8732 51.0264 92.2328 51.1753 92.4979 51.4404C92.763 51.7055 92.9119 52.0651 92.9119 52.44Z"
      fill="#76CBB5"
    />
    <path
      d="M86.3504 16.0247C86.5071 16.0009 86.6673 16.0241 86.8108 16.0914C86.9543 16.1586 87.0747 16.2669 87.1566 16.4025C87.2386 16.5381 87.2786 16.695 87.2715 16.8533C87.2643 17.0116 87.2105 17.1642 87.1166 17.2919C87.0228 17.4196 86.8932 17.5166 86.7442 17.5707C86.5953 17.6248 86.4336 17.6335 86.2797 17.5957C86.1258 17.558 85.9865 17.4755 85.8795 17.3586C85.7725 17.2417 85.7025 17.0957 85.6784 16.9391C85.6467 16.7288 85.6996 16.5145 85.8256 16.3431C85.9515 16.1717 86.1402 16.0572 86.3504 16.0247Z"
      fill="#76CBB5"
    />
    <path
      d="M26.5711 2.91826C26.5711 3.07696 26.5242 3.23212 26.4361 3.36418C26.3481 3.49623 26.2229 3.59928 26.0765 3.66034C25.93 3.7214 25.7687 3.73773 25.6129 3.70729C25.4572 3.67685 25.3139 3.60099 25.2012 3.48926C25.0884 3.37754 25.0113 3.23496 24.9795 3.07948C24.9476 2.92399 24.9625 2.76257 25.0223 2.61554C25.082 2.4685 25.184 2.34245 25.3152 2.25324C25.4465 2.16404 25.6012 2.11568 25.7599 2.11426C25.9732 2.11426 26.1777 2.19896 26.3284 2.34974C26.4792 2.50052 26.5639 2.70502 26.5639 2.91826"
      fill="#76CBB5"
    />
    <path
      d="M78.8 11.5896C78.7995 11.7481 78.7521 11.9028 78.6637 12.0343C78.5753 12.1658 78.45 12.2682 78.3035 12.3285C78.157 12.3888 77.9959 12.4044 77.8405 12.3732C77.6852 12.3419 77.5426 12.2654 77.4307 12.1532C77.3188 12.041 77.2427 11.8982 77.212 11.7427C77.1813 11.5873 77.1973 11.4263 77.258 11.2799C77.3188 11.1336 77.4215 11.0085 77.5533 10.9206C77.6851 10.8326 77.8399 10.7856 77.9984 10.7856C78.1038 10.7856 78.2083 10.8065 78.3057 10.8469C78.4031 10.8873 78.4916 10.9466 78.566 11.0213C78.6405 11.096 78.6995 11.1846 78.7396 11.2822C78.7798 11.3797 78.8003 11.4842 78.8 11.5896Z"
      fill="#B0E7D8"
    />
    <path
      d="M3.17108 30.6766C2.93387 30.8247 2.65806 30.8992 2.37855 30.8905C2.09903 30.8818 1.82837 30.7904 1.6008 30.6279C1.37323 30.4654 1.19898 30.239 1.1001 29.9774C1.00122 29.7158 0.982153 29.4308 1.04531 29.1583C1.10846 28.8859 1.25099 28.6383 1.45488 28.4469C1.65877 28.2555 1.91485 28.1289 2.19072 28.0831C2.46659 28.0372 2.74985 28.0743 3.00468 28.1895C3.2595 28.3047 3.47443 28.4928 3.62228 28.7302C3.81907 29.0486 3.88207 29.4318 3.79755 29.7963C3.71304 30.1609 3.48786 30.4773 3.17108 30.6766Z"
      fill="#B0E7D8"
    />
    <path
      d="M6.61745 31.6632C6.48286 31.747 6.32644 31.789 6.16797 31.784C6.0095 31.7789 5.8561 31.7269 5.72717 31.6346C5.59823 31.5423 5.49956 31.4139 5.44363 31.2655C5.38769 31.1172 5.37701 30.9556 5.41293 30.8011C5.44886 30.6467 5.52977 30.5064 5.64543 30.398C5.7611 30.2895 5.90633 30.2178 6.06275 30.1919C6.21917 30.166 6.37976 30.1871 6.52421 30.2525C6.66865 30.3178 6.79047 30.4246 6.87425 30.5592C6.98656 30.7397 7.0226 30.9573 6.97445 31.1644C6.92629 31.3714 6.79788 31.5508 6.61745 31.6632Z"
      fill="#B0E7D8"
    />
    <path
      d="M33.6895 8.00879L33.5863 6.25439L35.1199 7.11359L36.8767 7.01039L36.0175 8.54399L36.1207 10.3008L34.5847 9.43919L32.8303 9.54479L33.6895 8.00879Z"
      fill="#B0E7D8"
    />
    <path
      d="M96.0753 13.3198L96.6993 12.355L97.1745 13.4014L98.1393 14.023L97.0953 14.5006L96.4713 15.4654L95.9937 14.419L95.0312 13.795L96.0753 13.3198Z"
      fill="#B0E7D8"
    />
    <path
      d="M73.76 39.4126L74.384 38.4478L74.8592 39.4942L75.824 40.1158L74.7776 40.591L74.156 41.5557L73.6784 40.5117L72.7136 39.8878L73.76 39.4126Z"
      fill="#B0E7D8"
    />
  </svg>
</template>

<script>
export default {
  name: 'NoResult'
}
</script>

<style scoped></style>
