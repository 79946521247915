import VStepper from './VStepper'
import VStepperContent from './VStepperContent'
import VStepperHeader from './VStepperHeader'
import VStepperItems from './VStepperItems'
import VStepperStep from './VStepperStep'

export default {
  VStepper,
  VStepperContent,
  VStepperHeader,
  VStepperItems,
  VStepperStep
}
