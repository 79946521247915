<template lang="pug">
  .v-color-select
    .v-color-select__overlay(
      v-if="isVisible"
      @click="hide")

    transition(name="fadex")
      v-card.v-color-select__flyout(v-if="isVisible")
        .v-color-select__color-chip(
          :style="{ 'background-color': color }")

        v-card-content
          .v-color-select__sliders
            .v-color-select__slider
              .v-color-select__slider-track(:style="gradientH")
              input(type="range" min="0" max="360" v-model="h")
            .v-color-select__slider
              .v-color-select__slider-track(:style="gradientS")
              input(type="range" min="0" max="100" v-model="s")
            .v-color-select__slider
              .v-color-select__slider-track(:style="gradientL")
              input(type="range" min="0" max="100" v-model="l")
          .v-color-select__hex
            .v-color-select__hex-input
              span #
              the-mask(
                mask="FFFFFF"
                v-model="hex"
                :tokens="hexTokens"
                @blur.native="onInputHex")

        v-card-actions
            v-btn(text @click="hide")
              | {{ 'ui.buttons.cancel' | translate }}
            v-btn(@click="addColor")
              | {{ 'ui.buttons.apply' | translate }}

    .v-color-select__swatch.v-color-select__swatch--parent(
      v-if="resetColor"
      v-tooltip="$t('infotext.use_parent_color')"
      @click="selectInheritColor")

    .v-color-select__swatch(
      v-for="(color, index) in colorsToDisplay"
      :key="index"
      :class="{ 'v-color-select__swatch--active': color === value }"
      :style="{ 'background-color': color }"
      @click="selectColor(color)")

    .v-color-select__swatch.v-color-select__swatch--custom(@click="toggle"
      v-tooltip="$t('infotext.add_color')")
      i.fa.fa-plus

</template>

<script>
import api from '@/api'
import { TheMask } from 'vue-the-mask'

const LOCAL_STORAGE_KEY = 'localColors'

export default {
  name: 'VColorSelect',

  components: {
    TheMask
  },

  props: {
    value: String,
    resetColor: {
      type: Boolean,
      default: false
    },
    saveLastCount: {
      type: Number,
      default: 13
    }
  },

  async mounted () {
    const localColors = localStorage.getItem(LOCAL_STORAGE_KEY)
    this.localColors = localColors
      ? localColors.split(',')
      : []
    const { data } = await api.badges.colors()
    if (data) {
      this.serverColors = data
      for (const item of this.serverColors) {
        const index = this.localColors.indexOf(item)
        if (index !== -1) {
          this.localColors.splice(index, 1)
        }
      }
    }
    this.rerangeColors()
    this.hex = this.value
  },

  data: () => ({
    hex: '',
    hexTokens: {
      F: {
        pattern: /[0-9a-fA-F]/,
        transform: v => v.toLocaleUpperCase()
      }
    },
    colorsToDisplay: [],
    serverColors: [],
    localColors: [],
    isVisible: false,
    h: 120,
    s: 100,
    l: 50
  }),

  methods: {
    show () {
      this.isVisible = true
    },

    hide () {
      this.isVisible = false
    },

    toggle () {
      this.isVisible = !this.isVisible
    },

    rerangeColors () {
      let maxLocalColorsCount = this.saveLastCount - this.serverColors.length
      if (this.resetColor) {
        maxLocalColorsCount -= 1
      }
      const localColorsCount = this.localColors.length
      const showLocalCount = localColorsCount > maxLocalColorsCount
        ? maxLocalColorsCount
        : this.localColors.length
      const restCount = localColorsCount - showLocalCount
      if (restCount > 0) {
        this.localColors.splice(0, restCount)
      }
      this.colorsToDisplay = [...this.serverColors, ...this.localColors]
      localStorage.setItem(LOCAL_STORAGE_KEY, this.localColors)
    },

    addColor () {
      const color = this.getRgbHexValue()
      const index = this.colorsToDisplay
        .indexOf(color)
      if (index === -1) {
        this.localColors.push(color)
        this.rerangeColors()
        this.selectColor(color)
      }
      this.hide()
    },

    selectInheritColor () {
      this.selectColor(null)
    },

    selectColor (color) {
      this.$emit('input', color)
    },

    onInputHex () {
      if (this.hex.length === 6) {
        this.selectColor(`#${this.hex}`)
      }
    },

    hslToRgb (h, s, l) {
      let r, g, b
      if (s === 0) {
        r = g = b = l
      } else {
        const hue2rgb = (p, q, t) => {
          if (t < 0) t += 1
          if (t > 1) t -= 1
          if (t < 1 / 6) return p + (q - p) * 6 * t
          if (t < 1 / 2) return q
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
          return p
        }
        let q = l < 0.5 ? l * (1 + s) : l + s - l * s
        let p = 2 * l - q
        r = hue2rgb(p, q, h + 1 / 3)
        g = hue2rgb(p, q, h)
        b = hue2rgb(p, q, h - 1 / 3)
      }
      return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
    },

    rgbToHsl (r, g, b) {
      r /= 255
      g /= 255
      b /= 255
      let max = Math.max(r, g, b)
      let min = Math.min(r, g, b)
      let h
      let s
      let l = (max + min) / 2
      if (max === min) {
        h = s = 0
      } else {
        let d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
          case r: h = (g - b) / d + (g < b ? 6 : 0)
            break
          case g: h = (b - r) / d + 2
            break
          case b: h = (r - g) / d + 4
            break
        }
        h /= 6
      }
      return [h, s, l]
    },

    hexToRgb (hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null
    },

    componentToHex (c) {
      let hex = c.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    },

    rgbToHex (r, g, b) {
      return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b)
    },

    getRgbHexValue () {
      let rgb = this.hslToRgb(this.h / 360, this.s / 100, this.l / 100)
      return this.rgbToHex(rgb[0], rgb[1], rgb[2])
    }
  },

  computed: {
    color () {
      return `hsl(${this.h}, ${this.s}%, ${this.l}%)`
    },

    colorString () {
      return this.h + ', ' + this.s + '%, ' + this.l + '%'
    },

    gradientH () {
      let stops = []
      for (let i = 0; i < 7; i++) {
        stops.push(`hsl(${i * 60}, 100%, 50%)`)
      }
      return {
        backgroundImage: 'linear-gradient(to right, ' + stops.join(', ') + ')'
      }
    },

    gradientS () {
      let stops = []
      stops.push(`hsl(${this.h}, 0%, 50%)`)
      stops.push(`hsl(${this.h}, 100%, 50%)`)
      return {
        backgroundImage: 'linear-gradient(to right, ' + stops.join(', ') + ')'
      }
    },

    gradientL () {
      let stops = []
      stops.push(`hsl(${this.h}, 50%, 0%)`)
      stops.push(`hsl(${this.h}, 100%, 100%)`)
      return {
        backgroundImage: 'linear-gradient(to right, ' + stops.join(', ') + ')'
      }
    }
  },

  watch: {
    value () {
      if (this.value) {
        let { r, g, b } = this.hexToRgb(this.value)
        let hsl = this.rgbToHsl(r, g, b)
        this.h = Math.round(hsl[0] * 360)
        this.s = Math.round(hsl[1] * 100)
        this.l = Math.round(hsl[2] * 100)
      } else {
        this.h = 360
        this.s = 100
        this.l = 50
      }
    },

    color () {
      this.hex = this.getRgbHexValue()
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-color-select {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .v-color-select__overlay {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0px;
      left: 0;
      background: black;
      z-index: 1;
      opacity: 0.1;
    }

    .v-color-select__flyout {
      width: 275px;
      position: absolute;
      bottom: -130px;
      right: -12px;
      z-index: 2;
    }

    .v-color-select__sliders {
      padding-bottom: 5px;
    }

    .v-color-select__color-chip {
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      border-radius: 4px 4px 0 0;
    }
  }

  .v-color-select__slider {
    position: relative;
    width: 100%;
    height: 20px;

    + .v-color-select__slider {
      margin-top: 10px;
    }

    input {
      position: absolute;
      width: 100%;
      margin: 0;
    }

    input[type=range] {
      -webkit-appearance: none;
      width: 100%;
      background: transparent;
    }

    input[type=range]:focus {
      outline: none;
    }

    input[type=range]::-ms-track {
      width: 100%;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: 1px solid #ddd;
      height: 20px;
      width: 20px;
      border-radius: 50px;
      background: $color-white;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    }
  }

  .v-color-select__hex {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;

    .v-color-select__hex-input {
      display: flex;
      align-items: center;
      width: 74px;
      border-radius: 4px;
      padding: 3px 4px;
      background-color: $light-blue;

      input {
        width: 64px;
        font-size: 14px;
        padding: 0;
        border: none;
        background-color: transparent;
      }
    }
  }

  .v-color-select__slider-track {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 14px;
    border-radius: 12px;
    border: 1px solid #ddd;
    z-index: 0;
  }

  .v-color-select__swatch {
    position: relative;
    transform: scale(0.85);
    width: 30px;
    height: 30px;
    border-radius: 5px;
    margin-right: 8px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &--custom {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $h2-title;
      background: $light-blue;
    }

    &--active {
      transform: scale(1);
    }

    &--parent {
      opacity: .55;
      border: 3px solid $h2-title;
      background-color: $color-white;

      &:hover {
        opacity: .4;
      }
    }

    &--parent::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 36px;
      height: 3px;
      background-color: $h2-title;
      transform: rotate(45deg) translate(calc(-50% + 4px), calc(-50% + 13px));
    }

    i {
      opacity: .55;
    }
  }
</style>
