import api from '@/api'
import { TOKEN_COOKIE, NOTIFICATIONS_TYPES } from '@/util/constants'
import cookie from '@/util/cookie'
import store from '@/store'

const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
const SET_NOTIFICATIONS_UNSEEN_COUNT = 'SET_NOTIFICATIONS_UNSEEN_COUNT'
const SET_NOTIFICATIONS_PER_PAGE = 'SET_NOTIFICATIONS_PER_PAGE'
const SET_UNSEEN_NOTIFICATIONS = 'SET_UNSEEN_NOTIFICATIONS'
const SHOW_READ_MORE_NOTIFICATIONS = 'SHOW_READ_MORE_NOTIFICATIONS'

const activatePrevioslyGradationalBadges = (node, badge, store) => {
  const badges = [node.props, ...node.props.gradationalSubBadges]
  const currenBadgeIndex = badges.findIndex(el => el.id === badge.id)
  const requiredBadgeParam = {
    isActivated: true
  }
  store.dispatch('suggestions/updateMyProfileTabs')
  for (const i in badges) {
    if (+i >= +currenBadgeIndex) break
    badges[i].isActivated = true
    badge.validation = null
    store.dispatch('trees/updateRequiredBadge', { badgeId: badges[i].id, requiredBadgeParam }, { root: true })
  }
}

const updateByNotification = async (notification) => {
  if (notification.typeId === NOTIFICATIONS_TYPES.BADGE_VALIDATION_STATUS) {
    const nodeByGradtionalId = await store.dispatch('trees/getNodeBySubbadgeId', notification.data.badgeId, { root: true })
    if (nodeByGradtionalId) {
      let badge = null
      if (nodeByGradtionalId.props.id === notification.data.badgeId) {
        badge = nodeByGradtionalId.props
      } else {
        badge = (nodeByGradtionalId.props.gradationalSubBadges || []).find(el => el.id === notification.data.badgeId)
      }

      if (badge && badge.validation) {
        badge.isActivated = notification.data.validation.statusId === 'APPROVED'
        const params = {
          isActivated: badge.isActivated
        }
        if (badge.isActivated) {
          activatePrevioslyGradationalBadges(nodeByGradtionalId, badge, store)
        }
        store.dispatch('trees/updateRequiredBadge', { badgeId: badge.id, params }, { root: true })
        for (const key in badge.validation) {
          badge.validation[key] = notification.data.validation[key]
        }
      }
    }
  } else if (notification.typeId === NOTIFICATIONS_TYPES.GOAL_APPROVAL_STATUS) {
    store.dispatch('goals/setAprrovalStatusFromNotification', { goal: notification.data.goal }, { root: true })
  } else if (notification.typeId === NOTIFICATIONS_TYPES.GOAL_COMPLETION_STATUS) {
    store.dispatch('goals/setCompletionStatusFromNotification', { goal: notification.data.goal }, { root: true })
  }
}

const parseNewNotification = (newData, oldData) => {
  for (const item of newData) {
    const findRes = oldData.find(el => el.id === item.id)
    if (!findRes) {
      updateByNotification(item)
    }
  }
}

const onUpdateNotificationData = (array, id, data) => {
  const oldData = array.find(el => el.id === id)
  if (oldData) {
    for (const key in oldData) {
      oldData[key] = data[key]
    }
  }
  return oldData
}

export default {
  namespaced: true,

  state () {
    return {
      notifications: [],
      unseenCount: 0,
      notificationPerPage: [],
      unseenNotifications: [],
      showReadMoreNotifications: {
        show: false,
        comment: ''
      }
    }
  },

  getters: {
    notifications: (state) => state.notifications,
    unseenCount: (state) => state.unseenCount,
    notificationPerPage: (state) => state.notificationPerPage,
    unseenNotifications: (state) => state.unseenNotifications,
    showReadMoreNotifications: (state) => state.showReadMoreNotifications
  },

  mutations: {
    [SET_NOTIFICATIONS] (state, options) {
      state.notifications = options
    },

    [SET_NOTIFICATIONS_UNSEEN_COUNT] (state, count) {
      state.unseenCount = count
    },

    [SET_NOTIFICATIONS_PER_PAGE] (state, options) {
      state.notificationPerPage = options
    },

    [SET_UNSEEN_NOTIFICATIONS] (state, options) {
      state.unseenNotifications = options
    },

    [SHOW_READ_MORE_NOTIFICATIONS] (state, props) {
      for (let key in props) {
        state.showReadMoreNotifications[key] = props[key]
      }
    }

  },
  actions: {
    setUnseenCount (context, count) {
      context.commit(SET_NOTIFICATIONS_UNSEEN_COUNT, count)
    },

    async onNotificationsSubscribe (context, pageNumber = 1) {
      const { data, headers } = await api.notificationsSubscribe(pageNumber)
      if (data && +headers['x-pagination-page-count'] >= pageNumber) {
        const notifications = pageNumber && pageNumber > 1
          ? [...context.state.notifications, ...data.notifications]
          : data.notifications
        context.commit(SET_NOTIFICATIONS, notifications)
        context.commit(SET_NOTIFICATIONS_UNSEEN_COUNT, data.unseenCount)
        return { length: data.notifications.length, headers }
      }
      return { headers }
    },

    async onNotificationsPerPageSubscribe (context, isLoopCall = false) {
      const { data } = await api.onNotificationsPerPageSubscribe()
      if (data) {
        if (isLoopCall) {
          const copiedNewData = JSON.parse(JSON.stringify(data.notifications))
          const copiedOldData = JSON.parse(JSON.stringify(context.state.notificationPerPage))
          parseNewNotification(copiedNewData, copiedOldData)
        }
        context.commit(SET_NOTIFICATIONS_PER_PAGE, data.notifications)
        context.commit(SET_NOTIFICATIONS_UNSEEN_COUNT, data.unseenCount)
      }
      setTimeout(() => {
        if (cookie.hasItem(TOKEN_COOKIE)) {
          context.dispatch('onNotificationsPerPageSubscribe', true)
        }
      }, 20000)
    },

    async getUnseenNotifications (context, pageNumber = 1) {
      const { data, headers } = await api.loadUnseenNotifications(pageNumber)
      if (data && +headers['x-pagination-page-count'] >= pageNumber) {
        const notifications = pageNumber && pageNumber > 1
          ? [...context.state.unseenNotifications, ...data.notifications]
          : data.notifications || []
        context.commit(SET_UNSEEN_NOTIFICATIONS, notifications)
        return { length: data.notifications.length, headers }
      }
      return { headers }
    },

    async getNotification (context, { id, update = false }) {
      const { data } = await api.getNotificationById(id)

      if (data) {
        if (update) {
          await onUpdateNotificationData(context.state.notificationPerPage, id, data)
          await onUpdateNotificationData(context.state.notifications, id, data)
          await onUpdateNotificationData(context.state.unseenNotifications, id, data)
          return data
        }
      }
    },

    setIsSeenMessage (context, { id, isSeen, all = false }) {
      context.state.notificationPerPage.forEach(el => {
        if (all || el.id === id) { el.isSeen = isSeen }
      })

      context.state.notifications.forEach(el => {
        if (all || el.id === id) { el.isSeen = isSeen }
      })

      context.state.unseenNotifications.forEach(el => {
        if (all || el.id === id) { el.isSeen = isSeen }
      })
    }
  }
}
