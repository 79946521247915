<template functional>
  <svg :class="[data.class, data.staticClass]" class="v-info-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-tooltip="props.text">
    <path d="M8.00001 0.320007C3.75761 0.320007 0.319214 3.75841 0.319214 8.00001C0.319109 9.00869 0.517707 10.0075 0.903666 10.9394C1.28963 11.8714 1.85538 12.7181 2.56863 13.4314C3.28188 14.1446 4.12865 14.7104 5.06057 15.0964C5.9925 15.4823 6.99133 15.6809 8.00001 15.6808C12.2408 15.6808 15.68 12.2424 15.68 8.00001C15.68 3.75841 12.2408 0.320007 8.00001 0.320007ZM8.71681 3.09281C9.46561 3.09281 9.68561 3.52721 9.68561 4.02401C9.68561 4.64401 9.18961 5.21761 8.34241 5.21761C7.63361 5.21761 7.29601 4.86161 7.31681 4.27201C7.31681 3.77521 7.73201 3.09281 8.71681 3.09281ZM6.79841 12.6C6.28641 12.6 5.91281 12.2888 6.27041 10.9248L6.85681 8.50481C6.95841 8.11761 6.97521 7.96241 6.85681 7.96241C6.70401 7.96241 6.03921 8.22961 5.64721 8.49361L5.39201 8.07521C6.63601 7.03601 8.06641 6.42641 8.67841 6.42641C9.19041 6.42641 9.27521 7.03121 9.02001 7.96241L8.34801 10.5064C8.22881 10.956 8.28001 11.1112 8.39921 11.1112C8.55281 11.1112 9.05521 10.9256 9.54961 10.536L9.83921 10.9248C8.62881 12.1344 7.30961 12.6 6.79841 12.6Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'VInfoIcon',

  props: {
    text: String
  }
}
</script>

<style lang="scss" scoped>
  .v-info-icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    color: $body-text-2;
    cursor: pointer;
  }
</style>
