import { execute } from '@/api/utils.js'
import { GET, PUT, POST, DELETE } from '@/api/types.js'

const ENDPOINT = 'badges/skills/external/bookmarks'

export default {
  bookmarkSkills () {
    return execute(GET, ENDPOINT)
  },

  deleteBookmarkAllSkills () {
    return execute(DELETE, ENDPOINT)
  },

  addBookmarkSkill (props) {
    return execute(POST, ENDPOINT, props)
  },

  deleteBookmarkSkill (ID) {
    return execute(DELETE, `${ENDPOINT}/${ID}`)
  },

  updateBookmarkSkill ({ id, badgeTemplateId }) {
    return execute(PUT, `${ENDPOINT}/${id}`, { badgeTemplateId: badgeTemplateId })
  }
}
