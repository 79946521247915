<template lang="pug">
v-group
  tree-badge(
    :node="badge",
    :color="color",
    :pos="posBadge",
    :cursorNode="cursorNode",
    :level="level",
    :isSelected="focusChain",
    @click="$emit('onClick', badge)"
  )
  tree-label(
    isBadge,
    :depth="depth",
    :width="textWidth",
    :direction="direction",
    :pos="posLabel",
    :text="nodeTitle"
  )
  v-circle(v-if="showDotNewBadge", :config="configDotNewBadge")
</template>

<script>
import { mapGetters } from 'vuex'
import TreeBadge from './TreeBadge'
import TreeLabel from './TreeLabel'

const BADGE_STEP = 120

export default {
  name: 'TreeLabelAndBadge',

  components: {
    TreeBadge,
    TreeLabel
  },

  props: {
    color: String,
    cursorNode: Object,
    badge: Object,
    depth: Number,
    posBadge: Object,
    posLabel: Object,
    focusChain: Boolean,
    direction: Number
  },

  methods: {},

  computed: {
    ...mapGetters('preferences', ['isDefaultView']),

    ...mapGetters('treeView', ['showAllAsActivated']),

    ...mapGetters('badges/newlyAdded', ['isNewlyBadgesIds']),

    ...mapGetters('settings', ['isEnabledNotification']),

    ...mapGetters('trees', ['isLoadLazyTree']),

    isMyPage () {
      return this.$route.path.includes('my')
    },

    textWidth () {
      return BADGE_STEP - 10
    },

    nodeTitle () {
      const badges = [
        this.badge.props,
        ...this.badge.props.gradationalSubBadges
      ]
      const badge = badges[this.level - 1]
      return badge.displayName || badge.name
    },

    level () {
      if (this.showAllAsActivated) {
        return this.badge.props.gradationalSubBadges.length + 1
      }
      let lev = 1

      let { isActivated, gradationalSubBadges } = this.badge.props
      if (isActivated) {
        for (let i of gradationalSubBadges) {
          if (i.isActivated) {
            lev = i.starsCount
          }
        }
      } else if (
        this.isDefaultView.key === 'MY_PROFILE' ||
        this.isDefaultView.key === 'MY_SKILLTREE'
      ) {
        for (let i of gradationalSubBadges) {
          if (
            i.helpers.isGoal ||
            i.helpers.isProfileBadge ||
            i.helpers.isRecommended
          ) {
            lev = i.starsCount
          }
        }
      }
      return lev
    },
    configDotNewBadge () {
      return {
        x: this.direction > 0 ? this.posLabel.x - 12 : this.posLabel.x + 3,
        y: this.direction > 0 ? this.posLabel.y + 5 : this.posLabel.y + 6,
        radius: 2,
        fill: '#F68A37'
      }
    },
    showDotNewBadge () {
      return (
        this.isNewlyBadgesIds.includes(this.badge.props.id) &&
        this.isMyPage &&
        this.isEnabledNotification
      )
    }
  }
}
</script>

<style lang="scss">
</style>
