<template lang="pug">
.drawer-panel__toggler(:class="clasessToggler")
  button.dpt-button(
    v-for="(tab, idx) in tabs",
    :key="tab.id",
    :class="[{ 'dpt-button--active': activeTab && tab.id === activeTab.id }, { 'dpt-button--first': idx === 0 }]",
    @click="toggle(tab)"
  )
    span {{ tab.title | translate }}
  .chevron-wrappers(:class="[!typeChevron ? 'arrow' : typeChevron]")
    component.chevron-wrapper(
      :is="componentChevron"
      :menu="menu",
      :active="selectedItemMenu"
      :active-tab="activeTab"
      @click-arrow="toggle(!!activeTab ? activeTab : defaultTab)"
      @change="selectedChevron")
</template>

<script>
import ChevronBurger from './chevron-burger.vue'
import ChevronSlider from './chevron-slider.vue'
import ChevronArrow from './chevron-arrow.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DrawerPanelToggler',
  components: {
    ChevronBurger,
    ChevronSlider,
    ChevronArrow
  },

  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    activeTab: {
      type: Object,
      default: () => null
    },
    side: {
      type: String,
      default: 'left'
    },
    typeChevron: {
      type: String,
      default: 'arrow'
    }
  },
  data: () => ({
    toggleBurger: false,
    selectedItemMenu: null,
    menu: [
      { id: 0, value: 'Closed', width: 0 },
      { id: 1, value: 'Open', width: 384 },
      { id: 2, value: 'Open full', width: 982 }
    ]
  }),

  methods: {
    ...mapActions('skillWizard', ['setSizeModal']),

    toggle (tab) {
      this.$emit('input', tab)
    },

    selectedChevron (item) {
      this.selectedItemMenu = item
      this.$emit('active-chevron', item)
    }
  },
  computed: {
    ...mapGetters('app', ['lastOpenedSidebar']),
    ...mapGetters('skillWizard', ['sizeModal']),

    defaultTab () {
      return this.lastOpenedSidebar || this.tabs[0]
    },

    clasessToggler () {
      return [
        `${this.side}-side`,
        { 'drawer-panel__toggler-show': this.activeTab }
      ]
    },
    componentChevron () {
      return `chevron-${this.typeChevron || 'arrow'}`
    }
  },
  watch: {
    sizeModal (val) {
      if (val) {
        this.selectedChevron(this.menu[2])
        this.setSizeModal(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/abstracts/_mixins.scss";
$shadow-left-first: inset 6px -6px 12px -9px rgba(0, 0, 0, 0.08);
$shadow-left-other: inset 6px 6px 12px -9px rgba(0, 0, 0, 0.08);
$border-radius-left: 0 $border-radius $border-radius 0;
$border-radius-right: $border-radius 0 0  $border-radius ;
$shadow-right-other: inset 6px 6px 12px -9px rgba(0, 0, 0, 0.08);
// not toggle
$shadow-left-other-hide: inset 0 6px 12px -9px rgba(0, 0, 0, 0.08);

.drawer-panel__toggler {
  display: flex;
  flex-direction: column;

  &-show {
    &.left-side {
      .dpt-button {
        box-shadow: $shadow-left-other;
        border-left: 1px solid $color-line-dotted;

        &--first {
          box-shadow: $shadow-left-first;
        }

        &--active {
          box-shadow: none;
          border-color: transparent;
        }
      }
      .chevron-wrapper {
        box-shadow: $shadow-left-other;
        border-left: 1px solid $color-line-dotted;
      }
    }
    &.right-side{
      .dpt-button {
        box-shadow: $shadow-right-other;
        border-right: 1px solid $color-line-dotted;

        &--first {
          box-shadow: $shadow-left-first;
        }

        &--active {
          box-shadow: none;
          border-color: transparent;
        }
      }
      .chevron-wrapper {
        box-shadow: $shadow-right-other;
      }
    }
  }
  &.left-side {
    .dpt-button {
      border-radius: $border-radius-left;
      &--active {
        &::before {
          right: 0;
        }
      }

      .chevron-wrapper {
        box-shadow: $shadow-left-other;
        border-left: 1px solid $color-line-dotted;
      }
    }
  }

  &.right-side {
    .dpt-button{
      border-radius: $border-radius-right;
      &--active {
        &::before {
          left: 0;
        }
      }
      span{
        transform: scale(-1, -1);
      }
    }
    .chevron-wrapper{
      transform: scale(-1, -1);
      box-shadow: $shadow-right-other;
      border-left: 1px solid $color-line-dotted;
    }
  }
}

.dpt-button {
  position: relative;
  display: flex;
  width: 40px;
  padding: 32px 10px;
  color: $body-text;
  border: 0;
  background-color: var(--white);
  border-color: transparent;
  border-style: solid;
  box-shadow: $shadow-left-other-hide;
  overflow: hidden;

  &--first {
    box-shadow: 0;
  }

  &--active {
    color: $color-dark-green;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      display: flex;
      width: 2px;
      height: 100%;
      background-color: $color-dark-green;
    }
  }
  span {
    color: inherit;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    white-space: nowrap;
    writing-mode: vertical-lr;
  }
}

.chevron-wrappers {
  &.arrow{
    margin: 0;
  }
  &.slider,
  &.burger{
     margin-top: auto;
  }
}

.chevron-wrapper {
  background-color: $color-white;
  padding: 16px 12px;
  box-shadow: $shadow-left-other-hide;
}
</style>
