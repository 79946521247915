<template lang="pug">
  i.table-sort-arrows(
    :style="{ opacity: show ? 1 : 0 }"
    :class="toggled ? 'flaticon2-arrow-up' : 'flaticon2-arrow-down'")

</template>

<script>
// TODO do functional after  remove showSorting env variable
export default {
  name: 'VTableSorter',

  props: {
    show: Boolean,
    toggled: Boolean
  }

}
</script>

<style lang="scss">
</style>
