<template lang="pug">
  span.node-icon
    category-circle(
      v-if="isCategoty"
      :color="data.color || colorPrimary")
    badge(
      v-else
      width="25"
      :color="data.color || colorPrimary"
      :level="data.starsCount")

</template>

<script>
import { NODE_TYPE, COLOR_PRIMARY } from '@/util/constants'

import Badge from '@/components/svg/Badge'
import BadgeSimple from '@/components/svg/BadgeSimple'
import CategoryCircle from '@/components/svg/CategoryCircle'

export default {
  name: 'NodeItem',

  props: {
    data: Object
  },

  components: {
    Badge,
    BadgeSimple,
    CategoryCircle
  },

  computed: {
    isCategoty () {
      return this.data.typeId === NODE_TYPE.CATEGORY
    },

    colorPrimary () {
      return COLOR_PRIMARY
    }
  }
}
</script>

<style lang="scss" scoped>
  .node-icon {
    position: absolute;
    left: 0;
    width: 30px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
