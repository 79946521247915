import { execute, addQueryParams } from '../utils'

export default {
  list (params = {}) {
    return execute('GET', addQueryParams('goals', params))
  },

  create (goal) {
    return execute('POST', 'goals', goal)
  },

  createForEmployee (employeeId, goal) {
    return execute('POST', `goals?forEmployeeId=${employeeId}`, goal)
  },

  update (id, payload) {
    return execute('PUT', `goals/${id}`, payload)
  },

  remove (id) {
    return execute('DELETE', `goals/${id}`)
  },

  fail (id) {
    return execute('DELETE', `goals/${id}/fail`)
  },

  complete (id) {
    return execute('PUT', `goals/${id}/complete`)
  },

  reject (id) {
    return execute('DELETE', `goals/${id}/reject`)
  },

  approve (id) {
    return execute('PUT', `goals/${id}/approve`)
  },

  comment (id, payload) {
    return execute('POST', `goals/${id}/comments`, payload)
  },

  deleteComment (commentId) {
    return execute('DELETE', `goals/comments/${commentId}`)
  },

  listByEmployeeId (employeeId) {
    return execute('GET', `goals?forEmployeeId=${employeeId}`)
  },

  singleGoal (goalId) {
    return execute('GET', `goals/${goalId}`)
  }
}
